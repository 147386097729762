import { axiosconfig } from "../../service/apiconfig";

export const WizardAPI = {
    getWizardData: ({projectId}:any) => {
        // debugger;
        return axiosconfig.get(`user/wizard-step/${projectId}`,{
            projectId
        })
    },
    getConfigureDataState: ({project_id}:any) => {
        return axiosconfig.get(`projects/${project_id}/configure_data_state`,{
            project_id
        })
    },
    postConfigureDataState:({project_id,  isAutosave, attribute_data, reporting_buckets, themes, entities, config_code}: any) =>{
        return axiosconfig.post(`projects/${project_id}/process_project`,{
            project_id,  isAutosave, attribute_data, reporting_buckets, themes, entities, config_code
        }) 
    },
    getProjectDataSet: ({project_id}:any) => {
        return axiosconfig.get(`projects/${project_id}/project_dataset_state`,{
            project_id
        })
    }
}
