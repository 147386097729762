import styled from "@emotion/styled";
import { UseSwitchParameters, useSwitch } from "@mui/base";
import clsx from "clsx";

function BasicSwitch(props: UseSwitchParameters) {
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);

  const stateClasses = {
    'Switch-checked': checked,
    'Switch-disabled': disabled,
    'Switch-focusVisible': focusVisible,
  };

  return (
    <BasicSwitchRoot className={clsx(stateClasses)}>
      <BasicSwitchThumb className={clsx(stateClasses)} />
      <BasicSwitchInput {...getInputProps()} aria-label="Demo switch" />
    </BasicSwitchRoot>
  );
}

const blue = {
  500: '#007FFF',
};

const grey = {
  400: '#BFC7CF',
  500: '#AAB4BE',
  600: '#6F7E8C',
};

const BasicSwitchRoot = styled('span')(
  ({ theme }) => `
    font-size: 0;
    position: relative;
    display: inline-block;
    width: 30px;
    height: 18px;
    margin: 10px;
    cursor: pointer;
    border-radius: 16px;
       background: ${grey[400]};

  
  
    &.Switch-disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  
    &.Switch-checked {
      background: #9747FF;
    }
    `,
);

const BasicSwitchInput = styled('input')`
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  `;


const BasicSwitchThumb = styled('span')`
  display: block;
  width: 14px; 
  height: 14px; 
  top: 2px; 
  left: 2px; 
  border-radius: 50%; 
  background-color: #fff;
  position: relative;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &.Switch-focusVisible {
    background-color: ${grey[500]};
    box-shadow: 0 0 1px 8px rgb(0 0 0 / 0.25);
  }

  &.Switch-checked {
    left: 14px;
    top: 2px; 
    background-color: #fff;
  }
`;

export default BasicSwitch  