import { createSlice } from "@reduxjs/toolkit";

interface ISiteData {
    loaded: boolean,
    data: any
}

const initialState = {
    loaded: false,
    data: {}
}

export const siteDataSlice = createSlice({
    name: "site",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
    },
})

export default siteDataSlice.reducer;