import { createSlice } from "@reduxjs/toolkit";
import { INotificationData } from "./type";

const initialState: INotificationData = {
  message: "",
  duration: 6000,
  type: "info",
  isOpen: false,
  anchorOrigin: { vertical: "bottom", horizontal: "left" }
};

export const notificationSlice = createSlice({
  name: "ProjectData",
  initialState: initialState,
  reducers: {
    setIsOpenFalse: (state: INotificationData) => {
      state.isOpen = false;
      state.type = "info";
      state.message = "";
      state.anchorOrigin = { vertical: "bottom", horizontal: "left" };

    },
    openSnackbar: (state: INotificationData, action) => {
      state.message = action.payload.message;
      if (action.payload.duration) {
        state.duration = action.payload.duration;
      }
      if (action.payload.type) {
        state.type = action.payload.type;
      }
      if (action.payload.anchorOrigin) {
        state.anchorOrigin = action.payload.anchorOrigin
      }
      state.isOpen = true;
    },
  },
});

export const { setIsOpenFalse, openSnackbar } = notificationSlice.actions;

export default notificationSlice.reducer;
