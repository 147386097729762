import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IViewGroupData } from "./type";
import { combineQuestionBuckets, deleteQuestionBuckets, excludeAllUngrouped, excludeQuestionAns, getALLGroups, getGroupQuestionAndAnswers, getViewGrpQbs, makeUngroupedBuckets, vgAddQBucket } from "./action";

const initialState: IViewGroupData = {
    viewGroupQuestionBucket: [],
    questionResponseLoader: false,
    viewBucketsLoader: false,
    loaded: false,
    data: undefined,
    error: null,
    questAnsList: [],
    questAnsCount: 0,
    excludeMakeUngrouped: false,
    makeExcludeSuccess: false,
    delBucketSuccess: false,
    vgQuestionBucks: []
}

const includesValue = (searchFor: string, array: Array<any>) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i] === searchFor) {
            return true
        }
    }
    return false
}

export const viewGroupDataSlice = createSlice({
    name: "ViewGroupData",
    initialState: initialState,
    reducers: {
        updateViewGroupQuestionBucket: (state, action) => {
            state.viewGroupQuestionBucket = action.payload.response
        },
        updateExMakeSuccess: (state, action) => {
            state.excludeMakeUngrouped = action.payload.response
        },
        updateMakeExcludeSuccess: (state, action) => {
            state.makeExcludeSuccess = action.payload.response
        },
        updateDelBucketSuccess: (state, action) => {
            state.delBucketSuccess = action.payload.response
        },
        makeGroupLoader: (state, action) => {
            state.loaded = action.payload
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getALLGroups.pending, (state: IViewGroupData) => {
                state.loaded = true
            })
            .addCase(getALLGroups.fulfilled, (state: IViewGroupData, action: PayloadAction<any>) => {
                // state.viewGroupQuestionBucket = 
                let data = action.payload.response.data
                let displayNames: any = []
                let formattedData: any = []
                data.map((item: any) => {
                    if (!includesValue(item.display_name, displayNames)) {
                        displayNames.push(item.display_name)
                    }
                })
                displayNames.map((item: any) => {
                    let no_of_questions = 0
                    let active = true
                    let temp = data.filter((elem: any) => elem.display_name === item)
                        .map((bub: any) => {
                            no_of_questions += bub.no_of_questions
                            // active = bub.active
                            return { question_bucket_name: bub.question_bucket_name, question_group_id: bub.question_group_id }
                        })
                    formattedData.push({ display_name: item, no_of_questions: no_of_questions, active: active, question_buckets: temp })
                })
                state.viewGroupQuestionBucket = formattedData
                state.loaded = false


            })
            .addCase(getALLGroups.rejected, (state: IViewGroupData) => {
                state.loaded = false

            });

        builder
            .addCase(getGroupQuestionAndAnswers.pending, (state: IViewGroupData) => {
                state.questionResponseLoader = true
            })
            .addCase(getGroupQuestionAndAnswers.fulfilled, (state: IViewGroupData, action: PayloadAction<any>) => {
                state.questAnsList = action.payload.response.data
                state.questAnsCount = action.payload.response.count
                state.questionResponseLoader = false
            })
            .addCase(getGroupQuestionAndAnswers.rejected, (state: IViewGroupData) => {
                state.questionResponseLoader = false
            });

        builder
            .addCase(combineQuestionBuckets.pending, (state: IViewGroupData) => {
            })
            .addCase(combineQuestionBuckets.fulfilled, (state: IViewGroupData, action: PayloadAction<any>) => {
            })
            .addCase(combineQuestionBuckets.rejected, (state: IViewGroupData) => { });

        builder
            .addCase(deleteQuestionBuckets.pending, (state: IViewGroupData) => {
                state.viewBucketsLoader = true
            })
            .addCase(deleteQuestionBuckets.fulfilled, (state: IViewGroupData, action: PayloadAction<any>) => {
                let data = state.viewGroupQuestionBucket.filter((item: any) => !includesValue(item.display_name, action.payload.display_names))
                state.viewGroupQuestionBucket = data
                state.vgQuestionBucks = data
                state.delBucketSuccess = true
                state.viewBucketsLoader = false
            })
            .addCase(deleteQuestionBuckets.rejected, (state: IViewGroupData) => {
                state.viewBucketsLoader = false
            });
        builder
            .addCase(excludeQuestionAns.pending, (state: IViewGroupData) => {

            })
            .addCase(excludeQuestionAns.fulfilled, (state: IViewGroupData, action: PayloadAction<any>) => {
                let temp = state.questAnsList.filter((item: any) => !includesValue(item?.question_id, action.payload.question_ids))
                state.questAnsList = temp
                state.makeExcludeSuccess = action.payload.response.status
            })
            .addCase(excludeQuestionAns.rejected, (state: IViewGroupData) => {
                // state.viewBucketsLoader=false
            })
        builder
            .addCase(excludeAllUngrouped.pending, (state: IViewGroupData) => { })
            .addCase(excludeAllUngrouped.fulfilled, (state: IViewGroupData, action: PayloadAction<any>) => {
                state.excludeMakeUngrouped = action.payload.response.status
            })
            .addCase(excludeAllUngrouped.rejected, (state: IViewGroupData) => { })
        builder
            .addCase(makeUngroupedBuckets.pending, (state: IViewGroupData) => { })
            .addCase(makeUngroupedBuckets.fulfilled, (state: IViewGroupData, action: PayloadAction<any>) => {
                state.excludeMakeUngrouped = action.payload.response.status
            })
            .addCase(makeUngroupedBuckets.rejected, (state: IViewGroupData) => { })
        builder
            .addCase(getViewGrpQbs.pending, (state: IViewGroupData, action: PayloadAction<any>) => {
            })
            .addCase(getViewGrpQbs.fulfilled, (state: IViewGroupData, action: PayloadAction<any>) => {
                let data = action.payload.response.data
                let displayNames: any = []
                let formattedData: any = []
                data.map((item: any) => {
                    if (!includesValue(item.display_name, displayNames)) {
                        displayNames.push(item.display_name)
                    }
                })
                displayNames.map((item: any) => {
                    let phrase_count = 0
                    let question_count = 0
                    let active = true
                    let temp = data.filter((elem: any) => elem.display_name === item)
                        .map((bub: any) => {
                            phrase_count += bub.phrase_count
                            active = bub.active
                            question_count += bub.question_count
                            return {
                                question_bucket_id: bub.question_bucket_id
                            }
                        })
                    formattedData.push({ display_name: item, question_buckets: temp, question_count: question_count })
                })
                state.vgQuestionBucks = formattedData
            })
            .addCase(getViewGrpQbs.rejected, (state: IViewGroupData, action: PayloadAction<any>) => {
            });
        builder
            .addCase(vgAddQBucket.pending, (state: IViewGroupData, action: PayloadAction<any>) => {
            })
            .addCase(vgAddQBucket.fulfilled, (state: IViewGroupData, action: PayloadAction<any>) => {
                if (action.payload.response.status == true) {
                    let data1 = JSON.parse(JSON.stringify(state.vgQuestionBucks))
                    let data2 = JSON.parse(JSON.stringify(state.viewGroupQuestionBucket))
                    data1.push({
                        "display_name": action.payload.question_bucket_name,
                        "question_count": 0,
                        "question_buckets": [
                            {
                                "question_bucket_id": action.payload.response.data
                            }
                        ]
                    })
                    state.vgQuestionBucks = data1

                    data2.push({
                        display_name: action.payload.question_bucket_name,
                        no_of_questions: 0,
                        active: true,
                        question_buckets: [{ question_bucket_name: action.payload.question_bucket_name, question_group_id: action.payload.response.data }]
                    })
                    state.viewGroupQuestionBucket = data2
                }
                if (action.payload.response.status == false) {
                    //state.addQuestBucSuccessMessage = true
                }
            })
            .addCase(vgAddQBucket.rejected, (state: IViewGroupData, action: PayloadAction<any>) => {
            });
    }
})
export const {
    updateViewGroupQuestionBucket,
    updateExMakeSuccess,
    updateMakeExcludeSuccess,
    updateDelBucketSuccess,
    makeGroupLoader
} = viewGroupDataSlice.actions
export default viewGroupDataSlice.reducer;