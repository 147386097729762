import { Search, X } from "react-feather"
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Spinner from "../../components/Spinner";
import { Button, CircularProgress, Pagination, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, Popover, Select, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { RootState, useAppDispatch } from "../../redux/store";
import { getSentenceContext } from "../../redux/Themes/action";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import AttrMultiSelect from "../MultiSelectDropdown/AttrMultiSelect";
import { getSentenceMatrix, updateBubbleBookMark } from "../../redux/focus/action";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import BasicSwitch from "../BasicSwitch";
import { updateSelectedView } from "../../redux/Themes/themesDataReducer";
import { updateSelectedAttr } from "../../redux/Masters/masterDataReducer";
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

interface ISentenceView {
    dataList: Array<any>
    selectedFilter: string
    setSelectedFilter: any
    showLoader: boolean
    selectedSentiment: string
    handlePPTSelection: any
    larger: boolean
    filterSenti: string
    setFilterSenti: any
    lightningOnly: boolean
    setLightningOnly: any
    allTranscripts?: Array<any>
    jumpTo?: string
    setJumpTo?: any
    handleSearchSentence?: any
    sentenceMatrix: Array<any>
    sentenceCount?: any
    handlePagination?: any
    selectedPage?: any
    selectedQuestionB: Array<any>
    setIsTopOrAll?: any
    isTopOrAll?: any
    sentenceBubbleData?: any
    handleBubbleSelection?: any
    selectedBubble?: any
}

export default function SentenceView(props: ISentenceView) {

    const dispatch = useAppDispatch()
    const pathname = useLocation().pathname;
    const projectId = pathname.split('/')[2];

    const contextLoader = useSelector((state: RootState) => state.themes.contextLoader)
    const sentenceContext = useSelector((state: RootState) => state.themes.sentenceContext)
    const matrixLoader = useSelector((state: RootState) => state.focus.matrixLoader)
    const isStarCount = useSelector((state: RootState) => state.themes.isStarCount)
    const bubbleSentenceLoader = useSelector((state: RootState) => state.focus.bubbleSentenceLoader)
    const selectedViewRedux = useSelector((state: RootState) => state.themes.selectedView)

    const [searchSentence, setSearchSentence] = useState<string>("")
    const [sentencesHeight, setSentencesHeight] = useState<number>(0)
    const sentencesRef = useRef<any>(null)
    const [checked, setChecked] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [showDrillDown, setShowDrillDown] = useState<boolean>(false)
    const [selectedView, setSelectedView] = useState(selectedViewRedux);
    const [showTooltip, setShowTooltip] = useState(false);
    const [bookmarkCount, setBookmarkCount] = useState(0);





    const getMorePhrase = (sentence: any) => {
        let phrase = JSON.parse(sentence?.phrases)
        let data = []
        phrase = _.sortBy(phrase, [(item) => item.start_index])
        if (phrase?.length > 1) {
            data?.push({ phrase: sentence.sentence.slice(0, phrase[0].start_index), highlight: false })
            for (let i = 0; i < phrase.length - 1; i++) {
                if (phrase[i].end_index > phrase[i + 1].start_index) {
                    data?.push({
                        phrase: sentence.sentence.slice(phrase[i].start_index, phrase[i + 1].start_index),
                        highlight: true,
                        sentiment: phrase[i].sentiment
                    })
                }
                else {
                    data?.push({
                        phrase: sentence.sentence.slice(phrase[i].start_index, phrase[i].end_index),
                        highlight: true,
                        sentiment: phrase[i].sentiment
                    })
                    data.push({
                        phrase: sentence.sentence.slice(phrase[i].end_index, phrase[i + 1].start_index),
                        highlight: false,
                    })
                }
            }
            data.push({
                phrase: sentence.sentence.slice(phrase[phrase.length - 1].start_index, phrase[phrase.length - 1].end_index),
                highlight: true,
                sentiment: phrase[phrase.length - 1].sentiment
            })
            data.push({ phrase: sentence.sentence.slice(phrase[phrase.length - 1].end_index), highlight: false })
        }
        else {
            data.push({
                phrase: sentence.sentence.slice(0, phrase[0].start_index),
                highlight: false,
            })
            data.push({
                phrase: sentence.sentence.slice(phrase[0].start_index, phrase[0].end_index),
                highlight: true,
                sentiment: phrase[0].sentiment
            })
            data.push({
                phrase: sentence.sentence.slice(phrase[0].end_index),
                highlight: false,
            })
        }
        return data
    }
    useEffect(() => {
        dispatch(updateSelectedView(selectedView))
    }, [selectedView])
    const getColor = (sentiment: string) => {
        switch (sentiment) {
            case "Positive":
                if (props.selectedSentiment === "Positive" || props.selectedSentiment === "Overall") {
                    return "bg-green-100 border border-green-600 rounded-sm";
                } return ""
            case "Negative":
                if (props.selectedSentiment === "Negative" || props.selectedSentiment === "Overall") {
                    return "bg-red-100 border border-red-600 rounded-sm";
                } return ""
            case "Neutral":
                if (props.selectedSentiment === "Neutral" || props.selectedSentiment === "Overall") {
                    return "bg-yellow-50 border border-yellow-600 rounded-sm";
                } return ""
        }
    }


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
        setAnchorEl(event.currentTarget);
        dispatch(getSentenceContext({ projectId: projectId, sentenceId: data.question_id }))
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTimeout(() =>
            setShowDrillDown(false), 200)
    };
    useEffect(() => {
        if (props.dataList.length > 0) {
            setBookmarkCount(isStarCount)
        }
    }, [isStarCount])

    useEffect(() => {
        const sentencesTopPos = sentencesRef.current?.getBoundingClientRect().top;
        setSentencesHeight(window.innerHeight - sentencesTopPos - 75);
    });

    // useEffect(() => {
    //     if (props.larger) {
    //         setChecked(true)
    //     }
    //     else {
    //         setChecked(false)
    //     }
    // }, [props.larger])
    useEffect(() => {
        setSearchSentence("")
        props.handleSearchSentence("")

    }, [props.selectedQuestionB])

    const handleDrillDown = () => {
        let qbId: any[] = [];
        let themeId: any[] = [];
        let entities: any[] = [];

        props.selectedQuestionB.filter((item: any) => {
            if (item.type === "Reporting Buckets") {
                qbId.push(...(item.data[0].id.map((item: any) => { return item.id })))

            } else if (item.type === "Themes") {
                themeId.push(...(item.data[0].id.map((item: any) => { return item.id })))
            } else {
                entities.push(...(item.data[0].id.map((item: any) => { return item.id })))
            }
        })
        dispatch(getSentenceMatrix({ projectId: projectId, questionBucketId: qbId, themesId: themeId, entityId: entities }))
    }

    const handleBubbleBookMark = (item: any, isBubbled: boolean, e: any) => {
        e.stopPropagation()
        let bucket_id: any = ''
        props.selectedQuestionB.map((item: any) => {
            bucket_id = item.data[0].id.map((item: any) => { return item.id })
        })
        dispatch(updateBubbleBookMark({ project_id: projectId, bucket_id, bubble_bucket_id: item.BUBBLE_BUCKET_ID, is_bookmarked: isBubbled }))
    }

    const scrollInto = (container: any) => {
        container?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    useEffect(() => {
        if (props.selectedBubble !== "") {
            const bubbleContainer: any = document.getElementById(props.selectedBubble.BUBBLE_BUCKET_ID)
            if (bubbleContainer) {
                scrollInto(bubbleContainer)
            }
        }
    }, [props.sentenceBubbleData])

    return (
        <>
            <div className="flex justify-between items-center px-1">
                <div className="flex justify-start gap-1 items-center">
                    <span className="text-lg font-semibold w-40">Sentences <span className="">({props.sentenceCount})</span></span>

                    {/* <div className={`ml-3 rounded border border-[#9747FF] px-3 py-1 text-sm ${props.isTopOrAll ? 'bg-[#EDE9FE]' : 'bg-[#FFFFFF]'} cursor-pointer font-normal`} onClick={() => props.setIsTopOrAll(true)}>Top</div>
                    <div className={`ml-3 rounded border border-[#9747FF] px-3 py-1 text-sm ${props.isTopOrAll ? 'bg-[#FFFFFF]' : 'bg-[#EDE9FE]'} cursor-pointer font-normal`} onClick={() => props.setIsTopOrAll(false)}>All</div> */}
                    <BasicSwitch checked={props.isTopOrAll} onChange={() => props.setIsTopOrAll(!props.isTopOrAll)} />
                    <span className="text-[13px] font-semibold">Top picks</span>
                </div>
                <div className="flex justify-start items-center mt-2">
                    <span
                        style={{ fontSize: "12px", fontFamily: 'Poppins, sans-serif', marginRight: "5px" }}
                        className="font-semibold  text-gray-400"
                    >
                        View By
                    </span>
                    <Select
                        variant="standard"
                        value={selectedView}
                        sx={{ height: "32px", fontSize: "13px", fontWeight: "700", textAlign: "left", fontFamily: '"Poppins", sans-serif', paddingTop: '1px' }}
                        onChange={(e: any) => {
                            props.setJumpTo("All")
                            dispatch(updateSelectedAttr([]))
                            setSelectedView(e.target.value)
                        }}
                        IconComponent={ExpandMoreIcon}
                        disableUnderline
                    >
                        <MenuItem value="Attributes" sx={{ fontFamily: '"Poppins", sans-serif' }}>Attributes</MenuItem>
                        <MenuItem value="Transcripts" sx={{ fontFamily: '"Poppins", sans-serif' }}>Transcripts</MenuItem>
                    </Select>

                    {selectedView === "Attributes" ? (<AttrMultiSelect />) : (<div className="flex justify-start items-center mx-2">
                        {/* <FormControl
                            sx={{
                                m: 1,
                                width: 180,
                                position: "right",
                                backgroundColor: "white",
                                fontFamily: '"Poppins", sans-serif'
                            }}
                            size="small"
                        > */}
                        {/* <InputLabel id="demo-multiple-checkbox-label" sx={{ fontFamily: '"Poppins", sans-serif'}}>Go to</InputLabel> */}
                        <span
                            style={{ fontSize: "12px", fontFamily: 'Poppins, sans-serif', marginRight: "2px" }}
                            className="font-semibold  text-gray-400"
                        >
                            Go to
                        </span>
                        <Tooltip open={showTooltip} title={props.allTranscripts?.filter((item: any) => item?.dataset_id === props.jumpTo)[0]?.dataset_name}>
                            <Select
                                variant="standard"
                                className="truncate max-w-[300px]  px-2"
                                value={props.jumpTo}
                                sx={{ height: "32px", fontSize: "13px", fontWeight: "700", textAlign: "left", fontFamily: '"Poppins", sans-serif', paddingTop: '1px' }}
                                // input={<OutlinedInput label="Go to" />}
                                onChange={(e: any) => props.setJumpTo(e.target.value)}
                                disableUnderline
                                IconComponent={ExpandMoreIcon}
                                MenuProps={{
                                    sx: {
                                        width: "400px",
                                        maxHeight: "400px",
                                        fontFamily: '"Poppins", sans-serif'
                                    }, onMouseEnter: () => setShowTooltip(false)
                                }}
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                            >
                                <MenuItem value={"All"}>All</MenuItem>
                                {
                                    props.allTranscripts?.map((item: any, i: number) => {
                                        return (
                                            <MenuItem className="flex flex-wrap break-all w-[360px]" key={i} sx={{ whiteSpace: "pre-line" }} value={item.dataset_id}>{item?.dataset_name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Tooltip>
                        {/* </FormControl> */}
                    </div>)}


                    {/* <FormControl
                            sx={{
                                m: 1,
                                width: 140,
                                position: "right",
                                backgroundColor: "white",
                                fontFamily: '"Poppins", sans-serif'
                            }}
                            size="small"
                        >
                            <InputLabel id="demo-multiple-checkbox-label" sx={{ fontFamily: '"Poppins", sans-serif'}}>Sort By</InputLabel> */}
                    <span
                        style={{ fontSize: "12px", fontFamily: 'Poppins, sans-serif', marginRight: "5px" }}
                        className="font-semibold  text-gray-400"
                    >
                        Sort By
                    </span>
                    <Select
                        variant="standard"
                        value={props.selectedFilter}
                        sx={{ height: "32px", fontSize: "13px", fontWeight: "700", textAlign: "left", fontFamily: '"Poppins", sans-serif', paddingTop: '1px' }}
                        // input={<OutlinedInput label="Go to" />}
                        onChange={(e: any) => props.setSelectedFilter(e.target.value)}
                        IconComponent={ExpandMoreIcon}
                        disableUnderline
                    >
                        <MenuItem value="tone_strength" sx={{ fontFamily: '"Poppins", sans-serif' }}>Emotions</MenuItem>
                        <MenuItem value="transcript_order" sx={{ fontFamily: '"Poppins", sans-serif' }}>Transcript Order</MenuItem>
                    </Select>
                    {/* </FormControl> */}

                </div>
            </div>
            <div className="sentences-component bg-white rounded-lg pb-3 shadow-md" ref={sentencesRef} style={{ height: sentencesHeight }}>
                <div>
                    <div className="flex justify-between items-center gap-2 px-3 py-1 border-b border-b-gray-300">
                        {(checked && !props.larger) ?
                            <div className="cursor-pointer" onClick={() => setChecked(!checked)}><Search size={16} /></div>
                            :
                            <div className="flex justify-start items-center gap-2 w-[96%]">
                                <Search size={18} />
                                <input
                                    className="focus:outline-none w-full"
                                    placeholder="Search Sentence"
                                    value={searchSentence}
                                    onChange={(e) => setSearchSentence(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            props.handleSearchSentence(searchSentence)
                                        }
                                    }}
                                />
                                {searchSentence.length > 0 &&
                                    <div className="cursor-pointer" onClick={() => {
                                        setSearchSentence("")
                                        props.handleSearchSentence("")
                                    }}
                                    ><X size={16} /></div>
                                }
                            </div>
                        }
                        <div className="flex justify-end items-center">
                            {/* <Collapse className="whitespace-nowrap" orientation="horizontal" in={checked}> */}
                            <div className='col-span-3 flex justify-end items-center gap-3 mr-2 text-sm'>
                                <div className="flex  items-center">
                                    {/* <Switch
                                            size="small"
                                            checked={props.lightningOnly}
                                            onChange={() => props.setLightningOnly(!props.lightningOnly)}
                                        /> */}
                                    <BasicSwitch checked={props.lightningOnly} onChange={() => props.setLightningOnly(!props.lightningOnly)}
                                    />

                                    {/* <StarIcon className={`${props.lightningOnly ? "text-yellow-500" : "text-gray-400"}`} /> */}
                                    {props.lightningOnly ? <TurnedInIcon className="text-[#9747FF]" /> : <BookmarkBorderIcon className="text-[#9747FF]" />}
                                    {/* <Bookmark  className={`${props.lightningOnly ? "text-yellow-500" : "text-gray-400"}`} fill={`${props.lightningOnly ? "#FFEB3B" : "white"}`} /> */}
                                </div>
                                {/* <div className='flex justify-start items-center border-2 border-green-400 bg-green-50 scale-80 cursor-pointer pr-1 py-0.5' style={{borderRadius: "3px"}} onClick={() => toggleSenti("Positive")}>
                                <Checkbox style={{ width: "20px", padding:"0" }} size='small' color='success' checked={props.filterSenti.some((item: any) => item === "Positive")}/>
                                <span className='font-semibold'>Positive</span>
                            </div>
                            <div className='flex justify-start items-center border-2 border-red-400 bg-red-50 scale-80 cursor-pointer pr-1 py-0.5' style={{borderRadius: "3px"}} onClick={() => toggleSenti("Negative")}>
                                <Checkbox style={{ width: "20px", padding:0 }} size='small' color='error' checked={props.filterSenti.some((item: any) => item === "Negative")}/>
                                <span className='font-semibold'>Negative</span>
                            </div>
                            <div className='flex justify-start items-center border-2 border-yellow-400 bg-yellow-50 scale-80 cursor-pointer pr-1 py-0.5' style={{borderRadius: "3px"}} onClick={() => toggleSenti("Neutral")}>
                                <Checkbox style={{ width: "20px", padding:0 }} size='small' color='warning' checked={props.filterSenti.some((item: any) => item === "Neutral")}/>
                                <span className='font-semibold'>Neutral</span>
                            </div> */}
                                {/* green:bg-[#e1ffd233] red: bg-[#f9eceb]  */}
                                {/* <div className="flex justify-start items-center text-sm"> */}
                                {/* <div className={`cursor-pointer   border rounded-l text-gray-600 ${props.filterSenti === "All" ? "bg-[#DFEDF5] border-[#0077B6] font-semibold" : " border-gray-400 "}`} onClick={() => props.setFilterSenti("All")}>
                                            <span className="px-3">All</span>
                                        </div>
                                        <div className={`cursor-pointer  border-t border-b text-gray-600 ${props.filterSenti === "Positive" ? " bg-green-100 border-[#52bb20] font-semibold border" : "border-gray-400 "}`} onClick={() => props.setFilterSenti("Positive")}>
                                            <span className="px-3">Positive</span>
                                        </div>
                                        <div className={`cursor-pointer border rounded-r text-gray-600 ${props.filterSenti === "Negative" ? " bg-red-100 border-[#f03a5b] font-semibold" : "border-gray-400 "}`} onClick={() => props.setFilterSenti("Negative")}>
                                            <span className="px-3">Negative</span>
                                        // </div> */}

                                <div className="flex justify-start items-center text-sm">
                                    <div className={`cursor-pointer border mr-2 rounded ${props.filterSenti === "All" ? "bg-[#9747FF] border-[#9747FF] text-[#FFFFFF]" : " bg-[#DAD3F5] border-[#9747FF]"}`} onClick={() => { props.setFilterSenti("All"); }}>
                                        <span className="px-3">All</span>
                                    </div>
                                    <div className={`cursor-pointer border rounded ${props.filterSenti === "Positive" ? "bg-[#52bb20] border-[#52bb20] text-[#FFFFFF]" : "bg-[#E7F7E8] border-[#52bb20]"}`} onClick={() => { props.setFilterSenti("Positive"); }}>
                                        <span className="px-3">Positive</span>
                                    </div>
                                    <div className={`cursor-pointer ml-2 rounded border  ${props.filterSenti === "Negative" ? " bg-[#F03A5B] border-[#F03A5B] text-[#FFFFFF]" : "bg-[#F9ECEB] border-[#F03A5B]"}`} onClick={() => { props.setFilterSenti("Negative"); }}>
                                        <span className="px-3">Negative</span>
                                    </div>
                                </div>
                            </div>
                            {/* </Collapse> */}
                            {/* <IconButton onClick={() => setChecked(!checked)}>{<TuneIcon className={`${checked ? "text-[#9747FF]" : ""}`} />}</IconButton> */}
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap justify-start items-start bg-violet-100 rounded-md mx-4 mt-2 h-[20%] overflow-y-auto">
                    {bubbleSentenceLoader ?
                        <div className="h-full col-span-4 w-full flex justify-center items-center">
                            <CircularProgress className="" size={"20px"} />
                        </div>
                        : (props.sentenceBubbleData && props.sentenceBubbleData.length > 0) &&
                            (props.sentenceBubbleData.filter((item: any) => item.IS_ACTIVE === true).length > 0) ? (
                            props.sentenceBubbleData?.map((item: any, index: any) => (
                                item?.IS_ACTIVE && (
                                    <div
                                        key={index}
                                        onClick={() => props.handleBubbleSelection(item)}
                                        className={`col-span-1 rounded-md p-2 mx-2 my-2  ${props.selectedBubble?.DISPLAY_NAME === item.DISPLAY_NAME ? 'bg-violet-300 border border-violet-800' : 'bg-violet-300/50'} flex justify-between items-center px-2 cursor-pointer`}
                                        id={item.BUBBLE_BUCKET_ID}
                                    >
                                        <div className="flex justify-start items-center ">
                                            <Tooltip title={item.BUBBLE_BUCKET}>
                                                <div className="  mr-2">
                                                    <p className="bg-violet-600 rounded-2xl h-6 w-6 border border-violet-900 text-white flex justify-center items-center">
                                                        {item?.BUBBLE_BUCKET ? item.BUBBLE_BUCKET[0] : ''}
                                                    </p>
                                                </div>
                                            </Tooltip>
                                            {/* <Tooltip title={item.DISPLAY_NAME}>
                <span className="pr-2 truncate max-w-[140px]">{item.DISPLAY_NAME}</span>
                </Tooltip> */}
                                            <span className="pr-2">{item.DISPLAY_NAME}</span>
                                        </div>
                                        {item.IS_BOOKMARKED ? (
                                            <TurnedInIcon
                                                onClick={(e: any) => handleBubbleBookMark(item, false, e)}
                                                className="text-violet-600"
                                            />
                                        ) : (
                                            <Tooltip title="Save to PPT">
                                                <BookmarkBorderIcon
                                                    onClick={(e: any) => handleBubbleBookMark(item, true, e)}
                                                    className="text-violet-600"
                                                />
                                            </Tooltip>
                                        )}
                                    </div>)
                            ))) : (
                            <div className="col-span-4 w-full h-full flex justify-center items-center text-md font-semibold text-gray-500">
                                {props.selectedQuestionB[0]?.type === "Reporting Buckets" ? (
                                    <p>No overlaps with Themes or Subtopics</p>
                                ) : props.selectedQuestionB[0]?.type === "Themes" ? (
                                    <p>No overlaps with Subtopics</p>
                                ) : (
                                    <p>No overlaps with Themes</p>
                                )}

                            </div>
                        )}
                </div>

                <div className="flex flex-col justify-between" style={{ height: "66%" }}>
                    {!props.showLoader ?
                        <>
                            <div className=" overflow-y-auto mt-2">

                                {(props.dataList.length > 0 ?
                                    <>
                                        {props.dataList.map((sentence: any, index: number) => {
                                            let data = getMorePhrase(sentence)
                                            return (
                                                <div key={index} className="sentence-item leading-relaxed rounded-sm m-2 p-3 text-left border border-gray-300" style={{ borderRadius: "3px" }}>
                                                    {/* <div>
                                        {data.map((phraseItem: any, i: number) => {
                                            return (
                                                <>
                                                    <span className={`${phraseItem.highlight ? getColor(phraseItem.sentiment) : ""}`}>{phraseItem.phrase}</span>
                                                </>
                                            )
                                        })}
                                    </div> */}
                                                    <div className="flex justify-between items-center mt-2">

                                                        <div>   {sentence?.sentence}</div>
                                                        {sentence.is_edited && (

                                                            <div className=" m cursor-pointer float-right">
                                                                <Tooltip title={"This sentence has been replaced, which may give rise to duplicates in the exported PPT"}>
                                                                    <IconButton size="small" sx={{ zIndex: '100' }} edge="end" style={{ left: "2px" }}>
                                                                        <ErrorOutlineOutlined />
                                                                    </IconButton>
                                                                </Tooltip>

                                                            </div>


                                                        )
                                                        }
                                                    </div>
                                                    <div className="flex justify-between items-center mt-2">
                                                        <div className=" flex justify-start items-center">
                                                            {/* <div className="px-3 py-1 text bg-blue-100 rounded-md text-sm">
                                                {sentence.speaker_name}

                                            </div> */}
                                                            <div className={`px-1 ml-1  text-gray-400 text rounded-md text-xs flex justify-start items-center`}>
                                                                <div className={`w-3 h-3 rounded-lg mr-2 ${sentence.sentiment === "Neutral" ? "bg-blue-600" : sentence.sentiment === "Positive" ? "bg-green-600" : "bg-red-600"}`}></div>
                                                                {sentence.sentiment}
                                                            </div>
                                                            {(sentence.sentiment !== sentence.original_sentiment) &&
                                                                <div className="cursor-pointer transform rotate-180">
                                                                    <Tooltip title={`Sentiment edited; Suggested: ${sentence.original_sentiment}`}>
                                                                        <ErrorOutlineOutlined className="text-gray-400 mb-0.5" fontSize="small" />
                                                                    </Tooltip>
                                                                </div>
                                                            }
                                                            <div>
                                                                <Tooltip title={<span style={{ whiteSpace: 'pre-line', fontSize: "11px" }}>
                                                                    {sentence.ATTRIBUTE_1 ? `ATTRIBUTE_1: ${sentence.ATTRIBUTE_1}` : null + sentence.ATTRIBUTE_2 ? `\nATTRIBUTE_2: ${sentence.ATTRIBUTE_2}` : null}
                                                                </span>} placement="top">
                                                                    <div className="w-fit ml-1.5 text-gray-400 py-1 px-1 rounded-md truncate text-xs cursor-pointer">
                                                                        {`${sentence.ATTRIBUTE_1 !== null ? sentence.ATTRIBUTE_1 : ""} ${sentence.ATTRIBUTE_2 !== null ? "," + sentence.ATTRIBUTE_2 : ""}`}
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                            {/* <Tooltip title={sentence?.tanscript_name?.length > 15 ? sentence?.tanscript_name : null }>
                                                <div className={`cursor-pointer px-3 w-[150px] truncate ml-1.5 py-1 text rounded-md text-sm bg-blue-100`}>
                                                    {sentence?.tanscript_name}
                                                </div>
                                            </Tooltip> */}
                                                        </div>


                                                        <div className="flex justify-start items-center gap-2">

                                                            <Button variant="text" className="text-blue-400 text-xs" sx={{ textTransform: "lowercase", padding: "0px" }} onClick={(e) => handleClick(e, sentence)}>+ see context</Button>
                                                            <div onClick={() => (sentence.show_in_ppt || bookmarkCount < 6) && props.handlePPTSelection(sentence)}>
                                                                {sentence.show_in_ppt ?
                                                                    <TurnedInIcon className="text-[#9747FF] cursor-pointer" />
                                                                    :
                                                                    <Tooltip title={bookmarkCount < 6 ? "Add to PPT" : `There’s only space for 6 saved sentences for your PPT. ​`}>
                                                                        <BookmarkBorderIcon className="text-[#9747FF] cursor-pointer" />
                                                                    </Tooltip>
                                                                }
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>


                                            )
                                        }
                                        )}

                                    </>
                                    :
                                    <div className="h-[50vh] flex justify-center items-center text-lg font-semibold text-gray-500"><span>No data available for selected combination</span></div>
                                )}

                            </div>
                            <div className="flex justify-center items-center py-1 ">
                                {props.sentenceCount > 10 && <Pagination
                                    count={Math.ceil(props.sentenceCount / 10)}
                                    shape="rounded"
                                    page={props.selectedPage}
                                    onChange={(e: any, value) => props.handlePagination(value)}
                                />}
                            </div>
                        </>
                        :
                        <div className="h-full w-full flex justify-center items-center">
                            <Spinner />
                        </div>}

                </div>

                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}

                    anchorOrigin={{
                        vertical: showDrillDown ? "bottom" : "top",
                        horizontal: showDrillDown ? "right" : "center"
                    }}
                    transformOrigin={{
                        vertical: showDrillDown ? "top" : "center",
                        horizontal: showDrillDown ? "center" : "right"
                    }}
                    PaperProps={{
                        style: {
                            width: "450px",
                            maxHeight: "500px",
                            borderRadius: "6px"
                        }
                    }}
                >
                    {(!showDrillDown) && (!contextLoader ?
                        <div className="p-3">
                            <div className="head w-full flex justify-between items-start">
                                <div className="text-base font-semibold">{sentenceContext[0]?.question_type_identifier} : {sentenceContext[0]?.question_text}</div>
                                <IconButton className="scale-90" style={{ padding: "0px" }} onClick={handleClose}><CancelRoundedIcon /></IconButton>
                            </div>
                            <div className="flex flex-col justify-start items-start mx-4">
                                {sentenceContext[0]?.responses.map((resp: any, i: number) => {
                                    return (
                                        <div>
                                            <span className="font-semibold">{resp?.response_type_identifier} : </span>
                                            <span>{resp?.response_text}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        :
                        <div className="h-full w-full flex justify-center items-center">
                            <Spinner height={20} />
                        </div>
                    )}
                    {showDrillDown &&
                        (matrixLoader ?
                            <div className="h-[230px] flex justify-center items-center">
                                <Spinner />
                            </div>
                            :
                            <TableContainer component={Paper} sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                <Table sx={{ minWidth: 400 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <span className="whitespace-nowrap font-semibold" style={{ fontFamily: '"Poppins", sans-serif' }}>
                                                    Transcript Name
                                                </span>
                                            </TableCell>
                                            <TableCell align="right">
                                                <span className="whitespace-nowrap font-semibold" style={{ fontFamily: '"Poppins", sans-serif' }}>
                                                    Sentence Count
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.sentenceMatrix.map((row: any) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <span style={{ fontFamily: '"Poppins", sans-serif' }}>{row.DATASET_NAME}</span>
                                                </TableCell>
                                                <TableCell align="center"><span style={{ fontFamily: '"Poppins", sans-serif' }}>{row.sentence_count}</span></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                    }
                </Popover>
            </div>
        </>
    )
}

