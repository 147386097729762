import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IQbQcData } from "./type";
import { ResetTranscript, addQuestionBucket, addQuestionBucketTranscript, assignQuestionBucket, assignQuestionBucketTranscript, getOverlappedAnalysis, deleteQuestionBucketTranscript, deleteQuestionBuckets, getAllQuestionBucket, getQBForSelected, getQuestionBuckets, getQuestionBucketsStaging, getTextIDs, getTextIDsTranscript, getTranscript, getUnMappedQB, renameQuestionBucket, renameQuestionBucketTranscript, submitQbQc, submitReprocessing, getSelectedQBFromSTG } from "./actions";

const initialState: IQbQcData = {
    speakersToggle: false,
    questionBucket: [],
    questionBucketLoader: false,
    addQuestBucSuccessMessage: false,
    delBucketSuccess: false,
    qBucket: [],
    allTranscript: [],
    selectedTranscripts: 'All',
    searchIdentifier: "",
    selectedQB: [],
    addRBBucket: false,
    selectedTextIds: [],
    transcriptLoader: false,
    QBForSelectedLoader: false,
    clickedPage: '',
    submitQbQcSuccess: false,
    selectedQBId: "",
    transparentLoader: false,
    initialSelectionDone: false,
    allQuestionBuckets: [],
    textIds: [],
    textIdLoader: false,
    questionBucketId: "",
    transparentTranscriptLoader: false,
    submissionError: "",
    selectedDatasetIds: [],
    submitSuccessData: {},
    submitLoader: false,
    leavePopup: false,
    submitLeave: false,
    checkSaveChanges: false,
    submitenable: false,
    UnMappedQB: [],
    resetTranscript: false,
    overlapAnalysisTopics: [],
    resetLoader: false,
    validateData: false,
    entitiesSelectedViewAll: {},
    entitiesSelectedL1L2: [],
    entitiesSelectedL2L3: []
}
const includesValue = (searchFor: string, array: Array<any>) => {
    for (let i = 0; i < array?.length; i++) {
        if (array[i] === searchFor) {
            return true
        }
    }
    return false
}

export const QbQcDataSlice = createSlice({
    name: "QbQcData",
    initialState: initialState,
    reducers: {
        updateSelectedQB: (state, action) => {
            state.selectedQBId = action.payload
        },
        updateSpeakersToggle: (state, action) => {
            state.speakersToggle = action.payload
        },
        updateSelectedT: (state, action) => {
            state.selectedTranscripts = action.payload;
        },
        updateSearchIdentifier: (state, action) => {
            state.searchIdentifier = action.payload
        },
        UpdateQBAdded: (state, action) => {
            state.addRBBucket = action.payload
        },
        UpdateTextIds: (state, action) => {
            state.selectedTextIds = action.payload
        },
        updateSubmitQbQcSuccess: (state, action) => {
            state.submitQbQcSuccess = action.payload
        },
        updateInitialSelectionDone: (state, action) => {
            state.initialSelectionDone = action.payload
        },
        updateSelectedDatasetIds: (state, action) => {
            state.selectedDatasetIds = action.payload
        },
        SubmitPreprocessing: (state, action) => {
            state.submitSuccessData = action.payload;
        },
        addingingdata: (state, action) => {
            state.checkSaveChanges = action.payload.isSaved;
            state.clickedPage = action.payload.page
        },
        updateLeavePop: (state, action) => {
            state.leavePopup = action.payload;
        },
        updateAfterSubmit: (state, action) => {
            state.submitenable = action.payload;
        },
        updateIsExpandAll: (state, action) => {
            state.allTranscript = action.payload;
        },
        updateValidateData: (state, action) => {
            state.validateData = action.payload
        },
        updateEntitiesSelectedViewAll: (state, action) => {
            state.entitiesSelectedViewAll = action.payload
        },
        updateEntitiesSelectedL1L2: (state, action) => {
            state.entitiesSelectedL1L2 = action.payload
        },
        updateEntitiesSelectedL2L3: (state, action) => {
            state.entitiesSelectedL2L3 = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitReprocessing.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.submitLoader = true
                state.submitLeave = false
            })
            .addCase(submitReprocessing.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                if (action.payload.response.status === true) {
                    state.submitSuccessData = action.payload.response.data
                }
                state.submitLoader = false
                state.submitLeave = true
            })
            .addCase(submitReprocessing.rejected, (state: IQbQcData) => {
                state.submitLoader = false
                state.submitLeave = false
            })
        builder
            .addCase(ResetTranscript.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.resetLoader = true
            })
            .addCase(ResetTranscript.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.allQuestionBuckets = action.payload.response.data
                state.resetTranscript = true
                state.resetLoader = false
                state.submitenable = false
            })
            .addCase(ResetTranscript.rejected, (state: IQbQcData) => {
                state.resetLoader = false
            })

        builder
            .addCase(getAllQuestionBucket.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.initialSelectionDone = false
                state.questionBucketLoader = true
                state.transcriptLoader = true

            })
            .addCase(getAllQuestionBucket.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.initialSelectionDone = false
                state.questionBucket = action.payload.response.data
                state.questionBucketLoader = false
            })
            .addCase(getAllQuestionBucket.rejected, (state: IQbQcData, action: PayloadAction<any>) => {
            })
        builder
            .addCase(addQuestionBucket.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.questionBucketLoader = true

            })
            .addCase(addQuestionBucket.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                if (action.payload.response.status === true) {
                    let data1 = JSON.parse(JSON.stringify(state.questionBucket))
                    data1.push({
                        "display_name": action.payload.question_bucket_name,
                        "question_bucket_id": action.payload.response.data,
                        "qc_added_bucket": "True",
                        "text_ids": [""]

                    })
                    state.questionBucket = data1
                }

                state.questionBucketLoader = false

            })
            .addCase(addQuestionBucket.rejected, (state: IQbQcData) => {
                state.questionBucketLoader = false
            })
        builder
            .addCase(addQuestionBucketTranscript.pending, (state: IQbQcData, { meta }) => {
                let data1 = JSON.parse(JSON.stringify(state.allQuestionBuckets))
                data1.push({
                    "display_name": meta.arg.question_bucket_name,
                    "question_bucket_id": "pending",
                    "qc_added_bucket": "True",
                    "text_ids": [""]
                })
                state.allQuestionBuckets = data1

            })
            .addCase(addQuestionBucketTranscript.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                if (action.payload.response.status === true) {

                    const index = state.allQuestionBuckets.findIndex(
                        (bucket: any) => bucket.question_bucket_id === "pending" && bucket.display_name === action.payload.question_bucket_name
                    );
                    if (index !== -1) {
                        state.allQuestionBuckets[index].question_bucket_id = action.payload.response.data;
                    }
                }
                state.leavePopup = true
                state.submitenable = true
            })
            .addCase(addQuestionBucketTranscript.rejected, (state: IQbQcData, action: PayloadAction<any>) => {
                state.allQuestionBuckets = state.allQuestionBuckets.filter(
                    (bucket: any) => bucket.display_name !== action.payload.question_bucket_name
                )
            })
        builder
            .addCase(deleteQuestionBuckets.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.questionBucketLoader = true;


            })
            .addCase(deleteQuestionBuckets.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.questionBucketLoader = false;
                let updateData = JSON.parse(JSON.stringify(state.questionBucket)).filter((item: any) => item.question_bucket_id !== action.payload.question_bucket_id[0]);
                state.questionBucket = updateData
                state.delBucketSuccess = true;
            })
            .addCase(deleteQuestionBuckets.rejected, (state: IQbQcData) => {
                state.delBucketSuccess = false;
            });

        builder
            .addCase(deleteQuestionBucketTranscript.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.delBucketSuccess = true
            })
            .addCase(deleteQuestionBucketTranscript.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                let question_bucket_ids = action.payload.question_bucket_id; // Assuming action.payload.question_bucket_ids is List<string>                
                state.allQuestionBuckets = state.allQuestionBuckets.filter(
                    (bucket: any) => !question_bucket_ids.includes(bucket.question_bucket_id)
                );
                state.delBucketSuccess = false;
                state.leavePopup = true;
                state.submitenable = true;
            })
            .addCase(deleteQuestionBucketTranscript.rejected, (state: IQbQcData) => {
                state.delBucketSuccess = false

            });

        builder
            .addCase(renameQuestionBucket.pending, (state: IQbQcData, { meta }) => {
                let { question_bucket_id, new_name } = meta.arg
                // state.questionBucketLoader=true

                let UpdatedQBList = state.questionBucket.map((Qb) => {
                    if (Qb.question_bucket_id === question_bucket_id) {
                        return { ...Qb, display_name: new_name }
                    } else {
                        return { ...Qb }
                    }
                })
                state.questionBucket = UpdatedQBList
            })
            .addCase(renameQuestionBucket.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.questionBucketLoader = false
                // state.questionBucket = action.payload.data
            })
            .addCase(renameQuestionBucket.rejected, (state: IQbQcData, { meta }) => {
                state.questionBucketLoader = false
                let { question_bucket_id, display_name } = meta.arg

                let UpdatedQBList = state.questionBucket.map((Qb) => {
                    if (Qb.question_bucket_id === question_bucket_id) {
                        return { ...Qb, display_name: display_name }
                    } else {
                        return { ...Qb }
                    }
                })
                state.questionBucket = UpdatedQBList

            })
        builder
            .addCase(renameQuestionBucketTranscript.pending, (state: IQbQcData, { meta }) => {
                let question_bucket_id = meta.arg.question_bucket_id
                let new_question_bucket_name = meta.arg.new_question_bucket_name
                const index = state.allQuestionBuckets.findIndex(
                    (bucket: any) => bucket.question_bucket_id === question_bucket_id
                );
                if (index !== -1) {
                    state.allQuestionBuckets[index].display_name = new_question_bucket_name;
                }
            })
            .addCase(renameQuestionBucketTranscript.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.leavePopup = true
                state.submitenable = true

            })
            .addCase(renameQuestionBucketTranscript.rejected, (state: IQbQcData, { meta }) => {
                let question_bucket_id = meta.arg.question_bucket_id
                let question_bucket_name = meta.arg.question_bucket_name
                const index = state.allQuestionBuckets.findIndex(
                    (bucket: any) => bucket.question_bucket_id === question_bucket_id
                );
                if (index !== -1) {
                    state.allQuestionBuckets[index].display_name = question_bucket_name;
                }
            })
        builder
            .addCase(getTranscript.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.transcriptLoader = true
            })
            .addCase(getTranscript.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                const update = action.payload.response.data.map((each: any) => {
                    each["isExpand"] = true;

                    if (JSON.parse(JSON.stringify(state.textIds)).includes(each.question_text_id)) {
                        each["isHighlight"] = true;
                    } else {
                        each["isHighlight"] = false;
                    }

                    // for (const [index, responseId] of each.responses.entries()) {
                    //     each.responses[index]["isExpand"] = true;

                    //   if (JSON.parse(JSON.stringify(state.textIds)).includes(responseId.response_text_id)) {
                    //     each.responses[index]["isHighlight"] = true;
                    //   } else {
                    //     each.responses[index]["isHighlight"] = false;
                    //   }
                    // }

                    return each;
                });


                state.allTranscript = update
                state.transcriptLoader = false

            })
            .addCase(getTranscript.rejected, (state: IQbQcData, action: PayloadAction<any>) => {
            })
        builder
            .addCase(assignQuestionBucket.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.transparentLoader = true
                state.addRBBucket = false
            })
            .addCase(assignQuestionBucket.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.transparentLoader = false
                state.addRBBucket = true
                const { exclude, remove, text_ids, bucket_type } = action.payload;

                if (exclude || remove) {
                    state.textIds = state.textIds.filter(
                        (id: any) => !text_ids.includes(id)
                    );
                } else if (!exclude && !remove && !(bucket_type === "ungrouped")) {
                    state.textIds.push(...text_ids);
                }
                if (bucket_type === "ungrouped") {
                    if (!action.payload.question_bucket_ids || action.payload.question_bucket_ids.length === 0) {
                        state.textIds.push(...text_ids);
                    } else if (exclude) {
                        state.textIds = state.textIds.filter((id: any) => !text_ids.includes(id));
                    } else if (action.payload.question_bucket_ids.includes(state.questionBucketId)) {
                        state.textIds?.push(...text_ids);
                    } else {
                        state.textIds = state.textIds.filter((textId: any) => !text_ids?.includes(textId));
                    }
                } else if (bucket_type === "excluded") {
                    if (remove) {
                        state.textIds = state.textIds.filter(
                            (id: any) => !text_ids.includes(id)
                        );
                    } else {
                        state.textIds.push(...text_ids)
                    }
                }
            })
            .addCase(assignQuestionBucket.rejected, (state: IQbQcData, action: PayloadAction<any>) => {
                state.addRBBucket = false
                state.transparentLoader = false
            })
        builder
            .addCase(assignQuestionBucketTranscript.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.transparentLoader = true
                state.addRBBucket = false
            })
            .addCase(assignQuestionBucketTranscript.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.transparentLoader = false
                state.submitenable = true
                state.leavePopup = true
                state.addRBBucket = true
                const { exclude, remove, text_ids, bucket_type } = action.payload;

                if (exclude || remove) {
                    state.textIds = state.textIds.filter(
                        (id: any) => !text_ids.includes(id)
                    );
                } else if (!exclude && !remove && !(bucket_type === "ungrouped")) {
                    state.textIds.push(...text_ids);
                }
                if (bucket_type === "ungrouped") {
                    if (!action.payload.question_bucket_ids || action.payload.question_bucket_ids.length === 0) {
                        state.textIds.push(...text_ids);
                    } else if (exclude) {
                        state.textIds = state.textIds.filter((id: any) => !text_ids.includes(id));
                    } else if (action.payload.question_bucket_ids.includes(state.questionBucketId)) {
                        state.textIds?.push(...text_ids);
                    } else {
                        state.textIds = state.textIds.filter((textId: any) => !text_ids?.includes(textId));
                    }
                } else if (bucket_type === "excluded") {
                    if (remove) {
                        state.textIds = state.textIds.filter(
                            (id: any) => !text_ids.includes(id)
                        );
                    } else {
                        state.textIds.push(...text_ids)
                    }
                }
            })
            .addCase(assignQuestionBucketTranscript.rejected, (state: IQbQcData, action: PayloadAction<any>) => {
                state.addRBBucket = false
                state.transparentLoader = false
                state.submitenable = false
            })
        builder
            .addCase(getQBForSelected.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.QBForSelectedLoader = true
            })
            .addCase(getQBForSelected.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.selectedQB = action.payload.response.data
                state.QBForSelectedLoader = true

            })
            .addCase(getQBForSelected.rejected, (state: IQbQcData, action: PayloadAction<any>) => {

            })
        builder
            .addCase(getSelectedQBFromSTG.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.QBForSelectedLoader = true
            })
            .addCase(getSelectedQBFromSTG.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.selectedQB = action.payload.response.data
                state.QBForSelectedLoader = true

            })
            .addCase(getSelectedQBFromSTG.rejected, (state: IQbQcData, action: PayloadAction<any>) => {

            })
        builder
            .addCase(submitQbQc.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.QBForSelectedLoader = true
            })
            .addCase(submitQbQc.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.submitQbQcSuccess = action.payload.response.data.status
                state.submissionError = action.payload.response.error
                state.QBForSelectedLoader = false
            })
            .addCase(submitQbQc.rejected, (state: IQbQcData, action: PayloadAction<any>) => {
                state.QBForSelectedLoader = false
            })
        builder
            .addCase(getQuestionBuckets.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.questionBucketLoader = true
                state.transcriptLoader = true
            })
            .addCase(getQuestionBuckets.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.allQuestionBuckets = action.payload.response.data
                state.questionBucketLoader = false
            })
            .addCase(getQuestionBuckets.rejected, (state: IQbQcData, action: PayloadAction<any>) => {
                state.questionBucketLoader = false

            })
        builder
            .addCase(getQuestionBucketsStaging.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.questionBucketLoader = true
                state.transcriptLoader = true
            })
            .addCase(getQuestionBucketsStaging.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.allQuestionBuckets = action.payload.response.data
                state.questionBucketLoader = false
            })
            .addCase(getQuestionBucketsStaging.rejected, (state: IQbQcData, action: PayloadAction<any>) => {
                state.questionBucketLoader = false

            })
        builder
            .addCase(getTextIDs.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.textIdLoader = true
            })
            .addCase(getTextIDs.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.textIds = action.payload.response.data
                state.textIdLoader = false
                state.questionBucketId = action.payload.question_bucket_id;
            })
            .addCase(getTextIDs.rejected, (state: IQbQcData, action: PayloadAction<any>) => {
                state.textIdLoader = false

            })
        builder
            .addCase(getTextIDsTranscript.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.textIdLoader = true
            })
            .addCase(getTextIDsTranscript.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.textIds = action.payload.response.data
                state.textIdLoader = false
                state.questionBucketId = action.payload.question_bucket_id;
            })
            .addCase(getTextIDsTranscript.rejected, (state: IQbQcData, action: PayloadAction<any>) => {
                state.textIdLoader = false

            })
        builder
            .addCase(getUnMappedQB.pending, (state: IQbQcData, action: PayloadAction<any>) => {
                state.submitLoader = true
            })
            .addCase(getUnMappedQB.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                state.submitLoader = false
                state.submitenable = true
                state.UnMappedQB = action.payload.response.data
            })
            .addCase(getUnMappedQB.rejected, (state: IQbQcData, action: PayloadAction<any>) => {
                state.submitLoader = false
                state.submitenable = false
            })
        builder
            .addCase(getOverlappedAnalysis.pending, (state: IQbQcData, action: PayloadAction<any>) => { })
            .addCase(getOverlappedAnalysis.fulfilled, (state: IQbQcData, action: PayloadAction<any>) => {
                const update = JSON.parse(JSON.stringify(state.allTranscript)).map((each: any) => {
                    each["showOverlap"] = action.payload.response.data.some((eachObj: any) => eachObj.hasOwnProperty(each.question_text_id));
                    // for (const [index, responseId] of each.responses.entries()) {                        
                    //      each.responses[index]["showOverlap"] = action.payload.response.data.some((eachObj:any)=>eachObj.hasOwnProperty(responseId.response_text_id));
                    // }
                    return each;
                });
                state.allTranscript = update
                state.overlapAnalysisTopics = action.payload.response.data

            })
            .addCase(getOverlappedAnalysis.rejected, (state: IQbQcData, action: PayloadAction<any>) => {
            })
    }
})
export const { updateSpeakersToggle, updateSelectedT, updateIsExpandAll, updateLeavePop, updateSearchIdentifier, UpdateQBAdded, UpdateTextIds, updateSubmitQbQcSuccess, updateSelectedQB, updateInitialSelectionDone, updateSelectedDatasetIds, SubmitPreprocessing, addingingdata, updateAfterSubmit, updateValidateData, updateEntitiesSelectedViewAll, updateEntitiesSelectedL1L2, updateEntitiesSelectedL2L3 } = QbQcDataSlice.actions;
export default QbQcDataSlice.reducer;
