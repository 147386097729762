import React, { useEffect, useRef, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_SortingState,
  type MRT_RowVirtualizer,
} from "material-react-table";
import { Merge_col } from "./Tables";
import Popup from "reactjs-popup";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { XCircle } from "react-feather";
import Select from "react-select";
import { MenuItem, TextField, Select as MuiSelect } from "@mui/material";
import {
  accountProjectList,
  getAllAccounts,
  mergeProject,
} from "../../redux/EditProject/action";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../redux/store";
import {
  setDestinationAccountName,
  setDestinationAccountId,
  setScopeUpdated,
} from "../../redux/EditProject/EditProjectReducer";
import { useSelector } from "react-redux";

interface OptionType {
  value: string;
  label: string;
}

interface SelectedProject {
  id: string;
  name: string;
}

interface ChangeThemeTypeProps {
  cell: any;
  row: any;
  column: any;
  onSelectionChange: (
    projectId: string,
    projectName: string,
    isSelected: boolean
  ) => void;
  selectedProjects: Array<SelectedProject>
}

const Merge = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Refs for storing table dimensions and virtualization
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);
  const heightRef = useRef<any>(null);

  const { pathname } = useLocation();
  const projectId = pathname.split("/")[2];
  const [projectData, setProjectData] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const destinationAccountName = useSelector(
    (state: RootState) => state.editProject.destinationAccountName
  );
  const [selectedValue, setselectedValue] = useState<any>(null)
  const AllAccountsData = useSelector(
    (state: RootState) => state.editProject.AllAccountsData
  );
  const destinationAccountId = useSelector(
    (state: RootState) => state.editProject.destinationAccountId
  );
  const [nameError, setNameError] = useState(false);
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(1);
  const [destinationProjectName, setDestinationProjectName] =
    React.useState<string>("");
  const [errorMessage, setErrorMessage] = useState<String>("");
  const [selectedProjects, setSelectedProjects] = useState<SelectedProject[]>(
    []
  );

  // Callback function to be passed to ChangeThemeType
  const handleSelectionChange = (
    projectId: string,
    projectName: string,
    isSelected: boolean
  ) => {
    dispatch(setScopeUpdated(true))
    setSelectedProjects((prev) => {
      // Check if the project is already selected
      const projectIndex = prev.findIndex(
        (project) => project.id === projectId
      );
      if (isSelected) {
        // Add the project if it's not already selected
        if (projectIndex === -1) {
          return [...prev, { id: projectId, name: projectName }];
        }
      } else {
        // Remove the project if it's deselected
        if (projectIndex !== -1) {
          return prev.filter((project) => project.id !== projectId);
        }
      }
      return prev; // Return the previous state if no changes were made
    });
  };

  const ChangeThemeType: React.FC<ChangeThemeTypeProps> = ({
    cell,
    row,
    column,
    onSelectionChange,
    selectedProjects
  }) => {
    const [value, setValue] = useState<string>(selectedProjects && selectedProjects.some((item: any) => item.id === row.original?.PROJECT_ID) ? "Yes" : "No");
    const dropDownData = ["Yes", "No"];

    useEffect(() => {
      const initialValue = selectedProjects && selectedProjects.some((item: any) => item.id === row.original?.PROJECT_ID) ? "Yes" : "No"
      if (initialValue !== value) {
        setValue(initialValue)
      }
    }, [])

    const onChange = (e: any) => {
      const currVal = e.target.value;
      setValue(currVal);
      onSelectionChange(
        row.original.PROJECT_ID,
        row.original.PROJECT_NAME,
        currVal === "Yes"
      );
    };

    return (
      <div className="w-full hover:bg-white transition-all">
        <MuiSelect
          value={value}
          onChange={onChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          className="w-full h-10"
          MenuProps={{
            style: {
              maxHeight: 400,
            },
          }}
          sx={{
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
            },
            ".MuiSvgIcon-root": {
              fill: "black !important",
            },
          }}
        >
          {dropDownData.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </MuiSelect>
      </div>
    );
  };

  const modifiedColumns = Merge_col.map((col: any) => {
    if (col.accessorKey === "SELECT_TO_MERGE") {
      return {
        ...col,
        Cell: (props: any) => (
          <ChangeThemeType
            {...props}
            selectedProjects={selectedProjects}
            onSelectionChange={handleSelectionChange}
          />
        ),
      };
    }
    return col;
  });

  useEffect(() => {
    const TopPos = heightRef.current?.getBoundingClientRect().top;
    const LeftPos = heightRef.current?.getBoundingClientRect().left;
    setHeight(window.innerHeight - TopPos - 60);
    setWidth(window.innerWidth - LeftPos - 20);
  });

  //handle input change for destination project name
  const handleChangeEnterName = (event: any) => {
    dispatch(setScopeUpdated(true))
    const value = event.target.value;
    const sanitizedValue = value.replace(/[^\w\s.[\]()*,&/-]/g, "");
    setDestinationProjectName(sanitizedValue);
    setNameError(value !== sanitizedValue);
    setErrorMessage("")
  };

  //fetch all accounts on load of page
  useEffect(() => {
    dispatch(getAllAccounts({}));
  }, []);

  //fetch project list and set selected projects
  useEffect(() => {
    dispatch(accountProjectList({ project_id: projectId })).then(
      (response: any) => {
        if (response?.payload?.response?.data) {
          const data: Array<any> = response?.payload?.response?.data.filter((item: any) => item?.PROJECT_ID !== projectId)
          const projectName = response?.payload?.response?.data.find((item: any) => item?.PROJECT_ID === projectId)['PROJECT_NAME']
          const tempData = data.map((item, index) => (
            { ...item, sl_no: index + 1 }
          ))
          setProjectData([...tempData])
          setSelectedProjects([{ name: projectName, id: projectId }])
        } else {
          console.log("No data received from accountProjectList action");
        }
      }
    );
    if (typeof window !== "undefined") {
      setIsLoading(false);
    }
  }, []);

  // Extract unique account names for Select options
  const uniqueOptionsMap = new Map();
  AllAccountsData?.forEach((each: any) => {
    uniqueOptionsMap.set(each.account_name, each.account_name);
  });

  const options = Array.from(uniqueOptionsMap).map(([value, label]) => ({
    value,
    label,
  }));


  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  const table = useMaterialReactTable({
    columns: modifiedColumns,
    data: projectData,
    defaultDisplayColumn: { enableResizing: true },
    enableBottomToolbar: false,
    enableColumnResizing: true,
    enableColumnVirtualization: true,
    enableGlobalFilterModes: true,
    enablePagination: false,
    enableColumnPinning: true,
    //enableRowNumbers: true,
    enableRowVirtualization: true,
    muiTableContainerProps: { sx: { maxHeight: height - 250 } },
    onSortingChange: setSorting,
    state: { isLoading, sorting },
    rowVirtualizerInstanceRef, //optional
    rowVirtualizerOptions: { overscan: 5 }, //optionally customize the row virtualizer
    columnVirtualizerOptions: { overscan: 2 },
    muiTableProps: {
      sx: {
        borderCollapse: "collapse",
        "& td": {
          border: "1px solid #e2e8f0",
          padding: "5px",
          height: "50px",
          fontSize: "1rem",
        },
      },
    },
    globalFilterFn: 'contains',
  });

  var projectNames = selectedProjects.map((project) => project.name);

  const handleMove = () => {
    setOpenPopup(true);
  };

  const handleProceedMerge = () => {
    dispatch(setScopeUpdated(false))
    const projectIds = selectedProjects.map((project) => project.id);
    dispatch(
      mergeProject({
        destination_account_id: destinationAccountId,
        destination_project_name: destinationProjectName,
        merge_project_ids: projectIds,
      })
    ).then((response: any) => {
      setErrorMessage(response.payload.response?.error)
      if (response.payload.response?.status) {
        navigate('/dashboard')
      }

    })

    setOpenPopup(false);
  };

  // Function to handle change in account selection
  const handleChange = (selectedOption: OptionType | null) => {
    dispatch(setScopeUpdated(true))
    const accountNameValue = selectedOption ? selectedOption.value : "";
    dispatch(setDestinationAccountName(accountNameValue));
    const selectedOrganisation = selectedOption?.value;

    const selectedAccount = AllAccountsData.find(
      (item: any) => item.account_name === selectedOrganisation
    );

    if (selectedAccount) {
      dispatch(setDestinationAccountId(selectedAccount.account_id));
    }
  };

  const moveButtonBackgroundColor =
    destinationAccountName && destinationProjectName.trim() && selectedProjects.length > 1
      ? "#9747ff"
      : "#808080";

  useEffect(() => {
    const selectedValue = options.find(option => option.value === destinationAccountName) || null;
    setselectedValue(selectedValue)
  }, [destinationAccountName]);

  return (
    <div
      className="shadow-md mx-1 "
      ref={heightRef}
      style={{ height: (height + 5), width: width }}
    >
      {projectData.length > 0 ? <MaterialReactTable table={table} /> : <div className="flex justify-center items-center bg-white" style={{ height: height - 250, width: width }}>No data available</div>}

      <div
        className="bg-white shadow-lg h-56
       rounded-lg mt-2 w-[670px]"
      >
        <h3 className=" bg-[#9747FF] text-md text-white font-semi mb-1  py-1 pl-2 rounded-t-lg ">
          Merge and move project to
        </h3>
        <div className="pl-4 pr-5">
          <div>
            <div className=" flex">
              <label
                htmlFor="destinationAccountName"
                className="block text-sm font-medium mt-4 text-gray-700"
              >
                Destination Account name*:{" "}
              </label>
              <Select
                id="destinationAccountName"
                value={selectedValue}
                onChange={handleChange}
                options={options}
                className=" bg-white mt-1 ml-4  text-md rounded-md w-[450px] dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                isSearchable={true}
                placeholder="Select an option"
              />
            </div>
            <div className=" flex mt-4">
              <label
                htmlFor="destinationProjectName"
                className="block text-sm mt-3 font-medium text-gray-700"
              >
                Destination Project name*:
              </label>
              <div className="ml-6">



                <TextField
                  className="w-[450px] shadow-md"
                  id="outlined-basic"
                  label="Enter Project Name"
                  variant="outlined"
                  size="small"
                  required={true}
                  sx={{
                    "& input:invalid + fieldset": {

                      borderWidth: 1,
                    },
                    "& .MuiInputBase-root": {
                      fontFamily: '"Poppins", sans-serif',
                      height: "40px",
                    },
                    "& #outlined-basic-label": {
                      fontFamily: '"Poppins", sans-serif',
                      zIndex: 0,
                    },
                  }}
                  helperText={
                    nameError === true ? "Special characters are not allowed" : errorMessage ? <span className="flex justify-end text-red-600 text-sm font-medium mr-2">{errorMessage}</span> : ""

                  }
                  value={destinationProjectName}
                  onChange={handleChangeEnterName}
                />
              </div>{" "}
            </div>
          </div>
          <div
            className={`flex justify-end ${(nameError === true || errorMessage != "") ? "mt-7" : "mt-9"}`}
            style={{ transform: "translateY(-15px)" }}
          >
            <button
              className={`px-4 py-2 mr-1 rounded text-white`}
              style={{ backgroundColor: !(destinationAccountName && destinationProjectName.trim() && (selectedProjects.length > 1)) ? moveButtonBackgroundColor : "#9747ff" }}
              onClick={handleMove}
              disabled={!(destinationAccountName && destinationProjectName.trim() && (selectedProjects.length > 1))}
            >
              Merge and move
            </button>
          </div>
        </div>

        <Popup
          position="bottom left"
          modal
          open={openPopup}
          contentStyle={{
            width: "34vw",
            boxShadow: "0px 0px 1px white",
            backgroundColor: "white",
            borderRadius: "6px",
          }}
        >
          <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
            <div className="flex justify-between items-center w-full mb-6">
              <p className="font-base text-lg  flex items-center pl-10 pr-10">
                <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
              </p>
              <XCircle
                className="mr-6"
                size={24}
                style={{ cursor: "pointer" }}
                fill="#BDBCBC"
                color="#FFFF"
                onClick={() => {
                  setOpenPopup(false);
                }}
              />
            </div>

            <span
              className="font-base pb-6 -2 pl-11 pr-10"
              style={{ fontSize: "12px", color: "#545454" }}
            >
              Please note that you are merging following projects and moving the merged project to {" "}
              {destinationAccountName} account as {destinationProjectName}.
            </span>
            <ul className="list-disc pl-14 pr-10">
              {projectNames.map((name, index) => (
                <li key={index} style={{ fontSize: "12px", color: "#545454" }}>
                  {name}
                </li>
              ))}
            </ul>
            <div
              className=" mt-5 flex justify-between items-center ml-10 mr-10"
              style={{ width: "90%" }}
            >
              <button
                style={{ color: " #8088A4", border: "none" }}
                onClick={() => {
                  setOpenPopup(false);
                }}
              >
                Cancel
              </button>

              <button
                onClick={handleProceedMerge}
                className="border w-48 p-2 rounded"
                style={{ border: "1px solid red", color: "red" }}
              >
                Proceed
              </button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
};

export default Merge;
