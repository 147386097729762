import { axiosconfig } from "../../service/apiconfig";

export const ThemesAPI = {
    getThemes: ({project_id, sentiment, question_bucket_id} : any) => {
        let params:any = {}
        if (sentiment){
            params.type = sentiment
        }
        if (question_bucket_id){
            params.question_bucket_id = question_bucket_id
        }
        return axiosconfig.get(`projects/${project_id}/theme`, {params: params})
    },
    getThemeSentences: ({project_id, theme_id, bucket_type,order_by, question_bucket_id, transcript_id,entity_ids, included_in_ppt_only, sentiment, search,offset,limit, attributes, top_sentence, source}: any) => {
        let params:any = {}
        params.bucket_type = bucket_type
        if (theme_id){
            params.theme_id = theme_id
        }
        if (question_bucket_id){
            params.question_bucket_id = question_bucket_id
        }
        if (entity_ids){
            params.entity_ids = entity_ids
        }
        if(sentiment){
            params.sentiment = [sentiment]
        }
        if(search){
            params.search_sentence = search
        }
        if(attributes){
            params.attributes = attributes
        }
        if(transcript_id !== "All"){
            return axiosconfig.post(`projects/${project_id}/sentences_v3?order_by=${order_by}&offset=${offset??0}&limit=${limit??10000}&transcript_id=${transcript_id}&included_in_ppt_only=${included_in_ppt_only}&top_sentence=${top_sentence}`,
            params, {cancelToken: source.token})
        }
        else{
            return axiosconfig.post(`projects/${project_id}/sentences_v3?order_by=${order_by}&offset=${offset??0}&limit=${limit??10000}&included_in_ppt_only=${included_in_ppt_only}&top_sentence=${top_sentence}`,
            params, {cancelToken: source.token})
        }
    },
    getViewByCount: ({project_id, theme_id,bucket_type, question_bucket_id, transcript_id,entity_ids, attributes, source}: any) => {
        let params:any = {}
        params.bucket_type = bucket_type
        if (theme_id){
            params.theme_id = theme_id
        }
        if (question_bucket_id){
            params.question_bucket_id = question_bucket_id
        }
        if (entity_ids){
            params.entity_ids = entity_ids
        }
        if(attributes){
            params.attributes = attributes
        }
        if(transcript_id !== "All"){
            return axiosconfig.post(`projects/${project_id}/viewby_count?transcript_id=${transcript_id}`, params, {cancelToken: source.token})
        }
        else{
            return axiosconfig.post(`projects/${project_id}/viewby_count`, params, {cancelToken: source.token})
        }
    },
    mergeThemes: ({projectId, payload}:any) => {
        return axiosconfig.post(`projects/${projectId}/focus/action=merge`, payload)
    },
    hideThemes: ({ payload}:any) => {
        return axiosconfig.post(`projects/{project_id}/focus/action=hide`, payload)
    },
    addingSentencesToPPT:({projectId,updatedPPTSelection}:any)=>{
        return axiosconfig.put(`projects/${projectId}/sentence/show_in_ppt`,updatedPPTSelection)
    },
    editedSentence:({projectId,sentenceId,updatedSentence,type}:any)=>{
        return axiosconfig.put(`projects/${projectId}/sentence/action=edit?sentence_id=${sentenceId}&sentence=${updatedSentence}&type=${type}`)
    },
    deleteSentence:({projectId,sentenceId,themeId}:any)=>{
        return axiosconfig.delete(`projects/${projectId}/sentence/action=delete?sentence_id=${sentenceId}&theme_id=${themeId}&is_delete=true`)
    },
    getSentenceContext: ({projectId, sentenceId}: any) => {
        return axiosconfig.post(`/projects/${projectId}/sentence/${sentenceId}/context?limit=30&offset=0`, {})
    },
    getTranscriptIndexes: ({projectId}: any) => {
        return axiosconfig.get(`/projects/${projectId}/transcript/index/order`)
    },
    getTranscriptBlocks: ({projectId, offset, limit}: any) => {
        return axiosconfig.get(`projects/${projectId}/theme/transcript_v2`, {params: {offset, limit}})
    },
    getOrderedTranscriptIds :({projectId}: any) => {
        return axiosconfig.get(`projects/${projectId}/theme/transcript_datasets`)
    },
    getTranscriptData: ({projectId, datasetId}: any) => {
        return axiosconfig.get(`projects/${projectId}/theme/transcript_v3`, {params: {dataset_id: datasetId}})
    }
}