import { useEffect, useRef, useState, useMemo } from "react";
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
    type MRT_SortingState,
} from 'material-react-table';
import { Autocomplete, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../redux/store";
import { getEntityMaster } from "../../redux/EditProject/action";
import { useSelector } from "react-redux";
import { setScopeUpdated } from "../../redux/EditProject/EditProjectReducer";
import Spinner from "../../components/Spinner";

export default function EntityMaster(props: any) {

    const projectId = useParams().id
    const dispatch = useAppDispatch()

    const entityMasterData = useSelector((state: RootState) => state.editProject.entityMasterData)
    const entityMasterLoaderGet = useSelector((state: RootState) => state.editProject.getEntityMasterLoader)
    const entityMasterLoaderUpdate = useSelector((state: RootState) => state.editProject.updateEntityMasterLoader)

    const [data, setData] = useState<any>([])
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [currentDataState, setCurrentDataState] = useState<any>([])
    const heightRef = useRef<any>(null);
    const [height, setHeight] = useState<number>(0);
    const [renames, setRenames] = useState<Array<string>>([])
    const [width, setWidth] = useState<number>(1);

    useEffect(() => {
        const TopPos = heightRef.current?.getBoundingClientRect().top;
        const LeftPos = heightRef.current?.getBoundingClientRect().left;
        setHeight(window.innerHeight - TopPos - 60);
        setWidth(window.innerWidth - LeftPos - 15);
    });

    useEffect(() => {
        dispatch(getEntityMaster({ project_id: projectId }))
    }, [])

    useEffect(() => {
        table.resetSorting(true)
        table.resetColumnFilters(true)
    }, [props.resetSortFilter])

    useEffect(() => {
        const tempEntityMasterData = entityMasterData.map((item, index) => (
            { ...item, sl_no: index + 1 }
        ))
        setData([...tempEntityMasterData])
        setCurrentDataState(entityMasterData.map((item: any) => {
            return {
                ...item,
                user_given: false
            }
        }))
        setRenames(entityMasterData.map((item: any) => ""))
    }, [entityMasterData])

    useEffect(() => {
        var enable = false
        currentDataState.map((item: any, index: any) => {
            if (item.delete_anchor) {
                enable = true
            }
            else if (item.rename_to && item.rename_to !== "") {
                enable = true
            }
            else if (item.merge_to && item.merge_to !== "") {
                enable = true
            }
            else if (item.move_to && item.move_to !== "") {
                enable = true
            }
            else if (item.is_user_defined !== data[index]?.is_user_defined) {
                enable = true
            }
        })
        props.handleResetEnable(enable)
        props.setEntityMaster(currentDataState)
    }, [currentDataState])

    function RenameEntityCol({ cell, row, column, setCurrentDataState }: any) {
        const [value, setValue] = useState(cell.getValue())

        const onChange = (e: any) => {
            setValue(e.target.value)
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "rename_to": e.target.value }
                return temp
            })
            row._valuesCache[column.id] = e.target.value
            props.handleResetEnable(true)
            dispatch(setScopeUpdated(true))
        }

        return (
            <input
                placeholder="Rename Anchor"
                className="p-2 w-full h-full border rounded bg-white"
                value={value as string}
                onChange={onChange}
            />
        )
    }

    function MergeToCol({ cell, row, table, setCurrentDataState, type }: any) {
        const [mergeTo, setMergeTo] = useState<string>(cell.getValue())
        let dropDownData: Array<string> = []

        if (type === "merge") {
            let my_type = row.original.type
            table.options?.data.forEach((item: any) => {
                if (item.type === my_type && cell.row.index !== item.ID - 1) {
                    dropDownData.push(item.anchor)
                }
            })
        }
        else if (type === "move") {
            let temp: Array<string> = []
            let my_type = row.original.type
            table.options?.data.forEach((item: any) => {
                if (item.type !== my_type) {
                    temp.push(item.type)
                }
            })
            const unique_types = new Set(temp)
            dropDownData = Array.from(unique_types)
        }

        function mergeChange(newVal: any) {
            setMergeTo(newVal);
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                if (type === "merge") {
                    temp[cell.row.index] = { ...temp[cell.row.index], "merge_to": newVal }
                    row._valuesCache['merge_to'] = newVal
                }
                else if (type === "move") {
                    temp[cell.row.index] = { ...temp[cell.row.index], "move_to": newVal }
                    row._valuesCache['move_to'] = newVal
                }
                return temp
            })
            props.handleResetEnable(true)
            dispatch(setScopeUpdated(true))
        }

        return (
            <div className="w-full hover:bg-white transition-all">
                <Autocomplete
                    value={mergeTo}
                    onChange={(e: any, newVal: any) => mergeChange(newVal)}
                    className="w-full"
                    options={dropDownData}
                    autoHighlight
                    getOptionLabel={(option: any) => option}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={type === "move" ? "Select Entity Type" : "Select Entity Anchor"}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                                style: { fontFamily: "'Poppins'" }
                            }}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'black',
                                },
                                '& .MuiInputBase-input .MuiOutlinedInput-input': {
                                    fontFamily: "'Poppins'"
                                }
                            }}
                        />
                    )}
                />
            </div>
        )
    }

    function DeleteRow({ cell, row, column, setCurrentDataState }: any) {
        const [value, setValue] = useState<boolean>(cell.getValue())

        function onDelete(e: any) {
            const newValue = !value
            setValue(newValue)
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "delete_anchor": newValue }
                return temp
            })
            row._valuesCache[column.id] = newValue
            props.handleResetEnable(true)
            dispatch(setScopeUpdated(true))
            // cell.row._valuesCache = {...cell.row._valuesCache, "delete_anchor": newValue}
        }

        return (
            <div className="w-full flex justify-center items-center">
                <input type="checkbox" className="h-5 w-5 accent-[#9747FF] transition-all cursor-pointer" checked={value === true} onClick={onDelete} />
            </div>
        )
    }

    function UserDefined({ cell, column, row, table, currentDataState, setCurrentDataState }: any) {
        // const dropDownData = ['Yes', 'No']
        const [value, setValue] = useState(cell.getValue())

        const onChange = (e: any) => {
            const currVal = e.target.checked
            setValue(currVal)
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "is_user_defined": e.target.checked, user_given: true }
                return temp
            })
            row._valuesCache[column.id] = e.target.checked
            props.handleResetEnable(true)
            dispatch(setScopeUpdated(true))
        }

        // useEffect(() => {
        //     row._valuesCache[column.id] = null
        // }, [entityMasterData])

        // const onChange = (e: any) => {
        //     const currVal = value ? null : true
        //     setValue(currVal)
        //     setCurrentDataState((prevState: any) => {
        //         let temp = [...prevState]
        //         temp[cell.row.index] = { ...temp[cell.row.index], "is_deleted": currVal }
        //         return temp
        //     })
        //     row._valuesCache[column.id] = currVal
        //     dispatch(setResetEnable(true))
        // }

        return (
            <div className="w-full flex justify-center items-center">
                <input type="checkbox" className="h-5 w-5 accent-[#9747FF] transition-all cursor-pointer" checked={value === true} onClick={onChange} />
            </div>
        )

        // return (
        //     <div className="w-full hover:bg-white transition-all">
        //         <Select
        //             value={value as string}
        //             onChange={onChange}
        //             inputProps={{ 'aria-label': 'Without label' }}
        //             className={`w-full h-10`}
        //             MenuProps={{
        //                 style: {
        //                     maxHeight: 400,
        //                 },
        //             }}
        //             sx={{
        //                 '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        //                     borderColor: 'black',
        //                 },
        //             }}
        //             renderValue={(selected: any) => (
        //                 <div className="grid grid-cols-5 w-full items-center">
        //                     <div className="col-span-1">
        //                         <div className={`h-[5px] w-[5px] rounded-full border-4 transition-all ${selected === 'No' ? 'border-[#F59E1B]' : 'border-green-500'}`} />
        //                     </div>
        //                     <div className="col-span-4">
        //                         {selected}
        //                     </div>
        //                 </div>
        //             )}
        //         >
        //             {dropDownData?.map((item: any) => (
        //                 <MenuItem value={item} >{item}</MenuItem>
        //             ))}
        //         </Select>
        //     </div>
        // )
    }


    function MergeToColFilter({ column, columnName, table }: any) {

        const [dropDownData, setDropDownData] = useState<any>([])
        const [value, setValue] = useState<any>('')
        const [open, setOpen] = useState(false)

        const onChange = (newValue: any) => {
            const currVal = newValue
            setValue(currVal)
            column.setFilterValue(currVal)
        }

        useEffect(() => {
            setDropDownData(Array.from(new Set(table?.getRowModel().rows?.map((item: any) => item?._valuesCache?.sl_no ? item?._valuesCache : item?.original)?.map((item: any) => item?.[`${columnName}`])?.filter((item: any) => item !== undefined && item !== null && item !== ''))))
        }, [table])

        const onFocus = () => {
            setOpen(true)
        }

        function OptionElement({ params }: any) {
            var ref = useRef<any>()
            const [visible, setVisible] = useState(false)
            const isVisible = useOnScreen(ref)

            useEffect(() => {
                if (isVisible) {
                    setVisible(true)
                }
            })

            return (
                <div {...params} ref={ref} className="p-2 border-b h-[40px] flex items-center cursor-pointer hover:bg-slate-100 transition-all">
                    {visible && <div className="truncate">{params.key}</div>}
                </div>
            )
        }

        return (
            <div className="w-full mt-1 hover:bg-white transition-all">
                <Autocomplete
                    open={open}
                    value={value}
                    onChange={(event: any, newValue: string | null) => {
                        onChange(newValue);
                    }}
                    onFocus={onFocus}
                    onBlur={() => setOpen(false)}
                    className="w-full"
                    options={dropDownData}
                    autoHighlight
                    getOptionLabel={(option: any) => option}
                    renderOption={(params) => <OptionElement params={params} />}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onChange={(event: any) => {
                                onChange(event.target.value);
                            }}
                            placeholder={`Select ${column.columnDef.header}`}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                                onKeyDown: (e) => {
                                    if (e.key === 'Enter') {
                                        setOpen(false)
                                        e.stopPropagation();
                                    }
                                },
                            }}
                            variant="standard"
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'black',
                                },
                            }}
                        />
                    )}
                />
            </div>
        )
    }


    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "sl_no",
                header: "Sl.No",
                size: 120,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'sl_no'} table={table} />
                ),
            },
            {
                accessorKey: 'type',
                header: 'Entity type',
                size: 140,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'type'} table={table} />
                ),
            },
            {
                accessorKey: 'anchor',
                header: 'Entity anchor',
                size: 150,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'anchor'} table={table} />
                ),
            },
            {
                accessorKey: 'rename_to',
                header: 'Rename Entity anchor',
                size: 190,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <RenameEntityCol cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'rename_to'} table={table} />
                ),
            },
            {
                accessorKey: 'sentence_count',
                header: 'Sentence count',
                size: 150,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'sentence_count'} table={table} />
                ),
            },
            {
                accessorKey: 'merge_to',
                header: 'Merge Entity anchor to',
                size: 190,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <MergeToCol cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} type={"merge"} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'merge_to'} table={table} />
                ),
            },
            {
                accessorKey: 'move_to',
                header: 'Move Entity anchor to',
                size: 190,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <MergeToCol cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} type={"move"} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'move_to'} table={table} />
                ),
            },
            {
                accessorKey: 'delete_anchor',
                header: 'Delete Entity anchor',
                size: 170,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <DeleteRow cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'delete_anchor'} table={table} />
                ),
            },
            {
                accessorKey: 'is_user_defined',
                header: 'User Defined',
                size: 150,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <UserDefined cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'is_user_defined'} table={table} />
                ),
            },
        ], []
    )

    const table = useMaterialReactTable({
        columns,
        data,
        defaultDisplayColumn: { enableResizing: true },
        enableBottomToolbar: false,
        enableColumnResizing: true,
        enableGlobalFilterModes: false,
        enablePagination: false,
        enableColumnPinning: true,
        enableStickyHeader: true,
        // enableRowNumbers: true,
        initialState: { sorting },
        // muiTableContainerProps: { sx: { maxHeight: height - 40 } },
        muiTableContainerProps: {
            sx: {
                maxHeight: height - 50,
                overflowY: 'auto',
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#CECDCD',
                    borderRadius: '0px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#F5F7FB',
                },
            },
        },
        muiTableProps: {
            sx: {
                borderCollapse: 'collapse',
                '& td': {
                    border: '1px solid #e2e8f0',
                    padding: '5px',
                    height: '50px',
                    fontSize: '1rem',
                    fontFamily: "'Poppins'"
                },
                '& th': {
                    fontSize: "1rem",
                    fontFamily: "'Poppins'"
                },

            },
        },
        globalFilterFn: 'contains',
    });

    return (
        <div
            className="mx-1 mr-1 w-full"
            ref={heightRef}
            style={{ height: height, width: width }}
        >
            {entityMasterLoaderUpdate && (
                <div
                    className="flex justify-center items-center overflow-hidden"
                    style={{
                        backgroundColor: "#8080801c",
                        position: "absolute",
                        height: "74%",
                        width: "93.8%",
                        zIndex: 1000,
                    }}
                >
                    <Spinner height={40} />
                </div>
            )}

            {entityMasterLoaderGet && data.length === 0 ? (
                <div className="flex justify-center items-center overflow-hidden w-full h-full rounded bg-white">
                    <Spinner height={40} />
                </div>
            ) : data.length > 0 ? (
                <MaterialReactTable table={table} />
            ) : (
                <div className="flex justify-center items-center overflow-hidden w-full h-full rounded bg-white text-lg">
                    No data available
                </div>
            )}
        </div>
    );
}

function useOnScreen(ref: any) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                setIntersecting(entry.isIntersecting);
            }),
        []
    );

    useEffect(() => {
        const currentRef = ref.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [observer, ref]);

    return isIntersecting;
}
