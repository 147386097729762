import { axiosconfig } from "../apiconfig";

export const CompareWordCloudAPI = {
  getSavedView: ({ project_id,is_compare_view }: any) => {
    return axiosconfig.get(`projects/${project_id}/saved_view?is_compare_view=${is_compare_view}`); 
  },
  addSavedView: (projectId:any, payload:any) => {
    return axiosconfig.post(`projects/${projectId}/saved_view`, payload);
  },
  deleteSavedView: ({ projectId, id }: any) => {
    return axiosconfig.delete(`projects/${projectId}/saved_view?id=${id}`);
  },
  getWordCloudData: (project_id: any, payload: any) => {
    return axiosconfig.post(`projects/${project_id}/compare_wordcloud`, payload);
  },
  getIntersectingAreas: (project_id: any, bucket_id : any) => {
    return axiosconfig.get(`projects/${project_id}/intersecting_areas?bucket_id=${bucket_id}`)
  }

};
