import { useEffect, useRef, useState, useMemo } from "react";
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
    type MRT_SortingState,
} from 'material-react-table';
import { Autocomplete, TextField } from "@mui/material";
import { RootState, useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { getEntityTypeData } from "../../redux/EditProject/action";
import { useParams } from "react-router";
import Spinner from "../../components/Spinner";
import { setScopeUpdated } from "../../redux/EditProject/EditProjectReducer";

interface IEntityType {
    setApplyChangesActive?: (value: boolean) => null,
    setResetActive?: (value: boolean) => null,
    resetChanges?: boolean
    setWarning?: any
    setResetSortFilter?: any
    resetSortFilter: any
    handleResetEnable: (value: boolean) => void,
}



export default function EntityType(props: IEntityType) {

    const heightRef = useRef<any>(null);
    const [height, setHeight] = useState<number>(0);

    const dispatch = useAppDispatch()
    const projectId = useParams().id;



    useEffect(() => {
        const TopPos = heightRef.current?.getBoundingClientRect().top;
        setHeight(window.innerHeight - TopPos - 60);
    });

    const [data, setData] = useState<any>([])
    const [sorting, setSorting] = useState<MRT_SortingState>([]);

    const [currentDataState, setCurrentDataState] = useState<any>([])

    const entityTypeData = useSelector((state: RootState) => state.editProject.entityTypeData)
    const updateEntityLoader = useSelector((state: RootState) => state.editProject.updateEntityTypeDataLoader)
    // const isResetEnabledButton = useSelector((state: RootState) => state.editProject.isResetEnabled);
    const getEntityLoader = useSelector((state: RootState) => state.editProject.entityTypeDataLoader)


    useEffect(() => {
        table.resetSorting(true)
        table.resetColumnFilters(true)
    }, [props.resetSortFilter])





    useEffect(() => {
        if (entityTypeData.length > 0) {
            const tempEntityTypeData = entityTypeData.map((item, index) => (
                { ...item, sl_no: index + 1 }
            ))
            setData([...tempEntityTypeData]);
            setCurrentDataState([...entityTypeData]);
        }
    }, [entityTypeData]);

    useEffect(() => {
        var enable = false
        currentDataState.map((item: any, index: any) => {
            if (item.delete_type) {
                enable = true
            }
            else if (item.rename_to && item.rename_to !== "") {
                enable = true
            }
            else if (item.merge_to && item.merge_to !== "") {
                enable = true
            }
        })
        props.handleResetEnable(enable)
        props.setWarning(currentDataState)
    }, [currentDataState])

    useEffect(() => {
        dispatch(getEntityTypeData({ project_id: projectId }))
    }, [])

    function RenameThemeCol({ row, column, cell, setCurrentDataState }: any) {
        const [value, setValue] = useState("")
        useEffect(() => {
            setValue(cell.getValue());
        }, [cell.getValue()])


        const onChange = (e: any) => {
            setValue(e.target.value)
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "rename_to": e.target.value }
                return temp
            })
            row._valuesCache[column.id] = e.target.value
            props.handleResetEnable(true)
            dispatch(setScopeUpdated(true))
        }

        return (
            <input
                placeholder="Rename Entity"
                className="p-2 w-full h-full border rounded bg-white"
                value={value as string}
                onChange={onChange}
            />
        )
    }

    function MergeToCol({ row, column, cell, table, setCurrentDataState }: any) {
        const [mergeTo, setMergeTo] = useState<string>(cell.getValue())
        useEffect(() => {
            setMergeTo(cell.getValue());
        }, [cell.getValue()])
        let dropDownData: Array<string> = []

        table.options?.data.forEach((item: any) => {
            if (cell.row.index !== item.ID - 1) {
                dropDownData.push(item.entity_type)
            }
        })

        function mergeChange(newVal: any) {
            setMergeTo(newVal);
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "merge_to": newVal }
                return temp
            })
            row._valuesCache[column.id] = newVal
            props.handleResetEnable(true)
            dispatch(setScopeUpdated(true))
        }

        return (
            <div className="w-full hover:bg-white transition-all">
                <Autocomplete
                    value={mergeTo}
                    onChange={(e: any, newVal: any) => mergeChange(newVal)}
                    className="w-full"
                    options={dropDownData}
                    autoHighlight
                    getOptionLabel={(option: any) => option}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            placeholder="Select Entity Type"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                                style: { fontFamily: "'Poppins'" }
                            }}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'black',
                                },
                                '& .MuiInputBase-input .MuiOutlinedInput-input': {
                                    fontFamily: "'Poppins'"
                                }
                            }}
                        />
                    )}
                />
            </div>
        )
    }

    function DeleteRow({ row, column, cell, setCurrentDataState }: any) {
        const [value, setValue] = useState<boolean>(cell.getValue())
        useEffect(() => {
            setValue(cell.getValue());
        }, [cell.getValue()])
        function onDelete(e: any) {
            const newValue = !value
            setValue(newValue)
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "delete_type": newValue }
                return temp
            })
            row._valuesCache[column.id] = newValue
            props.handleResetEnable(true)
            dispatch(setScopeUpdated(true))
            // cell.row._valuesCache = {...cell.row._valuesCache, delete_type: newValue}
        }

        return (
            <div className="w-full flex justify-center items-center">
                <input type="checkbox" className="h-5 w-5 accent-[#9747FF] transition-all cursor-pointer" checked={value === true} onClick={onDelete} />
            </div>
        )
    }

    function MergeToColFilter({ column, columnName, table }: any) {

        const [dropDownData, setDropDownData] = useState<any>([])
        const [value, setValue] = useState<any>('')
        const [open, setOpen] = useState(false)

        const onChange = (newValue: any) => {
            const currVal = newValue
            setValue(currVal)
            column.setFilterValue(currVal)
        }

        useEffect(() => {
            setDropDownData(Array.from(new Set(table?.getRowModel().rows?.map((item: any) => item?._valuesCache?.sl_no ? item?._valuesCache : item?.original)?.map((item: any) => item?.[`${columnName}`])?.filter((item: any) => item !== undefined && item !== null && item !== ''))))
        }, [table])

        const onFocus = () => {
            setOpen(true)
        }

        function OptionElement({ params }: any) {
            var ref = useRef<any>()
            const [visible, setVisible] = useState(false)
            const isVisible = useOnScreen(ref)

            useEffect(() => {
                if (isVisible) {
                    setVisible(true)
                }
            })

            return (
                <div {...params} ref={ref} className="p-2 border-b h-[40px] flex items-center cursor-pointer hover:bg-slate-100 transition-all">
                    {visible && <div className="truncate">{params.key}</div>}
                </div>
            )
        }

        return (
            <div className="w-full mt-1 hover:bg-white transition-all">
                <Autocomplete
                    open={open}
                    value={value}
                    onChange={(event: any, newValue: string | null) => {
                        onChange(newValue);
                    }}
                    onFocus={onFocus}
                    onBlur={() => setOpen(false)}
                    className="w-full"
                    options={dropDownData}
                    autoHighlight
                    getOptionLabel={(option: any) => option}
                    renderOption={(params) => <OptionElement params={params} />}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onChange={(event: any) => {
                                onChange(event.target.value);
                            }}
                            value={value}
                            placeholder={`Select ${column.columnDef.header}`}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                                onKeyDown: (e) => {
                                    if (e.key === 'Enter') {
                                        setOpen(false)
                                        e.stopPropagation();
                                    }
                                },
                            }}
                            variant="standard"
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'black',
                                },
                            }}
                        />
                    )}
                />
            </div>
        )
    }

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "sl_no",
                header: "Sl.No",
                size: 120,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'sl_no'} table={table} />
                ),
            },
            {
                accessorKey: 'entity_type',
                header: 'Entity type',
                size: 200,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'entity_type'} table={table} />
                ),
            },
            {
                accessorKey: 'rename_to',
                header: 'Rename Entity type',
                size: 220,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <RenameThemeCol cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'rename_to'} table={table} />
                ),
            },
            {
                accessorKey: 'delete_type',
                header: 'Delete Entity type',
                size: 200,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <DeleteRow cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'delete_type'} table={table} />
                ),
            },
            {
                accessorKey: 'merge_to',
                header: 'Merge Entity type',
                size: 250,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <MergeToCol cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'merge_to'} table={table} />
                ),
            },
        ], []
    )

    const table = useMaterialReactTable({
        columns,
        data,
        defaultDisplayColumn: { enableResizing: true },
        enableBottomToolbar: false,
        enableColumnResizing: true,
        enableGlobalFilterModes: false,
        enablePagination: false,
        enableColumnPinning: true,
        // enableRowNumbers: true,
        initialState: { sorting },
        // muiTableContainerProps: { sx: { maxHeight: height - 40 } },
        muiTableContainerProps: {
            sx: {
                maxHeight: height - 50,
                overflowY: 'auto',
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#CECDCD',
                    borderRadius: '0px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#F5F7FB',
                },
            },
        },
        muiTableProps: {
            sx: {
                borderCollapse: 'collapse',
                '& td': {
                    border: '1px solid #e2e8f0',
                    padding: '5px',
                    height: '50px',
                    fontSize: '1rem',
                    fontFamily: "'Poppins'",
                    color: "black"
                },
                '& th': {
                    fontSize: "1rem",
                    fontFamily: "'Poppins'"
                },
            },
        },
        globalFilterFn: 'contains',
    });

    return (
        <div className="mx-1 mr-1" ref={heightRef} style={{ height: height }}>
            {updateEntityLoader && (
                <div
                    className="flex justify-center items-center overflow-hidden"
                    style={{
                        backgroundColor: "#8080801c",
                        position: "absolute",
                        height: "74%",
                        width: "93.8%",
                        zIndex: 1000,
                    }}
                >
                    <Spinner height={40} />
                </div>
            )}

            {getEntityLoader ? (
                <div className="flex justify-center items-center overflow-hidden w-full h-full rounded bg-white">
                    <Spinner height={40} />
                </div>
            ) : data.length > 0 ? (
                <MaterialReactTable table={table} />
            ) : (
                <div className="flex justify-center items-center overflow-hidden w-full h-full rounded bg-white text-lg">
                    No data available
                </div>
            )}

            {/* {loading && data.length===0 ?
                <div
                    className="flex justify-center items-center overflow-hidden w-full h-full rounded bg-white"
                >
                    <Spinner height={40} />
                </div>
                :
                <MaterialReactTable table={table} />
            } */}
        </div>
    );
}


function useOnScreen(ref: any) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                setIntersecting(entry.isIntersecting);
            }),
        []
    );

    useEffect(() => {
        const currentRef = ref.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [observer, ref]);

    return isIntersecting;
}
