import { Button, Fade, Menu, Theme } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { RootState, useAppDispatch } from "../../redux/store";
import { getPendingDomains } from "../../redux/DomAccept/action";
import { useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import ConfirmApproval from "./confirmApproval";
import SeeDomains from "./SeeDomains";

export default function DomainApproval() {

    const dispatch = useAppDispatch()
    const getPendingLoader = useSelector((state: RootState) => state.domAccept.getPendingLoader)
    const pendingDomains = useSelector((state: RootState) => state.domAccept.pendingDomains)

    const [pdData, setPdData] = useState<Array<any>>([])
    const mainRef = useRef<any>(null)
    const [mainHeight, setMainHeight] = useState<number>(0)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)

    const handleClose = () => {
        setAnchorEl(null)
    }

    const setOutcome = (id: any, outcome: string) => {
        setPdData((prev: any) => {
            return prev.map((item: any) => {
                if (item.DOMAIN_ID === id) {
                    return { ...item, outcome: outcome }
                }
                else {
                    return item
                }
            })
        })
    }

    useEffect(() => {
        setPdData(pendingDomains.map((item: any) => {
            return { ...item, outcome: "" }
        }))
    }, [pendingDomains])

    useEffect(() => {
        dispatch(getPendingDomains({ some: "" }))
        setMainHeight(mainRef.current.clientHeight)
    }, [])

    return (
        <div className="w-full h-[86%]" ref={mainRef}>
            <div className="h-full w-[80%] m-auto mt-16 shadow-md rounded-md bg-white p-2">
                <div className="grid grid-cols-3 pt-2">
                    <div></div>
                    <h1 className="text-2xl text-center font-semibold">
                        <span className="border-b-2 px-1">DOMAIN SIGN-UPS</span>
                    </h1>
                    <div className="flex justify-end items-center pr-2">
                        <Button variant="outlined"
                            id="fade-button"
                            aria-controls={open ? 'fade-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(e: any) => setAnchorEl(e.currentTarget)}
                        >
                            <span className="capitalize" style={{ fontFamily: '"Poppins", sans-serif' }}>See Domains</span>
                        </Button>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center"
                            }}
                            sx={{
                                zIndex: 5
                            }}
                        >
                            <SeeDomains type="approved" handleClose={handleClose} />
                            <SeeDomains type="rejected" handleClose={handleClose} />
                        </Menu>
                    </div>
                </div>
                <div className="mt-7 border rounded-md overflow-y-auto" style={{ height: 0.80 * mainHeight }}>
                    <div className="bg-blue-50 border-b grid grid-cols-5 h-12">
                        <div className="col-span-2 flex justify-center items-center border-r font-semibold"><span>Company Name</span></div>
                        <div className="col-span-2 flex justify-center items-center border-r font-semibold"><span>Email Domain</span></div>
                        <div className="flex justify-center items-center font-semibold"><span>Approve / Reject</span></div>
                    </div>
                    {getPendingLoader ?
                        <div className="h-[85%] w-full flex justify-center items-center">
                            <Spinner />
                        </div>
                        :
                        (pendingDomains.length > 0 ?
                            pdData.map((item: any, index: number) => {
                                return (
                                    <div className={`${index % 2 !== 0 ? "bg-gray-100" : ""} border-b grid grid-cols-5 h-12`} key={index}>
                                        <div className="col-span-2 flex justify-center items-center border-r"><span>{item.COMPANY_NAME}</span></div>
                                        <div className="col-span-2 flex justify-center items-center border-r"><span>{item.DOMAIN_NAME}</span></div>
                                        <div className="flex justify-center items-center gap-1">
                                            {item.outcome !== "Accepted" ?
                                                <Button color="success" onClick={() => setOutcome(item.DOMAIN_ID, "Accepted")}><span style={{ fontFamily: '"Poppins", sans-serif' }}>Approve</span></Button>
                                                :
                                                <span onClick={() => setOutcome(item.DOMAIN_ID, "")} className="uppercase bg-green-400 px-2 py-1 text-white rounded-md cursor-pointer" style={{ fontFamily: '"Poppins", sans-serif', fontSize: "12.25px" }}>Approved</span>
                                            }
                                            {item.outcome !== "Rejected" ?
                                                <Button color="error" onClick={() => setOutcome(item.DOMAIN_ID, "Rejected")}><span style={{ fontFamily: '"Poppins", sans-serif' }}>Reject</span></Button>
                                                :
                                                <span onClick={() => setOutcome(item.DOMAIN_ID, "")} className="uppercase bg-red-400 px-2 py-1 text-white rounded-md cursor-pointer" style={{ fontFamily: '"Poppins", sans-serif', fontSize: "12.25px" }}>Rejected</span>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                            :
                            (
                                <div className="h-[85%] w-full flex justify-center items-center">
                                    <span className="text-xl font-semibold text-gray-400">No pending Domain requests</span>
                                </div>
                            )
                        )
                    }
                </div>
                <div className="flex justify-center items-center" style={{ height: 0.09 * mainHeight }}>
                    <ConfirmApproval dataList={pdData} />
                </div>
            </div>
        </div>
    )
}
