import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPaymentData } from "./type";
import { getIncludeQuestionCount, getExcludeQuestionCount, getTotalWordCount, getPaymentConfirm, getClientId, getPaymentStatus, getCredits, getFileSlpit } from "./action";


const initialState: IPaymentData = {
  getIncludeQuestionCount: [],
  getExcludeQuestionCount: [],
  getTotalWordCount: [],
  mappedData: {},
  mappedSuccess: false,
  clientIdAPISuccess: false,
  clientId: {},
  paymentLoader: [],
  paymentSuccessStatus: {},
  availableCredits: [],
  fileCostSplit: []
};

export const paymentDataSlice = createSlice({
  name: "PaymentData",
  initialState: initialState,
  reducers: {
    updateIncludeQuestionCount: (state, action) => {
      state.getIncludeQuestionCount = action.payload.response
    },
    updateExcludeQuestionCount: (state, action) => {
      state.getExcludeQuestionCount = action.payload.response
    },
    updateTotalWordCount: (state, action) => {
      state.getTotalWordCount = action.payload.response
    },
    updateMappedSuccess: (state, action) => {
      state.mappedSuccess = action.payload
    },
    updateClientIdAPISuccess: (state, action) => {
      state.clientIdAPISuccess = false
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getIncludeQuestionCount.pending, (state: IPaymentData) => { })
      .addCase(
        getIncludeQuestionCount.fulfilled,
        (state: IPaymentData, action: PayloadAction<any>) => {
          state.getIncludeQuestionCount = action.payload.response.data;
        }
      )
      .addCase(getIncludeQuestionCount.rejected, (state: IPaymentData) => { });

    builder
      .addCase(getExcludeQuestionCount.pending, (state: IPaymentData) => { })
      .addCase(
        getExcludeQuestionCount.fulfilled,
        (state: IPaymentData, action: PayloadAction<any>) => {
          state.getExcludeQuestionCount = action.payload.response.data;
        }
      )
      .addCase(getExcludeQuestionCount.rejected, (state: IPaymentData) => { });

    builder
      .addCase(getTotalWordCount.pending, (state: IPaymentData) => { })
      .addCase(
        getTotalWordCount.fulfilled,
        (state: IPaymentData, action: PayloadAction<any>) => {
          state.getTotalWordCount = action.payload.response.data;
        }
      )
      .addCase(getTotalWordCount.rejected, (state: IPaymentData) => { });
    builder
      .addCase(getPaymentConfirm.pending, (state: IPaymentData) => { })
      .addCase(
        getPaymentConfirm.fulfilled,
        (state: IPaymentData, action: PayloadAction<any>) => {
          state.mappedSuccess = true
          state.mappedData = action.payload.response
        }
      )
      .addCase(getPaymentConfirm.rejected, (state: IPaymentData) => { });
    builder
      .addCase(getClientId.pending, (state: IPaymentData) => { })
      .addCase(
        getClientId.fulfilled,
        (state: IPaymentData, action: PayloadAction<any>) => {
          state.clientIdAPISuccess = action.payload.response.status
          state.clientId = action.payload.response.data
        }
      )
      .addCase(getClientId.rejected, (state: IPaymentData) => { });
    builder
      .addCase(getPaymentStatus.pending, (state: IPaymentData) => {
        state.paymentLoader = true
      })
      .addCase(getPaymentStatus.fulfilled,
        (state: IPaymentData, action: PayloadAction<any>) => {
          state.paymentLoader = false
          state.paymentSuccessStatus = action.payload.response.data
        }
      )
      .addCase(getPaymentStatus.rejected, (state: IPaymentData) => {
        state.paymentLoader = false
      });
    builder
      .addCase(getCredits.pending, (state: IPaymentData) => { })
      .addCase(getCredits.fulfilled, (state: IPaymentData, action: PayloadAction<any>) => {
        state.availableCredits = action.payload.response.data
      })
      .addCase(getCredits.rejected, (state: IPaymentData) => { });
    builder
      .addCase(getFileSlpit.pending, (state: IPaymentData) => { })
      .addCase(getFileSlpit.fulfilled, (state: IPaymentData, action: PayloadAction<any>) => {
        state.fileCostSplit = action.payload.response.data
      })
      .addCase(getFileSlpit.rejected, (state: IPaymentData) => { });
  },
});

export const { updateIncludeQuestionCount, updateClientIdAPISuccess, updateExcludeQuestionCount, updateTotalWordCount, updateMappedSuccess } = paymentDataSlice.actions;

export default paymentDataSlice.reducer;
