import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import DownloadIcon from '@mui/icons-material/Download';
import { FaFilePen } from "react-icons/fa6";
import { Tooltip } from "@mui/material";
import BarChartIcon from '@mui/icons-material/BarChart';

export const placeholderText = `Describe the problem you are facing. \n\nMention when the issue started and whether it occurs consistently.\nIf you have received any error messages or specific indications of the issue, please share it with the team (with screenshots if possible). \n \nRemember never to share any confidential information with anyone!`;

export const bookingSlotPlaceHolder = `Let us know a preferred date and timeslot that you have in mind.
Mention your timezone too so that we're sure to arrange the correct slot for the meeting! \n
Someone from our team will be reaching out to you to confirm the slot soon after you submit the form (: \n
Remember never to share any confidential information with anyone!`;


export const categories = [
  { data: "Onboarding session" },
  { data: "Issues faced with my project" },
  { data: "Page is not loading/responding" },
  { data: "Others" },

];
export const pageData = [
  /* {
    link: "output",
    icon: <VisibilityOutlinedIcon />,
    nav_content: "Output",
    content: "Transcript Output"
  }, */
  //  {
  //    link: "themes",
  //    icon: <VisibilityOutlinedIcon />,
  //    nav_content: "Themes",
  //    content: "Themes"
  //  },
  {
    link: "query",
    icon: <Tooltip title={<span>AI assistant</span>} >
      <svg width="50" height="50" viewBox="0 0 36 11"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_450_1388" maskUnits="userSpaceOnUse" x="0" y="4" width="16" height="17" style={{ maskType: "alpha" }}>
          <rect y="4.36719" width="15.9272" height="15.9272"
            fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_450_1388)">
          <path d="M10.9498 16.9751C11.4143 16.9751 11.807 16.8147 12.1277 16.494C12.4485 16.1732 12.6089 15.7806 12.6089 15.316C12.6089 14.8515 12.4485 14.4588 12.1277 14.1381C11.807 13.8173 11.4143 13.657 10.9498 13.657C10.4852 13.657 10.0926 13.8173 9.77182 14.1381C9.45107 14.4588 9.29069 14.8515 9.29069 15.316C9.29069 15.7806 9.45107 16.1732 9.77182 16.494C10.0926 16.8147 10.4852 16.9751 10.9498 16.9751ZM14.3343 19.6297L12.5425 17.8544C12.3102 17.9982 12.0586 18.1088 11.7876 18.1863C11.5166 18.2637 11.2373 18.3024 10.9498 18.3024C10.1202 18.3024 9.41512 18.0121 8.83444 17.4314C8.25376 16.8507 7.96342 16.1456 7.96342 15.316C7.96342 14.4865 8.25376 13.7814 8.83444 13.2007C9.41512 12.62 10.1202 12.3297 10.9498 12.3297C11.7793 12.3297 12.4844 12.62 13.0651 13.2007C13.6458 13.7814 13.9361 14.4865 13.9361 15.316C13.9361 15.6147 13.8946 15.8995 13.8117 16.1705C13.7287 16.4415 13.6154 16.6931 13.4716 16.9254L15.2634 18.7006L14.3343 19.6297ZM3.31799 18.966C2.95299 18.966 2.64053 18.8361 2.38061 18.5761C2.12068 18.3162 1.99072 18.0038 1.99072 17.6388V7.02063C1.99072 6.65563 2.12068 6.34317 2.38061 6.08324C2.64053 5.82332 2.95299 5.69336 3.31799 5.69336H8.62705L12.6089 9.67516V11.3342C12.3434 11.2236 12.0724 11.1407 11.7959 11.0854C11.5194 11.0301 11.2373 11.0024 10.9498 11.0024C10.3304 11.0024 9.75247 11.1186 9.21603 11.3508C8.67959 11.5831 8.21781 11.9094 7.83069 12.3297H4.64526V13.657H6.96797C6.87949 13.8671 6.81036 14.0828 6.76059 14.304C6.71081 14.5252 6.67487 14.752 6.65275 14.9842H4.64526V16.3115H6.75229C6.87396 16.8645 7.09517 17.3705 7.41592 17.8296C7.73668 18.2886 8.14592 18.6674 8.64365 18.966H3.31799ZM7.96342 10.3388H11.2816L7.96342 7.02063V10.3388Z"
            fill="currentColor" />
        </g>
        <path d="M7.07861 1.06471C7.07861 0.476685 7.5553 0 8.14332 0H34.3289C34.9169 0 35.3936 0.476685 35.3936 1.06471V11.3231C35.3936 11.9111 34.9169 12.3878 34.3289 12.3878H8.14332C7.5553 12.3878 7.07861 11.9111 7.07861 11.3231V1.06471Z"
          fill="#9747FF" />
        <path d="M12.587 5.22048C12.7566 4.96926 12.989 4.76513 13.2842 4.60812C13.5856 4.4511 13.9279 4.37259 14.311 4.37259C14.757 4.37259 15.1589 4.4825 15.5169 4.70233C15.8812 4.92215 16.167 5.23618 16.3742 5.64443C16.5878 6.04639 16.6946 6.5143 16.6946 7.04815C16.6946 7.58201 16.5878 8.0562 16.3742 8.47072C16.167 8.87897 15.8812 9.19614 15.5169 9.42224C15.1589 9.64835 14.757 9.7614 14.311 9.7614C13.9216 9.7614 13.5793 9.68603 13.2842 9.5353C12.9952 9.37828 12.7629 9.1773 12.587 8.93235V9.67661H11.2681V2.70508H12.587V5.22048ZM15.3474 7.04815C15.3474 6.73412 15.2814 6.46405 15.1495 6.23795C15.0239 6.00556 14.8543 5.82971 14.6408 5.71037C14.4335 5.59104 14.2074 5.53137 13.9625 5.53137C13.7238 5.53137 13.4977 5.59418 13.2842 5.71979C13.0769 5.83913 12.9073 6.01499 12.7754 6.24737C12.6498 6.47975 12.587 6.75296 12.587 7.067C12.587 7.38103 12.6498 7.65424 12.7754 7.88662C12.9073 8.11901 13.0769 8.29801 13.2842 8.42362C13.4977 8.54295 13.7238 8.60262 13.9625 8.60262C14.2074 8.60262 14.4335 8.53981 14.6408 8.4142C14.8543 8.28858 15.0239 8.10959 15.1495 7.8772C15.2814 7.64482 15.3474 7.36847 15.3474 7.04815Z"
          fill="white" />
        <path d="M22.5048 6.95394C22.5048 7.14236 22.4922 7.31194 22.4671 7.46268H18.6516C18.683 7.83952 18.8149 8.13471 19.0473 8.34825C19.2797 8.56179 19.5655 8.66856 19.9046 8.66856C20.3945 8.66856 20.7431 8.45816 20.9503 8.03736H22.3729C22.2222 8.53981 21.9333 8.95434 21.5062 9.28093C21.0791 9.60124 20.5547 9.7614 19.9329 9.7614C19.4304 9.7614 18.9782 9.65149 18.5763 9.43166C18.1806 9.20556 17.8697 8.88839 17.6436 8.48014C17.4238 8.0719 17.3138 7.60085 17.3138 7.067C17.3138 6.52686 17.4238 6.05267 17.6436 5.64443C17.8634 5.23618 18.1711 4.92215 18.5668 4.70233C18.9625 4.4825 19.4179 4.37259 19.9329 4.37259C20.429 4.37259 20.8718 4.47936 21.2612 4.69291C21.6569 4.90645 21.9615 5.21106 22.1751 5.60674C22.3949 5.99614 22.5048 6.44521 22.5048 6.95394ZM21.1388 6.5771C21.1325 6.23795 21.01 5.96788 20.7713 5.7669C20.5327 5.55964 20.2406 5.45601 19.8952 5.45601C19.5686 5.45601 19.2922 5.5565 19.0661 5.75748C18.8463 5.95218 18.7113 6.22539 18.661 6.5771H21.1388Z"
          fill="white" />
        <path d="M25.0031 5.5408V8.06562C25.0031 8.24148 25.044 8.37023 25.1256 8.45188C25.2135 8.52725 25.358 8.56493 25.559 8.56493H26.1713V9.67661H25.3423C24.2306 9.67661 23.6748 9.13647 23.6748 8.0562V5.5408H23.053V4.45738H23.6748V3.16671H25.0031V4.45738H26.1713V5.5408H25.0031Z"
          fill="white" />
        <path d="M26.7808 7.04815C26.7808 6.52058 26.8845 6.05267 27.0917 5.64443C27.3053 5.23618 27.591 4.92215 27.949 4.70233C28.3133 4.4825 28.7184 4.37259 29.1643 4.37259C29.5537 4.37259 29.8929 4.4511 30.1818 4.60812C30.477 4.76513 30.7125 4.96298 30.8884 5.20164V4.45738H32.2167V9.67661H30.8884V8.91351C30.7188 9.15846 30.4833 9.36258 30.1818 9.52588C29.8866 9.68289 29.5443 9.7614 29.1549 9.7614C28.7153 9.7614 28.3133 9.64835 27.949 9.42224C27.591 9.19614 27.3053 8.87897 27.0917 8.47072C26.8845 8.0562 26.7808 7.58201 26.7808 7.04815ZM30.8884 7.067C30.8884 6.74668 30.8256 6.47347 30.7 6.24737C30.5743 6.01499 30.4048 5.83913 30.1912 5.71979C29.9777 5.59418 29.7484 5.53137 29.5035 5.53137C29.2586 5.53137 29.0324 5.59104 28.8252 5.71037C28.6179 5.82971 28.4483 6.00556 28.3165 6.23795C28.1908 6.46405 28.128 6.73412 28.128 7.04815C28.128 7.36219 28.1908 7.63854 28.3165 7.8772C28.4483 8.10959 28.6179 8.28858 28.8252 8.4142C29.0387 8.53981 29.2648 8.60262 29.5035 8.60262C29.7484 8.60262 29.9777 8.54295 30.1912 8.42362C30.4048 8.29801 30.5743 8.12215 30.7 7.89604C30.8256 7.66366 30.8884 7.38731 30.8884 7.067Z"
          fill="white" />
      </svg>
    </Tooltip>,
    nav_content: "Query",
    content: "Query",
  },
  {
    link: "qbqc",
    icon: <Tooltip title={<span>Manage Transcripts page</span>} ><EditIcon /></Tooltip>,
    nav_content: "Transcripts",
    content: "Transcripts",
  },
  {
    link: "review",
    icon: <Tooltip title={<span>Review page</span>} ><VisibilityOutlinedIcon />
    </Tooltip>,
    nav_content: "Review",
    content: "Review",
  },
  {
    link: "compare",
    icon: <Tooltip title={<span>Compare screen page</span>} >
      <BarChartIcon style={{ transform: 'rotate(90deg) scaleX(-1)' }} />
    </Tooltip>,
    nav_content: "Compare",
    content: "Compare",
  },
  {
    link: "wordcloud",
    icon: <Tooltip title={<span>Word cloud page</span>} ><svg width="25" height="18" viewBox="0 0 25 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.6197 16.2281C2.6995 17.4094 4.00112 18 5.52455 18H17.5781C18.8337 18 19.9009 17.5078 20.7799 16.5234C21.0647 16.2044 21.3033 15.8633 21.4959 15.5H18.6459C18.3233 15.6667 17.9674 15.75 17.5781 15.75H5.52455C4.55357 15.75 3.72489 15.3656 3.0385 14.5969C2.35212 13.8281 2.00893 12.9 2.00893 11.8125C2.00893 10.725 2.35212 9.79688 3.0385 9.02813C3.72489 8.25938 4.55357 7.875 5.52455 7.875H6.02679C6.02679 6.31875 6.51646 4.99219 7.49581 3.89531C8.47517 2.79844 9.6596 2.25 11.0491 2.25C12.4386 2.25 13.623 2.79844 14.6024 3.89531C15.2796 4.65373 15.7226 5.52196 15.9316 6.5H17.9849C17.7538 4.89283 17.1034 3.49023 16.0338 2.29219C14.6694 0.764063 13.0078 0 11.0491 0C9.45871 0 8.03572 0.534375 6.78013 1.60313C5.52455 2.67188 4.6875 4.06875 4.26897 5.79375C2.99665 6.1125 1.96708 6.84375 1.18025 7.9875C0.393415 9.13125 0 10.4344 0 11.8969C0 13.6031 0.5399 15.0469 1.6197 16.2281ZM18.7008 8H20.5358V9.07709V10H16.0714H10.7144V8H16.0714H18.7008ZM22.0271 12H25.0002V14H22.0076H19.917H13.3931V12H19.9579H22.0271Z" />
    </svg></Tooltip>,
    nav_content: "Cloud",
    content: "Cloud",
  },
  {
    link: "export-ppt",
    icon: <Tooltip title={<span>Configure PPT page</span>} ><SyncAltIcon /></Tooltip>,
    nav_content: "Configure",
    content: "Configure PowerPoint",
  },
  /* {
    link: "manage",
    icon: <TuneIcon sx={{ transform: "rotate(90deg)" }} />,
    nav_content: "Manage",
    content: "Manage Question Buckets"
  } */
  /* {
    link: "edit",
    icon: <EditIcon />,
    nav_content: "Edit",
    content: "Edit Transcript"
  }, */
  // {
  //   link: "newlogin",
  //   icon: <LogIn />,
  //   nav_content: "Login",
  //   content: "Login Layout"
  // },
  /* {
      link: "compare",
      icon: <CompareArrowsIcon fontSize='large'/>,
      nav_content: "Compare",
      content: "Compare Attributes"
  }, */

  {
    link: "download-ppt",
    icon: <Tooltip title={<span>Download PPT page</span>} ><DownloadIcon /></Tooltip>,
    nav_content: "Download",
    content: "Download",
  },
  {
    link: "edit-project",
    icon: <Tooltip title={<span>Manage project</span>} ><div className="m-1 ml-2"><FaFilePen /></div></Tooltip>,
    nav_content: "Edit",
    content: "Manage Project",
  },

  // {
  //   link: "VoiceToText",
  //   icon: <EditIcon />,
  //   nav_content: "VoiceToText",
  //   content: "Voice to text conversion"
  // },
];

export const markersDataList = [
  {
    content:
      "These are the markers we've picked from the transcripts you uploaded.",
  },
  {
    content: "Let us know which belong to the moderator.",
  },
  {
    content:
      " The rest of the indicators will be assumed as respondents(s) accordingly.",
  },
];

const drawerWidth = 86;
export const Total = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  marginLeft: 0,
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const dataEditors = {
  formats: [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "script",
    "indent",
    "direction",
    "color",
    "background",
    "font",
    "align",
    "link",
    "image",
    "video",
  ],
  modules: {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  },
};


export const dummyAccounts = [
  {
    account_name: "ACCOUNT-1",
    subscriptions: [
      {
        subscription_name: 'SUBSCRIPTION-1',
        subscription_id: "jehsfjsdhfskjdgsd",
        credits: 1008
      },
      {
        subscription_name: 'SUBSCRIPTION-2',
        subscription_id: "jehsfjsdhfskjdgsd",
        credits: 108
      },
      {
        subscription_name: 'SUBSCRIPTION-3',
        subscription_id: "jehsfjsdhfskjdgsd",
        credits: 138928
      }
    ]
  },
  {
    account_name: "ACCOUNT-2",
    subscriptions: [
      {
        subscription_name: 'SUBSCRIPTION-1',
        subscription_id: "jehsfjsdhfskjdgsd",
        credits: 1098708
      }
    ]
  },
  {
    account_name: "ACCOUNT-3",
    subscriptions: [
      {
        subscription_name: 'SUBSCRIPTION-1',
        subscription_id: "jehsfjsdhfskjdgsd",
        credits: 3239
      },
      {
        subscription_name: 'SUBSCRIPTION-2',
        subscription_id: "jehsfjsdhfskjdgsd",
        credits: 234534
      },
    ]
  }
]

export const timeSlots = [
  '09:00hrs (UTC-08:00)',
  '09:30hrs (UTC-08:00)',
  '10:00hrs (UTC-08:00)',
  '10:30hrs (UTC-08:00)',
  '12:00hrs (UTC-08:00)',
  '12:30hrs (UTC-08:00)',
  '13:00hrs (UTC-08:00)',
  '13:30hrs (UTC-08:00)',
  '14:00hrs (UTC-08:00)',
  '14:30hrs (UTC-08:00)',
  '15:00hrs (UTC-08:00)',
  '15:30hrs (UTC-08:00)',
  '16:00hrs (UTC-08:00)',
  '16:30hrs (UTC-08:00)',
  '17:00hrs (UTC-08:00)',
  '17:30hrs (UTC-08:00)',
  '18:00hrs (UTC-08:00)',
  '18:30hrs (UTC-08:00)',
];


export const createProjectStepperData = ["Upload file", "Configure data"];

export const sentimentTypeColors = [
  {
    color: "green",
    type: "Positive"
  },
  {
    color: "red",
    type: "Negative"
  },
  {
    color: "blue",
    type: "Overall"
  },
  {
    color: "gray",
    type: "Entity"
  }
]

export const orderedQCKeys = ['INTELLIGENT_CORRECTION_QC', 'ANALYSIS_TOPIC_QC', 'ENTITIES_QC', 'THEMES_QC']