const Unauthorized = () => {
    return (
        <div className="flex h-screen items-center justify-center">
            <div className="text-red-600 flex items-center justify-center 
                bg-white p-6 rounded-lg shadow-sm w-1/2 border-2 border-red-500">
                <p className="w-3/4 text-center font-semibold text-lg">
                    You are not authorized to view this page.
                    Please contact system administrator in case of any queries.
                </p>
            </div>
        </div>
    )
}

export default Unauthorized