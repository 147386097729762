import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ForgotPwd from "./ForgotPassword";
import { Visibility, VisibilityOff, CheckCircle } from "@mui/icons-material";
import { RootState, useAppDispatch } from "../../redux/store";
import { login } from "../../redux/Profile/action";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import UserDataContext from "../../context/userDataContext";
import * as React from 'react';
import { LoadingButton } from "@mui/lab";
import MainSignUp from "./MainSignUp";
import { updateErrorNote, updateSignUpstate, updateTokenExpiry } from "../../redux/Profile/profileDataReducer";
import CancelIcon from '@mui/icons-material/Cancel';
import * as CryptoJS from 'crypto-js';

interface IUser {
  accountName: string;
  email: string;
  displayName: string;
  accessToken: string;
  refreshToken: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: any;
}
export const decryption = (encryptedText: any, secretKey: string) => {
  try {
    encryptedText = encryptedText.replaceAll('"', '');
    const decrypted = CryptoJS.AES.decrypt(encryptedText?.toString(), secretKey).toString(CryptoJS.enc.Utf8);
    return decrypted
  } catch (error) {

  }

}

export const secrateKey = '#1234'

const LoginLayout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [forgotPwd, setForgotPwd] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailEmpty, setEmailEmpty] = useState(false);
  const [passwordEmpty, setPasswordEmpty] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<any>(null);
  const [enteredLoginMail, setEnteredLoginMail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const errorMessage = useSelector(
    (state: RootState) => state.profile.errorMessage
  );
  const loginDetails = useSelector(
    (state: RootState) => state.profile.loginDetails
  );
  const loginLoader = useSelector(
    (state: RootState) => state.profile.loginLoader
  );

  const [loginErrorMessage, setLoginErrorMessage] = useState(false);
  const { setUserData } = useContext(UserDataContext);

  const { state } = useLocation();
  const { activated } = state || { activated: null }




  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
      },
    },
  });

  useEffect(() => {
    let url: any = localStorage.getItem("redirectUrl")
    if (url !== null) {
      setRedirectUrl(JSON.parse(url));
    }
    localStorage.clear();
    setEnteredLoginMail("");
    setLoginPassword("");
    dispatch(updateSignUpstate());
    dispatch(updateTokenExpiry(false))
    dispatch(updateErrorNote(""))
  }, []);

  useEffect(() => {
    if (loginDetails?.accessToken) {
      const decodedAT: any = jwt_decode(loginDetails?.accessToken);
      const expiry = {
        exp: decodedAT.exp * 1000,
        time: new Date(decodedAT.exp * 1000).toISOString(),
      };
      const cuser: IUser = {
        accountName: loginDetails?.accountName,
        email: loginDetails?.email,
        displayName: loginDetails?.displayName,
        firstName: loginDetails?.firstName,
        lastName: loginDetails?.lastName,
        phoneNumber: loginDetails?.phoneNumber,
        accessToken: loginDetails?.accessToken,
        refreshToken: loginDetails?.refreshToken,
      };
      const encryptedText = CryptoJS.AES.encrypt(JSON.stringify(cuser), secrateKey).toString();

      localStorage.setItem("_U", JSON.stringify(encryptedText));
      localStorage.setItem("expiry", JSON.stringify(expiry));
      setUserData(cuser);
      if (redirectUrl !== null) {
        window.location.href = redirectUrl
      } else {
        if (loginDetails?.accessToken) {
          navigate("/dashboard");
        }
      }
    }
  }, [loginDetails]);


  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  };

  function handleForgot() {
    setIsLogin(false);
    setSignUp(false);
    setForgotPwd(true);
    setLoginErrorMessage(false);
    navigate("/forgotPassword");
  }

  function goToSignUp() {
    setIsLogin(false);
    setSignUp(true);
    setForgotPwd(false);
    setLoginErrorMessage(false);
    navigate("/signUp");
  }
  function handleClickBack() {
    setEnteredLoginMail("");
    setLoginPassword("");
    setIsLogin(true);
    setSignUp(false);
    setForgotPwd(false);
    setLoginErrorMessage(false);
    navigate("/login");
  }
  function handleLoginSu() {
    setLoginErrorMessage(false);
    setEnteredLoginMail("");
    setLoginPassword("");
    setIsLogin(true);
    setSignUp(false);
    setForgotPwd(false);
    navigate("/login");
  }
  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleLogin = (e: any) => {
    e.preventDefault();
    if (
      enteredLoginMail == "" ||
      loginPassword == "" ||
      loginPassword.length == 0 ||
      enteredLoginMail.length == 0 ||
      !isValidEmail(enteredLoginMail)
    ) {
      setError(true);
    } else {
      setError(false);
      HandleSubmit();
    }
  };
  function HandleSubmit() {
    dispatch(
      login({
        username: enteredLoginMail,
        password: loginPassword,
      })).then((result: any) => {
        if (!(result.payload.response.status)) {
          setLoginErrorMessage(true)
        }
      })

  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (

    <div className="grid grid-cols-2 max-lg:flex max-lg:flex-col h-full w-full relative overflow-hidden">
      <svg className=" absolute top-0 mt-[48px] w-screen" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 1440 16" fill="none">
        <path d="M1440 0H-188.109L1440 16V0Z" fill="#9747FF" />
        <path d="M1440 0H-198L1440 9.6V0Z" fill="#85E8E5" />
      </svg>
      <div className="bg-gray-150 flex flex-col justify-center items-center gap-7 max-lg:mt-[30px] max-lg:h-[20vh] max-lg:bg-white">
        <img
          src={`${window.innerWidth >= 1024 ? "images/EzyThemes logo _vertical_white bg.svg" : "https://ezythemes.com/wp-content/uploads/2024/04/logo.svg"}`}
          style={{
            width: window.innerWidth >= 1024 ? "270px" : "50vw",
            height: "auto",
          }}
          alt="Logo"
        />
        <div
          style={{
            fontWeight: 600,
            lineHeight: "32px",
            textAlign: "center",
            fontFamily: 'Poppins',
          }}
          className="text-[#3B3B3B] text-2xl max-lg:text-4xl"
        >
          The Ezy-est productivity
          {window.innerWidth >= 1024 ? <br /> : ""}
          tool you’ll ever need!
        </div>
      </div>
      {isLogin ? (
        <div className="flex-col col-span-1 bg-white mt-[28px] max-lg:min-h-[75vh] max-lg:mt-0 flex justify-center items-center max-lg:items-start">
          <div className="flex flex-col justify-center items-center gap-9 max-lg:gap-20 w-[28vw] max-lg:w-[90vw]">
            <div className="w-full px-2 max-lg:text-center">
              <h1 className="w-full font-semibold text-2xl max-lg:text-5xl mb-2 max-lg:mb-6">
                Welcome Back
              </h1>
              <p className="text-gray-400 max-lg:text-3xl">
                Sign in to begin your day with productivity!
              </p>
              {activated &&
                <p className="w-full flex ml-1 items-center max-lg:justify-center mt-5 text-[#52BB20] text-sm max-lg:text-2xl">
                  <CheckCircle
                    className="text-[#52BB20] mr-1 max-lg:mr-4 max-lg:scale-[1.8] text-sm"
                    fontSize="small"
                  />
                  {activated}
                </p>
              }
              {activated === "" &&
                <p className="w-full flex ml-1 items-center max-lg:justify-center max-lg:text-2xl mt-5 text-red-500 text-sm ">
                  <CancelIcon
                    className="text-red-500 mr-1 max-lg:mr-4 max-lg:scale-[1.8] text-sm"
                    fontSize="small"
                  />
                  Link is invalid. Please <a href="mailto:support@ezythemes.com" className="text-blue-400 no-underline">&nbsp;contact&nbsp;</a> our support team for help.
                </p>
              }
            </div>

            <div className="w-full">
              <ThemeProvider theme={customTheme}>
                <FormControl
                  sx={{
                    //maxWidth: "350px",
                    placeSelf: "center",
                    flexGrow: "1",
                    width: "100%",
                  }}
                  variant="outlined"
                >
                  <TextField
                    id="outlined-basic"
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginBottom: window.innerWidth >= 1024 ? "16px" : "36px",
                      "& .MuiInputBase-root": {
                        height: { sm: 60, md: 80, lg: 52 },
                        fontFamily: '"Poppins", sans-serif'
                      },
                      "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                        fontFamily: '"Poppins", sans-serif'
                      },
                      "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root": {
                        fontFamily: '"Poppins", sans-serif'
                      },
                      "& .MuiInputLabel-root, & .MuiInputBase-root": {
                        fontSize: { sm: 20, md: 30, lg: 15 },
                        fontFamily: '"Poppins", sans-serif'
                      },
                      "& .MuiFormHelperText-root": {
                        fontSize: { sm: 20, md: 20, lg: 10 },
                        fontFamily: '"Poppins", sans-serif'
                      },
                    }}
                    label="Email Address"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Email Address"
                    onInput={(e: any) => { setEnteredLoginMail(e.target.value); setLoginErrorMessage(false) }}
                    onChange={(e: any) => { setEnteredLoginMail(e.target.value); setLoginErrorMessage(false) }}
                    value={enteredLoginMail}
                    onBlur={(e: any) => {
                      (enteredLoginMail.trim().length == 0 || !isValidEmail(enteredLoginMail)) ?
                        setEmailEmpty(true) : setEmailEmpty(false);
                    }}
                    onKeyDown={handleKeyPress}
                    error={(error || emailEmpty) && (enteredLoginMail.length === 0 || !isValidEmail(enteredLoginMail))}
                    helperText={
                      (error || emailEmpty) && enteredLoginMail.length === 0 ? (
                        "Required"
                      ) : (error || emailEmpty) && !isValidEmail(enteredLoginMail) ? (
                        <span style={{ color: "red" }}>Email is not valid</span>
                      ) : (
                        ""
                      )
                    }
                  />
                </FormControl>
              </ThemeProvider>
              <ThemeProvider theme={customTheme}>
                <FormControl
                  sx={{
                    //maxWidth: "350px",
                    placeSelf: "center",
                    flexGrow: "1",
                    width: "100%",
                  }}
                  variant="outlined"
                >
                  <TextField
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}

                    sx={{
                      "& .MuiInputBase-root": {
                        height: { sm: 60, md: 80, lg: 52 },
                        fontFamily: '"Poppins", sans-serif'
                      },
                      "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                        fontFamily: '"Poppins", sans-serif'
                      },
                      "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root": {
                        fontFamily: '"Poppins", sans-serif'
                      },
                      "& .MuiInputLabel-root, & .MuiInputBase-root": {
                        fontSize: { sm: 20, md: 30, lg: 15 },
                        fontFamily: '"Poppins", sans-serif'
                      },
                      "& .MuiFormHelperText-root": {
                        fontSize: { sm: 20, md: 20, lg: 10 },
                        fontFamily: '"Poppins", sans-serif'
                      },
                    }}
                    error={(error || passwordEmpty) && loginPassword.length === 0}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end"
                          sx={{ marginRight: window.innerWidth >= 1024 ? "" : '20px !important' }}>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            className="max-lg:scale-[2]"
                            onMouseUp={(e: any) => e.preventDefault()}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                    }}
                    label="Password"
                    value={loginPassword}
                    InputLabelProps={{ shrink: true }}
                    placeholder="Password"
                    onChange={(e: any) => { setLoginPassword(e.target.value); setLoginErrorMessage(false) }}
                    onKeyDown={handleKeyPress}
                    onBlur={(e: any) => {
                      (loginPassword.length === 0) ?
                        setPasswordEmpty(true) : setPasswordEmpty(false);
                    }}
                    helperText={
                      (error || passwordEmpty) && loginPassword.length === 0 ? (
                        "Required"
                      ) : (
                        ""
                      )
                    }
                  />
                </FormControl>
              </ThemeProvider>
            </div>
            <div className="w-full flex flex-col justify-center items-center">
              <ThemeProvider theme={customTheme}>
                <LoadingButton onClick={(e: any) => handleLogin(e)}
                  disabled={loginLoader}
                  loading={loginLoader}
                  loadingPosition="end"
                  variant="contained"
                  style={{
                    width: "100%",
                    backgroundColor: "#9747FF",
                    color: "#FFFF",
                    padding: "8px",
                  }}
                  sx={{
                    height: { sm: "70px", md: "70px", lg: "35px" },
                    fontSize: { sm: 25, md: 25, lg: 15 },
                    fontFamily: '"Poppins", sans-serif'
                  }}
                >
                  <span>Login</span>
                </LoadingButton>
              </ThemeProvider>
              {errorMessage && (
                <div className="mt-2">
                  <span style={{ color: "red" }}>
                    {errorMessage.includes('unsuccessful login attempts.') ? (
                      <span>
                        {errorMessage} Please{' '}
                        <a href="mailto:support@ezythemes.com" className="text-blue-400 no-underline">
                          contact
                        </a>{' '}
                        EZY Team to unlock.
                      </span>
                    ) : (
                      errorMessage
                    )}
                  </span>

                </div>
              )}
            </div>

            <div className="flex flex-col items-center justify-center gap-2 max-lg:gap-6 max-lg:text-3xl">
              <span
                className=" text-[#9747FF] text-lg not-italic font-medium cursor-pointer"
                style={{ fontFamily: "Poppins" }}
                onClick={handleForgot}
              >

                Forgot Password?
              </span>
              <p className=" text-[#3B3B3B] text-lg not-italic font-medium cursor-pointer"
                style={{ fontFamily: "Poppins" }}>
                Don't have an account?
                <span
                  className="text-[#9747FF] text-lg not-italic font-medium cursor-pointer"
                  style={{ fontFamily: "Poppins" }}
                  onClick={goToSignUp}
                >
                  {" "}
                  Sign up
                </span>
              </p>
            </div>

          </div>
          <div className={`flex justify-center gap-2 absolute bottom-6`}>
            <span>Need help?</span>
            {/* <span
              className="cursor-pointer"
              style={{ color: "#9747FF" }}
              // onClick={() => navigate("/help", { replace: true })}
            >
              Contact team
            </span> */}
            <a href="mailto:support@ezythemes.com" className="cursor-pointer"
              style={{ color: "#9747FF" }}>&nbsp;Contact team&nbsp;</a>
          </div>
        </div>
      ) : (
        <>
          {signUp ? (
            <MainSignUp handleLoginSu={handleLoginSu} />
          ) : (
            <ForgotPwd
              handleClickBack={handleClickBack}
            />
          )}
        </>
      )}
      <svg className=" absolute top-[97.7%] w-screen" xmlns="http://www.w3.org/2000/svg" width="1440" height="19" viewBox="0 0 1440 16" fill="none">
        <path d="M0.000732422 16H1628.11L0.000732422 0V16Z" fill="#9747FF" />
        <path d="M0 16H1638L0 7V16Z" fill="#85E8E5" />
      </svg>
    </div>
  )
};

export default LoginLayout;
