import { createAsyncThunk } from "@reduxjs/toolkit";
import { QbQcAPI } from "../../service/api/qbqcAPI";
import { openSnackbar } from "../Notification/notificationReducer";



export const submitReprocessing = createAsyncThunk(
  "submitReprocessing",
  async ({ project_id }: any, { rejectWithValue }) => {
    try {
      const response = await QbQcAPI.submitReprocessing({
        project_id
      });
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);

export const getAllQuestionBucket = createAsyncThunk(
  "getQuestionBucket",
  async ({ project_id, limit, offset }: any, { rejectWithValue }) => {
    try {
      const response = await QbQcAPI.getAllQuestionBuckets({
        project_id,
        limit,
        offset,
      });
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);
export const addQuestionBucket = createAsyncThunk(
  "addQuestionBucket",
  async ({ project_id, question_bucket_name }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await QbQcAPI.addQuestionBucket({
        project_id,
        question_bucket_name,
      });
      if (response.data.status) {
        return {
          response: response.data,
          question_bucket_name: question_bucket_name,

        };

      }
      else {
        dispatch(
          openSnackbar({
            message: "Question Bucket with same already exists",
            type: "error",
          })
        );
        throw rejectWithValue(response.data);
      }

    } catch (error: any) {
      throw rejectWithValue(error.data);

    }
  }
);
export const addQuestionBucketTranscript = createAsyncThunk(
  "addQuestionBucketTranscript",
  async ({ project_id, question_bucket_name }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await QbQcAPI.addQuestionBucketTranscript({
        project_id,
        question_bucket_name,
      });
      if (response.data.status) {
        return {
          response: response.data,
          question_bucket_name: question_bucket_name,

        };

      }
      else {
        dispatch(
          openSnackbar({
            message: "Question Bucket with same already exists",
            type: "error",
          })
        );
        throw rejectWithValue(response.data);
      }

    } catch (error: any) {
      throw rejectWithValue(error.data);

    }
  }
);
export const deleteQuestionBuckets = createAsyncThunk(
  "deleteQuestionBuckets",
  async (
    { project_id, question_bucket_id, display_names }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await QbQcAPI.deleteQuestionBuckets({
        project_id,
        question_bucket_id,
      });
      return {
        response: response.data,
        question_bucket_id: question_bucket_id,
        display_names: display_names,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);
export const deleteQuestionBucketTranscript = createAsyncThunk(
  "deleteQuestionBucketTranscript",
  async (
    { project_id, question_bucket_id, display_names }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await QbQcAPI.deleteQuestionBucketTranscript({
        project_id,
        question_bucket_id,
      });
      return {
        response: response.data,
        question_bucket_id: question_bucket_id,
        display_names: display_names,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);
export const renameQuestionBucket = createAsyncThunk("renameQuestionBucket", async ({ project_id, question_bucket_id, new_name, display_name }: any, { dispatch, rejectWithValue }) => {
  try {
    const response = await QbQcAPI.renameQuestionBucket({
      project_id,
      question_bucket_id,
      new_name,
      display_name,
    });
    if (response.data.status) {
      return { response: response.data }
    } else {
      dispatch(
        openSnackbar({
          message: "Question Bucket with same already exists",
          type: "error",
        })
      );
      throw rejectWithValue(response.data);
    }
  } catch (error: any) {
    throw rejectWithValue(error.data);

  }
}
);
export const renameQuestionBucketTranscript = createAsyncThunk("renameQuestionBucketTranscript", async ({ project_id, question_bucket_id, new_question_bucket_name, question_bucket_name }: any, { dispatch, rejectWithValue }) => {
  try {
    const response = await QbQcAPI.renameQuestionBucketTranscript({
      project_id,
      question_bucket_id,
      new_question_bucket_name,
      question_bucket_name,
    });
    const jsonString = new TextDecoder().decode(response.data);
    const jsonObject = JSON.parse(jsonString);
    if (jsonObject.status) {
      return jsonObject.data;
    } else {
      dispatch(
        openSnackbar({
          message: "Question Bucket with same already exists",
          type: "error",
        })
      );
      throw rejectWithValue(response.data);
    }
  } catch (error: any) {
    throw rejectWithValue(error.data);

  }
}
);

export const getTranscript = createAsyncThunk("getTranscript", async ({ project_id, dataset_id, get_moderator_only }: any, { rejectWithValue }) => {
  try {
    const response = await QbQcAPI.getTranscript({
      project_id,
      dataset_id, get_moderator_only,
    })
    return {
      response: response.data
    }
  } catch (error: any) {
    throw rejectWithValue(error.data)
  }
});

export const assignQuestionBucket = createAsyncThunk("assignQuestionBucket", async ({ project_id, text_ids, question_bucket_ids, bucket_type, exclude, remove }: any, { rejectWithValue }) => {
  try {
    const response = await QbQcAPI.assignQuestionBucket({ project_id, text_ids, question_bucket_ids, bucket_type, exclude, remove })
    return {
      response: response.data,
      project_id,
      text_ids,
      question_bucket_ids,
      bucket_type,
      exclude,
      remove,
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})
export const assignQuestionBucketTranscript = createAsyncThunk("assignQuestionBucketTranscript", async ({ project_id, text_ids, question_bucket_ids, bucket_type, exclude, remove }: any, { rejectWithValue }) => {
  try {
    const response = await QbQcAPI.assignQuestionBucketTranscript({ project_id, text_ids, question_bucket_ids, bucket_type, exclude, remove })
    return {
      response: response.data,
      project_id,
      text_ids,
      question_bucket_ids,
      bucket_type,
      exclude,
      remove,
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})
export const getQBForSelected = createAsyncThunk("getQBForSelected", async ({ project_id, text_ids }: any, { rejectWithValue }) => {
  try {
    const response = await QbQcAPI.getQBForSelected({ project_id, text_ids })
    return {
      response: response.data,
      project_id,
      text_ids
    }
  } catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getSelectedQBFromSTG = createAsyncThunk("getSelectedQBFromSTG", async ({ project_id, text_ids }: any, { rejectWithValue }) => {
  try {
    const response = await QbQcAPI.getSelectedQBFromSTG({ project_id, text_ids })
    return {
      response: response.data,
      project_id,
      text_ids
    }
  } catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const submitQbQc = createAsyncThunk("submitQbQc", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await QbQcAPI.submitQbQc({ projectId })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const submitThemesQC = createAsyncThunk("submitThemesQC", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await QbQcAPI.submitThemesQC({ projectId })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getQuestionBuckets = createAsyncThunk("getQuestionBuckets", async ({ project_id, limit, offset }: any, { rejectWithValue }) => {
  try {
    const response = await QbQcAPI.getQuestionBuckets({ project_id, limit, offset })
    return {
      response: response.data,
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})
export const getQuestionBucketsStaging = createAsyncThunk("getQuestionBucketsStaging", async ({ project_id, limit, offset }: any, { rejectWithValue }) => {
  try {
    const response = await QbQcAPI.getQuestionBucketsStaging({ project_id, limit, offset })
    return {
      response: response.data,
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})


export const getTextIDs = createAsyncThunk("getTextIDs", async ({ project_id, question_bucket_id, dataset_ids }: any, { rejectWithValue }) => {
  try {
    const response = await QbQcAPI.getTextIDs({ project_id, question_bucket_id, dataset_ids })
    return {
      response: response.data,
      question_bucket_id: question_bucket_id,
      project_id: project_id
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})
export const getTextIDsTranscript = createAsyncThunk("getTextIDsTranscript", async ({ project_id, question_bucket_id, dataset_ids }: any, { rejectWithValue }) => {
  try {
    const response = await QbQcAPI.getTextIDsTranscript({ project_id, question_bucket_id, dataset_ids })
    return {
      response: response.data,
      question_bucket_id: question_bucket_id,
      project_id: project_id
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getUnMappedQB = createAsyncThunk(
  "getUnMappedQB",
  async ({ project_id }: any, { rejectWithValue }) => {
    try {
      const response = await QbQcAPI.getUnMappedQB({
        project_id
      });
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);


export const getOverlappedAnalysis = createAsyncThunk("getOverlappedAnalysis", async ({ project_id, isReview }: any, { rejectWithValue }) => {
  try {
    const response = await QbQcAPI.getOverlappedAnalysis({ project_id, isReview })
    return {
      response: response.data,
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})
export const ResetTranscript = createAsyncThunk(
  "ResetTranscript",
  async ({ project_id }: any, { rejectWithValue }) => {
    try {
      const response = await QbQcAPI.ResetTranscript({
        project_id

      });

      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);

export const getTranscriptNewTab = createAsyncThunk(
  "getTranscriptNewTab",
  async ({ projectId, datasetId }: any, { rejectWithValue }) => {
    try {
      const response = await QbQcAPI.getTranscriptNewTab({
        project_id: projectId,
        dataset_id: datasetId,
      });
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.response.data);
    }
  }
);
