import { useEffect, useRef, useState, useMemo } from "react";
import { RootState, useAppDispatch } from "../../redux/store";
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
    type MRT_SortingState,
    type MRT_RowVirtualizer,
} from 'material-react-table';
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { getCEEnabledTabs, getThemeMasterData, updateThemeMasterData } from "../../redux/EditProject/action";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import { setScopeUpdated, updateIsDisable, setSubmitEnable } from "../../redux/EditProject/EditProjectReducer";



interface IThemeMaster {
    themeTab?: any
    setWarningMaster: any
    setApplyChangesActive: any
    applyChangesClick: boolean
    setResetChanges: any
    resetClick: boolean
    setResetClick: any
    setApplyChangesClick: any
    setResetSortFilter: any
    resetSortFilter: any
}

function ThemeMaster(props: IThemeMaster) {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const projectId = pathname.includes('edit-project') ? pathname.split('/')[2] : pathname.split('/')[3]

    // const projectId = useParams().id;

    const themeMasterData = useSelector((state: RootState) => state.editProject.themeMasterData)
    const themeMasterDataLoading = useSelector((state: RootState) => state.editProject.themeMasterDataLoading)


    const heightRef = useRef<any>(null);
    const [height, setHeight] = useState<number>(0);
    const [width, setWidth] = useState<number>(1);

    useEffect(() => {
        const TopPos = heightRef.current?.getBoundingClientRect().top;
        const LeftPos = heightRef.current?.getBoundingClientRect().left;
        setHeight(window.innerHeight - TopPos - 60);
        setWidth(window.innerWidth - LeftPos - 15);
    });

    const [data, setData] = useState<any>([])
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);

    const [currentDataState, setCurrentDataState] = useState<any>([])
    const [themesToDelete, setThemesToDelete] = useState<any>([])
    const [themesToRename, setThemesToRename] = useState<any>([])
    const [themesToUpdateSenti, setThemesToUpdateSentiment] = useState<any>([])
    const [themesToUpdateUserDef, setThemesToUpdateUserDef] = useState<any>([])
    const [themesToMerge, setThemesToMerge] = useState<any>([])

    useEffect(() => {
        dispatch(getThemeMasterData({ project_id: projectId }))
    }, [])

    useEffect(() => {
        props.setWarningMaster(currentDataState)
    }, [currentDataState])

    useEffect(() => {
        table.resetSorting(true)
        table.resetColumnFilters(true)
    }, [props.resetSortFilter])

    useEffect(() => {
        if (Array.isArray(themeMasterData)) {
            const sortedThemeMasterData = themeMasterData
                .slice()
                .sort((a, b) => a.display_name.localeCompare(b.display_name));

            const tempThemeMasterData = sortedThemeMasterData.map((item, index) => (
                { ...item, sl_no: index + 1 }
            ))
            setData([...tempThemeMasterData])
            setCurrentDataState([...sortedThemeMasterData])
        }
    }, [themeMasterData])

    useEffect(() => {
        if (themesToDelete.length === 0 && themesToRename.length === 0 &&
            themesToUpdateSenti.length === 0 && themesToUpdateUserDef.length === 0 && themesToMerge.length === 0) {
            props.setApplyChangesActive(false)
            props.setResetChanges(false)
            dispatch(setSubmitEnable(false))
        }
        else {
            dispatch(setScopeUpdated(true))
            props.setApplyChangesActive(true)
            props.setResetChanges(true)
            dispatch(setSubmitEnable(true))

        }

    }, [themesToDelete, themesToRename, themesToUpdateSenti, themesToUpdateUserDef, themesToMerge])


    useEffect(() => {
        let payload =
        {
            "list_of_themes_to_delete": themesToDelete,
            "themes_to_rename": themesToRename,
            "themes_to_update_sentiment": themesToUpdateSenti,
            "themes_to_update_user_defined": themesToUpdateUserDef,
            "themes_to_merge": themesToMerge,
        }


        if (props.themeTab === "Theme Master" && props.applyChangesClick) {
            dispatch(updateIsDisable(5));
            dispatch(updateThemeMasterData(
                {
                    project_id: projectId,
                    qc_step: pathname.includes('themes_qc'),
                    payload: [payload]
                }
            ))
                .then((res: any) => {
                    if (res.meta.requestStatus === 'fulfilled') {
                        setData([])
                        setCurrentDataState([])
                        setThemesToDelete([])
                        setThemesToRename([])
                        setThemesToUpdateSentiment([])
                        setThemesToUpdateUserDef([])
                        setThemesToMerge([])
                        dispatch(getThemeMasterData({ project_id: projectId }))
                        props.setApplyChangesClick(false)
                        dispatch(getCEEnabledTabs({ project_id: projectId }))
                    }
                })
        }

    }, [props.applyChangesClick])

    useEffect(() => {
        if (props.themeTab === "Theme Master" && props.resetClick) {
            setData([])
            setCurrentDataState([])
            setThemesToDelete([])
            setThemesToRename([])
            setThemesToUpdateSentiment([])
            setThemesToUpdateUserDef([])
            setThemesToMerge([])

            dispatch(getThemeMasterData({ project_id: projectId }))
                .then((res: any) => {
                    if (res.meta.requestStatus === "fulfilled") {
                        props.setResetClick(false)

                    }
                })
        }

    }, [props.resetClick])

    useEffect(() => {
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);

    function RenameThemeCol({ cell, column, row, table, currentDataState, setCurrentDataState }: any) {

        const [value, setValue] = useState(cell.getValue())

        const onChange = (e: any) => {
            const currVal = e.target.value === '' ? null : e.target.value
            setValue(currVal)
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "rename_theme": currVal }
                return temp
            })
            setThemesToRename((prevThemesToRename: any) => {
                let updatedRenameList;
                let themeId = row.original.theme_id;
                const existingIndex = prevThemesToRename.findIndex(
                    (theme: any) => theme.old_name_id === themeId
                );

                if (existingIndex !== -1) {
                    // If the themeId exists and currVal is not null, update the new_name property
                    if (currVal !== null) {
                        updatedRenameList = [...prevThemesToRename];
                        updatedRenameList[existingIndex] = {
                            ...updatedRenameList[existingIndex],
                            new_name: currVal
                        };
                        return updatedRenameList;
                    } else {
                        // If currVal is null, remove the object from the list
                        updatedRenameList = [...prevThemesToRename];
                        updatedRenameList.splice(existingIndex, 1);
                        return updatedRenameList;
                    }
                } else {
                    // If the themeId doesn't exist and currVal is not null, add a new entry
                    if (currVal !== null) {
                        return [
                            ...prevThemesToRename,
                            {
                                old_name_id: themeId,
                                new_name: currVal
                            }
                        ];
                    } else {
                        // If currVal is null, return the original list without adding a new entry
                        return prevThemesToRename;
                    }
                }
            });

            row._valuesCache[column.id] = currVal
        }

        return (
            <input
                placeholder="Rename Theme"
                className="p-2 w-full h-full border rounded bg-white"
                value={value as string}
                onChange={onChange}
            />
        )
    }

    function MergeToCol({ cell, column, row, table, currentDataState, setCurrentDataState }: any) {
        const themeType = row.original['type']
        const dropDownData = [...table.options.data?.map((item: any) => item?.['display_name'])].filter((item: any) => item !== row.original['display_name'])
        // const dropDownData = [...table.options.data?.filter((item: any) => item['type'] === themeType)].map((item: any) => item?.['theme_name']).filter((item: any) => item !== row.original['theme_name'])

        const [value, setValue] = useState<any>(cell.getValue())

        const onChange = (newValue: any) => {
            var targetThemeId = ''
            const currVal = newValue
            if (newValue === null) {
                setValue(null)
                targetThemeId = ''
            } else {
                setValue(currVal)
                table.options.data.map((item: any) => {
                    if (item['display_name'] === currVal) {
                        targetThemeId = item['theme_id']
                    }
                })
            }

            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "merge_to": currVal }
                return temp
            })
            row._valuesCache[column.id] = currVal

            setThemesToMerge((prevThemesToMergeThemes: any) => {
                let updatedMergeThemeList
                let themeId = row.original.theme_id;

                const existingIndex = prevThemesToMergeThemes.findIndex(
                    (theme: any) => theme.source_id === themeId
                );

                if (existingIndex !== -1) {

                    if (currVal === null) {
                        updatedMergeThemeList = prevThemesToMergeThemes.filter((item: any) => item.source_id !== themeId);
                        return updatedMergeThemeList;

                    } else {
                        updatedMergeThemeList = [...prevThemesToMergeThemes];
                        updatedMergeThemeList[existingIndex] = {
                            ...updatedMergeThemeList[existingIndex],
                            target_id: targetThemeId
                        };
                        return updatedMergeThemeList;

                    }

                }

                else {
                    // If the themeId doesn't exist, add a new entry
                    return [
                        ...prevThemesToMergeThemes,
                        {
                            source_id: themeId,
                            target_id: targetThemeId
                        }
                    ];
                }

            })

        }

        function OptionElement({ params }: any) {
            var ref = useRef<any>()
            const [visible, setVisible] = useState(false)
            const isVisible = useOnScreen(ref)

            useEffect(() => {
                if (isVisible) {
                    setVisible(true)
                }
            })


            return (
                <div {...params} ref={ref} className="p-2 border-b h-[40px] flex items-center cursor-pointer hover:bg-slate-100 transition-all">
                    {visible && <div className="truncate">{params.key}</div>}
                </div>
            )
        }

        return (
            <div className="w-full hover:bg-white transition-all">
                <Autocomplete
                    value={value}
                    onChange={(event: any, newValue: string | null) => {
                        onChange(newValue);
                    }}
                    className="w-full"
                    options={dropDownData}
                    autoHighlight
                    getOptionLabel={(option: any) => option}
                    renderOption={(params) => <OptionElement params={params} />}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Select Theme"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'black',
                                },
                            }}
                        />
                    )}
                />
            </div>
        )
    }

    function DeleteRow({ cell, column, row, table, currentDataState, setCurrentDataState }: any) {
        const [value, setValue] = useState(cell.getValue())

        const onChange = (e: any) => {
            const currVal = value ? null : true
            setValue(currVal)
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "is_deleted": currVal }
                return temp
            })
            row._valuesCache[column.id] = currVal

            setThemesToDelete((prevThemesToDelete: any) => {
                let updatedList;
                const themeId = row.original.theme_id;

                if (currVal === true && !prevThemesToDelete.includes(themeId)) {
                    //toadd
                    updatedList = [...prevThemesToDelete, themeId];
                } else if (currVal === null && prevThemesToDelete.includes(themeId)) {
                    //toremove
                    updatedList = prevThemesToDelete.filter((id: any) => id !== themeId);
                } else {
                    // No change in themesToDelete
                    return prevThemesToDelete;
                }

                return updatedList;
            });
        }


        return (
            <div className="w-full flex justify-center items-center">
                <input type="checkbox" className="h-5 w-5 accent-[#9747FF] transition-all cursor-pointer" checked={value === true} onClick={onChange} />
            </div>
        )
    }

    function ChangeThemeType({ cell, column, row, table, currentDataState, setCurrentDataState }: any) {
        const dropDownData = ['Positive', 'Negative', 'Overall']
        const [value, setValue] = useState(cell.getValue() ? cell.getValue() : '')

        const onChange = (e: any) => {
            const currVal = e.target.value
            setValue(currVal)
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "change_theme_type": (currVal === '' ? null : currVal) }
                return temp
            })
            row._valuesCache[column.id] = currVal

            setThemesToUpdateSentiment((prevThemesToUpdateSenti: any) => {
                let updatedSentimentThemeList
                let themeId = row.original.theme_id;

                const existingIndex = prevThemesToUpdateSenti.findIndex(
                    (theme: any) => theme.theme_id === themeId
                );

                if (existingIndex !== -1) {

                    if (currVal !== "") {
                        updatedSentimentThemeList = [...prevThemesToUpdateSenti];
                        updatedSentimentThemeList[existingIndex] = {
                            ...updatedSentimentThemeList[existingIndex],
                            type: currVal
                        };
                    } else {
                        updatedSentimentThemeList = prevThemesToUpdateSenti.filter((item: any) => item.theme_id !== themeId);
                    }
                    return updatedSentimentThemeList;

                }

                else {
                    // If the themeId doesn't exist, add a new entry
                    return [
                        ...prevThemesToUpdateSenti,
                        {
                            theme_id: themeId,
                            type: currVal
                        }
                    ];
                }

            })


        }


        return (
            <div className="w-full hover:bg-white transition-all ">
                <Select
                    value={value as string}
                    onChange={onChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    // className={`w-full h-10 text-white ${value === 'Positive' ? 'bg-[#27AE68]' : (value === 'Negative' ? 'bg-[#F03A5B]' : 'bg-[#338ACD]')}`}
                    className={`w-full h-10 ${value === 'Positive' ? 'bg-green-300' : (value === 'Negative' ? 'bg-red-300' : (value === 'Overall') ? 'bg-blue-300' : 'bg-slate-50')}`}
                    MenuProps={{
                        style: {
                            maxHeight: 400,
                        },
                    }}
                    sx={{
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'black',
                        },
                        '.MuiSvgIcon-root ': {
                            fill: "black !important",
                        }
                    }}
                    renderValue={(selected) => {
                        if (selected === '') {
                            return 'Select Theme';
                        }
                        return selected
                    }}
                >
                    {dropDownData?.map((item: any) => (
                        <MenuItem value={item} >{item}</MenuItem>
                    ))}
                </Select>
            </div>
        )
    }

    // function UserDefined({ cell, column, row, table, currentDataState, setCurrentDataState }: any) {
    //     const dropDownData = ['Yes', 'No']

    //     const [value, setValue] = useState(cell.getValue() ? 'Yes' : 'No')

    //     const onChange = (e: any) => {
    //         const currVal = e.target.value === 'Yes' ? true : false
    //         setValue(e.target.value)
    //         setCurrentDataState((prevState: any) => {
    //             let temp = [...prevState]
    //             temp[cell.row.index] = { ...temp[cell.row.index], "is_user_defined": currVal }
    //             return temp
    //         })
    //         row._valuesCache[column.id] = currVal

    //         setThemesToUpdateUserDef((prevThemesToUpdateUserDef: any) => {
    //             let updatedUserDefList
    //             let themeId = row.original.theme_id;

    //             const existingIndex = prevThemesToUpdateUserDef.findIndex(
    //                 (theme: any) => theme.theme_id === themeId
    //             );

    //             if (existingIndex !== -1) {
    //                 {
    //                     updatedUserDefList = prevThemesToUpdateUserDef.filter((item: any) => item.theme_id !== themeId);
    //                     return updatedUserDefList;

    //                 }

    //             }

    //             else {
    //                 // If the themeId doesn't exist, add a new entry
    //                 return [
    //                     ...prevThemesToUpdateUserDef,
    //                     {
    //                         theme_id: themeId,
    //                         user_defined: currVal
    //                     }
    //                 ];
    //             }

    //         })

    //     }

    //     return (
    //         <div className="w-full hover:bg-white transition-all">
    //             <Select
    //                 value={value as string}
    //                 onChange={onChange}
    //                 // displayEmpty
    //                 inputProps={{ 'aria-label': 'Without label' }}
    //                 className={`w-full h-10`}
    //                 MenuProps={{
    //                     style: {
    //                         maxHeight: 400,
    //                     },
    //                 }}
    //                 sx={{
    //                     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //                         borderColor: 'black',
    //                     },
    //                 }}
    //                 renderValue={(selected) => (
    //                     <div className="grid grid-cols-5 w-full items-center">
    //                         <div className="col-span-1">
    //                             <div className={`h-[5px] w-[5px] rounded-full border-4 transition-all ${selected === 'No' ? 'border-[#F59E1B]' : 'border-transparent'}`} />
    //                         </div>
    //                         <div className="col-span-4">
    //                             {selected}
    //                         </div>
    //                     </div>
    //                 )}
    //             >
    //                 {dropDownData?.map((item: any) => (
    //                     <MenuItem value={item} >{item}</MenuItem>
    //                 ))}
    //             </Select>
    //         </div>
    //     )
    // }

    function UserDefined({ cell, column, row, table, currentDataState, setCurrentDataState }: any) {
        const [value, setValue] = useState(cell.getValue())

        const onChange = (e: any) => {
            const currVal = e.target.checked
            setValue(e.target.checked)
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "is_user_defined": currVal }
                return temp
            })
            row._valuesCache[column.id] = currVal

            setThemesToUpdateUserDef((prevThemesToUpdateUserDef: any) => {
                let updatedUserDefList
                let themeId = row.original.theme_id;

                const existingIndex = prevThemesToUpdateUserDef.findIndex(
                    (theme: any) => theme.theme_id === themeId
                );

                if (existingIndex !== -1) {
                    {
                        updatedUserDefList = prevThemesToUpdateUserDef.filter((item: any) => item.theme_id !== themeId);
                        return updatedUserDefList;
                    }
                }
                else {
                    // If the themeId doesn't exist, add a new entry
                    return [
                        ...prevThemesToUpdateUserDef,
                        {
                            theme_id: themeId,
                            user_defined: currVal
                        }
                    ];
                }
            })
        }

        return (
            <div className="w-full flex justify-center items-center">
                <input type="checkbox" className="h-5 w-5 accent-[#9747FF] transition-all cursor-pointer" checked={value === true} onClick={onChange} />
            </div>
        )
    }


    function MergeToColFilter({ column, columnName, table }: any) {

        const [dropDownData, setDropDownData] = useState<any>([])
        const [value, setValue] = useState<any>('')
        const [open, setOpen] = useState(false)

        const onChange = (newValue: any) => {
            const currVal = newValue
            setValue(currVal)
            column.setFilterValue(currVal)
        }

        useEffect(() => {
            setDropDownData(Array.from(new Set(table?.getRowModel().rows?.map((item: any) => item?._valuesCache?.sl_no ? item?._valuesCache : item?.original)?.map((item: any) => item?.[`${columnName}`])?.filter((item: any) => item !== undefined && item !== null && item !== ''))))
        }, [table])

        const onFocus = () => {
            setOpen(true)
        }

        function OptionElement({ params }: any) {
            var ref = useRef<any>()
            const [visible, setVisible] = useState(false)
            const isVisible = useOnScreen(ref)

            useEffect(() => {
                if (isVisible) {
                    setVisible(true)
                }
            })

            return (
                <div {...params} ref={ref} className="p-2 border-b h-[40px] flex items-center cursor-pointer hover:bg-slate-100 transition-all">
                    {visible && <div className="truncate">{params.key}</div>}
                </div>
            )
        }

        return (
            <div className="w-full mt-1 hover:bg-white transition-all">
                <Autocomplete
                    open={open}
                    value={value}
                    onChange={(event: any, newValue: string | null) => {
                        onChange(newValue);
                    }}
                    onFocus={onFocus}
                    onBlur={() => setOpen(false)}
                    className="w-full"
                    options={dropDownData}
                    autoHighlight
                    getOptionLabel={(option: any) => option}
                    renderOption={(params) => <OptionElement params={params} />}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onChange={(event: any) => {
                                onChange(event.target.value);
                            }}
                            placeholder={`Select ${column.columnDef.header}`}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                                onKeyDown: (e) => {
                                    if (e.key === 'Enter') {
                                        setOpen(false)
                                        e.stopPropagation();
                                    }
                                },
                            }}
                            variant="standard"
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'black',
                                },
                            }}
                        />
                    )}
                />
            </div>
        )
    }


    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'sl_no',
                header: 'Sl.No',
                size: 100,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'sl_no'} table={table} />
                ),
            },
            {
                accessorKey: 'display_name',
                header: 'Theme',
                size: 200,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'display_name'} table={table} />
                ),
            },
            {
                accessorKey: 'rename_theme',
                header: 'Rename Theme',
                size: 175,
                // Cell: RenameThemeCol,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <RenameThemeCol cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />,
                sortUndefined: 1,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'rename_theme'} table={table} />
                ),
            },
            {
                accessorKey: 'sentence_count',
                header: 'Sentence Count',
                size: 150,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'sentence_count'} table={table} />
                ),
            },
            {
                accessorKey: 'merge_to',
                header: 'Merge To',
                size: 200,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <MergeToCol cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'merge_to'} table={table} />
                ),
                // Cell: MergeToCol,
            },
            {
                accessorKey: 'is_deleted',
                header: 'Delete',
                size: 110,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <DeleteRow cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'is_deleted'} table={table} />
                ),
                // Cell: DeleteRow,
            },
            {
                accessorKey: 'type',
                header: 'Theme Type',
                size: 175,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'type'} table={table} />
                ),
            },
            {
                accessorKey: 'change_theme_type',
                header: 'Change Theme Type',
                size: 175,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <ChangeThemeType cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'change_theme_type'} table={table} />
                ),
                // Cell: ChangeThemeType
            },
            {
                accessorKey: 'is_user_defined',
                header: 'User Defined',
                size: 150,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <UserDefined cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'is_user_defined'} table={table} />
                ),
                // Cell: UserDefined,
            },
        ], []
    )

    const table = useMaterialReactTable({
        columns,
        data,
        defaultDisplayColumn: { enableResizing: true },
        enableBottomToolbar: false,
        enableColumnResizing: true,
        enableColumnVirtualization: true,
        enableGlobalFilterModes: true,
        enablePagination: false,
        enableColumnPinning: true,
        // enableRowNumbers: true,
        enableRowVirtualization: true,
        initialState: { sorting },
        rowVirtualizerInstanceRef,
        rowVirtualizerOptions: { overscan: 1 },
        columnVirtualizerOptions: { overscan: 2 },
        muiTableContainerProps: {
            sx: {
                maxHeight: height - 50,
                overflowY: 'auto',
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#CECDCD',
                    borderRadius: '0px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#F5F7FB',
                },
            },
        },
        muiTableProps: {
            sx: {
                borderCollapse: 'collapse',
                '& td': {
                    border: '1px solid #e2e8f0',
                    padding: '5px',
                    height: '50px',
                    fontSize: '1rem',
                    fontFamily: "'Poppins'"
                },
                '& th': {
                    fontSize: "1rem",
                    fontFamily: "'Poppins'"
                },


                // '& th': {
                //     backgroundColor: '#e2e8f0',
                // },
            },
        },
        globalFilterFn: 'contains',
    });

    return (
        <div className="mt-5 shadow-md mx-1" ref={heightRef} style={{ height: height, width: width }}>
            {themeMasterDataLoading ?
                <div
                    className="flex justify-center items-center overflow-hidden"
                    style={{
                        backgroundColor: "white",
                        position: "absolute",
                        // height: "74%",
                        height: height,
                        // width: "93.8%",
                        width: width,
                        zIndex: 1000,
                    }}
                >
                    <Spinner height={40} />
                </div>
                :
                <>
                    {(data.length > 0) ? <MaterialReactTable table={table} /> : <div className="h-full w-full flex justify-center items-center bg-white">No data available</div>}
                </>
            }
        </div>
    )
}

function useOnScreen(ref: any) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                setIntersecting(entry.isIntersecting);
            }),
        []
    );

    useEffect(() => {
        const currentRef = ref.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [observer, ref]);

    return isIntersecting;
}

export default ThemeMaster