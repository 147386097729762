import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { getInternalPublish } from "../../redux/Configure/action";
import { XCircle } from "react-feather";
import { TextField } from "@mui/material";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import {
  getAccountFilterData,
  getProjectDetails,
} from "../../redux/Project/action";
import { moveProject } from "../../redux/EditProject/action";
import { setDestinationAccountName, setScopeUpdated } from "../../redux/EditProject/EditProjectReducer"
import Spinner from "../../components/Spinner";


interface OptionType {
  value: string;
  label: string;
}

function PublishAndMove() {
  const dispatch = useAppDispatch();
  const projectDetails = useSelector(
    (state: RootState) => state.project.projectDetails
  );
  const selectedProj = useSelector(
    (state: RootState) => state.config.selectedProject
  );



  const AccountFilterData = useSelector(
    (state: RootState) => state.project.AccountFilterData
  );
  const { pathname } = useLocation();
  const projectId = pathname.split("/")[2];
  const [openPopup, setOpenPopup] = useState<any>(false);
  const destinationAccountName = useSelector((state: RootState) => state.editProject.destinationAccountName);
  const [destinationProjectName, setDestinationProjectName] =
    React.useState<string>("");
  const [destinationAccountId, setDestinationAccountId] = useState("");
  const [nameError, setNameError] = useState(false);
  const [pageLoader, setPageLoader] = useState<boolean>(false)
  const [selectedValue, setselectedValue] = useState<any>(null)
  const navigate = useNavigate();
  const [moveLoader, setMoveLoader] = useState<boolean>(false)
  const [openPublishLoader, setOpenPublishLoader] = useState<boolean>(false)

  // Handle change for entering project name
  const handleChangeEnterName = (event: any) => {
    dispatch(setScopeUpdated(true))
    const value = event.target.value;
    const sanitizedValue = value.replace(/[^\w\s.[\]()*,&/-]/g, ""); //sanitizes the input by removing characters that are not letters, digits, underscores, spaces, dots, square brackets, parentheses, commas, ampersands, slashes, or hyphens.
    setDestinationProjectName(sanitizedValue);
    setNameError(value !== sanitizedValue);
  };

  // Fetch project details when projectId changes
  useEffect(() => {
    if (projectId) {
      setPageLoader(true);
      dispatch(getProjectDetails({ project_id: projectId })).then(() => setPageLoader(false))
    }
  }, [pathname]);

  // manages the selection of an account name when users select an account name from a dropdown menu
  const handleChange = (selectedOption: OptionType | null) => {
    setselectedValue(selectedOption?.value)
    dispatch(setScopeUpdated(true))
    const accountNameValue = selectedOption ? selectedOption.value : '';
    dispatch(setDestinationAccountName(accountNameValue));
    const selectedOrganisation = selectedOption?.value;
    const selectedAccount = AccountFilterData.find(
      (item: any) => item.account_name === selectedOrganisation
    );


    if (selectedAccount) {
      setDestinationAccountId(selectedAccount.account_id);
    }
  };

  useEffect(() => {
    dispatch(getAccountFilterData({}));
    dispatch(setDestinationAccountName(""))
  }, []);

  // Extract unique account names for Select component options
  const uniqueOptionsMap = new Map();
  AccountFilterData?.forEach((each: any) => {
    uniqueOptionsMap.set(each.account_name, each.account_name);
  });

  const options = Array.from(uniqueOptionsMap).map(([value, label]) => ({
    value,
    label,
  }));

  useEffect(() => {
    const selectedValue = options.find(option => option.value === destinationAccountName) || null;
    setselectedValue(selectedValue)
  }, [destinationAccountName]);

  const handlePublish = () => {
    setOpenPublishLoader(true);
    dispatch(setScopeUpdated(false))
    dispatch(getInternalPublish({ project_id: projectId })).then(() => {
      setOpenPublishLoader(false);
      navigate("/dashboard", { replace: true });
    });
  };

  const handleMove = () => {
    const ProjectName =
      destinationProjectName.trim() !== ""
        ? destinationProjectName
        : selectedProj.PROJECT_NAME;
    setDestinationProjectName(ProjectName);
    setOpenPopup(true)
  };

  const handleMoveProceed = () => {
    dispatch(setDestinationAccountName(""))
    dispatch(setScopeUpdated(false))
    setMoveLoader(true)
    dispatch(
      moveProject({
        project_id: projectId,
        destination_account_id: destinationAccountId,
        destination_project_name: destinationProjectName,
      })
    ).then((response: any) => {
      if (response.payload.response?.status) {
        setMoveLoader(false)
        navigate('/dashboard')
      }
    })
    setOpenPopup(false);
  }

  // Determine if move or publish button should be disabled
  const isButtonDisabled = !destinationAccountName || !destinationProjectName.trim() || projectDetails.STEP_CODE === "INTERNAL_REVIEW";
  const moveButtonBackgroundColor = isButtonDisabled ? "bg-[#808080]" : "bg-[#9747FF]";
  const is_internal_review = projectDetails.STEP_CODE === "INTERNAL_REVIEW"

  return pageLoader || moveLoader ? (
    <div className="h-full w-full flex justify-center items-center">
      <Spinner height={20} />
    </div>
  ) :
    (

      <div className="">
        {(projectDetails.STEP_CODE === "INTERNAL_REVIEW" || projectDetails.STATUS === "Review") && (
          <button
            className={`py-1 px-4 shadow-xl text-white font-normal rounded-lg mx-1 cursor-pointer ${projectDetails.STEP_CODE === "INTERNAL_REVIEW" ? 'bg-[#9747FF]' : 'bg-[#808080]'}`}
            onClick={handlePublish}
            disabled={projectDetails.STATUS === "Review"}
          >
            Publish
          </button>
        )}
        <Popup
          modal
          open={openPublishLoader}
          contentStyle={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            border: "none",
          }}
        >
          <div className="flex flex-col items-start justify-center mr-2  bg-transparent pb-6 pt-5">
            <Spinner />
          </div>
        </Popup>

        <div className={`${is_internal_review ? "bg-gray-200" : "bg-white"} shadow-lg h-60
       rounded-lg mt-5 w-[670px]`}>
          <h3 className={`${is_internal_review ? "bg-[#c8a5f6]" : "bg-[#9747FF]"} text-md text-white font-semi mb-4  py-1  rounded-t-lg pl-3`}>
            Move project to
          </h3>
          <div className="pl-4 pr-5 ">
            <div className="mb-4 mt-4 flex">
              <label
                htmlFor="destinationAccountName"
                className="block text-sm  font-medium text-gray-700 mt-3"
              >
                Destination Account name*:{" "}
              </label>
              <Select
                id="destinationAccountName"
                value={selectedValue}

                onChange={handleChange}
                options={options}
                className="bg-white text-md  ml-4 rounded-md w-[450px]  dark:border-gray-600 dark:text-gray-900"
                isSearchable={true}
                placeholder="Select an option"
                isDisabled={is_internal_review}

              />
            </div>
            <div className=" flex">
              <label
                htmlFor="destinationAccountName"
                className="block mt-3 text-sm font-medium text-gray-700"
              >
                Destination Project name*:{" "}
              </label>
              <div className="ml-7">
                <TextField
                  className="w-[450px] shadow-md"
                  id="outlined-basic"
                  label="Enter Project Name"
                  variant="outlined"
                  size="small"
                  required={true}
                  disabled={is_internal_review}
                  sx={{
                    "& input:invalid + fieldset": {

                      borderWidth: 1,
                    },
                    "& .MuiInputBase-root": {
                      fontFamily: '"Poppins", sans-serif',
                      height: "40px",
                    },
                    "& #outlined-basic-label": {
                      fontFamily: '"Poppins", sans-serif',
                      zIndex: 0,
                    },
                  }}
                  helperText={
                    nameError === true ? "Special characters are not allowed" : ""
                  }
                  value={destinationProjectName}
                  onChange={handleChangeEnterName}
                />
              </div>
            </div>


            <div className={`flex justify-end ${(nameError === true) ? "mt-2" : "mt-5"}`}>
              <button
                className={`px-4 py-2 mr-1 rounded text-white ${moveButtonBackgroundColor}`}
                disabled={isButtonDisabled}
                onClick={handleMove}
              >
                Move
              </button>
            </div>
          </div>

          <Popup
            position="bottom left"
            modal
            open={openPopup}
            contentStyle={{
              width: "34vw",
              boxShadow: "0px 0px 1px white",
              backgroundColor: "white",
              borderRadius: "6px",
            }}
          >
            <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
              <div className="flex justify-between items-center w-full mb-6">
                <p className="font-base text-lg  flex items-center pl-10 pr-10">
                  {" "}
                  <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
                </p>
                <XCircle
                  className="mr-6"
                  size={24}
                  style={{ cursor: "pointer" }}
                  fill="#BDBCBC"
                  color="#FFFF"
                  onClick={() => {
                    setOpenPopup(false);
                  }}
                />
              </div>

              <span
                className="font-base pb-6 -2 pl-11 pr-10"
                style={{ fontSize: "12px", color: "#545454" }}
              >
                Please note that you are moving the current project to{" "}
                {destinationAccountName} account as {destinationProjectName}.
              </span>
              <div
                className=" mt-5 flex justify-between items-center ml-10 mr-10"
                style={{ width: "90%" }}
              >
                <button
                  style={{ color: " #8088A4", border: "none" }}
                  onClick={() => {
                    setOpenPopup(false);
                  }}
                >
                  Cancel
                </button>

                <button
                  onClick={handleMoveProceed}
                  className="border w-48 p-2 rounded"
                  style={{ border: "1px solid red", color: "red" }}
                >
                  Proceed
                </button>
              </div>
            </div>
          </Popup>
        </div>
      </div>
    );
}

export default PublishAndMove;