import { useEffect, useMemo, useRef, useState } from "react";
import Popup from 'reactjs-popup';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { XCircle } from "react-feather";
import {
    MaterialReactTable,
    type MRT_RowVirtualizer,
    MRT_ColumnFiltersState,
} from "material-react-table";
import { setScopeUpdated, updateIsEditedSummaryReport, updateReportSummaryData } from "../../redux/EditProject/EditProjectReducer";
import { email_summary, report_summary } from "./Tables";
import { RootState, useAppDispatch } from "../../redux/store";
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getSummaryReport, updateReportSummary } from "../../redux/EditProject/action";
import { getDivergenceData } from "../../redux/Project/action";

function ReportandEmail() {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const projectId = pathname.split("/")[2];
    const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);
    const heightRef = useRef<any>(null);
    const topRef: any = useRef(null);

    const reportSummaryData = useSelector((state: RootState) => state.editProject.reportSummaryData)
    const isSummaryEdited = useSelector((state: RootState) => state.editProject.isSummaryEdited);
    const userInfo = useSelector((state: RootState) => state.profile.userInfo)
    const projectName = useSelector((state: RootState) => state.project.projectDetails);
    const ConfigureDataState = useSelector((state: RootState) => state.wizard.ConfigureDataState);

    const [currentTab, setCurrentTab] = useState<string>('Report');
    const [isClicked, setIsClicked] = useState<String>('');
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [clickpreview, setClickPreview] = useState(false);
    const [reportPreview, setReportPreview] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(1);
    const [height, setHeight] = useState<number>(1);
    const [data, setData] = useState<any>([]);
    const [sorting, setSorting] = useState<any>([]);
    const [selectedtab, setSelectedtab] = useState<string>('');
    const [reportPreviewData, setReportPreviewData] = useState<any>([]);
    const [areasOfDivergenceData, setAreasOfDivergenceData] = useState<any>(null);
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [emailPreviewData, setEmailPreviewData] = useState<any>([])

    useEffect(() => {
        dispatch(getDivergenceData({ project_id: projectId })).then((res: any) => {
            setAreasOfDivergenceData(res.payload.response)
        })
    }, [])

    useEffect(() => {
        setIsLoading(true);
        dispatch(getSummaryReport({ project_id: projectId, output_type: currentTab })).then((repsonse: any) => {
            setIsLoading(false);
            dispatch(updateIsEditedSummaryReport(false));
        })
        setGlobalFilter("");
        setColumnFilters([]);
    }, [currentTab])

    useEffect(() => {
        setIsEdited(isSummaryEdited)
    }, [isSummaryEdited])

    useEffect(() => {
        if (currentTab === "Report") {
            const Reporting_Bucket: any = []
            const themes: any = []
            const entities: any = []
            const keyTakeaways: any = []

            reportSummaryData?.map((each: any) => {
                if (each.TOPIC_TYPE === "Analysis topic") {
                    const index = Reporting_Bucket?.findIndex((item: any) => item.display_name === each.TOPIC_VALUE)
                    if (index !== -1) {
                        let obj = Reporting_Bucket[index]
                        Reporting_Bucket[index] = {
                            ...obj, data:
                                [...obj?.data,
                                {
                                    nesting_id: each.NESTING_ID,
                                    nesting_type: each.NESTING_TYPE,
                                    summary: each.SUMMARY
                                }]
                        }
                    } else {
                        Reporting_Bucket.push(
                            {
                                display_name: each.TOPIC_VALUE,
                                data: [{
                                    nesting_id: each.NESTING_ID,
                                    nesting_type: each.NESTING_TYPE,
                                    summary: each.SUMMARY
                                }]
                            })
                    }
                } else if (each.TOPIC_TYPE === "Theme") {
                    themes.push({
                        display_name: each.TOPIC_VALUE,
                        summary: each.SUMMARY
                    })
                } else if (each.TOPIC_TYPE === "Key_takeaways") {
                    if (each.SUMMARY) {
                        keyTakeaways.push({
                            display_name: each.TOPIC_ID,
                            summary: each.SUMMARY
                        })
                    }
                } else {
                    entities.push({
                        display_name: each.TOPIC_VALUE,
                        summary: each.SUMMARY
                    })
                }
            })

            const updatedData = { keyTakeaways, Reporting_Bucket, themes, entities }
            setReportPreviewData(updatedData);
        } else {
            let rb_summary: any = [];
            let aoi_summary: any = [];
            let data: any = [];
            reportSummaryData?.map((item: any) => {
                if (item.SUMMARY && item.TOPIC_TYPE === "Analysis topic") {
                    rb_summary.push(item)
                } else if (item.SUMMARY && ["Entity", "Theme"].includes(item.TOPIC_TYPE)) {
                    aoi_summary.push(item)
                }
            })
            if (rb_summary.length) {
                data.push({ header: 'Here’s a preview of some of the topics you asked for:', top_summaries: rb_summary })
            }
            if (aoi_summary.length) {
                data.push({ header: 'These are what our AI has gathered as most discussed areas of interest:', top_summaries: aoi_summary })
            }
            setEmailPreviewData(data)
        }
        setData(reportSummaryData)
    }, [reportSummaryData])

    const handleCellChange = (tableName: string, rowIndex: number, columnName: string, newValue: any) => {
        setIsEdited(true)
        dispatch(setScopeUpdated(true))

        const update = data?.map((each: any, index: number) => {
            if (index === rowIndex) {
                return { ...each, SUMMARY: newValue !== null ? newValue : '', isEdited: true }
            }
            return { ...each }
        })

        dispatch(updateReportSummaryData(update))
    };

    const reportSummary = useMemo(() => report_summary(handleCellChange), [handleCellChange, data, currentTab]);
    const emailSummary = useMemo(() => email_summary(handleCellChange), [handleCellChange, data, currentTab]);

    const columnsByTab = {
        "Report": reportSummary,
        "Email": emailSummary,
    };

    useEffect(() => {
        // Scroll to the top of the table when the sorting changes
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);

    useEffect(() => {
        const TopPos = heightRef.current?.getBoundingClientRect().top;
        const LeftPos = heightRef.current?.getBoundingClientRect().left;
        setHeight(window.innerHeight - TopPos - 110);
        setWidth(window.innerWidth - LeftPos - 10);
    });

    const onClickTabSwitch = (tab: any) => {
        if (!isEdited) {
            setCurrentTab(tab)
        } else {
            setIsClicked("Edited");
            setOpenPopup(true);
            setSelectedtab(tab);
        }
    }

    const handleReset = () => {
        setOpenPopup(true);
        setIsClicked("Reset")
    }

    const handleApplyChnages = () => {
        setIsClicked("Apply Changes")
        setOpenPopup(true);
    }


    const onClickPreview = () => {
        if (currentTab === "Report") {
            setReportPreview(true)
        } else {
            setClickPreview(true)
            setTimeout(() => {
                topRef.current.scrollIntoView({ behavior: 'smooth' }); // Scrolls to the top of the page smoothly
            }, 3)
        }
    }

    const handleCancel = () => {
        setOpenPopup(false);
        if (isClicked === 'Edited') {
            setIsEdited(false);
            dispatch(setScopeUpdated(false));
            setCurrentTab(selectedtab);
            // dispatch(getSummaryReport({project_id:projectId, output_type:currentTab})).then(()=>{
            //     dispatch(updateIsEditedSummaryReport(false));
            // })
        }
    }

    const handleProceed = () => {
        setOpenPopup(false);
        dispatch(updateIsEditedSummaryReport(false));
        if (isClicked === 'Edited' || isClicked === "Apply Changes") {
            let payload: any = []
            data?.map((item: any) => {
                if (item.isEdited) {
                    const obj: any = {
                        output_type: item.OUTPUT_TYPE,
                        topic_id: item.TOPIC_ID,
                        updated_summary: item.SUMMARY,
                    }
                    if (item.NESTING_ID) {
                        obj["nesting_id"] = item.NESTING_ID
                    }
                    payload.push(obj);
                }
            })
            setIsLoading(true);
            dispatch(updateReportSummary({ project_id: projectId, payload })).then((res: any) => {
                if (res.meta.requestStatus === "fulfilled") {
                    const updateIsEdit = data?.map((item: any) => {
                        return { ...item, isEdited: false }
                    })
                    dispatch(updateReportSummaryData(updateIsEdit))
                    dispatch(setScopeUpdated(false));
                    setIsEdited(false);
                    if (isClicked === 'Edited') {
                        setCurrentTab(selectedtab);
                    }
                    setIsLoading(false);
                }
            })
        } else if (isClicked === "Reset") {
            setIsLoading(true)
            dispatch(getSummaryReport({ project_id: projectId, output_type: currentTab })).then(() => {
                setIsLoading(false);
                dispatch(setScopeUpdated(false));
                setIsEdited(false);

            })
        }
    }

    const handleGlobalFilterChange = (filterValue: any) => {
        // Update your state with the new global filter value
        setGlobalFilter(filterValue);

    };

    return (
        <div className="">
            <div className="flex w-full justify-between">
                <div className="flex items-center">
                    <div className={`bg-[#9747FF] transform transition duration-300 w-fit py-1 px-5 text-white font-normal text-lg rounded-lg mx-2 cursor-pointer ${currentTab === "Report"
                        ? "scale-[1.05] shadow-lg"
                        : "bg-opacity-50"
                        }`}
                        onClick={() => onClickTabSwitch("Report")}
                    >
                        Report
                    </div>
                    <div
                        className={`bg-[#9747FF] transform transition duration-300 w-fit py-1 px-5 text-white font-normal text-lg rounded-lg mx-2 cursor-pointer ${currentTab === "Email"
                            ? "scale-[1.05] shadow-lg"
                            : "bg-opacity-50"
                            }`}
                        onClick={() => onClickTabSwitch("Email")}
                    >
                        {" "}
                        Email
                    </div>
                </div>
                <div className="flex">
                    <button
                        className={`bg-[#9747FF] transform transition duration-300 w-fit py-1 px-5 text-white font-normal text-lg rounded-lg mx-2 cursor-pointer $ 'scale-[1.05] shadow-lg' : 'bg-opacity-50'`}
                        onClick={() => onClickPreview()}
                    >
                        Preview
                    </button>
                    <button
                        disabled={!isEdited}
                        className={`${isEdited
                            ? "bg-[#9747FF] cursor-pointer"
                            : "bg-[#808080] cursor-not-allowed"
                            } transition-all py-1 px-5 text-white font-normal text-lg rounded-lg mx-1`}
                        onClick={handleReset}
                    >
                        Reset
                    </button>
                    <button
                        disabled={!isEdited}
                        className={`${isEdited
                            ? "bg-[#9747FF] cursor-pointer"
                            : "bg-[#808080] cursor-not-allowed"
                            } transition-all text-white py-1 px-5  font-normal text-lg rounded-lg mx-1 `}
                        onClick={handleApplyChnages}
                    >
                        Apply Changes{" "}
                    </button>
                </div>
            </div>
            <div ref={heightRef} className="mt-5">
                {Object.entries(columnsByTab).map(([tabName, columns], index) => (
                    <div
                        key={index}
                        className={currentTab === tabName ? "block" : "hidden"}
                    >
                        {data.length > 0 || isLoading ? (
                            <MaterialReactTable
                                key={index}
                                columns={columns}
                                data={data}
                                globalFilterFn="contains"
                                onGlobalFilterChange={handleGlobalFilterChange} // Provide your callback function
                                onColumnFiltersChange={(filterVal: any) => setColumnFilters(filterVal)}
                                editDisplayMode="cell"
                                defaultDisplayColumn={{ enableResizing: true }}
                                enableBottomToolbar={false}
                                enableColumnResizing={true}
                                enableColumnVirtualization={true}
                                enableGlobalFilterModes={true}
                                enablePagination={false}
                                enableColumnPinning={true}
                                // enableRowNumbers={true}
                                enableRowVirtualization={true}
                                initialState={{ sorting, isLoading }}
                                muiTableContainerProps={{
                                    sx: {
                                        maxHeight: height,
                                        maxWidth: width,
                                        overflowY: "auto",
                                        overflowX: "auto",
                                        "&::-webkit-scrollbar": {
                                            width: "5px",
                                            height: "5px",
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            backgroundColor: "#CECDCD",
                                            borderRadius: "4px",
                                        },
                                        "&::-webkit-scrollbar-track": {
                                            backgroundColor: "#F5F7FB",
                                        },
                                    },
                                }}
                                onSortingChange={setSorting}
                                state={{ isLoading, sorting, globalFilter, columnFilters }}
                                rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
                                rowVirtualizerOptions={{ overscan: 5 }}
                                columnVirtualizerOptions={{ overscan: 2 }}
                                muiTableProps={{
                                    sx: {
                                        borderCollapse: "collapse",
                                        "& td": {
                                            border: "1px solid #e2e8f0",
                                            padding: "5px",
                                            height: "70px",
                                            fontSize: "1rem",
                                            fontFamily: "'Poppins'",
                                            color: "black",
                                        },
                                        "& th": {
                                            fontSize: "1rem",
                                            fontFamily: "'Poppins'",
                                        },
                                    },
                                }}
                            />
                        ) : (
                            <>
                                <div className="flex justify-center items-center bg-white h-[70vh] w-[90vw]">
                                    No data available
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
            <Popup
                open={clickpreview}
                onClose={() => setClickPreview(false)}
                contentStyle={{
                    height: "80vh",
                    overflowY: "auto",
                    width: "50vw",
                    boxShadow: "0px 0px 1px white",
                    backgroundColor: "white",
                    borderRadius: "6px",
                }}
            >
                <div ref={topRef} className="font-poppins text-base my-5">
                    <div className="flex w-full justify-end"></div>
                    <div className="flex w-full justify-between">
                        <div className="w-full">
                            <div className="m-12">
                                <div>Hi {userInfo.FIRSTNAME},</div>
                                <div className="mt-6 mb-6">
                                    We’re pleased to inform you that your project, "{projectName.PROJECT_NAME}", has been successfully processed.
                                </div>
                                <b>Below is a brief summary of what was picked up:</b>
                                {emailPreviewData?.map((prev: any) => (
                                    <div className="my-6">
                                        <b>{prev.header}</b>
                                        {prev?.top_summaries?.map((item: any) => (
                                            <div className="pl-8">
                                                <li className="list-disc my-2 underline font-semibold text-purple-600">
                                                    {item.TOPIC_VALUE}
                                                </li>
                                                <span className="ml-10">
                                                    {item.SUMMARY}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                ))}

                                <p className="mt-2"><b>Check out the PDF</b> that is attached in this email for a detailed preview of your project contents.</p>
                                <p className="my-6">
                                    Please proceed to the{' '}
                                    <span className="text-purple-600" >
                                        platform
                                    </span>
                                    to review your project outputs! Once you're there, you can start analyzing and tweaking them to your liking. When you're ready, go ahead and export the PowerPoint so you can get started on your report.
                                </p>
                                <p>
                                    If you have any additional questions or require further assistance, please do not hesitate to reach out to us at{' '}
                                    <span className="text-purple-600">
                                        support@ezythemes.com
                                    </span>
                                    . Our team is here to ensure you have the best possible experience and support!
                                </p>
                                <div className="mt-12">Warm regards,</div>
                                <div>EZY Team</div>
                            </div>
                            <table className="w-full text-xs border-collapse">
                                <thead>
                                    <tr className="border-b-2 border-t-2 border-gray-600">
                                        <th className=" pl-6">
                                            <a href="https://ezythemes.com/" target="_blank">
                                                <img className="w-[3rem]" src="https://transcripts-test.ezythemes.com/images/EzyThemes%20logo%20_vertical_white%20bg.svg" />
                                            </a>
                                        </th>
                                        <th className="p-2">
                                            For Technical Support
                                            <br />
                                            <a className="text-purple-600" href="mailto:support@ezythemes.com">
                                                support@ezythemes.com
                                            </a>
                                        </th>
                                        <th className=" p-2">
                                            For Sales Enquiries
                                            <br />
                                            <a className="text-purple-600" href="mailto:customerSuccess@ezythemes.com">
                                                customerSuccess@ezythemes.com
                                            </a>
                                        </th>
                                        <th className=" p-2">
                                            <a href="https://www.linkedin.com/company/ezythemes/" target="_blank">
                                                <img className="w-[3rem]" src="https://w7.pngwing.com/pngs/887/616/png-transparent-linkedin-icon-linkedin-text-rectangle-logo-thumbnail.png" />
                                            </a>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className="p-3">
                                            <a className="no-underline" href="https://ezythemes.com/" target="_blank">
                                                © 2024 Ezythemes
                                            </a>
                                        </th>
                                        <th className="p-3">
                                            <a className="text-black" href="https://ezythemes.com/privacy-statement">
                                                Privacy Statement
                                            </a>
                                        </th>
                                        <th className="p-3">
                                            <a className="text-black" href="https://ezythemes.com/terms-and-conditions">
                                                Terms & Conditions
                                            </a>
                                        </th>
                                        <th className="p-3"></th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Popup>
            <Popup
                open={reportPreview}
                onClose={() => setReportPreview(false)}
                contentStyle={{
                    height: "80vh",
                    overflowY: "auto",
                    width: "50vw",
                    boxShadow: "0px 0px 1px white",
                    backgroundColor: "white",
                    borderRadius: "6px",
                }}
            >
                <div className="p-5 flex flex-col gap-4">
                    <div className="py-2 text-lg font-medium">
                        <h1>Project name: {projectName.PROJECT_NAME}</h1>
                        <h1>Category: {ConfigureDataState.category_name}</h1>
                        <div className="border border-gray-500 border-t mt-1"></div>
                    </div>
                    <div className="px-8 pt-1">
                        <div>
                            <h1 className="text-lg font-bold">Key Takeaways: </h1>
                            <ul className="pl-3">
                                {reportPreviewData.keyTakeaways?.map((description: any, index: any) => (
                                    <li className="list-disc my-3 ml-6" key={index}>
                                        {description.summary}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            {/* Section for Analysis Topics */}
                            <div>
                                <div className="mt-4">
                                    {reportPreviewData?.Reporting_Bucket?.length > 0 && <h3 className="text-lg font-bold">Analysis Topics: </h3>}

                                    {reportPreviewData?.Reporting_Bucket?.length > 0 && reportPreviewData?.Reporting_Bucket?.map((topic: any, index: number) => (
                                        <div key={index}>
                                            <li className="text-md font-bold list-disc ml-6 mt-2">{topic.display_name}</li>
                                            <p className="text-md font-medium ml-12">Top areas of interest talked about: </p>
                                            <ul>
                                                {topic.data?.map((item: any) => (
                                                    <>
                                                        <div className="ml-20 mt-3 flex flex-col justify-start" >
                                                            <li className="list-disc font-bold text-md ">{item.nesting_type}</li>
                                                            <span className="font-gray-600 indent-8 mt-2">{item?.summary}</span>
                                                        </div>
                                                    </>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>

                                {/* Section for Themes */}
                                {reportPreviewData?.themes?.length &&
                                    <div className="mt-6">
                                        <h3 className="text-lg font-bold">Themes talked about:</h3>
                                        <div className="border border-gray-400 border-t"></div>
                                        <ul>
                                            {reportPreviewData.themes?.map((theme: any, index: number) => (
                                                <div key={index} className="ml-5 mt-3">
                                                    <li className=" text-md font-bold">{theme.display_name}</li>
                                                    <span className="ml-6 font-gray-600 mt-2">{theme?.summary}</span>
                                                </div>
                                            ))}
                                        </ul>
                                    </div>
                                }
                                {/* Section for Others */}
                                {reportPreviewData?.entities?.length > 0 &&
                                    <div className="mt-6">
                                        <h3 className="text-lg font-bold">
                                            Other areas of interest talked about:
                                        </h3>
                                        <div className="border border-gray-400 border-t"></div>
                                        <ul>
                                            {reportPreviewData?.entities?.map((entity: any, index: number) => (
                                                <div className="ml-5 mt-3">
                                                    <li className="font-bold text-md ">{entity.display_name}</li>
                                                    <span className="ml-6 font-gray-600 mt-2">{entity?.summary}</span>
                                                </div>
                                            ))}
                                        </ul>
                                    </div>
                                }
                                {areasOfDivergenceData ?
                                    <div className="mt-5">
                                        <span className="font-bold text-lg ">
                                            Top areas of divergence in respondent conversations: {areasOfDivergenceData.attribute_1} vs {areasOfDivergenceData.attribute_2}
                                        </span>
                                        <p className="indent-8 mt-2">
                                            {areasOfDivergenceData.attr_comp_summary}
                                        </p>


                                        <div className="w-full grid grid-cols-3 font-semibold mt-2 text-center">
                                            <div className="w-full p-1"></div>
                                            <div className="w-full p-1">{areasOfDivergenceData.attribute_1}</div>
                                            <div className="w-full p-1">{areasOfDivergenceData.attribute_2}</div>
                                        </div>
                                        <div className="border flex flex-col divide-y">
                                            {areasOfDivergenceData?.list_of_aoi?.map((item: any) => (
                                                <div className="w-full grid grid-cols-3 items-center">
                                                    <div className="border-r w-[100%] h-12 p-1 flex justify-start items-center">
                                                        <span className="truncate w-[90%]" title={item.aoi}>{item.aoi}</span>
                                                    </div>
                                                    <div className="border-r w-full h-full p-1">
                                                        <div className="relative z-0 h-full flex items-center">
                                                            <div className=" h-full" style={{ width: `${item.attr_1}%` }}></div>
                                                            <div className="absolute top-0 w-full h-full flex justify-center items-center font-semibold z-10">{`${item.attr_1}%`}</div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full h-full p-1">
                                                        <div className="relative z-0 h-full flex items-center">
                                                            <div className=" h-full" style={{ width: `${item.attr_2}%` }}></div>
                                                            <div className="absolute top-0 w-full h-full flex justify-center items-center font-semibold z-10">{`${item.attr_2}%`}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>

            <Popup
                open={openPopup}
                onClose={() => setOpenPopup(false)}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                contentStyle={{
                    width: "34vw",
                    boxShadow: "0px 0px 1px white",
                    backgroundColor: "white",
                    borderRadius: "6px",
                }}
            >
                <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
                    <div className="flex justify-between items-center w-full mb-6">
                        <p className="font-base text-lg  flex items-center pl-10 pr-10">
                            {" "}
                            <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
                        </p>
                        <XCircle
                            className="mr-6"
                            size={24}
                            style={{ cursor: "pointer" }}
                            fill="#BDBCBC"
                            color="#FFFF"
                            onClick={() => {
                                setOpenPopup(false);
                            }}
                        />
                    </div>
                    <span
                        className="font-base pb-6 -2 pl-10 pr-10"
                        style={{ fontSize: "12px", color: "#545454" }}
                    >
                        {isClicked === "Edited"
                            ? "You are leaving the tab. Do you want to apply changes and continue? If not, the changes will not be saved."
                            : isClicked === "Reset"
                                ? " Changes will be discarded. Do you wish to proceed? "
                                : " Applying changes. Do you wish to proceed?"}
                    </span>
                    <div
                        className=" mt-5 flex justify-between items-center ml-10 mr-10"
                        style={{ width: "90%" }}
                    >
                        <button
                            style={{ color: " #8088A4", border: "none" }}
                            onClick={handleCancel}
                        >
                            {isClicked === "Edited" ? "Continue anyway" : "Cancel"}
                        </button>
                        <button
                            onClick={handleProceed}
                            className="border w-48 p-2 rounded"
                            style={{ border: "1px solid red", color: "red" }}
                        >
                            {isClicked === "Edited" ? "Apply and continue" : "Proceed"}
                        </button>
                    </div>
                </div>
            </Popup>
        </div>
    );
}

export default ReportandEmail 
