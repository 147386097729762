import { useState, useEffect } from "react";


import { useSelector } from "react-redux";
import { useAppDispatch, RootState } from "../../redux/store";
import { updateProjectsSearchString, updateCurrentPaginationPage, updateCurrentPorjectId, updateProjectState } from '../../redux/Project/projectDataReducer';
import { getAllProjects } from "../../redux/Project/action";
import { Search, X } from "react-feather";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { findFeature } from "../../utils/helper";


export default function DashboardHeader() {

  const [projectUuid, setProjectUuid] = useState<string>(uuidv4());
  const dispatch = useAppDispatch();
  const searchString = useSelector((state: RootState) => state.project.projectsSearch)


  const featuresData = useSelector((state: RootState) => state.master.featuresData);
  const [showCreateProject, setShowCreateProject] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 1024) {
      setShowCreateProject(findFeature(featuresData, "CREATE_NEW_PROJECT"))
    }
  }, [featuresData])

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      dispatch(updateCurrentPaginationPage(1));
      dispatch(getAllProjects());
    }
  }
  const handleClearSearch = () => {
    const clear = dispatch(updateProjectsSearchString(""));
    if (clear.payload === "") {
      updateCurrentPaginationPage(1);
      dispatch(getAllProjects());
    }

  };

  useEffect(() => {
    dispatch(updateCurrentPorjectId(projectUuid));
  }, []);

  return (
    <div
      className="page-header flex justify-between items-center"
      style={{
        marginTop: "51px",
        padding: "15px 30px",
        zIndex: "10",
        boxShadow: "0px 2px 6px 0px rgba(218,224,237,1)",
      }}
    >
      {/* <div className="font-semibold">
        <span style={{ fontSize: "16px" }}> Market Research </span>
      </div> */}
      <div
        className="flex items-center border-2 bg-white rounded-lg "
        style={{ width: "66%" }}
      >
        <Search size={16} className="ml-3 mr-3 " style={{ color: "#3B3B3B" }} />
        <input
          type="text"
          placeholder="Search Project"
          value={searchString}
          onChange={(e) => {
            dispatch(updateProjectsSearchString(e.target.value));
          }}
          onKeyDown={handleKeyDown}
          className="w-full h-12 pl-10"
          style={{
            borderRadius: "5px",
            outline: "none",
            width: "95%",
            position: "relative",
            paddingLeft: "1rem",
          }}
        />
        {searchString && (
          <X
            onClick={handleClearSearch}
            style={{ cursor: "pointer" }}
            className="mr-4"
            size={16}
          />
        )}
      </div>
      {/* <UploadFile /> */}
      {showCreateProject && (
        <Link to={`/createProject/project_setup/${projectUuid}`}>
          <Button onClick={() => dispatch(updateProjectState([]))}
            style={{
              fontSize: "80px",
              backgroundColor: "#9747FF",
              color: "white",
              //  width: "100%",
              height: "3rem",
              textTransform: "none",
              fontWeight: "300"
            }}
            className="ml-9 flex items-center justify-around w-fit"
          >
            {/* <Plus size={16} className="mr-2" /> */}
            <span style={{ fontSize: "12px", fontFamily: '"Poppins", sans-serif' }} className=" ml-1 flex gap-1 items-center mr-2">
              Create New Project
            </span>
          </Button>
        </Link>
      )}
    </div>
  );
}
