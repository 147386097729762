import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Info, Trash2, X, XCircle, AlertCircle } from "react-feather";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import {
  Checkbox,
  Input,
  TextField,
  Tooltip,
} from "@mui/material";
import { updateTopicsOfInterest } from "../../redux/Project/projectDataReducer";
import { openSnackbar } from "../../redux/Notification/notificationReducer";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import DescriptionIcon from "@mui/icons-material/Description";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import {
  getProjectConfiguration,
} from "../../redux/Project/action";
import Popup from "reactjs-popup";
import { setResetEnable } from "../../redux/EditProject/EditProjectReducer";
import { makeStyles } from '@mui/styles';
import { getCEEnabledTabs, updateProjectScope } from "../../redux/EditProject/action";
import Spinner from "../../components/Spinner";
import { findFeature } from "../../utils/helper";



const useStyles = makeStyles({
  label: {
    fontWeight: 500,
    fontSize: "16px",
    color: '#9CA3AF',
  },
});

const fixedColumnStyle = {
  width: '150px', // Set the desired width for the columns
};

interface Attribute {
  name: string;
  is_active: boolean;
  is_non_editable: boolean;
  value: string;
}
export default function ScopeEditProject() {
  const dispatch = useAppDispatch();
  const containerRef: any = useRef<any>(null);
  const projectId = useParams().id;
  const { state } = useLocation();
  const cloneStatus = state || { clone: false };
  const classes = useStyles();

  const featuresData = useSelector(
    (state: RootState) => state.master.featuresData
  );
  const isResetEnabledButton = useSelector(
    (state: RootState) => state.editProject.isResetEnabled
  );
  const projectConfigData = useSelector(
    (state: RootState) => state.project.projectConfigData
  );
  const discussionGuideFile = useSelector(
    (state: RootState) => state.project.discussionFiles
  );

  const minLeftWidth = 20; // Minimum width for the left div in percentage
  const minRightWidth = 20; // Minimum width for the right div in percentage
  const [height, setHeight] = useState<number>(0);

  const [newTOI, setNewTOI] = useState("");
  const [duplicateTOI, setDuplicateTOI] = useState<any>([]);
  const [openPopup, setOpenPopup] = useState<any>(false);
  const [openPopup1, setOpenPopup1] = useState<any>(false);
  const [dividerPosition, setDividerPosition] = useState(40);
  const [openPopupUpTOI, setOpenPopupTOI] = useState<any>(false);
  const [openPopupUpST, setOpenPopupST] = useState<any>(false);
  const [fileErrorPop, setFileErrorPop] = useState<any>(false);
  const [topicofInterests, setTopicofInterests] = useState(
    projectConfigData?.topic_of_interests?.topic_of_interest || []
  );
  const [editIndex, setEditIndex] = useState(null);
  const [editValue, setEditValue] = useState<any>({
    toi_name: "",
    is_non_editable: false,
  });
  const [newProjectFiles, setNewProjectFiles] = useState<any>([]);
  const [checkedItems, setCheckedItems] = useState(
    newProjectFiles?.map((file: any) => file?.simultaneous_transcript)
  );
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [attributesDataHeader, setAttributesDataHeader] = useState<Attribute[]>(
    [
      {
        name: "",
        is_active: true,
        is_non_editable: true,
        value: "",
      },
    ]
  );
  const allowedTranscriptFiles = ["pdf", "docx", "xlsx", "csv"];
  const allowedAudioFiles = ["mp3", "wav", "m4a", "aac", "flac", "alac", "wma"];
  const allowedVideoFiles = [
    "mp4",
    "avi",
    "mov",
    "wmv",
    "mpeg-4",
    "h.264",
    "avc",
    "h.265",
    "hevc",
    "mkv",
  ];

  const [errorMsg, setErrorMsg] = useState("");
  const [errorFile, setErrorFile] = useState("");
  const [projectSetupData, setProjectSetupData] = useState<any>({});
  const [newProjectName, setNewProjectName] = useState<string>("");
  const [newCategoryName, setNewCategoryName] = useState<any>("");
  const [newCategoryId, setNewCategoryId] = useState<any>(0);
  const [domainId, setdomainId] = useState<any>(0);
  const [domainName, setdomainName] = useState<any>("");
  const [configureLoader, setConfigureLoader] = useState<boolean>(false);
  const [showST,setShowST]= useState(true)


  useEffect(() => {
    if (cloneStatus.clone) {
      dispatch(
        getProjectConfiguration({
          project_id: cloneStatus.parent_id,
          activeStep: 1,
        })
      );
    }


    if (projectId) {
      dispatch(
        getProjectConfiguration({ project_id: projectId, activeStep: 1 })
      ).then((res: any) => {
        if (res.meta.requestStatus === "fulfilled") {
          let tempProjData = res?.payload?.response?.data?.topics_of_interests === "" ? { ...res?.payload?.response?.data, topics_of_interests: [] } : res?.payload?.response?.data;
          tempProjData = "attribute_data" in tempProjData ? tempProjData : { ...tempProjData, attribute_data: [] }
          setProjectSetupData({ ...tempProjData });

        }
      });
    }
  }, [projectId]);

  useEffect(() => {
    const TopPos = containerRef.current?.getBoundingClientRect().top;
    setHeight(window.innerHeight - TopPos - 50);
  })

  useEffect(()=>{
    if(featuresData){
       setShowST(findFeature(featuresData, "SHOW_SIMULTANEOUS_TRANSCRIPTION"));
    }
  },[featuresData])

  useEffect(() => {
    if (newProjectFiles.length > 0) {

      const allChecked = newProjectFiles?.every(
        (file: any) => file?.simultaneous_transcript === undefined ? false : file?.simultaneous_transcript
      );
      setIsAllChecked(allChecked);
    }
  }, [checkedItems, newProjectFiles, isAllChecked]);

  useEffect(() => {
    if (projectSetupData?.project_id !== undefined) {
      // let toiData = projectSetupData?.topic_of_interests?.map((toi:any)=>toi?.toi_name)
      setNewProjectName(projectSetupData?.state?.project_name);
      setNewCategoryName(projectSetupData?.state?.category?.name);
      setNewCategoryId(projectSetupData?.state?.category?.id);
      setdomainName(projectSetupData?.state?.domain?.name);
      setdomainId(projectSetupData?.state?.domain?.id);
      setTopicofInterests(projectSetupData?.topics_of_interests);

      if (projectSetupData?.state?.files?.length > 0) {
        setNewProjectFiles(projectSetupData?.state?.files);
        setAttributesDataHeader(
          projectSetupData?.state?.files[0]?.attribute_data === undefined ? [] : projectSetupData?.state?.files[0]?.attribute_data
        );
      }
    }
  }, [projectSetupData]);

  // useEffect(()=>{
  //   dispatch(setResetEnable(true))

  // },[ newProjectFiles,topicofInterests,attributesDataHeader,])

  const handleReset = () => {
    setOpenPopup1(true);

  };

  function handleInfo(iconPlace: any) {
    if (iconPlace === "TOI") {
      setOpenPopupTOI((prev: any) => !prev);
      setOpenPopupST(false);
    } else if (iconPlace === "ST") {
      setOpenPopupST((prev: any) => !prev);
      setOpenPopupTOI(false);
    }
  }

  const handleNewTOIChange = (e: any) => {
    setNewTOI(e?.target.value);
  };

  const startDragging = (e: any) => {
    e?.preventDefault();
    document.addEventListener("mousemove", onDrag);
    document.addEventListener("mouseup", stopDragging);
  };

  const onDrag = (e: any) => {
    if (containerRef.current) {
      const containerWidth =
        containerRef?.current?.getBoundingClientRect().width;
      let newPosition = (e.clientX / containerWidth) * 100;

      // Apply constraints
      if (newPosition < minLeftWidth) {
        newPosition = minLeftWidth;
      } else if (newPosition > 100 - minRightWidth) {
        newPosition = 100 - minRightWidth;
      }
      setDividerPosition(newPosition);
    }
  };

  const stopDragging = () => {
    document.removeEventListener("mousemove", onDrag);
    document.removeEventListener("mouseup", stopDragging);
  };


  const parseItems = (input: any) => {
    const regex = /"([^"]*)"|'([^']*)'|([^,]+)/g;
    const items = [];
    let match;
    while ((match = regex.exec(input)) !== null) {
      if (match[1] && match[1].trim()) {
        items.push({ toi_name: match[1].trim(), is_non_editable: false });
      } else if (match[2] && match[2].trim()) {
        items.push({ toi_name: match[2].trim(), is_non_editable: false });
      } else if (match[3] && match[3].trim()) {
        items.push({ toi_name: match[3].trim(), is_non_editable: false });
      }
    }
    return items;
  };
  const handleAddTOI = (index: any) => {
    if (index === null) {
      // Adding a new item

      const newItems = parseItems(newTOI);
      // const uniqueNewItems = newItems?.filter(
      //   (item: any) =>
      //     !topicofInterests?.some(
      //       (existingItem: any) => existingItem.toi_name === item.toi_name
      //     )
      // );
      // const duplicateItems = newItems?.filter((item: any) =>
      //   topicofInterests?.some(
      //     (existingItem: any) => existingItem.toi_name === item.toi_name
      //   )
      // );
      const uniqueNewItems = newItems?.filter((item: any) => {
        return Array.isArray(topicofInterests) && !topicofInterests.some(
          (existingItem: any) => existingItem?.toi_name === item?.toi_name
        );
      });

      const duplicateItems = newItems?.filter((item: any) => {
        return Array.isArray(topicofInterests) && topicofInterests.some(
          (existingItem: any) => existingItem?.toi_name === item?.toi_name
        );
      });


      if (
        uniqueNewItems?.length > 0 &&
        topicofInterests?.length + uniqueNewItems.length <= 100
      ) {
        const newItemsWithStructure = uniqueNewItems?.map((item) => ({
          toi_name: item.toi_name,
          is_non_editable: false,
        }));
        setTopicofInterests([...topicofInterests, ...newItemsWithStructure]);
        setNewTOI("");
        dispatch(updateTopicsOfInterest(uniqueNewItems));
        dispatch(setResetEnable(true))

      } else if (topicofInterests?.length + uniqueNewItems?.length > 100) {
        dispatch(
          openSnackbar({
            message: `Maximum limit of topics of interest reached`,
            type: "error",
          })
        );
      }

      if (duplicateItems?.length > 0) {
        let duplicateItemNames = duplicateItems.map((item: any) => item.toi_name);
        setDuplicateTOI([...duplicateTOI, ...duplicateItems]);
        dispatch(
          openSnackbar({
            message: `${duplicateItemNames} ${duplicateItems?.length == 1 ? "is" : "are"} already present in topics of interest`,
            type: "error",
          })
        );
      }
    }
    // else {
    //   // Editing an existing item
    //   const newItems = [...topicofInterests];
    //   newItems[index] = { toi_name: editValue, is_non_editable: false };
    //   setTopicofInterests(newItems);
    //   setEditIndex(null);
    //   dispatch(
    //     updateTopicsOfInterest(
    //       newItems?.map((item: any) => item.toi_name, false)
    //     )
    //   );
    // }
    else {
      let duplicateNameCheck: Array<any> = []
      // Editing an existing item
      const newItems = [...topicofInterests];
      duplicateNameCheck = newItems?.filter((item: any) => item?.toi_name?.toLowerCase() === editValue?.toi_name?.toLowerCase());
      if (editValue.toi_name?.trim() === "") {
        setTopicofInterests([...newItems]);
        dispatch(
          openSnackbar({
            message: `Can't edit value to empty Topic of Interest`,
            type: "error",
          })
        );
      } else if (duplicateNameCheck.length > 0) {
        setTopicofInterests([...newItems]);
        dispatch(
          openSnackbar({
            message: `${editValue.toi_name} already exists.Edit to new Topic of Interest`,
            type: "error",
          })
        );
      }
      else {
        // newItems[index] = editValue;
        // setTopicofInterests([...newItems]);
        newItems[index] = { toi_name: editValue.toi_name, is_non_editable: false };
        setTopicofInterests([...newItems]);
      }
      setEditIndex(null);
      dispatch(
        updateTopicsOfInterest(
          newItems?.map((item: any) => item.toi_name, false)
        )
      );
    }
  };
  const onEnterAddTOI = (e: any, index: number | null) => {
    if (e.key === "Enter") {
      handleAddTOI(index);
    }
  };

  function handleDeleteTOI(index: any): void {
    const newItems = [...topicofInterests];
    newItems.splice(index, 1);
    setTopicofInterests(newItems);
    dispatch(updateTopicsOfInterest(newItems));
    dispatch(setResetEnable(true))

    //need to add newprojectfiles
  }


  const handleEditTOIClick = (index: any, value: any) => {
    setEditIndex(index);
    setEditValue({
      toi_name: value.toi_name,
      is_non_editable: false,
    });
    dispatch(setResetEnable(true))

  };





  const handleTOIEditInputChange = (e: any) => {
    setEditValue({
      toi_name: e?.target.value,
      is_non_editable: false,
    });
  };
  const handleCheckbox = (type: string, filename: string | null = null) => {
    if (type === "multi") {
      const newState = !isAllChecked;
      setIsAllChecked(newState);
      setNewProjectFiles((prevFiles: any) =>
        prevFiles?.map((file: any) => {
          return {
            ...file,
            simultaneous_transcript: newState,
          };
        })
      );
      dispatch(setResetEnable(true))

    } else if (type === "single" && filename !== null) {
      setNewProjectFiles((prevFiles: any) =>
        prevFiles?.map((file: any) =>
          file?.filename === filename
            ? {
              ...file,
              simultaneous_transcript: !file?.simultaneous_transcript,
            }
            : file
        )
      );

      setIsAllChecked(
        newProjectFiles?.every(
          (file: any) => checkedItems[file?.filename] === true
        )
      );
      dispatch(setResetEnable(true))

    }
  };

  function handleAttributeNameChange(e: any, index: any) {
    let updatedAttributesDataHeader = [...attributesDataHeader];

    let newAttributesDataHeader = updatedAttributesDataHeader?.map(
      (attr: any, key: any) => {
        if (key === index) {
          return {
            ...attr,
            name: e.target.value,
          };
        } else {
          return {
            ...attr,
          };
        }
      }
    );

    let projectFiles = [...newProjectFiles];
    let updatedFilesAttrNames = projectFiles.map((file: any) => {
      let updatedAttributes = file.attribute_data?.map(
        (attr: any, key: any) => {
          if (key == index) {
            return {
              ...attr,
              name: e.target.value,
            };
          } else {
            return {
              ...attr,
            };
          }
        });
      if (!file.attribute_data || file.attribute_data.length === 0) {
        updatedAttributes = newAttributesDataHeader;
      } else if (file.attribute_data.length <= index) {
        updatedAttributes.push(newAttributesDataHeader[index]);
      }
      return {
        ...file,
        attribute_data: updatedAttributes,
      };
    });

    setNewProjectFiles(updatedFilesAttrNames);
    setAttributesDataHeader(newAttributesDataHeader);
    dispatch(setResetEnable(true))
  }

  function handleAttributeValueChange(
    e: any,
    attrIndex: any,
    datasetId: any,
    name: any
  ) {
    let projectFiles = [...newProjectFiles];

    let updatedProjectFiles = projectFiles?.map((file: any) => {
      if (name === file?.filename) {
        let updatedAttributes = [...(file?.attribute_data === undefined ? [] : file?.attribute_data)];
        let attributesExists = false;

        updatedAttributes = updatedAttributes?.map((attr: any, key: any) => {
          if (
            attr.name === attributesDataHeader[attrIndex].name &&
            key == attrIndex
          ) {
            attributesExists = true;
            return {
              ...attr,
              value: e?.target.value,
            };
          }
          return attr;
        });
        if (!attributesExists) {
          updatedAttributes.push({
            name: attributesDataHeader[attrIndex].name,
            is_active: true,
            is_non_editable: true,
            value: e?.target.value,
          });
        }
        return {
          ...file,
          attribute_data: updatedAttributes,
        };
      } else {
        let updatedAttributes = [...(file?.attribute_data === undefined ? [] : file?.attribute_data)];
        let attributesExists = attributesDataHeader.length===updatedAttributes.length ? true : false;

        if (!attributesExists) {
          updatedAttributes.push({
            name: attributesDataHeader[attrIndex].name,
            is_active: true,
            is_non_editable: true,
            value:'',
          });
          return {
            ...file,
            attribute_data: updatedAttributes,
          };
        } else {
          return {
            ...file,
          };
        }
      }
    });

    setNewProjectFiles(updatedProjectFiles);
    dispatch(setResetEnable(true))

  }
  const addAttributeColumns = () => {
    if (attributesDataHeader?.length < 2) {
      const newAttribute = {
        name: "",
        is_active: true,
        is_non_editable: true,
        value: "",
      };
      setAttributesDataHeader([...attributesDataHeader, newAttribute]);
      // dispatch(setResetEnable(true))
    }
  };
  const deleteAttributesColumns = (index: any) => {
    let updatedAttributesDataHeader = [...attributesDataHeader];
    updatedAttributesDataHeader?.splice(index, 1);

    setAttributesDataHeader(updatedAttributesDataHeader);

    //updateProjectFiles

    let updatedProjectFiles = [...newProjectFiles];
    updatedProjectFiles = updatedProjectFiles?.map((file: any) => {
      let updatedAttributes = [...(file?.attribute_data === undefined ? [] : file?.attribute_data)];
      updatedAttributes?.splice(index, 1);
      return {
        ...file,
        attribute_data: updatedAttributes,
      };
    });

    setNewProjectFiles(updatedProjectFiles);
    dispatch(setResetEnable(true))

  };

  const truncateName = (str: any) => {
    return str?.length > 35 ? str?.substring(0, 24) + "..." : str;
  };





  const handleErrorFile = (errMsg: any, errFile: any) => {
    setErrorFile(errFile);
    setErrorMsg(errMsg);
    setFileErrorPop((prev: any) => !prev);
  };

  //   const OnProcess = () => {
  //     processCallMade.current = true

  //     // Checking if any attribute payload is active
  //     for (let i = 0; i < 2; i++) {
  //       if (attrPayload[i].is_active) {
  //         // Checking if the number of values for the attribute matches the number of files uploaded
  //         if (attrPayload[i].values.length !== fileNameList.files.length) {
  //           // Dispatching an error message if the number of values doesn't match
  //           dispatch(openSnackbar({ message: `Attribute "${attrPayload[i].name}" has a missing value`, type: "error" }))
  //           return ""; // Exiting the function
  //         }
  //         // Checking if any value is empty
  //         else {
  //           for (let j = 0; j < fileNameList.files.length; j++) {
  //             if (attrPayload[i].values[j].value.trim() === '') {
  //               // Dispatching an error message if any value is empty
  //               dispatch(openSnackbar({ message: `Attribute "${attrPayload[i].name}" has a missing value`, type: "error" }))
  //               return ""; // Exiting the function
  //             }
  //           }
  //         }
  //       }
  //     }

  //     var isValueEmpty = false;
  //     areaOfInterest?.map((item: any) => {
  //       if(item.value===""){
  //           isValueEmpty=true;
  //       }
  //     })
  //     if (isValueEmpty) {
  //        dispatch(openSnackbar({ message: `Area of interest name cannot be empty`, type: "error" }));
  //        return "";
  //     }

  //     clearTimeout(timeOutID.current)
  //     setConfigureLoader(true)
  //     dispatch(updateIsDisable(4));
  //     dispatch(
  //       updateProjectScope({
  //         project_id: projectId,
  //         isAutosave: false,
  //         attribute_data: attrPayload,
  //         reporting_buckets: questionBucket.filter((item:any) => item.bucket_name !== ""),
  //         themes: themesSubmittedValues ?? [],
  //         entities: transformedData
  //       })
  //     )
  //      .then(() => {
  //       dispatch(getCEEnabledTabs({ project_id: projectId }))
  //        setConfigureLoader(false);
  //        setUpdateTrigger(true);

  //     });
  //   };

  function attributesCheck(attributesFullList: any) {
    const columnCount = attributesFullList[0]?.length;
    const columnResults = new Array(columnCount).fill(true);

    for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
      let columnHasValues = false;
      let columnHasNames = false;
      let columnIsValid = true;

      for (let rowIndex = 0; rowIndex < attributesFullList.length; rowIndex++) {
        const attribute = attributesFullList[rowIndex][columnIndex];
        const name = attribute?.name?.trim();
        const value = attribute?.value?.trim();

        if (name) {
          columnHasNames = true;
        }
        if (value) {
          columnHasValues = true;
        }

        if ((name && !value) || (!name && value)) {
          columnIsValid = false;
        }
      }

      // If column has names but any value is missing
      if (columnHasNames && !columnHasValues) {
        columnIsValid = false;
      }

      // If column has values but any name is missing
      if (columnHasValues && !columnHasNames) {
        columnIsValid = false;
      }

      columnResults[columnIndex] = columnIsValid;
    }


    return columnResults;
  }

  const onProcess = () => {

    let discussionGuide = {
      filename: "string",
      is_error: true,
      error_message: "string",
    };
    let payload: any = {
      state: {
        project_name: newProjectName,
        category: {
          id: projectSetupData?.state?.category?.id,
          name: newCategoryName,
        },
        domain: {
          id: projectSetupData?.state?.domain?.id,
          name: domainName,
        },
        discussion_guide:
          discussionGuideFile?.length > 0
            ? discussionGuideFile
            : discussionGuide,
        clone: cloneStatus.clone ? true : false,
        parent_project_id: cloneStatus.clone ? cloneStatus.parent_id : null,
        files: newProjectFiles,
      },
      topics_of_interests: {

        topics_of_interest: topicofInterests.length === 0 ? [] : topicofInterests?.map((toi: any) => ({
          toi_name: toi?.toi_name,
          is_non_editable: toi?.is_non_editable,
        })),
      },
    };

    let isValidToProcess: boolean;


    let attributesFullList: any = []

    attributesFullList = newProjectFiles.map((file: any) => {
      return [...file.attribute_data]
    })

    const attributesValidation: any = attributesCheck(attributesFullList)

    isValidToProcess = !attributesValidation.includes(false)

    const attributeEqualNamesCheck = attributesDataHeader?.length > 0 && attributesDataHeader[0]?.name === attributesDataHeader[1]?.name;



    if (newTOI.length > 0) {
      handleAddTOI(null)
    }

    if (!isValidToProcess) {
      dispatch(setResetEnable(true))
      dispatch(
        openSnackbar({
          message: "Please fill all the attribute fields",
          type: "error",
        })
      );
    } else if (attributeEqualNamesCheck) {
      dispatch(setResetEnable(true))
      dispatch(
        openSnackbar({
          message: "Attribute names cannot be equal",
          type: "error",
        })
      );
    }
    else {
      dispatch(setResetEnable(false))
      setConfigureLoader(true)

      dispatch(updateProjectScope({
        projectId: projectId,
        field: "scope",
        payload: payload
      }

      )).then(() => {
        dispatch(getCEEnabledTabs({ project_id: projectId }))
        setConfigureLoader(false);

      });
    }



  }

  return (
    <>
      <div className="flex flex-col gap-6" style={{ height: height - 50 }}>
        <div className="flex justify-end">
          <button
            className={`py-1 px-4 text-white font-normal rounded-lg mx-1 ${isResetEnabledButton
              ? "bg-[#9747FF] cursor-pointer"
              : "bg-[#808080] cursor-not-allowed"
              }`}
            onClick={isResetEnabledButton ? handleReset : undefined}
          >
            Reset
          </button>
          <button
            className={`py-1 px-4 text-white font-normal rounded-lg mx-1 ${isResetEnabledButton
              ? "bg-[#9747FF] cursor-pointer"
              : "bg-[#808080] cursor-not-allowed"
              }`}
            onClick={() => {
              isResetEnabledButton ? setOpenPopup(true) : setOpenPopup(false);
            }}
          >
            Apply Changes
          </button>
        </div>
        <div className="second-row mb-3 mt-1 flex flex-row " style={{ height: height * 0.999 }} ref={containerRef}>
          <div
            className=" bg-white h-full flex flex-col p-2 rounded-md shadow-sm "
            style={{ width: `${dividerPosition}%`, height: height }}
          >
            <h1 className="flex items-center text-lg font-bold">
              Topics of Interest
              <span className="ml-1 ">
                <Info
                  size={16}
                  className="cursor-pointer"
                  onClick={() => {
                    handleInfo("TOI");
                  }}
                />{" "}
              </span>
            </h1>
            <p className="text-gray-400 text-sm italic mb-2">
              Enter Topics that are covered in the study and other areas that
              you're interested in finding out.
            </p>
            <div style={{ position: "relative", width: "100%" }}>
              <TextField
                className="w-full font-bold"
                id="standard-basic"
                label="Add Topics of Interest"
                variant="standard"
                value={newTOI}
                onChange={handleNewTOIChange}
                onKeyPress={(e) => onEnterAddTOI(e, null)}
                InputLabelProps={{
                  className: classes.label,
                }}
              // InputProps={{
              //   endAdornment: (
              //     <span
              //       style={{
              //         position: "absolute",
              //         right: 0,
              //         top: 1,
              //         fontSize: "10px",
              //         fontStyle: "italic",
              //         color: "#888",
              //         transform: "translateY(50%)",
              //       }}
              //     >
              //       Press Enter to add
              //     </span>
              //   ),
              // }}
              />
            </div>
            {newTOI.length > 0 && <p className='text-end text-xs' style={{ color: "#888", fontSize: "10px", fontStyle: "italic" }}>Press Enter to add</p>}
            <p className="text-gray-400 text-sm italic mb-3">
              {" "}
              Enter values separated by commas.To include a comma within a value
              enclose the value in double quotes.(e.g.,"Reactions to A,B and C")
            </p>
            <div className="overflow-auto flex flex-wrap items-center max-h-full">
              {topicofInterests?.length > 0 && topicofInterests?.map((item: any, index: any) => (
                <div
                  className={`mr-2 my-1 px-1 flex items-center border w-fit ${editIndex === index ? "border-purple-400" : "border-b"
                    } rounded-md`}
                >
                  {editIndex === index ? (
                    <input
                      type="text"
                      value={editValue.toi_name}
                      onChange={handleTOIEditInputChange}
                      onKeyPress={(e) => onEnterAddTOI(e, index)}
                      onBlur={() => setEditIndex(null)} // Optional: to save on blur
                      className="p-1"
                      style={{ borderColor: "red" }}
                      autoFocus
                    />
                  ) : (
                    <span
                      className="text-gray-500 text-md px-2 "
                      onClick={() => handleEditTOIClick(index, item)}
                    >
                      {item.toi_name}
                    </span>
                  )}

                  <Tooltip title={<span >Remove</span>} >
                    <X
                      size={12}
                      className=" ml-2 cursor-pointer"
                      onClick={() => handleDeleteTOI(index)}
                    /></Tooltip>
                </div>
              ))}
            </div>
          </div>


          <div className="text-center flex justify-center items-center h-full ">
            <div
              className="divider h-20 bg-white flex flex-row justify-center items-center shadow-lg"
              style={{
                width: "18px",
                cursor: "col-resize",
                borderRadius: "5px",
                padding: "9px 0",
                backgroundColor: "#fff",
              }}
              onMouseDown={startDragging}
            >
              <div
                style={{
                  width: "2px",
                  height: "40px",
                  backgroundColor: "#ccc",
                  margin: "auto",
                  borderRadius: "2px",
                }}
              ></div>
              <div
                style={{
                  width: "2px",
                  height: "40px",
                  backgroundColor: "#ccc",
                  margin: "auto",
                  borderRadius: "2px",
                }}
              ></div>
            </div>
          </div>
          <div
            className={`file-table bg-white border shadow-md rounded-md w-full h-full z-30`}
            style={{ width: `${100 - dividerPosition}%`, minWidth: "300px" }}
          >
            <div className="overflow-y-auto h-full z-20 custom-scrollbar" style={{ maxWidth: "1500px",overflowX: 'auto' }}>
              <table className="table-auto w-full">
                <thead className="bg-white sticky -top-0.5 z-30 shadow-md">
                  <tr>
                    <th className="w-[19%] h-16 border border-[#ddd] bg-white">
                      <div className="flex justify-center items-center font-semibold">
                        Files uploaded
                      </div>
                    </th>
                    {showST&&
                    <th className="w-[13%] h-16 border border-[#ddd]">
                      <div className="flex justify-center items-center font-semibold text-gray-400">
                        <div className="flex flex-col">
                          <span>Simultaneous</span>
                          <span>Transcription</span>
                        </div>
                        <Info
                          className="cursor-pointer ml-2"
                          size={16}
                          onClick={() => handleInfo("ST")}
                        />
                        <Checkbox
                          style={{ color: "purple" }}
                          checked={isAllChecked}
                          onChange={() => handleCheckbox("multi")}
                        />
                      </div>
                    </th>
                     }
                    {attributesDataHeader?.map((attr, index) => (
                      <th
                        key={index}
                        style={{ minWidth: "300px" }}
                        className="w-[22%] h-16 border border-[#ddd]"
                      >
                        <div className="flex justify-center items-center font-semibold">
                          <Tooltip title={attr?.name}
                            placement="bottom-start"
                            PopperProps={{
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [20, -14],
                                  },
                                },
                              ],
                            }}>
                            <Input className="w-full pl-2 text-center  placeholder-wrap font-semibold"
                              value={attr?.name}
                              onChange={(e) => handleAttributeNameChange(e, index)}
                              style={{ fontWeight: "bold", fontSize: "15px" }}
                              placeholder={`Enter Attribute name ${index == 0 ? "(Eg Location)" : ""}`} />
                          </Tooltip>
                          <Tooltip title={<span >Delete attribute</span>} >
                            <Trash2 size={18}
                              onClick={() => deleteAttributesColumns(index)}
                              className="cursor-pointer ml-2"
                            />

                          </Tooltip>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {newProjectFiles?.map((file: any, index: any) => (
                    <tr key={index}>
                      <td className="border border-[#ddd] h-14">
                        <div className="overflow-y-auto overflow-x-hidden px-2">
                          <div className="flex justify-start items-center">
                            <div>
                              {allowedAudioFiles.includes(
                                file?.filename?.split(".")[1].toLowerCase()
                              ) ? (
                                <Tooltip title={<span >Audio file</span>}><AudioFileIcon /></Tooltip>
                              ) : allowedVideoFiles.includes(
                                file?.filename?.split(".")[1].toLowerCase()
                              ) ? (
                                <Tooltip title={<span >Video file</span>} ><VideoFileIcon /></Tooltip>
                              ) : (
                                <Tooltip title={<span >Text file</span>} ><DescriptionIcon /></Tooltip>
                              )}
                            </div>
                            <div className="flex flex-col w-full px-2">
                              <Tooltip title={file?.filename} >
                                <div className="flex justify-between gap-2">
                                  <span
                                    style={{
                                      fontSize: "10px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      cursor: "pointer",
                                      color:
                                        file?.status === "sizeError" ? "red" : "",
                                    }}
                                    title={file?.filename}
                                  >
                                    {truncateName(file?.filename)}{" "}
                                    {`(${file?.file_size > 1048576
                                      ? `${(file?.file_size / 1048576).toFixed(
                                        2
                                      )} MB`
                                      : `${(file?.file_size / 1024).toFixed(
                                        2
                                      )} KB`
                                      })`}
                                  </span>
                                  {/* <span
                                    style={{ width: "10%", fontSize: "10px" }}
                                  >
                                    {file?.is_error
                                      ? 0
                                      : file?.fileUploadProgress}

                                  </span> */}
                                </div>
                              </Tooltip>
                              {/* {file?.fileUploadProgress === 100 ? (
                                <LinearProgress
                                  variant="determinate"
                                  color={file?.is_error ? "error" : "success"}
                                />
                              ) : (
                                <div className="w-full bg-gray-200 h-[5px] dark:bg-slate-200 rounded-lg">
                                  <div
                                    className="bg-indigo-500 h-[5px] dark:bg-indigo-400 rounded-lg"
                                    style={{
                                      width: `${file?.fileUploadProgress}%`,
                                    }}
                                  ></div>
                                </div>
                              )} */}
                              <span
                                className="flex gap-2 items-center cursor-default"
                                style={{
                                  fontSize: "10px",
                                  color: file?.is_error ? "red" : "#9747FF !important",

                                }}
                              >
                                {file?.is_error ? (
                                  <div className="flex gap-2 items-center justify-center">
                                    <Tooltip className="cursor-pointer" title={<span > View File error</span>} >
                                      <p className="hover:underline mt-1" onClick={() =>
                                        handleErrorFile(
                                          file?.error_message,
                                          file?.filename
                                        )}>File error: </p>
                                    </Tooltip>
                                    <div>
                                      <AlertCircle
                                        color="white"
                                        fill="red"
                                        size={12}
                                        className="mt-1"

                                      />
                                    </div>
                                  </div>
                                ) : (
                                  file?.fileUploadStatusMessage
                                )}
                              </span>
                            </div>
                            <div className="flex gap-2">
                              {/* {file?.fileUploadProgress === 100 ? (
                                <div className="flex items-center">
                                    {
                                     
                                      file?.status === "Failed" || file?.fileUploadStatus === "sizeError" ? (
                                        <XCircle className="text-red-700 mr-2 text-sm" />
                                      ) : null
                                    }
                                    <Tooltip title={<span >Delete file</span>} >
                                    <Trash2
                                    className="cursor-pointer"
                                    size={16}
                                    onClick={() => {
                                      if (file?.status === "sizeError") {
                                        handleDeleteError(file);
                                      } else {
                                        handleDeleteFile(file);
                                      }
                                    }}
                                  />
                                  </Tooltip>
                                </div>
                              ) : (
                                <div className="flex items-center gap-2">
                                  <CircularProgress size={16} />
                                  <Tooltip title={<span>Cancel File Upload</span>} >
                                  <button
                                    className="text-slate-600"
                                    onClick={() =>
                                      cancelFileUpload(file?.filename)
                                    }
                                  >
                                    <DisabledByDefaultIcon />
                                  </button>
                                  </Tooltip>
                                </div>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </td>
                      {showST && 
                      <td className="border border-[#ddd] h-16 text-center">
                        <Checkbox
                          style={{ color: "purple" }}
                          checked={file?.simultaneous_transcript === undefined ? false : file?.simultaneous_transcript}
                          onChange={(e) =>
                            handleCheckbox("single", file?.filename)
                          }
                        />
                      </td>
                        }
                      {attributesDataHeader?.map((attr, attrIndex) => {
                        const datasetId = file?.dataset_id;
                        const attribute = file?.attribute_data?.find(
                          (a: any, index: any) =>
                            a.name === attr.name && index === attrIndex
                        );
                        return (
                          <td key={attrIndex} className="border border-[#ddd]" style={{ minWidth: "300px" }} >
                            <Tooltip title={attribute?.value}
                              placement="bottom-start"
                              PopperProps={{
                                modifiers: [
                                  {
                                    name: 'offset',
                                    options: {
                                      offset: [26, -25],
                                    },
                                  },
                                ],
                              }}>
                              <Input
                                className="w-full h-16 pl-8 text-center border border-b-0 "
                                value={attribute ? attribute?.value : ""}
                                onChange={(e) =>
                                  handleAttributeValueChange(
                                    e,
                                    attrIndex,
                                    file?.dataset_id,
                                    file?.filename
                                  )
                                }
                                placeholder="Enter attribute"
                              />
                            </Tooltip>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="add h-full flex bg-white w-[6%] items-center flex-col justify-center">
            {
              <div className="flex bg-white w-[6%] items-center flex-col justify-center cursor-pointer"
                onClick={() => { attributesDataHeader?.length < 2 && addAttributeColumns() }}
              >
                <Tooltip title={<span >Add new Attribute</span>} >
                  <AddCircleIcon
                    sx={{ color: attributesDataHeader?.length < 2 ? "#9747FF" : "gray", fontSize: "30px" }}
                  />
                </Tooltip>
                <span className="items-center justify-center w-[10%] flex">
                  Add{" "}
                </span>
                <span className="items-center justify-center w-[10%] flex">
                  Attributes{" "}
                </span>
              </div>
            }
          </div>
        </div>
        <Popup
          modal
          open={openPopupUpTOI || openPopupUpST || fileErrorPop}
          onClose={() => {
            setOpenPopupTOI(false);
            setOpenPopupST(false);
            setFileErrorPop(false);
          }}
          // overlayStyle={{
          //   backgroundColor: "rgba(0, 0, 0, 0.5)",
          //   width: "calc(100% + 10px)", // Increase width by 10px for 5px on each side
          //   height: "calc(100% + 10px)", // Increase height by 10px for 5px on each side
          //   top: "-5px", // Shift overlay up by 5px
          //   left: "-5px", // Shift overlay left by 5px
          //   position: "fixed",
          //   zIndex: 999, // Ensure the overlay is below the popup
          // }}
          overlayStyle={{ background: "transparent" }}
          contentStyle={
            openPopupUpTOI
              ? {
                width: "34vw",
                boxShadow: "0 4px 6px 4px rgba(0, 0, 0, 0.1), 0 2px 4px 4px rgba(0, 0, 0, 0.06)",
                backgroundColor: "white",
                borderRadius: "6px",
                position: "fixed",
                left: "10%",
                top: "42%",
                zIndex: 1000,
              }
              : openPopupUpST
                ? {
                  width: "36vw",
                  boxShadow: "0 4px 6px 4px rgba(0, 0, 0, 0.1), 0 2px 4px 4px rgba(0, 0, 0, 0.06)",
                  backgroundColor: "white",
                  borderRadius: "6px",
                  position: "fixed",
                  left: "45%",
                  top: "40%",
                  zIndex: 1000,
                }
                : undefined
          }
        >
          <div className="flex flex-col items-start mr-2 bg-transparent pb-6 pt-5 ml-8">
            <div className="flex justify-between items-center w-full mb-6 ">
              {fileErrorPop ? (
                <div className="flex gap-2 items-center justify-center text-red-600">
                  <Info size={13} /> <p className="text-red-600">File Error</p>
                </div>
              ) : (
                <p className="font-base text-lg  flex items-center underline">
                  What is this?
                </p>
              )}
              <XCircle
                className="mr-6  text-right"
                size={24}
                style={{ cursor: "pointer" }}
                fill="#BDBCBC"
                color="#FFFF"
                onClick={() => {
                  setOpenPopupTOI(false);
                  setOpenPopupST(false);
                  setFileErrorPop(false);
                }}
              />
            </div>

            <div
              className="font-base pb-6 -2 ml-2 mr-2 flex flex-col"
              style={{ fontSize: "12px", color: "#545454" }}
            >
              {openPopupUpTOI && (
                <>
                  <p className="text-black">
                    Our AI will look at the questions from the transcripts and
                    outline its own topics for analysis. It will also generate
                    its own discovered themes and classifactions. However, if
                    you have specific topics that you want it to report, you may
                    input them belore. For e.g.,
                  </p>
                  <ul className="list-disc pl-4 text text-purple-500">
                    <li>What are the some concerns around snacking.</li>
                    <li>Reactions to concept X</li>
                    <li>Ingredients, brands</li>
                    <li>Bus, car</li>
                  </ul>
                </>
              )}

              {openPopupUpST && (
                <>
                  <p className="text-black text-base">
                    Select the checkbox beside the file if it went through
                    *simultaneous transcription.
                  </p>
                  <p className="italic my-2 text-black">
                    *meaning while the original speaker is speaking, there is
                    another person who translates their words into a different
                    language in real-time(only 1 voice is available).
                  </p>
                </>
              )}

              {fileErrorPop && (
                <p className="text-red-600">
                  Error while translating {errorFile}: {errorMsg}
                </p>
              )}
            </div>
          </div>
        </Popup>
        {
          <Popup
            modal
            open={openPopup}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            contentStyle={{
              width: "34vw",
              boxShadow: "0px 0px 1px white",
              backgroundColor: "white",
              borderRadius: "6px",
            }}
          >
            <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
              <div className="flex justify-between items-center w-full mb-6">
                <p className="font-base text-lg  flex items-center pl-10 pr-10">
                  {" "}
                  <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
                </p>
                <XCircle
                  className="mr-6"
                  size={24}
                  style={{ cursor: "pointer" }}
                  fill="#BDBCBC"
                  color="#FFFF"
                  onClick={() => {
                    setOpenPopup(false);
                  }}
                />
              </div>

              <span
                className="font-base pb-6 -2 pl-10 pr-10"
                style={{ fontSize: "12px", color: "#545454" }}
              >
                Applying changes. Do you wish to proceed?
              </span>
              <div
                className=" mt-5 flex justify-between items-center ml-10 mr-10"
                style={{ width: "90%" }}
              >
                <button
                  style={{ color: " #8088A4", border: "none" }}
                  onClick={() => {
                    setOpenPopup(false);
                  }}
                >
                  Cancel
                </button>
                <Link to={`/edit-project/${projectId}/scope`}>
                  <button
                    onClick={() => {
                      setOpenPopup(false);
                      onProcess()
                    }}
                    className="border w-48 p-2 rounded"
                    style={{ border: "1px solid red", color: "red" }}
                  >
                    Proceed
                  </button>
                </Link>
              </div>
            </div>
          </Popup>
        }

        {openPopup1 && (
          <Popup
            modal
            open={openPopup1}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            contentStyle={{
              width: "34vw",
              boxShadow: "0px 0px 1px white",
              backgroundColor: "white",
              borderRadius: "6px",
            }}
          >
            <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
              <div className="flex justify-between items-center w-full mb-6">
                <p className="font-base text-lg  flex items-center pl-10 pr-10">
                  {" "}
                  <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
                </p>
                <XCircle
                  className="mr-6"
                  size={24}
                  style={{ cursor: "pointer" }}
                  fill="#BDBCBC"
                  color="#FFFF"
                  onClick={() => {
                    setOpenPopup1(false);
                  }}
                />
              </div>

              <span
                className="font-base pb-6 -2 pl-10 pr-10"
                style={{ fontSize: "12px", color: "#545454" }}
              >
                Changes will be discarded. Do you wish to proceed?{" "}
              </span>
              <div
                className=" mt-5 flex justify-between items-center ml-10 mr-10"
                style={{ width: "90%" }}
              >
                <button
                  style={{ color: " #8088A4", border: "none" }}
                  onClick={() => {
                    setOpenPopup1(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    // dispatch(getConfigureDataState({ project_id: projectId }));
                    dispatch(
                      getProjectConfiguration({
                        project_id: projectId,
                        activeStep: 1,
                      })
                    ).then((res: any) => {
                      if (res.meta.requestStatus === "fulfilled") {
                        let tempProjData = res?.payload?.response?.data?.topics_of_interests === "" ? { ...res?.payload?.response?.data, topics_of_interests: [] } : res?.payload?.response?.data;
                        tempProjData = "attribute_data" in tempProjData ? tempProjData : { ...tempProjData, attribute_data: [] }
                        setProjectSetupData({ ...tempProjData });
                      }
                    });
                    setOpenPopup1(false);
                    dispatch(setResetEnable(false))

                  }
                  }
                  className="border w-48 p-2 rounded"
                  style={{ border: "1px solid red", color: "red" }}
                >
                  Proceed
                </button>
              </div>
            </div>
          </Popup>

        )}
        <>
          <Popup
            modal
            open={configureLoader}
            closeOnDocumentClick={false}
            contentStyle={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <div className="flex flex-col items-start justify-center mr-2  bg-transparent pb-6 pt-5">
              <Spinner />
            </div>
          </Popup></>
      </div>
    </>
  );
}
