import { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../redux/store";
import EntityType from "./EntityType";
import EntityMaster from "./EntityMaster";
import EntitySentences from "./EntitySentences";
import { XCircle } from "react-feather";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import { useSelector } from "react-redux";
import { setScopeUpdated, setUpdateEntitySentencesDataLoading, updateIsDisable } from "../../redux/EditProject/EditProjectReducer";
import {
  getCEEnabledTabs,
  getEntityMaster,
  getEntitySentencesData,
  getEntityTypeData,
  updateEntityMaster,
  updateEntitySentenceData,
  updateEntityTypeData,
} from "../../redux/EditProject/action";


export default function Entities() {
  const dispatch = useAppDispatch();

  const entityMasterData = useSelector((state: RootState) => state.editProject.entityMasterData)

  const [selectedTab, setSelectedTab] = useState<string>("ENTITY_TYPE");
  const [clickedTab, setClickedTab] = useState<string>("ENTITY_TYPE");
  const [warning, setWarning] = useState([]);
  const [entityMaster, setEntityMaster] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup1, setOpenPopup1] = useState(false);
  const [openPopupReset, setOpenPopupReset] = useState(false)
  // const isResetEnabledButton = useSelector(
  //   (state: RootState) => state.editProject.isResetEnabled
  // );
  const projectId = useParams().id;

  const [entitySentenceWarning, setEntitySentenceWarning] = useState([]);
  const [warningsShow, setWarningsShow] = useState<any>([])
  const [resetSortFilter, setResetSortFilter] = useState(false)
  const [resetEnable, setResetEnable] = useState(false)


  useEffect(() => {
    setWarningsShow([])
    validation()
  }, [entitySentenceWarning, warning, entityMaster])

  useEffect(() => {
    if (openPopup === true) {
      setResetSortFilter(!resetSortFilter)
    }
  }, [openPopup])
  const handleResetEnable = (value: any) => {
    setResetEnable(value)
  }

  // const handleResetValuesCache = (value : any ) => {
  //   setvalueCache(value)
  // }



  function showCurrentTab(selTab: string) {
    switch (selTab) {
      case "ENTITY_TYPE":
        return <EntityType setWarning={setWarning} setResetSortFilter={setResetSortFilter} resetSortFilter={resetSortFilter} handleResetEnable={handleResetEnable} />;
      case "ENTITY_MASTER":
        return <EntityMaster setEntityMaster={setEntityMaster} setResetSortFilter={setResetSortFilter} resetSortFilter={resetSortFilter} handleResetEnable={handleResetEnable} />;
      case "ENTITY_SENTENCE":
        return (
          <EntitySentences
            setEntitySentenceWarning={setEntitySentenceWarning} setResetSortFilter={setResetSortFilter} resetSortFilter={resetSortFilter} handleResetEnable={handleResetEnable}
          />
        );
    }
  }
  //Validation Function for EntityTypeMaster
  const validation = () => {
    if (selectedTab === "ENTITY_TYPE") {
      let msg = "";
      warning.forEach((row: any, index: any) => {
        //If entity type is renamed but deleted
        //If entity type is renamed, merged, and deleted
        if (row.rename_to && row.merge_to && row.delete_type) {
          msg = `The entity type "${row.entity_type}" is deleted and will not be merged. Do you wish to Apply changes?`;
          setWarningsShow(((prevState: any) => {
            let temp = [...prevState]
            temp[index] = { ...temp[index], "message": msg }
            return temp
          }))
        } else if (row.rename_to && row.delete_type) {
          msg = `The entity type "${row.entity_type}" is deleted and renaming will not have any effect. Do you wish to Apply changes?`;
          setWarningsShow(((prevState: any) => {
            let temp = [...prevState]
            temp[index] = { ...temp[index], "message": msg }
            return temp
          }))
        }
        //If entity type is renamed but merged with anoth
        else if (row.rename_to && row.merge_to) {
          msg = `The entity type "${row.entity_type}" is merged with "${row.merge_to}" and renaming will not have any effect. Do you wish to Apply changes?`;
          setWarningsShow(((prevState: any) => {
            let temp = [...prevState]
            temp[index] = { ...temp[index], "message": msg }
            return temp
          }))
        }
        //If entity type is merged but deleted
        else if (row.merge_to && row.delete_type) {
          msg = `The entity type "${row.entity_type}" is deleted and will not be merged with "${row.merge_to}". Do you wish to Apply changes?`;
          setWarningsShow(((prevState: any) => {
            let temp = [...prevState]
            temp[index] = { ...temp[index], "message": msg }
            return temp
          }))
        }
        //If entity type is merged to another and the other is deleted
        else if (row.merge_to && warning.some((item: any) => item.entity_type === row.merge_to && item.delete_type)) {
          msg = `The entity type "${row.merge_to}" is deleted "${row.entity_type}" will not be merged. Do you wish to Apply changes?`;
          setWarningsShow(((prevState: any) => {
            let temp = [...prevState]
            temp[index] = { ...temp[index], "message": msg }
            return temp
          }))
        }
        // } else if (row.rename_to || row.merge_to || row.delete_type) {
        //   msg = `The changes will be applied. Do you wish to Apply changes?`;
        //   setWarningsShow(((prevState: any) => {
        //     let temp = [...prevState]
        //     temp[index] = { ...temp[index], "message": msg }
        //     return temp
        //   }))
        // }
      });
      return msg;


    } else if (selectedTab === "ENTITY_SENTENCE") {
      let msg = "";
      entitySentenceWarning.map((row: any, index: any) => {
        if (row.is_deleted && row.change_sentence_anchor) {
          msg = `The sentence "${row.sentence}" is deleted and will not be moved to theme "${row.change_sentence_anchor}". Do you wish to Apply changes?`;
          setWarningsShow(((prevState: any) => {
            let temp = [...prevState]
            temp[index] = { ...temp[index], "message": msg }
            return temp
          }))
        }
      });
      return msg;
    } else if (selectedTab === "ENTITY_MASTER") {
      let msg = "";
      entityMaster.map((row: any, index: any) => {
        if (row.merge_to && row.delete_anchor && row.rename_to) {
          msg = `The entity anchor "${row.anchor}" is deleted therefore will not be merged with "${row.merge_to}" or renamed. Do you wish to Apply changes?`;
          setWarningsShow(((prevState: any) => {
            let temp = [...prevState]
            temp[index] = { ...temp[index], "message": msg }
            return temp
          }))
        } else if (row.rename_to && row.delete_anchor) {
          msg = `The entity anchor "${row.anchor}" is deleted and renaming will not have any effect. Do you wish to Apply changes?`;
          setWarningsShow(((prevState: any) => {
            let temp = [...prevState]
            temp[index] = { ...temp[index], "message": msg }
            return temp
          }))
        } else if (row.rename_to && row.merge_to) {
          msg = `The entity anchor "${row.anchor}" is merged with "${row.merge_to}" and renaming will not have any effect. Do you wish to Apply changes?`;
          setWarningsShow(((prevState: any) => {
            let temp = [...prevState]
            temp[index] = { ...temp[index], "message": msg }
            return temp
          }))
        } else if (row.merge_to && row.delete_anchor) {
          msg = `The entity anchor "${row.anchor}" is deleted therefore will not be merged with "${row.merge_to}". Do you wish to Apply changes?`;
          setWarningsShow(((prevState: any) => {
            let temp = [...prevState]
            temp[index] = { ...temp[index], "message": msg }
            return temp
          }))
        }

        // else if (row.delete_anchor && row.delete_anchor !== row.anchor) {
        //   msg = `The entity anchor "${row.anchor}" is deleted therefore will not be moved to entity type "${row.move_to}". Do you wish to Apply changes?`;
        //   setWarningsShow(((prevState: any) => {
        //     let temp = [...prevState]
        //     temp[index] = { ...temp[index], "message": msg }
        //     return temp
        //   }))
        // }
        else if (row.delete_anchor && row.move_to) {
          msg = `The entity anchor "${row.anchor}" is deleted therefore will not be moved to entity type "${row.move_to}". Do you wish to Apply changes?`;
          setWarningsShow(((prevState: any) => {
            let temp = [...prevState]
            temp[index] = { ...temp[index], "message": msg }
            return temp
          }))


        }
        else if (row.is_user_defined && row.delete_anchor) {
          msg = `You are trying to delete "${row.anchor}" which is an user defined entity anchor. Do you wish to Apply changes?`;
          setWarningsShow(((prevState: any) => {
            let temp = [...prevState]
            temp[index] = { ...temp[index], "message": msg }
            return temp
          }))
        }
        else if (row.merge_to) {
          entityMaster?.map((item: any) => {
            if (item.anchor === row.merge_to) {
              if (item.delete_anchor) {
                msg = `The entity anchor "${row.anchor}" is merged to "${row.merge_to}" but you are trying to delete "${row.merge_to}" which involves deleting of "${row.anchor}". Do you wish to Apply changes?`;
                setWarningsShow(((prevState: any) => {
                  let temp = [...prevState]
                  temp[index] = { ...temp[index], "message": msg }
                  return temp
                }))
              }
            }
          })
        }
      });
      return msg
    }
  };

  const handleUpdateChnages = () => {
    dispatch(setScopeUpdated(false))
    if (selectedTab === "ENTITY_TYPE") {
      setOpenPopup(false);
      let delete_ent: any = [];
      let merge_ent: any = [];
      let rename_ent: any = [];

      warning.map((each: any) => {
        if (each.delete_type) {
          delete_ent.push(each?.entity_type);
        }
        if (each.merge_to && !(each.merge_to && warning.some((item: any) => item.entity_type === each.merge_to && item.delete_type))) {
          merge_ent.push({
            source_ent_type: each.entity_type,
            target_ent_type: each.merge_to,
          });
        }
        if (each.rename_to) {
          rename_ent.push({
            ent_type: each.entity_type,
            new_ent_name: each.rename_to

            // ent_type:each.entity_type,
            // new_ent_name:each.rename_to
          });
        }
      });


      const payload = {
        delete_entity_types: delete_ent,
        merge_entity: merge_ent,
        rename_ent: rename_ent,
      };
      dispatch(updateIsDisable(1));
      dispatch(updateEntityTypeData({ project_id: projectId, payload })).then(
        () => {
          dispatch(getEntityTypeData({ project_id: projectId }));
          dispatch(getCEEnabledTabs({ project_id: projectId }))
        }
      );
    } else if (selectedTab === "ENTITY_SENTENCE") {
      setOpenPopup(false);
      dispatch(setUpdateEntitySentencesDataLoading(true))
      let delete_sentence_map: any = [];
      let update_entity_map: any = [];
      entitySentenceWarning.map((item: any) => {
        if (item.is_deleted) {
          delete_sentence_map.push({
            "entity_id": item.entity_id,
            "sentence_id": item.sentence_id
          })
        } else if (item.change_sentence_anchor) {
          let targetId = ""
          entityMasterData.map((row: any) => {
            if (row.anchor === item.change_sentence_anchor) {
              targetId = row.entity_id
            }
          })
          update_entity_map.push({
            "target_entity_id": targetId,
            "sentence_id": item.sentence_id,
            "source_entity_id": item.entity_id
          })
        }
      })
      const payload = {
        "delete_sentence_map": delete_sentence_map,
        "update_entity_map": update_entity_map
      }
      dispatch(updateIsDisable(2));
      dispatch(updateEntitySentenceData({ project_id: projectId, payload })).then(
        () => {
          dispatch(getEntitySentencesData({ project_id: projectId }));
          dispatch(getCEEnabledTabs({ project_id: projectId }))
        }
      );
      // ID : 29
      // anchor: "Sausage Gun"
      // delete_anchor: false
      // entity_id: "CE5FE037-345A-4A9A-ADAB-D42A5EEAEBB1"
      // is_user_defined: true
      // merge_to: ""
      // move_to: ""
      // rename_to: ""
      // sentence_count: 0
      // ent_type: "Vijay"
    } else if (selectedTab === "ENTITY_MASTER") {
      setOpenPopup(false)
      let delete_ent_master: any = []
      let merge_ent_master: any = []
      let move_ent_master: any = []
      let rename_ent_master: any = []
      let user_def_ent_master: any = []

      entityMaster.map((each: any, index: any) => {
        if (each?.delete_anchor) {
          delete_ent_master.push(each?.entity_id)
        }

        if (each.merge_to) {
          const targetEntity: any = entityMaster?.find((item: any) => item.anchor === each.merge_to);
          merge_ent_master.push({
            source_ent: each.entity_id,
            target_ent: targetEntity?.entity_id
          });
        }
        if (each.rename_to) {
          rename_ent_master.push({
            ent_id: each.entity_id,
            new_name: each.rename_to,
          });
        }
        if (each.move_to) {
          move_ent_master.push({
            ent_id: each.entity_id,
            remapped_type: each.move_to,
          });
        }
        if (each.user_given) {
          user_def_ent_master.push({
            ent_id: each.entity_id,
            user_def: each.is_user_defined
          })
        }
      })

      const payload = {
        delete_ent: delete_ent_master,
        merge_ent: merge_ent_master,
        move_ent: move_ent_master,
        rename_ent: rename_ent_master,
        user_def_ent: user_def_ent_master
      }
      dispatch(updateIsDisable(3));
      dispatch(updateEntityMaster({ project_id: projectId, payload }))
        .then(() => {

          dispatch(getEntityMaster({ project_id: projectId }))
          dispatch(getCEEnabledTabs({ project_id: projectId }))
        })
    }
    setOpenPopup1(false)
  };

  const handleReset = () => {
    setOpenPopupReset(true)
    dispatch(setScopeUpdated(false))
  }


  const handleApplyAndContinue = () => {
    setSelectedTab(clickedTab);
    handleUpdateChnages()
    setOpenPopup1(false);
  };
  const handleContinueAnyway = () => {
    dispatch(setScopeUpdated(false))
    setSelectedTab(clickedTab);
    setOpenPopup1(false);
    setResetEnable(false)
  }
  const handleTabChange = (tab: string) => {
    if (resetEnable) {
      setOpenPopup1(true);
      setClickedTab(tab);
      // setResetEnable(false)
    } else {
      setSelectedTab(tab);
    }
  };


  const onApplyChangesTM = () => {
    if (validation() === '') {
      setOpenPopup(true)
    } else {
      setOpenPopup(true)
      setResetSortFilter(!resetSortFilter)
    }
  }

  return (
    <div className="w-full h-full">
      <div className="w-full h-[9%] p-2 mb-2 flex justify-between">
        <div className="h-full flex justify-start items-center gap-4">
          <div
            className={` text-white p-2 rounded-md cursor-pointer transform transition duration-300 
                        ${selectedTab === "ENTITY_TYPE"
                ? "bg-[#9747FF] scale-[1.05] shadow-lg"
                : "bg-[#CFABFF]"
              }`}
            onClick={() => {
              handleTabChange("ENTITY_TYPE");
            }}
          >
            Entity type Master
          </div>
          <div
            className={` text-white p-2 rounded-md cursor-pointer transform transition duration-300 
                        ${selectedTab === "ENTITY_MASTER"
                ? "bg-[#9747FF] scale-[1.05] shadow-lg"
                : "bg-[#CFABFF]"
              }`}
            onClick={() => {
              handleTabChange("ENTITY_MASTER");
            }}
          >
            Entity Master
          </div>
          <div
            className={` text-white p-2 rounded-md cursor-pointer transform transition duration-300 
                        ${selectedTab === "ENTITY_SENTENCE"
                ? "bg-[#9747FF] scale-[1.05] shadow-lg"
                : "bg-[#CFABFF]"
              }`}
            onClick={() => {
              handleTabChange("ENTITY_SENTENCE");
            }}
          >
            Entity Sentences
          </div>
        </div>
        <div className="h-full flex justify-end items-center gap-4">
          <button
            disabled={!resetEnable}
            className={`text-white p-2 rounded-md transform transition duration-300 ${resetEnable
              ? "bg-[#9747FF] cursor-pointer"
              : "bg-[#808080] cursor-not-allowed"
              } `}
            onClick={handleReset}
          >
            Reset
          </button>
          <button
            disabled={!resetEnable}
            className={`text-white p-2 rounded-md transform transition duration-300 ${resetEnable
              ? "bg-[#9747FF] cursor-pointer"
              : "bg-[#808080] cursor-not-allowed"
              } `}
            onClick={onApplyChangesTM}
          >
            Apply Changes
          </button>
        </div>
      </div>
      <div className="w-full h-[90%]">{showCurrentTab(selectedTab)}</div>

      <Popup
        modal
        open={openPopup}
        // onClose={() => setOpenPopup(false)}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        contentStyle={{
          width: "34vw",
          boxShadow: "0px 0px 1px white",
          backgroundColor: "white",
          borderRadius: "6px",
        }}
      >
        <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
          <div className="flex justify-between items-center w-full mb-6">
            <p className="font-base text-lg  flex items-center pl-10 pr-10">
              {" "}
              <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
            </p>
            <XCircle
              className="mr-6"
              size={24}
              style={{ cursor: "pointer" }}
              fill="#BDBCBC"
              color="#FFFF"
              onClick={() => {
                setOpenPopup(false);
              }}
            />
          </div>
          <div className="flex justify-center mx-8 max-h-[300px] overflow-y-auto">
            <div className="grid grid-cols-8 gap-2 justify-center" style={{ fontSize: "12px", color: "#545454" }}>
              {warningsShow.map((item: any, index: any) => {
                if (item) {
                  return (
                    <>
                      <div className="">row: {index + 1}</div>
                      <div className="col-span-7">{item.message}</div>
                    </>
                  )
                }
              })}
            </div>
          </div>
          {warningsShow?.length === 0 &&
            <span
              className="font-base pb-6 -2 pl-10 pr-10"
              style={{ fontSize: "12px", color: "#545454" }}
            >
              Applying changes. Do you wish to proceed?
            </span>
          }
          <div
            className=" mt-5 flex justify-between items-center ml-10 mr-10"
            style={{ width: "90%" }}
          >
            <button
              style={{ color: " #8088A4", border: "none" }}
              onClick={() => {
                setOpenPopup(false);
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleUpdateChnages();
                setOpenPopup(false);
              }}
              className="border w-48 p-2 rounded"
              style={{ border: "1px solid red", color: "red" }}
            >
              Proceed
            </button>
          </div>
        </div>
      </Popup>

      <Popup
        modal
        open={openPopup1}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        contentStyle={{
          width: "34vw",
          boxShadow: "0px 0px 1px white",
          backgroundColor: "white",
          borderRadius: "6px",
        }}
      >
        <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
          <div className="flex justify-between items-center w-full mb-6">
            <p className="font-base text-lg  flex items-center pl-10 pr-10">
              {" "}
              <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
            </p>
            <XCircle
              className="mr-6"
              size={24}
              style={{ cursor: "pointer" }}
              fill="#BDBCBC"
              color="#FFFF"
              onClick={() => {
                setOpenPopup1(false);
              }}
            />
          </div>

          <span
            className="font-base pb-6 -2 pl-10 pr-7"
            style={{ fontSize: "12px", color: "#545454" }}
          >
            {/* You are leaving the tab do you want to apply changes and continue else the changes will not be saved */}
            You are leaving the tab. Do you want to apply changes and continue? If not, the changes will not be saved.
          </span>
          <div
            className=" mt-5 flex justify-between items-center ml-10 mr-10"
            style={{ width: "90%" }}
          >
            <button
              style={{ color: " #8088A4", border: "none" }}
              onClick={handleContinueAnyway}
            >
              Continue anyway
            </button>
            <button
              onClick={handleApplyAndContinue}
              className="border w-48 p-2 rounded"
              style={{ border: "1px solid red", color: "red" }}
            >
              Apply and continue
            </button>
          </div>
        </div>
      </Popup>
      <Popup
        modal
        open={openPopupReset}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        contentStyle={{
          width: "34vw",
          boxShadow: "0px 0px 1px white",
          backgroundColor: "white",
          borderRadius: "6px",
        }}
      >
        <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
          <div className="flex justify-between items-center w-full mb-6">
            <p className="font-base text-lg  flex items-center pl-10 pr-10">
              {" "}
              <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
            </p>
            <XCircle
              className="mr-6"
              size={24}
              style={{ cursor: "pointer" }}
              fill="#BDBCBC"
              color="#FFFF"
              onClick={() => setOpenPopupReset(false)}
            />
          </div>

          <span
            className="font-base pb-6 -2 pl-10 pr-10"
            style={{ fontSize: "12px", color: "#545454" }}
          >
            Changes will be discarded. Do you wish to proceed?{" "}
          </span>
          <div
            className=" mt-5 flex justify-between items-center ml-10 mr-10"
            style={{ width: "90%" }}
          >
            <button
              style={{ color: " #8088A4", border: "none" }}
              onClick={() => setOpenPopupReset(false)}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                if (selectedTab === "ENTITY_SENTENCE") {
                  dispatch(getEntitySentencesData({ project_id: projectId }));
                } else if (selectedTab === 'ENTITY_TYPE') {
                  dispatch(getEntityTypeData({ project_id: projectId }))
                  // handleResetValuesCache(valueCache)
                } else if (selectedTab === 'ENTITY_MASTER') {
                  dispatch(getEntityMaster({ project_id: projectId }))

                }
                setOpenPopupReset(false)
                setResetEnable(false)
              }}
              className="border w-48 p-2 rounded"
              style={{ border: "1px solid red", color: "red" }}
            >
              Proceed
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}
