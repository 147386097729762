import { axiosconfig } from "../../service/apiconfig";


export const LLMAPI = {
    ChatStatus : ({project_id}:any) =>{
        return axiosconfig.post(`status?project_id=${project_id}`,{
            project_id:project_id
        })
    },
    ChatStart : ({project_id}:any) =>{
        return axiosconfig.post(`start?project_id=${project_id}`,{
            project_id:project_id
        })
    },
    ChatQuery : ({session_id, query}:any) =>{
        return axiosconfig.post(`query?session_id=${session_id}&query=${query}`,{
            session_id:session_id,query:query
        })
    },
    ChatPopulation : ({project_id}:any) =>{
        return axiosconfig.post(`populate?project_id=${project_id}`,{
            project_id:project_id,
        })
    },
}