import { useEffect, useState } from 'react';
import ThemeComponent from "../../components/ThemeComponent/ThemeComponent";
import { getThemeSentences, getThemes, getTranscriptDataList, updateSentencePPTSelection } from "../../redux/Themes/action";
import { RootState, useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ChevronsLeft, ChevronsRight } from 'react-feather';
import SummaryView from '../../components/SummaryView/SummaryView';
import SentenceView from '../../components/SentenceView/SentenceView';

interface IViewMode {
  project_id: any
  selectedSentiment: string
}

export default function ViewMode(props: IViewMode) {

  const dispatch = useAppDispatch()
  const pathname = useLocation().pathname;
  const projectId = pathname.split('/')[2];
  const themeLoader = useSelector((state: RootState) => state.themes.themeLoader)
  const allThemes = useSelector((state: RootState) => state.themes.allThemes)
  const SelectedQb = useSelector((state: RootState) => state.master.selectedQuestionBuc)
  const sentences = useSelector((state: RootState) => state.themes.allThemesSentence)
  const sentenceLoader = useSelector((state: RootState) => state.themes.sentenceLoader)
  const allTranscripts = useSelector((state: RootState) => state.themes.allTranscripts)

  const [selectedFilter, setSelectedFilter] = useState("tone_strength");
  const [showSentence, setShowSentence] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState<any>({});
  const [showThemes, setShowThemes] = useState<boolean>(true)
  const [maximizeView, setMaximizeView] = useState<boolean>(false)
  const [filterSenti, setFilterSenti] = useState<string>("All")
  const [lightningOnly, setLightningOnly] = useState<boolean>(false)
  const [jumpTo, setJumpTo] = useState<string>("")


  const clickOnTheme = (themeItem: any) => {
    if (themeItem === selectedTheme) {
      setSelectedTheme({})
      setShowSentence(false)
    }
    else {
      setSelectedTheme(themeItem)
      setShowSentence(true)
    }
  }

  const getLayout = () => {
    let availabel = 8
    if (maximizeView) {
      return availabel
    }
    if (showThemes) {
      availabel = availabel - 2
    }
    if (showSentence) {
      availabel = availabel / 2
    }
    return availabel
  }

  const handleSearchSentence = (search: string) => {
    if (Object.keys(selectedTheme).length > 0) {
      let payload: any = {
        project_id: props.project_id,
        theme_id: selectedTheme?.id?.map((item: any) => item.id),
        order_by: selectedFilter,
        transcript_id: jumpTo,
        included_in_ppt_only: lightningOnly,
        sentiment: filterSenti === "All" ? null : filterSenti
      }
      if (SelectedQb !== "All") {
        payload["question_bucket_id"] = [SelectedQb?.question_bucket_id]
      }
      if (search.length > 0) {
        payload["search"] = search
      }
      dispatch(getThemeSentences(payload));
    }
  }

  useEffect(() => {
    dispatch(getTranscriptDataList({ project_id: props.project_id }))
  }, [])

  useEffect(() => {
    if (allTranscripts.length > 0) {
      setJumpTo(allTranscripts[0]?.dataset_id)
    }
  }, [allTranscripts])

  useEffect(() => {
    if (SelectedQb !== "All") {
      dispatch(getThemes({ project_id: props.project_id, sentiment: props.selectedSentiment, question_bucket_id: SelectedQb.question_bucket_id }))
    }
    else {
      dispatch(getThemes({ project_id: props.project_id, sentiment: props.selectedSentiment }))
    }
    setSelectedTheme({})
    setShowSentence(false)
    setShowThemes(true)
    setMaximizeView(false)
    setFilterSenti("All")
  }, [props.selectedSentiment, SelectedQb])

  useEffect(() => {
    if (Object.keys(selectedTheme).length > 0) {
      let payload: any = {
        project_id: props.project_id,
        theme_id: selectedTheme?.id?.map((item: any) => item.id),
        order_by: selectedFilter,
        transcript_id: jumpTo,
        included_in_ppt_only: lightningOnly,
        sentiment: filterSenti === "All" ? null : filterSenti
      }
      if (SelectedQb !== "All") {
        payload["question_bucket_id"] = [SelectedQb?.question_bucket_id]
      }
      dispatch(getThemeSentences(payload));
    }
  }, [selectedTheme, selectedFilter, jumpTo, lightningOnly, filterSenti])

  useEffect(() => {
    //setApplicableThemes(allThemes)
    //setShowThemeData(findFeature(featuresData, "THEMES_GET_DATA"))
  }, [allThemes])

  const handlePPTSelection = (item: any) => {
    dispatch(updateSentencePPTSelection({
      projectId: projectId,
      updatedPPTSelection: {
        sentence_id: [item.sentence_id],
        show: !item.show_in_ppt
      }
    }));
  };

  useEffect(() => {
    if (maximizeView) {
      setShowThemes(false)
    }
    else {
      setShowThemes(true)
    }
  }, [maximizeView])

  return (
    <div className="w-full grid grid-cols-8 gap-4 h-full relative">
      {Object.keys(selectedTheme).length > 0 &&
        <div className="flex justify-between items-center w-fit cursor-pointer absolute" style={{ left: "91%", top: "-6%" }} onClick={() => setMaximizeView(!maximizeView)}>
          {maximizeView ?
            <ChevronsRight color="gray" size={18} />
            :
            <ChevronsLeft color="gray" size={18} />
          }
          <span className="font-semibold text-gray-500 whitespace-nowrap">{maximizeView ? "Minimize View" : "Maximize View"}</span>
        </div>
      }
      {showThemes &&
        <div className="Theme col-span-2 pt-0.5">
          <ThemeComponent
            type="view"
            dataList={allThemes}
            selectedTheme={selectedTheme}
            clickOnTheme={clickOnTheme}
            showLoader={themeLoader}
            setShowTheme={setShowThemes}
          />
        </div>
      }
      {!maximizeView &&
        <div className={`Summary mt-0.5 col-span-${getLayout()}`}>
          <SummaryView
            selectedTheme={selectedTheme}
            dataList={allThemes}
            showLoader={themeLoader}
            showTheme={showThemes}
            setShowTheme={setShowThemes}
          />
        </div>
      }
      {showSentence &&
        <div className={`Sentences flex flex-col justify-start ${maximizeView ? "col-start-1 col-end-9" : `col-span-${getLayout()}`}`}>
          <SentenceView
            dataList={sentences}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            showLoader={sentenceLoader}
            selectedSentiment={props.selectedSentiment}
            handlePPTSelection={handlePPTSelection}
            larger={!showThemes || maximizeView}
            filterSenti={filterSenti} setFilterSenti={setFilterSenti}
            lightningOnly={lightningOnly} setLightningOnly={setLightningOnly}
            allTranscripts={allTranscripts}
            jumpTo={jumpTo} setJumpTo={setJumpTo}
            handleSearchSentence={handleSearchSentence}
            sentenceMatrix={[]}
            selectedQuestionB={[]}
          />
        </div>
      }
    </div>

  );
}
