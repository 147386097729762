import React, { useEffect, useRef, useState } from "react";
import { RootState, useAppDispatch } from "../../redux/store";
import { InputAdornment, TextField } from "@mui/material";
import { Search } from "react-feather";
import SendIcon from "@mui/icons-material/Send";
import {
  ChatPopulation,
  ChatStart,
  ChatStatus,
} from "../../redux/LLM/action";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { updateError, updateQueryResponse, updateResponse } from "../../redux/LLM/llmReducer";
import { QueryResponseComponent } from "./QueryResponseComponent";
import ErrorHandling from "./errorHandling";
import Spinner from "../../components/Spinner";
import settings from "../../config";
import { updateCurrentPage } from "../../redux/currentPage";


export const LLMQuery = () => {
  const baseUrl = settings.REACT_APP_API_URL;
  const dispatch = useAppDispatch();
  const queryRef: any = useRef(null);
  const projectId = useLocation().pathname.split("/")[2];
  const [text, setText] = useState<any>(0);
  const [populate, setPopulate] = useState<boolean>(false);

  const [isTextFieldScrollable, setIsTextFieldScrollable] = useState<boolean>(false);
  const [responseGenerating, setResponseGenerating] = useState<boolean>(false);

  const [maxHeight, setMaxHeight] = useState<number>(1);
  const [queryInput, setQueryInput] = useState<string>("");
  const [check, setCheck] = useState<string>();
  const [loader, setLoader] = useState<boolean>(true);


  const sessionId = useSelector((state: RootState) => state.llm.sessionId);
  const QueryResponse = useSelector(
    (state: RootState) => state.llm.QueryResponse
  );
  const QueryError = useSelector((state: RootState) => state.llm.QueryError);
  const QueryErrorMessage = useSelector(
    (state: RootState) => state.llm.QueryErrorMessage
  );
  const projectDetails = useSelector(
    (state: RootState) => state.project.projectDetails
  );

  const StatusData = useSelector((state: RootState) => state.llm.StatusData);
  useEffect(() => {
    dispatch(updateCurrentPage("Query"));
    dispatch(
      ChatStatus({
        project_id: projectId,
      })
    ).then((response: any) => {


      if (
        response?.payload?.detail === "project data is not populated" ||
        (response?.payload?.response?.source !== "vector-db" &&
          response?.payload?.response?.session_transcript_ids === "") || response?.meta?.requestStatus !== "fulfilled"
      ) {
        setPopulate(true);
        setLoader(false)
        dispatch(
          ChatPopulation({
            project_id: projectId,
          })
        ).then(() => {
          setPopulate(true);
        });
      } else {
        setPopulate(false);
        if (response.payload.response.status === 'Ready') {
          dispatch(ChatStart({ project_id: projectId })).then((response: any) => {
            setLoader(false)
            if (response.meta.requestStatus !== "fulfilled") {
              setPopulate(true)
            }
          });
        } else {
          setLoader(false)
          setPopulate(true)
        }
      }
    });
  }, []);

  useEffect(() => {
    const height = queryRef.current?.getBoundingClientRect().top;
    setMaxHeight(window.innerHeight - height - 75);
  });

  const handleQuery = ((query: any) => {
    if (query.length <= 0) {
      return;
    }

    setResponseGenerating(true);

    if (queryInput !== "") {
      dispatch(
        updateQueryResponse({
          session_id: sessionId,
          query: query,
          response: "",
          error: false,
        })
      );
      setQueryInput("");
      setCheck("");

      if (query !== "") {
        console.time("start api call");
        const eventSource = new EventSource(
          `${baseUrl}query?session_id=${sessionId}&query=${query}`
        );

        query = "";

        // Initialize streamData
        let dataPopulationstarted = false
        let byteArray: number[] = [];

        let first_time = false;

        eventSource.onmessage = (event) => {
          if (first_time === false) {
            console.timeEnd("start api call");
            first_time = true;
            console.time("streaming started");
          }
          let data = JSON.parse(event.data);
          dataPopulationstarted = true;
          setTimeout(() => {
            data.forEach((element: any) => {
              byteArray.push(element);
              const string_data = returnString(byteArray);
              dispatch(updateResponse(string_data));
            });
          }, 5);
        };

        eventSource.onerror = (event) => {
          console.timeEnd("streaming started");
          if (!dataPopulationstarted) {
            dispatch(updateError("Couldn't generate response. Please try again."));
          }
          if (event.eventPhase === EventSource.CLOSED) {
            console.log("Connection closed");
            setResponseGenerating(false);
            eventSource.close();
          }
        };
      }
    }


    function returnString(byteArray: number[]) {
      const uint8Array = new Uint8Array(byteArray);
      const utf8String = new TextDecoder().decode(uint8Array);
      return utf8String;
    }
  });


  const handleLines = () => {
    if (text <= 2) {
      setText(text + 1);
    } else {
      setIsTextFieldScrollable(true);
    }
  };
  const responseContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (responseContainerRef.current && QueryResponse) {
      responseContainerRef.current.scrollTop = responseContainerRef.current.scrollHeight;
    }
  }, [QueryResponse]);
  return (
    <div
      ref={queryRef}
      className="w-full flex justify-center h-full"
    >
      <div className="w-3/5 bg-white  rounded shadow-md" style={{ height: maxHeight }}>
        {loader ? (
          <div className="h-full flex justify-center">
            <Spinner height="100%" />
          </div>
        ) : (
          <>
            <div
              ref={responseContainerRef}
              className="flex flex-col justify-start overflow-y-auto h-[80%] w-full"
            >
              {QueryResponse.length > 0 ? (
                QueryResponse?.map((each: any, index: number) => (
                  <QueryResponseComponent data={each} />
                ))
              ) : populate ? (
                <ErrorHandling />
              ) : (
                <div className="flex flex-col justify-center items-center text-lg text-gray-700 font-medium mt-12">
                  <img
                    src={`/images/EzyThemes logo _vertical_white bg.svg`}
                    className="w-28 h-28 mr-2"
                    alt="Ezythemes"
                  />
                  <h1 className="text-3xl font-bold mt-4">
                    How can I help you today?
                  </h1>
                </div>
              )}
            </div>
            {!populate && (
              <div className="flex justify-center items-end h-[20%] overflow-y-auto pb-3">
                <TextField
                  value={queryInput}
                  className="w-11/12 bg-gray-100/50 text-lg"
                  disabled={!sessionId || responseGenerating}
                  placeholder="Ask me anything"
                  type="text"
                  style={{ maxHeight: "100px" }}
                  onChange={(e: any) => {
                    setQueryInput(e.target.value);
                  }}
                  inputRef={(input) => {
                    if (input) {
                      input.scrollTop = input.scrollHeight;
                    }
                  }}
                  InputProps={{
                    style: { fontSize: 15, maxHeight: "100%" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search className="w-5 h-5" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <span
                          onClick={() =>
                            queryInput.trim() !== "" && !responseGenerating
                              ? handleQuery(queryInput)
                              : ""
                          }
                          className={`w-10 h-10 rounded flex justify-center items-center ${queryInput
                            ? responseGenerating
                              ? "bg-gray-400 cursor-not-allowed"
                              : "bg-purple-600 cursor-pointer"
                            : "bg-gray-400"
                            }`}
                        >
                          {responseGenerating ? (
                            <StopCircleIcon className={`text-white`} />
                          ) : (
                            <SendIcon className={`text-white`} />
                          )}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      setText(1);
                      if (queryInput.trim() !== "" && !responseGenerating) {
                        handleQuery(queryInput)
                      }
                    } else if (e.key === "Enter" && e.shiftKey) {
                      e.preventDefault();
                      setQueryInput((prevText) => prevText + "\n");
                      handleLines();
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                      }
                      if (queryInput.trim() === "") {
                        setText(1);
                      }

                      const lines = queryInput.split("\n").length;
                      const newText = Math.min(3, lines);
                      setText(newText);

                      if (newText <= 3) {
                        setIsTextFieldScrollable(false);
                      }
                    }
                  }}
                  // onKeyUp={(e: any) => {
                  //   if (
                  //     e.key === "Enter" &&
                  //     queryInput.trim() !== "" &&
                  //     !responseGenerating
                  //   ) {
                  //     e.preventDefault();
                  //     setQueryData(queryInput);
                  //     setIsTextFieldScrollable(false);
                  //   }
                  //   if (queryInput.trim() === "") {
                  //
                  //   }
                  // }}
                  id="fullWidth"
                  multiline
                  rows={text}
                  inputProps={{
                    maxRows: 3,
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};