import { useEffect } from "react"
import { useSelector } from "react-redux"
import { getAssociations, getAttributes, getMasterWordCloudData, getMentions, getQBPhrases, getQuestionBucket } from "../redux/Masters/action"
import { updateRenameAssocSuccess, updateSelectedQB, updateCurrentPaginationPage } from "../redux/Masters/masterDataReducer"
import { RootState, useAppDispatch } from "../redux/store"
import { useLocation } from 'react-router-dom';

export default function OutputWrapper() {

    const dispatch = useAppDispatch();
    const pathname = useLocation().pathname;
    const id = pathname.split('/')[2]
    const masterSentiment = useSelector((state: RootState) => state.master.masterSentiment)
    const questionbuckets = useSelector((state: RootState) => state.master.formattedQbs)
    const renameAssocSuccess = useSelector((state: RootState) => state.master.renameAssocSuccess)
    const selectedQbs = useSelector((state: RootState) => state.master.selectedQuestionBuc)
    const selectedAttr = useSelector((state: RootState) => state.master.selectedAttr)
    const paginationLimit = useSelector((state: RootState) => state.master.paginationLimit);
    const currentPage = useSelector((state: RootState) => state.master.currentPage);


    function formatAttributesList(arrayList: any) {
        let newList: any = []
        arrayList?.map((item: any) => {
            const found = newList?.filter((elem: any) => elem.name === item.attr_id).length > 0;
            if (!found) {
                newList?.push({
                    name: item.attr_id,
                    value: [item.name],
                })
            } else {
                newList = newList?.map((elem: any) => {
                    if (elem.name === item.attr_id) {
                        return {
                            ...elem,
                            value: [...elem.value, item.name]
                        }
                    } else {
                        return elem
                    }
                })
            }
        })
        return newList
    }

    function getQuestionBucketIds(selectedQbs: any[]) {
        let seleQbs: Array<number> = [];
        selectedQbs.map((item: any) => {
            item.question_buckets.map((elem: any) => {
                seleQbs.push(elem.question_bucket_id);
            });
        });
        return seleQbs;
    }

    useEffect(() => {
        if (id) {
            dispatch(getQuestionBucket({ project_id: id }))
            dispatch(getAttributes({ project_id: id }))
        }
    }, [])

    useEffect(() => {
        const seleQbs = getQuestionBucketIds(selectedQbs);
        const updatedAttributesList = formatAttributesList(selectedAttr);

        if (seleQbs.length !== 0 && (updatedAttributesList.length !== 0 || updatedAttributesList.length === 0)) {
            dispatch(getQBPhrases({
                project_id: id, selectedQbs: seleQbs, sentiment: masterSentiment,
                attributes: updatedAttributesList,
                offset: paginationLimit * (currentPage - 1),
                limit: paginationLimit,
                associations: [],
                mentions: [],
                search_phrase: "",
                search_sentence: ""
            }))

            dispatch(getAssociations({
                project_id: id,
                selectedQbs: seleQbs,
                sentiment: masterSentiment,
                attributes: updatedAttributesList
            }))

            dispatch(getMentions({
                project_id: id,
                selectedQbs: seleQbs,
                sentiment: masterSentiment,
                attributes: updatedAttributesList
            }))

            dispatch(getMasterWordCloudData({
                project_id: id,
                selectedQbs: seleQbs,
                sentiment: masterSentiment,
                attributes: updatedAttributesList
            }))

        }
        dispatch(updateCurrentPaginationPage(1))
    }, [masterSentiment, selectedQbs, selectedAttr])

    useEffect(() => {
        dispatch(updateSelectedQB(questionbuckets.filter((item: any) => item.active === true)))
    }, [questionbuckets])

    useEffect(() => {
        if (renameAssocSuccess) {
            dispatch(updateRenameAssocSuccess(false))
        }
    }, [renameAssocSuccess])



    return (
        <></>
    )
}