import React, { useEffect, useRef, useState } from "react";
import {
	Checkbox,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	ThemeProvider,
	Tooltip,
	createTheme,
} from "@mui/material";
import { RootState, useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import { addSavedView, deleteSavedView, getIntersectingAreas, getSavedView, getWordCloudData } from "../../redux/CompareWordCloud/action";
import { useLocation } from "react-router-dom";
import GeneralWordCloud from "./GeneralWordCloud";
import Spinner from "../Spinner";
import { updateCurrentPage } from "../../redux/currentPage";
import { updateSelectedWordCloudAttr } from "../../redux/CompareWordCloud/dataReducer";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const theme = createTheme({
	palette: {
		primary: {
			main: "#9747FF",
		},
	},
});

export default function WordCloudComponent({ areasToShow, selectedTopic, wordCloudViewViz, setWordCloudViewViz, analyzeByDataIsLoading, worldcloudDeleteID, savedViewRenameItem, setAddBookMarkToggle }: any) {
	const masterAttr = useSelector(
		(state: RootState) => state.master.masterAttributes
	);
	const attributeSelection = useSelector((state: RootState) => state.compare.selectedWordCloudAttr)

	const [words, setWords] = useState<any>([]);
	const dispatch = useAppDispatch();
	const pathname = useLocation().pathname;
	const projectId = pathname.split("/")[2];
	const heightRef = useRef<any>(null);
	const [height, setHeight] = useState<number>(0);
	const [width, setWidth] = useState<number>(1);
	const [areasSelection, setAreasSelection] = useState<any>([]);
	const [bookMark, setBookMark] = useState<boolean>(false);
	const [bookMarkID, setBookMarkID] = useState<any>(null);
	const [selectedItems, setSelectedItems] = useState<any>([])
	const [wordCloudResponseData, setWordCloudResponseData] = useState([])
	const [payload, setPayload] = useState<any>({})
	const [cloudName, setCloudName] = useState<any>(null)
	const [heading, setHeading] = useState<any>('')
	const [loading, setLoading] = useState(false)
	const [invalidSelection, setInvalidSelection] = useState(false)
	const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});

	const toggleGroup = (groupId: any) => {
		setExpandedGroups(prevState => ({
			...prevState,
			[groupId]: !prevState[groupId]
		}));
	};

	const AttributesData = masterAttr !== undefined ? Object.keys(masterAttr)?.map((key: any) => {
		return {
			id: key,
			name: masterAttr[key].name,
			value: masterAttr[key].value,
		};
	}) : [];

	useEffect(() => {
		if (!attributeSelection.length) {
			dispatch(updateSelectedWordCloudAttr(AttributesData))
		}
	}, [masterAttr])

	//checks if any attribute in the attributeSelection array has a value selected, setting invalidSelection to true otherwise false
	useEffect(() => {
		let selection = true
		attributeSelection.map((item: any) => {
			if (item?.value.length > 0) {
				selection = false
			}
		})
		setInvalidSelection(selection)
	}, [attributeSelection])

	useEffect(() => {
		dispatch(updateCurrentPage("Cloud"));
	}, [pathname])


	useEffect(() => {
		dispatch(getSavedView({ project_id: projectId, is_compare_view: false }));
	}, [])

	const updateSelection = () => {
		if (wordCloudViewViz) {
			setWordCloudViewViz(null)
		} else {
			setLoading(true)
			setAreasSelection(areasToShow);

			let areas: any = ["All"]
			let config_1: any = []
			// AttributesData.map((item: any) => {
			attributeSelection.map((item: any) => {
				if (item.value.length > 0) {
					config_1.push({
						name: item.id,
						value: [...item.value]?.sort((a, b) => a - b)
					})
				}

			})

			let payload = {}
			if (selectedTopic) {
				payload = {
					analyze_by: {
						type: selectedTopic.type === 'Reporting Buckets' ? 'Analysis Topics' : selectedTopic.type,
						bucket_id: selectedTopic.id,
						name: selectedTopic.display_name
					},
					areas: [...areas]?.sort((a, b) => a - b),
					config_1: config_1,
					is_compare_view: false
				}
			} else {
				payload = {
					areas: [...areas]?.sort((a, b) => a - b),
					config_1: config_1,
					is_compare_view: false
				}
			}

			setPayload(payload)
			setCloudName(null)
			dispatch(getWordCloudData({
				project_id: projectId,
				payload: payload
			}))
				.then((res: any) => {
					setWordCloudResponseData(res.payload.response.data.data)
					setBookMark(res.payload.response.data.is_saved_view)
					setBookMarkID(res.payload.response.data.ID)
					setCloudName(res.payload.response.data.NAME)
					setLoading(false)
				})
				.catch((error: any) => {
					console.error("Error fetching word cloud data:", error);
					setLoading(false)
				});
		}
	}

	useEffect(() => {
		if (!analyzeByDataIsLoading) {
			updateSelection()
		}
	}, [areasToShow, analyzeByDataIsLoading])

	//used to handle updates when wordCloudViewViz changes and perform data fetching and state updates accordingly
	useEffect(() => {
		if (wordCloudViewViz) {
			let tempAreas: any = []
			const tempAttribute = AttributesData.map((attritem: any) => {
				let val: any = []
				wordCloudViewViz.CONFIG_1.map((veiwvizitem: any) => {
					if (attritem.id === veiwvizitem.name) {
						val = veiwvizitem.value
					}
				})
				return { ...attritem, value: val }
			})
			if (wordCloudViewViz.AREAS.length > 0 && wordCloudViewViz.AREAS[0] === 'All') {
				if (selectedTopic) {
					dispatch(getIntersectingAreas({
						project_id: projectId,
						bucket_id: selectedTopic?.id
					})).then((res: any) => {
						if (res.meta.requestStatus === "fulfilled") {
							if (Array.isArray(res.payload.response.data)) {
								setAreasSelection(res.payload.response.data)
							} else {
								setAreasSelection([])
							}
						}

					})
				}
				else {
					setAreasSelection(areasToShow);
				}
				tempAreas = ['All']
			}
			else {
				setAreasSelection(wordCloudViewViz.AREAS);
				tempAreas = wordCloudViewViz.AREAS
			}


			dispatch(updateSelectedWordCloudAttr(tempAttribute))

			let config_1: any = []
			tempAttribute.map((item: any) => {
				if (item.value.length > 0) {
					config_1.push({
						name: item.id,
						value: [...item.value]?.sort((a, b) => a - b)
					})
				}
			})
			let payload = {}
			if (selectedTopic) {
				payload = {
					analyze_by: {
						type: selectedTopic.type === 'Reporting Buckets' ? 'Analysis Topics' : selectedTopic.type,
						bucket_id: selectedTopic.id,
						name: selectedTopic.display_name
					},
					areas: [...tempAreas]?.sort((a, b) => a - b),
					config_1: config_1,
					is_compare_view: false
				}
			} else {
				payload = {
					areas: [...tempAreas]?.sort((a, b) => a - b),
					config_1: config_1,
					is_compare_view: false
				}
			}

			setPayload(payload)

			setLoading(true)
			setCloudName(null)
			dispatch(getWordCloudData({
				project_id: projectId,
				payload: payload
			}))
				.then((res: any) => {
					setWordCloudResponseData(res.payload.response.data.data)
					setBookMark(res.payload.response.data.is_saved_view)
					setBookMarkID(res.payload.response.data.ID)
					setCloudName(res.payload.response.data.NAME)
					setLoading(false)
				})
				.catch((error: any) => {
					console.error("Error fetching word cloud data:", error);
					setLoading(false)
				});

			if (!selectedTopic) {
				setWordCloudViewViz(null)
			}
		}
	}, [wordCloudViewViz])

	useEffect(() => {
		const TopPos = heightRef.current?.getBoundingClientRect().top;
		const LeftPos = heightRef.current?.getBoundingClientRect().left;
		setHeight(window.innerHeight - TopPos - 50);
		setWidth(window.innerWidth - LeftPos - 15);
	});

	useEffect(() => {
		let tempWords: any = []
		if (Array.isArray(wordCloudResponseData)) {
			wordCloudResponseData?.map((item: any, index: any) => {
				tempWords.push({
					text: /* item.is_ai_discovered ? `⌖ ${item.name}` : */ item.name,
					value: item.count,
					sentiment: item.sentiment,
					loadCount: index + 1
				})
			})
		}
		setWords(tempWords)
	}, [wordCloudResponseData])

	//Updates the selectedItems state based on values in attributeSelection.
	useEffect(() => {
		let temp: any = []
		attributeSelection?.map((attr: any) => {
			attr.value?.map((item: any) => {
				temp.push(item)
			})
		})
		setSelectedItems(temp)
	}, [attributeSelection])


	const updateAreasSelection = (area: any) => {
		let updateAreasSelection: any = []
		const index = areasSelection.findIndex((item: any) => item === area);
		if (index !== -1) {
			const temp = [
				...areasSelection.slice(0, index),
				...areasSelection.slice(index + 1),
			];
			updateAreasSelection = temp
		} else {
			const temp = [...areasSelection, area];
			updateAreasSelection = temp
		}
		setAreasSelection(updateAreasSelection)

		setLoading(true)
		const tempPayload = { ...payload, areas: [...updateAreasSelection]?.sort((a, b) => a - b) }
		setPayload(tempPayload)
		setCloudName(null)
		dispatch(getWordCloudData({
			project_id: projectId,
			payload: tempPayload
		}))
			.then((res: any) => {
				setWordCloudResponseData(res.payload.response.data.data)
				setBookMark(res.payload.response.data.is_saved_view)
				setBookMarkID(res.payload.response.data.ID)
				setCloudName(res.payload.response.data.NAME)
				setLoading(false)
			})
			.catch((error: any) => {
				console.error("Error fetching word cloud data:", error);
				setLoading(false)
			});
	};

	const areaIsChecked = (area: any) => {
		return areasSelection?.some((item: any) => {
			return item === area
		});
	};

	// toggles the selection of all areas in the WordCloudComponent.
	const areasSelectionSelectAll = () => {
		if (areasToShow.length === areasSelection.length) {
			setAreasSelection([]);
			setLoading(true)
			const tempPayload = { ...payload, areas: [] }
			setPayload(tempPayload)
			setCloudName(null)
			dispatch(getWordCloudData({
				project_id: projectId,
				payload: tempPayload
			}))
				.then((res: any) => {
					setWordCloudResponseData(res.payload.response.data.data)
					setBookMark(res.payload.response.data.is_saved_view)
					setBookMarkID(res.payload.response.data.ID)
					setCloudName(res.payload.response.data.NAME)
					setLoading(false)
				})
				.catch((error: any) => {
					console.error("Error fetching word cloud data:", error);
					setLoading(false)
				});
		} else {
			setAreasSelection(areasToShow);
			setLoading(true)
			const tempPayload = { ...payload, areas: [...areasToShow]?.sort((a, b) => a - b) }
			setPayload(tempPayload)
			setCloudName(null)
			dispatch(getWordCloudData({
				project_id: projectId,
				payload: tempPayload
			}))
				.then((res: any) => {
					setWordCloudResponseData(res.payload.response.data.data)
					setBookMark(res.payload.response.data.is_saved_view)
					setBookMarkID(res.payload.response.data.ID)
					setCloudName(res.payload.response.data.NAME)
					setLoading(false)
				})
				.catch((error: any) => {
					console.error("Error fetching word cloud data:", error);
					setLoading(false)
				});
		}
		// fetchWordsData()
	};

	const updateAttributeSelection = (attr: any, item: any) => {
		const temp = attributeSelection.map((tempAttr: any) => {
			if (tempAttr.id === attr.id) {
				let val = tempAttr.value;
				const index = val.findIndex((tempitem: any) => item === tempitem);
				if (index !== -1) {
					val = [...val.slice(0, index), ...val.slice(index + 1)];
				} else {
					val = [...val, item];
				}
				return { ...tempAttr, value: val };
			}
			return tempAttr;
		});
		dispatch(updateSelectedWordCloudAttr(temp))

		let config_1: any = []
		temp.map((item: any) => {
			if (item.value.length > 0) {
				config_1.push({
					name: item.id,
					value: [...item.value]?.sort((a, b) => a - b)
				})
			}
		})

		setLoading(true)
		const tempPayload = { ...payload, config_1: config_1 }
		setPayload(tempPayload)
		setCloudName(null)
		dispatch(getWordCloudData({
			project_id: projectId,
			payload: tempPayload
		}))
			.then((res: any) => {
				setWordCloudResponseData(res.payload.response.data.data)
				setBookMark(res.payload.response.data.is_saved_view)
				setBookMarkID(res.payload.response.data.ID)
				setCloudName(res.payload.response.data.NAME)
				setLoading(false)
			})
			.catch((error: any) => {
				console.error("Error fetching word cloud data:", error);
				setLoading(false)
			});
	};

	//checks if a specific item is selected for a given attribute in the attributeSelection state.
	const attributeItemIsSelected = (attr: any, item: any) => {
		return attributeSelection?.some((tempItem: any) => {
			if (tempItem.id === attr.id) {
				return tempItem.value.includes(item);
			}
			return false;
		});
	};

	//toggles the selection of all items for a specific attribute filter and updates the WordCloud data accordingly.
	const attributeItemSelectAll = (attr: any) => {
		const temp = attributeSelection?.map((tempItem: any) => {
			if (tempItem.id === attr.id) {
				if (tempItem.value.length === attr.value.length) {
					return { ...tempItem, value: [] };
				}
				return { ...tempItem, value: attr.value };
			}
			return tempItem;
		});
		dispatch(updateSelectedWordCloudAttr(temp))

		let config_1: any = []
		temp.map((item: any) => {
			if (item.value.length > 0) {
				config_1.push({
					name: item.id,
					value: [...item.value]?.sort((a, b) => a - b)
				})
			}
		})

		setLoading(true)
		const tempPayload = { ...payload, config_1: config_1 }
		setPayload(tempPayload)
		setCloudName(null)
		dispatch(getWordCloudData({
			project_id: projectId,
			payload: tempPayload
		}))
			.then((res: any) => {
				setWordCloudResponseData(res.payload.response.data.data)
				setBookMark(res.payload.response.data.is_saved_view)
				setBookMarkID(res.payload.response.data.ID)
				setCloudName(res.payload.response.data.NAME)
				setLoading(false)
			})
			.catch((error: any) => {
				console.error("Error fetching word cloud data:", error);
				setLoading(false)
			});
	};

	//verifies if all items of a particular attribute are selected in the attributeSelection state.
	const attributeItemCheckIsAll = (attr: any) => {
		const selection: any[] = [];
		attributeSelection?.forEach((item: any) => {
			if (item?.id === attr?.id) {
				selection.push(...item.value);
			}
		});
		return attr.value.every((item: any) => selection.includes(item));
	};

	//Handles bookmarking functionality including saving and deleting views.
	const handleBookmark = () => {
		if (words.length > 0 && !invalidSelection && !loading) {
			if (!bookMark) {
				let areas: any = ["All"]
				let config_1: any = []
				attributeSelection.map((item: any) => {
					if (item.value.length > 0) {
						config_1.push({
							name: item.id,
							value: [...item.value]?.sort((a, b) => a - b)
						})
					}
				})
				if (areasSelection?.length !== areasToShow?.length) {
					areas = areasSelection
				}
				let payload = {}
				if (selectedTopic) {
					payload = {
						analyze_by: {
							type: selectedTopic.type === 'Reporting Buckets' ? 'Analysis Topics' : selectedTopic.type,
							bucket_id: selectedTopic.id,
							name: selectedTopic.display_name
						},
						areas: [...areas]?.sort((a, b) => a - b),
						config_1: config_1,
						is_compare_view: false
					}
				} else {
					payload = {
						areas: [...areas]?.sort((a, b) => a - b),
						config_1: config_1,
						is_compare_view: false
					}
				}


				setBookMark((prevState: any) => !prevState);
				dispatch(addSavedView({
					projectId: projectId,
					payload: payload
				})).then(() => {
					setAddBookMarkToggle((prevData: any) => !prevData)
					setCloudName(null)
					dispatch(getWordCloudData({
						project_id: projectId,
						payload: payload
					}))
						.then((res: any) => {
							// setWordCloudResponseData(res.payload.response.data.data)
							setBookMark(res.payload.response.data.is_saved_view)
							setBookMarkID(res.payload.response.data.ID)
							setCloudName(res.payload.response.data.NAME)
							setLoading(false)
						})
						.catch((error: any) => {
							console.error("Error fetching word cloud data:", error);
							setLoading(false)
						});
				})
			} else {
				setBookMark((prevState: any) => !prevState);
				dispatch(deleteSavedView({ projectId: projectId, id: bookMarkID })).then(() => {
					setAddBookMarkToggle((prevData: any) => !prevData)
				});
			}
		}
	};

	useEffect(() => {
		if (worldcloudDeleteID === bookMarkID) {
			setBookMark(false);
		}
	}, [worldcloudDeleteID])

	useEffect(() => {
		setLoading(true)
	}, [selectedTopic])

	useEffect(() => {
		if (cloudName) {
			setHeading(cloudName)
		} else {
			setHeading(
				`${selectedItems.join(', ')} ${(areasToShow?.length === areasSelection?.length || areasToShow?.length < areasSelection?.length) ? ': Sub-topics' : `: ${areasSelection?.join(', ')}`} ${selectedTopic ? ` within ${selectedTopic.display_name}` : ''}`
			)
		}
	}, [selectedItems, areasToShow, areasSelection, selectedTopic, cloudName])

	useEffect(() => {
		if (savedViewRenameItem?.ID === bookMarkID) {
			setCloudName(savedViewRenameItem?.NAME)
		}
	}, [savedViewRenameItem])

	return (
		<div ref={heightRef} className="select-none w-full h-full">
			<div className="h-[100px] p-5">
				{/* areas */}
				<div className="flex items-center justify-between">
					<ThemeProvider theme={theme}>
						<FormControl sx={{ m: 2, width: 120, margin: "0px" }} disabled={areasToShow?.length === 0}>
							<InputLabel
								id="areas-feature"
								shrink={true}
								sx={{
									fontFamily: "'Poppins'",
									fontSize: "14px",
								}}
							>
								Areas
							</InputLabel>
							<Select
								className="truncate"
								value={[]}
								autoWidth
								label="Areas"
								renderValue={() => {
									if (areasToShow?.length === 0) {
										return "NA"
									}
									if (areasSelection?.length === 0) {
										return "Select";
									} else if (areasToShow?.length === areasSelection?.length) {
										return "All";
									} else if (areasSelection?.length > 0 && areasSelection?.length < areasToShow?.length) {
										return areasSelection?.length + " Selected";
									} else {
										return 'All'
									}
								}}
								sx={{
									fontFamily: "'Poppins'",
									".MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple": {
										paddingY: "8px",
										paddingLeft: "8px",
										fontWeight: "700",
										fontSize: "13px",
									},
								}}
								multiple
								displayEmpty={true}
							// disabled={areasSelection.length === 0}
							>
								<MenuItem
									value="All"
									sx={{ paddingX: "7px 10px" }}
									onClick={areasSelectionSelectAll}
								>
									<Checkbox
										color="primary"
										sx={{ margin: 0, padding: "2px 2px" }}
										checked={areasToShow?.length === areasSelection?.length}
									/>
									<ListItemText
										primary={"All"}
										primaryTypographyProps={{
											sx: { fontFamily: '"Poppins", sans-serif' },
										}}
									/>
								</MenuItem>
								{areasToShow?.map((item: any) => (
									<MenuItem
										key={item}
										value="All"
										sx={{ paddingX: "7px 10px" }}
										onClick={() => updateAreasSelection(item)}
									>
										<Checkbox
											color="primary"
											sx={{ margin: 0, padding: "2px 2px" }}
											checked={areaIsChecked(item)}
										/>
										<ListItemText
											primary={item}
											primaryTypographyProps={{
												sx: { fontFamily: '"Poppins", sans-serif' },
											}}
										/>
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</ThemeProvider>
					<div className="flex">
						<div className="flex items-center gap-5 mr-20">
							<ThemeProvider theme={theme}>
								<FormControl sx={{ m: 2, width: 200, margin: "0px" }} disabled={AttributesData?.length === 0}>
									<InputLabel
										id="attribute-filter-label"
										shrink={true}
										sx={{
											fontFamily: "'Poppins'",
											fontSize: "14px",
											backgroundColor: "white"
										}}
									>
										Attributes
									</InputLabel>
									<Select
										labelId="attribute-filter-label"
										id="attribute-filter"
										multiple
										value={[]}
										displayEmpty
										renderValue={() => {

											if (AttributesData?.length === 0) {
												return 'Not defined';
											}
											// Determine the display value based on selection
											let selection: any = [];
											attributeSelection.forEach((item: any) => {
												AttributesData.forEach((attr) => {
													if (item.id === attr.id) {
														selection = [...selection, ...item.value];
													}
												});
											});
											if (selection.length === 0) {
												return "Select";
											} else if (selection.length > 0) {
												return `${selection.length} Selected`;
											} else {
												return "All";
											}
										}}
										sx={{
											fontFamily: "'Poppins'",
											".MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple": {
												paddingY: "8px",
												paddingLeft: "8px",
												fontWeight: "700",
												fontSize: "13px",
											},
										}}
										MenuProps={{
											PaperProps: {
												style: {
													maxHeight: 300, // Set the fixed height for the dropdown
													overflowY: 'auto', // Enable vertical scrolling
												},
											},
										}}
									// No onChange handler specified here
									>
										{AttributesData.map((attr) => (
											<React.Fragment key={attr.name}>
												{/* Group Heading without checkbox */}
												<MenuItem
													onClick={() => toggleGroup(attr.id)}
													sx={{
														fontWeight: 'bold',
														fontFamily: '"Poppins", sans-serif',
														paddingX: "7px 10px",
													}}
												>
													{attr.name}
													{expandedGroups[attr.id] ? (
														<KeyboardArrowDown sx={{ cursor: "pointer" }} />
													) : (
														<KeyboardArrowUp sx={{ cursor: "pointer" }} />
													)}
												</MenuItem>
												{/* "All" option with checkbox */}
												{!expandedGroups[attr.id] && (
													<React.Fragment>
														<MenuItem
															value={`All-${attr.name}`}
															sx={{ paddingX: "7px 10px" }}
															onClick={() => attributeItemSelectAll(attr)}
														>
															<Checkbox
																color="primary"
																sx={{ margin: 0, padding: "2px 2px" }}
																checked={attributeItemCheckIsAll(attr)}
															/>
															<ListItemText
																primary="All"
																primaryTypographyProps={{
																	sx: { fontFamily: '"Poppins", sans-serif' },
																}}
															/>
														</MenuItem>
														{attr.value.map((item: any) => (
															<MenuItem
																key={item}
																value={item}
																sx={{ paddingX: "7px 10px" }}
																onClick={() => updateAttributeSelection(attr, item)}
															>
																<Checkbox
																	color="primary"
																	sx={{ margin: 0, padding: "2px 2px" }}
																	checked={attributeItemIsSelected(attr, item)}
																/>
																<ListItemText
																	primary={item}
																	primaryTypographyProps={{
																		sx: { fontFamily: '"Poppins", sans-serif' },
																	}}
																/>
															</MenuItem>
														))}</React.Fragment>
												)}
											</React.Fragment>
										))}
									</Select>
								</FormControl>
							</ThemeProvider>

						</div>
						{bookMark ? (
							<Tooltip title="Remove saved view">
								<BookmarkOutlinedIcon
									style={{ color: (words.length > 0 && !invalidSelection) ? '#9747FF' : '#6B7280', cursor: (words.length > 0 && !invalidSelection) ? "pointer" : "not-allowed", opacity: (loading || words.length === 0 || invalidSelection) ? '0.5' : '1' }}
									onClick={() => {
										handleBookmark();
									}}
								/>
							</Tooltip>
						) : (
							<Tooltip title="Save view">
								<BookmarkBorderOutlinedIcon
									style={{ color: (words.length > 0 && !invalidSelection) ? '#9747FF' : '#6B7280', cursor: (words.length > 0 && !invalidSelection) ? "pointer" : "not-allowed", opacity: (loading || words.length === 0 || invalidSelection) ? '0.5' : '1' }}
									onClick={() => {
										handleBookmark();
									}}
								/>
							</Tooltip>
						)}
					</div>
				</div>
				<div className="mt-3 text-lg font-semibold">
					{selectedItems.length > 0 && !loading && !analyzeByDataIsLoading &&
						<div className="flex items-center">
							{heading}
						</div>
					}
				</div>
			</div>

			<div className="" style={{ height: height - 105, width: width }}>
				{(loading || analyzeByDataIsLoading) ?
					<div className="h-full w-full flex justify-center items-center text-gray-400 text-lg">
						<Spinner />
					</div>
					:
					<>
						{words.length > 0 && (AttributesData?.length !== 0 ? !invalidSelection : true) ?
							<div className="h-full w-full overflow-hidden flex justify-center items-center">
								<div className="h-[70%] w-[70%] scale-[1.43]">
									<GeneralWordCloud words={words} />
								</div>
							</div>
							:
							<div className="h-full w-full flex justify-center items-center text-gray-400 text-lg">
								{areasToShow?.length === 0 ? 'There are no intersecting subtopics' : 'No data available'}
								{/* There are no intersecting subtopics */}
							</div>
						}
					</>
				}
			</div>
		</div>
	);
}