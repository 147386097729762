import React, { useEffect, useRef, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import { Trash2 } from "react-feather";
import { getSavedView, deleteSavedView } from "../../redux/CompareWordCloud/action";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { renameSavedViews } from "../../redux/Project/action";
import Spinner from "../Spinner";



interface SavedViewProps {
  isDivOpen: boolean;
  setIsDivOpen: any;
  handleSelectedTopic: (selectedTopic: Object | null) => void
  handleSelectedAreas: (selectedAreas: Array<any> | null) => void
  handleAttributeSelection1: (config1: any) => void
  handleAttributeSelection2: (config: any) => void
  setWordCloudViewViz?: any
  setWorldcloudDeleteID?: any
  setViewVizCompare?: any
  setSavedViewRenameItem?: any
  setSelectedSortFilter: any
  addBookMarkToggle?: any
}

const SavedView: React.FC<SavedViewProps> = ({ isDivOpen, setIsDivOpen, handleSelectedTopic, handleSelectedAreas, handleAttributeSelection1, handleAttributeSelection2, setWordCloudViewViz, setWorldcloudDeleteID, setViewVizCompare, setSavedViewRenameItem, setSelectedSortFilter, addBookMarkToggle }) => {
  const dispatch = useAppDispatch();
  const pathname = useLocation().pathname;
  const projectId = useParams().id;

  const [data, setData] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [hoveredItemId, setHoveredItemId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false)

  const ref = useRef<any>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    setSelectedItem(null)
    if (pathname.includes("compare")) {
      dispatch(getSavedView({ project_id: projectId, is_compare_view: true })).then((res: any) => {
        if (Array.isArray(res.payload.response.data)) {
          setData([...res.payload.response.data].reverse()); //newly saved data appears at the top of the saved view section
        } else {
          setData([])
        }
        setLoading(false)
      })
    } else {
      dispatch(getSavedView({ project_id: projectId, is_compare_view: false })).then((res: any) => {
        if (Array.isArray(res.payload.response.data)) {
          setData([...res.payload.response.data].reverse()); //newly saved data appears at the top of the saved view section
        } else {
          setData([])
        }
        setLoading(false)
      })
    }
  }, [isDivOpen])

  //used for watching the clicks outside the saved view data section, closing it if clicked elsewhere.
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsDivOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    const TopPos = ref.current?.getBoundingClientRect().top;
    setHeight(window.innerHeight - TopPos - 110);
  });

  useEffect(() => {
    setLoading(true)
    if (pathname.includes("compare")) {
      dispatch(getSavedView({ project_id: projectId, is_compare_view: true })).then((res: any) => {
        if (Array.isArray(res.payload.response.data)) {
          setData([...res.payload.response.data].reverse()); //newly saved data appears at the top of the saved view section
        } else {
          setData([])
        }
        setLoading(false)
      })
    } else {
      dispatch(getSavedView({ project_id: projectId, is_compare_view: false })).then((res: any) => {
        if (Array.isArray(res.payload.response.data)) {
          setData([...res.payload.response.data].reverse()); //newly saved data appears at the top of the saved view section
        } else {
          setData([])
        }
        setLoading(false)
      })
    }
  }, [pathname, addBookMarkToggle])


  const handleDivToggle = () => {
    setIsDivOpen((prevState: any) => !prevState);
  };

  const handleItemHover = (itemId: number | null) => {
    setHoveredItemId(itemId);
  };

  const handleDeleteItem = (itemId: number) => {
    setWorldcloudDeleteID(itemId)
    const updatedData = data.filter((item: any) => item.ID !== itemId);
    setData(updatedData);
    dispatch(deleteSavedView({ projectId: projectId, id: itemId })).then(() => {
      if (pathname.includes("compare")) {
        dispatch(getSavedView({ project_id: projectId, is_compare_view: true })).then((res: any) => {
          if (Array.isArray(res.payload.response.data)) {
            setData([...res.payload.response.data].reverse()); //newly saved data appears at the top of the saved view section
          } else {
            setData([])
          }
        })
      } else {
        dispatch(getSavedView({ project_id: projectId, is_compare_view: false })).then((res: any) => {
          if (Array.isArray(res.payload.response.data)) {
            setData([...res.payload.response.data].reverse()); //newly saved data appears at the top of the saved view section
          } else {
            setData([])
          }
        })
      }
    });
  };

  //manages the action when users click "view viz", configuring the visualization based on the selected item's details.
  const handleViewViz = (itemId: number) => {
    setIsDivOpen(false)
    let findSavedIndex = data.findIndex((item: any) => (
      item.ID == itemId
    ))
    const temp: any = data[findSavedIndex]
    setWordCloudViewViz(temp)
    handleSelectedTopic(temp["ANALYZE_BY"])
    handleSelectedAreas(temp["AREAS"])
    handleAttributeSelection1(temp["CONFIG_1"])
    handleAttributeSelection2(temp["CONFIG_2"])
    setSelectedSortFilter(temp["SORT_BY"])
  };

  return (
    <>
      {isDivOpen && (
        <div ref={ref} className="z-50 border absolute bg-white rounded-md shadow-lg h-full w-[365px] -ml-[270px]">
          <IconButton
            onClick={handleDivToggle}
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              zIndex: 1,
            }}
          >
            <CancelIcon />
          </IconButton>
          <div className="flex justify-between items-center border-b-2 border-gray-300 mx-3 gap-2 py-2">
            <span className="text-lg font-bold">Saved Views</span>
          </div>
          <div
            className={`main w-full mt-2 flex flex-col justify-start gap-1 overflow-y-auto `} style={{ height: pathname.includes("compare") ? height - 10 : height }}
          >{pathname.includes("compare") && data?.length > 0 && <p className="text-gray-400 text-xs italic pl-5 pr-14 center-justify">
            The top 5 topics of the saved comparsion will appear in the
            generated PowerPoint
          </p>}
            {!loading && pathname.includes("compare") &&
              data?.map((item: any) => (
                <div
                  key={item.ID}
                  className={` relative p-3 pl-5 pr-14 ${(hoveredItemId === item.ID || selectedItem?.ID === item.ID) ? "bg-violet-100" : ""}`}
                  onMouseEnter={() => handleItemHover(item.ID)}
                  onMouseLeave={() => handleItemHover(null)}
                >
                  <SavedViewElement data={data} selectedItem={selectedItem} item={item} is_compare_view={true} setSelectedItem={setSelectedItem} hoveredItemId={hoveredItemId} handleDeleteItem={handleDeleteItem} handleViewViz={handleViewViz} setData={setData} setSavedViewRenameItem={setSavedViewRenameItem} />
                </div>
              ))}
            {!loading && pathname.includes("wordcloud") &&
              data?.map((item: any) => (
                <div
                  key={item.ID}
                  className={` relative p-3 pl-5 pr-14 ${(hoveredItemId === item.ID || selectedItem?.ID === item.ID) ? "bg-violet-100" : ""}`}
                  onMouseEnter={() => handleItemHover(item.ID)}
                  onMouseLeave={() => handleItemHover(null)}
                >
                  <SavedViewElement data={data} selectedItem={selectedItem} item={item} is_compare_view={false} setSelectedItem={setSelectedItem} hoveredItemId={hoveredItemId} handleDeleteItem={handleDeleteItem} handleViewViz={handleViewViz} setData={setData} setSavedViewRenameItem={setSavedViewRenameItem} />
                </div>
              ))}
            {loading &&
              <div className="h-full w-full flex justify-center items-center">
                <Spinner />
              </div>
            }
            {data?.length === 0 && !loading &&
              <div className="h-full w-full text-gray-400 flex justify-center items-center">
                No saved views
              </div>
            }
          </div>
        </div>
      )}
    </>
  );
};

function SavedViewElement({ is_compare_view, item, selectedItem, setSelectedItem, hoveredItemId, handleDeleteItem, handleViewViz, data, setData, setSavedViewRenameItem }: any) {

  const dispatch = useAppDispatch();
  const pathname = useLocation().pathname;
  const projectId = pathname.split("/")[2];

  const [name, setName] = useState('')
  const [inputName, setInputName] = useState('')
  const [select, setSelect] = useState(false)

  const textareaRef = useRef<any>(null);

  const focusTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.selectionStart = textareaRef.current.value.length;
      textareaRef.current.selectionEnd = textareaRef.current.value.length;
    }
  };

  useEffect(() => {
    defaultName()
  }, [data])

  useEffect(() => {
    focusTextarea()
  }, [inputName, selectedItem]);

  useEffect(() => {
    if (textareaRef.current) {
      if (textareaRef.current.scrollHeight < 42) {
        textareaRef.current.style.height = '21px';
      } else {
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }
  }, [inputName, select]);

  useEffect(() => {
    if (selectedItem?.ID === item.ID) {
      setSelect(true)
    } else {
      setSelect(false)
    }
    setInputName(name)
  }, [selectedItem])

  const onClickRename = () => {
    setName(inputName)
    dispatch(renameSavedViews({ projectId: projectId, name: inputName, id: item.ID }))
    setData((prevData: any) => {
      return prevData.map((prevItem: any) => {
        if (prevItem.ID === item.ID) {
          setSavedViewRenameItem({ ...prevItem, NAME: inputName })
          return { ...prevItem, NAME: inputName }
        }
        return prevItem
      })
    })
    setSelectedItem(null)
    setSelect(false)
  }

  const defaultName = () => {
    if (item.NAME) {
      setName(item.NAME)
      setInputName(item.NAME)
      return
    }
    if (is_compare_view) {
      let name = ''
      let temp = (
        item?.CONFIG_1?.find(
          (attr: any) => attr.name === "ATTRIBUTE_1"
        )?.value || []
      ).length > 1
        ?
        `${(
          item?.CONFIG_1?.find(
            (attr: any) => attr.name === "ATTRIBUTE_1"
          )?.value || []
        ).join(", ")}`
        : item?.CONFIG_1?.find(
          (attr: any) => attr.name === "ATTRIBUTE_1"
        )?.value || []
      name = name + temp
      temp = (
        item?.CONFIG_1?.find(
          (attr: any) => attr.name === "ATTRIBUTE_2"
        )?.value || []
      ).length > 1
        ? `${(
          item?.CONFIG_1?.find(
            (attr: any) => attr.name === "ATTRIBUTE_1"
          )?.value || []
        ).length >= 1 ? ', ' : ''}${(
          item?.CONFIG_1?.find(
            (attr: any) => attr.name === "ATTRIBUTE_2"
          )?.value || []
        ).join(", ")}`
        : (item?.CONFIG_1?.find(
          (attr: any) => attr.name === "ATTRIBUTE_2"
        )?.value || []).length > 0
          ? `${(
            item?.CONFIG_1?.find(
              (attr: any) => attr.name === "ATTRIBUTE_1"
            )?.value || []
          ).length >= 1 ? ', ' : ''}${item?.CONFIG_1?.find(
            (attr: any) => attr.name === "ATTRIBUTE_2"
          )?.value || []
          }`
          : ""
      name = name + temp + ' vs '
      temp = (
        item?.CONFIG_2?.find(
          (attr: any) => attr.name === "ATTRIBUTE_1"
        )?.value || []
      ).length > 1
        ? `${(
          item?.CONFIG_2?.find(
            (attr: any) => attr.name === "ATTRIBUTE_1"
          )?.value || []
        ).join(", ")}`
        : item?.CONFIG_2?.find(
          (attr: any) => attr.name === "ATTRIBUTE_1"
        )?.value || []
      name = name + temp
      temp = (
        item?.CONFIG_2?.find(
          (attr: any) => attr.name === "ATTRIBUTE_2"
        )?.value || []
      ).length > 1
        ? `${(
          item?.CONFIG_2?.find(
            (attr: any) => attr.name === "ATTRIBUTE_1"
          )?.value || []
        ).length >= 1 ? ', ' : ''}${(
          item?.CONFIG_2?.find(
            (attr: any) => attr.name === "ATTRIBUTE_2"
          )?.value || []
        ).join(", ")}`
        : (item?.CONFIG_2?.find(
          (attr: any) => attr.name === "ATTRIBUTE_2"
        )?.value || []).length > 0
          ? `${(
            item?.CONFIG_2?.find(
              (attr: any) => attr.name === "ATTRIBUTE_1"
            )?.value || []
          ).length >= 1 ? ', ' : ''}${item?.CONFIG_2?.find(
            (attr: any) => attr.name === "ATTRIBUTE_2"
          )?.value || []
          }`
          : ""
      name = name + temp
      temp = item.AREAS.length > 0 && item.AREAS[0] === 'All' ? " " : `: ${item.AREAS.join(", ")}`
      name = name + temp
      temp = item.ANALYZE_BY !== null
        ? ` within ${item.ANALYZE_BY?.name}`
        : ""
      name = name + temp
      setName(name.trim())
      setInputName(name.trim())
    } else {
      let name = ''
      let temp = (
        item?.CONFIG_1?.find(
          (attr: any) => attr.name === "ATTRIBUTE_1"
        )?.value || []
      ).length > 1
        ? `${(
          item?.CONFIG_1?.find(
            (attr: any) => attr.name === "ATTRIBUTE_1"
          )?.value || []
        ).join(", ")}`
        : item?.CONFIG_1?.find(
          (attr: any) => attr.name === "ATTRIBUTE_1"
        )?.value || []
      name = name + temp
      temp = (
        item?.CONFIG_1?.find(
          (attr: any) => attr.name === "ATTRIBUTE_2"
        )?.value || []
      ).length > 1
        ? `${(
          item?.CONFIG_1?.find(
            (attr: any) => attr.name === "ATTRIBUTE_1"
          )?.value || []
        ).length >= 1 ? ', ' : ''}${(
          item?.CONFIG_1?.find(
            (attr: any) => attr.name === "ATTRIBUTE_2"
          )?.value || []
        ).join(", ")}`
        : (item?.CONFIG_1?.find(
          (attr: any) => attr.name === "ATTRIBUTE_2"
        )?.value || []
        ).length > 0
          ? `${(
            item?.CONFIG_1?.find(
              (attr: any) => attr.name === "ATTRIBUTE_1"
            )?.value || []
          ).length >= 1 ? ', ' : ''}${item?.CONFIG_1?.find(
            (attr: any) => attr.name === "ATTRIBUTE_2"
          )?.value || []
          }`
          : ""
      name = name + temp + " "
      temp = item.AREAS.length > 0 && item.AREAS[0] === 'All' ? " " : `: ${item.AREAS.join(", ")}`
      name = name + temp
      temp = item.ANALYZE_BY !== null
        ? ` within ${item.ANALYZE_BY?.name}`
        : ""
      name = name + temp
      setName(name.trim())
      setInputName(name.trim())
    }
  }

  const onHitEnterRename = (e: any) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      onClickRename()
    }
  }

  return (
    <div className="w-[90%] flex break-all text-wrap h-fit">
      {selectedItem?.ID === item.ID ?
        <textarea style={{ height: textareaRef?.current?.scrollHeight ? textareaRef?.current?.scrollHeight : '100%' }} ref={textareaRef} value={inputName} onChange={(e) => setInputName(e.target.value)} className="bg-transparent overflow-hidden resize-none w-full focus:outline-none" onClick={(e) => e.stopPropagation()} autoFocus onKeyDown={onHitEnterRename} />
        :
        <>
          {name}
        </>
      }
      {(hoveredItemId === item.ID || select) && (
        <div className="absolute top-2 right-2 mr-3 ml-3 gap-2 flex items-center">
          {select ?
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 cursor-pointer" onClick={onClickRename}>
              <path strokeLinecap="round" d="m4.5 12.75 6 6 9-13.5" />
            </svg>
            :
            <EditOutlinedIcon fontSize='inherit' style={{ fontSize: '1.3rem' }} className='cursor-pointer' onClick={() => { setSelectedItem(item) }} />
          }
          <Trash2
            size={16}
            className="cursor-pointer "
            onClick={() => handleDeleteItem(item.ID)}
          />
        </div>
      )}
      {(hoveredItemId === item?.ID || selectedItem?.ID === item.ID) && (
        <div className="absolute bottom-0 right-0 text-sm mr-3">
          <button
            onClick={() => handleViewViz(item?.ID)}
            className="mr-2 relative"
          >
            <svg
              className="absolute top-0 left-0 pt-1"
              width="16"
              height="16"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_2806_5795"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="14"
                height="14"
              >
                <rect width="14" height="14" fill="#3B3B3B" />
              </mask>
              <g mask="url(#mask0_2806_5795)">
                <path
                  d="M8.1665 9.91683H11.0832V7.00016H9.9165V8.75016H8.1665V9.91683ZM2.9165 7.00016H4.08317V5.25016H5.83317V4.0835H2.9165V7.00016ZM2.33317 11.6668C2.01234 11.6668 1.73768 11.5526 1.50921 11.3241C1.28074 11.0956 1.1665 10.821 1.1665 10.5002V3.50016C1.1665 3.17933 1.28074 2.90468 1.50921 2.6762C1.73768 2.44773 2.01234 2.3335 2.33317 2.3335H11.6665C11.9873 2.3335 12.262 2.44773 12.4905 2.6762C12.7189 2.90468 12.8332 3.17933 12.8332 3.50016V10.5002C12.8332 10.821 12.7189 11.0956 12.4905 11.3241C12.262 11.5526 11.9873 11.6668 11.6665 11.6668H2.33317ZM2.33317 10.5002H11.6665V3.50016H2.33317V10.5002Z"
                  fill="#555555"
                />
              </g>
            </svg>
            <span style={{ paddingLeft: "18px" }}>view viz</span>
          </button>
        </div>
      )}
    </div>
  )
}

export default SavedView;
