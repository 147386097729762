import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IConfigData } from "./type";
import { getAllModerators, getFileNames, sendMQnAData, getExcludeQnA, addQuestionBucket, makeQstnsGrouped, makeQuestionsExclude, getMapQnAColNames, getMQnAColValues, sendMQnAExcelData, getQuestionBucket, fetchSelectedProject, getConfigQbs, getGroupQuestionAndAnswers, getInternalPublish } from "./action";

const initialState: IConfigData = {
    moderatorList: [],
    isLoading: false,
    fileNameList: [],
    sendMQnAdataSuccess: false,
    question_bucket: [],
    excludeQnAList: [],
    excludeDataLoader: false,
    filter: "All",
    grouped_questions: [],
    groupQuestionsLoader: false,
    grouped_questions_success: false,
    excluded_questions: [],
    excluded_questions_success: false,
    selectedQuesExclude: [],
    qstnBucketList: [],
    selectedProject: {},
    paginationLimit: 30,
    currentPage: 1,
    mapQnAColNames: [],
    mapQnAColValues: {},
    addQuestionBucSuccess: false,
    addQuestBucSuccessMessage: false,
    excludeQnACount: 0,
    makeQuesGroupSuccess: false,
    questionBucketList: [],
    unformattedQBList: [],
    excludeQuestAnsList: [],
    ExcludeQuestAnsCount: 0,
    autoSave: null
}

const includesValue = (searchFor: string, array: Array<any>) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i] === searchFor) {
            return true
        }
    }
    return false
}
const formatQuestionBucks = (payload: any) => {
    let data = payload
    let displayNames: any = []
    let formattedData: any = []
    data.map((item: any) => {
        if (!includesValue(item.display_name, displayNames)) {
            displayNames.push(item.display_name)
        }
    })
    displayNames.map((item: any) => {
        let phrase_count = 0
        let question_count = 0
        let active = true
        let temp = data.filter((elem: any) => elem.display_name === item)
            .map((bub: any) => {
                phrase_count += bub.phrase_count
                active = bub.active
                question_count += bub.question_count
                return {
                    question_bucket_id: bub.question_bucket_id
                }
            })
        formattedData.push({ display_name: item, question_buckets: temp, question_count: question_count })
    })
    return formattedData
}

const postGroupUpdateQB = (payload: any, minusCount: any, plusIds: any, plusValue: any) => {
    let data = payload.map((item: any) => {
        let count = item.question_count
        if (includesValue(`${item.question_bucket_id}`, Object.keys(minusCount))) {
            count = count - minusCount[item.question_bucket_id]
        }
        if (includesValue(item.question_bucket_id, plusIds)) {
            count = count + plusValue
        }
        return {
            ...item,
            question_count: count
        }
    })
    return data
}

const postGroupUpdateQuest = (payload: any, quest_ids: any, assignValue: any) => {
    let data = payload.map((item: any) => {
        if (includesValue(item.question_id, quest_ids)) {
            return {
                ...item,
                question_bucket: assignValue
            }
        }
        else {
            return item
        }
    })
    return data
}


export const configDataSlice = createSlice({
    name: "configdata",
    initialState: initialState,
    reducers: {
        updateMQnAdataSuccess: (state, action) => {
            state.sendMQnAdataSuccess = action.payload
        },
        updateConfigureAutoSave: (state, action) => {
            state.autoSave = action.payload
        }
        , updateGroupedSuccess: (state, action) => {
            state.grouped_questions_success = action.payload
        },
        updateSelectedProject: (state, action) => {
            state.selectedProject = action.payload
        },
        updateExcludeQnA: (state, action) => {
            state.excludeQnAList = action.payload
        },
        updateCurrentPaginationPage: (state, action) => {
            state.currentPage = action.payload;
        },
        updateSelectedQuesExclude: (state, action) => {
            state.excluded_questions_success = action.payload
        },
        updateQuestionBucketList: (state, action) => {
            state.qstnBucketList = action.payload
        },
        clearConfigData: (state, action) => {
            state.fileNameList = []
            state.moderatorList = []
            state.isLoading = false
            state.mapQnAColNames = []
            state.mapQnAColValues = {}
        },
        updateMakeQuesGroupSuccess: (state, action) => {
            state.makeQuesGroupSuccess = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllModerators.pending, (state: IConfigData, action: PayloadAction<any>) => {
                state.isLoading = true
                state.moderatorList = []
            })
            .addCase(getAllModerators.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
                state.isLoading = false
                state.moderatorList = action.payload.response.data
            })
            .addCase(getAllModerators.rejected, (state: IConfigData, action: PayloadAction<any>) => {
                state.isLoading = false
            });
        builder
            .addCase(getFileNames.pending, (state: IConfigData, action: PayloadAction<any>) => {
                state.fileNameList = []
                state.isLoading = true
            })
            .addCase(getFileNames.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
                state.fileNameList = action.payload.response.data
                state.isLoading = false
            })
            .addCase(getFileNames.rejected, (state: IConfigData, action: PayloadAction<any>) => {
                state.isLoading = false
            });
        builder
            .addCase(sendMQnAData.pending, (state: IConfigData, action: PayloadAction<any>) => {
            })
            .addCase(sendMQnAData.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
                state.sendMQnAdataSuccess = action.payload.response.status
            })
            .addCase(sendMQnAData.rejected, (state: IConfigData, action: PayloadAction<any>) => {
            });
        builder
            .addCase(fetchSelectedProject.pending, (state: IConfigData) => { })
            .addCase(fetchSelectedProject.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
                state.selectedProject = action.payload.response.data;
            })
            .addCase(fetchSelectedProject.rejected, (state: IConfigData, action: PayloadAction<any>) => { })
        builder
            .addCase(getConfigQbs.pending, (state: IConfigData, action: PayloadAction<any>) => {
            })
            .addCase(getConfigQbs.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
                state.unformattedQBList = action.payload.response.data
                state.questionBucketList = formatQuestionBucks(action.payload.response.data)
            })
            .addCase(getConfigQbs.rejected, (state: IConfigData, action: PayloadAction<any>) => {
            });
        builder
            .addCase(getExcludeQnA.pending, (state: IConfigData, action: PayloadAction<any>) => {
                // state.excludeDataLoader=true
            })
            .addCase(getExcludeQnA.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
                state.excludeQnAList = action.payload.response.data
                state.excludeQnACount = action.payload.response.count
                // state.excludeDataLoader=false
            })
            .addCase(getExcludeQnA.rejected, (state: IConfigData, action: PayloadAction<any>) => {
                // state.excludeDataLoader=false
            });
        builder
            .addCase(getGroupQuestionAndAnswers.pending, (state: IConfigData) => { })
            .addCase(getGroupQuestionAndAnswers.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
                state.excludeQuestAnsList = action.payload.response.data
                state.ExcludeQuestAnsCount = action.payload.response.count
            })
            .addCase(getGroupQuestionAndAnswers.rejected, (state: IConfigData) => { });

        builder
            .addCase(getQuestionBucket.pending, (state: IConfigData, action: PayloadAction<any>) => {
            })
            .addCase(getQuestionBucket.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
                state.question_bucket = action.payload.response
            })
            .addCase(getQuestionBucket.rejected, (state: IConfigData, action: PayloadAction<any>) => {
            });
        builder
            .addCase(addQuestionBucket.pending, (state: IConfigData, action: PayloadAction<any>) => {
                //state.groupQuestionsLoader=true
            })
            .addCase(addQuestionBucket.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
                if (action.payload.response.status == true) {
                    let data1 = JSON.parse(JSON.stringify(state.questionBucketList))
                    data1.push({
                        "display_name": action.payload.question_bucket_name,
                        "question_count": 0,
                        "question_buckets": [
                            {
                                "question_bucket_id": action.payload.response.data
                            }
                        ]
                    })
                    state.questionBucketList = data1
                }
                if (action.payload.response.status == false) {
                    state.addQuestBucSuccessMessage = true
                }
                state.groupQuestionsLoader = false
            })
            .addCase(addQuestionBucket.rejected, (state: IConfigData, action: PayloadAction<any>) => {
                state.groupQuestionsLoader = false
            });
        builder
            .addCase(makeQstnsGrouped.pending, (state: IConfigData, action: PayloadAction<any>) => {
                state.groupQuestionsLoader = true
            })
            .addCase(makeQstnsGrouped.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
                let qb_ids: any = []
                let modified_data = JSON.parse(JSON.stringify(state.excludeQuestAnsList))
                action.payload.question_ids.forEach((item: any, i: any) => {
                    let index = JSON.parse(JSON.stringify(state.excludeQuestAnsList)).findIndex((item2: any) => item2.question_id === item)
                    if (index != -1) {
                        modified_data[index].group_names = action.payload.question_bucket_names
                    }
                })
                state.excludeQuestAnsList = modified_data
                state.makeQuesGroupSuccess = true
                state.groupQuestionsLoader = false

            })
            .addCase(makeQstnsGrouped.rejected, (state: IConfigData, action: PayloadAction<any>) => {
                state.groupQuestionsLoader = false
            });
        builder
            .addCase(makeQuestionsExclude.pending, (state: IConfigData, action: PayloadAction<any>) => {
            })
            .addCase(makeQuestionsExclude.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
                state.excluded_questions_success = action.payload.response.status
                if (state.excluded_questions_success) {
                    let modified_data = JSON.parse(JSON.stringify(state.excludeQuestAnsList))
                    action.payload.question_ids.forEach((item: any, i: any) => {
                        let index = JSON.parse(JSON.stringify(state.excludeQuestAnsList)).findIndex((item2: any) => item2.question_id === item)
                        modified_data[index].is_active = false
                    })
                    state.excludeQuestAnsList = modified_data

                }

            })
            .addCase(makeQuestionsExclude.rejected, (state: IConfigData, action: PayloadAction<any>) => {
            });

        builder
            .addCase(getMapQnAColNames.pending, (state: IConfigData, action: PayloadAction<any>) => {
            })
            .addCase(getMapQnAColNames.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
                state.mapQnAColNames = action.payload.response.data
                let data = action.payload.response.data
                state.fileNameList = data.map((fileItem: any) => {
                    return { dataset_name: fileItem.dataset_name, dataset_id: fileItem.dataset_id }
                })
            })
            .addCase(getMapQnAColNames.rejected, (state: IConfigData, action: PayloadAction<any>) => {
            });
        builder
            .addCase(getMQnAColValues.pending, (state: IConfigData, action: PayloadAction<any>) => {
            })
            .addCase(getMQnAColValues.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
                if (state.mapQnAColValues[action.payload.datasetID] !== undefined) {
                    state.mapQnAColValues[action.payload.datasetID][action.payload.columnName] = action.payload.response.data
                }
                else {
                    let temp: any = {}
                    temp[action.payload.columnName] = action.payload.response.data
                    state.mapQnAColValues[action.payload.datasetID] = temp
                }
            })
            .addCase(getMQnAColValues.rejected, (state: IConfigData, action: PayloadAction<any>) => {
            });
        builder
            .addCase(sendMQnAExcelData.pending, (state: IConfigData, action: PayloadAction<any>) => {
            })
            .addCase(sendMQnAExcelData.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
                state.sendMQnAdataSuccess = action.payload.response.status
            })
            .addCase(sendMQnAExcelData.rejected, (state: IConfigData, action: PayloadAction<any>) => {
            });
        builder
            .addCase(getInternalPublish.pending, (state: IConfigData, action: PayloadAction<any>) => {
            })
            .addCase(getInternalPublish.fulfilled, (state: IConfigData, action: PayloadAction<any>) => {
            })
            .addCase(getInternalPublish.rejected, (state: IConfigData, action: PayloadAction<any>) => {
            });
    },

})

export const {
    updateMQnAdataSuccess,
    updateSelectedProject,
    updateExcludeQnA,
    updateSelectedQuesExclude,
    updateConfigureAutoSave,
    clearConfigData,
    updateQuestionBucketList,
    updateCurrentPaginationPage,
    updateGroupedSuccess,
    updateMakeQuesGroupSuccess
} = configDataSlice.actions;

export default configDataSlice.reducer;