import { createAsyncThunk } from "@reduxjs/toolkit";
import { configAPI } from "../../service/api/configAPI";
import { MasterAPI } from "../../service/api/masterData";


export const getAllModerators = createAsyncThunk("getAllModerators", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.getAllModerators({ project_id })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getFileNames = createAsyncThunk("getFileNames", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.getFileNames({ project_id })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const sendMQnAData = createAsyncThunk("sendMQnAData", async ({ project_id, Moderator_list, Respondent_list, attribute_json }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.sendMQnAData({ project_id, Moderator_list, Respondent_list, attribute_json })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const getExcludeQnA = createAsyncThunk("getExcludeQnA", async ({ project_id, filter, limit, offset, keyword }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.getExcludeQnA({ project_id, filter, limit, offset, keyword })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const getQuestionBucket = createAsyncThunk("getQuestionBucket", async ({ project_id, limit, offset }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.getQuestionBucket({ project_id, limit, offset })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const addQuestionBucket = createAsyncThunk("addQuestionBucket", async ({ project_id, question_bucket_name }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.addQuestionBucket({ project_id, question_bucket_name })
        return {
            response: response.data,
            question_bucket_name: question_bucket_name
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const makeQstnsGrouped = createAsyncThunk("makeQstnsGrouped", async ({ project_id, question_ids, question_bucket_ids, selBuckets, pageType, question_bucket_names }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.makeQstnsGrouped({ project_id, question_ids, question_bucket_ids, selBuckets, question_bucket_names })
        return {
            response: response.data,
            project_id: project_id,
            question_ids: question_ids,
            question_bucket_ids: question_bucket_ids,
            selBuckets: selBuckets,
            question_bucket_names: question_bucket_names,
            pageType: pageType
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const makeQuestionsExclude = createAsyncThunk("makeQuestionsExclude", async ({ project_id, question_ids, exclude }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.makeQuestionsExclude({ project_id, question_ids, exclude })
        return {
            response: response.data,
            project_id: project_id,
            question_ids: question_ids
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})


export const updateProjState = createAsyncThunk("updateProjState", async ({ project_id, new_state }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.updateProjState({ project_id, new_state })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getMapQnAColNames = createAsyncThunk("getMapQnAColNames", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.getMapQnAColNames({ project_id })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getMQnAColValues = createAsyncThunk("getMapQnAColValues", async ({ project_id, column_name, dataset_id }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.getMQnAColValues({ project_id, column_name, dataset_id })
        return {
            response: response.data,
            columnName: column_name,
            datasetID: dataset_id
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const sendMQnAExcelData = createAsyncThunk("sendMQnAExcelData", async ({ project_id, attribute_json }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.sendMQnAExcelData({ project_id, attribute_json })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})


export const fetchSelectedProject = createAsyncThunk("fetchSelectedProject", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.fetchProject({ project_id })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const getConfigQbs = createAsyncThunk("getConfigQbs", async ({ project_id, offset, limit }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.getConfigQbs({ project_id, offset, limit })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const getGroupQuestionAndAnswers = createAsyncThunk("getGroupQuestionAndAnswers", async ({ project_id, group_ids, offset, limit, keyword }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.getGroupQuestionAndAnswers({ project_id, group_ids, offset, limit, keyword })
        return { response: response.data }
    } catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getInternalPublish = createAsyncThunk("getInternalPublish", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.getInternalPublish({ project_id })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})