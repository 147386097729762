import { createAsyncThunk } from "@reduxjs/toolkit";
import { paymentAPI } from "../../service/api/paymentAPI";

export const getIncludeQuestionCount = createAsyncThunk(
  "getIncludeQuestionCount",
  async ({ project_id }: any, { rejectWithValue }) => {
    try {
      const response = await paymentAPI.getIncludeQuestionCount({ project_id });
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);
export const getExcludeQuestionCount = createAsyncThunk(
  "getExcludeQuestionCount",
  async ({ project_id }: any, { rejectWithValue }) => {
    try {
      const response = await paymentAPI.getExcludeQuestionCount({ project_id });
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);
export const getTotalWordCount = createAsyncThunk(
  "getTotalWordCount",
  async ({ project_id }: any, { rejectWithValue }) => {
    try {
      const response = await paymentAPI.getTotalWordCount({ project_id });
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);
export const getPaymentConfirm = createAsyncThunk(
  "getPaymentConfirm",
  async ({ project_id }: any, { rejectWithValue }) => {
    try {
      const response = await paymentAPI.getPaymentConfirm({ project_id });
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);

export const getClientId = createAsyncThunk("getClientId", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await paymentAPI.getClientId({ projectId });
    return { response: response.data }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getPaymentStatus = createAsyncThunk("getPaymentStatus", async ({ checkoutSessionId, subscriptionId }: any, { rejectWithValue }) => {
  try {
    const response = await paymentAPI.paymentStatus({ checkoutSessionId, subscriptionId });
    return { response: response.data }
  }
  catch (error: any) {
    throw rejectWithValue(error.data);
  }
})

export const getCredits = createAsyncThunk("getCredits", async (any, { rejectWithValue }) => {
  try {
    const response = await paymentAPI.userCredits();
    return { response: response.data }
  }
  catch (error: any) {
    throw rejectWithValue(error.data);
  }
})

export const getFileSlpit = createAsyncThunk("getFileSlpit", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await paymentAPI.getFileSlpit({ projectId });
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data);
  }
})
