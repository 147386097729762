import React, { useEffect } from "react";
import { RootState, useAppDispatch } from "../../redux/store";
import { useParams, useNavigate } from "react-router-dom";
import { updateCurrentPage } from "../../redux/currentPage";
import { useSelector } from "react-redux";
import { getProjectDetails } from "../../redux/Project/action";



function EditProject() {
    const dispatch = useAppDispatch();
    const projectId = useParams().id;

    const projDetails = useSelector((state: RootState) => state.project.projectDetails)
    // const tab = useSelector((state: RootState) => state.editProject.editProjectTab);
    const navigate = useNavigate();

    // const tab = useSelector((state: RootState) => state.editProject.editProjectTab);
    useEffect(() => {
        dispatch(updateCurrentPage("Manage Project"));
        if (projDetails?.PROJECT_ID === projectId) {
            if (projDetails?.STATE === 12) {
                navigate(`/edit-project/${projectId}/publish-and-move`);
            }
            else {
                navigate(`/edit-project/${projectId}/scope`);
            }
        }
        else {
            dispatch(getProjectDetails({ project_id: projectId }))
        }
    }, []);



    useEffect(() => {
        if (projDetails?.STATE === 12) {
            navigate(`/edit-project/${projectId}/publish-and-move`);
        }
        else {
            navigate(`/edit-project/${projectId}/scope`);
        }
    }, [projDetails])

    return (
        <div className="">
            {/* {tab === 'Scope' && <Scope />}
            {tab === 'Entities' && <Entities />}
            {tab === 'Themes' && <Themes />}
            {tab === 'Summaries' && <Summaries />}
            {tab === 'Publish and move' && <PublishAndMove />}
            {tab === 'Merge' && <Merge />} */}
        </div>
    )
}

export default EditProject