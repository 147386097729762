import { axiosconfig } from "../../service/apiconfig"

export const QbQcAPI = {
    submitReprocessing: ({project_id}: any) => {
        return axiosconfig.post(`transcripts/reprocessing?project_id=${project_id}`, {project_id:project_id})
    },
    ResetTranscript: ({project_id}: any) => {
        return axiosconfig.post(`transcripts/resetPage?project_id=${project_id}`, {project_id:project_id})
    },
    getAllQuestionBuckets: ({project_id, limit, offset}: any) =>{
        return axiosconfig.get(`projects/${project_id}/get_question_bucket_v2`)
    },
    addQuestionBucket:({project_id, question_bucket_name}: any) => {
        return axiosconfig.post(`projects/${project_id}/question_buckets_v2/` + question_bucket_name, {
            project_id: project_id,
            question_bucket_name: question_bucket_name,
        })
    },
    addQuestionBucketTranscript:({project_id, question_bucket_name}: any) => {
        return axiosconfig.post(`projects/${project_id}/add_question_bucket_transcript/` + question_bucket_name, {
            project_id: project_id,
            question_bucket_name: question_bucket_name,
        })
    },
    renameQuestionBucket:({project_id,question_bucket_id,new_name}: any) => {
        return axiosconfig.post(`projects/${project_id}/rename_buckets_v2?question_bucket_id=${question_bucket_id}&new_name=${new_name}`,{
            project_id,
            question_bucket_id,
            new_name
        })
    },
    renameQuestionBucketTranscript:({project_id,question_bucket_id,new_question_bucket_name }: any) => {
        return axiosconfig.put(`projects/${project_id}/question_bucket_transcript/edit?question_bucket_id=${question_bucket_id}&new_question_bucket_name=${new_question_bucket_name}`,{
            project_id,
            question_bucket_id,
            new_question_bucket_name
        })
    },
    deleteQuestionBuckets: ({ project_id ,question_bucket_id}: any) => {
        return axiosconfig.delete(`projects/${project_id}/groups/delete_buckets`,{'data':question_bucket_id});
    },
    deleteQuestionBucketTranscript: ({ project_id ,question_bucket_id}: any) => {
        return axiosconfig.delete(`projects/${project_id}/question_bucket_transcript/delete?question_bucket_id=${question_bucket_id}`,{'data':question_bucket_id});
    },
    getTranscript: ({project_id, dataset_id, get_moderator_only}: any) => {
        return axiosconfig.post(`projects/${project_id}/get_transcript_v2`,{
            project_id,
            dataset_id,
            get_moderator_only
        })
    },
    getTranscriptNewTab: ({project_id, dataset_id}: any) => {
        return axiosconfig.get(`projects/${project_id}/get_transcript/${dataset_id}`)
    },
    assignQuestionBucket: ({project_id, text_ids, question_bucket_ids, exclude,bucket_type, remove}:any) =>{
        return axiosconfig.post(`projects/${project_id}/assign_bucket?bucket_type=${bucket_type}&exclude=${exclude}&remove=${remove}`,{
            text_ids,
            question_bucket_ids,
            bucket_type,
            exclude,
            remove
            })
    },
    assignQuestionBucketTranscript: ({project_id, text_ids, question_bucket_ids, exclude,bucket_type, remove}:any) =>{
        return axiosconfig.post(`projects/${project_id}/assign_bucket_transcript?bucket_type=${bucket_type}&exclude=${exclude}&remove=${remove}`,{
            text_ids,
            question_bucket_ids,
            bucket_type,
            exclude,
            remove
        })
    },
    getQBForSelected : ({project_id, text_ids}: any) =>{
        return axiosconfig.post(`projects/${project_id}/get_qb_of_selected_section_v2`, text_ids)
    },
    getSelectedQBFromSTG : ({project_id, text_ids}: any) =>{
        return axiosconfig.post(`projects/${project_id}/get_qb_of_selected_section_transcript`, text_ids)
    },
    submitQbQc: ({projectId}: any) => {
        return axiosconfig.post(`projects/${projectId}/qb_qc/action=confirm`, {})
    },
    submitThemesQC: ({projectId}: any) => {
        return axiosconfig.post(`/projects/${projectId}/manage_themes_qc`, {})
    },
    getQuestionBuckets: ({project_id,offset,limit}:any)=>{
        return axiosconfig.get(`projects/${project_id}/V3/question_buckets?offset=${offset}&limit${limit}`)
    },
    getQuestionBucketsStaging: ({project_id,offset,limit}:any)=>{
        return axiosconfig.get(`projects/${project_id}/question_buckets_transcripts?offset=${offset}&limit${limit}`)
    },
    getTextIDs:({project_id,question_bucket_id,dataset_ids}:any)=>{
        return axiosconfig.post(`projects/${project_id}/V3/question_buckets/text_ids?question_bucket_id=${question_bucket_id}`,dataset_ids)
    },
    getTextIDsTranscript:({project_id,question_bucket_id,dataset_ids}:any)=>{
        return axiosconfig.post(`projects/${project_id}/question_buckets_transcripts/text_ids?question_bucket_id=${question_bucket_id}`,dataset_ids)
    },
    getUnMappedQB:({project_id}:any)=>{
        return axiosconfig.get(`transcripts/check_qb_mapping?project_id=${project_id}`,{project_id})
    },
    getOverlappedAnalysis:({project_id,isReview}:any)=>{
        return axiosconfig.get(`/transcripts/${project_id}/get_mapped_overlaps?review=${isReview}`)
    }
}

