import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FileText, XCircle } from "react-feather";
import { RootState, useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { updateNotes } from "../../redux/Masters/masterDataReducer";
import { getNotes, postNotes } from "../../redux/Masters/action";
import { useLocation } from "react-router-dom";
import { SvgIcon, Tooltip } from "@mui/material";
import { openSnackbar } from "../../redux/Notification/notificationReducer";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Spinner from "../Spinner";
import CircleIcon from '@mui/icons-material/Circle';

const NotePad = () => {

  const dispatch = useAppDispatch()
  const { pathname } = useLocation();

  const id = useLocation().pathname;
  const projectId = id.split('/')[2];
  const notes = useSelector((state: any) => state.master.notes)
  const selectedRBNote = useSelector((state: any) => state.master.selectedRBNote)
  const notesLoader = useSelector((state: RootState) => state.master.notesLoader)

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [editorContent, setEditorContent] = useState<string>(''); // Editor content state
  const [displayData, setDisplayData] = useState<Array<any>>([])
  const [selectedItem, setSelectedItem] = useState<any>("")
  const [selectedSlide, setSelectedSlide] = useState<any>()
  const [allSlideData, setAllSlideData] = useState<Array<any>>([])
  const divRef = useRef<any>(null)
  const [contHeight, setContHeight] = useState<number>(window.innerHeight * 0.70)
  const quillRef = useRef<any>(null)
  const [expanded, setExpanded] = useState<string>("")
  const [showAll, setShowAll] = useState<boolean>(true)
  const recentlyExpanded = useRef<string>("")

  const handleEditorChange = (content: any) => {
    let charLen = stripHtmlTags(content)
    if (charLen.length <= 2000) {
      setEditorContent(content);
    } else if (charLen?.length > 2000) {
      setEditorContent(createHtmlTags(charLen.slice(0, 2000)));
      dispatch(openSnackbar({ message: "You have reached the 2000 character limit for this slide", type: "error" }))
    }
  };


  const handleItemChange = (data: any) => {
    let tempEditor = stripHtmlTags(editorContent)
    let temp = allSlideData.map((item: any) => {
      if (item.slide_id === selectedSlide) {
        return { ...item, notes: tempEditor }
      }
      else {
        return item
      }
    })
    if (selectedItem === 'general') {
      dispatch(updateNotes({ id: selectedItem, data: temp }))
    }
    else {
      dispatch(updateNotes({ id: selectedItem.id, data: temp, type: selectedItem.type }))
    }
    if (data.id === 'general') {
      setSelectedItem(data.id)
    }
    else {
      setSelectedItem({ id: data.id, type: data.type })
    }
    setTimeout(() => { quillRef.current.focus() }, 150)
  }

  const handleSlideChange = (id: number) => {
    let tempEditor = stripHtmlTags(editorContent)
    let temp = allSlideData.map((item: any) => {
      if (item.slide_id === selectedSlide) {
        return { ...item, notes: tempEditor }
      }
      else {
        return item
      }
    })
    setAllSlideData(temp)
    setSelectedSlide(id)
    let data = temp.find((item: any) => item.slide_id === id).notes
    setEditorContent(createHtmlTags(data))
    setTimeout(() => quillRef.current.focus(), 200)
  }

  const setQuillSelectionRange = () => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = { index: stripHtmlTags(editorContent).length, length: 0 };
      quill.setSelection(range);
    }
  }


  const handleClose = () => {
    let tempEditor = stripHtmlTags(editorContent)
    let tempAllSlides: any = allSlideData?.map((item: any) => {
      if (item.slide_id === selectedSlide) {
        return { ...item, notes: tempEditor }
      }
      else {
        return item
      }
    })

    let temp = JSON.parse(JSON.stringify(notes))
    if (selectedItem === 'general') {
      dispatch(updateNotes({ id: selectedItem, data: tempAllSlides }))
    }
    else {
      dispatch(updateNotes({ id: selectedItem.id, data: tempAllSlides, type: selectedItem.type }))
    }



    if (selectedItem === "general") {
      temp.general_notes = tempAllSlides;
    }
    else {
      switch (selectedItem.type) {
        case 'Reporting Buckets':
          temp.question_bucket_notes.data = temp.question_bucket_notes.data.map(
            (item: any) => {
              if (item.id === selectedItem.id) {
                return { ...item, notes: tempAllSlides };
              } else {
                return item;
              }
            }
          )
          break;
        case 'Themes':
          temp.themes.data = temp.themes.data.map(
            (item: any) => {
              if (item.id === selectedItem.id) {
                return { ...item, notes: tempAllSlides };
              } else {
                return item;
              }
            }
          )
          break;
        default:
          let entity_index = temp.entities.findIndex((item: any) => item.type === selectedItem.type)
          temp.entities[entity_index].data = temp.entities[entity_index].data.map(
            (item: any) => {
              if (item.id === selectedItem.id) {
                return { ...item, notes: tempAllSlides };
              } else {
                return item;
              }
            }
          );
      }
    }

    setSelectedItem("general")
    setEditorContent("")
    setExpanded("")
    setShowAll(true)
    recentlyExpanded.current = ""
    setOpenPopup(false)

    setTimeout(() => {
      dispatch(postNotes({ projectId: projectId, payload: temp, page: pathname.split('/')[1] }))
    }, 100)
  }

  const stripHtmlTags = (html: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    let cleanString = '';
    const paragraphs = doc.getElementsByTagName('p');

    for (let i = 0; i < paragraphs.length; i++) {
      const paragraph = paragraphs[i];
      const text = paragraph.textContent;
      cleanString += text
      if (i !== paragraphs.length - 1) {
        cleanString += '\n'
      }
    }
    return cleanString;
  };

  const createHtmlTags = (data: string) => {
    const container = document.createElement('div')

    if (data !== undefined) {
      const texts = data.split('\n')
      texts.map((item: any) => {
        let para = document.createElement('p')
        if (item !== "") {
          if (item[0] === " ") {
            item = '&nbsp;' + item
          }
          if (item[item.length - 1] === " ") {
            item = item + '&nbsp;'
          }
          para.innerHTML = item.replace(/ {2,}/g, (match: any) => '&nbsp;'.repeat(match.length))
        }
        else {
          para.innerHTML = "<br>"
        }
        container.append(para)
      })
      return container.innerHTML
    }
    return ""
  }



  useEffect(() => {
    setSelectedItem('general')
  }, [])

  useEffect(() => {
    if (Object.keys(notes).length > 0) {
      let data = []
      data?.push(notes?.question_bucket_notes, notes?.themes, ...notes?.entities)
      setDisplayData(data)
    }
    // setReportingBucks(notes.question_bucket_notes)
    setSelectedSlide(1)
    if (selectedItem === 'general') {
      let temp = notes.general_notes?.find((item: any) => item.slide_id === 1).notes
      setEditorContent(createHtmlTags(temp))
      setAllSlideData(notes?.general_notes)
    }
    else {
      let bucket: Array<any> = []
      switch (selectedItem.type) {
        case 'Reporting Buckets':
          bucket = notes?.question_bucket_notes !== undefined ? notes.question_bucket_notes.data.find((item: any) => item.id === selectedItem.id)?.notes : []
          break;
        case 'Themes':
          bucket = notes?.themes !== undefined ? notes.themes.data.find((item: any) => item.id === selectedItem.id)?.notes : []
          break;
        default:
          let entity = notes.entities?.find((item: any) => item.type === selectedItem.type)
          bucket = entity?.data?.find((item: any) => item.id === selectedItem.id)?.notes;
      }
      let temp = bucket?.find((item: any) => item.slide_id === 1)?.notes
      setEditorContent(createHtmlTags(temp))
      setAllSlideData(bucket)
    }
  }, [notes])

  const handleNotes = () => {
    dispatch(getNotes({ projectId: projectId, page: pathname.split('/')[1] }));
    setOpenPopup(true);
  }

  useEffect(() => {
    if (!notesLoader) {
      if (selectedRBNote === null) {
        setSelectedItem('general')
        setAllSlideData(notes.general_notes)
        let temp = notes?.general_notes?.find((item: any) => item.slide_id === 1)?.notes
        setEditorContent(createHtmlTags(temp))
      }
      else {
        let item: any = {}
        switch (selectedRBNote.type) {
          case 'Reporting Buckets':
            item = notes?.question_bucket_notes?.data.find((item: any) => item.id === selectedRBNote.data[0].id[0]?.id)
            break;
          case 'Themes':
            item = notes?.themes?.data?.find((item: any) => item.id === selectedRBNote.data[0].id[0]?.id)
            break;
          default:
            let entity = notes?.entities?.find((ent: any) => ent.type === selectedRBNote.type)
            item = entity?.data.find((item: any) => item.id === selectedRBNote?.data[0]?.id[0]?.id);
            break;
        }
        if (item) {
          setExpanded(selectedRBNote.type)
          setShowAll(false)
          recentlyExpanded.current = selectedRBNote.type
          setSelectedItem({ type: selectedRBNote.type, id: item.id })
          setAllSlideData(item.notes)
          let temp = item.notes.find((item: any) => item.slide_id === 1).notes
          setEditorContent(createHtmlTags(temp))
        }
        else {
          setSelectedItem('general')
          setAllSlideData(notes.general_notes)
          let temp = notes?.general_notes?.find((item: any) => item.slide_id === 1)?.notes
          setEditorContent(createHtmlTags(temp))
        }
      }
      setTimeout(() => quillRef.current?.focus(), 150)
    }
  }, [notesLoader])

  const filteredDisplayData = displayData.filter((item: any) => !(item.type === "Reporting Buckets" && item.data.length === 0))
  return (
    <>
      <Tooltip title={<span >Add notes in Notebook</span>} ><SvgIcon onClick={handleNotes} viewBox="0 0 24 24" sx={{ width: '28', height: '28', fill: 'none', cursor: 'pointer', color: "black" }}>
        <g clipPath="url(#clip0_731_6703)">
          <path d="M22 3H16C14.9391 3 13.9217 3.42143 13.1716 4.17157C12.4214 4.92172 12 5.93913 12 7V21C12 20.2044 12.3161 19.4413 12.8787 18.8787C13.4413 18.3161 14.2044 18 15 18H22V3Z" stroke='#F59E1B' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
          <path d="M2 3H8C9.06087 3 10.0783 3.42143 10.8284 4.17157C11.5786 4.92172 12 5.93913 12 7V21C12 20.2044 11.6839 19.4413 11.1213 18.8787C10.5587 18.3161 9.79565 18 9 18H2V3Z" stroke='#F59E1B' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
        </g>
        <defs>
          <clipPath id="clip0_731_6703">
            <rect width="24" height="24" fill="none" />
          </clipPath>
        </defs>
      </SvgIcon></Tooltip>
      <Popup
        modal
        open={openPopup}
        onOpen={() => {
          setOpenPopup(true)
          if (!notesLoader) {
            if (selectedRBNote === null) {
              setSelectedItem('general')
              setAllSlideData(notes.general_notes)
              let temp = notes?.general_notes?.find((item: any) => item.slide_id === 1)?.notes
              setEditorContent(createHtmlTags(temp))
            }
            else {
              let item: any = {}
              switch (selectedRBNote.type) {
                case 'Reporting Buckets':
                  item = notes?.question_bucket_notes?.data.find((item: any) => item.id === selectedRBNote.data[0].id[0]?.id)
                  break;
                case 'Themes':
                  item = notes.themes.data.find((item: any) => item.id === selectedRBNote.data[0].id[0]?.id)
                  break;
                default:
                  let entity = notes.entities.find((ent: any) => ent.type === selectedRBNote.type)
                  item = entity?.data.find((item: any) => item.id === selectedRBNote.data[0].id[0]?.id)
                  break;
              }
              if (item) {
                setExpanded(selectedRBNote.type)
                setShowAll(false)
                recentlyExpanded.current = selectedRBNote.type
                setSelectedItem({ type: selectedRBNote.type, id: item.id })
                setAllSlideData(item.notes)
                let temp = item.notes.find((item: any) => item.slide_id === 1).notes
                setEditorContent(createHtmlTags(temp))
              }
              else {
                setSelectedItem('general')
                setAllSlideData(notes.general_notes)
                let temp = notes?.general_notes?.find((item: any) => item.slide_id === 1)?.notes
                setEditorContent(createHtmlTags(temp))
              }
            }
            setTimeout(() => quillRef.current?.focus(), 150)
          }
        }}
        onClose={() => handleClose()}

        className="rounded-lg bg-gray-200"
        contentStyle={{
          width: "80vw",
          height: "70vh",
          maxHeight: "70vh",
          borderRadius: "6px",
          backgroundColor: "#f5f7fb"
        }}
      >
        <div className="h-full px-1" ref={divRef}>
          <div className="flex justify-between" style={{ height: 0.06 * contHeight, maxHeight: 0.06 * contHeight }}>
            <h2 className="font-semibold text-xl pl-1.5">Additional Notes for Story Telling</h2>
            <XCircle
              className=""
              size={24}
              style={{ cursor: "pointer" }}
              fill="#BDBCBC"
              color="#FFFF"
              onClick={() => setOpenPopup(false)}
            />
          </div>
          {notesLoader ?
            <div style={{ height: 0.91 * contHeight, maxHeight: 0.91 * contHeight, marginTop: "7vh" }}>
              <Spinner text="Fetching Data. Please wait..." />
            </div>
            :
            <div className="grid grid-cols-4 gap-4" style={{ height: 0.91 * contHeight, maxHeight: 0.91 * contHeight }}>
              <div className="bg-white rounded-md shadow-md flex flex-col justify-start gap-2 p-2" style={{ height: 0.91 * contHeight, maxHeight: 0.91 * contHeight }}>
                <div className={`font-semibold flex justify-start items-center gap-1.5 cursor-pointer p-2 py-3 rounded-md ${selectedItem === "general" ? "bg-violet-200 border border-[#9747FF]" : "hover:bg-violet-100"}`}
                  onClick={() => {
                    handleItemChange({ type: "general", id: "general", notes: notes?.general_notes })
                  }}
                >
                  <FileText size={18} />
                  <span>General Notes</span>
                </div>
                <div className={`h-[93%] ${expanded ? "overflow-y-hidden" : "overflow-y-auto"}`}>
                  {filteredDisplayData.map((item: any, index: number) => {
                    return (
                      <div className={`rounded-md ${!showAll ? recentlyExpanded.current === item.type ? "bg-gray-100 pb-1" : "hidden" : ""}`} key={index}>
                        <Accordion elevation={0} sx={{
                          backgroundColor: "transparent",
                          '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
                            minHeight: "fit-content !important",
                          },
                          '& .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters': {
                            margin: "12px 0px 20px 0px !important"
                          }, "& .css-157r5e3-MuiAccordionDetails-root":
                          {
                            paddingBottom: '35px',
                          }
                        }}
                          expanded={expanded === item.type}
                          onClick={() => {
                            if (item.type === expanded) {
                              if (selectedItem !== 'general') {
                                handleItemChange({ type: "general", id: "general", notes: notes?.general_notes })
                              }
                              setExpanded("")
                              setTimeout(() => {
                                setShowAll(true)
                                recentlyExpanded.current = ""
                              }, 150)
                            }
                            else {
                              setExpanded(item.type)
                              setShowAll(false)
                              recentlyExpanded.current = item.type
                            }
                          }}
                        >
                          <AccordionSummary className={`${recentlyExpanded.current === item.type ? "" : "hover:bg-gray-100"}`}
                            expandIcon={<ArrowForwardIosSharpIcon className="rotate-90" fontSize="small" />}
                            sx={{
                              borderRadius: "0.375rem",
                              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                transform: 'rotate(180deg)',
                              },
                            }}
                          >
                            <span className="font-semibold">{item.type === "Reporting Buckets" ? 'Analysis Topics' : item.type} ({item.data.length})</span>
                            <div>
                              {expanded &&
                                item.type === "Themes" ?
                                <div className="flex relative top-6 gap-0.5 " style={{ right: '85px' }}>
                                  <Tooltip title={<span style={{ whiteSpace: 'pre-line', fontSize: "13px" }}>Positive</span>} >
                                    <CircleIcon
                                      style={{ color: '#27AE68', cursor: 'pointer', width: '16px', height: '15px' }} />
                                  </Tooltip>
                                  <Tooltip title={<span style={{ whiteSpace: 'pre-line', fontSize: "13px" }}>Negative</span>}>
                                    <CircleIcon
                                      style={{ color: '#F03A5B', cursor: 'pointer', width: '16px', height: '15px' }} />
                                  </Tooltip>
                                  <Tooltip title={<span style={{ whiteSpace: 'pre-line', fontSize: "13px" }}>Mixed</span>}>
                                    <CircleIcon
                                      style={{ color: '#338ACD', cursor: 'pointer', width: '16px', height: '15px' }} />
                                  </Tooltip>
                                </div> : ''
                              }
                            </div>
                          </AccordionSummary>
                          <AccordionDetails sx={{ maxHeight: (0.91 * contHeight) * 0.77, overflowY: "auto" }} onClick={(e) => e.stopPropagation()}>
                            {expanded === item.type &&
                              item.data.map((elem: any, i: number) => {
                                return (
                                  <div key={`note${i}`} className={`my-1 cursor-pointer p-2 rounded-md hover:bg-violet-100 ${selectedItem !== 'general' ? selectedItem.id === elem.id ? "bg-violet-200 border border-[#9747FF]" : "" : ""}`}
                                    onClick={() => {
                                      handleItemChange({ type: item.type, ...elem })
                                    }}
                                  >
                                    <span className={`${item.type === 'Themes' ? elem.sentiment === "Overall" ? "text-[#338ACD]" : elem.sentiment === "Positive" ? "text-[#27AE68]" : "text-[#F03A5B]" : ""}`}>{elem.display_name}</span>
                                  </div>
                                )
                              })
                            }
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
              <div className="col-span-3 flex flex-col justify-between" style={{ height: 0.91 * contHeight, maxHeight: 0.91 * contHeight }}>
                <div className="bg-white rounded-md shadow-md h-[92%] flex flex-col justify-between py-1">
                  <div className="overflow-y-auto">
                    <ReactQuill
                      key={selectedSlide}
                      ref={quillRef}
                      value={editorContent}
                      onChange={handleEditorChange}
                      modules={{
                        toolbar: false
                      }}
                      formats={[]}
                      placeholder="Type here..."
                      //formats={dataEditors.formats}
                      onKeyDown={(e: any) => {
                        if (stripHtmlTags(editorContent).length >= 2000 && e.key !== "Backspace" && !((e.key === "z" || e.key === "a") && (e.ctrlKey || e.metaKey))) {
                          e.preventDefault()
                          dispatch(openSnackbar({ message: "You have reached the 2000 character limit for this slide", type: "error" }))
                        }
                      }}
                      onFocus={(e: any) => setQuillSelectionRange()}
                    // onBlur={(e) => setQuillSelectionRange()}
                    />
                  </div>
                  <div className="flex justify-end items-center px-1">
                    <span className="bg-gray-800 rounded-md text-white px-2 py-1 text-xs opacity-90">
                      {`${stripHtmlTags(editorContent)?.length}/2000`}
                    </span>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-1 h-[7%]">
                  {Array.from(Array(4).keys()).map((item: number) => {
                    return (
                      <div className={`hover:shadow-md ${selectedSlide === item + 1 ? "bg-violet-200 shadow-md border border-violet-400" : "bg-white shadow-sm"} 
                      px-2 py-1 rounded-md cursor-pointer`}
                        onClick={() => handleSlideChange(item + 1)}
                      >
                        Slide {item + 1}
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </Popup>
    </>
  );
};

export default NotePad;
