import { useState, useEffect } from "react"
import { User, GitBranch, Globe, HelpCircle } from 'react-feather'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { updateLoginDetails, updateStatus, updateUserInfo } from "../../redux/Profile/profileDataReducer"
import { RootState, useAppDispatch } from "../../redux/store"
import { getFeatures } from '../../redux/Masters/action';
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { userLogout } from "../../redux/Profile/action";
import { getAccessToken } from "../../utils/localStorage";
import { openSnackbar } from "../../redux/Notification/notificationReducer";
import Popup from "reactjs-popup";
import Spinner from "../Spinner";
import { decryption, secrateKey } from "../../modules/LoginLayout/LoginLayout";

export default function ProfileSideNav() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const disp = useDispatch()
  const [selectedTab, setSelectedTab] = useState("MyAccount")
  const [username, setUsername] = useState("");
  const location = useLocation();
  const [logoutLoader, setLogoutLoader] = useState<boolean>(false)
  const userFeatures: any = useSelector((state: RootState) => state.master.featuresData)
  const userDetails: any = useSelector((state: RootState) => state.profile.userInfo)

  const [userManagement, setUserManagement] = useState(false);
  const encrypted = localStorage.getItem("_U");


  useEffect(() => {
    if (userFeatures == '') {
      dispatch(getFeatures({}));
    }
    if (userFeatures?.includes('SHOW_USERMANAGEMENT')) {
      setUserManagement(true);
    }
  }, [userFeatures]);

  useEffect(() => {
    setSelectedTab(location.pathname.slice(1))
    // dispatch(getUser({}))
    dispatch(updateUserInfo())
  }, [])

  useEffect(() => {
    if (encrypted) {
      let userInfo: any = decryption(encrypted, secrateKey)
      if (userInfo) {
        userInfo = JSON.parse(userInfo);
        setUsername(userDetails['FIRSTNAME']);


      }
    }
  }, [userDetails]);

  const logout = async () => {
    const accessToken = getAccessToken()
    setLogoutLoader(true)
    dispatch(userLogout({ token: accessToken })).then((result: any) => {
      setLogoutLoader(false)
      if (result.meta.requestStatus === "fulfilled") {
        localStorage.clear();
        dispatch(updateLoginDetails({}));
        dispatch(updateStatus());
        disp({ type: "USER_LOGOUT" })
        navigate('/login', { replace: true });
      }
      else {
        dispatch(openSnackbar({ message: `Logout unsuccessful`, type: "error" }))
      }
    })
  }

  return (
    <div className="pt-12 h-full bg-white border-r-2">
      <div className=" w-full shadow-3xl">
        <div className="flex justify-between flex-col ">
          <div
            style={{
              height: "29.2px",
              marginTop: "40px",
              marginBottom: "25px",
            }}
            className="my-4 w-[317px]"
          >
            {username?.length > 15 ? <Tooltip title={username}>
              <span
                className="w-[317px] truncate"
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#3B3B3B",
                  float: "left",
                  paddingLeft: "1.5rem",
                }}
              >
                Welcome, {username}
              </span>
            </Tooltip> :
              <span
                className="w-[317px] truncate"
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#3B3B3B",
                  float: "left",
                  paddingLeft: "1.5rem",
                }}
              >
                Welcome, {username}
              </span>
            }
          </div>
          <Link to="/profile">
            <div
              className={`flex items-center py-6 pl-4 cursor-pointer text-lg ${selectedTab === "profile"
                  ? "bg-gradient-to-r from-custom-gray to-custom-lightblue text-custom-blue "
                  : "hover:bg-gray-50 transition duration-200"
                }`}
              style={{ color: (selectedTab === "profile") ? "#9747FF" : "" }}
              onClick={() => setSelectedTab("profile")}
            >
              <User className="ml-2 mr-3" />
              <span>My Account</span>
            </div>
          </Link>

          {/* <Link to="/subscription">
              <div
                className={`flex items-center py-6 pl-4 cursor-pointer text-lg ${
                  selectedTab === "Subscription"
                    ? "bg-gradient-to-r from-custom-gray to-custom-lightblue text-custom-blue "
                    : "hover:bg-gray-50 transition duration-200"
                }`}
                style={{color:( selectedTab === "Subscription")?"#9747FF":""}}
                onClick={() => setSelectedTab("Subscription")}
              >
                <Layout className="ml-2 mr-3" />
                <span>Subscription</span>
              </div>
            </Link> */}
          {userManagement ? (
            <Link to="/userManagement">
              <div
                className={`flex items-center py-6 pl-4 cursor-pointer text-lg ${selectedTab === "userManagement"
                    ? "bg-gradient-to-r from-custom-gray to-custom-lightblue text-custom-blue "
                    : "hover:bg-gray-50 transition duration-200"
                  }`}
                style={{ color: (selectedTab === "userManagement") ? "#9747FF" : "" }}
                onClick={() => setSelectedTab("userManagement")}
              >
                <GitBranch className="ml-2 mr-4" />
                <span>User Management</span>
              </div>
            </Link>
          ) : (
            ""
          )}
          {/* <div
                        className={`flex items-center py-6 pl-4 cursor-pointer text-lg ${selectedTab === "PaymentMethod" ? "bg-gradient-to-r from-custom-gray to-custom-lightblue text-custom-blue " : "hover:bg-gray-50 transition duration-200"}`}
                        onClick={() => setSelectedTab("PaymentMethod")}
                    >
                        {<DollarSign className="ml-2 mr-3"/>}
                        <span>Payment Method</span>
                    </div> */}
          {/* <Link to="/credits">
              <div
                className={`flex items-center py-6 pl-4 cursor-pointer text-lg ${
                  selectedTab === "Credits"
                    ? "bg-gradient-to-r from-custom-gray to-custom-lightblue text-custom-blue "
                    : "hover:bg-gray-50 transition duration-200"
                }`}
                style={{color:( selectedTab === "Credits")?"#9747FF":""}}
                onClick={() => setSelectedTab("Credits")}
              >
                {<DollarSign className="ml-2 mr-3" />}
                <span>Credits</span>
              </div>
            </Link> */}
          {userFeatures.includes("VALIDATE_PENDING_DOMAINS") && (
            <Link to="/domain-approval">
              <div
                className={`flex items-center py-6 pl-4 cursor-pointer text-lg ${selectedTab === "domain-approval"
                    ? "bg-gradient-to-r from-custom-gray to-custom-lightblue text-custom-blue "
                    : "hover:bg-gray-50 transition duration-200"
                  }`}
                onClick={() => setSelectedTab("domain-approval")}
              >
                <Globe className="ml-2 mr-3" />
                <span>Domain Approval</span>
              </div>
            </Link>
          )}

          <div className="ml-3 mr-2 w-72 mt-6 mr-4 border-b border-b-gray-300" />
          <div
            className="flex items-center my-4 ml-4 cursor-pointer"
            onClick={() => logout()}
          >
            <span className="text-lg ml-1 text-red-600 font-bold">
              Log out
            </span>
          </div>
          <div className="flex items-center my-4 ml-4 gap-2 text-gray-400 " >
            <span className="text-lg ml-1 cursor-pointer" onClick={() => navigate('/help')}>Help</span>
            <HelpCircle className="cursor-pointer" onClick={() => navigate('/help')} size={16} />
          </div>
        </div>
      </div>
      <Popup modal open={logoutLoader} contentStyle={{ display: "flex", justifyContent: "center", backgroundColor: "transparent", border: "none", zIndex: 1000 }}>
        <div className="flex flex-col items-start justify-center mr-2  bg-transparent pb-6 pt-5">
          <Spinner />
        </div>
      </Popup>
    </div>
  );
}