import { useEffect, useRef, useState } from "react"
import { ChevronsRight, MinusSquare, PlusSquare } from "react-feather"
import SummaryViewLabel from "./SummaryViewLabel.component"
import Spinner from "../Spinner"

interface ISummaryView {
    selectedTheme: any
    dataList: Array<any>
    showLoader: boolean
    showTheme?: boolean
    setShowTheme?: any
}

export default function SummaryView(props: ISummaryView) {

    const [expandAll, setExpanAll] = useState<boolean>(false)
    const [summaryHeight, setSummaryHeight] = useState<number>(0)
    const summaryRef = useRef<any>(null)

    useEffect(() => {
        const summaryTopPos = summaryRef.current?.getBoundingClientRect().top;
        setSummaryHeight(window.innerHeight - summaryTopPos - 75);
    })

    return (
        <>
            <div className="flex justify-between items-center p-1">
                <div className="flex justify-start items-center gap-2">
                    <span className="text-lg font-semibold">Summary</span>
                    {(Object.keys(props.selectedTheme).length > 0 && !props.showTheme) &&
                        <div className="flex justify-between items-center w-fit cursor-pointer" onClick={() => props.setShowTheme(true)}>
                            <ChevronsRight color="gray" size={18} />
                            <span className="font-semibold text-gray-500 text-sm">Expand Themes :
                                <span className="uppercase text-blue-400 ml-1">{props.selectedTheme.name}</span>
                            </span>
                        </div>
                    }
                </div>
                {Object.keys(props.selectedTheme).length === 0 &&
                    <div className="flex justify-end items-center gap-2 pr-1 cursor-pointer" onClick={() => setExpanAll(!expandAll)}>
                        {expandAll ? <MinusSquare size={16} /> : <PlusSquare size={16} />}
                        <span>{expandAll ? "Collapse all" : "Expand all"}</span>
                    </div>
                }
            </div>
            <div className="bg-white rounded-lg p-4 text-left overflow-y-auto shadow-md" ref={summaryRef} style={{ height: summaryHeight }}>
                {!props.showLoader ?
                    (Object.keys(props.selectedTheme).length === 0 ?
                        <div className="flex flex-col justify-start pt-1 gap-2">
                            {props.dataList?.map((item: any) => {
                                return (
                                    <SummaryViewLabel data={item} expandAll={expandAll} />
                                )
                            })}
                        </div>
                        :
                        <span>{props.selectedTheme.summary}</span>
                    )
                    :
                    <div className="h-full w-full flex justify-center items-center">
                        <Spinner height={20} />
                    </div>
                }
            </div>
        </>
    )
}