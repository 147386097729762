import { useEffect, useState } from "react";
import { AccordionFunc } from "../Accordian/accordian";
import { ISelectedTopic } from "../../modules/Review/Review";
import { updateIsHideMessage, updateSelectedAnalyzeByItem } from "../../redux/focus/focusReducer";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import { useLocation, useParams } from "react-router-dom";
import SavedView from "../SavedView/SavedView";
import WordCloudComponent from "./WordCloudComponent";
import { Tooltip } from "@mui/material";
import CompareComp from "./CompareComp";
import { RootState, useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { getIntersectingAreas } from "../../redux/CompareWordCloud/action";
import { updateSelectedRBNote } from "../../redux/Masters/masterDataReducer";
import { updateSelectedTopicViewVizId } from "../../redux/CompareWordCloud/dataReducer";

const LayoutForCompareWordCloud = () => {
  const dispatch = useAppDispatch();
  const pathName = useLocation().pathname.split("/")[1];
  const projectId = useParams().id

  const [analyzeByDataIsLoading, setAnalyzeByDataIsLoading] = useState(true)

  const savedViewData = useSelector((state: RootState) => state.compare.savedViewData);
  const [selectedTopic, setSelectedTopic] = useState<ISelectedTopic | null>(
    null
  );
  const [isDivOpen, setIsDivOpen] = useState(false);

  // !important
  const [analyzeByData, setAnalyzeByData] = useState<any>([])
  const [areasToShow, setAreasToShow] = useState<any>([])
  const [updateSelectedQB, setUpdateSelectedQB] = useState<any>(null)

  const [areasinViewViz, setAreasinViewViz] = useState<any>([])
  const [config1ViewViz, setConfig1ViewViz] = useState()
  const [config2ViewViz, setConfig2ViewViz] = useState()

  //  do NOT use this anywhere else than worCloud
  const [areasToShowWordCloud, setAreasToShowWordCloud] = useState<any>([])
  const [wordCloudViewViz, setWordCloudViewViz] = useState<any>(null)
  const [worldcloudDeleteID, setWorldcloudDeleteID] = useState<any>(null)
  const [savedViewRenameItem, setSavedViewRenameItem] = useState<any>(null)
  const [addBookMarkToggle, setAddBookMarkToggle] = useState(false)

  const [selectedSortFilter, setSelectedSortFilter] = useState<any>("default")


  useEffect(() => {
    setUpdateSelectedQB(selectedTopic?.type)
  }, [selectedTopic])

  const handleBookmarkToggle = () => {
    setIsDivOpen((prevState) => !prevState);
  };

  useEffect(() => {
    dispatch(updateSelectedAnalyzeByItem(selectedTopic));
    let data = null
    if (selectedTopic !== null) {
      data = {
        type: selectedTopic.type,
        data: [
          {
            id: [{ id: selectedTopic.id }]
          }
        ]
      }
    }
    dispatch(updateSelectedRBNote(data))
  }, [selectedTopic, dispatch])


  function getColor(item: string) {
    if (item === "Positive") {
      return "bg-green-500";
    } else if (item === "Negative") {
      return "bg-red-500";
    } else if (item === "Mixed") {
      return "bg-blue-500";
    } else {
      return "bg-gray-500";
    }
  }
  const handleAnalyzeBySection = (item: any, type: string) => {
    if (item.id[0]?.id !== selectedTopic?.id) {
      const data = {
        type,
        display_name: item.display_name,
        id: item.id[0]?.id,
      };

      setSelectedTopic(data);
      dispatch(updateIsHideMessage(false));
    }
  };

  useEffect(() => {
    if (selectedTopic) {
      // setAnalyzeByDataIsLoading(true)
      dispatch(getIntersectingAreas({
        project_id: projectId,
        bucket_id: selectedTopic.id
      })).then((res: any) => {
        if (Array.isArray(res.payload.response.data)) {
          setAreasToShow(res.payload.response.data)
          setAreasToShowWordCloud(res.payload.response.data)
          // setAnalyzeByDataIsLoading(false)
        } else {
          setAreasToShowWordCloud([])
          setAreasToShow([])
          // setAnalyzeByDataIsLoading(false)
        }
      })
    } else {
      setAreasToShow(analyzeByData?.filter((item: any) => item.type !== 'Reporting Buckets').map((item: any) => item.type))
      setAreasToShowWordCloud(analyzeByData?.filter((item: any) => item.type !== 'Reporting Buckets').map((item: any) => item.type))
    }
  }, [selectedTopic, analyzeByData])

  function handleSelectedTopic(selectedTop: any) {
    if (selectedTop) {
      setSelectedTopic({
        type: selectedTop.type === 'Analysis Topics' ? 'Reporting Buckets' : selectedTop.type,
        display_name: selectedTop.name,
        id: selectedTop?.bucket_id
      })
      dispatch(updateSelectedTopicViewVizId(selectedTop?.bucket_id))

    } else {
      setSelectedTopic(null)
      dispatch(updateSelectedTopicViewVizId(null))
    }
  }

  function handleSelectedAreas(seleAreas: any) {
    if (seleAreas[0] === "All") {
      setAreasToShow(analyzeByData?.filter((item: any) => item.type !== 'Reporting Buckets').map((item: any) => item.type))
    }
    setAreasinViewViz(seleAreas)
  }

  function handleAttributeSelection1(config1: any) {
    setConfig1ViewViz(config1)
  }
  function handleAttributeSelection2(config2: any) {
    setConfig2ViewViz(config2)
  }

  const renderRadioButtonsAndBookmarkButton = () => (
    <>
      <div className="flex mr-5 justify-end">
        <div className="flex justify-end gap-3.5 pr-1">
          {["Positive", "Negative", "Mixed", "Neutral"].map(
            (item: any, index: number) => (
              <Tooltip title={item} key={item}>
                <div
                  className={`cursor-pointer w-3.5 h-3.5 rounded-full ${getColor(
                    item
                  )}`}
                ></div>
              </Tooltip>
            )
          )}
        </div>
      </div>
      {
        <Tooltip title={`${(savedViewData?.length > 0 || savedViewData?.length !== undefined) ? 'Show saved views' : 'No saved views'} `} >
          <button
            style={{
              border: `1px solid #9747FF`,
              borderRadius: "5px",
              padding: "2px",
              color: "#9747FF",
              backgroundColor: "#F5F0FF",
              fontWeight: 700,
              fontSize: "14px",
              fontFamily: "Poppins, sans-serif",
              paddingRight: "4px",
              width: "117px",
            }}
            onClick={handleBookmarkToggle}
          >

            {
              (savedViewData?.length > 0 || savedViewData?.length !== undefined) ?
                <BookmarkOutlinedIcon style={{ color: "#9747FF", cursor: "pointer" }} />
                :
                <BookmarkBorderOutlinedIcon style={{ color: "#9747FF", cursor: "pointer" }} />
            }
            Saved Views
          </button>
        </Tooltip>
      }
    </>
  );

  useEffect(() => {
    dispatch(updateSelectedAnalyzeByItem(selectedTopic));
    let data = null
    if (selectedTopic !== null) {
      data = {
        type: selectedTopic.type,
        data: [
          {
            id: [{ id: selectedTopic.id }]
          }
        ]
      }
    }
    dispatch(updateSelectedRBNote(data))
  }, [selectedTopic, dispatch])


  return (
    <div className="h-full w-full grid grid-cols-9 gap-3.5 px-0.5 pt-1">
      <div className="col-span-2 w-full rounded-md">
        <AccordionFunc
          selectedQb={selectedTopic}
          handleAnalyzeBySection={handleAnalyzeBySection}
          setSelectedTopic={setSelectedTopic}
          setAnalyzeByData={setAnalyzeByData}
          CompareWordCloudSavedViewUpdate={updateSelectedQB}
          setAnalyzeByDataIsLoading={setAnalyzeByDataIsLoading}
        />
      </div>

      {/* {selectedTopic ? ( */}
      <div className="col-span-7">
        <div className=" relative bg-white rounded-md w-full shadow-md" style={{ height: pathName.includes('compare') ? window.innerHeight - 175 : '100%' }}>
          <div className="flex justify-between items-center border-b-2 border-gray-300  gap-2 py-2 text-lg font-semibold h-12">
            <div className="mx-3">   {selectedTopic && (selectedTopic.type === "Reporting Buckets"
              ? `Analysis Topics
                 : ${selectedTopic.display_name}`
              : `${selectedTopic.type} : ${selectedTopic.display_name}`)}</div>

            <div className="flex mr-2 items-center">
              {renderRadioButtonsAndBookmarkButton()}
            </div>
          </div>
          <div className="absolute inset-y-0 right-16 mt-12 mr-12 z-3">
            <SavedView isDivOpen={isDivOpen} setIsDivOpen={setIsDivOpen} handleSelectedTopic={handleSelectedTopic}
              setWordCloudViewViz={setWordCloudViewViz}
              handleSelectedAreas={handleSelectedAreas}
              handleAttributeSelection1={handleAttributeSelection1}
              handleAttributeSelection2={handleAttributeSelection2}
              setWorldcloudDeleteID={setWorldcloudDeleteID}
              setSavedViewRenameItem={setSavedViewRenameItem}
              setSelectedSortFilter={setSelectedSortFilter}
              addBookMarkToggle={addBookMarkToggle}
            />
          </div>
          <div className="h-full w-full">
            {pathName.includes('wordcloud') &&
              <WordCloudComponent areasToShow={areasToShowWordCloud} selectedTopic={selectedTopic} wordCloudViewViz={wordCloudViewViz} setWordCloudViewViz={setWordCloudViewViz} analyzeByData={analyzeByData} analyzeByDataIsLoading={analyzeByDataIsLoading} worldcloudDeleteID={worldcloudDeleteID} savedViewRenameItem={savedViewRenameItem} setAddBookMarkToggle={setAddBookMarkToggle} />
            }
            {pathName.includes('compare') &&
              <CompareComp areasToShow={areasToShow} selectedTopic={selectedTopic} updateAreasSelectedForView={areasinViewViz}
                config1ViewViz={config1ViewViz}
                config2ViewViz={config2ViewViz} worldcloudDeleteID={worldcloudDeleteID}
                analyzeByDataIsLoading={analyzeByDataIsLoading}
                setSelectedSortFilter={setSelectedSortFilter}
                selectedSortFilter={selectedSortFilter}
                setAddBookMarkToggle={setAddBookMarkToggle}
              />
            }
          </div>
        </div>
        <div className="">
          {pathName.includes('compare') &&
            <div className="flex justify-end italic text-[#6B7280]">
              Percentage breakdown of respondent conversation within the selected section(s)
            </div>
          }
        </div>
      </div>
      {/* ) : (
        <div className="col-span-7">
          <div className=" relative bg-white rounded-md w-full shadow-md" style={{ height: pathName.includes('compare') ? window.innerHeight - 175 : '100%' }}>
            <div className="flex justify-end items-center border-b-2 border-gray-300  gap-2 py-2 text-lg font-semibold h-12">
              <div className="flex mr-2 items-center">
                {renderRadioButtonsAndBookmarkButton()}
              </div>
            </div>
            <div className="absolute inset-y-0 right-16 mt-12 mr-12 z-3">
              <SavedView savedViewData={savedViewData} isDivOpen={isDivOpen} setIsDivOpen={setIsDivOpen} selectedTopic={selectedTopic} handleSelectedTopic={handleSelectedTopic}
                setWordCloudViewViz={setWordCloudViewViz}
                handleSelectedAreas={handleSelectedAreas} handleAttributeSelection1={handleAttributeSelection1}
                handleAttributeSelection2={handleAttributeSelection2} />
            </div>
            <div className="h-full w-full">
              {pathName.includes('wordcloud') &&
                <WordCloudComponent areasToShow={areasToShowWordCloud} selectedTopic={null} wordCloudViewViz={wordCloudViewViz} setWordCloudViewViz={setWordCloudViewViz} />
              }
              {pathName.includes('compare') &&
                <CompareComp areasToShow={areasToShow} selectedTopic={null} updateAreasSelectedForView={areasinViewViz}
                  config1ViewViz={config1ViewViz}
                  config2ViewViz={config2ViewViz} />
              }
            </div>
          </div>
          <div className="">
            {pathName.includes('compare') &&
              <div className="flex justify-end italic text-[#6B7280]">
                percentage breakdown of respondent conversation within the selected section(s)
              </div>
            }
          </div>
        </div>
      )} */}
    </div>
  );
};


export default LayoutForCompareWordCloud;