import { ChevronsLeft, ChevronsRight, Search, X } from "react-feather";
import { MenuItem, Select, IconButton, Input, Popover, Collapse, Switch, SvgIcon } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from 'react';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import TuneIcon from '@mui/icons-material/Tune';
import Button from '@mui/material/Button';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { getThemeSentences, getThemes, getTranscriptBlocks, getTranscriptDataList, getTranscriptIndexes, hideThemes, mergeThemes } from "../../redux/Themes/action";
import FlashOnIcon from '@mui/icons-material/FlashOn';
import TranscriptView from "../../components/TranscriptsView";
import Spinner from "../../components/Spinner";
import ThemeComponent from "../../components/ThemeComponent/ThemeComponent";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { ReceiptLongOutlined } from "@mui/icons-material";

interface IEditMode {
  project_id: any
  selectedSentiment: string
}

export default function EditMode(props: IEditMode) {
  const dispatch = useAppDispatch()
  const themeLoader = useSelector((state: RootState) => state.themes.themeLoader)
  const allThemes = useSelector((state: RootState) => state.themes.allThemes)
  const sentences = useSelector((state: RootState) => state.themes.allThemesSentence)
  const SelectedQb = useSelector((state: RootState) => state.master.selectedQuestionBuc)
  const sentenceLoader = useSelector((state: RootState) => state.themes.sentenceLoader)
  const allTranscripts = useSelector((state: RootState) => state.themes.allTranscripts)
  const transcriptData = useSelector((state: RootState) => state.themes.transcriptData)

  const [open, setOpen] = useState(true);
  const [summaryHeight, setSummaryHeight] = useState<number>(0)
  const [sentencesHeight, setSentencesHeight] = useState<number>(0)
  const [themesList, setThemesList] = useState<any>(allThemes)
  const [selectedTheme, setSelectedTheme] = useState<any>([])
  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const [newName, setNewName] = useState<string>("")
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [selectedFilter, setSelectedFilter] = useState("emotions");
  const [activeSentence, setActiveSentence] = useState<any>("")
  const [sentenseList, setSentenseList] = useState<any>(sentences)
  const [questionId, setQuestionId] = useState<any>("")
  const [combineToggle, setCombineToggle] = useState<boolean>(false)
  const [combineInputValue, setCombineInputValue] = useState<string>("")
  const summaryRef = useRef<any>(null)
  const sentencesRef = useRef<any>(null)
  const [filterSenti, setFilterSenti] = useState<string>("All")
  const [lightningOnly, setLightningOnly] = useState<boolean>(false)
  const [jumpTo, setJumpTo] = useState<string>("")
  const [checked, setChecked] = useState<boolean>(false)
  const [maximizeView, setMaximizeView] = useState<boolean>(false)
  const [showThemes, setShowThemes] = useState<boolean>(true)
  const [transcriptLoader, setTranscriptLoader] = useState<boolean>(false)
  const [searchSentence, setSearchSentence] = useState<string>("")

  const memoizedRichTextEditor = useMemo(() => {
    return <TranscriptView transcript={transcriptData} selectedSentence={{ id: activeSentence, color: "#E5F2F8", questionId: questionId }} sentenceIds={[]} />
  }, [questionId, transcriptData])

  const handleSearchSentence = (search: string) => {
    if (Object.keys(selectedTheme).length > 0) {
      let payload: any = {
        project_id: props.project_id,
        theme_id: [...selectedTheme.map((e: any) => e?.id.map((item: any) => item?.id)).flat()],
        order_by: selectedFilter,
        transcript_id: jumpTo,
        included_in_ppt_only: lightningOnly,
        sentiment: filterSenti === "All" ? null : filterSenti
      }
      if (SelectedQb !== "All") {
        payload["question_bucket_id"] = [SelectedQb?.question_bucket_id]
      }
      if (search.length > 0) {
        payload["search"] = search
      }
      dispatch(getThemeSentences(payload));
    }
  }

  useEffect(() => {
    dispatch(getTranscriptDataList({ project_id: props.project_id }))
    dispatch(getTranscriptIndexes({ projectId: props.project_id }))
  }, [])

  useEffect(() => {
    if (allTranscripts.length > 0) {
      setJumpTo(allTranscripts[0]?.dataset_id)
    }
  }, [allTranscripts])

  useEffect(() => {
    setSentenseList(sentences)
  }, [sentences])

  useEffect(() => {
    setActiveSentence("")
  }, [selectedTheme])

  const toggleComponent = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const summaryTopPos = summaryRef.current?.getBoundingClientRect().top;
    setSummaryHeight(window.innerHeight - summaryTopPos - 75);
    const sentencesTopPos = sentencesRef.current?.getBoundingClientRect().top
    setSentencesHeight(window.innerHeight - sentencesTopPos - 75)
  })

  const handleThemeSelection = (item: any, type: any) => {
    switch (type) {
      case "Single": {
        let data: any = [...selectedTheme];
        const checkId = data.some((each: any) => each.name === item.name);
        if (checkId) {
          data = data.filter((each: any) => each.name !== item.name);
        } else {
          data.push(item);
        }
        setSelectedTheme(data);
      }
        break;
      case "All": {
        const filter = themesList.filter((each: any) => each.is_active)
        setSelectedTheme(filter);
      }
        break;
      case "None":
        setSelectedTheme([]);

        break;
    }
    if (selectedTheme?.length < 2) {
      setCombineToggle(false)
    }
  }

  const handleHideTheme = (themeIds: Array<any>, toHide?: boolean) => {
    let payload = {
      project_id: props.project_id,
      class_type: "Themes",
      class_ids: themeIds,
      hide: toHide
    }
    dispatch(hideThemes({ projectId: props.project_id, payload: payload, themeIds: themeIds }))
  }

  useEffect(() => {
    if (Object.keys(selectedTheme).length > 0) {
      let payload: any = {
        project_id: props.project_id,
        theme_id: [...selectedTheme.map((e: any) => e?.id.map((item: any) => item?.id)).flat()],
        order_by: selectedFilter,
        transcript_id: jumpTo,
        included_in_ppt_only: lightningOnly,
        sentiment: filterSenti === "All" ? null : filterSenti
      }
      if (SelectedQb !== "All") {
        payload["question_bucket_id"] = [SelectedQb?.question_bucket_id]
      }
      dispatch(getThemeSentences(payload));
    }
  }, [selectedTheme, selectedFilter, jumpTo, lightningOnly, filterSenti])

  useEffect(() => {
    setThemesList(allThemes);
    setSelectedTheme([])
  }, [allThemes])

  const combineThemes = () => {
    let themeIds: any = [...selectedTheme.map((e: any) => e?.id.map((item: any) => item?.id)).flat()]
    let payload: any = {
      include_ids: themeIds,
      new_name: newName,
      class_type: "Themes"
    }
    dispatch(mergeThemes({ projectId: props.project_id, payload: payload, sentiment: props.selectedSentiment, selectedQb: SelectedQb }))
    setPopupOpen(false)
    setNewName("")
    setCombineToggle(false)
    setCombineInputValue("")
  }

  const editTheme = (payload: any) => {
    dispatch(mergeThemes({ projectId: props.project_id, payload: payload, sentiment: props.selectedSentiment, selectedQb: SelectedQb }))
  }


  useEffect(() => {
    setSelectedTheme([]);
    setFilterSenti("All")
    if (SelectedQb !== "All") {
      dispatch(getThemes({ project_id: props.project_id, sentiment: props.selectedSentiment, question_bucket_id: SelectedQb.question_bucket_id }))
    }
    else {
      dispatch(getThemes({ project_id: props.project_id, sentiment: props.selectedSentiment }))
    }
  }, [props.selectedSentiment, SelectedQb])

  const handleClose = () => {
    setAnchorEl(null)
    setPopupOpen(false)
  }

  const handleIsActive = (item: any) => {
    let offset = (item.question_index - 4) < 0 ? 0 : (item.question_index - 4)
    let makeCall = false
    for (let i = offset; i < offset + 8; i++) {
      if (Object.keys(transcriptData[i].data).length === 0) {
        makeCall = true
        break
      }
    }

    if (makeCall) {
      dispatch(getTranscriptBlocks({ projectId: props.project_id, offset: offset, limit: 8 }))
    }
    setActiveSentence(item.sentence_id)
    setQuestionId(item.question_id)
  }

  const getLayout = () => {
    let availabel = 8
    if (showThemes) {
      availabel = availabel - 2
    }
    if (!maximizeView) {
      availabel = availabel / 2
    }
    return availabel
  }

  useEffect(() => {
    if (maximizeView) {
      setShowThemes(false)
    }
    else {
      setShowThemes(true)
    }
  }, [maximizeView])

  useEffect(() => {
    if (!showThemes) {
      setChecked(true)
    }
    else {
      setChecked(false)
    }
  }, [showThemes])

  return (
    <div className="grid w-full grid-cols-8 gap-4 h-full relative">
      {Object.keys(selectedTheme).length > 0 &&
        <div className="flex justify-between items-center w-fit cursor-pointer absolute" style={{ left: "91%", top: "-6%" }} onClick={() => setMaximizeView(!maximizeView)}>
          {maximizeView ?
            <ChevronsRight color="gray" size={18} />
            :
            <ChevronsLeft color="gray" size={18} />
          }
          <span className="font-semibold text-gray-500 whitespace-nowrap">{maximizeView ? "Minimize View" : "Maximize View"}</span>
        </div>
      }
      {showThemes &&
        <div className="text-start col-span-2">
          <ThemeComponent
            type="edit"
            dataList={allThemes}
            selectedTheme={selectedTheme}
            clickOnTheme={handleThemeSelection}
            showLoader={themeLoader}
            handelHide={handleHideTheme}
            editTheme={editTheme}
            setShowTheme={setShowThemes}
          />
        </div>
      }
      {(selectedTheme?.length <= 1) ?
        <>
          {!maximizeView ? (
            <div className={`Summary mt-0.5 col-span-${getLayout()}`}>
              <div className="flex justify-between gap-1 items-center text-lg font-semibold">
                <div className="flex justify-start items-center gap-2">
                  <span>Sentences ({selectedTheme?.length > 0 ? sentences?.length : 0})</span>
                  {!showThemes &&
                    <div className="cursor-pointer flex justify-start items-center gap-1 text-sm text-gray-500 whitespace-nowrap"
                      onClick={() => setShowThemes(true)}
                    >
                      <ChevronsRight color="gray" size={18} />
                      <span>Expand Theme: <span className="uppercase text-blue-400">{selectedTheme[0]?.name}</span></span>
                    </div>}
                </div>
                {/* <Info size={15}/> */}
                <div className="flex justify-end items-center gap-0.5">
                  <div className="flex justify-center items-center gap-2 focus:bg-transparent mt-1 ">
                    <span className="font-semibold text-gray-500 text-base">Go To :</span>
                    <Select
                      variant="standard"
                      className="truncate"
                      value={jumpTo}
                      sx={{ width: "100px", fontWeight: "700", fontFamily: '"Poppins", sans-serif' }}
                      onChange={(e: any) => setJumpTo(e.target.value)}
                      disableUnderline
                      MenuProps={{
                        sx: {
                          width: "300px",
                        },
                      }}
                    >
                      {
                        allTranscripts?.map((item: any, i: number) => {
                          return (
                            <MenuItem sx={{ whiteSpace: "pre-line" }} key={i} value={item.dataset_id}>{item?.dataset_name}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </div>
                  <div className="flex justify-around items-center">
                    <SyncAltIcon className="rotate-90 " />
                    <div className="flex justify-center items-center gap-3 focus:bg-transparent mt-1 ">
                      <Select
                        variant="standard"
                        value={selectedFilter}
                        sx={{ fontWeight: "700", fontFamily: '"Poppins", sans-serif' }}
                        onChange={(e: any) => setSelectedFilter(e.target.value)}
                        disableUnderline
                      >
                        <MenuItem value="emotions">Emotions</MenuItem>
                        <MenuItem value="transcript_order">Transcript Order</MenuItem>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-lg flex flex-col justify-start shadow-md" ref={summaryRef} style={{ height: summaryHeight }}>
                <div>
                  <div className="flex justify-between items-center gap-2 px-3 py-1 border-b border-b-gray-300">
                    {(checked && showThemes) ?
                      <div className="cursor-pointer" onClick={() => setChecked(!checked)}><Search size={16} /></div>
                      :
                      <div className="flex justify-start items-center gap-2 w-3/5">
                        <Search size={18} />
                        <input
                          className="focus:outline-none w-full"
                          placeholder="Search Sentence"
                          value={searchSentence}
                          onChange={(e) => setSearchSentence(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSearchSentence(searchSentence)
                            }
                          }}
                        />
                        {searchSentence.length > 0 &&
                          <div className="cursor-pointer" onClick={() => {
                            setSearchSentence("")
                            handleSearchSentence("")
                          }}
                          ><X size={16} /></div>
                        }
                      </div>
                    }
                    <div className="flex justify-end items-center">
                      <Collapse className="whitespace-nowrap" orientation="horizontal" in={checked}>
                        <div className='col-span-3 flex justify-end items-center gap-3 mr-2 text-sm'>
                          <div>
                            <Switch
                              size="small"
                              checked={lightningOnly}
                              onChange={() => setLightningOnly(!lightningOnly)}
                            />
                            <FlashOnIcon className={`${lightningOnly ? "text-blue-500" : "text-gray-400"}`} />
                          </div>
                          <div className="flex justify-start items-center text-sm">
                            <div className={`cursor-pointer   border rounded-l text-gray-600 ${filterSenti === "All" ? "bg-[#DFEDF5] border-[#0077B6] font-semibold" : " border-gray-400 "}`} onClick={() => setFilterSenti("All")}>
                              <span className="px-3">All</span>
                            </div>
                            <div className={`cursor-pointer  border-t border-b text-gray-600 ${filterSenti === "Positive" ? " bg-green-100 border-[#52bb20] font-semibold border" : "border-gray-400 "}`} onClick={() => setFilterSenti("Positive")}>
                              <span className="px-3">Positive</span>
                            </div>
                            <div className={`cursor-pointer border rounded-r text-gray-600 ${filterSenti === "Negative" ? " bg-red-100 border-[#f03a5b] font-semibold" : "border-gray-400 "}`} onClick={() => setFilterSenti("Negative")}>
                              <span className="px-3">Negative</span>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                      <IconButton onClick={() => setChecked(!checked)}>{<TuneIcon className={`${checked ? "text-blue-500" : ""}`} />}</IconButton>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-start gap-3 overflow-y-auto p-4">
                  {sentenceLoader ? (
                    <div className="flex justify-center items-center w-full">
                      <Spinner />
                    </div>
                  ) :
                    //   (sentences?.length > 0 && selectedTheme?.length > 0) ? sentenseList?.map((item: any, index: number) => (
                    //     <EditItem key={item.sentence_id} isActive={item.sentence_id === activeSentence} handleIsActive={handleIsActive} item={item} selectedTheme={selectedTheme} />
                    //   )) : <div className="h-full w-full flex items-center justify-center text-lg font-semibold text-gray-500">
                    //     {selectedTheme?.length === 0 ? <span>Select a Theme to view sentence(s)</span> :
                    //       <span>No data available for selected combination</span>}
                    //   </div>
                    <></>
                  }
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className={`sentences flex flex-col justify-start pt-1 ${maximizeView ? "col-start-1 col-end-9" : `col-span-${getLayout()}`}`}>
            <div className="flex justify-start gap-3 items-center">
              {!open &&
                <div className="flex justify-start items-center w-fit cursor-pointer text-lg" onClick={toggleComponent}>
                  <ChevronsRight color="gray" />
                  <span className="font-semibold text-gray-500 text-xs">Expand to see the Sentences</span>
                </div>
              }
              <div className="flex justify-start items-center text-lg font-semibold px-2 mb-1 pt-0.5">
                {/* <Search size={18} fill="#6495ED	" /> */}
                <ReceiptLongOutlined />
                <span>Transcript Preview</span>
              </div>
            </div>
            <div className="sentences-component bg-white rounded-lg p-4 overflow-y-auto shadow-md" ref={sentencesRef} style={{ height: sentencesHeight }}>
              {transcriptLoader ?
                <div className="h-5/6 w-full flex items-center justify-center"><Spinner height={20} /></div>
                :
                memoizedRichTextEditor
              }
            </div>
          </div>
        </> :
        <div className="bg-white shadow-lg col-span-6 flex flex-col justify-start items-start text-gray-600 text-lg font-base p-10 mt-10 rounded overflow-y-auto overflow-x-hidden" ref={summaryRef} style={{ height: summaryHeight }}>
          <p>{selectedTheme?.length} themes Selected</p>

          {selectedTheme?.map((each: any) => (
            <li className="list-disc list-inside ml-8">{each.name}</li>
          ))}

          <div className="mt-10 text-start ml-72 w-3/4">
            <div className="flex items-center mb-2">

              <SvgIcon viewBox="0 0 24 24" sx={{ width: "24", height: "24", fill: 'none' }}>
                <path d="M10 13.0039C10.4295 13.578 10.9774 14.0531 11.6066 14.3968C12.2357 14.7406 12.9315 14.945 13.6467 14.9962C14.3618 15.0474 15.0796 14.9442 15.7513 14.6937C16.4231 14.4431 17.0331 14.0509 17.54 13.5439L20.54 10.5439C21.4508 9.60086 21.9548 8.33785 21.9434 7.02687C21.932 5.71588 21.4061 4.46182 20.4791 3.53478C19.5521 2.60774 18.298 2.08189 16.987 2.0705C15.676 2.05911 14.413 2.56308 13.47 3.47387L11.75 5.18387" stroke="#6b7280" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.0002 10.9973C13.5707 10.4232 13.0228 9.94811 12.3936 9.60436C11.7645 9.2606 11.0687 9.05618 10.3535 9.00496C9.63841 8.95374 8.92061 9.05693 8.24885 9.30751C7.5771 9.5581 6.96709 9.95023 6.4602 10.4573L3.4602 13.4573C2.54941 14.4003 2.04544 15.6633 2.05683 16.9743C2.06822 18.2853 2.59407 19.5393 3.52111 20.4664C4.44815 21.3934 5.70221 21.9193 7.01319 21.9307C8.32418 21.9421 9.58719 21.4381 10.5302 20.5273L12.2402 18.8173" stroke="#6b7280" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />

              </SvgIcon>
              {combineToggle ?
                <>
                  <input type="text" value={newName} onChange={(e: any) => setNewName(e.target.value)} placeholder="Enter new combine name" className="mr-2 ml-2 w-96 p-2 border" />
                  <CancelOutlinedIcon
                    onClick={() => {
                      setCombineToggle(false);
                      setCombineInputValue("");
                    }}
                    className="hover:text-blue-500 cursor-pointer "
                    style={{ height: "30px", width: "30px" }}
                  />
                  <CheckCircleOutlineIcon
                    onClick={combineThemes}
                    className="hover:text-blue-500 mr-2 cursor-pointer text-xl"
                    style={{ height: "30px", width: "30px" }}
                  />
                </>
                : <span onClick={() => setCombineToggle(true)} className="ml-3 cursor-pointer">Combine</span>}
            </div>
            <div >
              <VisibilityOffIcon /><span className="ml-3 cursor-pointer" onClick={() => handleHideTheme((selectedTheme?.map((each: any) => {
                return ((each.id.map((item: any) => item.id)))
              })).flat())}>Hide</span>
            </div>
          </div>
        </div>}
      <Popover
        open={popupOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        PaperProps={{
          style: {
            width: "300px",
            padding: "20px",
            maxHeight: "500px",
            borderRadius: "6px"
          }
        }}
      >
        <div className='flex flex-col justify-start  gap-3'>
          <div className='text-sm'>{`(${selectedTheme?.length}) Theme Selected`}</div>
          <div className='overflow-y-auto flex flex-col justify-start gap-2 pr-2' style={{ maxHeight: "250px" }}>
            {selectedTheme.map((item: any, i: number) => {
              return (
                <div className='w-full flex justify-between items-center bg-gray-100 rounded-md' key={i}>
                  <span className='px-4'>{item.name}</span>
                  <IconButton className="float-right" onClick={() => handleThemeSelection(item, "Single")}><CancelRoundedIcon /></IconButton>
                </div>
              )
            })}
          </div>
          <div className='border-2 px-2 rounded-md'>
            <Input className="w-11/12"
              placeholder='Type here...'
              value={newName}
              onChange={(e: any) => setNewName(e.target.value)}
              disableUnderline />
          </div>
          <div className='flex justify-end items-center gap-3'>
            <Button variant='text' onClick={() => { setPopupOpen(false); setNewName("") }}>Cancel</Button>
            <Button variant='contained' onClick={combineThemes} disabled={newName.length === 0 || selectedTheme.length === 0}
            >Combine</Button>
          </div>
        </div>
      </Popover>

    </div>
  );
}
