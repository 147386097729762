import { PuffLoader } from "react-spinners";

const Spinner = (prop: any) => {
    const text = prop.text; // Fetching Data. Please wait...
    const opacityperc = 100;
    return (
        <div className={`flex items-center justify-center ease-in-out opacity-${opacityperc}`} style={{ height: `${prop.height ?? 46.3}vh` }}>
            <div className="flex items-center justify-center flex-col p-12">
                <PuffLoader color="#0062cc" size={50} loading={true} speedMultiplier={2} />
                <div className="logging mt-4">
                    <span className="text-lg text-gray-800 font-semibold">{text}</span>
                </div>
            </div>
        </div>

    )
}

export default Spinner