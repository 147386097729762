import { axiosconfig } from "../../service/apiconfig";

export const EditProjectAPI = {
        moveProject: ({ project_id,destination_account_id,destination_project_name }: any) => {
            return axiosconfig.post(`project/${project_id}/move_project?destination_account_id=${destination_account_id}&destination_project_name=${destination_project_name}`,{}
            )
        },

        accountProjectList: ({ project_id }: any) => {
            return axiosconfig.get(`project/account_projects_list/${project_id}`)
        },

        mergeProject: ({ destination_account_id,destination_project_name,merge_project_ids }: any) => {
            return axiosconfig.post(`project/merge-project`,{
            destination_account_id:destination_account_id,
            destination_project_name:destination_project_name,
            merge_project_ids:merge_project_ids
            })
        },

        getEntitySentencesData:({project_id}:any)=>{
            return axiosconfig.get(`project/${project_id}/get_entity_sentences`)
        },
        getEntitySentencesSeeContextData:({project_id, question_id}:any)=>{
            return axiosconfig.post(`/projects/${project_id}/sentence/${question_id}/context?limit=30&offset=0`, {})
        },

        getThemeMasterData:({project_id}:any)=>{
            return axiosconfig.get(`project/${project_id}/get_theme_master`)
        },
        getThemeSentencesData:({project_id}:any)=>{
            return axiosconfig.get(`project/${project_id}/get_theme_sentence`)
        },
        getSummaries: ({ project_id,type }: any) => {
            return axiosconfig.get(`project/${project_id}/summaries?type=${type}`)
        },
        updateThemeMasterData:({project_id,qc_step,payload}:any)=>{
            return axiosconfig.post(`project/${project_id}/update_theme_master?qc_step=${qc_step}`,payload)
        },
        updateEntitySentenceData:({project_id,payload}:any)=>{
            return axiosconfig.post(`project/${project_id}/update_entity_sentences`,payload)
        },
        getEntityTypeData : ({project_id} : any) => {
            return axiosconfig.get(`project/${project_id}/entity_type_data`)
        },
        updateEntityTypeData : ({project_id, payload} : any) => {
            return axiosconfig.post(`project/${project_id}/update_entity_type`, payload)
        },
        getEntityMaster: ({ project_id }: any) => {
            return axiosconfig.get(`project/${project_id}/entities_data`)
        },
        updateEntityMaster : ({project_id, payload}:any) => {
            return axiosconfig.post(`project/${project_id}/update_entity_master`, payload)
        },
        getAllAccounts:()=>{
            return axiosconfig.get(`/project/get_acounts`)
        },
        // updateProjectScope:({project_id,  isAutosave, attribute_data, reporting_buckets, themes, entities}: any) =>{
        //     return axiosconfig.post(`project/${project_id}/update_project_scope`,{
        //         project_id,  isAutosave, attribute_data, reporting_buckets, themes, entities
        //     }) 
        // },
        updateProjectScope: (projectId: any, field:any, payload: any) => {
            return axiosconfig.post(`/project/${projectId}/update_project_scope?field=${field}`, payload)
          },
        updateSummaries: ({ project_id,type,changes }: any) => {
            return axiosconfig.post(`project/${project_id}/update_summaries?type=${type}`,changes)
        },
        updateThemeSentenceData:({project_id,qc_step, payload}:any)=>{
            return axiosconfig.post(`project/${project_id}/update_theme_sentence?qc_step=${qc_step}`,payload)
        },
        getCEEnabledTabs: ({ project_id }: any) => {
            return axiosconfig.get(`project/${project_id}/ce_enabled_tabs`)   
        },
        triggerCEReprocessing: ({project_id}: any) => {
            return axiosconfig.get(`project/${project_id}/trigger_ce_reprocessing`)
        },
        getSummaryReport:({project_id, output_type}:any)=>{
            return axiosconfig.get(`/project/${project_id}/summary_report?output_type=${output_type}`)
        },
        validateCeSubmit: (project_id: any) => {
          return axiosconfig.get(`/project/${project_id}/validate_ce_submit`)  
        },
        updateReportSummary:({project_id, payload}:any)=>{            
            return axiosconfig.post(`/project/${project_id}/update_summary_report`,payload)
        }
}



