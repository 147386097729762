import { decryption, secrateKey } from "../modules/LoginLayout/LoginLayout";

const user: any = '_U';
const expiry: any = 'expiry';

export const getAccessToken = () => {
    const item = getUserDetails();

    return item?.accessToken ?? null;
}

export const getRefreshToken = () => {
    const item = getUserDetails();
    return item?.refreshToken ?? null;
}

export const removeTokens = () => {
    localStorage.removeItem(user);
}

export const getUserDetails = () => {
    let encrypted = localStorage.getItem(user);
    if (encrypted) {


        let decrypted = decryption(encrypted, secrateKey)
        if (decrypted)
            return JSON.parse(decrypted!);
        else return null
    }

    return null;
}

export const getExpiry = () => {
    if (localStorage.getItem(expiry) !== null && localStorage.getItem(expiry) !== "" && localStorage.getItem(expiry) !== undefined) {
        return JSON.parse(localStorage.getItem(expiry)!);
    }
    return null;
}
