import { createAsyncThunk } from "@reduxjs/toolkit";
import { domAcceptAPI } from "../../service/api/domAcceptAPI";
import { openSnackbar } from "../Notification/notificationReducer";

export const getPendingDomains = createAsyncThunk("getPendingDomains", async ({ some }: any, { rejectWithValue }) => {
    try {
        const response = await domAcceptAPI.getPendingDomains()
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const postDomainOutcomes = createAsyncThunk("postDomainOutcomes", async ({ payload }: any, { rejectWithValue, dispatch }) => {
    try {
        const response = await domAcceptAPI.postDomainOutcomes({ payload })
        if (response.data) {
            dispatch(getPendingDomains({ some: "" }))
        }
        else {
            dispatch(openSnackbar({ message: "Submission was unsuccessful", type: "error" }))
        }
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getDomains = createAsyncThunk("getDomains", async ({ is_approved }: any, { rejectWithValue }) => {
    try {
        const response = await domAcceptAPI.getDomains({ is_approved })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})