import { useEffect, useRef, useState } from "react"
import { ChevronsLeft, Search } from "react-feather";
import Spinner from "../Spinner";
import SingleSelectComp from "../SingleSelectComp/SingleSelectComp";
import MultiSelectComp from "../MultiSelectComp/MultiSelectComp";

interface IThemeComponent {
    type: string
    dataList: Array<any>
    selectedTheme: any
    clickOnTheme: any
    showLoader: boolean
    handelHide?: any
    editTheme?: any
    setShowTheme?: any
}

export default function ThemeComponent(props: IThemeComponent) {

    const mainRef = useRef<any>(null)
    const [mainHeight, setMainHeight] = useState<number>(0)
    const [searchValue, setSearchValue] = useState<string>("")
    const [filteredDataList, setFilteredDataList] = useState<Array<any>>(props.dataList)

    const searchTheme = (typedVal: string) => {
        let newData = props.dataList.filter((item: any) => item?.name?.toLowerCase().includes(typedVal.toLocaleLowerCase()))
        setFilteredDataList(newData)
        setSearchValue(typedVal)
    }

    useEffect(() => {
        setFilteredDataList(props.dataList)
        setSearchValue((""))
    }, [props.dataList])

    useEffect(() => {
        const summaryTopPos = mainRef.current?.getBoundingClientRect().top;
        setMainHeight(window.innerHeight - summaryTopPos - 75);
    });

    return (
        <div className="w-full h-full">
            <div className="flex justify-between items-center text-lg font-semibold p-1">
                <span>Themes <span className="">({props.dataList?.length})</span></span>
                {Object.keys(props.selectedTheme).length > 0 &&
                    <div className="flex justify-between items-center w-fit cursor-pointer" onClick={() => props.setShowTheme(false)}>
                        <span className="font-semibold text-gray-500 text-sm">Collapse Themes</span>
                        <ChevronsLeft color="gray" size={18} />
                    </div>
                }
            </div>
            <div className="main w-full bg-white rounded-lg flex flex-col justify-start gap-2 shadow-md" ref={mainRef} style={{ height: mainHeight }}>
                <div className="flex justify-start items-center gap-2 p-3 border-b border-b-gray-300" style={{ height: "9.5%" }}>
                    <Search size={18} />
                    <input
                        className="focus:outline-none w-full"
                        type='search'
                        placeholder="Search Theme"
                        value={searchValue}
                        onChange={(e) => searchTheme(e.target.value)} />
                </div>
                {props.type === "edit" &&
                    <div className='flex justify-start items-center gap-5 p-2 pl-[19px]' style={{ height: "7%" }}>
                        <div>
                            <span className='font-semibold' style={{ fontSize: "13px" }}>({props.selectedTheme.length}) Selected</span>
                        </div>
                        <div>
                            <span className='font-semibold text-gray-400' style={{ fontSize: "13px" }}>Select&nbsp;&nbsp;</span>
                            <span className='font-semibold hover:underline cursor-pointer' style={{ fontSize: "13px" }}
                                onClick={() => props.clickOnTheme({}, "All")}>All</span>
                            <span style={{ fontSize: "13px" }}> / </span>
                            <span className='font-semibold hover:underline cursor-pointer' style={{ fontSize: "13px" }}
                                onClick={() => props.clickOnTheme({}, "None")}>None</span>
                        </div>
                    </div>
                }
                <div className="overflow-y-auto px-2 pt-0.5" style={{ height: props.type === "view" ? "86%" : "78%" }}>
                    {!props.showLoader ?
                        (filteredDataList.length > 0 ?
                            (props.type === "view" ?
                                <SingleSelectComp
                                    dataList={filteredDataList}
                                    selectedItem={props.selectedTheme}
                                    handleSelect={props.clickOnTheme}
                                />
                                :
                                <MultiSelectComp
                                    dataList={filteredDataList}
                                    selectedItem={props.selectedTheme}
                                    handleSelect={props.clickOnTheme}
                                    handleHide={props.handelHide}
                                    editTheme={props.editTheme}
                                />
                            )
                            :
                            <div className="h-full flex justify-center items-center text-lg font-semibold text-gray-500"><span> No data Available</span></div>
                        )
                        :
                        <div className="h-full w-full flex justify-center items-center">
                            <Spinner height={20} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}