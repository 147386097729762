import { ThemeProvider } from "@emotion/react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  createTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import CompareData from "../../components/CompareData/CompareData";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import { deleteSavedView, getSavedView, getWordCloudData, addSavedView } from "../../redux/CompareWordCloud/action";
import { useLocation } from "react-router-dom";
import { updateCurrentPage } from "../../redux/currentPage";
import { updateDeleteSavedStatus, updateSelectedCompareAttr1, updateSelectedCompareAttr2 } from "../../redux/CompareWordCloud/dataReducer";
import Spinner from "../Spinner";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#9747FF",
    },
  },
});

export default function CompareComp({ selectedTopic, areasToShow, updateAreasSelectedForView, config1ViewViz, config2ViewViz, worldcloudDeleteID, analyzeByDataIsLoading, setAddBookMarkToggle, setSelectedSortFilter, selectedSortFilter }: any) {
  const dispatch = useAppDispatch();

  const pathname = useLocation().pathname;
  const projectId = pathname.split("/")[2];

  const masterAttr = useSelector((state: RootState) => state.master.masterAttributes);
  const deletedId = useSelector((state: RootState) => state.compare.deletedId);
  const deleteSavedViewStatus = useSelector((state: RootState) => state.compare.deleteSavedViewStatus);
  const selectedTopicViewVizId = useSelector((state: RootState) => state.compare.selectedTopicViewVizId);
  const wordCloudViewStateLoader = useSelector((state: RootState) => state.compare.wordCloudViewStateLoader);
  const attributeSelection = useSelector((state: RootState) => state.compare.selectedCompareAttr1);
  const attributeSelection2 = useSelector((state: RootState) => state.compare.selectedCompareAttr2);

  const heightRef = useRef<any>(null);
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(1);
  const [areasSelection, setAreasSelection] = useState<any>([]);
  const [bookMark, setBookMark] = useState<any>(false);
  const [grayOut, setGrayOut] = useState<boolean>(false);
  const [analyzeByData, setAnalyzeByData] = useState<any>([])
  const [compareResponseData, setCompareResponseData] = useState<any>(null)
  const [savePayload, setSavePayload] = useState<any>()
  const [savedViewDisplay, setSavedViewDisplay] = useState(false)
  const [bookMarkID, setBookMarkID] = useState<any>(null);
  const [loader, setLoader] = useState(true)
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});
  const [expandedGroups1, setExpandedGroups1] = useState<Record<string, boolean>>({});
  const sortConstantDropDownValues: { [key: string]: string } = {
    "default": "Descending Order (Difference)",
    "left": "Descending Order (Profile 1)",
    "right": "Descending Order (Profile 2)"
  }
  const [sortFilterDropDownValues] = useState<{ [key: string]: string }>({ ...sortConstantDropDownValues })

  const toggleGroup = (groupId: any) => {
    setExpandedGroups(prevState => ({
      ...prevState,
      [groupId]: !prevState[groupId]
    }));
  };

  const toggleGroup1 = (groupId: any) => {
    setExpandedGroups1(prevState => ({
      ...prevState,
      [groupId]: !prevState[groupId]
    }));
  };

  const AttributesData = Object.keys(masterAttr).map((key: any) => {
    return {
      id: key,
      name: masterAttr[key].name,
      value: masterAttr[key].value,
    };
  });

  useEffect(() => {
    dispatch(getSavedView({ project_id: projectId, is_compare_view: true }));
  }, [])

  useEffect(() => {
    const attributeValue1 = AttributesData.find((item: any) => item.id === "ATTRIBUTE_1")?.value?.length
    const attributeValue2 = AttributesData.find((item: any) => item.id === "ATTRIBUTE_2")?.value?.length
    let tempAttributesData2: any
    if (attributeValue1 > 1 && attributeValue2 > 1) {
      // tempAttributesData2 = AttributesData.map((item: any) => {
      //   return { ...item, value: [item.value[0]] }
      // })
      tempAttributesData2 = AttributesData.filter((item: any) => item.id === "ATTRIBUTE_1").map((item: any) => {
        return { ...item, value: [item.value[0]] }
      })

    } else if (attributeValue1 > 1 && attributeValue2 <= 1) {
      // tempAttributesData2 = AttributesData.map((item: any) => {
      //   return { ...item, value: [item.value[0]] ? [item.value[0]] : [] }
      // })
      tempAttributesData2 = AttributesData.filter((item: any) => item.id === "ATTRIBUTE_1").map((item: any) => {
        return { ...item, value: [item.value[0]] ? [item.value[0]] : [] }

      })
    }
    else if (attributeValue1 <= 1 && attributeValue2 > 1) {
      // tempAttributesData2 = AttributesData.map((item: any) => {
      //   return { ...item, value: [item.value[0]] ? [item.value[0]] : [] }
      // })
      tempAttributesData2 = AttributesData.filter((item: any) => item.id === "ATTRIBUTE_1").map((item: any) => {
        return { ...item, value: [item.value[0]] ? [item.value[0]] : [] }

      })
    }
    else if (attributeValue1 === 1 && attributeValue2 == 1) {
      // tempAttributesData2 = AttributesData.map((item: any) => {
      //   return { ...item, value: [item.value[0]] ? [item.value[0]] : [] }
      // })
      tempAttributesData2 = AttributesData.filter((item: any) => item.id === "ATTRIBUTE_1").map((item: any) => {
        return { ...item, value: [item.value[0]] ? [item.value[0]] : [] }

      })
    }
    else if(attributeValue1>1 && (attributeValue2===undefined||attributeValue2===0)){
          tempAttributesData2=AttributesData.filter((item:any)=>item.id === "ATTRIBUTE_1").map((item: any) => {
            return { ...item, value: [item.value[0]] ? [item.value[0]] : [] }
          })
          
        } 
        else{
          tempAttributesData2 = AttributesData
        }

    if (!attributeSelection.length) {
      dispatch(updateSelectedCompareAttr1([...AttributesData]))
    }
    if (!attributeSelection2.length) {
      dispatch(updateSelectedCompareAttr2([...tempAttributesData2]))
    }



  }, [masterAttr])


  useEffect(() => {
    dispatch(updateCurrentPage("Compare"));
  }, [pathname])

  useEffect(() => {
    if (worldcloudDeleteID === bookMarkID) {
      setBookMark(false);
    }
  }, [worldcloudDeleteID])


  useEffect(() => {
    const TopPos = heightRef.current?.getBoundingClientRect().top;
    const LeftPos = heightRef.current?.getBoundingClientRect().left;
    setHeight(window.innerHeight - TopPos - 50);
    setWidth(window.innerWidth - LeftPos - 15);
  });



  useEffect(() => {
    if (updateAreasSelectedForView.length > 0) {
      setAreasSelection(updateAreasSelectedForView)
    }
  }, [updateAreasSelectedForView])




  // initial selection all
  useEffect(() => {
    if (!savedViewDisplay) {
      setAreasSelection(analyzeByData)
    }

    if (updateAreasSelectedForView.length > 0 && updateAreasSelectedForView[0] == "All") {
      setAreasSelection(analyzeByData)
    }
  }, [analyzeByData]);



  // Sets analyzeByData to areasToShow along with selected filters in the saved view section for comparison if savedViewDisplay is true
  useEffect(() => {
    setAnalyzeByData(areasToShow)
    if (savedViewDisplay) {
      if ((selectedTopic === null && selectedTopicViewVizId === null) || (selectedTopic?.id === selectedTopicViewVizId)) {
        setSavedViewDisplay(true)
      } else {
        setSavedViewDisplay(false)
      }
    }
  }, [areasToShow])


  useEffect(() => {
    if (!bookMark && !deleteSavedViewStatus) {
      setSavedViewDisplay(false)
    }
  }, [bookMark])

  useEffect(() => {
    if (deleteSavedViewStatus) {
      if (bookMarkID === deletedId) {
        setBookMark(false)
      }
      dispatch(updateDeleteSavedStatus(false))
    }
  }, [deleteSavedViewStatus])



  useEffect(() => {
    if (!analyzeByDataIsLoading) {
      setLoader(true)
      let areas: any = ["All"]
      let config_1: any = []
      let config_2: any = []

      attributeSelection.map((item: any) => {
        if (item?.value?.length > 0) {
          config_1.push({
            name: item.id,
            value: [...item.value]?.sort((a, b) => a - b)
          })
        }
      })

      attributeSelection2.map((item: any) => {
        if (item?.value?.length > 0) {
          config_2.push({
            name: item.id,
            value: [...item.value]?.sort((a, b) => a - b)
          })
        }
      })

      if (areasSelection?.length !== analyzeByData?.length) {
        areas = areasSelection
      }


      let payload = {}
      if (selectedTopic) {
        payload = {
          analyze_by: {
            type: selectedTopic.type === 'Reporting Buckets' ? 'Analysis Topics' : selectedTopic.type,
            bucket_id: selectedTopic.id,
            name: selectedTopic.display_name

          },
          areas: areasSelection?.length > 0 ? Array?.from(areas)?.sort((a: any, b: any) => a - b) : [],
          config_1: config_1,
          config_2: config_2,
          is_compare_view: true,
          sort_by: selectedSortFilter
        }
      } else {
        payload = {
          areas: areasSelection?.length > 0 ? Array?.from(areas)?.sort((a: any, b: any) => a - b) : [],
          config_1: config_1,
          config_2: config_2,
          is_compare_view: true,
          sort_by: selectedSortFilter
        }
      }

      setSavePayload(payload)
      const noApiCall = (areasSelection?.length === 0 || areasSelection?.length === undefined) && config_1?.length === 0 && config_2?.length === 0

      if (!noApiCall) {
        dispatch(getWordCloudData({
          project_id: projectId,
          payload: payload
        }))
          .then((res: any) => {

            setLoader(false)
            setCompareResponseData(res.payload.response?.data)
            setBookMark(res?.payload?.response?.data?.is_saved_view)
            setBookMarkID(res?.payload?.response?.data?.ID)
          })
      }
    }
  }, [areasSelection, attributeSelection, attributeSelection2, analyzeByDataIsLoading, selectedSortFilter]);


  useEffect(() => {
    if (compareResponseData) {
      handleSort(selectedSortFilter)
    }
  }, [compareResponseData])



  useEffect(() => {
    if (config1ViewViz?.length > 0) {
      const attr1 = AttributesData.map((attritem: any) => {
        let val: any = []
        config1ViewViz.map((veiwvizitem: any) => {
          if (attritem.id === veiwvizitem.name) {
            val = veiwvizitem.value
          }
        })
        return { ...attritem, value: val }
      })
      dispatch(updateSelectedCompareAttr1(attr1))
      setSavedViewDisplay(true)
      setBookMark(true)
    }
  }, [config1ViewViz])

  useEffect(() => {
    if (config2ViewViz?.length > 0) {
      const attr2 = AttributesData.map((attritem: any) => {
        let val: any = []
        config2ViewViz.map((veiwvizitem: any) => {
          if (attritem.id === veiwvizitem.name) {
            val = veiwvizitem.value
          }
        })
        return { ...attritem, value: val }
      })
      dispatch(updateSelectedCompareAttr2(attr2))
      setSavedViewDisplay(true)
      setBookMark(true)

    }
  }, [config2ViewViz])


  const updateAreasSelection = (area: any) => {
    setAreasSelection((prevData: any) => {
      const index = prevData.findIndex((item: any) => item === area);
      if (index !== -1) {
        const temp = [
          ...prevData.slice(0, index),
          ...prevData.slice(index + 1),
        ];
        return temp;
      } else {
        const temp = [...prevData, area];
        return temp;
      }
    });
  };

  const areaIsChecked = (area: any) => {
    return areasSelection?.some((item: any) => {
      return item === area;
    });
  };

  const areasSelectionSelectAll = () => {
    if (analyzeByData?.length === areasSelection?.length) {
      setAreasSelection([]);
    } else {
      setAreasSelection(analyzeByData);
    }
  };

  const updateAttributeSelection = (attr: any, item: any) => {
    const temp = [...attributeSelection].map((tempAttr: any) => {
      if (tempAttr.id === attr.id) {
        let val = tempAttr.value;
        const index = val.findIndex((tempitem: any) => item === tempitem);
        if (index !== -1) {
          val = [...val.slice(0, index), ...val.slice(index + 1)];
        } else {
          val = [...val, item];
        }
        return { ...tempAttr, value: val };
      }
      return tempAttr;
    });
    dispatch(updateSelectedCompareAttr1(temp))

  };
  const updateAttributeSelection2 = (attr: any, item: any) => {
    const attributeExists = attributeSelection2?.some((tempAttr: any) => tempAttr.id === attr.id)
    let temp: any

    if (attributeExists) {
      temp = [...attributeSelection2].map((tempAttr: any) => {
        if (tempAttr.id === attr.id) {
          let val = tempAttr.value;
          const index = val.findIndex((tempitem: any) => item === tempitem);
          if (index !== -1) {
            val = [...val.slice(0, index), ...val.slice(index + 1)];
          } else {
            val = [...val, item];
          }
          return { ...tempAttr, value: val };
        }
        return tempAttr;
      })
    }
    else {
      temp = [...attributeSelection2, { ...attr, value: [item] }]
    }

    dispatch(updateSelectedCompareAttr2(temp))

  };

  const attributeItemIsSelected = (attr: any, item: any) => {
    return attributeSelection?.some((tempItem: any) => {
      if (tempItem.id === attr.id) {
        return tempItem.value.includes(item);
      }
      return false;
    });
  };

  const attributeItemIsSelected2 = (attr: any, item: any) => {
    return attributeSelection2?.some((tempItem: any) => {
      if (tempItem.id === attr.id) {
        return tempItem.value.includes(item);
      }
      return false;
    });
  };

  const attributeItemSelectAll = (attr: any) => {
    const temp = attributeSelection?.map((tempItem: any) => {
      if (tempItem.id === attr.id) {
        if (tempItem.value.length === attr.value.length) {
          return { ...tempItem, value: [] };
        }
        return { ...tempItem, value: attr.value };
      }
      return tempItem;
    });
    dispatch(updateSelectedCompareAttr1(temp))

  };

  const attributeItemSelectAll2 = (attr: any) => {
    const attributeExists = attributeSelection2?.some((tempAttr: any) => tempAttr.id === attr.id)
    let temp: any
    if (attributeExists) {
      temp = attributeSelection2?.map((tempItem: any) => {
        if (tempItem.id === attr.id) {
          if (tempItem.value.length === attr.value.length) {
            return { ...tempItem, value: [] };
          }
          return { ...tempItem, value: attr.value };
        }
        return tempItem;
      });
    }
    else {
      temp = [...attributeSelection2, attr]
    }
    dispatch(updateSelectedCompareAttr2(temp))
  };

  const attributeItemCheckIsAll = (attr: any) => {
    const selection: any[] = [];
    attributeSelection?.forEach((item: any) => {
      if (item?.id === attr?.id) {
        selection.push(...item.value);
      }
    });
    return attr.value.every((item: any) => selection.includes(item));
  };

  const attributeItemCheckIsAll2 = (attr: any) => {
    const selection: any[] = [];
    attributeSelection2?.forEach((item: any) => {
      if (item?.id === attr?.id) {
        selection.push(...item.value);
      }
    });
    return attr.value.every((item: any) => selection.includes(item));
  };


  //This function manages bookmark toggling, graying out content momentarily, and adding/removing saved views and associated data.
  const handleBookmark = () => {
    if (!bookMark) {
      setBookMark(true);
      setGrayOut(true);
      setTimeout(() => {
        setGrayOut(false);
      }, 300);
      dispatch(addSavedView({
        projectId: projectId,
        payload: savePayload
      })).then(() => {
        setAddBookMarkToggle((prevData: any) => !prevData)
        dispatch(getWordCloudData({
          project_id: projectId,
          payload: savePayload
        }))
          .then((res: any) => {
            setBookMark(res.payload.response.data.is_saved_view)
            setBookMarkID(res.payload.response.data.ID)
            setSelectedSortFilter(res.payload.response.data.SORT_BY)
          })
          .catch((error: any) => {
            console.error("Error fetching word cloud data:", error);
          });
      })
    }

    if (bookMark) {
      setBookMark(false);
      dispatch(deleteSavedView({ projectId: projectId, id: bookMarkID })).then(() => {
        setAddBookMarkToggle((prevData: any) => !prevData)
      })
    }




  };

  //checks if any value is selected in filters or not
  const isFilterEmpty = () => {
    const attributeValue1 =
      attributeSelection.find((item: any) => item.id === "ATTRIBUTE_1")
        ?.value || [];
    const attributeValue2 =
      attributeSelection.find((item: any) => item.id === "ATTRIBUTE_2")
        ?.value || [];

    const attributeValue3 =
      attributeSelection2.find((item: any) => item.id === "ATTRIBUTE_1")
        ?.value || [];
    const attributeValue4 =
      attributeSelection2.find((item: any) => item.id === "ATTRIBUTE_2")
        ?.value || [];
    if (
      (attributeValue1.length === 0 && attributeValue2.length === 0) ||
      (attributeValue3.length === 0 && attributeValue4.length === 0)
    ) {
      return true;
    }
    return false;
  };

  const attributeValue1 = attributeSelection.find((item: any) => item.id === "ATTRIBUTE_1")?.value || [];
  const attributeValue2 = attributeSelection.find((item: any) => item.id === "ATTRIBUTE_2")?.value || [];
  const selection = `${attributeValue1?.join(", ")} ${attributeValue1?.length === 0 || attributeValue2?.length === 0 ? "" : ", "} ${attributeValue2?.join(", ")}`;

  const attributeValue3 = attributeSelection2.find((item: any) => item.id === "ATTRIBUTE_1")?.value || [];
  const attributeValue4 = attributeSelection2.find((item: any) => item.id === "ATTRIBUTE_2")?.value || [];
  const selection1 = `${attributeValue3?.join(", ")} ${attributeValue3?.length === 0 || attributeValue4?.length === 0 ? "" : ", "} ${attributeValue4?.join(", ")}`;

  //Checks if two sets of sorted attribute values are identical or not
  const isFilterSame = () => {
    const sortedAttributeValue1 = attributeValue1.slice().sort();
    const sortedAttributeValue2 = attributeValue2.slice().sort();

    const sortselection = `${sortedAttributeValue1.join(", ")}${sortedAttributeValue1.length === 0 || sortedAttributeValue2.length === 0 ? "" : ", "} ${sortedAttributeValue2.join(", ")}`;

    const sortedAttributeValue3 = attributeValue3.slice().sort();
    const sortedAttributeValue4 = attributeValue4.slice().sort();

    const sortselection1 = `${sortedAttributeValue3.join(", ")}${sortedAttributeValue3.length === 0 || sortedAttributeValue4.length === 0 ? "" : ", "} ${sortedAttributeValue4.join(", ")}`;

    const trimmedSortselection = sortselection.trim();
    const trimmedSortselection1 = sortselection1.trim();

    const areSortedSelectionsEqual = trimmedSortselection === trimmedSortselection1;

    if (areSortedSelectionsEqual) {
      return true
    }
    return false
  };

  //Checks if there is only one value to select for location filter 
  const isOnlyOneLocation = () => {
    const attributeValue1 = AttributesData.find((item: any) => item.id === "ATTRIBUTE_1")?.value || [];
    const attributeValue2 = AttributesData.find((item: any) => item.id === "ATTRIBUTE_2")?.value || []
    const selectedAttributeVal1 = attributeSelection.find((item: any) => item.id === "ATTRIBUTE_1")?.value || [];
    const selectedAttributeVal2 = attributeSelection2.find((item: any) => item.id === "ATTRIBUTE_1")?.value || [];

    if (attributeValue1.length === 1 && attributeValue2.length === 0 && selectedAttributeVal1.length !== 0 && selectedAttributeVal2.length !== 0) {
      return true;
    }
    return false;
  };

  const sortData = (opt: any) => {
    let tempData = [];
    if (opt === "default") {
      tempData = compareResponseData?.data?.sort((a: any, b: any) => {
        const diffA = Math.abs(a.percentage_1 - a.percentage_2);
        const diffB = Math.abs(b.percentage_1 - b.percentage_2);
        return diffB - diffA;
      });

    } else if (opt === "left") {
      tempData = compareResponseData?.data?.sort((a: any, b: any) => b.percentage_1 - a.percentage_1);
    } else if (opt === "right") {
      tempData = compareResponseData?.data?.sort((a: any, b: any) => b.percentage_2 - a.percentage_2);
    }
    if (tempData) {
      setCompareResponseData({ data: [...tempData] });
    }
  };

  const handleSort = (value: any) => {
    setSelectedSortFilter(value);
    sortData(value);
  };

  return (
    <div ref={heightRef} className="select-none w-full h-full">
      <div className="h-[60px] p-5">
        {/* areas */}
        <div className="flex items-center justify-between">
          <ThemeProvider theme={theme}>
            <FormControl
              sx={{ m: 2, width: 120, margin: "0px" }}
              disabled={areasToShow?.length === 0}
            >
              <InputLabel
                id="areas-feature"
                shrink={true}
                sx={{
                  fontFamily: "'Poppins'",
                  fontSize: "14px",
                }}
              >
                Areas
              </InputLabel>
              <Select
                className="truncate"
                value={[]}
                autoWidth
                label="Areas"
                renderValue={() => {
                  if (areasToShow?.length === 0) {
                    return "NA";
                  }
                  if (areasSelection?.length === 0) {
                    return "Select";
                  } else if (analyzeByData?.length === areasSelection?.length) {
                    return "All";
                  } else {
                    return areasSelection?.length + " Selected";
                  }
                }}
                sx={{
                  fontFamily: "'Poppins'",
                  ".MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple": {
                    paddingY: "8px",
                    paddingLeft: "8px",
                    fontWeight: "700",
                    fontSize: "13px",
                  },
                }}
                multiple
                displayEmpty={true}
              // disabled={areasSelection.length === 0}
              >
                <MenuItem
                  key={0}
                  value="All"
                  sx={{ paddingX: "7px 10px" }}
                  onClick={areasSelectionSelectAll}
                >
                  <Checkbox
                    color="primary"
                    sx={{ margin: 0, padding: "2px 2px" }}
                    checked={analyzeByData?.length === areasSelection?.length}
                  />
                  <ListItemText
                    primary={"All"}
                    primaryTypographyProps={{
                      sx: { fontFamily: '"Poppins", sans-serif' },
                    }}
                  />
                </MenuItem>
                {analyzeByData?.map((item: any) => (
                  <MenuItem
                    key={0}
                    value="All"
                    sx={{ paddingX: "7px 10px" }}
                    onClick={() => updateAreasSelection(item)}
                  >
                    <Checkbox
                      color="primary"
                      sx={{ margin: 0, padding: "2px 2px" }}
                      checked={areaIsChecked(item)}
                    />
                    <ListItemText
                      primary={item}
                      primaryTypographyProps={{
                        sx: { fontFamily: '"Poppins", sans-serif' },
                      }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ThemeProvider>


          <ThemeProvider theme={theme}>
            <FormControl
              className="col-span-1"
              sx={{ m: 2, width: 200, margin: "0px" }}            >
              <InputLabel
                id="demo-job-checkbox-label"
                sx={{
                  fontFamily: "'Poppins'",
                  fontSize: "14px",
                }}
              >
                Sort by
              </InputLabel>
              <Select
                label='Sort by'
                value={selectedSortFilter}
                sx={{
                  width: "202px", height: "34px", fontSize: "13px", fontWeight: "700", textAlign: "left", fontFamily: '"Poppins", sans-serif', marginRight: "10px", paddingY: '8px', paddingLeft: "8px"
                }}
                disabled={loader || wordCloudViewStateLoader}
                onChange={(e: any) => { handleSort(e.target.value) }}
              >
                {Object.keys(sortFilterDropDownValues)?.map((key: any) =>
                  <MenuItem
                    className="flex text-wrap break-word"
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      cursor: "pointer",
                      width: "202px",
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                    }}
                    key={key}
                    value={key}
                  >
                    {sortFilterDropDownValues[key]}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </ThemeProvider>
          <div className="flex items-center gap-3 ml-72">
            <ThemeProvider theme={theme}>
              <FormControl sx={{ m: 2, width: 200, margin: '0px' }}>
                <InputLabel
                  id="attribute-filter-label"
                  shrink={true}
                  sx={{
                    fontFamily: "'Poppins'",
                    fontSize: '14px',
                    backgroundColor: "white"

                  }}
                >
                  Attributes
                </InputLabel>
                <Select
                  labelId="attribute-filter-label"
                  id="attribute-filter"
                  multiple
                  value={[]}
                  displayEmpty
                  renderValue={() => {
                    // Determine the display value based on selection
                    let selection: any = [];
                    attributeSelection.forEach((item: any) => {
                      AttributesData.forEach((attr) => {
                        if (item.id === attr.id) {
                          selection = [...selection, ...item.value];
                        }
                      });
                    });
                    if (selection.length === 0) {
                      return 'Select';
                    } else if (selection.length > 0) {
                      return `${selection.length} Selected`;
                    } else {
                      return 'All';
                    }
                  }}
                  // onChange={handleAttributeFilterChange}
                  sx={{
                    fontFamily: "'Poppins'",
                    '.MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple': {
                      paddingY: '8px',
                      paddingLeft: '8px',
                      fontWeight: '700',
                      fontSize: '13px',
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Set the fixed height for the dropdown
                        overflowY: 'auto', // Enable vertical scrolling
                      },
                    },
                  }}
                >
                  {AttributesData.map((attr) => (
                    <React.Fragment key={attr.id}>
                      {/* Group Heading without checkbox */}
                      <MenuItem
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: '"Poppins", sans-serif',
                          paddingX: '7px 10px',
                        }}
                        onClick={() => toggleGroup(attr.id)}
                      >
                        {attr.name}
                        {expandedGroups[attr.id] ? (
                          <KeyboardArrowDown sx={{ cursor: 'pointer' }} />
                        ) : (
                          <KeyboardArrowUp sx={{ cursor: 'pointer' }} />
                        )}
                      </MenuItem>
                      {/* "All" option with checkbox */}
                      {!expandedGroups[attr.id] && (
                        <React.Fragment>
                          <MenuItem
                            value="All"
                            sx={{ paddingX: '7px 10px' }}
                            onClick={() => attributeItemSelectAll(attr)}
                          >
                            <Checkbox
                              color="primary"
                              sx={{ margin: 0, padding: '2px 2px' }}
                              checked={attributeItemCheckIsAll(attr)}
                            />
                            <ListItemText
                              primary="All"
                              primaryTypographyProps={{
                                sx: { fontFamily: '"Poppins", sans-serif' },
                              }}
                            />
                          </MenuItem>
                          {attr.value.map((item: any) => (
                            <MenuItem
                              key={item}
                              value={item}
                              sx={{ paddingX: '7px 10px' }}
                              onClick={() => updateAttributeSelection(attr, item)}
                            >
                              <Checkbox
                                color="primary"
                                sx={{ margin: 0, padding: '2px 2px' }}
                                checked={attributeItemIsSelected(attr, item)}
                              />
                              <ListItemText
                                primary={item}
                                primaryTypographyProps={{
                                  sx: { fontFamily: '"Poppins", sans-serif' },
                                }}
                              />
                            </MenuItem>
                          ))}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ))}
                </Select>
              </FormControl>
            </ThemeProvider>
          </div>
          <span>vs</span>
          <div className="flex items-center gap-3">
            <ThemeProvider theme={theme}>
              <FormControl sx={{ m: 2, width: 200, margin: "0px" }}>
                <InputLabel
                  id="attribute-filter-label2"
                  shrink={true}
                  sx={{
                    fontFamily: "'Poppins'",
                    fontSize: "14px",
                    backgroundColor: "white"
                  }}
                >
                  Attributes
                </InputLabel>
                <Select
                  labelId="attribute-filter-label2"
                  id="attribute-filter2"
                  multiple
                  value={[]}
                  displayEmpty
                  renderValue={() => {
                    // Determine the display value based on selection
                    let selection: any = [];
                    attributeSelection2.forEach((item: any) => {
                      AttributesData.forEach((attr) => {
                        if (item.id === attr.id) {
                          selection = [...selection, ...item.value];
                        }
                      });
                    });
                    if (selection.length === 0) {
                      return "Select";
                    } else if (selection.length > 0) {
                      return `${selection.length} Selected`;
                    } else {
                      return "All";
                    }
                  }}
                  // onChange={handleAttributeFilterChange}
                  sx={{
                    fontFamily: "'Poppins'",
                    ".MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple": {
                      paddingY: "8px",
                      paddingLeft: "8px",
                      fontWeight: "700",
                      fontSize: "13px",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Set the fixed height for the dropdown
                        overflowY: 'auto', // Enable vertical scrolling
                      },
                    },
                  }}
                >
                  {AttributesData.map((attr) => (
                    <React.Fragment key={attr.id}>
                      {/* Group Heading without checkbox */}
                      <MenuItem
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: '"Poppins", sans-serif',
                          paddingX: "7px 10px",
                        }}
                        onClick={() => toggleGroup1(attr.id)}

                      >
                        {attr.name}
                        {expandedGroups1[attr.id] ? (
                          <KeyboardArrowDown sx={{ cursor: "pointer" }} />
                        ) : (
                          <KeyboardArrowUp sx={{ cursor: "pointer" }} />
                        )}
                      </MenuItem>
                      {/* "All" option with checkbox */}
                      {!expandedGroups1[attr.id] && (
                        <React.Fragment>
                          <MenuItem
                            value={`All-${attr.name}`}
                            sx={{ paddingX: "7px 10px" }}
                            onClick={() => attributeItemSelectAll2(attr)}
                          >
                            <Checkbox
                              color="primary"
                              sx={{ margin: 0, padding: "2px 2px" }}
                              checked={attributeItemCheckIsAll2(attr)}
                            />
                            <ListItemText
                              primary="All"
                              primaryTypographyProps={{
                                sx: { fontFamily: '"Poppins", sans-serif' },
                              }}
                            />
                          </MenuItem>
                          {attr.value.map((item: any) => (
                            <MenuItem
                              key={item}
                              value={item}
                              sx={{ paddingX: "7px 10px" }}
                              onClick={() => updateAttributeSelection2(attr, item)}
                            >
                              <Checkbox
                                color="primary"
                                sx={{ margin: 0, padding: "2px 2px" }}
                                checked={attributeItemIsSelected2(attr, item)}
                              />
                              <ListItemText
                                primary={item}
                                primaryTypographyProps={{
                                  sx: { fontFamily: '"Poppins", sans-serif' },
                                }}
                              />
                            </MenuItem>
                          ))}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ))}
                </Select>
              </FormControl>
            </ThemeProvider>
          </div>

          {
            bookMark ? (
              <Tooltip title="Remove saved view">
                <BookmarkOutlinedIcon
                  style={{ color: "#9747FF", cursor: "pointer" }}
                  onClick={() => {
                    handleBookmark();
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  compareResponseData?.data?.length > 0 &&
                    !isFilterEmpty() &&
                    !isFilterSame() &&
                    !isOnlyOneLocation()
                    ? "Save view"
                    : ""
                }
              >
                <BookmarkBorderOutlinedIcon
                  style={{
                    color: "#9747FF",
                    cursor:
                      compareResponseData?.data?.length > 0 &&
                        !isFilterEmpty() &&
                        !isFilterSame() &&
                        !isOnlyOneLocation()
                        ? "pointer"
                        : "not-allowed",
                  }}
                  onClick={() => {
                    if (
                      compareResponseData?.data?.length > 0 &&
                      !isFilterEmpty() &&
                      !isFilterSame() &&
                      !isOnlyOneLocation()
                    ) {
                      handleBookmark();
                    } else {
                      return;
                    }
                  }}
                />
              </Tooltip>
            )
            // :
            // <div className="w-[10px]"></div>
          }
        </div>
      </div>
      <div
        className="overflow-y-auto"
        style={{ height: height - 50, width: width }}
      >
        {(loader || wordCloudViewStateLoader) ?
          <Spinner />
          :
          <>
            {!isFilterEmpty() && !isFilterSame() && !isOnlyOneLocation() ?
              (
                compareResponseData?.data === undefined ||
                  compareResponseData?.data?.length === 0 ? (
                  <span className=" flex justify-center align-items-center mt-52 font-bold">
                    No data is available for this combination
                  </span>
                ) : (
                  Object.keys(compareResponseData) &&
                  Object.keys(compareResponseData)?.length > 0 && (
                    <CompareData
                      attributeSelection={attributeSelection}
                      attributeSelection2={attributeSelection2}
                      compareData={compareResponseData}
                      grayOut={grayOut}
                      selectedTopic={selectedTopic}
                    />
                  )
                )
              )
              : isOnlyOneLocation() ? (
                <span className=" flex justify-center align-items-center mt-52 font-bold">
                  There are no other attributes available for comparsion
                </span>
              ) : isFilterEmpty() ? (
                <span className=" flex justify-center align-items-center mt-52 font-bold">
                  Please select attributes to compare with
                </span>
              ) :
                <>
                  <table style={{ width: "100%" }}>
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: "#fff",
                        zIndex: 12,
                      }}
                    >
                      <tr>
                        <th>
                          <div className="italic text-md text-gray-400  py-5"></div>
                        </th>
                        <th style={{ width: "35%" }}>
                          {selection.trim().length !== 0 && (
                            <div className="rounded-sm text-center border border-gray-300 p-2 w-80 mb-2 ml-14 mt-2">
                              {selection}
                            </div>
                          )}
                        </th>
                        <th style={{ width: "35%" }}>
                          {selection1.trim().length !== 0 && (
                            <div className="rounded-sm text-center border border-gray-300 p-2 w-80 mb-2 ml-14 mt-2">
                              {selection1}
                            </div>
                          )}
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <span className=" flex justify-center align-items-center mt-40 font-bold">
                    Please select different combinations of attributes to compare with
                  </span>
                </>
            }
          </>
        }
      </div>
    </div>
  );
}