import { RadioButtonUnchecked, CheckCircle, Visibility, VisibilityOff, Cancel } from "@mui/icons-material"
import { Button, Checkbox, IconButton, InputAdornment, TextField, createTheme } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../redux/store"
import { decodeToken, setPassword, verifyEmail } from "../../redux/Profile/action"
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { LoadingButton } from "@mui/lab";
import { ThemeProvider } from "@emotion/react"
import { openSnackbar } from "../../redux/Notification/notificationReducer"
import Spinner from "../Spinner"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ChevronLeft } from 'react-feather'
import { updateTokenExpiry } from "../../redux/Profile/profileDataReducer"

const ResetPassword = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation();
  const searchParams: any = new URLSearchParams(location.search);
  const token = (searchParams.get('token'));


  const [resetPassword, setResetPassword] = useState("")
  const [error, setError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [check, setCheck] = useState(false)
  const [isActiveLoader, setIsActiveLoader] = useState<boolean>(false);
  const [tokenExpired, setTokenExpired] = useState<boolean>(false)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const apiMsg = useRef<any>({})

  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
      },
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmPassword = (event: any) => {
    event.preventDefault();
  };


  const handleNext = () => {
    setResetPassword(resetPassword);
    if (resetPassword.length < 8 || (!/[a-z]/.test(resetPassword) || (!/[A-Z]/.test(resetPassword)) || (!/[!@#$%^&*()]/.test(resetPassword))) || (!/\d/.test(resetPassword)) || !isPasswordMatching) {
      setError(true);

    }
    else {
      setError(false);
      handleResetPassword()
    }
  }

  function handleConfirmation() {
    navigate("/login", { replace: true })
  }

  function handleResetPassword() {
    if (pathname.includes('/set-password')) {
      if (check) {
        setIsActiveLoader(true)
        dispatch(verifyEmail({
          password: resetPassword,
          reset_token: token
        })).then((response: any) => {
          if (response.meta.requestStatus === "fulfilled") {
            setIsActiveLoader(false)
            navigate("/login", { state: { activated: response.payload.response.detail } })
          } else {
            dispatch(openSnackbar({ message: "Activation Failed: Please contact our support team for assistance with the activation process", type: "error" }))
            setIsActiveLoader(false)
          }
        })
      }
    } else {
      setIsActiveLoader(true)
      dispatch(setPassword({
        password: resetPassword,
        reset_token: token
      })).then((response: any) => {
        if (response.meta.requestStatus === "fulfilled") {
          setIsActiveLoader(false)
          navigate("/login", { state: { activated: response.payload.response.detail } })
        } else {
          // dispatch(openSnackbar({message:"Activation Failed: Please contact our support team for assistance with the activation process",type: "error" }))
          setIsActiveLoader(false)
        }
      })
    }
  }

  const handleCancel = () => {
    setResetPassword("")
    navigate("/login")
  }

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
  };

  let isPasswordMatching = resetPassword === confirmPassword;

  const checkPayloadDetail = (detail: string) => {
    let options = ['Invalid token', 'Link used', 'Signature verification failed', 'Old link']
    if (options.some((item: string) => item === detail)) {
      return true
    }
    else {
      return false
    }
  }

  useEffect(() => {
    setShowLoader(true)
    dispatch(decodeToken({ token })).then((result: any) => {
      setShowLoader(false)
      if (result.meta.requestStatus === 'rejected') {
        if (checkPayloadDetail(result.payload.detail)) {
          navigate("/login", { state: { activated: "" } })
        }
        else if (result.payload.detail?.duration) {
          setTokenExpired(true)
          dispatch(updateTokenExpiry(true))
          apiMsg.current = result.payload.detail
        }
      }
    })
  }, [token])

  function handleClickBack() {
    navigate('/login')
    dispatch(updateTokenExpiry(false));
  }

  return (
    <>
      {tokenExpired &&
        <svg className="absolute z-10  top-0 mt-[48px] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 16" fill="none">
          <path d="M1440 0H-188.109L1440 16V0Z" fill="#9747FF" />
          <path d="M1440 0H-198L1440 9.6V0Z" fill="#85E8E5" />
        </svg>
      }
      <div className="grid grid-cols-2 max-lg:flex max-lg:flex-col max-lg:justify-start h-full w-full">
        <div className={`flex flex-col justify-center items-center gap-10 ${pathname.includes("/set-password") ? "max-lg:min-h-[20vh]" : "max-lg:min-h-[8vh]"}`}>
          {window.innerWidth < 1024 ?
            <img className="z-10"
              src="https://ezythemes.com/wp-content/uploads/2024/04/logo.svg"
              style={{
                width: "40vw",
                height: "auto"
              }}
              alt="logo"
            />
            :
            <img
              src="/images/EzyThemes logo _vertical_white bg.svg"
              style={{
                width: pathname.includes("/set-password") ? "10vw" : "250px",
                height: "auto"
              }}
              alt="logo"
            />
          }
          {pathname.includes("/set-password") &&
            <div className="w-[60%] max-lg:w-[80%] text-center text-lg max-lg:text-3xl text-gray-400 flex flex-col justify-start gap-5">
              <h1 className="text-3xl max-lg:text-4xl font-semibold text-black">Congratulations!</h1>
              <p>Thank you for your interest in <br /> EzyThemes MR Transcripts Automation.</p>
              <p>You are eligible to recieve $450 free credits. Simply activate your EzyThemes Account
                to get started!
              </p>
            </div>
          }
        </div>
        {showLoader ?
          <div className={`bg-white flex justify-center items-center max-lg:items-start max-lg:pt-[20vh] ${pathname.includes("/set-password") ? "max-lg:h-[79vh]" : "max-lg:h-[89vh]"}`}>
            <div className="max-lg:scale-[2.5]">
              <Spinner height={"20vh"} />
            </div>
          </div>
          :
          !tokenExpired ? (
            !showConfirmation ? (
              <div className={`relative col-span-1 bg-white flex justify-center items-center max-lg:items-start ${pathname.includes("/set-password") ? "max-lg:h-[79vh]" : "max-lg:h-[89vh]"}`}>
                <div className="flex justify-end items-center w-full p-5 absolute top-4">
                  <div className="flex justify-end items-center w-fit cursor-pointer" onClick={handleClickBack}>
                    <ChevronLeft
                      color="gray"
                      size={window.innerWidth >= 1024 ? 20 : 36}
                    />
                    <span className="text-gray-500 max-lg:text-3xl">Back to log in</span>
                  </div>
                </div>
                <div className="w-[32vw] max-lg:w-[86vw] flex flex-col justify-start gap-7 max-lg:gap-10 max-lg:mt-[3vh]">
                  <div className="max-lg:text-center">
                    <h1 className="font-semibold text-2xl max-lg:text-4xl mb-1">
                      {pathname.includes("/set-password")
                        ? "Account Activation"
                        : "Password Reset"}
                    </h1>
                    <p className="text-gray-400 text-md max-lg:text-3xl text-lg">
                      {pathname.includes("/set-password")
                        ? "Setup your password to activate your account!"
                        : "Ensure that your new password meets all the requirements below."}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <div className="">
                    </div>
                    <ThemeProvider theme={customTheme}>
                      <TextField
                        id="outlined-basic"
                        sx={{
                          //maxWidth: "350px",
                          placeSelf: "center",
                          flexGrow: "1",
                          width: "100%",
                          "& .MuiInputBase-root": {
                            height: { sm: 60, md: 80, lg: 52 },
                          },
                          "& .MuiInputLabel-root, & .MuiInputBase-root": {
                            fontSize: { sm: 20, md: 30, lg: 15 },
                            fontFamily: '"Poppins", sans-serif'
                          },
                          "& .MuiFormHelperText-root": {
                            fontSize: { sm: 20, md: 20, lg: 10 },
                            fontFamily: '"Poppins", sans-serif'
                          },
                        }}
                        label="New Password"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setResetPassword(e.target.value)}
                        value={resetPassword}
                        error={error && resetPassword.length === 0}
                        helperText={
                          (error && resetPassword.length === 0) && (
                            "Required"
                          )
                        }
                        InputProps={{
                          endAdornment:
                            <InputAdornment
                              position="end" sx={{ marginRight: window.innerWidth >= 1024 ? "" : '20px !important' }}
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                className="max-lg:scale-[2]"
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                        }}
                      />
                    </ThemeProvider>
                  </div>
                  <div className="text-lg flex flex-col justify-start gap-2 max-lg:text-3xl max-lg:pl-2">
                    <p className="flex items-center text-custom-gray">
                      {resetPassword.length >= 8 ? (
                        <CheckCircle
                          className="text-green-600 text-lg mr-2 max-lg:mr-4 max-lg:scale-[1.8]"
                          fontSize="small"
                        />
                      ) : (
                        <RadioButtonUnchecked
                          className="text-red-500 text-lg mr-2 max-lg:mr-4 max-lg:scale-[1.8]"
                          fontSize="small"
                        />
                      )}
                      Have minimum 8 characters
                    </p>
                    <p className="flex items-center text-custom-gray">
                      {/[a-z]/.test(resetPassword) ? (
                        <CheckCircle
                          className="text-green-600 mr-2 max-lg:mr-4 max-lg:scale-[1.8]"
                          fontSize="small"
                        />
                      ) : (
                        <RadioButtonUnchecked
                          className="text-red-500 mr-2 max-lg:mr-4 max-lg:scale-[1.8]"
                          fontSize="small"
                        />
                      )}
                      Include at least one lowercase (a-z)
                    </p>
                    <p className="flex items-center text-custom-gray">
                      {/[A-Z]/.test(resetPassword) ? (
                        <CheckCircle
                          className="text-green-600 mr-2 max-lg:mr-4 max-lg:scale-[1.8]"
                          fontSize="small"
                        />
                      ) : (
                        <RadioButtonUnchecked
                          className="text-red-500 mr-2 max-lg:mr-4 max-lg:scale-[1.8]"
                          fontSize="small"
                        />
                      )}
                      Include at least one uppercase (A-Z)
                    </p>
                    <p className="flex items-center text-custom-gray">
                      {/\d/.test(resetPassword) ? (
                        <CheckCircle
                          className="text-green-600 mr-2 max-lg:mr-4 max-lg:scale-[1.8]"
                          fontSize="small"
                        />
                      ) : (
                        <RadioButtonUnchecked
                          className="text-red-500 mr-2 max-lg:mr-4 max-lg:scale-[1.8]"
                          fontSize="small"
                        />
                      )}
                      Include at least one number (0-9)
                    </p>
                    <p className="flex items-center text-custom-gray">
                      {/[!@#$%^&*()]/.test(resetPassword) ? (
                        <CheckCircle
                          className="text-green-600 mr-2 max-lg:mr-4 max-lg:scale-[1.8]"
                          fontSize="small"
                        />
                      ) : (
                        <RadioButtonUnchecked
                          className="text-red-500 mr-2 max-lg:mr-4 max-lg:scale-[1.8]"
                          fontSize="small"
                        />
                      )}
                      Include at least one special character
                    </p>
                  </div>

                  <div className=" flex items-center">
                    <ThemeProvider theme={customTheme}>
                      <TextField
                        id="outlined-basic"
                        sx={{
                          //maxWidth: "350px",
                          placeSelf: "center",
                          flexGrow: "1",
                          width: "100%",
                          "& .MuiInputBase-root": {
                            height: { sm: 60, md: 80, lg: 52 },
                          },
                          "& .MuiInputLabel-root, & .MuiInputBase-root": {
                            fontSize: { sm: 20, md: 30, lg: 15 },
                            fontFamily: '"Poppins", sans-serif'
                          },
                          "& .MuiFormHelperText-root": {
                            fontSize: { sm: 20, md: 20, lg: 10 },
                            fontFamily: '"Poppins", sans-serif',
                            // marginTop: "20px"
                          },
                        }}
                        label="Confirm Password"
                        variant="outlined"
                        type={showConfirmPassword ? "text" : "password"}
                        onChange={handleConfirmPasswordChange}
                        value={confirmPassword}
                        error={error && resetPassword.length === 0}
                        helperText={
                          (error && confirmPassword.length === 0) ? (
                            "Required"
                          )
                            :
                            (!isPasswordMatching && confirmPassword.length > 0) ? (
                              <div className="text-lg flex flex-col justify-start gap-2 max-lg:text-3xl max-lg:pl-2">
                                <p className=" text-custom-gray">
                                  {(<Cancel
                                    sx={{ marginLeft: "-5px" }}
                                    className="text-red-500 mr-2 max-lg:mr-4 max-lg:scale-[1.8]"
                                    fontSize="small"
                                  />
                                  )}
                                  Password not matching
                                </p>
                              </div>
                            )
                              :
                              ((confirmPassword.length > 0 && confirmPassword === resetPassword) &&
                                (<div className="text-lg flex flex-col justify-start gap-2 max-lg:text-3xl max-lg:pl-2">
                                  <p className=" text-custom-gray">
                                    {(<CheckCircleIcon
                                      sx={{ marginLeft: "-5px" }}
                                      className="text-green-500 mr-2 max-lg:mr-4 max-lg:scale-[1.8]"
                                      fontSize="small"
                                    />
                                    )}
                                    Password matching
                                  </p>
                                </div>)
                              )
                        }
                        InputProps={{
                          endAdornment:
                            <InputAdornment
                              position="end" sx={{ marginRight: window.innerWidth >= 1024 ? "" : '20px !important' }}
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownConfirmPassword}
                                className="max-lg:scale-[2]"
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                        }}
                      />
                    </ThemeProvider>
                  </div>
                  {!pathname.includes("/reset-password") && (
                    <div className="flex justify-center items-start max-lg:text-3xl gap-2">
                      <Checkbox
                        className="max-lg:scale-[2]"
                        onClick={() => setCheck((prevState) => !prevState)}
                      />
                      <div>
                        <p>
                          I understand that by activating my account, I am
                          agreeing to the {" "}
                          <a href="https://ezythemes.com/license/">
                            <span className="text-blue-400 cursor-pointer">
                              EzyThemes Platform License Agreement
                            </span>
                          </a>
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="flex justify-center items-center">
                    {pathname.includes("/set-password") ? (
                      <ThemeProvider theme={customTheme}>
                        <LoadingButton
                          disabled={!check &&
                            !(
                              resetPassword.length < 8 ||
                              !/[a-z]/.test(resetPassword) ||
                              !!/[A-Z]/.test(resetPassword) ||
                              !/[!@#$%^&*()]/.test(resetPassword) ||
                              !/\d/.test(resetPassword) ||
                              !isPasswordMatching
                            )}
                          onClick={handleNext}
                          style={{
                            width: "100%",
                            backgroundColor: check && (
                              resetPassword.length >= 8 &&
                              /[a-z]/.test(resetPassword) &&
                              /[A-Z]/.test(resetPassword) &&
                              /[!@#$%^&*()]/.test(resetPassword) &&
                              /\d/.test(resetPassword) &&
                              isPasswordMatching
                            ) ? "#9747FF" : "gray",
                            color: "#FFFF",
                            padding: "8px",
                          }}
                          sx={{
                            height: { sm: "70px", md: "70px", lg: "35px" },
                            fontSize: { sm: 25, md: 25, lg: 15 },
                            fontFamily: '"Poppins", sans-serif'
                          }}
                          loading={isActiveLoader}
                          loadingPosition="end"
                          variant="contained"
                        >
                          Activate
                        </LoadingButton>
                      </ThemeProvider>
                    ) : (
                      <ThemeProvider theme={customTheme}>
                        <LoadingButton
                          disabled={
                            !(
                              resetPassword.length < 8 ||
                              !/[a-z]/.test(resetPassword) ||
                              !!/[A-Z]/.test(resetPassword) ||
                              !/[!@#$%^&*()]/.test(resetPassword) ||
                              !/\d/.test(resetPassword) ||
                              !isPasswordMatching
                            )
                          }
                          onClick={handleNext}
                          style={{
                            width: "100%",
                            backgroundColor: !(
                              resetPassword.length < 8 ||
                              !/[a-z]/.test(resetPassword) ||
                              !/[A-Z]/.test(resetPassword) ||
                              !/[!@#$%^&*()]/.test(resetPassword) ||
                              !/\d/.test(resetPassword) ||
                              !isPasswordMatching
                            )
                              ? "#9747FF"
                              : "gray",
                            color: "#FFFF",
                            padding: "8px",
                          }}
                          loading={isActiveLoader}
                          loadingPosition="end"
                          variant="contained"
                          sx={{
                            height: { sm: "70px", md: "70px", lg: "35px" },
                            fontSize: { sm: 25, md: 25, lg: 15 },
                            fontFamily: '"Poppins", sans-serif'
                          }}
                        >
                          Reset Password
                        </LoadingButton>
                      </ThemeProvider>
                    )}
                  </div>

                  {pathname.includes("/reset-password") && (
                    <div
                      className="cursor-pointer flex justify-center"
                      onClick={handleCancel}
                    >
                      <span className="text-gray-500 text-lg max-lg:text-4xl">Cancel</span>
                    </div>
                  )}
                  <div></div>
                </div>
              </div>
            ) : pathname.includes("/reset-password") ? (
              <div className="col-span-1 bg-white flex justify-center items-center max-lg:items-start max-lg:h-[89vh]">
                <div className="w-[30vw] max-lg:w-[86vw] max-lg:mt-[4vh] text-center flex flex-col justify-start items-center gap-5 max-lg:gap-10">
                  <div>
                    <h1 className="text-black font-semibold text-2xl max-lg:text-4xl mb-1 max-lg:mb-3">
                      Password Reset Successfully
                      <span >
                        <CheckCircle className="max-lg:scale-[1.8] max-lg:ml-4" />
                      </span>
                    </h1>
                    <p className="text-gray-400 text-lg max-lg:text-3xl">
                      Hurray you may login with this new password
                    </p>
                  </div>
                  <p className="max-lg:text-3xl">
                    You should have received an email from us that your password
                    has been successfully changed.This will take effect
                    immediately, nothing else has to be done on your end.
                  </p>
                  <ThemeProvider theme={customTheme}>
                    <Button
                      variant="outlined"
                      style={{
                        width: "80%",
                        backgroundColor: "#9747FF",
                        color: "#FFFF",
                        padding: "8px",
                      }}
                      sx={{
                        height: { sm: "70px", md: "70px", lg: "35px" },
                        fontSize: { sm: 25, md: 25, lg: 15 },
                        fontFamily: '"Poppins", sans-serif'
                      }}
                      onClick={handleConfirmation}
                    // disabled={error==true}
                    >
                      Done
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            ) : (
              <div className="col-span-1 bg-white flex items-center justify-center max-lg:items-start max-lg:h-[78vh]">
                <div className="flex flex-col justify-center items-center gap-3 max-lg:gap-5 max-lg:mt-[5vh]">
                  <MarkEmailReadIcon className="scale-[2] max-lg:scale-[4] max-lg:mb-7" />
                  <h1 className="text-black font-semibold text-2xl max-lg:text-5xl">
                    Account verification Success
                  </h1>

                  <p className="max-lg:text-3xl">
                    Please{" "}
                    <span
                      className="text-[#9747FF] cursor-pointer"
                      onClick={handleConfirmation}
                    >
                      Login
                    </span>{" "}
                    to ezyThemes to proceed
                  </p>
                </div>
              </div>
            )
          ) : (
            <div className="col-span-1 bg-white flex justify-center items-center max-lg:h-[50vh] relative">
              <div className="flex justify-end items-center w-full p-5 absolute top-12">
                <div className="flex justify-end items-center w-fit cursor-pointer" onClick={handleClickBack}>
                  <ChevronLeft
                    color="gray"
                    size={window.innerWidth >= 1024 ? 20 : 36}
                  />
                  <span className="text-gray-500 max-lg:text-3xl">Back to log in</span>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-7 px-[7vw]">
                <h1 className="text-3xl font-semibold text-gray-800">
                  Link has expired
                </h1>
                <span className="text-lg max-lg:text-4xl text-gray-400">
                  Oops! This link has expired as a security measure after {apiMsg.current.duration}.
                </span>
                <span className="text-lg max-lg:text-4xl text-gray-400">
                  {apiMsg.current.message}
                </span>
              </div>
            </div>
          )}
      </div>
      {tokenExpired &&
        <svg className=" fixed bottom-0 left-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 16" fill="none">
          <path d="M0.000732422 16H1628.11L0.000732422 0V16Z" fill="#9747FF" />
          <path d="M0 16H1638L0 7V16Z" fill="#85E8E5" />
        </svg>
      }
    </>
  );
}

export default ResetPassword