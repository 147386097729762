import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProjectData, INewProjectFiles } from "./type";
import {
  addFavorite,
  confirmMRQCCorrection,
  downLoadMRQc,
  editProjectName,
  getAllProjects,
  getDeleteProject,
  getDeleteProjectFile,
  getProjectDomine,
  getProjectFileUpload,
  getProjectsDataList,
  getProjectState,
  updateProject,
  downloadManageThemes,
  uploadManageThemes,
  confirmManageThemes,
  uploadMRQc,
  getMRQCFiles,
  getProjectDetails,
  getAccountFilterData,
  getUpdateAccountId,
  getClonedProjectInfo,
  downloadInputFiles,
  getEntitiesQcData,
  getEntityTypesData,
  getSentenceEntityAnchor,
  intelligentMR,
  intelligentCorrection,
  getParkAnchorsList,
  getParkEquivalentsList,
  getDivergenceData,
  getEntityTypesPreQcData,
  getEntitiesPreQcData,
  getParkAnchorsPreQcList,
  getPreQcParkEquivalentsList,
  getDomainMaster,
  postProcessOutputState,
  saveProjectConfiguration,
  getProjectConfiguration,
  getPreQcTabData,
  getIntelligentMrQcData,
  getSpeakersList,
  getCorrectedMrPreQcData,
  getTranscriptsData,
  updateMrPreQcTextBlocks,

} from "./action";
import { stat } from "fs";

const initialState: IProjectData = {
  projectDataSetList: [],
  projectDataSetListLoader: false,
  projectDataSetListDownloadAvailable: false,
  projectDomain: [],
  projectAssets: [],
  projectList: [],
  isLoading: false,
  projectCount: 0,
  editProjectName: false,
  projectsFilter: 'All Projects',
  accountFilter: "All Accounts",
  AccountFilterData: [],
  projectsOrder: 'last_modified',
  projectsSearch: '',
  paginationLimit: 9,
  currentPage: 1,
  newProjectFiles: [],
  favoritesProjectsOnly: false,
  projectCreated: false,
  projectState: {},
  projectStateFiles: {},
  projectStateLoader: false,
  currentProjectId: "",
  uploadMRQCStatus: null,
  mrQcFiles: [],
  uploadManageQc: false,
  confirmMRCorrectionStatus: false,
  confirmManageQcStatus: false,
  confirmManageQCLoader: false,
  confirmMrQCLoader: false,
  projectDetails: [],
  discussionFiles: [],
  fileUploadSuccess: false,
  MRLoader: false,
  uploadError: "",
  UpdatedAccountId: "",
  clonedProjectLoader: false,
  clonedProjectsList: [],
  entitiesQcDataLoader: false,
  entitiesQcData: {},
  entityTypesData: [],
  entityAnchorSentencesData: [],
  entityAnchorSentencesDataLoader: false,
  intelligentMRData: [],
  intelligentMRLoader: false,
  intelligentCorrectionData: [],
  intelligentCorrectionLoader: false,
  intelligentCorrectionPayload: [],
  intelligentMRPayload: [],
  entitiesHideTypes: [],
  entitiesHideAnchors: [],
  entitiesHideEquivalents: [],
  entityEquivalentsParktab1: [],
  entityEquivalentsParktab2: [],
  entityAnchorsParktab1: [],
  entityAnchorsParktab2: [],
  divergenceData: {},
  entityTypesPreQcData: [],
  entitiesPreQcData: [],
  entitiesPreQcDataLoader: false,
  entityAnchorsPreQcPark: [],
  entityEquivalentsPreQcPark: [],
  domainMasterData: [],
  categoryName: '',
  postProcessOutputStateSuccess: false,
  topicsOfInterestData: [],
  projectConfigData: {},
  preQcTabData: [],
  preQcTabDataLoader: false,
  updateProjectName: "",
  duplicateProjectNameStatus: false,
  intelligentMrQcData: [],
  speakersListData: [],
  correctedMrPreQcData: [],
  intelligentMrQcDataLoading: false,
  correctedMrPreQcDataLoading: false,
  transcriptsData: [],
  researchTypeProjSetUp: "",
  saveProjectPayload:{}
};
export const projectDataSlice = createSlice({
  name: "ProjectData",
  initialState: initialState,
  reducers: {
    insertFile: (state: IProjectData, action) => {
      state.newProjectFiles.unshift({
        filename: action.payload.filename,
        fileUploadStatus: "processing",
        fileUploadStatusMessage: "Upload in progress",
        fileUploadProgress: 0,
      });
    },
    setFileUploadProgress: (state: IProjectData, action) => {
      let updatedProjectFiles = state.newProjectFiles.map(
        (e: INewProjectFiles) => ({ ...e })
      );
      updatedProjectFiles.forEach((e: INewProjectFiles) => {
        if (action.payload.filenames.includes(e.filename)) {
          e.fileUploadProgress = action.payload.progress;
        }
      });
      state.newProjectFiles = updatedProjectFiles;
    },
    updateProjectName: (state, action) => {
      let updateProjectList = state.projectList.map((project) => {
        if (project.PROJECT_ID === action.payload.project_id) {
          return {
            ...project,
            PROJECT_NAME: action.payload.newProjectName,
          };
        } else {
          return project;
        }
      });
      state.projectList = updateProjectList;
    },
    updateCurrentPaginationPage: (state, action) => {
      state.currentPage = action.payload;
    },
    updateProjectDetails: (state, action) => {
      state.projectDetails = action.payload;
    },
    updateProjectsSearchString: (state, action) => {
      state.projectsSearch = action.payload;
    },
    updateProjectsFilter: (state, action) => {
      state.projectsFilter = action.payload;
    },
    updateAccountFilter: (state, action) => {
      state.accountFilter = action.payload
    },
    updateProjectsOrder: (state, action) => {
      state.projectsOrder = action.payload;
    },
    addProject: (state, action) => {
      state.projectList = [action.payload, ...state.projectList]
    },
    updateFileUploadSuccess: (state, action) => {
      state.fileUploadSuccess = action.payload
    },
    updateFavoritesProjectsOnly: (state, action) => {
      state.favoritesProjectsOnly = action.payload;
    },
    UpdateProjectCreated: (state, action) => {
      state.projectCreated = action.payload;
    },
    updateUploadFileStatus: (state, action) => {
      state.uploadMRQCStatus = action.payload
      state.uploadManageQc = action.payload
      state.confirmMRCorrectionStatus = false
      state.confirmManageQcStatus = false
    },
    updateProjectState: (state, action) => {
      state.projectState = action.payload
    },
    updateDiscussionFile: (state, action) => {
      state.discussionFiles = action.payload
    },
    updateEntitiesHideTypes: (state, action) => {
      state.entitiesHideTypes = action.payload
    },
    updateEntitiesHideAnchors: (state, action) => {
      state.entitiesHideAnchors = action.payload
    },
    updateEntitiesHideEquivalents: (state, action) => {
      state.entitiesHideEquivalents = action.payload
    },
    updateTopicsOfInterest: (state, action) => {
      state.topicsOfInterestData = action.payload
    },
    updateEntitiesPreQcDataLoader: (state, action) => {
      state.entitiesPreQcDataLoader = action.payload
    },
    // AllProjects : (state, action) =>{
    //   state.AllProjects = action.payload
    // }

    // filterProjects(state, action) {
    //   const isFavorite = action.payload;

    //   // if (isFavorite) {
    //   //   state.filteredProjects = state.AllProjectList.filter(
    //   //     (project) => project.isFavorite
    //   //   );
    //   // } else {
    //   //   state.filteredProjects = state.AllProjectList;
    //   // }
    // },
    updateCurrentPorjectId: (state, action) => {
      state.currentProjectId = action.payload;
    },

    updateMRPayload: (state, action) => {

      state.intelligentMRPayload = action.payload
    },
    updateIntelligentCorrectionPayload: (state, action) => {
      state.intelligentCorrectionPayload = action.payload
    },
    updateEntitiesQCData: (state, action) => {
      state.entitiesQcData = action.payload
    },
    updateParkEE1: (state, action) => {
      state.entityEquivalentsParktab1 = action.payload
    },
    updateParkEE2: (state, action) => {
      state.entityEquivalentsParktab2 = action.payload
    },
    updateParkEA1: (state, action) => {
      state.entityAnchorsParktab1 = action.payload
    },
    updateParkEA2: (state, action) => {
      state.entityAnchorsParktab2 = action.payload
    },
    updateCategoryName: (state, action) => {
      state.categoryName = action.payload;
    },
    updateProjectNameProjSetup: (state, action) => {
      state.updateProjectName = action.payload;
    },
    duplicateProjectNameStatus: (state, action) => {
      state.duplicateProjectNameStatus = action.payload
    },
    insertNewMRBlock: (state, action) => {
      state.correctedMrPreQcData = action.payload
    },
    updateResearchTypeProjSetup: (state, action) => {
      state.researchTypeProjSetUp=action.payload
    },
    saveProjSetupPayload: (state, action) => {
      state.saveProjectPayload = action.payload
    }
    
  },

  extraReducers: (builder) => {
    builder
      .addCase(getProjectsDataList.pending, (state: IProjectData) => {
        state.projectDataSetListLoader = true;
      })
      .addCase(
        getProjectsDataList.fulfilled,
        (state: IProjectData, action: PayloadAction<any>) => {
          state.projectDataSetList = action.payload.response.data?.data;
          state.projectDataSetListDownloadAvailable = action.payload.response.data?.download_available;
          state.projectDataSetListLoader = false;
        }
      )
      .addCase(getProjectsDataList.rejected, (state: IProjectData) => {
        state.projectStateLoader = true
      });

    builder.addCase(getProjectState.pending, (state: IProjectData) => {
      state.projectStateLoader = true;
    });
    builder.addCase(
      getProjectState.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        state.projectState = action.payload.response;
        state.projectStateFiles = action.payload.response?.data?.state?.files
        state.projectStateLoader = false;
      }
    );
    builder.addCase(getProjectState.rejected, (state: IProjectData) => {
      state.projectStateLoader = false;
    });

    builder
      .addCase(getDeleteProject.pending, (state: IProjectData) => {
        // state.isLoading=true
      })
      .addCase(getDeleteProject.fulfilled, (state: IProjectData, { meta }) => {
        // state.isLoading=false
        state.projectList = state.projectList?.filter((item: any) => item.PROJECT_ID !== meta.arg.project_id)
      }
      )
      .addCase(getDeleteProject.rejected, (state: IProjectData) => {
        // state.isLoading=false
      });

    builder
      .addCase(getProjectDomine.pending, (state: IProjectData) => { })
      .addCase(
        getProjectDomine.fulfilled,
        (state: IProjectData, action: PayloadAction<any>) => {
          state.projectDomain = action.payload.response;
        }
      )
      .addCase(getProjectDomine.rejected, (state: IProjectData) => { });

    builder
      .addCase(getProjectFileUpload.pending, (state: IProjectData) => { })
      .addCase(
        getProjectFileUpload.fulfilled,
        (state: IProjectData, action: PayloadAction<any>) => {
          state.projectAssets = action.payload.response;
        }
      )
      .addCase(
        getProjectFileUpload.rejected,
        (state: IProjectData, error: any) => {
        }
      );



    builder
      .addCase(getAllProjects.pending, (state: IProjectData) => {
        state.isLoading = true
      })
      .addCase(
        getAllProjects.fulfilled,
        (state: IProjectData, action: PayloadAction<any>) => {
          state.projectList = action.payload.response.data.projects;
          state.projectCount = action.payload.response.data.count;
          state.isLoading = false
        }
      )
      .addCase(getAllProjects.rejected, (state: IProjectData) => {
        state.isLoading = false
      });

    builder
      .addCase(getDeleteProjectFile.pending, (state: IProjectData) => {
        state.isLoading = true
      })
      .addCase(
        getDeleteProjectFile.fulfilled,
        (state: IProjectData, action: PayloadAction<any>) => {
          state.projectDataSetList = action.payload.response;
          state.isLoading = false
        }
      )
      .addCase(getDeleteProjectFile.rejected, (state: IProjectData) => {
        state.isLoading = false
      });

    builder
      .addCase(editProjectName.pending, (state: IProjectData, { meta }) => {
        let { projectId, projectName } = meta.arg;
        let updateProjectList = state.projectList.map((project) => {
          if (project.PROJECT_ID === projectId) {
            return { ...project, PROJECT_NAME: projectName }
          } else {
            return { ...project };
          }
        });
        state.projectList = updateProjectList;
      })
      .addCase(
        editProjectName.fulfilled, (state: IProjectData, action: PayloadAction<any>) => { }
      )
      .addCase(editProjectName.rejected, (state: IProjectData, action: any) => {
        let projectId = action.meta.arg.projectId;
        let projectName = action.payload.data.project_name;
        let updateProjectList = state.projectList.map((project) => {
          if (project.PROJECT_ID === projectId) {
            return { ...project, PROJECT_NAME: projectName }
          } else {
            return { ...project };
          }
        });
        state.projectList = updateProjectList;
      });

    builder
      .addCase(addFavorite.pending, (state: IProjectData, { meta }) => {
        let updateProjectList = state.projectList.map((project) => {
          if (project.PROJECT_ID === meta.arg.projectId) {
            return { ...project, IS_FAVORITE: meta.arg.isFavorite }
          } else {
            return { ...project };
          }
        });
        state.projectList = updateProjectList;
      })
      .addCase(
        addFavorite.fulfilled,
        (state: IProjectData, action: PayloadAction<any>) => {
        }
      )
      .addCase(addFavorite.rejected, (state: IProjectData, action: PayloadAction<any>) => {

      });
    builder
      .addCase(updateProject.pending, (state: IProjectData, action: PayloadAction<any>) => {
      })
      .addCase(updateProject.rejected, (state: IProjectData, action: PayloadAction<any>) => {
      })
      .addCase(updateProject.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        let temp = state.projectList.map((data: any) => {
          if (data.PROJECT_ID === action.payload.response.data.PROJECT_ID) {
            return action.payload.response.data
          }
          else {
            return data
          }
        })
        state.projectList = temp
      })
    builder
      .addCase(downloadManageThemes.pending, (state: IProjectData, action: PayloadAction<any>) => {
      })
      .addCase(downloadManageThemes.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        const response = action.payload.response.data
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        let filename = `MANAGE_THEMES.xlsx`
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .addCase(downloadManageThemes.rejected, (state: IProjectData, action: PayloadAction<any>) => {
      });
    builder
      .addCase(uploadManageThemes.pending, (state: IProjectData, action: PayloadAction<any>) => {
      })
      .addCase(uploadManageThemes.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        state.uploadManageQc = true
      })
      .addCase(uploadManageThemes.rejected, (state: IProjectData, action: PayloadAction<any>) => {
      });
    builder
      .addCase(confirmManageThemes.pending, (state: IProjectData, action: PayloadAction<any>) => {
        state.confirmManageQCLoader = true
      })
      .addCase(confirmManageThemes.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        state.confirmManageQcStatus = true
        state.confirmManageQCLoader = false
      })
      .addCase(confirmManageThemes.rejected, (state: IProjectData, action: PayloadAction<any>) => {
        state.confirmManageQCLoader = false
      })
      .addCase(downLoadMRQc.pending, (state: IProjectData, action: PayloadAction<any>) => { })
      .addCase(downLoadMRQc.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        const response = action.payload.response.data
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        let filename = "mr_correction_qc.xlsm"
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .addCase(downLoadMRQc.rejected, (state: IProjectData, action: PayloadAction<any>) => { })
    builder
      .addCase(uploadMRQc.pending, (state: IProjectData, action: PayloadAction<any>) => {
      })
      .addCase(uploadMRQc.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        state.uploadMRQCStatus = true
        state.uploadError = action.payload.response.error
      })
      .addCase(uploadMRQc.rejected, (state: IProjectData, action: PayloadAction<any>) => {
      })
    builder
      .addCase(confirmMRQCCorrection.pending, (state: IProjectData, action: PayloadAction<any>) => {
        state.confirmMrQCLoader = true
      })
      .addCase(confirmMRQCCorrection.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        state.confirmMRCorrectionStatus = true
        state.confirmMrQCLoader = false
      })
      .addCase(confirmMRQCCorrection.rejected, (state: IProjectData, action: PayloadAction<any>) => {
        state.confirmMrQCLoader = false
      })
    builder
      .addCase(getMRQCFiles.pending, (state: IProjectData, action: PayloadAction<any>) => {
        state.MRLoader = true
      })
      .addCase(getMRQCFiles.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        state.mrQcFiles = action.payload.response.data
        state.MRLoader = false
      })
      .addCase(getMRQCFiles.rejected, (state: IProjectData, action: PayloadAction<any>) => {
        state.MRLoader = false
      })
    builder
      .addCase(getProjectDetails.pending, (state: IProjectData, action: PayloadAction<any>) => { })
      .addCase(getProjectDetails.fulfilled, (state: IProjectData, action: PayloadAction<any>) => { state.projectDetails = action.payload.response.data })
      .addCase(getProjectDetails.rejected, (state: IProjectData, action: PayloadAction<any>) => { })
    builder
      .addCase(getDivergenceData.pending, (state: IProjectData, action: PayloadAction<any>) => { })
      .addCase(getDivergenceData.fulfilled, (state: IProjectData, action: PayloadAction<any>) => { state.divergenceData = action.payload.response?.data })
      .addCase(getDivergenceData.rejected, (state: IProjectData, action: PayloadAction<any>) => { })
    builder
      .addCase(getAccountFilterData.pending, (state: IProjectData, action: PayloadAction<any>) => { })
      .addCase(getAccountFilterData.fulfilled, (state: IProjectData, action: PayloadAction<any>) => { state.AccountFilterData = action.payload.response.data })
      .addCase(getAccountFilterData.rejected, (state: IProjectData, action: PayloadAction<any>) => { })
    builder
      .addCase(getUpdateAccountId.pending, (state: IProjectData, action: PayloadAction<any>) => { })
      .addCase(getUpdateAccountId.fulfilled, (state: IProjectData, action: PayloadAction<any>) => { state.UpdatedAccountId = action.payload.response.data })
      .addCase(getUpdateAccountId.rejected, (state: IProjectData, action: PayloadAction<any>) => { })
    builder
      .addCase(getClonedProjectInfo.pending, (state: IProjectData, action: PayloadAction<any>) => {
        state.clonedProjectLoader = true;
      })
      .addCase(getClonedProjectInfo.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        state.clonedProjectLoader = false
        state.clonedProjectsList = action.payload.response.data
      })
      .addCase(getClonedProjectInfo.rejected, (state: IProjectData, action: PayloadAction<any>) => {
        state.clonedProjectLoader = false;
      })
    builder
      .addCase(getEntitiesQcData.pending, (state: IProjectData, action: PayloadAction<any>) => {
        state.entitiesQcDataLoader = true;
      })
      .addCase(getEntitiesQcData.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        state.entitiesQcDataLoader = false;
        state.entitiesQcData = action.payload.response.data;
      })
      .addCase(getEntitiesQcData.rejected, (state: IProjectData, action: PayloadAction<any>) => {
        state.entitiesQcDataLoader = false;
      })
    builder
      .addCase(getEntitiesPreQcData.pending, (state: IProjectData, action: PayloadAction<any>) => {
        // state.entitiesPreQcDataLoader=true;
      })
      .addCase(getEntitiesPreQcData.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        // state.entitiesPreQcDataLoader=false;
        if (action.payload.response.status === false) {
          state.entitiesPreQcData = [];
        } else {
          state.entitiesPreQcData = action.payload.response.data;
        }
      })
      .addCase(getEntitiesPreQcData.rejected, (state: IProjectData, action: PayloadAction<any>) => {
        // state.entitiesPreQcDataLoader=false;
      })
    builder
      .addCase(getEntityTypesData.pending, (state: IProjectData, action: PayloadAction<any>) => {
      })
      .addCase(getEntityTypesData.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        state.entityTypesData = action.payload.response.data;
      })
      .addCase(getEntityTypesData.rejected, (state: IProjectData, action: PayloadAction<any>) => {
      })
    builder
      .addCase(getEntityTypesPreQcData.pending, (state: IProjectData, action: PayloadAction<any>) => {
      })
      .addCase(getEntityTypesPreQcData.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        state.entityTypesPreQcData = action.payload.response.data;
      })
      .addCase(getEntityTypesPreQcData.rejected, (state: IProjectData, action: PayloadAction<any>) => {
      })
    builder
      .addCase(getSentenceEntityAnchor.pending, (state: IProjectData, action: PayloadAction<any>) => {
        state.entityAnchorSentencesDataLoader = true
      })
      .addCase(getSentenceEntityAnchor.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        state.entityAnchorSentencesData = action.payload.response.data;
        state.entityAnchorSentencesDataLoader = false
      })
      .addCase(getSentenceEntityAnchor.rejected, (state: IProjectData, action: PayloadAction<any>) => {
        state.entityAnchorSentencesDataLoader = false
      })
    builder
      .addCase(intelligentMR.pending, (state: IProjectData, action: PayloadAction<any>) => {
        state.intelligentMRLoader = true
        state.intelligentMRData = []
      })
      .addCase(intelligentMR.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        state.intelligentMRData = action.payload.response.map((item: any, index: number) => {
          return {
            ...item,
            "ID": index + 1,
            "merge_to": "",
            "rename_speaker": '',
            "change_speaker_type": "",
            "delete": false,
          }
        })
        state.intelligentMRLoader = false
      })
      .addCase(intelligentMR.rejected, (state: IProjectData, action: PayloadAction<any>) => {
      })
    builder
      .addCase(intelligentCorrection.pending, (state: IProjectData, action: PayloadAction<any>) => {
        state.intelligentCorrectionLoader = true
        state.intelligentCorrectionData = []
      })
      .addCase(intelligentCorrection.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        state.intelligentCorrectionData = action.payload.response.map((item: any, index: number) => {
          return {
            ...item,
            "ID": index + 1

          }

        })
        state.intelligentCorrectionLoader = false
      })
      .addCase(intelligentCorrection.rejected, (state: IProjectData, action: PayloadAction<any>) => {
      })
    builder
      .addCase(getParkAnchorsList.pending, (state: IProjectData, action: PayloadAction<any>) => {
      })
      .addCase(getParkAnchorsList.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        if (action.payload.response.data) {
          state.entityAnchorsParktab1 = action.payload.response?.data["PARK_ANCHOR_GROUP_1"];
          state.entityAnchorsParktab2 = action.payload.response?.data["PARK_ANCHOR_GROUP_2"];
        } else {
          state.entityAnchorsParktab1 = []
          state.entityAnchorsParktab2 = []
        }
      })
      .addCase(getParkAnchorsList.rejected, (state: IProjectData, action: PayloadAction<any>) => {
      })
    builder
      .addCase(getParkAnchorsPreQcList.pending, (state: IProjectData, action: PayloadAction<any>) => {
      })
      .addCase(getParkAnchorsPreQcList.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        if (action.payload.response.data) {

          state.entityAnchorsPreQcPark = action.payload.response?.data["PARK_ANCHOR_GROUP_1"];
        } else {
          state.entityAnchorsPreQcPark = []
        }
      })
      .addCase(getParkAnchorsPreQcList.rejected, (state: IProjectData, action: PayloadAction<any>) => {
      })
    builder
      .addCase(getParkEquivalentsList.pending, (state: IProjectData, action: PayloadAction<any>) => {
      })
      .addCase(getParkEquivalentsList.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        if (action.payload.response.data) {
          state.entityEquivalentsParktab1 = action.payload.response?.data["PARK_EQUIVALENT_GROUP_1"];
          state.entityEquivalentsParktab2 = action.payload.response?.data["PARK_EQUIVALENT_GROUP_2"];
        } else {
          state.entityEquivalentsParktab1 = [];
          state.entityEquivalentsParktab2 = []
        }
      })
      .addCase(getParkEquivalentsList.rejected, (state: IProjectData, action: PayloadAction<any>) => {
      })
    builder
      .addCase(getPreQcParkEquivalentsList.pending, (state: IProjectData, action: PayloadAction<any>) => {
      })
      .addCase(getPreQcParkEquivalentsList.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        if (action.payload.response.data) {
          state.entityEquivalentsPreQcPark = action.payload.response?.data["PARK_EQUIVALENT_GROUP_1"];
        } else {
          state.entityEquivalentsPreQcPark = [];
        }
      })
      .addCase(getPreQcParkEquivalentsList.rejected, (state: IProjectData, action: PayloadAction<any>) => {
      })

    builder
      .addCase(getDomainMaster.pending, (state: IProjectData, action: PayloadAction<any>) => {
      })
      .addCase(getDomainMaster.fulfilled, (state: IProjectData, action: PayloadAction<any>) => {
        state.domainMasterData = action.payload.response;
      })
      .addCase(getDomainMaster.rejected, (state: IProjectData, action: PayloadAction<any>) => {
      })
    builder
      .addCase(postProcessOutputState.pending, (state: any) => {
        state.postProcessOutputStateSuccess = true;
      })
      .addCase(
        postProcessOutputState.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.postProcessOutputStateSuccess = true;
          // state.ConfigureDataState = action.payload.response.data;
        }
      )
      .addCase(saveProjectConfiguration.rejected, (state: any) => {
      });
    builder
      .addCase(saveProjectConfiguration.pending, (state: any) => {
      })
      .addCase(
        saveProjectConfiguration.fulfilled,
        (state: any, action: PayloadAction<any>) => {
        }
      )
      .addCase(getProjectConfiguration.rejected, (state: any) => {
      });
    builder
      .addCase(getProjectConfiguration.pending, (state: any) => {
      })
      .addCase(
        getProjectConfiguration.fulfilled,
        (state: any, action: any) => {
          state.projectConfigData = action.payload.response?.data
        }
      )
      .addCase(postProcessOutputState.rejected, (state: any) => {
      });
    builder
      .addCase(getPreQcTabData.pending, (state: any) => {
        state.preQcTabDataLoader = true
      })
      .addCase(
        getPreQcTabData.fulfilled,
        (state: any, action: any) => {
          state.preQcTabData = action.payload.response?.data
          state.preQcTabDataLoader = false
        }
      )
      .addCase(getPreQcTabData.rejected, (state: any) => {
        state.preQcTabDataLoader = false
      });
    builder
      .addCase(getIntelligentMrQcData.pending, (state: any) => {
        state.intelligentMrQcDataLoading = true
      })
      .addCase(getIntelligentMrQcData.fulfilled, (state: any, action: any) => {
        state.intelligentMrQcDataLoading = false
        state.intelligentMrQcData = action.payload.response?.data
      }
      )
      .addCase(getIntelligentMrQcData.rejected, (state: any) => {
        state.intelligentMrQcDataLoading = false
      });
    builder
      .addCase(getSpeakersList.pending, (state: any) => { })
      .addCase(getSpeakersList.fulfilled, (state: any, action: any) => {
        state.speakersListData = action.payload.response?.data
      }
      )
      .addCase(getSpeakersList.rejected, (state: any) => {
      });
    builder
      .addCase(getTranscriptsData.pending, (state: any) => { })
      .addCase(getTranscriptsData.fulfilled, (state: any, action: any) => {
        state.transcriptsData = action.payload.response?.data
      }
      )
      .addCase(getTranscriptsData.rejected, (state: any) => {
      });
    builder
      .addCase(getCorrectedMrPreQcData.pending, (state: any) => { })
      .addCase(getCorrectedMrPreQcData.fulfilled, (state: any, action: any) => {
        state.correctedMrPreQcDataLoading = false
        state.correctedMrPreQcData = action.payload.response?.data
      }
      )
      .addCase(getCorrectedMrPreQcData.rejected, (state: any) => {
        state.correctedMrPreQcDataLoading = false
      });
    builder
      .addCase(updateMrPreQcTextBlocks.pending, (state: any) => {

      })
      .addCase(updateMrPreQcTextBlocks.fulfilled, (state: any, action: PayloadAction<any>) => {

      })
      .addCase(updateMrPreQcTextBlocks.rejected, (state: any) => {
      })
  },
});

export const { insertFile, updateMRPayload, updateIntelligentCorrectionPayload, updateUploadFileStatus, updateProjectDetails, updateProjectState, setFileUploadProgress, updateProjectName, updateProjectNameProjSetup, duplicateProjectNameStatus, updateCurrentPaginationPage, updateProjectsSearchString, updateAccountFilter, updateProjectsFilter, updateProjectsOrder, addProject, updateFavoritesProjectsOnly, UpdateProjectCreated, updateCurrentPorjectId, updateDiscussionFile,
  updateFileUploadSuccess, updateCategoryName, updateEntitiesHideTypes, updateEntitiesHideAnchors, updateEntitiesHideEquivalents, updateParkEE1, updateParkEE2, updateParkEA1, updateParkEA2, updateEntitiesQCData, updateTopicsOfInterest, insertNewMRBlock, updateEntitiesPreQcDataLoader,updateResearchTypeProjSetup,saveProjSetupPayload } =
  projectDataSlice.actions;

export default projectDataSlice.reducer;
