import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getSavedView, addSavedView, getWordCloudData, deleteSavedView } from "./action";
import { ICompareWordCloud } from "./type";

const initialState: ICompareWordCloud = {
  savedViewStateLoader: false,
  wordCloudViewStateLoader: false,
  savedViewData: [],
  deleteSavedViewStatus: false,
  addSaveViewStatus: false,
  deletedId: -1,
  selectedTopicViewVizId: null,
  selectedCompareAttr1: [],
  selectedCompareAttr2: [],
  selectedWordCloudAttr: []

};

export const compareSlice = createSlice({
  name: "compareSlice",
  initialState: initialState,
  reducers: {
    updateDeleteSavedStatus: (state, action) => {
      state.deleteSavedViewStatus = action.payload
    },
    updateSelectedTopicViewVizId: (state, action) => {
      state.selectedTopicViewVizId = action.payload
    },
    updateSelectedCompareAttr1: (state, action) => {
      state.selectedCompareAttr1 = action.payload
    },
    updateSelectedCompareAttr2: (state, action) => {
      state.selectedCompareAttr2 = action.payload
    },
    updateSelectedWordCloudAttr: (state, action) => {
      state.selectedWordCloudAttr = action.payload
    }

  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getSavedView.pending, (state: ICompareWordCloud) => {
        state.savedViewStateLoader = true;
      })
      .addCase(
        getSavedView.fulfilled,
        (state: ICompareWordCloud, action: PayloadAction<any>) => {
          state.savedViewData = action.payload.response.data;
          state.savedViewStateLoader = false;
        }
      )
      .addCase(getSavedView.rejected, (state: ICompareWordCloud) => {
        state.savedViewStateLoader = false;
      })
      .addCase(deleteSavedView.pending, (state: ICompareWordCloud) => {
      })
      .addCase(deleteSavedView.fulfilled, (state: ICompareWordCloud, action: any) => {
        state.deleteSavedViewStatus = action.payload.response.status;
        state.deletedId = action.meta.arg.id
      }
      )
      .addCase(deleteSavedView.rejected, (state: ICompareWordCloud) => {
      })
      .addCase(addSavedView.pending, (state: ICompareWordCloud) => {
      })
      .addCase(addSavedView.fulfilled, (state: ICompareWordCloud, action: PayloadAction<any>) => {
        state.addSaveViewStatus = true;
      }
      )
      .addCase(addSavedView.rejected, (state: ICompareWordCloud) => {
        state.savedViewStateLoader = false;
      })
      .addCase(getWordCloudData.pending, (state: ICompareWordCloud) => {
        state.wordCloudViewStateLoader = true;
      })
      .addCase(
        getWordCloudData.fulfilled,
        (state: ICompareWordCloud, action: PayloadAction<any>) => {
          state.wordCloudViewStateLoader = action.payload.response;
          state.wordCloudViewStateLoader = false;
        }
      )
      .addCase(getWordCloudData.rejected, (state: ICompareWordCloud) => {
        state.wordCloudViewStateLoader = false;
      });
  },
});
export const {
  updateDeleteSavedStatus,
  updateSelectedTopicViewVizId,
  updateSelectedCompareAttr1,
  updateSelectedCompareAttr2,
  updateSelectedWordCloudAttr

} = compareSlice.actions;

export default compareSlice.reducer;
