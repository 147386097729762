import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompareWordCloudAPI } from "../../service/api/compareWordCloudAPI";

export const getSavedView = createAsyncThunk("getSavedView", async ({ project_id, is_compare_view }: any, { rejectWithValue }) => {
  try {
    const response = await CompareWordCloudAPI.getSavedView({ project_id, is_compare_view });
    return {
      response: response.data,
    };
  } catch (error: any) {
    throw rejectWithValue(error.data);
  }
}
);

export const deleteSavedView = createAsyncThunk("deleteSavedView", async ({ projectId, id }: any, { rejectWithValue }) => {
  try {
    const response = await CompareWordCloudAPI.deleteSavedView({ projectId, id });
    return {
      response: response.data,
    };
  } catch (error: any) {
    throw rejectWithValue(error.data);
  }
}
);

export const addSavedView = createAsyncThunk("addSavedView", async ({ projectId, payload }: any, { rejectWithValue }) => {
  try {
    const response = await CompareWordCloudAPI.addSavedView(projectId, payload);
    return {
      response: response.data,
    };
  } catch (error: any) {
    throw rejectWithValue(error.data);
  }
}
);

export const getWordCloudData = createAsyncThunk(
  "getWordCloudData",
  async ({ project_id, payload }: any, { rejectWithValue }) => {
    try {
      const response = await CompareWordCloudAPI.getWordCloudData(project_id, payload);
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);

export const getIntersectingAreas = createAsyncThunk(
  "getIntersectingAreas",
  async ({ project_id, bucket_id }: any, { rejectWithValue }) => {
    try {
      const response = await CompareWordCloudAPI.getIntersectingAreas(project_id, bucket_id);
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);
