import { axiosconfig } from "../apiconfig"

export const domAcceptAPI = {
    getPendingDomains: () => {
        return axiosconfig.get("/pending_domains")
    },
    postDomainOutcomes: ({payload}: any) => {
        return axiosconfig.post("/approve_reject_domain", payload)
    },
    getDomains: ({is_approved}: any) => {
        return axiosconfig.get("/get_domains?is_approved=" + is_approved)
    }
}