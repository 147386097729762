import { Fragment, lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Routes, Route, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import SideNav from '../SideNav/SideNav';
import Spinner from '../Spinner';
import SiteDataContext from '../../context/siteDataContext';
import { ToastContainer } from 'react-toastify';
import OutputWrapper from '../../data-wrappers/OutputWrapper';
import ExportWrapper from '../../data-wrappers/ExportWrapper';
import PageHeader from '../Header/PageHeader';
import ManageWrapper from '../../data-wrappers/ManageWrapper';
import DashboardHeader from '../Header/DashboardHeader';
import DashSideNav from '../SideNav/DashSideNav';
import HeaderD from '../Header/HeaderD';
import { useSelector } from 'react-redux';
import Unauthorized from '../Unauthorized';
import Footer from '../Footer';
import Themes from '../../modules/Themes/Themes';
import UserDataContext from '../../context/userDataContext';
import { RootState } from '../../redux/store';
import ResetPassword from '../ResetPassword/ResetPassword';
import ExampleData from '../Example/ExampleData';
import ProfileSideNav from '../SideNav/ProfileSideNav';
import ForgotPwd from '../../modules/LoginLayout/ForgotPassword';
import DomainApproval from '../../modules/DomainApproval/DomainApproval';
import MailSent from '../ChangePassword/MailSentUI';
import MainSignUp from '../../modules/LoginLayout/MainSignUp';
import QrReRoute from '../../modules/LoginLayout/qrReRoute';
import DownloadPPT from '../../modules/DownloadPPT/DownloadPPT';
import ProdSiteDownUI from '../ProdSiteDownUI/ProdSiteDownUI';
import { decryption, secrateKey } from '../../modules/LoginLayout/LoginLayout';
import { LLMQuery } from '../../modules/LLMQuery/Layout';
import GuideHome from '../../modules/QstnGroupings/GuidePages/guideHome';
import GuideTour from '../../modules/QstnGroupings/GuidePages/guideTour';
import Entities from '../../modules/EditProject/Entities';
import Summaries from '../../modules/EditProject/Summaries';
import PublishAndMove from '../../modules/EditProject/PublishAndMove';
import Merge from '../../modules/EditProject/Merge';
import EditProject from '../../modules/EditProject/EditProject';
import ThemesEditProject from '../../modules/EditProject/Themes';
import QCSideNav from '../SideNav/QcSideNav';
import TranscriptPage from '../../modules/TranscriptTab/TranscriptPage';
import ReportandEmail from '../../modules/EditProject/ReportandEmail';
import WordCloud from '../../modules/WordCloud/WordCloud';
import Compare from '../../modules/Compare/Compare';
import ScopeEditProject from '../../modules/EditProject/ScopeEditProject';

interface IPrivateRouteProps {
  allowedRoles: string[];
}

const PrivateRoute = ({ allowedRoles }: IPrivateRouteProps) => {
  // const { userData } = useContext(UserDataContext)

  return <Outlet />

  /* return user?.roles.find((role: string) => allowedRoles.includes(role)) ? (
    <Outlet />
  ) : user?.email && user?.accessToken ? (
    <Navigate to='/unauthorized' />
  ) : (
    <Navigate to='/login' />
  ); */
};

const Layout = () => {
  const { pathname } = useLocation();
  const { userData } = useContext(UserDataContext)
  const loginSuccess = useSelector((state: RootState) => state.profile.loginSuccess)
  const tokenExpiry = useSelector((state: RootState) => state.profile.tokenExpiry)
  const featuresData = useSelector(
    (state: RootState) => state.master.featuresData
  );
  const is_ce_manager = featuresData?.includes("INTERNAL_UI_EDIT_REPROCESSING")




  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, []);
  //const favicon = siteData?.app?.favicon;
  //const docfavicon: any = document.getElementById('favicon');
  //docfavicon.href = favicon;

  const Output = lazy(() => import('../../modules/Output/Output'));
  const Manage = lazy(() => import('../../modules/Manage/Manage'));
  const ExportPpt = lazy(() => import('../../modules/ExportPpt/Layout'))
  const Dashboard = lazy(() => import('../../modules/Dashboard/Dashboard'));
  const Configure = lazy(() => import('../../modules/Configure/Configure'))
  const EditLayout = lazy(() => import('../../modules/EditComponent/EditLayout'))
  const LoginLayout = lazy(() => import('../../modules/LoginLayout/LoginLayout'))
  const MyAccount = lazy(() => import('../../modules/Profile/MyAccount'))
  const Qbqc = lazy(() => import('../../modules/QstnGroupings/Qbqc'))
  const VoiceToText = lazy(() => import('../../modules/QstnGroupings/VoiceToText'))
  const Subscription = lazy(() => import('../../modules/Profile/Subscription'))
  const Organisation = lazy(() => import('../../modules/Profile/Organisation'))
  const Credits = lazy(() => import('../../modules/Profile/Credits'))
  const HelpPage = lazy(() => import('../../modules/HelpText/HelpText'))
  const CreateProjectLayout = lazy(() => import('../../modules/CreateProject/Layout'))
  const Review = lazy(() => import('../../modules/Review/Review'))
  // const TranscriptPage = lazy(()=> import ('../../modules/TranscriptTab/TranscriptPage'))
  const navigate = useNavigate();


  // const [isAuthorized,setIsAuthorized] = useState<any>(userData?.email && userData?.accessToken)
  // const isAuthorized =  userData?.email && userData?.accessToken;
  const [isAuthorized, setIsAuthorized] = useState<any>()
  const [userName, setUserName] = useState<any>()
  let encrypted = localStorage.getItem("_U");
  useEffect(() => {
    if (encrypted) {
      let userInfo: any = decryption(encrypted, secrateKey)
      if (userInfo && userInfo !== 'undefined' && userInfo !== 'null' && userInfo !== "") {
        userInfo = JSON.parse(userInfo);
        setIsAuthorized(userInfo?.email && userInfo?.accessToken)
        setUserName(userInfo?.userName)
      }
    }
  }, [loginSuccess, userData, pathname])

  useEffect(() => {
    if (isAuthorized && pathname === '/login') {
      navigate("/dashboard")

    }
  }, [isAuthorized])


  // useEffect(()=>{
  //   if(user?.accessToken){
  //     navigate("/profile")
  //   }
  //   else{
  //     navigate("/login")
  //   }
  // },[])

  const getDataWrapper = () => {

    switch (pathname) {
      case '/':
        return <LoginLayout />
      case `/output/${pathname.split('/')[2]}`:
        return <OutputWrapper />
      case `/manage/${pathname.split('/')[2]}`:
        return <ManageWrapper />
      case `/export-ppt/${pathname.split('/')[2]}`:
        return <ExportWrapper />
      default:
        return <></>;
    }


  };

  return (
    <Fragment>
      <div className="App h-screen max-h-screen flex overflow-x-hidden">
        <div className='side-bar-section min-h-full z-50'>
          {
            isAuthorized &&
            !pathname.includes('dashboard') &&
            !pathname.includes('configure') &&
            !pathname.includes('login') &&
            !pathname.includes('changePassword') &&
            !pathname.includes('profile') &&
            !pathname.includes('createProject') &&
            !pathname.includes('subscription') &&
            !pathname.includes('userManagement') &&
            !pathname.includes('credits') &&
            !pathname.includes('domain-approval') &&
            !pathname.includes('set-password') &&
            !pathname.includes('reset-password') &&
            !pathname.includes('signUp') &&
            !pathname.includes('qrSignup') &&
            !pathname.includes('forgotPassword') &&
            !pathname.includes('prodDown') &&
            !pathname.includes('help') &&
            !pathname.includes('transcript_view') &&

            /* !pathname.includes('unauthorized') &&
            !pathname.includes('user_management') && */
            <SideNav />}

          {
            isAuthorized &&
            (pathname.includes('qc_0') ||
              pathname.includes('reporting_buckets_qc') ||
              pathname.includes('entities_qc') ||
              pathname.includes('intelligent_correction_qc') ||
              pathname.includes('THEMES_QC') ||
              pathname.includes('themes_qc')) &&
            <QCSideNav />
          }

          {isAuthorized && pathname.includes('dashboard') && <DashSideNav />}
          {isAuthorized &&
            (pathname.includes('profile') ||
              pathname.includes('subscription') ||
              pathname.includes('credits') ||
              pathname.includes('userManagement') ||
              pathname.includes('changePassword') ||
              pathname.includes('changePassword') ||
              pathname.includes('domain-approval')
            ) && <ProfileSideNav />}




        </div>
        <div className="main-section grow flex flex-col">
          {
            isAuthorized &&
            getDataWrapper()}
          {
            isAuthorized &&
            !pathname.includes('dashboard') &&
            !pathname.includes('login') &&
            !pathname.includes('profile') &&
            !pathname.includes('changePassword') &&
            !pathname.includes('credits') &&
            !pathname.includes('userManagement') &&
            !pathname.includes('subscription') &&
            !pathname.includes('domain-approval') &&
            !pathname.includes('set-password') &&
            !pathname.includes('reset-password') &&
            !pathname.includes('createProject') &&
            !pathname.includes('signUp') &&
            !pathname.includes('qrSignup') &&
            !pathname.includes('forgotPassword') &&
            !pathname.includes('prodDown') &&
            !pathname.includes('help') &&


                    /* !pathname.includes('user_management') &&
                    !pathname.includes('dashboards') && */ <Header />}

          {(isAuthorized && pathname.includes('dashboard') || pathname.includes('configure') || pathname.includes('createProject') ||
            pathname.includes('login') || (pathname.includes('reset-password') && tokenExpiry) || (pathname.includes('forgotPassword') && tokenExpiry) || pathname.includes('signUp') || pathname.includes('profile') || pathname.includes('subscription') || pathname.includes('credits') ||
            pathname.includes('userManagement') || pathname.includes('domain-approval') || pathname.includes('changePassword') || pathname.includes('help')) &&

            <HeaderD />}
          {
            isAuthorized &&
            !pathname.includes('configure') &&
            !pathname.includes('dashboard') &&
            !pathname.includes('login') &&
            !pathname.includes('profile') &&
            !pathname.includes('changePassword') &&
            !pathname.includes('createProject') &&
            !pathname.includes('subscription') &&
            !pathname.includes('credits') &&
            !pathname.includes('userManagement') &&
            !pathname.includes('domain-approval') &&
            !pathname.includes('set-password') &&
            !pathname.includes('reset-password') &&
            !pathname.includes('signUp') &&
            !pathname.includes('qrSignup') &&
            !pathname.includes('forgotPassword') &&
            !pathname.includes('prodDown') &&
            !pathname.includes('help') &&
            !pathname.includes('transcript_view') &&

                    /* !pathname.includes('user_management') &&
                    !pathname.includes('dashboards') && */ <PageHeader />}
          {
            isAuthorized &&
            pathname.includes('dashboard') &&
            <DashboardHeader />}
          <div className='grow' style={{ backgroundColor: "#f5f7fb", padding: pathname.includes('configure') || pathname.includes('login') || pathname.includes('createProject') || pathname.includes('help') ? 0 : 10 }}>
            <Suspense fallback={<Spinner />}>
              <Routes>
                <Route path="/login" element={<LoginLayout />} />
                <Route path="/forgotPassword" element={<ForgotPwd />} />
                <Route path="/signUp/:mode?" element={<MainSignUp />} />
                <Route path="/qrSignup" element={<QrReRoute />} />
                <Route path='/unauthorized' element={<Unauthorized />} />
                <Route path='/changePassword' element={<MailSent email={userName} />} />
                <Route>
                  <Route path="/" element={<LoginLayout />} />
                  <Route path="/domain-approval" element={<DomainApproval />} />
                  <Route path="/output/:id" element={<Output />} />
                  <Route path="/manage/:id" element={<Manage />} />
                  <Route path="/export-ppt/:id" element={<ExportPpt />} />
                  <Route path="/compare" element={<div></div>} />
                  <Route path="/dashboard">
                    <Route index element={<Dashboard />} />
                    <Route path='projects' element={<Dashboard />} />
                    <Route path='favourites' element={<Dashboard />} />
                    <Route path='tipsToReducePrice' element={<Dashboard />} />
                    <Route path='howToGetStarted' element={<Dashboard />} />
                  </Route>
                  <Route path="/profile" element={<MyAccount />} />
                  <Route path='/configure/:id' element={<Configure />} />
                  <Route path='/themes/:id' element={<Themes />} />
                  <Route path="/edit/:id" element={<EditLayout />} />
                  <Route path="/review/:id/*" element={<Review />} />
                  <Route path="/qbqc/:id" element={<Qbqc />} />
                  <Route path="/qbqc-guide/:id/home" element={<GuideHome />} />
                  <Route path="/qbqc-guide/:id/tour1" element={<GuideTour />} />
                  <Route path="/qbqc-guide/:id/tour2" element={<GuideTour />} />
                  <Route path="/qbqc-guide/:id/tour3" element={<GuideTour />} />
                  <Route path="/qbqc-guide/:id/tour4" element={<GuideTour />} />
                  <Route path="/qbqc-guide/:id/tour5" element={<GuideTour />} />
                  <Route path="/qbqc-guide/:id/tour6" element={<GuideTour />} />
                  <Route path="/qbqc-guide/:id/tour7" element={<GuideTour />} />
                  <Route path="/download-ppt/:id" element={<DownloadPPT />} />
                  <Route path="/edit-project/:id" element={<EditProject />} />
                  {/* <Route path="/edit-project/:id/scope" element={<Scope />} /> */}
                  <Route path="/edit-project/:id/scope" element={<ScopeEditProject />} />

                  <Route path="/edit-project/:id/entities" element={<Entities />} />
                  <Route path="/edit-project/:id/themes" element={<ThemesEditProject />} />
                  <Route path="/edit-project/:id/summaries" element={<Summaries />} />
                  <Route path='/edit-project/:id/report-and-email' element={<ReportandEmail />} />
                  <Route path="/edit-project/:id/publish-and-move" element={is_ce_manager ? <PublishAndMove /> : <></>} />
                  <Route path="/edit-project/:id/merge" element={is_ce_manager ? <Merge /> : <></>} />
                  <Route path='VoiceToText/:id' element={<VoiceToText />} />
                  <Route path='/createProject' element={<CreateProjectLayout />} />
                  <Route path='/createProject/:projectId?' element={<CreateProjectLayout />} />
                  <Route path='/createProject/project_setup/:projectId?' element={<CreateProjectLayout />} />
                  <Route path='/createProject/project_setup/:projectId?' element={<CreateProjectLayout />} />
                  <Route path='/createProject/process_output/:projectId?' element={<CreateProjectLayout />} />
                  <Route path='/createProject/validation_pipeline/:projectId?' element={<CreateProjectLayout />} />
                  <Route path='/createProject/qc_0/:projectId?' element={<CreateProjectLayout />} />
                  <Route path='/createProject/qc_0/:projectId?/mr_correction' element={<CreateProjectLayout />} />
                  {/* <Route path='/createProject/mr_correction_qc/:projectId?/intelligent_correction' element={<CreateProjectLayout />} /> */}
                  <Route path='/createProject/qc_0/:projectId?/pre_qc' element={<CreateProjectLayout />} />
                  <Route path='/createProject/qc_0/:projectId?/entities' element={<CreateProjectLayout />} />
                  <Route path='/createProject/qc_0/:projectId?/validate' element={<CreateProjectLayout />} />
                  <Route path='/createProject/entities_qc/:projectId?' element={<CreateProjectLayout />} />
                  <Route path='/createProject/intelligent_correction_qc/:projectId?' element={<CreateProjectLayout />} />
                  <Route path='/createProject/processing_pipeline/:projectId?' element={<CreateProjectLayout />} />
                  <Route path='/createProject/reporting_buckets_qc/:projectId?' element={<CreateProjectLayout />} />
                  <Route path='/createProject/review_pipline/:projectId?' element={<CreateProjectLayout />} />
                  <Route path='/createProject/THEMES_QC/:projectId?' element={<CreateProjectLayout />} />
                  <Route path='/createProject/payment_confirmation/:projectId?' element={<CreateProjectLayout />} />
                  <Route path='/createProject/payment_pending/:projectId?' element={<CreateProjectLayout />} />
                  <Route path='/createProject/payment_pending/:projectId/:seasionId' element={<CreateProjectLayout />} />
                  <Route path='/createProject/example' element={<ExampleData />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/set-password" element={<ResetPassword />} />
                  <Route path="/prodDown" element={<ProdSiteDownUI />} />
                  <Route path='/help' element={<HelpPage />} />
                  <Route path="/transcript_view/:projectId/:id" element={<TranscriptPage />} />
                  <Route path="/subscription" element={<Subscription />} />
                  <Route path="/usermanagement" element={<Organisation />} />
                  <Route path="/credits/:id" element={<Credits />} />
                  <Route path="/credits" element={<Credits />} />
                  <Route path="/query/:id" element={<LLMQuery />} />
                  <Route path="/wordcloud/:id" element={<WordCloud />} />
                  <Route path="/compare/:id" element={<Compare />} />
                </Route>
              </Routes>
            </Suspense>
          </div>
          <div className="bottom-0 w-full">
            {
              (isAuthorized &&
                !pathname.includes('unauthorized') &&
                !pathname.includes('dashboard') &&
                !pathname.includes('login') &&
                !pathname.includes('signUp') &&
                !pathname.includes('qrSignup') &&
                !pathname.includes('forgotPassword') &&
                !pathname.includes('domain-approval') &&
                // !pathname.includes('reset-password') &&
                !pathname.includes('set-password') &&
                !pathname.includes('prodDown') &&
                !pathname.includes('help')
                || (pathname.includes('reset-password') && tokenExpiry)
                || (pathname.includes('forgotPassword') && tokenExpiry))
              &&
              <Footer />
            }
          </div>
        </div>

        {isAuthorized &&
          !pathname.includes('unauthorized') &&
          !pathname.includes('user_management')}
        <ToastContainer
          position='top-center'
          autoClose={5000}
          hideProgressBar={false}
        />
      </div>
    </Fragment>
  );
};

export default Layout;
