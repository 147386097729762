import { useEffect, useState } from "react"

interface ISummaryViewLabel {
    data: any
    expandAll: boolean
}

export default function SummaryViewLabel(props: ISummaryViewLabel) {

    const [showAllSumm, setShowAllSumm] = useState<boolean>(props.expandAll)

    function getData(data: string) {
        if (!showAllSumm && data?.length > 260) {
            return (data.slice(0, 260) + "...")
        }
        else {
            return data
        }
    }

    useEffect(() => {
        setShowAllSumm(props.expandAll)
    }, [props.expandAll])

    return (
        <div className="rounded-sm text-left border border-gray-300 p-2.5 w-full" style={{ borderRadius: "3px" }}>
            <div className="uppercase text-blue-400 w-full">{props.data?.name}</div>
            <div className="w-full">
                <span >{getData(props.data?.summary)}</span>
                {props.data?.summary.length > 260 && <span className="text-blue-400 cursor-pointer whitespace-nowrap" onClick={() => setShowAllSumm(!showAllSumm)}>{showAllSumm ? " - see less" : " + see more"}</span>}
            </div>
        </div>
    )
}