import { createAsyncThunk } from "@reduxjs/toolkit";
import { LLMAPI } from "../../service/api/llm";

export const ChatStatus = createAsyncThunk("ChatStatus",
    async ({ project_id }: any, { rejectWithValue }) => {
        try {
            const response = await LLMAPI.ChatStatus({
                project_id
            })
            return {
                response: response.data
            }
        } catch (error: any) {
            throw rejectWithValue(error.data)
        }
    }
)

export const ChatStart = createAsyncThunk("ChatStart",
    async ({ project_id }: any, { rejectWithValue }) => {
        try {
            const response = await LLMAPI.ChatStart({
                project_id
            })
            return {
                response: response.data
            }
        } catch (error: any) {
            throw rejectWithValue(error.data)
        }
    }
)
export const ChatQuery = createAsyncThunk("ChatQuery",
    async ({ session_id, query }: any, { rejectWithValue }) => {
        try {
            const response = await LLMAPI.ChatQuery({
                session_id, query
            })
            return {
                response: response.data
            }
        } catch (error: any) {
            throw rejectWithValue(error.response.data)
        }
    }
)
export const ChatPopulation = createAsyncThunk("ChatPopulation",
    async ({ project_id }: any, { rejectWithValue }) => {
        try {
            const response = await LLMAPI.ChatPopulation({
                project_id
            })
            return {
                response: response.data
            }
        } catch (error: any) {
            throw rejectWithValue(error.data)
        }
    }
)