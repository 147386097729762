import { useSelector } from "react-redux";
import EditMode from "./EditMode";
import ViewMode from "./ViewMode";
import { RootState, useAppDispatch } from "../../redux/store";
import { useEffect, useRef, useState } from "react"
import { getQuestionBucket } from "../../redux/Masters/action";
import { useParams } from "react-router-dom";
import { updateSelectedMode } from "../../redux/Themes/themesDataReducer";

export default function Themes() {

    const dispatch = useAppDispatch()
    const id = useParams().id

    const Mode = useSelector((state: RootState) => state.themes.selectedMode)

    const [selectedSentiment, setSelectedSentiment] = useState<string>("Overall")
    const [mainHeight, setMainHeight] = useState<number>(0)
    const mainRef = useRef<any>(null)

    useEffect(() => {
        dispatch(getQuestionBucket({ project_id: id }))
        dispatch(updateSelectedMode("View"))
    }, [])

    useEffect(() => {
        const topPos = mainRef.current?.getBoundingClientRect().top;
        setMainHeight(window.innerHeight - topPos - 110);
    });

    return (
        <div className="w-full h-full" ref={mainRef}>
            <div className="flex justify-between items-center">
                <div className="flex justify-start items-center text-sm px-2 pt-1">
                    <div className="font-semibold text-gray-500 text-base mr-4">Switch to</div>
                    <div className={`cursor-pointer   border  py-1 px-6 rounded-l ${selectedSentiment === "Overall" ? "text-[#0077B6] bg-[#DFEDF5] border-[#0077B6] font-semibold" : " border-gray-400 text-gray-600"}`} onClick={() => setSelectedSentiment("Overall")}>
                        <span className="px-3">Overall</span>
                    </div>
                    <div className={`cursor-pointer border-t border-b   py-1 px-6 ${selectedSentiment === "Positive" ? "text-[#0077B6] bg-[#DFEDF5] border-[#0077B6] font-semibold" : "border-gray-400 text-gray-600"}`} onClick={() => setSelectedSentiment("Positive")}>
                        <span className="px-3">Positive</span>
                    </div>
                    <div className={`cursor-pointer border   py-1 px-6 rounded-r ${selectedSentiment === "Negative" ? "text-[#0077B6] bg-[#DFEDF5] border-[#0077B6] font-semibold" : "border-gray-400 text-gray-600"}`} onClick={() => setSelectedSentiment("Negative")}>
                        <span className="px-3">Negative</span>
                    </div>
                </div>
            </div>
            <div className="w-full rounded-md mt-1.5 px-1 py-1.5" style={{ height: mainHeight }}>
                {Mode === 'View' ?
                    <ViewMode project_id={id} selectedSentiment={selectedSentiment} />
                    :
                    <EditMode project_id={id} selectedSentiment={selectedSentiment} />
                }
            </div>
        </div>

    )
}