import Snackbar from '@mui/material/Snackbar';
import {
    Alert,
    AlertColor,
} from "@mui/material";
import Button from '@mui/material/Button';

import { RootState, useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import { setIsOpenFalse } from '../redux/Notification/notificationReducer';


function Notification() {

    const isSnackBarOpen = useSelector((state: RootState) => state.notification.isOpen);
    const durationOpen = useSelector((state: RootState) => state.notification.duration);
    const message = useSelector((state: RootState) => state.notification.message);
    const type = useSelector((state: RootState) => state.notification.type);
    const anchorOrigin = useSelector((state: RootState) => state.notification.anchorOrigin)
    const dispatch = useAppDispatch();

    return (
        <Snackbar
            open={isSnackBarOpen}
            autoHideDuration={durationOpen}
            onClose={() => dispatch(setIsOpenFalse())}
            anchorOrigin={anchorOrigin}
        >
            <Alert severity={type as AlertColor} action={
                <Button color="inherit" size="small" onClick={() => dispatch(setIsOpenFalse())}>
                    Close
                </Button>
            }>{message}</Alert>
        </Snackbar>
    )
}

export default Notification;
