import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './editor.css'
import { Skeleton } from '@mui/material';
import { useAppDispatch } from '../../redux/store';
import { getTranscriptBlocks } from '../../redux/Themes/action';
import { ReceiptLongOutlined } from '@mui/icons-material';


interface TranscriptViewProps {
  projectId?: any
  transcript: any
  selectedSentence: {
    id: string,
    color: string
    questionId: string
  }
  sentenceIds: {
    id: string,
    color: string
  }[]
  setTranscriptName?: any
  setQuestionId?: any
}


const TranscriptView = ({ transcript, selectedSentence, sentenceIds, projectId, setTranscriptName, setQuestionId }: TranscriptViewProps) => {

  const dispatch = useAppDispatch()

  const [selectedTextId, setSelectedTextId] = useState("")
  const scrollDiv = useRef<any>(null)
  const textInput = useRef(null);

  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {//UseEffect to attach and remove the eventLister for scrollEnd event after every scroll action
    let scrollableComponent = scrollDiv.current;
    function onScroll() {
      let currentPosition = scrollableComponent.pageYOffset; // or use document.documentElement.scrollTop;
      if (currentPosition > scrollTop) {
        // downscroll code
        setScrolling(false);
      } else {
        // upscroll code
        setScrolling(true);
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
      if (selectedSentence.questionId !== "") {
        setQuestionId("") //this condition is to stop the automatic scroll to the selected sentence question once manual scroll is performed
      }
      handleScroll()
    }
    scrollableComponent.addEventListener("scrollend", onScroll);
    return () => scrollableComponent.removeEventListener("scrollend", onScroll);
  }, [scrollTop, transcript]);

  const textEditorOnChange = (e: any, id: any) => {
    setSelectedTextId(id);
  }

  const scroll = (id: any) => {
    const section = document.getElementById(id)
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleScroll = () => {
    const scrollableComponent = scrollDiv.current;
    const contents = scrollableComponent.getElementsByClassName('content');
    const scrollPosition = scrollableComponent.scrollTop;
    const viewportHeight = window.innerHeight;
    const visibleElements = [];

    for (const content of contents) {
      const elementTop = content.offsetTop;
      const elementHeight = content.clientHeight;

      const elementBottom = elementTop + elementHeight;
      const isInViewport = elementTop < scrollPosition + viewportHeight && elementBottom > scrollPosition;

      if (isInViewport) {
        visibleElements.push(content.className.split(" ")[0]); //push the indexes of the div's on components viewport
      }
    }
    setTranscriptName(transcript[visibleElements[0]]?.data.dataset_name)

    for (const element of visibleElements) {
      let item = parseInt(element)
      if (Object.keys(transcript[item]?.data).length === 0) {
        dispatch(getTranscriptBlocks({ projectId: projectId, offset: item + 1, limit: 8 })) //Make an API call for the first div to not have any data
        break
      }
    }
  };

  useEffect(() => {
    if (selectedSentence?.questionId && selectedSentence?.questionId !== "") {
      scroll(selectedSentence.questionId.replaceAll('-', ''))
    }
  }, [selectedSentence])

  return (
    <div ref={scrollDiv} className="h-full w-full overflow-y-auto" >
      {
        transcript.map((each: any) => {
          if (Object.keys(each.data).length === 0) {
            return (
              <div className={`${each.index} content w-full my-1 px-5 py-2`} key={each.index} id={`${each.index}`}>
                {transcript.length > 3000 ?
                  <>
                    <div className='w-4/5 h-[30px] bg-gray-100 rounded-md mb-1.5'></div>
                    <div className='w-11/12 h-[60px] bg-gray-100 rounded-md ml-5'></div>
                  </>
                  :
                  <>
                    <Skeleton className='mb-1.5' variant="rounded" width={"85%"} height={"30px"} />
                    <Skeleton className='ml-5' variant="rounded" width={"95%"} height={"60px"} />
                  </>
                }
              </div>
            )
          }
          else {
            let content = `<div>
              <b style="background-color:'#FFFFFF'};">
                  ${each.data.question_type_identifier}:  ${each.data.question_text}
              </b>
          </div>`
            let response = "";
            let sortedResponses = each.data?.responses.map((item: any) => {
              let tempSent = item?.sentence;
              if (item?.sentence) {
                tempSent = JSON.parse(item?.sentence).sort((a: any, b: any) => a.SENTENCE_RANK - b.SENTENCE_RANK)
              }
              return {
                ...item,
                sentence: tempSent
              }
            })
            sortedResponses?.map((r: any) => {
              let sentences = r.sentence //? JSON.parse(r.sentence) : []
              let sentenceArray = ""
              sentences?.forEach((element: any) => {
                let sentenceColor: string = '#FFFFFF'
                if (selectedSentence.id == element.SENTENCE_ID) {
                  sentenceColor = selectedSentence.color;
                }
                else {
                  sentenceColor = sentenceIds.find((ele: any) => ele.id == element.SENTENCE_ID)?.color ?? '#FFFFFF';
                }
                sentenceArray += `<span style="background-color: ${(sentenceColor) || '#FFFFFF'}">${element.SENTENCE}</span>`
              });
              response += `<div class="ql-indent-1">
              <span>
                <b>${r.response_type_identifier}</b>: 
                ${sentenceArray}
              </span>
            </div>`

            });
            content += response;

            return (
              <>
                {each.index === 0 ?
                  <div className="px-1 py-1.5 bg-white" style={{ position: "sticky", top: "0", zIndex: 10 }} >
                    <ReceiptLongOutlined />
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "black",
                      }}
                      className="text-left ml-2  font-bold w-full  pr-2"
                    >
                      {each.data.dataset_name}
                    </span>
                    <hr className="border-b-2 border-[#9747FF] w-full mt-2" />
                  </div>
                  :
                  transcript[each.index].data?.dataset_name !== transcript[each.index - 1].data?.dataset_name &&
                  <div className="px-1 py-1 bg-white" style={{ position: "sticky", top: "0", zIndex: 10 }} >
                    <ReceiptLongOutlined />
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "black",
                      }}
                      className="text-left ml-2  font-bold w-full  pr-2"
                    >
                      {each.data.dataset_name}
                    </span>
                    <hr className="border-b-2 border-[#9747FF] w-full mt-2" />
                  </div>
                }
                <div className={`${each.index} content`} key={each.index} id={`${each.data.question_id.replaceAll("-", "")}`}>
                  <ReactQuill theme="snow"
                    ref={textInput}
                    value={content}
                    modules={{ "toolbar": false }}
                    readOnly={true}
                    onChangeSelection={(e) => { textEditorOnChange(e, each.data.id) }}
                  />
                </div>
              </>
            )
          }
        })
      }
    </div>
  )
}
export default TranscriptView;