import { createAsyncThunk } from "@reduxjs/toolkit";
import { FocusApi } from "../../service/api/focusAPI";
import { openSnackbar } from "../Notification/notificationReducer";
import { getNotes } from "../Masters/action";
import { startNotesLoader } from "../Masters/masterDataReducer";

export const reportingQB = createAsyncThunk("reportingQB", async ({ project_id, sentiment, question_bucket_id }: any, { rejectWithValue }) => {
  try {
    const response = await FocusApi.reportingQB({ project_id, question_bucket_id })

    return {
      response: response.data
    }
  } catch (error: any) {
    throw rejectWithValue(error.data);
  }
})

export const getThemes = createAsyncThunk("getThemes", async ({ project_id, sentiment, question_bucket_id }: any, { rejectWithValue }) => {
  try {
    const response = await FocusApi.getThemes({ project_id, question_bucket_id, sentiment })

    return {
      response: response.data
    }
  } catch (error: any) {
    throw rejectWithValue(error.data);
  }
})


export const entities = createAsyncThunk("entities", async ({ project_id, sentiment, question_bucket_id }: any, { rejectWithValue }) => {
  try {
    const response = await FocusApi.entities({ project_id })

    return {
      response: response.data
    }
  } catch (error: any) {
    throw rejectWithValue(error.data);
  }
})

export const sentenceHide = createAsyncThunk("sentenceHide", async ({ payload }: any, { rejectWithValue, dispatch }) => {
  try {
    dispatch(startNotesLoader(true))
    const response = await FocusApi.sentenceHide({ payload })
    if (response.data.status) {
      dispatch(getNotes({ projectId: payload.project_id }))
    }
    return response.data;
  }
  catch (error: any) {
    throw rejectWithValue(error.data);
  }
})

export const mergeThemes = createAsyncThunk("mergeThemes", async ({ projectId, payload, sentiment, selectedQb, currentName }: any, { dispatch, rejectWithValue }) => {
  try {
    dispatch(startNotesLoader(true))
    const response = await FocusApi.mergeThemes({ projectId, payload })
    if (response.data.status) {
      // dispatch(reportingQB({project_id: projectId, sentiment: sentiment, question_bucket_id: selectedQb.question_bucket_id}))
      dispatch(getNotes({ projectId: projectId }))
      return response.data;
    }
    else {
      dispatch(openSnackbar({ message: response.data?.data.message, type: "error" }))
      return response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data);
  }
})

export const updateSentimentThemes = createAsyncThunk("updateSentimentThemes", async ({ project_id, theme_id, sentiment }: any, { rejectWithValue }) => {
  try {
    const response = await FocusApi.updateSentimentThemes({ project_id, theme_id, sentiment })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data);
  }
})

export const getSentenceMatrix = createAsyncThunk("getSentenceMatrix", async ({ projectId, questionBucketId, themesId, entityId }: any, { rejectWithValue }) => {
  try {
    const response = await FocusApi.getSentenceMatrix({ projectId, questionBucketId, themesId, entityId })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data);
  }
})


export const updateSentenceSentiment = createAsyncThunk("updateSentenceSentiment", async ({ project_id, sentiment, sentenceId }: any, { rejectWithValue }) => {
  try {
    const response = await FocusApi.updateSentenceSentiment({ project_id, sentiment, sentenceId })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getSentenceBubbleData = createAsyncThunk("getSentenceBubbleData", async ({ project_id, bucket_id, bucket_type, filter_payload }: any, { rejectWithValue }) => {
  try {
    const response = await FocusApi.getSentenceBubbleData({ project_id, bucket_id, bucket_type, filter_payload })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const updateBubbleBookMark = createAsyncThunk("updateBubbleBookMark", async ({ project_id, bucket_id, bubble_bucket_id, is_bookmarked }: any, { rejectWithValue }) => {
  try {
    const response = await FocusApi.updateBubbleBookMark({ project_id, bucket_id, bubble_bucket_id, is_bookmarked })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})