import { axiosconfig } from "../../service/apiconfig"

export const VireGroupAPI = {
    getALLGroups: ({ project_id }: any) => {
        return axiosconfig.get("projects/" + project_id + "/groups");
    },
    getGroupQuestionAndAnswers: ({ project_id, group_ids, offset, limit, keyword }: any) => {
        let params:any= {
            offset:offset,
            limit:limit
        }
        if (keyword){
            params.keyword = keyword
        }
        return axiosconfig.post(
            `projects/${project_id}/groups/questionresponses`, 
            group_ids,
            {params:params});
    },
    combineQuestionBuckets: ({ project_id, question_bucket_name, question_bucket_id }: any) => {
        return axiosconfig.post(
            `projects/${project_id}/groups/action=combine_buckets`, 
            question_bucket_id,
            {params:{
                question_bucket_name:question_bucket_name
            }}
        )
    },
    deleteQuestionBuckets: ({ project_id ,question_bucket_id}: any) => {
        return axiosconfig.delete("projects/" + project_id + "/groups/delete_buckets",{'data':question_bucket_id});
    },
    excludeAllUngrouped: ({ project_id }: any) => {
        return axiosconfig.post("projects/" + project_id + "/groups/exclude_all_ungrouped", {});
    },
    makeUngroupedBuckets: ({ project_id }: any) => {
        return axiosconfig.post("projects/" + project_id + "/groups/make_ungrouped_buckets", {});
    },
}