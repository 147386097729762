import axios, { AxiosResponse } from "axios";
import { getAccessToken, removeTokens } from "../utils/localStorage";
import packagejson from "../../package.json";
import store from "../redux/store";
import { openSnackbar } from "../redux/Notification/notificationReducer";

import settings from "../config";

const versions:any=`version=${packagejson?.version}`

const addingVresions=(endpoint:any)=>{
if(endpoint.includes('?')){
  return `${endpoint}&${versions}`
}else{
  return `${endpoint}?${versions}`
}
}

export const axiosconfig = {
  indexedArray: {},
  post: (endpoint: any, data: any, config: any = {}) =>
    axiosInstance.post(addingVresions(endpoint), data, config),
  get: (endpoint: any, data?: any) => axiosInstance.get(addingVresions(endpoint), data),
  delete: (endPoint: any, data?: any) => axiosInstance.delete(addingVresions(endPoint), data),
  put: (endPoint: any, data?: any) => axiosFileInstance.put(addingVresions(endPoint), data),
  getFile: (endPoint: any, config?: any) =>
    axiosFileDownloadInstance(addingVresions(endPoint), config),
  llm: (endpoint: any, data: any, config: any = {}) =>axiosLLMInstance.post(addingVresions(endpoint), data, config),

  // post: function (f1: any, f2: any) {
  //   let x = JSON.stringify(f1) + JSON.stringify(f2)
  //   if (!cacheService.isOnCache(x)) {
  //     cacheService.addToCache(x, axiosInstance.post(f1, f2));
  //     return cacheService.getFromCache(x);
  //   }
  //   else {
  //     return cacheService.getFromCache(x);
  //   }
  // },

  // get: function (f1: any) {
  //   let x = JSON.stringify(f1)
  //   if (cacheService.isOnCache(x) !== true) {
  //     cacheService.addToCache(x, axiosInstance.get(f1));
  //     return cacheService.getFromCache(x);
  //   }
  //   else {
  //     return cacheService.getFromCache(x);
  //   }
  // }
};

export const axiosInstance = axios.create({
  baseURL: settings.REACT_APP_API_URL,
  // baseURL: "https://sanofimce-2023-test-api.graphenesvc.com/api/v1",
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosLLMInstance = axios.create({
  
  baseURL: settings.REACT_APP_LLM_API,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosLLMInstance.interceptors.request.use(
  function (config: any) {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error: any) {
    return Promise.reject(error.response);
  }
);

export const axiosFileInstance = axios.create({
  baseURL: settings.REACT_APP_API_URL,
  headers: {
    "Content-Disposition": "attachment; filename=template.xlsx",
    "Content-Type": "application/json",
  },
  responseType: "arraybuffer",
});

axiosInstance.interceptors.request.use(
  function (config: any) {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },

  function (error: any) {
    return Promise.reject(error.response);
  }
);

axiosInstance.interceptors.response.use(
  function (response: AxiosResponse) {
    return response;
  },

  function (error: any) {
    if (error?.response?.status === 401 
      && !window.location.href.includes('signup')
       && !window.location.href.includes('set-password') 
       && !window.location.href.includes('reset-password')
       && !window.location.href.includes('forgotPassword')) {
        removeTokens();
        if (!window.location.href.includes("login")) {
          store.dispatch(openSnackbar({message:"Session expired. Please login again.", type:"error",anchorOrigin:{vertical:"top",horizontal:"center"}}))
          localStorage.clear();
          setTimeout(() => {
            window.location.href = window.location.origin + "/login";
          }, 1000);
      }
    }
    return Promise.reject(error.response);
  }
);

axiosFileInstance.interceptors.request.use(
  function (config: any) {
    const accessToken = getAccessToken();
  
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },

  function (error: any) {
    return Promise.reject(error.response);
  }
);

axiosFileInstance.interceptors.response.use(
  function (response: AxiosResponse) {
    return response;
  },

  function (error: any) {
    return Promise.reject(error.response);
  }
);

export const axiosFileDownloadInstance = axios.create({
  baseURL: settings.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json; application/octet-stream",
  },
  responseType: "blob",
});

axiosFileDownloadInstance.interceptors.request.use(
  function (config: any) {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error: any) {
    return Promise.reject(error.response);
  }
);
