import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addQuestionBucket,
  combineQuestionBuc,
  editQuestionBuc,
  getAssociations,
  getConfigurePPt,
  getConfigureProfiles,
  addConfigureProfile,
  deleteProfile,
  updateConfigureProfile,
  getAttributes,
  getExportState,
  getExportPptReportsData,
  getMentions,
  getQBPhrases,
  getQuestionBucket,
  hideUnhidePhrase,
  hideUnhideQB,
  renameAssociation,
  triggerExportData,
  resetData,
  backupData,
  getMasterWordCloudData,
  savePptConfiguration,
  getDeepDiveConfiguration,
  saveDeepDiveConfiguration,
  updatePPTSelectionValue,
  getFeatures,
  getNotes,
  getPptTemplates,
} from "./action";
import { IMasterData } from "./type";

const initialState: IMasterData = {
  loaded: false,
  data: {},
  error: "",
  question_bucket: [],
  viewBucketsLoader: false,
  formattedQbs: [],
  selectedQuestionBuc: "All",
  masterSentiment: ["positive", "negative", "neutral"],
  selectedAttr: null,
  associationList: [],
  associationListLoader: false,
  mentionList: [],
  mentionListLoader: false,
  allPhraseSent: [],
  phraseCount: 0,
  qbPhrasesLoader: false,
  masterAttributes: [],
  combineQBSuccess: false,
  hideUnhideQBSuccess: false,
  hideUnhidePhraseSuccess: false,
  renameAssocSuccess: false,
  paginationLimit: 30,
  currentPage: 1,
  addQBSuccess: false,
  resetStatus: false,
  backupStatus: false,
  addQuestBucSuccessMessage: false,
  exportState: -2,
  exportPptReportsData: [],
  exportPptReportsDataIsEmpty: false,
  masterWordCloudData: [],
  configurePpt: [],
  configureProfiles: [],
  createProfileSuccess: false,
  configurePptLoader: false,
  deleteProfileSuccess: false,
  updateConfigureProfileSuccess: false,
  deepDiveConfiguration: [],
  deepDiveConfigurationLoading: false,
  updatePPTSelectionValueSuccess: false,
  profileErrorState: null,
  showHowToGetStarted: false,
  showTipsToReduceCost: false,
  featuresData: [],
  notes: {},
  selectedRBNote: null,
  pptTemplates: [],
  defaultPptTemplate: null,
  showPptInfo: false,
  notesLoader: false,
  subscriptionDetails:[],
  getSentencesPayload:{},
  getSentenceCountToken: null,
  app_config : []
};

const includesValue = (searchFor: string, array: Array<any>) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i] === searchFor) {
      return true;
    }
  }
  return false;
};

export const masterDataSlice = createSlice({
  name: "masterdata",
  initialState: initialState,
  reducers: {
    updateSelectedQB: (state, action) => {
      state.selectedQuestionBuc = action.payload;
    },
    updateMasterSentiment: (state, action) => {
      state.masterSentiment = action.payload;
    },
    updateSelectedAttr: (state, action) => {
      state.selectedAttr = action.payload;
    }, updateMasterAttributes: (state) => {
      state.masterAttributes = [];
    },
    updateCombineQBSuccess: (state, action) => {
      state.combineQBSuccess = action.payload;
    },
    updateHideUnhideQBSuccess: (state, action) => {
      state.hideUnhideQBSuccess = action.payload;
    },
    updateRenameAssocSuccess: (state, action) => {
      state.renameAssocSuccess = action.payload;
    },
    updateHideUnhidePhraseSuccess: (state, action) => {
      state.hideUnhidePhraseSuccess = action.payload;
    },
    updateCurrentPaginationPage: (state, action) => {
      state.currentPage = action.payload;
    },
    updateAddQBSuccess: (state, action) => {
      state.addQBSuccess = action.payload;
    },
    updateconfigInfoState: (state, action) => {
      state.showPptInfo = action.payload;
    },
    updateAddQuestBucSuccessMessage: (state, action) => {
      state.addQuestBucSuccessMessage = action.payload;
    },
    updateFileExportState: (state, action) => {
      state.exportState = action.payload;
    },
    updateFileExportPptReportsData: (state, action) => {
      state.exportPptReportsData = action.payload;
    },
    updateAddConfigureProfile: (state, action) => {
      state.createProfileSuccess = action.payload;
    },
    updateDeleteProfile: (state, action) => {
      state.deleteProfileSuccess = action.payload;
    },
    updateEditConfigureProfile: (state, action) => {
      state.updateConfigureProfileSuccess = action.payload;
      state.updatePPTSelectionValueSuccess = action.payload;
    },
    updateShowHowToGetStarted: (state, action) => {
      state.showHowToGetStarted = action.payload;
    },
    updateShowTipsToReduceCost: (state, action) => {
      state.showTipsToReduceCost = action.payload;
    },
    updateErrorProfileMsg: (state, action) => {
      state.profileErrorState = action.payload;
    },
    updateFeaturesData: (state, action) => {
      state.featuresData = action.payload;
    },
    getAppConfig: (state, action) => {
      state.app_config = action.payload;
    },

    updateNotes: (state, action) => {
      let temp = JSON.parse(JSON.stringify(state.notes));
      if (action.payload.id === "general") {
        temp.general_notes = action.payload.data;
      }
      else {
        switch (action.payload.type) {
          case 'Reporting Buckets':
            temp.question_bucket_notes.data = temp.question_bucket_notes.data.map(
              (item: any) => {
                if (item.id === action.payload.id) {
                  return { ...item, notes: action.payload.data };
                } else {
                  return item;
                }
              }
            )
            break;
          case 'Themes':
            temp.themes.data = temp.themes.data.map(
              (item: any) => {
                if (item.id === action.payload.id) {
                  return { ...item, notes: action.payload.data };
                } else {
                  return item;
                }
              }
            )
            break;
          default:
            let entity_index = temp.entities.findIndex((item: any) => item.type === action.payload.type)
            temp.entities[entity_index].data = temp.entities[entity_index].data.map(
              (item: any) => {
                if (item.id === action.payload.id) {
                  return { ...item, notes: action.payload.data };
                } else {
                  return item;
                }
              }
            );
        }
      }
      state.notes = temp;
    },
    updateSelectedRBNote: (state, action) => {
      state.selectedRBNote = action.payload;
    },
    startNotesLoader: (state, action) => {
      state.notesLoader = action.payload
    },
    updateSentencesPayload: (state, action) => {
      state.getSentencesPayload = action.payload
    },
    updateSentencesCountToken: (state, action) => {
      state.getSentenceCountToken = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionBucket.pending, (state: IMasterData) => { })
      .addCase(
        getQuestionBucket.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.question_bucket = action.payload.response.data;
          //Question Bucket Formatting
          let data = action.payload.response.data;
          let displayNames: any = [];
          let formattedData: any = [];
          data.map((item: any) => {
            if (!includesValue(item.display_name, displayNames)) {
              displayNames.push(item.display_name);
            }
          });
          displayNames.map((item: any) => {
            let phrase_count = 0;
            let question_count = 0;
            let active = true;
            let temp = data
              .filter((elem: any) => elem.display_name === item)
              .map((bub: any) => {
                phrase_count += bub.phrase_count;
                active = bub.active;
                question_count += bub.question_count;
                return {
                  question_bucket_name: bub.question_bucket_name,
                  question_bucket_id: bub.question_bucket_id,
                };
              });
            formattedData.push({
              display_name: item,
              phrase_count: phrase_count,
              active: active,
              question_buckets: temp,
              question_count: question_count,
            });
          });
          state.formattedQbs = action.payload.response.data;
        }
      )
      .addCase(getQuestionBucket.rejected, (state: IMasterData) => { });

    builder
      .addCase(
        addQuestionBucket.pending,
        (state: IMasterData, action: PayloadAction<any>) => { }
      )
      .addCase(
        addQuestionBucket.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          if (action.payload.response.status == true) {
            let data1 = JSON.parse(JSON.stringify(state.formattedQbs));
            data1.push({
              display_name: action.payload.question_bucket_name,
              phrase_count: 0,
              question_count: 0,
              active: true,
              question_buckets: [
                {
                  question_bucket_name: action.payload.question_bucket_name,
                  question_bucket_id: action.payload.response.data,
                },
              ],
            });
            state.formattedQbs = data1;
            state.addQBSuccess = true;
          }
          if (action.payload.response.status == false) {
            state.addQuestBucSuccessMessage = true;
          }
        }
      )
      .addCase(
        addQuestionBucket.rejected,
        (state: IMasterData, action: PayloadAction<any>) => { }
      );
    builder
      .addCase(getAssociations.pending, (state: IMasterData) => {
        state.associationListLoader = true;
      })
      .addCase(
        getAssociations.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.associationList = action.payload.response.data;
          state.associationListLoader = false;
        }
      )
      .addCase(getAssociations.rejected, (state: IMasterData) => {
        state.associationListLoader = false;
      });
    builder
      .addCase(getConfigurePPt.pending, (state: IMasterData) => {
        state.configurePptLoader = true;
      })
      .addCase(
        getConfigurePPt.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.configurePptLoader = false;
          state.configurePpt = action.payload.response.data;
        }
      )
      .addCase(getConfigurePPt.rejected, (state: IMasterData) => {
        state.configurePptLoader = false;
      });
    builder
      .addCase(getConfigureProfiles.pending, (state: IMasterData) => { })
      .addCase(
        getConfigureProfiles.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.configureProfiles = action.payload.response.data;
        }
      )
      .addCase(getConfigureProfiles.rejected, (state: IMasterData) => { });
    builder
      .addCase(addConfigureProfile.pending, (state: IMasterData) => {
        state.profileErrorState = null;
      })
      .addCase(
        addConfigureProfile.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.createProfileSuccess = action.payload.response.status;
          state.configureProfiles = {
            ...state.configureProfiles,
            profiles: [
              action.payload.response.data,
              ...state.configureProfiles.profiles,
            ],
          };
        }
      )
      .addCase(
        addConfigureProfile.rejected,
        (state: IMasterData, action: any) => {
          state.profileErrorState = action.payload.data;
        }
      );
    builder
      .addCase(deleteProfile.pending, (state: IMasterData) => { })
      .addCase(deleteProfile.fulfilled, (state: IMasterData, action: any) => {
        state.deleteProfileSuccess = action.payload.response.status;
        const updatedList = state.configureProfiles.profiles.filter(
          (each: any) => each.ID !== action.meta.arg.profile_id
        );
        state.configureProfiles = {
          ...state.configureProfiles,
          profiles: updatedList,
        };
      })
      .addCase(deleteProfile.rejected, (state: IMasterData) => { });
    builder
      .addCase(updateConfigureProfile.pending, (state: IMasterData) => {
        state.profileErrorState = null;
      })
      .addCase(
        updateConfigureProfile.fulfilled,
        (state: IMasterData, action: any) => {
          const updatedList = state.configureProfiles.profiles.map(
            (each: any) => {
              if (each.ID === action.meta.arg.profile_id) {
                return {
                  ...each,
                  NAME: action.meta.arg.updatedData.name,
                  ATTRIBUTES: action.meta.arg.updatedData.attributes,
                };
              } else {
                return { ...each };
              }
            }
          );
          state.configureProfiles = {
            ...state.configureProfiles,
            profiles: updatedList,
          };
          state.updateConfigureProfileSuccess = action.payload.response.status;
        }
      )
      .addCase(
        updateConfigureProfile.rejected,
        (state: IMasterData, action: any) => {
          state.profileErrorState = action.payload;
        }
      );
    builder
      .addCase(updatePPTSelectionValue.pending, (state: IMasterData) => { })
      .addCase(
        updatePPTSelectionValue.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.updatePPTSelectionValueSuccess = action.payload.response.status;
        }
      )
      .addCase(updatePPTSelectionValue.rejected, (state: IMasterData) => { });
    builder
      .addCase(savePptConfiguration.pending, (state: IMasterData) => { })
      .addCase(
        savePptConfiguration.fulfilled,
        (state: IMasterData, { meta }) => {
          state.configurePpt = meta.arg.qbConfig;
        }
      )
      .addCase(savePptConfiguration.rejected, (state: IMasterData) => { });
    builder
      .addCase(getMentions.pending, (state: IMasterData) => {
        state.mentionListLoader = true;
      })
      .addCase(
        getMentions.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.mentionList = action.payload.response.data;
          state.mentionListLoader = false;
        }
      )
      .addCase(getMentions.rejected, (state: IMasterData) => {
        state.mentionListLoader = false;
      });
    builder
      .addCase(getMasterWordCloudData.pending, (state: IMasterData) => { })
      .addCase(
        getMasterWordCloudData.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.masterWordCloudData = action.payload.response.data;
        }
      )
      .addCase(getMasterWordCloudData.rejected, (state: IMasterData) => { });
    builder
      .addCase(getQBPhrases.pending, (state: IMasterData) => {
        state.qbPhrasesLoader = true;
      })
      .addCase(
        getQBPhrases.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.allPhraseSent = action.payload.response.data;
          state.phraseCount = action.payload.response.count;
          state.qbPhrasesLoader = false;
        }
      )
      .addCase(getQBPhrases.rejected, (state: IMasterData) => {
        state.qbPhrasesLoader = false;
      });
    builder
      .addCase(getAttributes.pending, (state: IMasterData) => { })
      .addCase(
        getAttributes.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.masterAttributes = action.payload.response.data;
        }
      )
      .addCase(getAttributes.rejected, (state: IMasterData) => { });
    builder
      .addCase(combineQuestionBuc.pending, (state: IMasterData) => {
        state.viewBucketsLoader = true;
      })
      .addCase(
        combineQuestionBuc.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.combineQBSuccess = action.payload.response.data.Success;
          state.viewBucketsLoader = false;
        }
      )
      .addCase(combineQuestionBuc.rejected, (state: IMasterData) => {
        state.viewBucketsLoader = false;
      });
    builder
      .addCase(hideUnhideQB.pending, (state: IMasterData) => { })
      .addCase(
        hideUnhideQB.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.hideUnhideQBSuccess = action.payload.response.data.Success;
        }
      )
      .addCase(hideUnhideQB.rejected, (state: IMasterData) => { });
    builder
      .addCase(hideUnhidePhrase.pending, (state: IMasterData, { meta }) => {
        state.allPhraseSent = state.allPhraseSent.map((e: any) => {
          if (meta.arg.phrase_ids.includes(e.phrase_id)) {
            return { ...e, active: !e.active };
          } else {
            return { ...e };
          }
        });
      })
      .addCase(
        hideUnhidePhrase.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.hideUnhidePhraseSuccess = action.payload.response.data.Success;
        }
      )
      .addCase(
        hideUnhidePhrase.rejected,
        (state: IMasterData, action: any) => { }
      );
    builder
      .addCase(editQuestionBuc.pending, (state: IMasterData) => { })
      .addCase(
        editQuestionBuc.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.combineQBSuccess = action.payload.response.data.Success;
        }
      )
      .addCase(editQuestionBuc.rejected, (state: IMasterData) => { });
    builder
      .addCase(renameAssociation.pending, (state: IMasterData) => { })
      .addCase(
        renameAssociation.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.renameAssocSuccess = action.payload.response.data.Success;
          if (action.payload.response.data.Success == true) {
            let data = JSON.parse(JSON.stringify(state.associationList));
            let data1 = data.findIndex(
              (item: any) => item.associations == action.payload.display_name
            );
            data[data1].associations = action.payload.new_display_name;
            state.associationList = data;
            let mentionsData = JSON.parse(JSON.stringify(state.mentionList));
            mentionsData.forEach((item: any, i: any) => {
              if (item.association === action.payload.display_name) {
                mentionsData[i].association = action.payload.new_display_name;
              }
            });
            state.mentionList = mentionsData;
          }
        }
      )
      .addCase(renameAssociation.rejected, (state: IMasterData) => { });
    builder
      .addCase(getExportState.pending, (state: IMasterData) => { })
      .addCase(
        getExportState.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.exportState = action.payload.response.data.status;
        }
      )
      .addCase(getExportState.rejected, (state: IMasterData) => { });
    builder
      .addCase(getExportPptReportsData.pending, (state: IMasterData) => {
        state.exportPptReportsData = []
      })
      .addCase(
        getExportPptReportsData.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          if (action.payload.response.data.length === 0) {
            state.exportPptReportsDataIsEmpty = true;
          } else {
            state.exportPptReportsDataIsEmpty = false;
            state.exportPptReportsData = action.payload.response.data;
          }
        }
      )
      .addCase(getExportPptReportsData.rejected, (state: IMasterData) => { });
    builder
      .addCase(triggerExportData.pending, (state: IMasterData) => {
        state.exportState = 1;
      })
      .addCase(triggerExportData.fulfilled, (state: IMasterData) => { })
      .addCase(triggerExportData.rejected, (state: IMasterData) => {
        state.exportState = -1;
      });
    builder
      .addCase(resetData.pending, (state: IMasterData) => { })
      .addCase(
        resetData.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.resetStatus = action.payload.status;
        }
      )
      .addCase(resetData.rejected, (state: IMasterData) => { });
    builder
      .addCase(backupData.pending, (state: IMasterData) => { })
      .addCase(
        backupData.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.backupStatus = action.payload.status;
        }
      )
      .addCase(backupData.rejected, (state: IMasterData) => { });
    builder
      .addCase(getDeepDiveConfiguration.pending, (state: IMasterData) => {
        state.deepDiveConfigurationLoading = true;
      })
      .addCase(
        getDeepDiveConfiguration.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.deepDiveConfigurationLoading = false;
          state.deepDiveConfiguration = action.payload.response.data;
        }
      )
      .addCase(
        getDeepDiveConfiguration.rejected,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.deepDiveConfigurationLoading = false;
          state.deepDiveConfiguration = [];
        }
      );
    builder
      .addCase(saveDeepDiveConfiguration.pending, () => { })
      .addCase(
        saveDeepDiveConfiguration.fulfilled,
        (state: IMasterData, { meta }) => {
          state.deepDiveConfiguration = meta.arg.deepDiveConfig;
        }
      )
      .addCase(saveDeepDiveConfiguration.rejected, () => { });

    builder
      .addCase(getFeatures.pending, () => { })
      .addCase(
        getFeatures.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.featuresData = action.payload.response.user_details;
          state.subscriptionDetails= action?.payload?.response?.subscriptions[0]?.feature;
          state.app_config = action?.payload?.response?.app_config;
        }
      )
      .addCase(getFeatures.rejected, () => { });
    builder
      .addCase(getNotes.pending, (state: IMasterData, action: PayloadAction<any>) => {
        state.notesLoader = true
      })
      .addCase(
        getNotes.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.notes = action.payload.response?.data;
          state.notesLoader = false
        }
      )
      .addCase(getNotes.rejected, () => { });
    builder
      .addCase(getPptTemplates.pending, () => { })
      .addCase(
        getPptTemplates.fulfilled,
        (state: IMasterData, action: PayloadAction<any>) => {
          state.pptTemplates = action.payload.pptTemplatesResponse?.data;
          state.defaultPptTemplate = action.payload.defaultTemplateResponse?.data;
        }
      )
      .addCase(getPptTemplates.rejected, () => { });
  },
});

export const {
  updateSelectedQB,
  updateMasterSentiment,
  updateCombineQBSuccess,
  updateHideUnhideQBSuccess,
  updateSelectedAttr,
  updateRenameAssocSuccess,
  updateHideUnhidePhraseSuccess,
  updateCurrentPaginationPage,
  updateAddQBSuccess,
  updateAddQuestBucSuccessMessage,
  updateFileExportState,
  updateFileExportPptReportsData,
  updateAddConfigureProfile,
  updateDeleteProfile,
  updateEditConfigureProfile,
  updateShowHowToGetStarted,
  updateShowTipsToReduceCost,
  updateErrorProfileMsg,
  updateFeaturesData,
  updateNotes,
  updateSelectedRBNote,
  updateconfigInfoState,
  startNotesLoader,
  updateSentencesPayload,
  updateMasterAttributes,
  updateSentencesCountToken
} = masterDataSlice.actions;

export default masterDataSlice.reducer;
