import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Switch } from "@mui/material";
import { useAppDispatch } from "../../redux/store";
import Spinner from "../../components/Spinner";
import { getTranscriptNewTab } from "../../redux/QbQc/actions";

export default function TranscriptPage() {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const [projectId, setProjectId] = useState("");
  const [datasetId, setDatasetId] = useState("");
  const [fileName, setFileName] = useState();
  const [transcriptData, setTranscriptData] = useState([])
  const [showModeratorOnly, setShowModeratorOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const heightRef = useRef<any>(null);
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(1);


  useEffect(() => {
    const TopPos = heightRef.current?.getBoundingClientRect().top;
    const LeftPos = heightRef.current?.getBoundingClientRect().left;
    setHeight(window.innerHeight - TopPos - 60);
    setWidth(window.innerWidth - LeftPos - 15);
  });


  useEffect(() => {
    const parts = pathname.split('/');
    const projectId = parts[2];
    const datasetId = parts[3];
    if (projectId) setProjectId(projectId);
    if (datasetId) setDatasetId(datasetId);
  }, [pathname]);

  //get transcripts 
  useEffect(() => {
    if (projectId && datasetId) {
      dispatch(getTranscriptNewTab({ projectId, datasetId }))
        .then((response: any) => {
          const datasetName = response?.payload?.response?.data?.dataset_name;
          const nameWithoutExtension = datasetName.substring(0, datasetName.lastIndexOf('.')) || datasetName;
          setFileName(nameWithoutExtension);
          setTranscriptData(response?.payload?.response?.data?.content)
          setIsLoading(false);
        }).catch((error) => {
          console.error("Failed to fetch data:", error);
          setIsLoading(false);
        });
    }
  }, [projectId, datasetId, dispatch]);

  //filter only moderators
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowModeratorOnly(event.target.checked);
  };


  return (
    <div className="ml-10 mr-36">
      <div className=" flex justify-between mt-20 ">
        <div className="flex">
          <span style={{ fontSize: '1rem', fontFamily: "'Poppins'", color: "black" }}>File name :  <span>{" "}</span></span>
          <span style={{ fontSize: '1rem', fontFamily: "'Poppins'", color: "black" }}>{fileName}</span>
        </div>
        <div className="flex">
          <Switch
            checked={showModeratorOnly}
            onChange={handleSwitchChange}
            size="small"
            sx={{
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "#9747FF",
              },
              "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
              {
                backgroundColor: "#9747FF",
              },
            }}
          />
          <span style={{ fontSize: '1rem', fontFamily: "'Poppins'", color: "black" }}>Moderator only</span>
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : transcriptData.length === 0 ? (
        <div className="flex justify-center items-center" style={{ height: '500px', fontFamily: "'Poppins'", color: "black" }}>
          No data available
        </div>
      ) : (
        <div className="bg-white overflow-auto mt-2 border border-b rounded shadow-md px-3" ref={heightRef} style={{ height: height, width: width }}>
          {transcriptData?.map((item: any, index: any) => (
            <div key={index} className="mt-4">
              <div>
                <span className="font-bold " style={{ fontSize: '1rem', fontFamily: "'Poppins'", color: "black" }}>Moderator: </span>
                {item.question_text}
              </div>
              {!showModeratorOnly && item.responses.map((response: any, respIndex: any) => (
                <div key={respIndex}>
                  <span className="font-bold" style={{ fontSize: '1rem', fontFamily: "'Poppins'", color: "black" }}>Respondent: </span>
                  {response.response_text}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};