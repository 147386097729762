import { axiosconfig } from "../../service/apiconfig";

export const MasterAPI = {
    getQuestionBucket: ({ project_id, limit, offset }: any) => {  //need to add the offset and limit.
        return axiosconfig.get("projects/" + project_id + "/question_buckets")
    },
    getAssociations: ({ project_id, selectedQbs, sentiment, attributes }: any) => {
        return axiosconfig.post("/projects/" + project_id + "/associations", { project_id: project_id, question_bucket_id: selectedQbs, sentiment: sentiment, attributes: attributes })
    },
    getMentions: ({ project_id, selectedQbs, sentiment, attributes,associations }: any) => {
        return axiosconfig.post("/projects/" + project_id + "/mentions", { project_id: project_id, question_bucket_id: selectedQbs, sentiment: sentiment, attributes: attributes, associations:associations })
    },
    
    getQBPhrases: ({ project_id, selectedQbs, sentiment, attributes, limit, offset, associations, mentions, search_phrase, search_sentence }: any) => {
        return axiosconfig.post("/projects/phrases?offset=" + offset + "&limit=" + limit,
            {
                project_id: project_id,
                question_bucket_id: selectedQbs,
                sentiment: sentiment,
                attributes: attributes,
                associations: associations,
                mentions: mentions,
                search_phrase: search_phrase,
                search_sentence: search_sentence
            })
    },
    getAllAttributes: ({ project_id }: any) => {
        return axiosconfig.get("/projects/" + project_id + "/attributes_v2")
    },
    combineQuestionBuc: ({ project_id, question_bucket_ids, display_name }: any) => {
        return axiosconfig.post("projects/" + project_id + "/question_buckets/action=combine", { project_id: project_id, question_bucket_ids: question_bucket_ids, display_name: display_name })
    },
    hideUnhideQB: ({ project_id, question_bucket_ids, hide }: any) => {
        return axiosconfig.post("/projects/" + project_id + "/question_buckets/action=hide", { project_id: project_id, question_bucket_ids: question_bucket_ids, hide: hide })
    },

    hideUnhidePhrase: ({ project_id, phrase_ids, hide }: any) => {
        return axiosconfig.post("/projects/" + project_id + "/phrases/action=hide", { project_id: project_id, phrase_ids: phrase_ids, hide: hide })
    },
    editQuestionBuc: ({ project_id, exclude_question_bucket_ids, include_question_bucket_ids, old_display_name, new_display_name }: any) => {
        return axiosconfig.post("projects/" + project_id + "/question_bucket_groups",
            {
                project_id: project_id,
                exclude_question_bucket_ids: exclude_question_bucket_ids,
                include_question_bucket_ids: include_question_bucket_ids,
                new_display_name: new_display_name
            })
    },
    renameAssociation: ({ project_id, display_name, new_display_name }: any) => {
        return axiosconfig.post("projects/" + project_id + "/associations/action=rename?entity_display_name=" + display_name + "&new_entity_display_name=" + new_display_name, {})
    },
    addQuestionBucket: ({ project_id, question_bucket_name }: any) => {
        return axiosconfig.post("projects/" + project_id + "/question_buckets/" + question_bucket_name, {
            project_id: project_id,
            question_bucket_name: question_bucket_name,
        })
    },
    getExportState: ({projectId}:any) => {
        return axiosconfig.get(`/projects/${projectId}/output/export_data`)
    },
    triggerExportData: ({projectId, reportingBucketsIds, themeIds, entityIds, reportName, reportDescription, templateId, templateType}:any) => {
        return axiosconfig.post(`/projects/${projectId}/output/export_data`,{
            "reporting_bucket_ids": reportingBucketsIds,
            "theme_ids": themeIds,
            "entity_ids": entityIds,
            "report_name": reportName,
            "description": reportDescription,
            "template_id": templateId,
            "template_type": templateType
        })
    },
    getexportPptReportsData: ({projectId}: any) => {
        return axiosconfig.get(`/project/${projectId}/reports`)
    },
    downloadData: ({projectId}:any) => {
        return axiosconfig.getFile(`/projects/${projectId}/output/download_data`,null)
    },
    resetData: ({projectId}:any) => {
        return axiosconfig.get(`/project/${projectId}/action=reset`)
    },
    backupData: ({projectId}:any) => {
        return axiosconfig.get(`/project/${projectId}/action=backup`)
    },
    masterWordCloudData: ({ project_id, selectedQbs, sentiment, attributes }: any) =>{
        return axiosconfig.post(`/projects/word_cloud_mentions`, { project_id: project_id, question_bucket_id: selectedQbs, sentiment: sentiment, attributes: attributes })
    },
    configurePPt:({project_id}:any)=>{
        return axiosconfig.get(`project/${project_id}/ppt-configuration`)
    },
    updatePptConfiguration:({project_id, qbConfig}:any)=>{
        return axiosconfig.post(`project/${project_id}/ppt-configuration`,qbConfig)
    },
      configureProfiles:({project_id,limit,offset}:any)=>{
        return axiosconfig.get(`project/${project_id}/profile?limit=${limit}&offset=${offset}`)
    },
    createConfigureProfille:({project_id,profileData}:any)=>{
        return axiosconfig.post(`project/${project_id}/profile`,profileData)
    },
    savePptConfiguration:({projectId, qbConfig}:any)=>{
        return axiosconfig.post(`project/${projectId}/ppt-configuration`,qbConfig)
    },
    deleteConfigureProfile:({project_id,profile_id}:any)=>{
        return axiosconfig.delete(`project/${project_id}/profile/${profile_id}`)
    },
    editConfigureProfile:({project_id,profile_id,updatedData}:any)=>{
        return axiosconfig.put(`project/${project_id}/profile/${profile_id}`,updatedData)
    },
    getDeepDiveConfiguration: ({profileIds, projectId}:any) => {
        return axiosconfig.post(`/project/${projectId}/deepdive-profile/action=fetch`, profileIds)
    },
    saveDeepDiveConfiguration: ({profileIds, deepDiveConfig, projectId}:any) => {
        let data: any[] = []
        profileIds.forEach((element:any) => {
            let deepDive = deepDiveConfig.map((ele:any) => ({ ...ele, PROFILE_ID: element}))
            data.push(...deepDive)
        });
        return axiosconfig.post(`/project/${projectId}/deepdive-profile/action=save`, data)
    },
    updatePPtSelectionValue:({project_id,selectionData}:any)=>{
        return axiosconfig.post(`project/${project_id}/profile/ppt-selection`,selectionData)
    },
    projectThemes:({projectId,SelectedQb}: any) =>{
        let params:any = {
            offset:0,
            limit:1000000
        }
        if (SelectedQb){
            params.question_bucket_id = SelectedQb;
        }
        return axiosconfig.get(`/projects/${projectId}/theme/transcript_v2`,{params:params})
    },
    getFeatures:() => {
        return axiosconfig.get(`/features`)
    },
    getNotes: ({projectId,page}: any) => {
        return axiosconfig.get(`/projects/${projectId}/notes?page=${page}`)
    },
    postNotes: ({projectId, payload,page}: any) => {
        return axiosconfig.post(`/projects/${projectId}/notes?page=${page}`, payload)
    },
    bookingSlot:(bookingData:any)=>{
        return axiosconfig.post(`/requested_timeslot`,bookingData)
    },
    getPptTemplates: (projectId:string) => {
        return axiosconfig.get(`/project/${projectId}/ppt_templates`)
    },
    getDefaultPptTemplate: (projectId:string) => {
        return axiosconfig.get(`/project/${projectId}/default_ppt_templates`)
    }
}
