import { Checkbox, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../redux/store";
import { getJobRoles, signup } from "../../redux/Profile/action";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from "@mui/lab";


interface ISignup {
  handleLoginSu?: false
}

export default function MobileSignup(props: any) {

  const useStyles = makeStyles({
    noArrows: {
      "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
  });

  const classes = useStyles();
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const signUpLoader = useSelector((state: RootState) => state.profile.signupLoader)


  const [signupMail, setSignupMail] = useState("")
  const [check, setCheck] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [company, setCompany] = useState("")
  const [countryCode, setCountryCode] = useState<any>("");
  const [signupMailSent, setSignupMailSent] = useState<Boolean>(false)
  const [error, setError] = useState(false)
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [emailCheck, setEmailCheck] = useState(false)
  const [buttonClick, setButtonClick] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [emailValidation, setEmailValidation] = useState<boolean>(false);
  const [verifiedDomain, setVerifiedDomain] = useState<boolean>(false)
  const [basicDomainCheck, setBasicDomainCheck] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    const width = window.innerWidth;
    if (width >= 1024) {
      navigate("/signUp")
    }
  }, [])

  useEffect(() => {
    dispatch(getJobRoles({}))
    // dispatch(getUsers({}))
    setCountryCode("1");
  }, [])

  function handleVerifyMail() {
    dispatch(signup({
      Email: signupMail,
      FirstName: firstName,
      LastName: lastName,
      DisplayName: firstName,
      CountryId: parseInt(countryCode),
      CompanyId: company,
      JobRoleId: selectedJobId,
      PhoneNumber: phoneNumber,
      Mode: "QR Code"
    })).then((result: any) => {
      if (result.payload.response.status) {
        setSignupMailSent(true)
      } else {
        setError(true);
        setEmailCheck(true);
        setErrorMessage(result.payload.response.error)
        setSignupMailSent(false)
      }
      setVerifiedDomain(result.payload?.response)
    })
  }


  function handleSignup() {
    setButtonClick(true)
    // setCheck(!check)
    if (signupMail === "" || signupMail?.length === 0 || firstName === "" || lastName === "" || company === "" || check === false || (/^[^\w\s]+$/.test(firstName) || /^[^\w\s]+$/.test(lastName))
      || basicEmailDomCheck(signupMail) || (phoneNumber.length > 0 && countryCode?.length === 0) || (!isValidEmail(signupMail))) {
      setError(true)
    } else {

      setError(false);
      setEmailCheck(false);
      handleVerifyMail();
    }
  }

  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function basicEmailDomCheck(email: any) {
    const domains = ['gmail', 'hotmail', 'yahoo'];

    return domains.some(domain => email.includes(domain));

  }
  const handleSignupMail = (e: any) => {
    setSignupMail(e.target.value)
    setButtonClick(false)
    setEmailValidation(false)
    setBasicDomainCheck(false)
  }

  const handleLoginSu = () => {
    navigate('/login')
  }

  return !signupMailSent ? (
    (
      <div className="h-full w-full bg-white p-10">
        <div
          className="bg-white flex flex-col justify-center items-center pt-5 h-[15%] mb-5 mt-10"
          style={{ fontFamily: "Poppins, sans-serif  !important" }}
        >
          <img
            src="images/EzyThemes logo _vertical_white bg.svg"
            style={{ width: "30vw", height: "auto" }}
          />
        </div>
        <div className="col-span-2 lg:col-span-1 bg-white flex justify-center items-start w-full  overflow-y-auto">
          <div className=" flex flex-col justify-center lg:justify-start items-center lg:items-start  pb-10 mx-5 ">
            {/* sm:mx-10 md:mx-20 */}
            <div className="mt-10">
              <h1 className="font-semibold text-center text-5xl lg:text-xl mb-4">
                Fill in Your Details
              </h1>
              <p className="text-gray-400 text-3xl lg:text-lg mb-2 text-center">
                Just a few things before we add you into the waiting list!
              </p>
              <div className="flex flex-col lg:flex-row justify-around lg:w-96  ">
                <div className="mb-7 lg:mr-10 ">
                  <TextField
                    id="outlined-basic-1"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: { sm: 60, md: 80, lg: 50 },
                      },
                      "& .MuiInputLabel-root, & .MuiInputBase-root": {
                        fontSize: { sm: 20, md: 30, lg: 15 },
                      },
                      "& .MuiFormHelperText-root": {
                        fontSize: { sm: 10, md: 18, lg: 10 },
                      },
                      placeSelf: "center",
                      flexGrow: "1",
                      width: "100%",
                      transform: "translateY(50%)",
                    }}
                    label="First Name*"
                    variant="outlined"
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/[^a-z ']/gi, '');
                      setFirstName(sanitizedValue)
                    }}
                    value={firstName}
                    error={
                      error &&
                      (firstName?.trim().length === 0 ||
                        /^[^\w\s]+$/.test(firstName))
                    }
                    helperText={
                      error && firstName?.trim().length === 0
                        ? "Required"
                        : error && /^[^\w\s]+$/.test(firstName)
                          ? "Name cannot contain special characters"
                          : ""
                    }
                    inputProps={{
                      type: "text",
                      pattern: "[A-Za-z]*",
                      inputMode: "text",
                    }}
                  />
                </div>
                <div className="mb-7 ">
                  <TextField
                    id="outlined-basic-2"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: { sm: 60, md: 80, lg: 50 },
                      },
                      "& .MuiInputLabel-root, & .MuiInputBase-root": {
                        fontSize: { sm: 20, md: 30, lg: 15 },
                      },
                      "& .MuiFormHelperText-root": {
                        fontSize: { sm: 10, md: 18, lg: 10 },
                      },
                      placeSelf: "center",
                      flexGrow: "1",
                      width: "100%",
                      transform: "translateY(50%)",
                    }}
                    label="Last Name*"
                    variant="outlined"
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/[^a-z ']/gi, '');
                      setLastName(sanitizedValue)
                    }}
                    value={lastName}
                    error={error && (lastName?.trim().length === 0 || /^[^\w\s]+$/.test(lastName))}
                    helperText={
                      error && lastName?.trim().length === 0
                        ? "Required"
                        : error && /^[^\w\s]+$/.test(lastName)
                          ? "Name cannot contain special characters"
                          : ""
                    }
                    inputProps={{
                      type: "text",
                      pattern: "[A-Za-z]*",
                      inputMode: "text",
                    }}
                  />

                </div>
              </div>
              <div className="mb-7 lg:w-96">
                <TextField
                  id="outlined-basic-3"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: { sm: 60, md: 80, lg: 50 },
                    },
                    "& .MuiInputLabel-root, & .MuiInputBase-root": {
                      fontSize: { sm: 20, md: 30, lg: 15 },
                    },
                    "& .MuiFormHelperText-root": {
                      fontSize: { sm: 10, md: 18, lg: 10 },
                    },
                    placeSelf: "center",
                    flexGrow: "1",
                    width: "100%",
                    transform: "translateY(50%)",
                  }}
                  label="Business email*"
                  variant="outlined"
                  onChange={handleSignupMail}
                  value={signupMail}
                  error={
                    (error &&
                      (signupMail?.length === 0 ||
                        (signupMail?.length > 0 &&
                          !isValidEmail(signupMail)) ||
                        (signupMail?.length > 0 &&
                          basicEmailDomCheck(signupMail)) ||
                        (signupMail?.length > 0 &&
                          emailCheck &&
                          buttonClick))) ||
                    emailValidation
                  }
                  helperText={
                    (error && signupMail?.length === 0) ||
                      (emailValidation && signupMail?.length === 0) ? (
                      "Required"
                    ) : (error && !isValidEmail(signupMail)) ||
                      emailValidation ? (
                      <span style={{ color: "red" }}>Email is not valid</span>
                    ) : (error &&
                      basicEmailDomCheck(signupMail) &&
                      isValidEmail(signupMail)) ||
                      basicDomainCheck ? (
                      <span style={{ color: "red" }}>
                        Please Enter Company Mail
                      </span>
                    ) : emailCheck && buttonClick ? (
                      <span style={{ color: "red" }}>{errorMessage}</span>
                    ) : (
                      ""
                    )
                  }
                  onBlur={() => {
                    if (!isValidEmail(signupMail)) {
                      setEmailValidation(true);
                    } else if (basicEmailDomCheck(signupMail)) {
                      setBasicDomainCheck(true);
                    }
                  }}
                />
              </div>
              <div className="mb-7 lg:w-96">
                <TextField
                  id="outlined-basic-4"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: { sm: 60, md: 80, lg: 50 },
                    },
                    "& .MuiInputLabel-root, & .MuiInputBase-root": {
                      fontSize: { sm: 20, md: 30, lg: 15 },
                    },
                    "& .MuiFormHelperText-root": {
                      fontSize: { sm: 10, md: 18, lg: 10 },
                    },
                    placeSelf: "center",
                    flexGrow: "1",
                    width: "100%",
                    transform: "translateY(50%)",
                  }}
                  label="Company name*"
                  variant="outlined"
                  onChange={(e) => {
                    if (e.target.value.length <= 100) {
                      setCompany(e.target.value)
                    }
                  }}
                  value={company}
                  error={error && company?.length === 0}
                  helperText={
                    error && company?.length === 0 ? "Required" : ""
                  }
                />
              </div>

              <div className="mt-20 mb-10 flex  w-full">
                <div className="mr-3">
                  <PhoneInput
                    inputStyle={{ width: "150px", height: "80px" }}
                    dropdownStyle={{ width: "400px", maxHeight: "400px", fontSize: "25px" }}
                    // containerStyle={{width:"50px"}}
                    country="US"
                    value={countryCode}
                    enableSearch={true}
                    onChange={(e: any) => setCountryCode(e)}
                    inputProps={{ readOnly: true }}
                  />
                  {(phoneNumber?.length > 0 && countryCode?.length === 0) && <span className="text-red-600 text-xs font-base">Country Code Required*</span>}
                </div>
                <div className="w-full">
                  <TextField
                    id="outlined-basic-5"
                    className={classes.noArrows}
                    sx={{
                      placeSelf: "center",
                      "& .MuiInputBase-root": {
                        height: { sm: 60, md: 80, lg: 50 },
                      },
                      "& .MuiInputLabel-root, & .MuiInputBase-root": {
                        fontSize: { sm: 20, md: 30, lg: 15 },
                      },
                      "& .MuiFormHelperText-root": {
                        fontSize: { sm: 10, md: 18, lg: 10 },
                      },
                      // minWidth: '100px',
                      // maxWidth:"450px",
                      fontSize: 30,
                      flexGrow: "1",
                      width: "100%",
                      // transform: 'translateY(50%)'
                    }}
                    label="Mobile Number"
                    variant="outlined"
                    onKeyDown={(e: any) => {
                      if (
                        e.key === '.' ||
                        e.key === 'e' ||
                        e.key === 'E' ||
                        e.key === '-' ||
                        e.key === '+'
                      ) {
                        e.preventDefault();
                      }
                    }

                    }
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/\./g, "");
                      if (sanitizedValue.length <= 15) {
                        setPhoneNumber(e.target.value)
                      }
                    }}
                    value={phoneNumber}
                    inputProps={{
                      type: 'tel',
                      pattern: '[0-9]*',
                      inputMode: 'numeric',
                    }}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end" className="cursor-pointer">
                  //       <Popup
                  //         arrowStyle={{ color: '#8088A4' }}
                  //         contentStyle={{ backgroundColor: '#8088A4', width: '' }}
                  //         trigger={
                  //           <button type="button" className="trigger-button">
                  //             <Info size={32} />
                  //           </button>
                  //         }
                  //         position="bottom right"
                  //       >
                  //         <p className="text-center" style={{ fontSize: '20px', color: 'white' }}>
                  //           We will require this to be filled if you want to take part in the lucky draw!
                  //         </p>
                  //       </Popup>
                  //     </InputAdornment>
                  //   ),
                  // }}
                  />
                </div>
              </div>
              <div className="flex justify-center items-start p-3 gap-4">
                <Checkbox
                  className="scale-[2.2]"
                  onClick={() => setCheck((prevState) => !prevState)}
                />
                <div>
                  <p className="text-2xl">
                    I understand and agree to the{" "}
                    <a href="https://ezythemes.com/terms/" target="blank">
                      <span
                        className="text-blue-400 cursor-pointer"

                      >
                        Terms & Conditions,
                      </span>{" "}</a>
                    and{" "}
                    <a href="https://ezythemes.com/privacy/" target="blank">
                      <span
                        className="text-blue-400 cursor-pointer">
                        Privacy Statement,
                      </span>{" "}</a>
                    of EzyTheme.
                  </p>
                </div>
              </div>

              <div className="my-5 flex justify-center items-center pl-5 w-full cursor-pointer">
                <LoadingButton
                  style={{
                    width: "100%",
                    height: "70px",
                    backgroundColor: check ? "#0077B6" : "#E5E5E5",
                    fontSize: "25px",
                    color: "#FFFF",
                    padding: "8px",
                  }}
                  loading={signUpLoader}
                  loadingPosition="end"
                  variant="contained"
                  onClick={handleSignup}
                  disabled={!check}

                >
                  Sign up for $450 free credits**
                </LoadingButton>
              </div>
              <p className="ml-6 mb-10">** Subject to approval</p>
              <div className="flex pl-10 justify-center">
                <p className="justify-center text-gray-400 text-3xl text-extrabold my-3">
                  Already have an account ?{" "}
                  <span
                    className=" cursor-pointer "
                    style={{ color: "#9747FF" }}
                    onClick={handleLoginSu}
                  >
                    Log in
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  ) : (
    <div className="bg-white flex justify-center w-full h-[100vh]">
      <div className="flex flex-col justify-start items-center p-7 mt-[17vh]  w-full">
        <img
          src="images/EzyThemes logo _vertical_white bg.svg"
          style={{ width: "13vh", height: "auto" }}
          alt=""
        />

        {/* {verifiedDomain ? (
            <div className="mt-20 mb-7">
              <div className="w-4/5	m-auto flex flex-col justify-start items-center">
                <h1 className="font-semibold  text-4xl text-black-600 mb-4">
                  Link Sent
                </h1>
                <p className="text-3xl text-gray-500 mb-4 font-medium text-center">
                  Check your email :
                  <span className="text-black-500 font-bold ml-1">
                    {signupMail}
                  </span>
                  .{" "}
                </p>
                <p className="text-3xl mt-10 text-gray-500 mb-4 font-medium text-center">
                  You should have received an email from us based on the email
                  address you have entered earlier. Open the link in your email
                  to reset your password.​
                </p>

                <p className="text-3xl mt-10 text-gray-500 font-medium">
                  Did not receive the link?{" "}
                  <span
                    className=" cursor-pointer"
                    style={{ color: "#9747FF" }}
                    onClick={handleVerifyMail}
                  >
                    Resend Mail
                  </span>
                  ​
                </p>
              </div>
            </div> */}

        {/* ) : ( */}
        <div className="flex flex-col mt-28 gap-9 justify-start items-center">
          <h1 className="text-6xl font-semibold">
            Thank you for your interest!
          </h1>
          <h5
            className="text-4xl text-center text-gray-400 w-4/5 leading-relaxed"
          >
            Our team will review your submission and respond within the next
            2-3 business days to confirm your eligibility for the offer.
          </h5>
        </div>
        {/* )} */}
      </div>
    </div>
  );
}