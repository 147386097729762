import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../redux/store"
import { updateSelectedAttr, updateconfigInfoState } from "../../redux/Masters/masterDataReducer"
import { updateSelectedMode, updateSelectedView } from "../../redux/Themes/themesDataReducer"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { HelpCircle, XCircle } from "react-feather"
import { getProjectDetails } from "../../redux/Project/action";
import Divider from '@mui/material/Divider';
import NotePad from "../NotePad"
import Popup from "reactjs-popup";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { getCEEnabledTabs, getSummaryReport, triggerCEReprocessing, validateCeSubmit } from "../../redux/EditProject/action"
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, ThemeProvider, Tooltip, createTheme } from "@mui/material"
import { setDestinationAccountName, updateIsEditedSummaryReport, setResetEnable, setScopeUpdated } from "../../redux/EditProject/EditProjectReducer"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AttrMultiSelect from "../MultiSelectDropdown/AttrMultiSelect"
import { updateGoTo } from "../../redux/focus/focusReducer"
import { updateCurrentPage } from "../../redux/currentPage"

const theme = createTheme({
  palette: {
    primary: {
      main: "#9747FF"
    }
  },
});

export default function PageHeader() {

  const dispatch = useAppDispatch()
  const currentPage = useSelector((state: RootState) => state.currentPage.currentPage)
  const projDetails = useSelector((state: RootState) => state.project.projectDetails)
  const isUpdated = useSelector((state: RootState) => state.editProject.isScopeUpdated);
  const CETabLoader = useSelector((state: RootState) => state.editProject.CETabLoader);
  const selectedViewRedux = useSelector((state: RootState) => state.themes.selectedView);
  const allTranscripts = useSelector((state: RootState) => state.themes.allTranscripts)
  const selectedAnalyzeByItem = useSelector((state: RootState) => state.focus.selectedAnalyzeByItem)
  const masterAttr = useSelector((state: RootState) => state.master.masterAttributes)

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentTab = pathname.split('/')[3];
  const projectId = pathname.split('/')[2]
  const [switchChecker, setSwitchChecker] = useState(false);
  const [summaryPopup, setSummaryPopup] = useState(false)
  const [summaryPopupError, setSummaryPopupError] = useState<any>('')
  const [confirmationPopup, setconfirmationPopup] = useState(false)
  const [isPublishEnable, setIsPublishEnable] = useState(false)
  const [isMergeEnable, setIsMergeEnable] = useState(false)
  const [isEntitiesEnable, setIsEntitiesEnable] = useState(false)
  const [isThemesEnable, setIsThemesEnable] = useState(false)
  const [summaryLoader, setSummaryLoader] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<string>('scope')
  const [summaryEnable, setSummaryEnable] = useState();
  const [selectedView, setSelectedView] = useState(selectedViewRedux);
  const [jumpTo, setJumpTo] = useState<string>("All");
  const [showTooltip, setShowTooltip] = useState(false);




  const featuresData = useSelector(
    (state: RootState) => state.master.featuresData
  );
  const is_ce_manager = featuresData?.includes("INTERNAL_UI_EDIT_REPROCESSING")
  const [reviewState, setReviewState] = useState<boolean>(false)
  const tabsEnabledData = useSelector((state: RootState) => state.editProject.tabsEnabledData)

  useEffect(() => {
    if (projDetails.STATE === 12) {
      setReviewState(true)
    }
    else {
      setReviewState(false)
    }
  }, [projDetails])

  useEffect(() => {
    setIsPublishEnable(false);
    setIsMergeEnable(false);
  }, [CETabLoader])

  useEffect(() => {
    if (projectId) {
      dispatch(getProjectDetails({ project_id: projectId }))
      if (pathname.includes('edit-project')) {
        dispatch(updateCurrentPage("Manage Project"));
        dispatch(getCEEnabledTabs({ project_id: projectId }))
          .then((res: any) => {
            if (res.meta.requestStatus === "fulfilled") {
              setSummaryEnable(res.payload.response.data[0]['summary_enable'])
              setIsPublishEnable(res.payload.response.data[0]['publish_enable'])
              setIsMergeEnable(res.payload.response.data[0]['merge_enable'])
              setIsEntitiesEnable(res.payload.response.data[0]['entities_enable'])
              setIsThemesEnable(res.payload.response.data[0]['themes_enable'])
            }
          })
      }
    }

  }, [pathname]);



  const discardAndContinue = (tabName: string) => {
    dispatch(setResetEnable(true))
    dispatch(setDestinationAccountName(""))
    setconfirmationPopup(false)
    dispatch(updateIsEditedSummaryReport(false));
    dispatch(getSummaryReport({ project_id: projectId, output_type: 'Report' }))
    if (!isUpdated || !confirmationPopup) {
      navigate(`/edit-project/${projectId}/${tabName}`);
    }
  };


  useEffect(() => {
    if (tabsEnabledData.length) {
      setSummaryEnable(tabsEnabledData[0]['summary_enable'])
      setIsMergeEnable(tabsEnabledData[0]['merge_enable'])
      setIsPublishEnable(tabsEnabledData[0]['publish_enable'])
      setIsEntitiesEnable(tabsEnabledData[0]['entities_enable'])
      setIsThemesEnable(tabsEnabledData[0]['themes_enable'])
    }
  }, [tabsEnabledData])

  useEffect(() => {
    setSwitchChecker(false);
  }, [currentPage])
  const handleSwitchChange = () => {
    if (switchChecker) {
      dispatch(updateSelectedMode("View"));
    } else {
      dispatch(updateSelectedMode("Edit"));
    }
    setSwitchChecker(!switchChecker);
  };

  const handleTabSwitch = (tabName: string) => {

    if (tabName === 'scope' || (tabName === 'entities' && isEntitiesEnable) || (tabName === 'themes' && isThemesEnable)) {
      if (!reviewState) {
        setSelectedTab(tabName);
        if (isUpdated) {
          setconfirmationPopup(true);
          dispatch(setScopeUpdated(false));
        }
        else {
          if (!confirmationPopup) {
            navigate(`/edit-project/${projectId}/${tabName}`);
          }
        }
      }
    }

    if (tabName === 'publish-and-move') {
      setSelectedTab(tabName);
      if (reviewState) {
        if (isUpdated) {
          setconfirmationPopup(true);
          dispatch(setScopeUpdated(false));
        }
        else {
          if (!confirmationPopup) {
            navigate(`/edit-project/${projectId}/${tabName}`);
          }
        }
      }
      else if (isPublishEnable && is_ce_manager) {
        if (isUpdated) {
          setconfirmationPopup(true);
          dispatch(setScopeUpdated(false));
        }
        else {
          if (!confirmationPopup) {
            navigate(`/edit-project/${projectId}/${tabName}`);
          }
        }
      }
    }
    if (tabName === 'merge') {
      setSelectedTab(tabName);
      if (reviewState) {
        if (isUpdated) {
          setconfirmationPopup(true);
          dispatch(setScopeUpdated(false));
        }
        else {
          if (!confirmationPopup) {
            navigate(`/edit-project/${projectId}/${tabName}`);
          }
        }
      }
      else if (isMergeEnable && is_ce_manager) {
        if (isUpdated) {
          setconfirmationPopup(true);
          dispatch(setScopeUpdated(false));
        }
        else {
          if (!confirmationPopup) {
            navigate(`/edit-project/${projectId}/${tabName}`);
          }
        }
      }

    }

  };

  //reprocess the project when click on summaries tab
  const handleConfirmProceed = () => {
    setSummaryLoader(true)
    dispatch(triggerCEReprocessing({ project_id: projectId }))
      .unwrap()
      .then((res: any) => {
        if (res.response.status === true) {
          setSummaryLoader(false)
          setSummaryPopup(!summaryPopup)
          navigate('/dashboard')
        }
      })
      .catch((error) => {
        console.error('There was an error processing your request:', error);
      });
  };

  useEffect(() => {
    const closeOnEscapeKey = (e: any) => {
      if (e.key === "Escape") {
        setSummaryPopup(false);
      }
    };

    document.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, []);

  useEffect(() => {
    setSelectedView('Attributes')
  }, [pathname])

  const handleTab = (page: any) => {

    if (!reviewState) {
      setSelectedTab(page)
      if (!summaryEnable) {
        dispatch(validateCeSubmit({ project_id: projectId })).then((res: any) => {
          setSummaryPopupError(res.payload.response.error)
          setSummaryPopup(true)
        })
      }
      else {
        if (isUpdated) {
          setconfirmationPopup(true);
          dispatch(setScopeUpdated(false));
        }
        else {
          navigate(`/edit-project/${projectId}/${page}`)
        }

      }
    }
  };


  function handleViewBy(e: any) {
    let data: any = []
    masterAttr !== undefined && (
      Object?.keys(masterAttr)?.map((item: any) => {
        data?.push({ attribute_name: masterAttr[item].name, attribute_value: masterAttr[item].value, showAttr: true, attribute_id: item })
      }))

    let newData: Array<any> = []
    data.map((group: any) => {
      newData.push({ isGroup: true, name: group.attribute_name, showAttr: group.showAttr })
      if (group.showAttr) {
        group.attribute_value.map((attr: any, i: number) => {
          newData.push({
            isGroup: false,
            name: attr,
            groupName: group.attribute_name,
            showDivider: i === (group.attribute_value.length - 1),
            attr_id: group.attribute_id
          })
        })
      }
    })
    dispatch(updateSelectedAttr(newData.filter((item: any) => !item.isGroup)))
    setJumpTo("All")
    dispatch(updateGoTo("All"))
    setSelectedView(e.target.value);
    dispatch(updateSelectedView(e.target.value));
  }
  return (
    <div className={`page-header flex justify-between items-center ${currentPage === "Review" ? "h-[60px]" : ""}`} style={{ marginTop: "51px", padding: currentPage === "Review" ? "3px 20px" : "8px 20px", boxShadow: '0px 2px 6px 0px rgba(218,224,237,1)', zIndex: "10" }}>
      <div className="font-semibold flex gap-2 items-center">
        <span style={{ fontSize: "16px" }}>{currentPage + " "}</span>

        {pathname.includes('edit-project') &&
          <div className="flex ml-5">

            <div className={`${reviewState ? "bg-[#808080] cursor-not-allowed" : "bg-[#9747FF] cursor-pointer"} transform transition duration-300 py-1 px-4 text-white font-normal rounded-lg mx-1 ${currentTab === 'scope' ? 'scale-[1.05] shadow-lg' : 'bg-opacity-50'}`} onClick={() => { handleTabSwitch('scope') }}>Scope</div>


            <div className={`${reviewState ? "bg-[#808080] cursor-not-allowed" : isEntitiesEnable ? 'bg-[#9747FF] cursor-pointer' : 'bg-[#808080] cursor-not-allowed'} transform transition duration-300 py-1 px-4 text-white font-normal rounded-lg mx-1 ${currentTab === 'entities' ? 'scale-[1.05] shadow-lg' : 'bg-opacity-50'}`} onClick={() => {
              handleTabSwitch('entities')
            }}>Entities</div>

            <div className={`${reviewState ? "bg-[#808080] cursor-not-allowed" : isThemesEnable ? 'bg-[#9747FF] cursor-pointer' : 'bg-[#808080] cursor-not-allowed'} transform transition duration-300 py-1 px-4 text-white font-normal rounded-lg mx-1 ${currentTab === 'themes' ? 'scale-[1.05] shadow-lg' : 'bg-opacity-50'}`} onClick={() => { handleTabSwitch('themes') }}>Themes</div>

            <div className={`${reviewState ? "bg-[#808080] cursor-not-allowed" : "bg-[#9747FF] cursor-pointer"} transform transition duration-300 py-1 px-4 text-white font-normal rounded-lg mx-1 ${currentTab === 'summaries' ? 'scale-[1.05] shadow-lg' : 'bg-opacity-50'}`} onClick={() => handleTab('summaries')}>Summaries</div>

            <div className={`${reviewState ? "bg-[#808080] cursor-not-allowed" : "bg-[#9747FF] cursor-pointer"} transform transition duration-300 py-1 px-4 text-white font-normal rounded-lg mx-1 ${currentTab === 'report-and-email' ? 'scale-[1.05] shadow-lg' : 'bg-opacity-50'}`} onClick={() => { handleTab('report-and-email') }} >Report and Email</div>

            <div
              className={` py-1 px-4 transform transition duration-300 text-white font-normal rounded-lg mx-1 ${currentTab === 'publish-and-move' ? 'scale-[1.05] shadow-lg' : 'bg-opacity-50'} ${reviewState ? 'bg-[#9747FF] cursor-pointer' : (isPublishEnable && is_ce_manager) ? 'bg-[#9747FF] cursor-pointer' : 'bg-[#808080] cursor-not-allowed'}`}
              onClick={(e) => {
                handleTabSwitch('publish-and-move')
                if (reviewState && !isUpdated) {
                  dispatch(setDestinationAccountName(""))
                }
              }}
            >
              Publish and move
            </div>



            <div
              className={` py-1 px-4 transform transition duration-300 text-white font-normal rounded-lg mx-1  ${currentTab === 'merge' ? 'scale-[1.05] shadow-lg' : 'bg-opacity-50'} ${reviewState ? 'bg-[#9747FF] cursor-pointer' : (isMergeEnable && is_ce_manager) ? 'bg-[#9747FF] cursor-pointer' : 'bg-[#808080] cursor-not-allowed'}`}
              onClick={(e) => {
                handleTabSwitch('merge')
                if (reviewState && !isUpdated) {
                  dispatch(setDestinationAccountName(""))
                }
              }}
            >
              Merge
            </div>

          </div>
        }
        {pathname !== '/dashboard' && !pathname.includes('qbqc-guide') && (pathname.includes('/export-ppt') || pathname.includes('/qbqc')) ? <div className="absolute right-16"> <Divider orientation="vertical" flexItem={true} className="bg-[#BDBCBC] " variant="middle" sx={{ width: "1px", height: '20px' }}></Divider></div> : ''}
        {!pathname.includes('qbqc-guide') &&
          <>{pathname !== '/dashboard' && (pathname.includes('/export-ppt') || pathname.includes('/qbqc')) ? <div className="absolute right-20"><NotePad /></div> : <div className="absolute right-4">
            <NotePad />
          </div>}</>
        }
        {currentPage !== "Manage Question Buckets" && <span style={{ fontSize: "16px" }} className="text-gray-400">
          {/* - Market Research APAC */}
        </span>}

      </div>
      <div className="flex justify-end gap-5 items-center">



        {currentPage === "Configure PowerPoint" &&
          <HelpCircle size={25} style={{ cursor: "pointer", position: "fixed" }}
            onClick={() => dispatch(updateconfigInfoState(true))} />

        }
        {currentPage === "Transcripts" &&
          <Link to={`/qbqc-guide/${projectId}/home`} style={{ cursor: "pointer", position: "fixed" }} >
            <HelpCircle size={25} />
          </Link>

        }
        {(window.innerWidth > 1024 && (currentPage === "Review") && selectedAnalyzeByItem !== null) &&
          <div className="flex justify-start items-center mt-2">
            <ThemeProvider theme={theme} >
              <FormControl
                size="small"
                sx={{
                  width: "130px",
                  margin: '10px',
                  position: "right",
                }}
              >
                <InputLabel
                  id="demo-job-checkbox-label"
                  sx={{
                    fontSize: { sm: 20, md: 30, lg: 15 },
                    fontFamily: '"Poppins", sans-serif'
                  }}
                >
                  View By
                </InputLabel>
                <Select
                  labelId="demo-job-checkbox-label"
                  id="demo-job-checkbox-label"
                  value={selectedView}
                  onChange={(e: any) => handleViewBy(e)}

                  label="View By"
                  sx={{
                    height: '30px',
                    fontFamily: '"Poppins", sans-serif'
                  }}
                >
                  <MenuItem value="Attributes" sx={{ fontFamily: '"Poppins", sans-serif' }}>Attributes</MenuItem>
                  <MenuItem value="Transcripts" sx={{ fontFamily: '"Poppins", sans-serif' }}>Transcripts</MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>

            {selectedView === "Attributes" ?
              <AttrMultiSelect />
              :
              (
                <div className="mr-8">
                  <FormControl
                    size="small"
                    sx={{
                      minWidth: '80px',
                      maxWidth: "150px",
                      position: "right",
                    }}
                  >
                    <InputLabel
                      id="demo-job-checkbox-label"
                      sx={{
                        height: '30px',
                        fontSize: { sm: 20, md: 30, lg: 15 },
                        fontFamily: '"Poppins", sans-serif'
                      }}
                    >
                      Go To
                    </InputLabel>
                    {/* <Tooltip open={showTooltip} title={(allTranscripts?.filter((item: any) => item?.dataset_id===jumpTo))[0]?.dataset_name}> */}
                    <Select
                      value={jumpTo}
                      sx={{ height: '30px', fontSize: "13px", fontWeight: "700", textAlign: "left", paddingTop: '3px' }}
                      // input={<OutlinedInput label="Go to" />}
                      onChange={(e: any) => {
                        setJumpTo(e.target.value);
                        dispatch(updateGoTo(e.target.value))
                      }}
                      disableUnderline
                      IconComponent={ExpandMoreIcon}
                      MenuProps={{
                        sx: {
                          fontFamily: '"Poppins", sans-serif'
                        },
                        onMouseEnter: () => setShowTooltip(false)
                      }}
                      label="Go to"
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {
                        allTranscripts?.map((item: any, i: number) => {
                          return (
                            <MenuItem className="flex flex-wrap break-all w-[350px]" key={i}
                              sx={{ whiteSpace: "pre-line", fontFamily: '"Poppins", sans-serif' }} value={item.dataset_id}>
                              {item?.dataset_name}
                            </MenuItem>
                          )
                        })
                      }
                    </Select>
                    {/* </Tooltip>  */}
                  </FormControl>
                </div>
              )}

          </div>
        }

      </div>

      {/* <SummaryPopup
            open={summaryPopup}
            onClose={() => {
              setSummaryPopup(!summaryPopup);
            }}
            handleConfirmProceed={handleConfirmProceed}
            is_ce_manager={is_ce_manager}
            summaryLoader={summaryLoader}
           /> */}

      <Popup
        modal
        open={summaryPopup}
        contentStyle={{
          width: "34vw",
          boxShadow: "0px 0px 1px white",
          backgroundColor: "white",
          borderRadius: "6px",
        }}
        closeOnDocumentClick={false}
        onClose={() => {

        }}
      >
        <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
          <div className="flex justify-between items-center w-full mb-6">
            <p className="font-base text-lg  flex items-center pl-10 pr-10">
              {" "}
              <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
            </p>
            <XCircle
              className="mr-6"
              size={24}
              style={{ cursor: "pointer" }}
              fill="#BDBCBC"
              color="#FFFF"
              onClick={() => {
                setSummaryPopup(!summaryPopup);
              }}
            />
          </div>

          <span
            className="font-base pb-6 -2 pl-11 pr-10"
            style={{ fontSize: "12px", color: "#545454" }}
          >
            {summaryPopupError === '' ? `Project needs to be reprocessed to see ${selectedTab}.` : summaryPopupError}
          </span>
          <div
            className=" mt-5 flex justify-between items-center ml-10 mr-10"
            style={{ width: "90%" }}
          >
            {summaryPopupError === '' ?
              <Tooltip title={`${is_ce_manager ? "" : "Only CE Manager have access"}`}>
                <button
                  disabled={!is_ce_manager}
                  onClick={() => {
                    if (is_ce_manager) {
                      handleConfirmProceed();
                    }
                  }}
                  className={`w-48 p-2 rounded ml-80 ${is_ce_manager ? "text-red-700 border border-red-700" : "text-gray-400 border border-gray-400 cursor-not-allowed"} `}
                >
                  Proceed
                  {summaryLoader && (
                    <CircularProgress size={16} sx={{ color: "black" }} />
                  )}{" "}

                </button>
              </Tooltip>
              :
              <button
                onClick={() => setSummaryPopup(!summaryPopup)}
                className={`w-48 p-2 rounded ml-80 text-red-700 border border-red-700`}
              >
                Continue
              </button>
            }
          </div>
        </div>
      </Popup>

      {/* <ConfirmationPopup
            open={confirmationPopup}
            onClose={() => {
              setconfirmationPopup(false);
              dispatch(setScopeUpdated(true))
            }}
             handleContinueAnyway={()=>{discardAndContinue(selectedTab)}}
           /> */}

      <Popup
        modal
        open={confirmationPopup}
        contentStyle={{
          width: "34vw",
          boxShadow: "0px 0px 1px white",
          backgroundColor: "white",
          borderRadius: "6px",
        }}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
          <div className="flex justify-between items-center w-full mb-6">
            <p className="font-base text-lg  flex items-center pl-10 pr-10">
              {" "}
              <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
            </p>
            <XCircle
              className="mr-6"
              size={24}
              style={{ cursor: "pointer" }}
              fill="#BDBCBC"
              color="#FFFF"
              onClick={() => {
                setconfirmationPopup(false);
                dispatch(setScopeUpdated(true))
              }}
            />
          </div>

          <span
            className="font-base pb-6 -2 pl-11 pr-7"
            style={{ fontSize: "12px", color: "#545454" }}
          >
            You are leaving this tab. Any changes made will not be saved
          </span>
          <div
            className=" mt-5 flex justify-between items-center ml-10 mr-10"
            style={{ width: "90%" }}
          >
            <button
              onClick={() => {
                setconfirmationPopup(false);
                dispatch(setScopeUpdated(true))
              }}

              style={{ color: " #8088A4", border: "none" }}
            >
              Cancel
            </button>
            <button
              style={{ border: "1px solid red", color: "red" }}
              className="border w-48 p-2 rounded"
              onClick={() => { discardAndContinue(selectedTab) }}
            >
              Continue anyway
            </button>

          </div>
        </div>
      </Popup>
    </div>
  )
}

