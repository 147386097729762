import { useEffect, useState } from "react";
import ReactWordcloud from "react-wordcloud";
import Spinner from "../Spinner";

export default function GeneralWordCloud({ words }: any) {

    const [wordsLoading, setWordsLoading] = useState(true)

    const options: any = {
        enableTooltip: false,
        deterministic: true,
        fontFamily: "poppins",
        // fontSizes: fontSize(),
        padding: 2,
        rotations: 0,
        rotationAngles: [0, 0],
        transitionDuration: 0,
        scale: "sqrt",
        // spiral: "archimedean",
    };


    const callbacks: any = {
        getWordColor: (item: any) => {
            if (item.loadCount === words.length) {
                setWordsLoading(false)
            }
            if (item.sentiment === "Positive") {
                return "#22C55E";
            }
            if (item.sentiment === "Negative") {
                return "#EF4444";
            }
            if (item.sentiment === "Overall") {
                return "#3B82F6";
            }
            return "#6B7280";
        },
    };

    return (
        <>
            {wordsLoading &&
                <div className="h-full w-full flex justify-center items-center text-gray-400 text-lg">
                    <Spinner />
                </div>
            }
            <ReactWordcloud
                options={options}
                callbacks={callbacks}
                words={words}
            />

        </>
    )
}
