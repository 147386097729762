import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import currentPage from './currentPage';
import siteDataReducer from './siteDataReducer';
import masterDataReducer from './Masters/masterDataReducer';
import configDataSlice from './Configure/dataReducer';
import projectDataReducer from './Project/projectDataReducer';
import viewGroupDataReducer from './ViewGroup/viewGroupDataReducer';
import paymentDataReducer from './Payment/paymentDataReducer';
import notificationSlice from './Notification/notificationReducer';
import themesDataReducer from './Themes/themesDataReducer';
import subscriptions from './subscriptions';
import checkout from './checkout';
import profileDataSlice from './Profile/profileDataReducer';
import QbQcDataReducer from './QbQc/QbQcDataReducer';
import focusReducer from './focus/focusReducer';
import wizardReducer from './Wizard/wizardReducer';
import DataReducer from './DomAccept/DataReducer';
import llmReducer from './LLM/llmReducer';
import EditProjectReducer from './EditProject/EditProjectReducer';
import compareSlice from "./CompareWordCloud/dataReducer"


const appReducer = combineReducers({
    checkout: checkout,
    subscriptions: subscriptions,
    currentPage: currentPage,
    site: siteDataReducer,
    master: masterDataReducer,
    config: configDataSlice,
    project: projectDataReducer,
    viewGroup: viewGroupDataReducer,
    payment: paymentDataReducer,
    notification: notificationSlice,
    themes: themesDataReducer,
    profile: profileDataSlice,
    qbqc: QbQcDataReducer,
    focus: focusReducer,
    wizard: wizardReducer,
    domAccept: DataReducer,
    llm: llmReducer,
    editProject: EditProjectReducer,
    compare: compareSlice,
})

const rootReducer = (state: any, action: any) => {
    if (action.type === 'USER_LOGOUT') {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

const store = configureStore({
    reducer: rootReducer,
})

export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();