import React from "react";
import { ChevronLeft } from "react-feather";

interface SBProps {
  activeButton: String
  handleButtonClick: Function;
}

function SwitchButtons({ activeButton, handleButtonClick }: SBProps) {
  return (
    <div className=" bg-white">
      <div className="flex">
        <p className="text-[#3B3B3B] font-semibold mb-0 mr-3 px-8 pt-8 pl-11 text-base"
          style={{ fontFamily: '"Poppins", sans-serif', lineHeight: 'normal' }}>
          Sample Transcripts Format
        </p>
        <div className="flex justify-end w-1/2 ml-80 mt-8" style={{ color: ' #8088A4', cursor: "pointer" }}
          onClick={() => { window.close(); }}>
          <ChevronLeft size={18} /><span >Go back</span></div></div>
      <div className="flex items-center bg-white  pt-7">
        <p className="text-[#6A7792] font-medium pl-11 text-sm "
          style={{ fontFamily: '"Poppins", sans-serif', lineHeight: 'normal' }}
        >Switch to</p>
        <div className="ml-5">
          <button

            className={` cursor-pointer border py-1 px-3  text-center text-xs font-medium capitalize rounded-l-sm  ${activeButton === 'pdfDocx' ? 'bg-[#DAD3F5] text-[#9747FF] border-[#9747FF] ' : 'bg-white text-[#8088A4] border-[#6A7792]'
              }`}
            style={{ width: '226px', height: "30px", fontFamily: '"Poppins", sans-serif' }}
            onClick={() => handleButtonClick('pdfDocx')}
          >
            PDF or DOCX
          </button>
        </div>
        <div className="ml-0">

          <button
            className={` cursor-pointer border py-1 px-3  text-center text-xs font-medium capitalize rounded-r-sm ${activeButton === 'xlsxCsv' ? 'bg-[#DAD3F5] text-[#9747FF] border-[#9747FF] ' : 'bg-white text-[#8088A4] border-[#6A7792]'
              }`}
            style={{ width: '226px', height: "30px", fontFamily: '"Poppins", sans-serif' }}
            onClick={() => handleButtonClick('xlsxCsv')}
          >
            XLSX or CSV
          </button>
        </div>
      </div>
    </div>
  );
}

export default SwitchButtons;
