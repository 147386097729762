import React from "react";

interface PDFDOCXProps {
  data: Array<any>;
  data2: Array<any>;
  data3: Array<any>;
}

function PDFDOCX({ data, data2, data3 }: PDFDOCXProps) {
  return (
    <div className="list-decimal px-5 p-4 mt-0 rounded overflow-y-scroll bg-white	" style={{ height: '80%' }}>
      <style>
        {`
          /* Style for reducing the size of the scrollbar */
          .overflow-y-scroll::-webkit-scrollbar {
            width: 6px;
          }

          .overflow-y-scroll::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 3px;
          }

          .overflow-y-scroll::-webkit-scrollbar-track {
            background-color: transparent;
          }
        `}
      </style>
      <div className="px-3 text-lg font-normal" style={{ fontFamily: 'Poppins', fontStyle: 'normal' }}>
        <div className="mb-8 pl-4 pt-4">
          <p className="text-[#8088A4]  font-normal text-sm mb-8 ">To optimize your outputs for higher accuracy,it's best if your transcripts contain a clear identification of moderator(s) and respondent(s) with structured prefix examples as shown below :</p>
          <p className="text-[#F59E1B]  font-medium text-base mb-8 ">Example 1</p>
          {data.map((item, index) => (
            <div style={{ display: 'flex' }}>
              <p className="pr-2.5 font-medium text-[#9747FF] text-sm mb-1	" style={{ fontFamily: '"Poppins", sans-serif' }} key={index}>{item.speaker}:</p>
              <p className="pr-2.5 font-medium text-sm	mb-1" style={{ fontFamily: '"Poppins", sans-serif' }} key={index}>{item.text}</p>
            </div>
          ))}
        </div>

        <div className="mb-8 pl-4">
          <p className="text-[#F59E1B] font-medium  text-base mb-8">Example 2</p>
          {data2.map((item, index) => (
            <React.Fragment key={index}>
              <p className="pr-2.5 font-medium text-[#9747FF] text-sm mb-2" style={{ fontFamily: '"Poppins", sans-serif' }}>{item.speaker}</p>
              <p className="pr-2.5 font-medium text-sm mb-1" style={{ fontFamily: '"Poppins", sans-serif' }}>{item.timeStamp}</p>
              <p className="pr-2.5 font-medium text-sm mb-1" style={{ fontFamily: '"Poppins", sans-serif' }}>{item.dialogue}</p>
            </React.Fragment>
          ))}
        </div>

        <div className="mb-8 pl-4">
          <p className="text-[#F59E1B] font-medium  text-base  mb-8" >Example 3</p>
          {data3.map((item, index) => (
            <div className="flex w-50">
              <p className="pr-2.5 mr-20 font-medium pl-0 text-sm w-5 text-[#9747FF] mb-1" style={{ fontFamily: '"Poppins", sans-serif' }} key={index}>{item.speaker}:</p>
              <div style={{}}>
                <p className=" font-medium  text-left text-sm mb-1" style={{ fontFamily: '"Poppins", sans-serif' }} key={index}>{item.text}</p>
              </div>
              {/* <p key={index}>{`${item.speaker}: ${item.text}`}</p> */}
            </div>
          ))}
        </div>
      </div>
      <div className='flex justify-center mt-8'>
        <div className="p-2 bg-[#9747FF] rounded-md text-[#F6F6F6] w-[20vw] cursor-pointer bg-size-200 text-center my-auto"
          onClick={() => { window.close() }} >Got it!</div>
      </div>
    </div>
  );
}

export default PDFDOCX;
