import { axiosconfig } from "../../service/apiconfig";

export const paymentAPI = {
    getIncludeQuestionCount: ({ project_id }: any) => {
        return axiosconfig.get("/projects/" + project_id + "/payment/included_question_count")
    },
    getExcludeQuestionCount: ({ project_id }: any) => {
        return axiosconfig.get("projects/" + project_id + "/payment/excluded_question_count")
    },
    getTotalWordCount: ({ project_id, limit, offset }: any) => {
        return axiosconfig.get("projects/" + project_id + "/payment/total_word_count")
    },
    getPaymentConfirm: ({ project_id}: any) => {
        return axiosconfig.get("/projects/"+ project_id +"/demo-mapping")
    },
    getClientId:({projectId}:any)=>{
        return axiosconfig.post(`project/${projectId}/payment`, null)
    },
    paymentStatus:({checkoutSessionId,subscriptionId}:any)=>{
        return axiosconfig.get(`payment/${checkoutSessionId}`)
    },
    userCredits:()=>{
        return axiosconfig.get(`/credits`)
    },
    getFileSlpit: ({projectId}: any) => {
        return axiosconfig.get(`/project/${projectId}/cost`)
    }
}
