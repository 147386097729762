import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFocusType } from "./type";
import {
  getSentenceMatrix,
  sentenceHide,
  mergeThemes,
  reportingQB,
  getThemes,
  entities,
  updateSentimentThemes,
  updateSentenceSentiment,
  getSentenceBubbleData,
  updateBubbleBookMark,
} from "./action";

const initialState: IFocusType = {
  getFocusAll: [],
  sentenceMatrix: [],
  focusAllLoader: false,
  matrixLoader: false,
  getThemes: [],
  getEntities: [],
  themesLoader: false,
  entitiesLoader: false,
  sentenceBubbleData: [],
  bubbleSentenceLoader: false,
  GoToId: "All",
  configureAllSelection: null,
  selectedAnalyzeByItem: null,
  isHideSelectedItem: false
};


export const focusReducer = createSlice({
  name: "focus",
  initialState: initialState,
  reducers: {
    updateAnalyzeBrackets: (state) => {
      state.getFocusAll = []
      state.getThemes = []
      state.getEntities = [];
    },
    updateGoTo: (state, action) => {
      state.GoToId = action.payload
    },
    updateConfigureSelection: (state, action) => {
      state.configureAllSelection = action.payload
    },
    updateBlubbleData: (state, action) => {
      state.sentenceBubbleData = action.payload
    },
    updateSelectedAnalyzeByItem: (state, action) => {
      state.selectedAnalyzeByItem = action.payload
    },
    updateIsHideMessage: (state, action) => {
      state.isHideSelectedItem = action.payload
    }
  },
  extraReducers: (builder) => {
    // Reporting QB
    builder
      .addCase(reportingQB.pending, (state: IFocusType) => {
        state.focusAllLoader = true;
      })
      .addCase(
        reportingQB.fulfilled,
        (state: IFocusType, action: PayloadAction<any>) => {
          if (action.payload.response.data.length) {
            const reportingQB = action.payload.response.data.map((each: any) => {
              return {
                id: each.id,
                display_name: each.name,
                summary: each.summary,
                is_active: each.is_active,
                sentence_count: each.sentence_count,
                is_userdefined: each.is_userdefined,
              };
            });
            const update = [{ type: "Reporting Buckets", data: reportingQB }];
            state.getFocusAll = update;
          } else {
            state.getFocusAll = [];
          }
          state.focusAllLoader = false;
        }
      )
      .addCase(reportingQB.rejected, (state: IFocusType) => {
        state.focusAllLoader = false;
      });

    // Themes
    builder
      .addCase(getThemes.pending, (state: IFocusType) => {
        state.themesLoader = true;
      })
      .addCase(
        getThemes.fulfilled,
        (state: IFocusType, action: PayloadAction<any>) => {
          const themes = action.payload.response.data.map((each: any) => {
            return {
              id: each.id,
              is_active: each.is_active,
              display_name: each.name,
              sentence_count: each.sentence_count,
              summary: each.summary,
              is_userdefined: each.is_userdefined,
              type: each.type
            };
          });

          const update = [{ type: "Themes", data: themes }];
          state.getThemes = update;
          state.themesLoader = false;
        }
      )
      .addCase(getThemes.rejected, (state: IFocusType) => {
        state.themesLoader = false;
      });

    //  Entities
    builder
      .addCase(entities.pending, (state: IFocusType) => {
        state.entitiesLoader = true;
      })
      .addCase(
        entities.fulfilled,
        (state: IFocusType, action: PayloadAction<any>) => {
          let entities = action.payload.response.data.map((each: any) => {
            return {
              type: each.type,
              data: each.entity.map((item: any) => {
                return {
                  id: item.id,
                  display_name: item.name,
                  summary: item.summary,
                  sentence_count: item.sentence_count,
                  is_active: item.is_active,
                  is_userdefined: item.is_userdefined
                };
              }),
            };
          });

          const update = [...entities];
          state.getEntities = update;
          state.entitiesLoader = false;
        }
      )
      .addCase(entities.rejected, (state: IFocusType) => {
        state.entitiesLoader = false;
      });

    builder
      .addCase(sentenceHide.pending, (state: IFocusType, action: any) => {
        const payload = action.meta.arg.payload;
        let updatedList: any[] = [];

        const filteredData = JSON.parse(JSON.stringify([...state.getFocusAll, ...state.getThemes, ...state.getEntities])).filter((item: any) => item?.type?.toLowerCase() === payload.class_type);

        updatedList = filteredData[0]?.data?.map((item: any) => {
          const check = item.id.map((check: any) => { return check.id; }).includes(payload.class_ids[0])
          if (check) {
            return { ...item, is_active: !item.is_active };
          }
          return { ...item };
        });

        if (payload.class_type === "reporting buckets") {
          state.getFocusAll[0].data = updatedList;
        } else if (payload.class_type === "themes") {
          state.getThemes[0].data = updatedList;
        } else {
          let index = state.getEntities?.findIndex((item: any) => item?.type?.toLowerCase() === payload.class_type);
          if (index !== undefined && index !== -1) {
            state.getEntities[index].data = updatedList;
          }
        }
      })
      .addCase(sentenceHide.fulfilled, (state, action: PayloadAction<any>) => { })
      .addCase(sentenceHide.rejected, (state: IFocusType, action: any) => {
        const payload = action.meta.arg.payload;
        let updatedList: any[] = [];

        const filteredData = JSON.parse(JSON.stringify([
          ...state.getFocusAll,
          ...state.getThemes,
          ...state.getEntities,
        ]))?.filter(
          (item: any) => item?.type?.toLowerCase() === payload.class_type
        );

        updatedList = filteredData[0]?.data?.map((item: any) => {
          if (
            item.id
              .map((check: any) => {
                return check.id;
              })
              .includes(payload.class_ids[0])
          ) {
            return { ...item, is_active: !item.is_active };
          }
          return { ...item };
        });

        if (payload.class_type === "reporting buckets") {
          state.getFocusAll[0].data = updatedList;
        } else if (payload.class_type === "themes") {
          state.getThemes[0].data = updatedList;
        } else {
          let index = state.getEntities?.findIndex(
            (item: any) => item?.type?.toLowerCase() === payload.class_type
          );
          if (index !== undefined && index !== -1) {
            state.getEntities[index].data = updatedList;
          }
        }
      });
    builder
      .addCase(mergeThemes.pending, (state: IFocusType, action) => {
        let currentName = action.meta.arg.currentName;
        let payload = action.meta.arg.payload;
        let [updatedList] = JSON.parse(JSON.stringify([
          ...state.getFocusAll,
          ...state.getThemes,
          ...state.getEntities,
        ]))?.filter((item: any) => item?.type?.toLowerCase() === payload.class_type)
        const check = updatedList?.data.some((each: any) => each.display_name === payload.new_name)

        if (!check) {
          updatedList = updatedList.data.map((item: any) => {
            if (item.display_name === currentName) {
              return {
                ...item,
                display_name: payload.new_name,
              };
            } else {
              return { ...item };
            }
          });
        } else {
          updatedList = [...updatedList?.data]
        }

        if (payload.class_type === "reporting buckets") {
          state.getFocusAll[0].data = updatedList;
        } else if (payload.class_type === "themes") {
          state.getThemes[0].data = updatedList;
        } else {
          let index = state.getEntities?.findIndex((item: any) => item?.type?.toLowerCase() === payload.class_type);
          if (index !== undefined && index !== -1) {
            state.getEntities[index].data = updatedList;
          }
        }

      })
      .addCase(mergeThemes.fulfilled, (state: IFocusType, action) => { })
      .addCase(mergeThemes.rejected, (state: IFocusType, action) => {
        let currentName = action.meta.arg.currentName;
        let payload = action.meta.arg.payload;
        let [updatedList] = JSON.parse(JSON.stringify([
          ...state.getFocusAll,
          ...state.getThemes,
          ...state.getEntities,
        ]))?.filter((item: any) => item?.type?.toLowerCase() === payload.class_type)

        updatedList = updatedList.data.map((item: any) => {
          if (item.display_name === payload.new_name) {
            return {
              ...item,
              display_name: currentName,
            };
          } else {
            return { ...item };
          }
        });

        if (payload.class_type === "reporting buckets") {
          state.getFocusAll[0].data = updatedList;
        } else if (payload.class_type === "themes") {
          state.getThemes[0].data = updatedList;
        } else {
          let index = state.getEntities?.findIndex((item: any) => item?.type?.toLowerCase() === payload.class_type);
          if (index !== undefined && index !== -1) {
            state.getEntities[index].data = updatedList;
          }
        }
      });
    builder
      .addCase(getSentenceMatrix.pending, (state: IFocusType, action) => {
        state.matrixLoader = true;
      })
      .addCase(getSentenceMatrix.fulfilled, (state: IFocusType, action) => {
        state.sentenceMatrix = action.payload.response.data;
        state.matrixLoader = false;
      })
      .addCase(getSentenceMatrix.rejected, (state: IFocusType, action) => {
        state.matrixLoader = false;
      });
    builder
      .addCase(updateSentimentThemes.fulfilled, (state: IFocusType, action) => {
        var data = JSON.parse(JSON.stringify([...state.getThemes]))
        data[0]?.data.map((item: any) => {
          if (item.id[0].id === action.meta.arg.theme_id) {
            item.type = action.meta.arg.sentiment
          }
        })
        state.getThemes = data
      })
    builder
      .addCase(getSentenceBubbleData.pending, (state: IFocusType, action: PayloadAction<any>) => {
        state.sentenceBubbleData = []
        state.bubbleSentenceLoader = true
      })
      .addCase(getSentenceBubbleData.fulfilled, (state: IFocusType, action: PayloadAction<any>) => {
        state.sentenceBubbleData = action.payload.response.data
        state.bubbleSentenceLoader = false
      })
      .addCase(getSentenceBubbleData.rejected, (state: IFocusType, action: PayloadAction<any>) => {
        state.bubbleSentenceLoader = false
      })
    builder
      .addCase(updateSentenceSentiment.pending, (state: IFocusType, action: PayloadAction<any>) => { })
      .addCase(updateSentenceSentiment.fulfilled, (state: IFocusType, action: PayloadAction<any>) => {

      })
      .addCase(updateSentenceSentiment.rejected, (state: IFocusType, action: PayloadAction<any>) => { })
    builder
      .addCase(updateBubbleBookMark.pending, (state: IFocusType, action: any) => {
        state.sentenceBubbleData = JSON.parse(JSON.stringify(state.sentenceBubbleData))?.map((each: any) => {
          if (each.id === action.meta.arg.bubble_bucket_id) {
            return { ...each, is_bookmarked: !each.is_bookmarked }
          }
          return { ...each }
        }
        )
      })
      .addCase(updateBubbleBookMark.fulfilled, (state: IFocusType, action: PayloadAction<any>) => {

      })
      .addCase(updateBubbleBookMark.rejected, (state: IFocusType, action: any) => {
        state.sentenceBubbleData = JSON.parse(JSON.stringify(state.sentenceBubbleData))?.map((each: any) => {
          if (each.id === action.meta.arg.bubble_bucket_id) {
            return { ...each, is_bookmarked: !each.is_bookmarked }
          }
          return { ...each }
        }
        )
      })
  },
});

export const { updateAnalyzeBrackets, updateGoTo, updateIsHideMessage, updateSelectedAnalyzeByItem, updateConfigureSelection, updateBlubbleData } = focusReducer.actions;

export default focusReducer.reducer;
