import { useEffect } from "react"
import { useNavigate } from "react-router-dom"


export default function QrReRoute() {

    const navigate = useNavigate()

    useEffect(() => {
        navigate("/signUp")
    })

    return <></>
}