import { useEffect, useRef, useState } from "react"
import { updateCurrentPage } from "../../../redux/currentPage"
import { useAppDispatch } from "../../../redux/store"
import { Link, useLocation } from "react-router-dom"
import { ChevronDown, Search, Triangle } from "react-feather"
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BasicSwitch from "../../../components/BasicSwitch"

export default function GuideHome() {
    // NOTE: this is a static page

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(updateCurrentPage("Transcripts"))
    }, [])

    const leftRef = useRef<any>(null);
    const [leftHeight, setLeftHeight] = useState<number>(0);
    const rightRef = useRef<any>(null);
    const [rightHeight, setRightHeight] = useState<number>(0);

    useEffect(() => {
        const LTopPos = leftRef.current?.getBoundingClientRect().top;
        const RTopPos = rightRef.current?.getBoundingClientRect().top;
        setLeftHeight(window.innerHeight - LTopPos - 70);
        setRightHeight(window.innerHeight - RTopPos - 70);
    });

    const { pathname } = useLocation();
    const id = pathname.split("/")[2]

    return (
        <>
            <div className="flex justify-between items-center">
                <div className="flex justify-start items-center text-lg font-semibold p-1">
                    Overview on how to manage transcripts bucketing
                </div>
                <div className="flex justify-between items-center">
                    <Link to={`/qbqc-guide/${id}/tour1`}>
                        <div className="rounded-lg shadow-md bg-[#9747FF] text-white p-3 flex justify-center items-center w-[10vw] cursor-pointer mr-3">Quick Tour</div>
                    </Link>
                    <Link to={`/qbqc/${id}`} >
                        <div className="rounded-lg shadow-md bg-[#9747FF] text-white p-3 flex justify-center items-center w-[10vw] cursor-pointer">Got it!</div>
                    </Link>
                </div>
            </div>
            <div className="w-full grid grid-cols-8 gap-4 h-full">
                <div className="col-span-2 pt-1">
                    <div className="flex justify-start items-center text-lg font-semibold p-1">
                        Analysis Topics
                    </div>
                    {/* this is analysis topics content */}
                    <div className="bg-[#D9D9D9] rounded-lg p-10 text-lg" ref={leftRef} style={{ height: leftHeight }}>
                        <div className="">Select the bucket you wish to edit/view.</div>
                        <br />
                        <div className=""> <span className="font-semibold">Ungrouped: </span> parts of the transcript(s) that do not belong to any defined Analysis Topics yet but will still be included in the overall analysis. </div>
                        <br />
                        <div className=""> <span className="font-semibold">Excluded:</span> parts of the transcript(s) that are excluded entirely from the overall analysis. </div>
                    </div>

                </div>
                <div className="col-span-6 pt-3">
                    <div className="flex justify-start items-center px-3 pt-1 pb-2">
                        <div className="flex justify-between items-center w-full">
                            <div className="flex justify-start items-center  text-lg font-semibold">
                                <span>Transcripts Preview</span>
                                <div className="ml-4 flex items-center justify-between p-2 border border-gray-300 rounded bg-white text-black" style={{ fontSize: "14px" }}>
                                    <span className="w-48 font-normal">All Selected</span>
                                    <ChevronDown className="ml-2 text-gray-700" style={{ width: '16px', height: '16px' }} />
                                </div>
                            </div>
                            <div className="flex justify-end items-center mr-2 gap-1">
                                <div style={{ width: '150px', display: 'flex' }}>
                                    <BasicSwitch />
                                    <span className="font-medium pt-2">Expand All</span>
                                </div>
                                <div className="ml-4 flex items-center justify-between p-2 border border-gray-300 rounded bg-white text-black" style={{ fontSize: "14px" }}>
                                    <div className="flex justify-between items-center text-[#A3A3A3]">
                                        <>
                                            <Search className="w-5 h-5 mx-1" />
                                            <span className="w-48 font-normal mx-1" style={{ fontSize: "14px" }}>Search Transcript</span>
                                        </>
                                        <>
                                            <ExpandLessIcon />
                                            <KeyboardArrowDownIcon />
                                        </>
                                    </div>
                                </div>
                                <span className="ml-2">prev</span>
                                <Triangle
                                    fill="black"
                                    size={18}
                                    color="black"
                                />
                                <Triangle
                                    fill="black"
                                    size={18}
                                    className="rotate-180"
                                />
                                <span>next</span>
                                {/* <div className="p-2 w-[5vw] mx-2"></div> */}
                                {/* <div className="rounded-lg bg-[#9747FF] text-white p-2 flex justify-center items-center w-[5vw] text-sm mx-2">RESET</div> */}
                                <div className="rounded-lg bg-[#9747FF] text-white p-2 flex justify-center items-center w-[5vw] text-sm">PROCESS</div>
                            </div>
                        </div>
                    </div>
                    {/* this is transcripts content */}
                    <div className="bg-[#D9D9D9] rounded-lg p-10 text-lg" ref={rightRef} style={{ height: rightHeight }}>
                        <div className="">Use this pane to edit/view selected buckets within the transcripts</div>
                    </div>

                </div>
            </div>
        </>
    )
}