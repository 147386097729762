import { useRef, useState } from "react"
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Checkbox, IconButton, Input, Button, Popover, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

interface IthemeItem {
    item: any,
    handleThemeSelection: any,
    selectedTheme: any
    handelHideTheme: any
    editTheme: any
}
const ThemeItem = (props: IthemeItem) => {
    const { item, handleThemeSelection, selectedTheme } = props
    const [newName, setNewName] = useState<string>(item.name)
    const [anchorEl, setAnchorEl] = useState(null);
    const [popupOpen, setPopupOpen] = useState<boolean>(false)
    const [themes, setThemes] = useState<Array<any>>([])
    const [hover, setHover] = useState<boolean>(false)
    const popUpRef = useRef<any>(null)

    const includesValue = (searchFor: string, array: Array<any>) => {
        for (const item of array) {
            if (item === searchFor) {
                return true
            }
        }
        return false
    }

    const handleClick = (event: any) => {
        setPopupOpen(true)
        setAnchorEl(event.currentTarget)
        setThemes(item.id)
        setNewName(item.name)
    }

    const handleClose = () => {
        setPopupOpen(false)
        setAnchorEl(null)
    }

    function handleRemove(data: any) {
        let currQBs = [...themes]
        setThemes(currQBs.filter((item: any) => item.id !== data.id))
    }

    function handleEditClick() {
        let payload: any = {}
        payload['include_ids'] = themes.map((item: any) => item.id)
        payload['exlude_ids'] = item.id.filter((item: any) => !includesValue(item.id, payload['include_ids'])).map((elem: any) => elem.id)
        payload['new_name'] = newName
        payload['class_type'] = "Themes"
        props.editTheme(payload)
        setPopupOpen(false)
    }

    return (
        <>
            <div className={`flex justify-between items-center px-2 rounded-md ${props.item.is_active ? "hover:bg-blue-100" : "hover:bg-gray-100"}`} style={{ width: "332px", height: "39px" }} ref={popUpRef}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <div onClick={() => {
                    if (item.is_active) {
                        handleThemeSelection(item, "Single")
                    }
                }} className={`flex justify-start items-center ${props.selectedTheme.length === 0 && !hover ? "pl-[39px]" : ""} w-full`} >
                    {(props.selectedTheme.length > 0 || hover) &&
                        <Checkbox checked={selectedTheme.some((each: any) => item.name === each.name)} disabled={!item.is_active} />
                    }
                    <Tooltip title={item?.name.length > 25 && <span style={{ whiteSpace: 'pre-line', fontSize: "11px" }}>{item.name}</span>} placement="top">
                        <div className={`rounded-md truncate text-start ${props.item.is_active ? "cursor-pointer" : "cursor-default text-gray-400"}`} style={{ maxWidth: "215px" }} >
                            {item.name}
                        </div>
                    </Tooltip>
                    <span className="text-gray-400 m-1 cursor-default"> ({item.sentence_count})</span>
                </div>
                {(props.selectedTheme.length > 0 || hover) &&
                    <div className="flex justify-end items-center">
                        {item.is_active && <EditIcon onClick={handleClick} className="text-gray-400 mr-2 hover:text-gray-900 cursor-pointer" />}
                        {item.is_active ?
                            <VisibilityOffIcon onClick={() => props.handelHideTheme(item.id.map((item: any) => item.id), true)} className="text-gray-400 hover:text-gray-900 cursor-pointer" />
                            :
                            <VisibilityIcon onClick={() => props.handelHideTheme(item.id.map((item: any) => item.id), false)} className="text-gray-400 hover:text-gray-900 cursor-pointer" />
                        }
                    </div>
                }
            </div>
            <Popover
                open={popupOpen}
                anchorEl={popUpRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                PaperProps={{
                    style: {
                        width: "300px",
                        padding: "20px",
                        maxHeight: "500px",
                        borderRadius: "6px"
                    }
                }}
            >
                <div className='flex flex-col justify-start gap-3'>
                    <div className='overflow-y-auto flex flex-col justify-start gap-2 pr-2' style={{ maxHeight: "250px" }}>
                        {themes.map((item: any, i: number) => {
                            return (
                                <div className='w-full flex justify-between items-center bg-gray-100 rounded-md' key={i}>
                                    <span className='px-4'>{item.name}</span>
                                    <IconButton className="float-right" onClick={() => handleRemove(item)}><CancelRoundedIcon /></IconButton>
                                </div>
                            )
                        })}
                    </div>
                    <div className='border-2 px-2 rounded-md'>
                        <Input
                            style={{ width: "280px" }}
                            placeholder='Type here...'
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            disableUnderline></Input>
                    </div>
                    <div className='flex justify-end items-center gap-3'>
                        <Button onClick={() => { setPopupOpen(false) }} variant='text' >Cancel</Button>
                        <Button variant='contained' onClick={handleEditClick}>Submit</Button>
                    </div>
                </div>
            </Popover>
        </>
    )
}
export default ThemeItem