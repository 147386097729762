import MarkdownPreview from "@uiw/react-markdown-preview";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { decryption, secrateKey } from "../../modules/LoginLayout/LoginLayout";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import '../../assets/css/App.css';

export const QueryResponseComponent = (data: any) => {
  const props = data.data;
  const [username, setUsername] = useState("");
  const [userFullName, setUserFullName] = useState("");
  let encrypted = localStorage.getItem("_U");
  const userDetails: any = useSelector(
    (state: RootState) => state.profile.userInfo
  );
  const QueryError = useSelector((state: RootState) => state.llm.QueryError);
  const QueryErrorMessage = useSelector(
    (state: RootState) => state.llm.QueryErrorMessage
  );
  useEffect(() => {
    if (encrypted) {
      let userInfo: any = decryption(encrypted, secrateKey);

      if (userInfo) {
        userInfo = JSON.parse(userInfo);
        setUsername(userDetails?.FIRSTNAME?.slice(0, 2).toLocaleUpperCase());
        setUserFullName(userDetails?.FIRSTNAME);
      }
    }
  }, [userDetails]);
  return (
    <div className="flex flex-col gap-6 justify-start p-4 w-full">
      <div className="flex gap-3 justify-start">
        <div>
          <div
            className={`border-gray-600 border-2 rounded-full h-6 w-6 flex justify-center items-center pt-0.5 bg-transparent text-gray-600	}`}
          >
            <div className="flex items-center ">
              <span
                className="font-semibold"
                style={{
                  fontSize: "10px",
                }}
              >
                {username}
              </span>
            </div>
          </div>
        </div>

        <span>{props.query}</span>
      </div>
      {props.error ? (
        <>
          {props.response ? (
            <span className="text-center flex items-center">
              <WarningAmberOutlinedIcon
                style={{ color: "red", marginRight: "12px" }}
              />
              {props.response}
            </span>
          ) : (
            <span className="text-center flex items-center">
              <WarningAmberOutlinedIcon
                style={{ color: "red", marginRight: "12px" }}
              />
              "Something crashed"
            </span>
          )}
        </>
      ) : (props.response && props.response.trim().length > 0) ? (
        <div className="flex items-start w-full">
          <img
            src={`/images/EzyThemes logo icon.svg`}
            className="w-7 h-7 flex-none"
            alt="LLM"
          />
          <span className="ml-2 markdown-css">
            <MarkdownPreview
              source={props.response}
              wrapperElement={{ "data-color-mode": "light" }}
              style={{ width: "50vw" }}
            />
          </span>
        </div>
      ) : (
        <img
          src="/images/spinner.svg"
          alt="loader"
          style={{
            height: "40px",
            width: "40px",
            position: "relative",
            left: "-10px",
          }}
        />
      )}
    </div>
  );
};
