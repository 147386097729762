import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDomAccept } from "./type";
import { getDomains, getPendingDomains, postDomainOutcomes } from "./action";

const initialState: IDomAccept = {
    pendingDomains: [],
    getPendingLoader: false,
    domainsData: []
}

export const domAcceptSlice = createSlice({
    name: "domAccept",
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getPendingDomains.pending, (state: IDomAccept, action: PayloadAction<any>) => {
                state.getPendingLoader = true
            })
            .addCase(getPendingDomains.fulfilled, (state: IDomAccept, action: PayloadAction<any>) => {
                state.pendingDomains = action.payload.response
                state.getPendingLoader = false
            })
            .addCase(getPendingDomains.rejected, (state: IDomAccept, action: PayloadAction<any>) => {
                state.getPendingLoader = false
            });
        builder
            .addCase(getDomains.pending, (state: IDomAccept, action: PayloadAction<any>) => {
                state.getPendingLoader = true
            })
            .addCase(getDomains.fulfilled, (state: IDomAccept, action: PayloadAction<any>) => {
                state.domainsData = action.payload.response
                state.getPendingLoader = false
            })
            .addCase(getDomains.rejected, (state: IDomAccept, action: PayloadAction<any>) => {
                state.getPendingLoader = false
            });
        builder
            .addCase(postDomainOutcomes.pending, (state: IDomAccept, action: PayloadAction<any>) => {
                state.getPendingLoader = true
            })
            .addCase(postDomainOutcomes.fulfilled, (state: IDomAccept, action: PayloadAction<any>) => {
                state.getPendingLoader = false
            })
            .addCase(postDomainOutcomes.rejected, (state: IDomAccept, action: PayloadAction<any>) => {
                state.getPendingLoader = false
            });
    }
})

export const { } = domAcceptSlice.actions;

export default domAcceptSlice.reducer;