import { createSlice } from "@reduxjs/toolkit";
interface IAdditionalFeatures {
  additionalFees: string[];
  processingTime: string[];
  PdfConversion: string;
  datasetConfig: string;
  dataKpis: string;
  manageThemes: string;
  exportReport: string;
  settings: string;
}
interface IPackage {
  name: string;
  useCase: string;
  cost: string;
  features: string[];
  additionalFeatures: IAdditionalFeatures,
}
interface ISubscription {
  subscriptions: {
    id: number;
    name: string;
    features: string[];
    packages: IPackage[];
  }[];
}

const initialState: ISubscription = {
  subscriptions: [],
};
export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: initialState,
  reducers: {
    addSubscription: (state, action) => {
      state.subscriptions.push(action.payload);
    },
    removeSubscription: (state, action) => {
      state.subscriptions = state.subscriptions.filter(
        (subscription) => subscription.id !== action.payload.id
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { addSubscription, removeSubscription } =
  subscriptionSlice.actions;
export default subscriptionSlice.reducer;
