import { Checkbox, FormControl, InputLabel, MenuItem, Select, TextField, createTheme, } from "@mui/material";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../redux/store";
import { getJobRoles, signup } from "../../redux/Profile/action";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { makeStyles } from '@mui/styles';
import 'react-phone-input-2/lib/bootstrap.css';
import { LoadingButton } from "@mui/lab";
import { ThemeProvider } from "@emotion/react";

interface ISignup {
  handleLoginSu?: false
}

export default function DesktopSignup(props: any) {

  const useStyles = makeStyles({
    noArrows: {
      "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
  });

  const classes = useStyles();
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
      },
    },
  });

  const jobsList = useSelector((state: RootState) => state.profile.jobRolesData)
  const signUpLoader = useSelector((state: RootState) => state.profile.signupLoader)


  const [signupMail, setSignupMail] = useState("")
  const [check, setCheck] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [company, setCompany] = useState("")
  const [jobRole, setJobRole] = useState("")
  const [error, setError] = useState(false)
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [signupMailSent, setSignupMailSent] = useState<Boolean>(false)
  const [emailCheck, setEmailCheck] = useState(false)
  const [buttonClick, setButtonClick] = useState(false)
  const [openJobRoleInput, setOpenJobRoleInput] = useState(false)
  const [jobRoleInput, setJobRoleInput] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState("")
  const [emailValidation, setEmailValidation] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<any>("");
  const [verifiedDomain, setVerifiedDomain] = useState<boolean>(false)
  const [basicDomainCheck, setBasicDomainCheck] = useState(false)
  const [anchorE2, setAnchorE2] = useState(null);
  const [firstNameNumCheck, setFirstNameNumCheck] = useState(false)
  const [lastNameNumCheck, setLastNameNumCheck] = useState(false)
  const [firstNameEmpty, setFirstNameEmpty] = useState(false)
  const [lastNameEmpty, setLastNameEmpty] = useState(false)
  const [companyNameEmpty, setCompanyNameEmpty] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')



  useEffect(() => {
    dispatch(getJobRoles({}))
    // dispatch(getCountries({}))
    // dispatch(getUsers({}))
    setCountryCode("1");
  }, [])




  function handleVerifyMail() {
    dispatch(signup({
      Email: signupMail,
      CountryCode: countryCode,
      FirstName: firstName,
      LastName: lastName,
      DisplayName: firstName,
      CountryId: parseInt(countryCode),
      CompanyId: company,
      JobRoleId: selectedJobId,
      Mode: "Direct Link",
      PhoneNumber: phoneNumber,
    })).then((result: any) => {
      if (result.payload.response.status) {
        setSignupMailSent(true)
      } else {
        setError(true);
        setEmailCheck(true);
        setErrorMessage(result.payload.response.error)
        setSignupMailSent(false)
      }
      setVerifiedDomain(result.payload?.response)
    })
  }


  function handleSignup() {
    setButtonClick(true);
    // setCheck(!check)
    if (
      signupMail === "" ||
      signupMail?.length === 0 ||
      firstName === "" ||
      lastName === "" ||
      company === "" ||
      check === false ||
      (phoneNumber.length > 0 && phoneNumber.includes('.')) ||
      (/^[^\w\s]+$/.test(firstName) || /^[^\w\s]+$/.test(lastName) || /^\d+$/.test(firstName) || /^\d+$/.test(lastName)) ||

      basicEmailCheck(signupMail) ||
      !isValidEmail(signupMail)
    ) {
      setError(true);
    } else {

      setError(false);
      setEmailCheck(false);
      handleVerifyMail();
      // } else {
      //   setError(true);
      //   setEmailCheck(true);
      // }
    }
  }

  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function basicEmailCheck(email: any) {
    const domains = ['gmail', 'hotmail', 'yahoo'];
    return domains.some(domain => email.includes(domain));

  }

  const handleSignupMail = (e: any) => {
    setSignupMail(e.target.value)
    setButtonClick(false)
    setEmailValidation(false)
    setBasicDomainCheck(false)

  }

  const handleLoginSu = () => {
    navigate('/login')
  }

  return !signupMailSent ? (
    (
      <div className="grid grid-cols-2 relative max-lg:flex max-lg:flex-col max-lg:justify-start h-full w-full">
        <div
          className="max-lg:col-span-2 lg:flex max-lg:bg-white bg-gray-150 flex flex-col justify-center items-center mb-24"
          style={{ padding: "50px 0px" }}
        >
          <img
            src={`${window.innerWidth >= 1024 ? "images/EzyThemes logo _vertical_white bg.svg" : "https://ezythemes.com/wp-content/uploads/2024/04/logo.svg"}`}
            style={{
              width: window.innerWidth >= 1024 ? "200px" : "50vw",
              height: "auto",
              top: "223.5px",
              left: "203px",
              gap: "60px",
              padding: "5px",
            }}
            alt="Logo"
          />
          {window.innerWidth >= 1024 &&
            <div
              style={{
                width: "479px",
                height: "152px",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "34px",
                letterSpacing: "0px",
                textAlign: "center",
                padding: "10px",
              }}
            >
              Supercharge your productivity!
              <br />
              Unlock $450 worth of reward credits and
              <br />
              discover the transformative power of MR
              <br />
              transcripts automation!
            </div>
          }
        </div>
        <div
          className="col-span-2 lg:col-span-1 bg-white flex justify-center items-start w-full max-lg:h-full"
          style={{ fontFamily: "Poppins, sans-serif  !important" }}
        >
          <div className="flex flex-col justify-center max-lg:justify-start items-start h-full max-lg:text-3xl max-lg:pt-[3vh] ">
            <div className="flex flex-col justify-start gap-6 max-lg:gap-10 max-lg:w-[90vw] min-[1023px]:w-[31vw] mb-2 ">
              <div className="mt-16">
                <h1 className="font-semibold text-2xl max-lg:text-5xl max-lg:text-center mb-2">
                  Fill in Your Details.
                </h1>
                <p className="text-gray-400 text-md max-lg:text-3xl max-lg:text-center">
                  Just a few things before we add you into the waiting list!
                </p>
              </div>
              <div className="flex gap-2 max-lg:flex-col max-lg:gap-10 justify-around w-full">
                <div className="w-[55%] max-lg:w-full">
                  <ThemeProvider theme={customTheme}>
                    <TextField
                      id="outlined-basic-1"
                      sx={{
                        //maxWidth: "350px",
                        placeSelf: "center",
                        flexGrow: "1",
                        width: "100%",
                        borderColor: "black",
                        "& .MuiInputBase-root": {
                          height: { sm: 60, md: 80, lg: 52 },
                        },
                        "& .MuiInputLabel-root, & .MuiInputBase-root": {
                          fontSize: { sm: 20, md: 30, lg: 15 },
                          fontFamily: '"Poppins", sans-serif'
                        },
                        "& .MuiFormHelperText-root": {
                          fontSize: { sm: 20, md: 20, lg: 10 },
                          fontFamily: '"Poppins", sans-serif'
                        },
                      }}
                      label="First Name*"
                      variant="outlined"

                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const sanitizedValue = inputValue.replace(/[^a-z ']/gi, '');
                        setFirstName(sanitizedValue);
                        if (/^\d+$/.test(inputValue)) {
                          setFirstNameNumCheck(true);
                        } else {
                          setFirstNameNumCheck(false);
                        }
                      }}
                      value={firstName}
                      onBlur={(e) => {
                        (firstName.trim().length == 0) ?
                        setFirstNameEmpty(true) : setFirstNameEmpty(false);
                      }}
                      error={
                        (error || firstNameEmpty) &&
                        (firstName?.trim().length === 0 ||
                          /^[^\w\s]+$/.test(firstName) ||
                          /^\d+$/.test(firstName)
                        )
                      }
                      helperText={
                        (error || firstNameEmpty) && firstName?.trim().length === 0
                          ? "Required"
                          : error && /^[^\w\s]+$/.test(firstName)
                            ? "Name cannot contain special characters"
                            :
                            firstNameNumCheck ?
                              <span className="text-red-600">Name cannot contain all numericals</span>
                              : ""
                      }
                      inputProps={{
                        type: "text",
                        pattern: "[A-Za-z]*",
                      }}
                    />
                  </ThemeProvider>
                </div>
                <div className="w-[45%] max-lg:w-full">
                  <ThemeProvider theme={customTheme}>
                    <TextField
                      id="outlined-basic-2"
                      sx={{
                        //maxWidth: "350px",
                        placeSelf: "center",
                        flexGrow: "1",
                        width: "100%",
                        "& .MuiInputBase-root": {
                          height: { sm: 60, md: 80, lg: 52 },
                        },
                        "& .MuiInputLabel-root, & .MuiInputBase-root": {
                          fontSize: { sm: 20, md: 30, lg: 15 },
                          fontFamily: '"Poppins", sans-serif'
                        },
                        "& .MuiFormHelperText-root": {
                          fontSize: { sm: 20, md: 20, lg: 10 },
                          fontFamily: '"Poppins", sans-serif'
                        },
                      }}
                      label="Last Name*"
                      variant="outlined"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const sanitizedValue = inputValue.replace(/[^a-z ']/gi, '');
                        setLastName(sanitizedValue);
                        if (/^\d+$/.test(inputValue)) {
                          setLastNameNumCheck(true);
                        } else {
                          setLastNameNumCheck(false);
                        }
                      }}
                      value={lastName}
                      onBlur={(e) => {
                        (lastName.trim().length == 0) ?
                        setLastNameEmpty(true) : setLastNameEmpty(false);
                      }}
                      error={
                        (error || lastNameEmpty) &&
                        (lastName?.trim().length === 0 || /^[^\w\s]+$/.test(lastName)
                          || /^\d+$/.test(lastName))
                      }
                      helperText={
                        (error || lastNameEmpty) && lastName?.trim().length === 0
                          ? "Required"
                          : error && /^[^\w\s]+$/.test(lastName)
                            ? "Name cannot contain special characters"
                            :
                            lastNameNumCheck ?
                              <span className="text-red-600">Name cannot contain all numericals</span>
                              : ""
                      }
                      inputProps={{
                        type: "text",
                        pattern: "[A-Za-z]*",
                      }}

                    />
                  </ThemeProvider>
                </div>
              </div>
              <div className="w-full">
                <ThemeProvider theme={customTheme}>
                  <TextField
                    id="outlined-basic-3"
                    sx={{
                      //maxWidth: "350px",
                      placeSelf: "center",
                      flexGrow: "1",
                      width: "100%",
                      "& .MuiInputBase-root": {
                        height: { sm: 60, md: 80, lg: 52 },
                      },
                      "& .MuiInputLabel-root, & .MuiInputBase-root": {
                        fontSize: { sm: 20, md: 30, lg: 15 },
                        fontFamily: '"Poppins", sans-serif'
                      },
                      "& .MuiFormHelperText-root": {
                        fontSize: { sm: 16, md: 20, lg: 10 },
                        fontFamily: '"Poppins", sans-serif'
                      },
                    }}
                    label="Business email*"
                    variant="outlined"
                    onChange={handleSignupMail}
                    value={signupMail}
                    error={
                      (error &&
                        (signupMail?.length === 0 ||
                          (signupMail?.length > 0 &&
                            !isValidEmail(signupMail)) ||
                          (signupMail?.length > 0 &&
                            basicEmailCheck(signupMail)) ||
                          (signupMail?.length > 0 &&
                            emailCheck &&
                            buttonClick))) ||
                      emailValidation
                    }
                    helperText={
                      (error && signupMail?.length === 0) ||
                        (emailValidation && signupMail?.length === 0) ? (
                        "Required"
                      ) : (error && !isValidEmail(signupMail)) ||
                        emailValidation ? (
                        <span style={{ color: "red" }}>Email is not valid</span>
                      ) : (error &&
                        basicEmailCheck(signupMail) &&
                        isValidEmail(signupMail)) ||
                        basicDomainCheck ? (
                        <span style={{ color: "red" }}>
                          Please Enter Company Mail
                        </span>
                      ) : emailCheck && buttonClick ? (
                        <span style={{ color: "red", marginBottom: "1%" }}>
                          {errorMessage}
                        </span>
                      ) : (
                        ""
                      )
                    }
                    onBlur={() => {
                      if (!isValidEmail(signupMail)) {
                        setEmailValidation(true);
                      } else if (basicEmailCheck(signupMail)) {
                        setBasicDomainCheck(true);
                      }
                    }}
                  />
                </ThemeProvider>
              </div>
              <div className="w-full">
                <ThemeProvider theme={customTheme}>
                  <TextField
                    id="outlined-basic-4"
                    sx={{
                      //maxWidth: "350px",
                      placeSelf: "center",
                      flexGrow: "1",
                      width: "100%",
                      "& .MuiInputBase-root": {
                        height: { sm: 60, md: 80, lg: 52 },
                      },
                      "& .MuiInputLabel-root, & .MuiInputBase-root": {
                        fontSize: { sm: 20, md: 30, lg: 15 },
                        fontFamily: '"Poppins", sans-serif'
                      },
                      "& .MuiFormHelperText-root": {
                        fontSize: { sm: 20, md: 20, lg: 10 },
                        fontFamily: '"Poppins", sans-serif'
                      },
                    }}
                    label="Company name*"
                    variant="outlined"
                    onChange={(e) => {
                      if (e.target.value.length <= 100) {
                        setCompany(e.target.value)
                      }
                    }}
                    value={company}
                    onBlur={(e) => {
                      (company.trim().length == 0) ?
                      setCompanyNameEmpty(true) : setCompanyNameEmpty(false);
                    }}
                    error={(error || companyNameEmpty) && company?.length === 0}
                    helperText={
                      (error || companyNameEmpty) && company?.length === 0 ? "Required" : ""
                    }
                  />
                </ThemeProvider>
              </div>
              <div className="w-full flex justify-start ">
                {openJobRoleInput ?
                  <div>
                    <TextField
                      id="outlined-basic-8"
                      sx={{
                        placeSelf: "center",
                        flexGrow: "1",
                        width: "100%",
                      }}
                      label="Custom Job Role"
                      variant="outlined"
                      onChange={(event: any) => setJobRoleInput(event.target.value)}
                      value={jobRoleInput}
                      error={error && jobRoleInput?.length === 0}
                      helperText={
                        error && jobRoleInput?.length === 0 ? "Required" : ""
                      }

                    />
                  </div>
                  :
                  <FormControl
                    sx={{
                      minWidth: "100px",
                      width: "100%",
                      position: "right",
                    }}
                  >
                    <InputLabel
                      id="demo-job-checkbox-label"
                      sx={{
                        fontSize: { sm: 20, md: 30, lg: 15 },
                        fontFamily: '"Poppins", sans-serif'
                      }}
                    >
                      What is your main role?
                    </InputLabel>
                    <Select
                      labelId="demo-job-checkbox-label"
                      id="demo-job-checkbox-label"
                      value={jobRole}
                      label="What is your main role?"
                      sx={{
                        height: { sm: 60, md: 80, lg: 52 },
                        fontSize: { sm: 20, md: 30, lg: 15 },
                        fontFamily: '"Poppins", sans-serif'
                      }}
                      MenuProps={{
                        sx: {
                          maxHeight: window.innerWidth >= 1024 ? "400px" : "600px",
                        },
                      }}
                      onChange={(e) => {
                        const selectedJob: any = jobsList.find(
                          (item: any) => item.JOB_ROLE_NAME === e.target.value
                        );
                        setSelectedJobId(selectedJob?.JOBROLEID);
                        setJobRole(selectedJob?.JOB_ROLE_NAME);
                      }}
                    >
                      {jobsList.map((item: any) => (
                        <MenuItem
                          key={item.JOBROLEID}
                          value={item.JOB_ROLE_NAME}
                          sx={{
                            fontSize: { sm: 20, md: 30, lg: 15 },
                            fontFamily: '"Poppins", sans-serif'
                          }}
                        >
                          {item.JOB_ROLE_NAME}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
              </div>

              <div className="w-full grid grid-cols-6 gap-2">
                <div className="col-span-2">
                  <div className="w-full max-lg:h-20 h-[52px]" style={{ height: "52px !important" }}>
                    <PhoneInput
                      inputStyle={{ width: "100%" }}
                      onChange={(e: any) => setCountryCode(e)}
                      // dropdownStyle={{fontSize:"50px"}}
                      country="US"
                      value={countryCode}
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                  {(phoneNumber?.length > 0 && countryCode?.length === 0) && <span className="text-red-600 text-xs font-base">Country Code Required*</span>}
                </div>
                <div className="col-span-4">
                  <ThemeProvider theme={customTheme}>
                    <TextField
                      id="outlined-basic-5"
                      className={classes.noArrows}
                      sx={{
                        //maxWidth: "350px",
                        placeSelf: "center",
                        flexGrow: "1",
                        width: "100%",
                        // "& fieldset": { border: 'none' }
                        // transform: 'translateY(50%)',
                        "& .MuiInputBase-root": {
                          height: { sm: 60, md: 80, lg: 52 },
                        },
                        "& .MuiInputLabel-root, & .MuiInputBase-root": {
                          fontSize: { sm: 20, md: 30, lg: 15 },
                          fontFamily: '"Poppins", sans-serif'
                        },
                        "& .MuiFormHelperText-root": {
                          fontSize: { sm: 20, md: 20, lg: 10 },
                          fontFamily: '"Poppins", sans-serif'
                        },
                      }}
                      label="Mobile Number"
                      variant="outlined"
                      onKeyDown={(e) => {
                        if (
                          e.key === "e" ||
                          e.key === "E" ||
                          e.key === "-" ||
                          e.key === "+" ||
                          e.key === "." ||
                          e.code === "Period"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/\D/g, '');
                        setPhoneNumber(numericValue);
                      }}
                      value={phoneNumber}
                      error={error && phoneNumber.includes('.')}
                      helperText={
                        error && phoneNumber.includes('.') ? "Invalid Phone number" : ""
                      }
                      inputProps={{
                        //type: "number",
                        //pattern: "[0-9]*",
                        //inputMode: "numeric",
                      }}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment
                    //       position="end"
                    //       className="cursor-pointer"
                    //       sx={{ marginRight: window.innerWidth >= 1024 ? "" : '6px !important' }}
                    //     >
                    //       <Popup
                    //         arrowStyle={{ color: "#8088A4" }}
                    //         contentStyle={{ backgroundColor: "#8088A4" }}
                    //         trigger={
                    //           <button
                    //             className="trigger-button max-[1023px]:scale-[2.2]"
                    //             type="button"
                    //           >
                    //             <Info size={16} />
                    //           </button>
                    //         }
                    //         position="bottom right"
                    //       >
                    //         <p className="max-lg:text-2xl" style={{ color: "white" }}>
                    //           We will require this to be filled if you want to
                    //           take part in the lucky draw!
                    //         </p>
                    //       </Popup>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    />
                  </ThemeProvider>
                </div>
              </div>
              <div className="w-full flex justify-start items-start">
                <div>
                  <Checkbox
                    className="max-lg:scale-[2]"
                    sx={{ padding: { sm: "5px 15px", md: "5px 15px", lg: "0px 5px" } }}
                    onClick={() => setCheck((prevState) => !prevState)}
                  />
                </div>
                <div>
                  <p>
                    I understand and agree to the {" "}
                    <a href="https://ezythemes.com/terms/" target="blank"><span className="text-blue-400 cursor-pointer">
                      Terms & Conditions,
                    </span></a>{" "}
                    and {" "}
                    <a href="https://ezythemes.com/privacy/" target="blank"><span className="text-blue-400 cursor-pointer">
                      Privacy Statement,
                    </span></a>{" "}
                    of EzyThemes.
                  </p>
                </div>
              </div>

              <div className="flex flex-col justify-center items-center w-full cursor-pointer">
                <ThemeProvider theme={customTheme}>
                  <LoadingButton
                    style={{
                      width: "90%",
                      backgroundColor: check ? "#9747FF" : "#999999",
                      color: "#FFFF",
                      padding: "8px",
                    }}
                    sx={{
                      height: { sm: "70px", md: "70px", lg: "35px" },
                      fontSize: { sm: 25, md: 25, lg: 15 },
                      fontFamily: '"Poppins", sans-serif'
                    }}
                    loading={signUpLoader}
                    loadingPosition="end"
                    variant="contained"
                    onClick={handleSignup}
                    disabled={!check}
                  >
                    Sign up for $450 free credits**
                  </LoadingButton>
                </ThemeProvider>
                <p className="mt-1 text-sm max-lg:text-2xl w-[90%]">** Subject to approval</p>
              </div>
              <div className="flex justify-center items-center ">
                <p>
                  Already have an account ?{" "}
                  <span
                    className="cursor-pointer "
                    style={{ color: "#9747FF" }}
                    onClick={handleLoginSu}
                  >
                    Log in
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  ) : (
    <div className=" flex relative flex-col justify-center items-center h-full w-full "   >
      <svg className=" absolute top-0 mt-[40px] w-screen" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 1440 16" fill="none">
        <path d="M1440 0H-188.109L1440 16V0Z" fill="#9747FF" />
        <path d="M1440 0H-198L1440 9.6V0Z" fill="#85E8E5" />
      </svg>
      <div className="text-center">
        <img
          src={`${window.innerWidth >= 1024 ? "images/EzyThemes logo _vertical_white bg.svg" : "https://ezythemes.com/wp-content/uploads/2024/04/logo.svg"}`}
          style={{ width: window.innerWidth > 1024 ? "200px" : "30vw", height: "auto" }}
          alt="logo"
        />
      </div>
      <div className="text-center">
        {/* {verifiedDomain ? (
              <div className="mb-7">
                <div className="w-4/5	m-auto">
                <h1 className="font-extrabold  text-4xl text-black-600 mb-4">
                  Link Sent
                </h1>
                <p className="text-xl text-gray-500 mb-4 font-medium">
                  Check your email : {signupMail}
                  <span className="text-black-500 font-bold ">
                    {signupMail}
                  </span>
                  .{" "}
                </p>
                <p className="text-xl mt-10 text-gray-500 mb-4 font-medium">
                  You should have received an email from us based on the email
                  address you have entered earlier. Open the link in your email
                  to reset your password.​
                </p>

                <p className="text-xl mt-10 text-gray-500 font-medium">
                  Did not receive the link?{" "}
                  <span
                    className=" cursor-pointer"
                    style={{ color: "#9747FF" }}
                    onClick={handleVerifyMail}
                  >
                    Resend Mail
                  </span>
                  ​
                </p>
              </div>
              </div>
            ) : ( */}
        {window.innerWidth < 1024 && <div className="flex justify-center items-center mb-24">
          <img
            src={"images/EzyThemes logo _vertical_white bg.svg"}
            style={{ width: "30vw", height: "auto" }}
            alt="logo"
          />
        </div>}
        <div className="">
          <h1 className="font-semibold mt-5 text-[31px] text-[#3B3B3B] not-italic"
            style={{ fontFamily: '"Poppins", sans-serif', lineHeight: window.innerWidth <= 1024 ? "1.5" : "default" }}
          >Thank you for your interest!</h1>
          <p className="text-[#8088A4] mt-4 font-medium"
            style={{ fontFamily: '"Poppins", sans-serif', lineHeight: window.innerWidth <= 1024 ? "1.5" : "default" }}
          >
            Our team will review your submission and respond
            <br />
            within the next 2-3 business days to confirm your
            <br />
            eligibility for the offer.
          </p>
        </div>
      </div>
      <svg className=" absolute top-[98.8%] w-screen" xmlns="http://www.w3.org/2000/svg" width="1440" height="19" viewBox="0 0 1440 16" fill="none">
        <path d="M0.000732422 16H1628.11L0.000732422 0V16Z" fill="#9747FF" />
        <path d="M0 16H1638L0 7V16Z" fill="#85E8E5" />
      </svg>
    </div>
    // <div className="grid grid-cols-2 max-lg:flex max-lg:flex-col max-lg:justify-start h-full w-full">
    //   <div className="col-span-1 flex justify-center items-center max-lg:h-[7vh]">
    //     <img
    //       src={`${window.innerWidth >= 1024 ? "images/EzythemesLogo.png" : "	https://ezythemes.com/_next/static/media/Logo.0b7d18cf.svg"}`}
    //       style={{ width: window.innerWidth > 1024 ? "240px" : "30vw", height: "auto" }}
    //       alt="logo"
    //     />
    //   </div>
    //   <div className="col-span-1 bg-white flex flex-col justify-center items-center max-lg:items-start max-lg:justify-end w-full h-full max-lg:height-[93vh] max-lg:pb-[35vh]">
    //     <div className="">
    //       {/* {verifiedDomain ? (
    //           <div className="mb-7">
    //             <div className="w-4/5	m-auto">
    //             <h1 className="font-extrabold  text-4xl text-black-600 mb-4">
    //               Link Sent
    //             </h1>
    //             <p className="text-xl text-gray-500 mb-4 font-medium">
    //               Check your email : {signupMail}
    //               <span className="text-black-500 font-bold ">
    //                 {signupMail}
    //               </span>
    //               .{" "}
    //             </p>
    //             <p className="text-xl mt-10 text-gray-500 mb-4 font-medium">
    //               You should have received an email from us based on the email
    //               address you have entered earlier. Open the link in your email
    //               to reset your password.​
    //             </p>

    //             <p className="text-xl mt-10 text-gray-500 font-medium">
    //               Did not receive the link?{" "}
    //               <span
    //                 className=" cursor-pointer"
    //                 style={{ color: "#9747FF" }}
    //                 onClick={handleVerifyMail}
    //               >
    //                 Resend Mail
    //               </span>
    //               ​
    //             </p>
    //           </div>
    //           </div>
    //         ) : ( */}
    //       {window.innerWidth < 1024 && <div className="flex justify-center items-center mb-20">
    //         <img
    //           src={"images/EzythemesLogo.png"}
    //           style={{ width: "30vw", height: "auto" }}
    //           alt="logo"
    //         />
    //       </div>}
    //       <div className="text-center w-2/3 max-lg:w-full m-auto">
    //         <h1 className="text-3xl max-lg:text-7xl mb-7 font-semibold max-lg:w-[50%] m-auto"
    //           style={{ lineHeight: window.innerWidth <= 1024 ? "1.5" : "default" }}
    //         >Thank you for your interest!</h1>
    //         <p className="text-base max-lg:text-5xl max-lg:px-[5vw] text-gray-400 max-lg:w-[90%] m-auto"
    //           style={{ lineHeight: window.innerWidth <= 1024 ? "1.5" : "default" }}
    //         >
    //           Our team will review your submission and respond within the
    //           next 2-3 business days to confirm your eligibility for the
    //           offer.
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );

}
