import { createAsyncThunk } from "@reduxjs/toolkit";
import { EditProjectAPI } from "../../service/api/editProject";

export const moveProject = createAsyncThunk("moveProject", async ({ project_id, destination_account_id, destination_project_name }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.moveProject({ project_id, destination_account_id, destination_project_name })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const accountProjectList = createAsyncThunk("accountProjectList", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.accountProjectList({ project_id })
        return {
            response: response.data

        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const mergeProject = createAsyncThunk("mergeProject", async ({ destination_account_id, destination_project_name, merge_project_ids }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.mergeProject({ destination_account_id, destination_project_name, merge_project_ids })
        return {
            response: response.data

        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getEntitySentencesData = createAsyncThunk("getEntitySentencesData", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.getEntitySentencesData({ project_id })
        return {
            response: response.data

        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getEntitySentencesSeeContextData = createAsyncThunk("getEntitySentencesSeeContextData", async ({ project_id, question_id }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.getEntitySentencesSeeContextData({ project_id, question_id })
        return {
            response: response.data

        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getThemeMasterData = createAsyncThunk("getThemeMasterData", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.getThemeMasterData({ project_id })
        return {
            response: response.data

        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const getThemeSentencesData = createAsyncThunk("getThemeSentencesData", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.getThemeSentencesData({ project_id })
        return {
            response: response.data

        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const getSummaries = createAsyncThunk("getSummaries", async ({ project_id, type }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.getSummaries({ project_id, type })
        return {
            response: response.data

        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const getEntityTypeData = createAsyncThunk("getEntityTypeData", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.getEntityTypeData({ project_id })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const updateThemeMasterData = createAsyncThunk("updateThemeMasterData", async ({ project_id, qc_step, payload }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.updateThemeMasterData({ project_id, qc_step, payload })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const updateEntitySentenceData = createAsyncThunk("updateEntitySentenceData", async ({ project_id, payload }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.updateEntitySentenceData({ project_id, payload })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getEntityMaster = createAsyncThunk("getEntityMaster", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.getEntityMaster({ project_id })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const updateSummaries = createAsyncThunk("updateSummaries", async ({ project_id, type, changes }: any, { rejectWithValue }) => {
    try {

        const response = await EditProjectAPI.updateSummaries({ project_id, type, changes })

        return {
            response: response.data

        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getAllAccounts = createAsyncThunk("getAllAccounts", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.getAllAccounts()
        return {
            response: response.data

        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }

})

// export const updateProjectScope = createAsyncThunk(
//     "updateProjectScope",
//     async (
//       {
//         project_id,
//         isAutosave,
//         attribute_data,
//         reporting_buckets,
//         themes,
//         entities,
//       }: any,
//       { rejectWithValue }
//     ) => {
//       try {
//         const response = await EditProjectAPI.updateProjectScope({project_id,isAutosave, attribute_data, reporting_buckets, themes, entities});
//         return {
//           response: response.data,
//           project_id, attribute_data, reporting_buckets, themes, entities
//         };
//       } catch (error: any) {
//         throw rejectWithValue(error.data);
//       }
//     }
//   );
export const updateProjectScope = createAsyncThunk("updateProjectScope", async ({ projectId, field, payload }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.updateProjectScope(projectId, field, payload)
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const updateThemeSentenceData = createAsyncThunk("updateThemeSentenceData", async ({ project_id, qc_step, payload }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.updateThemeSentenceData({ project_id, qc_step, payload })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})


export const updateEntityTypeData = createAsyncThunk("updateEntityTypeData", async ({ project_id, payload }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.updateEntityTypeData({ project_id, payload })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getCEEnabledTabs = createAsyncThunk("getCEEnabledTabs", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.getCEEnabledTabs({ project_id })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const updateEntityMaster = createAsyncThunk("updateEntityMaster", async ({ project_id, payload }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.updateEntityMaster({ project_id, payload })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const triggerCEReprocessing = createAsyncThunk("triggerCEReprocessing", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.triggerCEReprocessing({ project_id })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})


export const getSummaryReport = createAsyncThunk("getSummaryReport", async ({ project_id, output_type }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.getSummaryReport({ project_id, output_type })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const updateReportSummary = createAsyncThunk("updateReportSummary", async ({ project_id, payload }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.updateReportSummary({ project_id, payload })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const validateCeSubmit = createAsyncThunk("validateCeSubmit", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await EditProjectAPI.validateCeSubmit(project_id)
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})