import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThemesAPI } from "../../service/api/themesAPI";
import { ProjectAPI } from "../../service/api/createProj";
import { updateSentencesCountToken, updateSentencesPayload } from "../Masters/masterDataReducer";
import axios from "axios";

export const getThemes = createAsyncThunk("getThemes", async ({ project_id, sentiment, question_bucket_id }: any, { rejectWithValue }) => {
    try {
        const response = await ThemesAPI.getThemes({ project_id, sentiment, question_bucket_id })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data);
    }
})


export const getThemeSentences = createAsyncThunk("getThemeSentences", async ({ project_id, theme_id, bucket_type, order_by, question_bucket_id, entity_ids, transcript_id, included_in_ppt_only, sentiment, search, attributes, offset, limit, top_sentence }: any, { rejectWithValue, dispatch }) => {
    try {
        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();
        dispatch(updateSentencesPayload({ project_id, theme_id, bucket_type, order_by, question_bucket_id, entity_ids, transcript_id, included_in_ppt_only, sentiment, search, offset, limit, attributes, source }))
        const response = await ThemesAPI.getThemeSentences({ project_id, theme_id, bucket_type, order_by, question_bucket_id, entity_ids, transcript_id, included_in_ppt_only, sentiment, search, offset, limit, attributes, top_sentence, source })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data);
    }
})

export const getViewByCount = createAsyncThunk("getViewByCount", async ({ project_id, theme_id, bucket_type, question_bucket_id, transcript_id, entity_ids, attributes }: any, { rejectWithValue, dispatch }) => {
    try {
        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();
        dispatch(updateSentencesCountToken(source))
        const response = await ThemesAPI.getViewByCount({ project_id, theme_id, bucket_type, question_bucket_id, transcript_id, entity_ids, attributes, source })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data);
    }
})

export const hideThemes = createAsyncThunk("hideThemes", async ({ payload }: any, { rejectWithValue }) => {
    try {
        const response = await ThemesAPI.hideThemes({ payload })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data);
    }
})

export const mergeThemes = createAsyncThunk("mergeThemes", async ({ projectId, payload, sentiment, selectedQb }: any, { dispatch, rejectWithValue }) => {
    try {
        const response = await ThemesAPI.mergeThemes({ projectId, payload })

        if (response.data.status) {
            if (selectedQb === "All") {
                dispatch(getThemes({ project_id: projectId, sentiment: sentiment, question_bucket_id: selectedQb.question_bucket_id }))
            }
            else {
                dispatch(getThemes({ project_id: projectId, sentiment: sentiment }))
            }
            return response.data;
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data);
    }
})

export const updateSentencePPTSelection = createAsyncThunk("updateSentencePPTSelection", async ({ projectId, updatedPPTSelection }: any, { rejectWithValue }) => {
    try {
        const response = await ThemesAPI.addingSentencesToPPT({ projectId, updatedPPTSelection })
        return { response: response.data }
    } catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const saveUpdatedSentence = createAsyncThunk("saveUpdatedSentence", async ({ projectId, sentenceId, updatedSentence, type }: any, { rejectWithValue }) => {
    try {
        const response = await ThemesAPI.editedSentence({ projectId, sentenceId, updatedSentence, type })
        return { response: response.data }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const deleteSentence = createAsyncThunk("deleteSentence", async ({ projectId, sentenceId, themeId }: any, { rejectWithValue }) => {
    try {
        const response = await ThemesAPI.deleteSentence({ projectId, sentenceId, themeId })
        return { response: response.data }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getTranscriptDataList = createAsyncThunk("getTranscriptDataList", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await ProjectAPI.getProjectsDataList({ project_id })
        return { response: response.data }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getSentenceContext = createAsyncThunk("getSentenceContext", async ({ projectId, sentenceId }: any, { rejectWithValue }) => {
    try {
        const response = await ThemesAPI.getSentenceContext({ projectId, sentenceId })
        return { response: response.data }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getTranscriptIndexes = createAsyncThunk("getTranscriptIndexes", async ({ projectId }: any, { dispatch, rejectWithValue }) => {
    try {
        const response = await ThemesAPI.getTranscriptIndexes({ projectId })
        if (response.data.status) {
            dispatch(getTranscriptBlocks({ projectId: projectId, offset: 1, limit: 8 }))
        }
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getTranscriptBlocks = createAsyncThunk("getTranscriptBlocks", async ({ projectId, offset, limit }: any, { rejectWithValue }) => {
    try {
        const response = await ThemesAPI.getTranscriptBlocks({ projectId, offset, limit })
        return {
            response: response.data,
            offset: offset - 1
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getOrderedTranscriptIds = createAsyncThunk("getOrderedTranscriptIds", async ({ projectId }: any, { rejectWithValue, dispatch }) => {
    try {
        const response = await ThemesAPI.getOrderedTranscriptIds({ projectId })
        if (response?.data) {
            dispatch(getTranscriptData({
                projectId: projectId,
                datasetIds: response.data.data,
                index: 0
            }))
        }
        return {
            response: response.data,
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getTranscriptData = createAsyncThunk("getTranscriptData", async ({ projectId, datasetIds, index }: any, { rejectWithValue, dispatch }) => {
    try {
        const response = await ThemesAPI.getTranscriptData({ projectId: projectId, datasetId: datasetIds[index] })
        let final = false
        if (index + 1 < datasetIds.length) {
            dispatch(getTranscriptData({ projectId: projectId, datasetIds: datasetIds, index: index + 1 }))
        }
        else {
            final = true
        }
        return {
            response: response.data,
            index: index,
            final: final
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})