import { RootState, useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { useLocation, useNavigate } from "react-router-dom";
import { getAccountFilterData, getAllProjects } from "../../redux/Project/action";
import { updateFavoritesProjectsOnly, updateCurrentPaginationPage } from "../../redux/Project/projectDataReducer";
import { HelpCircle, Star } from "react-feather";
import { updateShowHowToGetStarted, updateShowTipsToReduceCost } from "../../redux/Masters/masterDataReducer";
import packagejson from '../../../package.json';
import BuildIcon from '@mui/icons-material/Build';
import { getFeatures } from "../../redux/Masters/action";
import { Tooltip } from "@mui/material";
import { decryption, secrateKey } from "../../modules/LoginLayout/LoginLayout";
import { updateUserInfo } from "../../redux/Profile/profileDataReducer";

export default function DashSideNav() {
  const subPage = useLocation().pathname.split('/').pop()
  const navigate = useNavigate()
  const dispatch = useAppDispatch();

  const isFav = useSelector((state: RootState) => state.project.projectCreated)
  const favoritesProjectsOnly = useSelector((state: RootState) => state.project.favoritesProjectsOnly)

  const [username, setUsername] = useState("");
  const [favoriteActive, setFavoriteActive] = useState(favoritesProjectsOnly);
  const [recent, setRecent] = useState(!favoritesProjectsOnly);
  const [secondRowItems, setSecondRowItems] = useState(false);
  const [secondRowItem, setSecondRowItem] = useState<any>();


  const showHowToGetStarted = useSelector((state: RootState) => state.master.showHowToGetStarted);
  const showTipsToReduceCost = useSelector((state: RootState) => state.master.showTipsToReduceCost);
  const userDetails: any = useSelector((state: RootState) => state.profile.userInfo)
  let encrypted = localStorage.getItem("_U");

  useEffect(() => {
    dispatch(getFeatures({}))
      .then((res: any) => {
        if (res.meta.requestStatus === "fulfilled") {
          if (res.payload.response.user_details?.includes('PROJECTLIST_ACROSS_ACCOUNTS') || res.payload.response.user_details.includes("INTERNAL_UI_EDIT")) {
            dispatch(getAccountFilterData({}))
          }
        }
      })
  }, [])

  useEffect(() => {
    if (isFav || !favoritesProjectsOnly) {
      setFavoriteActive(false)
      setRecent(true)
    }
  }, [])
  useEffect(() => {
    if (recent) {
      setFavoriteActive(false)
    } else {
      setFavoriteActive(true)
    }
  })
  useEffect(() => {

    if (encrypted) {
      let userInfo: any = decryption(encrypted, secrateKey)
      userInfo = JSON.parse(userInfo);
      // dispatch(getUser({userName : userInfo['email']}))
      // dispatch(getUser({userName : userInfo?.email}))
      dispatch(updateUserInfo())
    }
  }, [])

  useEffect(() => {
    if (encrypted) {
      let userInfo: any = decryption(encrypted, secrateKey)

      if (userInfo) {

        setUsername(userDetails?.FIRSTNAME)
      }
    }
  }, [userDetails]);



  useEffect(() => {
    if (!showTipsToReduceCost && !showHowToGetStarted) {
      setSecondRowItems(false)
    }
  }, [showHowToGetStarted, showTipsToReduceCost])

  const handleClick = () => {
    dispatch(updateCurrentPaginationPage(1));
    dispatch(updateFavoritesProjectsOnly(false));
    dispatch(getAllProjects());
    setFavoriteActive(isFav)
    setRecent(true)
    setSecondRowItems(false)
    dispatch(updateShowHowToGetStarted(false));
    dispatch(updateShowTipsToReduceCost(false));

  }

  const handleFavClick = () => {
    dispatch(updateCurrentPaginationPage(1));
    dispatch(updateFavoritesProjectsOnly(true));
    dispatch(getAllProjects());
    setFavoriteActive(!isFav);
    setRecent(false)
    setSecondRowItems(false)
    dispatch(updateShowHowToGetStarted(false));
    dispatch(updateShowTipsToReduceCost(false));

  };

  const onClickHowToGetStarted = () => {
    dispatch(updateShowHowToGetStarted(true));
    dispatch(updateShowTipsToReduceCost(false));
    setSecondRowItems(true)
    setSecondRowItem('HowToGetStarted')

  }

  const onClickTipsToReduceCost = () => {
    dispatch(updateShowTipsToReduceCost(true));
    dispatch(updateShowHowToGetStarted(false));
    setSecondRowItems(true)
    setSecondRowItem('TipsToReduceCost')

  }

  useEffect(() => {
    if (subPage === "projects") {
      handleClick()
    }
    if (subPage === "favourites") {
      handleFavClick()
    }
    else if (subPage === "howToGetStarted") {
      onClickHowToGetStarted()
    }
    else if (subPage === "tipsToReducePrice") {
      onClickTipsToReduceCost()
    }
  }, [subPage])

  return (
    <div
      className="bg-white h-full flex flex-col pt-8 z-10"
      style={{ width: "317px" }}
    >
      <div className="border-r" style={{ height: "97.4px", paddingTop: "48px" }}>
        {username?.length > 15 ? <Tooltip title={username}>
          <span className="w-[315px] truncate " style={{ fontSize: "18px", fontWeight: "600", color: "#3B3B3B", float: "left", paddingLeft: "2.1rem" }}>
            Welcome, {username}
          </span>
        </Tooltip> :
          <span className="w-[315px] truncate " style={{ fontSize: "18px", fontWeight: "600", color: "#3B3B3B", float: "left", paddingLeft: "2.1rem" }}>
            Welcome, {username}
          </span>
        }
      </div>
      <div className="flex justify-between flex-col">
        <div style={{
          width: "100%", height: "4.5rem", cursor: "pointer", paddingLeft: "2.1rem", gap: ".7rem",
          backgroundColor: recent && (!favoriteActive && !secondRowItems) ? "#f5f7fb" : "transparent",
          color: recent && (!favoriteActive && !secondRowItems) ? "#9747FF" : "",
          fontSize: "16px", fontWeight: 500, lineHeight: "24px", letterSpacing: "0em", textAlign: "left",

        }} className="flex items-center" onClick={() => { navigate('/dashboard/projects') }}>
          <DashboardOutlinedIcon />
          <span>Projects</span>
        </div>

        <div
          style={{
            width: "100%", height: "4.5rem", cursor: "pointer", paddingLeft: "2.1rem", gap: ".7rem",
            backgroundColor: (favoriteActive && !secondRowItems) ? "#f5f7fb" : "transparent",
            color: (favoriteActive && !secondRowItems) ? "#9747FF" : "",
            fontSize: "16px", fontWeight: 500, lineHeight: "24px", letterSpacing: "0em", textAlign: "left",
          }}
          className="flex items-center"
          onClick={() => { navigate('/dashboard/favourites') }}
        >
          <Star size={21}
            color="#808080"
            style={{
              width: "18.333332061767578px",
              height: "17.434999465942383px",
              top: "1.8359375px",
              left: "1.8359375px",
              border: "1.5px",
            }}
          />
          <span >Favourites</span>
        </div>

        <div className="mx-5 border border-[#EBEDF9]"></div>
        <div className={`w-full h-[4.5rem] cursor-pointer pl-[2.1rem] gap-[0.7rem] flex items-center ${(secondRowItems && secondRowItem === 'HowToGetStarted') ? 'bg-[#f5f7fb] text-[#9747FF] stroke-[#9747FF]' : 'bg-transparent text-[#6A7792] stroke-[#6A7792]'}`} onClick={() => { navigate('/dashboard/howToGetStarted') }}>
          <HelpCircle size={20} />
          <div className="" style={{
            fontSize: "16px", fontWeight: 500, lineHeight: "24px", letterSpacing: "0em", textAlign: "left"
          }}>How to get started</div>
        </div>
        <div className={`w-full h-[4.5rem] cursor-pointer pl-[2.1rem] gap-[0.7rem] flex items-center ${(secondRowItems && secondRowItem === 'TipsToReduceCost') ? 'bg-[#f5f7fb] text-[#9747FF] stroke-[#9747FF]' : 'bg-transparent text-[#6A7792] stroke-[#6A7792]'}`} onClick={() => navigate('/dashboard/tipsToReducePrice')}>
          <HelpCircle size={20} />
          <div className="" style={{
            // fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0em",
            textAlign: "left"
          }}>Tips to reduce price</div>
        </div>
      </div>
      <div style={{ position: 'fixed', bottom: 0, padding: 10, fontSize: '10px', marginLeft: 10 }}>
        <BuildIcon style={{ fontSize: '10px' }} /> Client Version: {packagejson?.version}
      </div>
    </div>
  );
}
