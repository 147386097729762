import { useEffect } from "react"
import { useAppDispatch } from "../redux/store"
import { updateCurrentPage } from "../redux/currentPage"


const ExportWrapper = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        //    dispatch(getConfigurePPt({project_id:id}))
        //    dispatch(getConfigureProfiles({project_id:id,limit:10000,offset:0}))
        dispatch(updateCurrentPage("Configure PowerPoint"))
    }, [])
    return (
        <></>
    )
}
export default ExportWrapper