import { useTable } from 'react-table';
import React from 'react';
import { log } from 'console';

interface XLSXCSVProps {
  data: Array<any>;
  data2: Array<any>;
  // data3: Array<any>;
}


function XLSXCSV({ data, data2 }: XLSXCSVProps) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Text*',
        accessor: 'text',
      },
      {
        Header: 'Type*',
        accessor: 'type',
      },
      {
        Header: 'Location*',
        accessor: 'location',
      },
      {
        Header: '...',
        accessor: 'optional',
      }
      // {
      //   Header: 'gender',
      //   accessor: 'gender',
      // },
      // {
      //   Header: 'location',
      //   accessor: 'location',
      //   Cell: () => {
      //     const locations = ['Delhi', 'Mumbai', 'Chennai'];
      //     const randomIndex = Math.floor(Math.random() * locations.length);
      //     const randomLocation = locations[randomIndex];
      //     return <td>{randomLocation}</td>;
      //   },
      // },
    ],
    []
  );

  const columns2 = React.useMemo(
    () => [
      {
        Header: 'text',
        accessor: 'dialogue',
      },
      {
        Header: 'speaker',
        accessor: 'speaker',
      },
      {
        Header: 'time_stamp',
        accessor: 'timeStamp',
      },
      {
        Header: 'gender',
        accessor: 'gender',
        Cell: () => <td>{Math.random() < 0.5 ? 'M' : 'F'}</td>,
      },
    ],
    []
  );

  const columns3 = React.useMemo(
    () => [
      {
        Header: 'text',
        accessor: 'text',
      },
      // {
      //   Header: 'speaker',
      //   accessor: 'speaker',
      // },
      // {
      //   Header: 'gender',
      //   accessor: 'gender',
      // },
      // {
      //   Header: 'location',
      //   accessor: 'location',
      // },
      {
        Header: 'type',
        accessor: 'type',
      },
      {
        Header: 'city',
        accessor: 'city',
      }
    ],
    []
  );

  const tableCellStyle = {
    fontFamily: 'Poppins'
  };

  const tableInstance = useTable({ columns, data });
  // const tableInstance2 = useTable({ columns: columns2, data: data2 });
  const tableInstance3 = useTable({ columns: columns3, data: data2 });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  // const {
  //   getTableProps: getTableProps2,
  //   getTableBodyProps: getTableBodyProps2,
  //   headerGroups: headerGroups2,
  //   rows: rows2,
  //   prepareRow: prepareRow2,
  // } = tableInstance2;

  const {
    getTableProps: getTableProps3,
    getTableBodyProps: getTableBodyProps3,
    headerGroups: headerGroups3,
    rows: rows3,
    prepareRow: prepareRow3,
  } = tableInstance3;

  return (
    <div className="list-decimal px-5 p-4  rounded overflow-y-scroll bg-white" style={{ height: '80%' }}>
      <style>
        {`
          /* Style for reducing the size of the scrollbar */
          .overflow-y-scroll::-webkit-scrollbar {
            width: 6px;
          }

          .overflow-y-scroll::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 3px;
          }

          .overflow-y-scroll::-webkit-scrollbar-track {
            background-color: transparent;
          }
        `}
      </style>
      <div className="px-3" >
        <p className="text-[#8088A4] font-normal mb-0 pl-4 pt-8 pb-8 text-sm"
          style={{ fontFamily: '"Poppins", sans-serif' }}>
          To optimize your outputs for higher accurancy,it's best if your transcripts contain this structure defined below :
        </p>
        {/* <p className="text-lg font-bold underline pb-2 pl-0" style={{fontFamily : 'poppins' , fontSize : '18px'}}>Example 1</p> */}
        <div className="border border-[#3B3B3B] rounded-lg ml-4 w-4/5  text-sm font-medium not-italic" style={tableCellStyle}>
          <table {...getTableProps()} className="table ">
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="h-16">
                  {headerGroup.headers.map((column: any, columnIndex: any) => (
                    <th
                      {...column.getHeaderProps()}
                      className={`py-2 px-4 border-b text-[#8088A4] text-left  ${columnIndex === headerGroup.headers.length ? 'border-r border-[#E5E5E5]' : 'border-l border-[#E5E5E5]'
                        }`}

                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="h-12">
                    {row.cells.map((cell: any, cellIndex: any) => (
                      <td
                        {...cell.getCellProps()}
                        className={`py-6 px-6  text-sm text-[#3B3B3B] rounded-lg font-medium not-italic mb-8	 ${' border-l border-[#E5E5E5] rounded-lg'
                          }`}
                        style={tableCellStyle}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <p className="text-[#8088A4] font-normal mb-0 pl-4 pt-8 pb-7 text-sm"
          style={{ fontFamily: '"Poppins", sans-serif', lineHeight: 'normal' }}>
          *Compulsory columns
        </p>
        {/* <p className="text-lg font-bold underline pb-2 pl-0 mt-8" style={{fontFamily : 'poppins', fontSize : '18px'}} >Example 2</p>
        <div className="border border-black rounded-lg" style={tableCellStyle}>
        <table {...getTableProps2()} className="table w-full">
          <thead>
            {headerGroups2.map((headerGroup :any) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="h-16">
                {headerGroup.headers.map((column :any, columnIndex:any) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`py-2 px-4 border-b text-gray-500 text-left ${
                      columnIndex === 0 ? 'w-1/2 border-r border-gray-400' : 'border-r border-gray-400'
                    }`}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps2()}>
            {rows2.map((row:any) => {
              prepareRow2(row);
              return (
                <tr {...row.getRowProps()} className="h-12">
                  {row.cells.map((cell :any, cellIndex :any) => (
                    <td
                      {...cell.getCellProps()}
                      className={`py-2 px-4 border-b border-gray-400 text-sm ${
                        cellIndex === 0 ? 'w-3/4 border-r border-gray-400' : 'border-r border-gray-400'
                      }`}
                      style={tableCellStyle}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div> */}

        <div className='pl-4 '>


          <p className="text-lg font-bold pb-2 mb-4 text-[#F59E1B] " style={{ fontFamily: 'poppins' }}>Example</p>
          <div className="border border-black rounded-lg w-2/5 text-sm font-medium not-italic " style={tableCellStyle}>
            <table {...getTableProps3()} className="table pl-4">
              <thead>
                {headerGroups3.map((headerGroup: any) => (
                  <tr {...headerGroup.getHeaderGroupProps()} className="h-16">
                    {headerGroup.headers.map((column: any, columnIndex: any) => (
                      <th
                        {...column.getHeaderProps()}
                        className={`py-2 px-4 border-b text-[#8088A4] text-left ${columnIndex === headerGroup.headers.length ? 'border-r border-[#E5E5E5]' : 'border-l border-[#E5E5E5]'
                          }`}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps3()}>
                {rows3.map((row: any) => {
                  prepareRow3(row);
                  return (
                    <tr {...row.getRowProps()} className="h-12">
                      {row.cells.map((cell: any, cellIndex: any) => (
                        <td
                          {...cell.getCellProps()}
                          className={`py-2 pl-4 px-4 border-b text-sm text-[#3B3B3B font-medium not-italic]${cellIndex < 2 ? 'w-3/4 border-r  border-[#E5E5E5] rounded-lg' : 'border-l border-[#E5E5E5] rounded-lg'
                            }`}
                          style={tableCellStyle}
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='flex justify-center mt-8'>
        <div className="p-2 bg-[#9747FF] rounded-md text-[#F6F6F6] w-[20vw] cursor-pointer bg-size-200 text-center my-auto"
          onClick={() => { window.close() }} >Got it!</div>
      </div>
    </div>
  );
}

export default XLSXCSV;
