import { useEffect } from "react"
import { useSelector } from "react-redux"
import { updateCurrentPage } from "../redux/currentPage"
import { getAssociations, getAttributes, getMentions, getQBPhrases, getQuestionBucket } from "../redux/Masters/action"
import { updateCombineQBSuccess, updateHideUnhideQBSuccess, updateRenameAssocSuccess, updateSelectedQB, updateCurrentPaginationPage } from "../redux/Masters/masterDataReducer"
import { RootState, useAppDispatch } from "../redux/store"
import { useLocation } from "react-router-dom"

export default function ManageWrapper() {

    const dispatch = useAppDispatch();
    const pathname = useLocation().pathname;
    const id = pathname.split('/')[2];
    const masterSentiment = useSelector((state: RootState) => state.master.masterSentiment)
    const combineQbSuccess = useSelector((state: RootState) => state.master.combineQBSuccess)
    const hideUnhideQBSuccess = useSelector((state: RootState) => state.master.hideUnhideQBSuccess)
    const renameAssocSuccess = useSelector((state: RootState) => state.master.renameAssocSuccess)
    const selectedQbs = useSelector((state: RootState) => state.master.selectedQuestionBuc)
    const selectedAttr = useSelector((state: RootState) => state.master.selectedAttr)
    const paginationLimit = useSelector((state: RootState) => state.master.paginationLimit);
    const currentPage = useSelector((state: RootState) => state.master.currentPage);


    function formatAttributesList(arrayList: any) {
        let newList: any = []
        arrayList.map((item: any) => {
            const found = newList.filter((elem: any) => elem.name === item.attr_id).length > 0;
            if (!found) {
                newList.push({
                    name: item.attr_id,
                    value: [item.name],
                })
            } else {
                newList = newList.map((elem: any) => {
                    if (elem.name === item.attr_id) {
                        return {
                            ...elem,
                            value: [...elem.value, item.name]
                        }
                    } else {
                        return elem
                    }
                })
            }
        })
        return newList
    }

    function getQuestionBucketIds(selectedQbs: any[]) {
        let seleQbs: Array<number> = [];
        selectedQbs.map((item: any) => {
            item.question_buckets.map((elem: any) => {
                seleQbs.push(elem.question_bucket_id);
            });
        });
        return seleQbs;
    }

    useEffect(() => {
        dispatch(getQuestionBucket({ project_id: id }))
        dispatch(getAttributes({ project_id: id }))
        dispatch(updateCurrentPage("Manage Question Buckets"))
        dispatch(updateSelectedQB([]))
    }, [])

    useEffect(() => {
        const seleQbs = getQuestionBucketIds(selectedQbs);
        const updatedAttributesList = formatAttributesList(selectedAttr);

        if (seleQbs.length !== 0 && (updatedAttributesList.length !== 0 || updatedAttributesList.length === 0)) {
            dispatch(getQBPhrases({
                project_id: id, selectedQbs: seleQbs, sentiment: masterSentiment,
                attributes: updatedAttributesList,
                offset: paginationLimit * (currentPage - 1),
                limit: paginationLimit,
                associations: [],
                mentions: [],
                search_phrase: "",
                search_sentence: ""
            }))
            dispatch(getAssociations({
                project_id: id,
                selectedQbs: seleQbs,
                sentiment: masterSentiment,
                attributes: updatedAttributesList
            }))
            dispatch(getMentions({
                project_id: id,
                selectedQbs: seleQbs,
                sentiment: masterSentiment,
                attributes: updatedAttributesList
            }))
        }
        dispatch(updateCurrentPaginationPage(1))

    }, [masterSentiment, selectedQbs, selectedAttr])

    useEffect(() => {
        if (combineQbSuccess) {
            dispatch(getQuestionBucket({ project_id: id }))
            dispatch(updateCombineQBSuccess(false))
        }
    }, [combineQbSuccess])

    useEffect(() => {
        if (hideUnhideQBSuccess) {
            dispatch(getQuestionBucket({ project_id: id }))
            dispatch(updateHideUnhideQBSuccess(false))
        }
    }, [hideUnhideQBSuccess])

    useEffect(() => {
        if (renameAssocSuccess) {

            // dispatch(getAssociations({project_id: id,
            //     selectedQbs: seleQbs,
            //     sentiment: masterSentiment,
            //     attributes:  updatedAttributesList 
            // }))
            dispatch(updateRenameAssocSuccess(false))
        }
    }, [renameAssocSuccess])


    return (
        <></>
    )
}