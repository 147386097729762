import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import { RootState, useAppDispatch } from "../../redux/store";
import {
  setScopeUpdated,
  setTableChangeStatus,
  setupdateSummaries,
  setupdateSummary,
  updateIsEditedSummaryReport,
  updateReportSummaryData,
} from "../../redux/EditProject/EditProjectReducer";
import { useSelector } from "react-redux";

interface ChangeThemeTypeProps {
  cell: any;
  row: any;
  column: any;
  onSelectionChange: (
    projectId: string,
    projectName: string,
    isSelected: boolean
  ) => void;
}

const dropDownData = ["Yes", "No"];

const ChangeThemeType: React.FC<ChangeThemeTypeProps> = ({
  cell,
  row,
  column,
  onSelectionChange,
}) => {
  const initialValue = dropDownData.includes(cell.getValue())
    ? cell.getValue()
    : "No";
  const [value, setValue] = useState<string>(initialValue);

  const onChange = (e: SelectChangeEvent<string>) => {
    const currVal = e.target.value;
    setValue(currVal);
    onSelectionChange(
      row.original.PROJECT_ID,
      row.original.PROJECT_NAME,
      currVal === "Yes"
    );
  };

  return (
    <div className="w-full hover:bg-white transition-all">
      <Select
        value={value}
        onChange={onChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        className="w-full h-10"
        MenuProps={{
          style: {
            maxHeight: 400,
          },
        }}
        sx={{
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          ".MuiSvgIcon-root": {
            fill: "black !important",
          },
        }}
      >
        {dropDownData.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default ChangeThemeType;

function SeeContextCol({ cell, column, row, table }: any) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const selectedData = row.original;
  const dispatch = useAppDispatch();
  const summaryData = useSelector((state: RootState) => state.editProject.summaryData);
  const activeTab = useSelector(
    (state: RootState) => state.editProject.activeTab
  );
  const payloadSummary = useSelector(
    (state: RootState) => state.editProject.payloadSummary
  );

  const [sentenceSummaryData, setSentenceSummaryData] = useState(summaryData)
  const [editedSummary, setEditedSummary] = useState(row.original?.SUMMARY); // State to keep track of edited summary


  useEffect(() => {
    setSentenceSummaryData(summaryData)
    setEditedSummary(sentenceSummaryData[row?.id]?.SUMMARY)
  }, [summaryData])


  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (selectedData: any) => {
    const idField = idMapping[activeTab];
    let id = selectedData[idField];
    let p_key = selectedData.P_KEY;

    let update = [...payloadSummary];
    const is_exist = update?.find((each: any) => each?.id == id);

    if (is_exist === undefined) {
      if (id !== undefined) {
        update.push({
          id: id,
          p_key: p_key,
          updated_summary: editedSummary,
        });
      }
    } else {
      update = update?.map((each: any) => {
        if (each.id === id) {
          return { ...each, updated_summary: editedSummary };
        }
        return { ...each };
      });
    }

    dispatch(setupdateSummaries(update));
    setAnchorEl(null);
  };

  const handleSummaryChange = (
    event: any,
    { selectedData, editedSummary }: any
  ) => {
    dispatch(setScopeUpdated(true))
    const idField = idMapping[activeTab];
    const updatedSummaryData = sentenceSummaryData.map((item: any) => {
      if (item[idField] === row.original[idField]) {
        return { ...item, SUMMARY: event.target.value };
      }
      return item;
    });
    setSentenceSummaryData(updatedSummaryData)
    dispatch(setupdateSummary(updatedSummaryData));
    dispatch(setTableChangeStatus({ tableName: activeTab, hasChanges: true }));
  };

  const idMapping = {
    "Analysis topics Summary": "REPORTING_BUCKET_ID",
    "Entities Summary": "ENTITY_ID",
    "Themes Summary": "THEME_ID",
    "Market Summary": "DISPLAY_NAME",
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <div
        className="underline cursor-pointer text-blue-500 hover:text-blue-800 transition-all"
        onClick={handleClick}
      >
        see Sentences
      </div>

      <Popup
        modal
        open={open}
        onClose={() => handleClose(selectedData)}
        contentStyle={{
          width: "60vw",
          maxHeight: "80vh",
          opacity: 1,
          boxShadow: "0px 0px 1px white",
          backgroundColor: "white",
          borderRadius: "6px",
        }}
      >
        <div className="max-h-[80vh]">
          <div className="w-full flex justify-between items-start mt-2 px-3">
            {activeTab == "Entities Summary" ? (
              <span className="text-gray-800 font-extrabold text-lg">
                {selectedData?.TYPE} :
                <span className="font-semibold text-gray-600 ml-2">
                  {selectedData?.DISPLAY_NAME}
                </span>
              </span>
            ) : (
              <span className="text-gray-800 font-extrabold text-lg">
                {selectedData?.DISPLAY_NAME} :
                <span className="font-semibold text-gray-600 ml-2">
                  {selectedData?.TYPE}
                </span>
              </span>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-6 h-6 cursor-pointer"
              onClick={handleClose}
            >
              <path
                fill-rule="evenodd"
                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm2.78-4.22a.75.75 0 0 1-1.06 0L8 9.06l-1.72 1.72a.75.75 0 1 1-1.06-1.06L6.94 8 5.22 6.28a.75.75 0 0 1 1.06-1.06L8 6.94l1.72-1.72a.75.75 0 1 1 1.06 1.06L9.06 8l1.72 1.72a.75.75 0 0 1 0 1.06Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div className="mt-2">
            <span className="font-semibold text-gray-800 text-lg mx-3">
              Summary
            </span>
            <textarea
              id="editedSummary"
              rows={4}
              className="block p-4 w-[98%] ml-2 text-sm text-gray-900 bg-gray-50 border-2 border-gray-400"
              value={sentenceSummaryData[row?.id]?.SUMMARY}
              onChange={(event: any) => {
                setEditedSummary(event.target.value);
                handleSummaryChange(event, { selectedData, editedSummary })
              }
              }
              // onBlur={saveEditedSummary} // Optionally save when the user navigates away from the textarea
              style={{
                boxSizing: "border-box",
                outline: "none",
                resize: "none",
              }}
            />
          </div>
          <span className="font-semibold text-gray-800 mx-3 pb-2 text-lg">
            Sentences
          </span>
          <div className="flex flex-col justify-start items-start border-2 border-gray-400 max-h-[40vh] overflow-y-auto w-[98%] mx-2 my-2">
            {selectedData?.SENTENCES?.map((each: any) => (
              <div className="my-2 border-b-2 border-gray-400 w-full">
                <span className="font-medium text-gray-600 px-2">
                  {each.SENTENCE}
                </span>
              </div>
            ))}
          </div>
        </div>
      </Popup>
    </div>
  );
}

function SeeContextColReportEmailSummary({ cell, column, row, table }: any) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const textAreaRef: any = useRef(null);

  const open: boolean = Boolean(anchorEl);


  const selectedData = row.original;
  const dispatch = useAppDispatch();
  const reportSummaryData = useSelector((state: RootState) => state.editProject.reportSummaryData)
  const activeTab = useSelector(
    (state: RootState) => state.editProject.activeTab
  );
  const payloadSummary = useSelector(
    (state: RootState) => state.editProject.payloadSummary
  );

  const [sentenceSummaryData, setSentenceSummaryData] = useState(reportSummaryData)
  const [editedSummary, setEditedSummary] = useState(row.original?.SUMMARY); // State to keep track of edited summary

  useEffect(() => {
    setTimeout(() => {
      if (open && textAreaRef.current) {
        textAreaRef.current.focus();

        textAreaRef.current.selectionStart = textAreaRef.current.value.length;
        textAreaRef.current.selectionEnd = textAreaRef.current.value.length;
      }

    }, 150);


  }, [open, textAreaRef]);


  useEffect(() => {
    setSentenceSummaryData(reportSummaryData)
    setEditedSummary(sentenceSummaryData[row?.id]?.SUMMARY)
  }, [reportSummaryData])


  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (selectedData: any) => {
    setAnchorEl(null);
  };

  const handleSummaryChange = (event: any, { selectedData, editedSummary }: any) => {
    dispatch(setScopeUpdated(true))
    const updatedSummaryData = sentenceSummaryData.map((item: any, index: number) => {
      if (row.index === index) {
        return { ...item, SUMMARY: event.target.value, isEdited: true }
      }
      return item
    })

    setSentenceSummaryData(updatedSummaryData)
    dispatch(updateReportSummaryData(updatedSummaryData))
    dispatch(updateIsEditedSummaryReport(true));
  };


  return (
    <div>
      <div
        className="underline cursor-pointer text-blue-500 hover:text-blue-800 transition-all"
        onClick={handleClick}
      >
        See Sentences
      </div>

      <Popup
        modal
        open={open}
        onClose={() => handleClose(selectedData)}
        contentStyle={{
          width: "60vw",
          maxHeight: "80vh",
          opacity: 1,
          boxShadow: "0px 0px 1px white",
          backgroundColor: "white",
          borderRadius: "6px",
        }}
      >
        <div className="max-h-[80vh]">
          <div className={`w-full flex ${selectedData?.TOPIC_VALUE ? "justify-between" : "justify-end"} items-start mt-2 px-3`}>
            {selectedData?.TOPIC_VALUE && <span className="text-gray-800 font-extrabold text-lg">
              {selectedData?.TOPIC_TYPE} :
              <span className="font-semibold text-gray-600 ml-2 ">
                {selectedData?.TOPIC_VALUE}, {"  "}
                <span className="ml-3">
                  {selectedData.NESTING_TYPE ? `Nesting value:  ${selectedData.NESTING_TYPE}` : ""}
                </span>
              </span>
            </span>}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-6 h-6 cursor-pointer"
              onClick={handleClose}
            >
              <path
                fill-rule="evenodd"
                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm2.78-4.22a.75.75 0 0 1-1.06 0L8 9.06l-1.72 1.72a.75.75 0 1 1-1.06-1.06L6.94 8 5.22 6.28a.75.75 0 0 1 1.06-1.06L8 6.94l1.72-1.72a.75.75 0 1 1 1.06 1.06L9.06 8l1.72 1.72a.75.75 0 0 1 0 1.06Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div className="mt-2">
            <span className="font-semibold text-gray-800 text-lg mx-3">
              Summary
            </span>
            <textarea
              ref={textAreaRef}
              id="editedSummary"
              rows={4}
              className="block p-4 w-[98%] ml-2 text-sm text-gray-900 bg-gray-50 border-2 border-gray-400"
              value={sentenceSummaryData[row?.id]?.SUMMARY}
              onChange={(event: any) => {
                setEditedSummary(event.target.value);
                handleSummaryChange(event, { selectedData, editedSummary })
              }
              }
              // onBlur={saveEditedSummary} // Optionally save when the user navigates away from the textarea
              style={{
                boxSizing: "border-box",
                outline: "none",
                resize: "none",
              }}
            />
          </div>
          {selectedData?.SENTENCES?.length &&
            <>
              <span className="font-semibold text-gray-800 mx-3 pb-2 text-lg">
                Sentences
              </span>
              <div className="flex flex-col justify-start items-start border-2 border-gray-400 max-h-[40vh] overflow-y-auto w-[98%] mx-2 my-2">
                {selectedData?.SENTENCES?.map((each: any, index: number) => (
                  <div className={`my-2 ${index == selectedData?.SENTENCES?.length - 1 ? "" : "border-b-2 border-gray-400"} w-full`}>
                    <span className="font-medium text-gray-600 px-2">
                      {each.SENTENCE}
                    </span>
                  </div>
                ))}
              </div>
            </>
          }
        </div>
      </Popup>
    </div>
  );
}

function RenameThemeCol({ cell, column, row, onCellChange, tableName }: any) {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(cell.getValue());
  }, [cell.getValue()])

  var currentDataState: any = [];

  const onChange = (e: any) => {
    const currVal = e.target.value === "" ? null : e.target.value;
    setValue(currVal);

    onCellChange(tableName, row.index, "Summaries", currVal);

    const temp = { row: row.index, col: "Summaries", val: currVal };
    let update = true;
    const stateData = currentDataState.map((item: any) => {
      if (item.row === row.index && item.col === "Summaries") {
        update = false;
        return temp;
      }
      return item;
    });
    if (update) {
      currentDataState.push(temp);
    } else {
      currentDataState = stateData;
    }
    row._valuesCache[column.id] = currVal;
  };

  return (
    <textarea
      id="message"
      rows={4}
      className="block p-4 w-128 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
      value={value}
      onChange={onChange}
      style={{
        boxSizing: "border-box",
        width: "100%",
        border: "none",
        outline: "none",
        resize: "none",
      }}
    />
  );
}

export const Analysis_topics_Summary = (handleCellChange: any) => [
  {
    accessorKey: 'sl_no',
    header: 'Sl.No',
    size: 100,
    filterFn: 'contains',
  },
  { header: "Analysis Topics", accessorKey: "DISPLAY_NAME", size: 300, filterFn: 'contains' },
  {
    header: "Summaries",
    accessorKey: "SUMMARY",
    size: 800,
    enableSorting: false,
    filterFn: 'contains',
    Cell: (props: any) => (
      <RenameThemeCol
        {...props}
        onCellChange={handleCellChange}
        tableName="Analysis topics Summary"
      />
    ),
  },
  {
    header: "See sentences",
    accessorKey: "see sentences",
    // filterFn: 'contains',
    Cell: SeeContextCol,
    size: 100,
    enableSorting: false,
    enableGlobalFilterModes: false,
    enableColumnActions: false,
  },
];


export const report_summary = (handleCellChange: any) => [
  {
    accessorKey: 'sl_no',
    header: 'Sl.No',
    size: 100,
    filterFn: 'contains',
  },
  { header: "Topic Type", accessorKey: "TOPIC_TYPE", size: 220, filterFn: 'contains' },
  { header: "Topic Value", accessorKey: "TOPIC_VALUE", size: 220, filterFn: 'contains' },
  { header: "Nesting Value", accessorKey: "NESTING_TYPE", size: 220, filterFn: 'contains' },
  {
    header: "Summaries",
    accessorKey: "SUMMARY",
    size: 600,
    enableSorting: false,
    filterFn: 'contains',
    Cell: (props: any) => (
      <RenameThemeCol
        {...props}
        onCellChange={handleCellChange}
        tableName="Report"
      />
    ),
  },
  {
    header: "See sentences",
    accessorKey: "see sentences",
    Cell: SeeContextColReportEmailSummary,
    size: 130,
    enableSorting: false,
    enableGlobalFilterModes: false,
    enableColumnActions: false,
    // enableColumnFilters:false  
  },
];


export const email_summary = (handleCellChange: any) => [
  {
    accessorKey: 'sl_no',
    header: 'Sl.No',
    size: 100,
    filterFn: 'contains',
  },
  { header: "Topic Type", accessorKey: "TOPIC_TYPE", size: 220, filterFn: 'contains' },
  { header: "Topic Value", accessorKey: "TOPIC_VALUE", size: 220, filterFn: 'contains' },
  {
    header: "Summaries",
    accessorKey: "SUMMARY",
    size: 800,
    enableSorting: false,
    filterFn: 'contains',
    Cell: (props: any) => (
      <RenameThemeCol
        {...props}
        onCellChange={handleCellChange}
        tableName="Email"
      />
    ),
  },
  {
    header: "See sentences",
    accessorKey: "see sentences",
    Cell: SeeContextColReportEmailSummary,
    size: 130,
    enableSorting: false,
    enableGlobalFilterModes: false,
    enableColumnActions: false,
    // enableColumnFilters:false 
  },
];

export const Entities_Summary = (handleCellChange: any) => [
  {
    accessorKey: 'sl_no',
    header: 'Sl.No',
    size: 100,
    filterFn: 'contains',
  },
  { header: "Entity type", accessorKey: "TYPE", filterFn: 'contains', },
  { header: "Anchor", accessorKey: "DISPLAY_NAME", filterFn: 'contains', },
  {
    header: "Summaries",
    accessorKey: "SUMMARY",
    filterFn: 'contains',
    size: 600,
    enableSorting: false,
    Cell: (props: any) => (
      <RenameThemeCol
        {...props}
        onCellChange={handleCellChange}
        tableName="Entities Summary"
      />
    ),
  },
  {
    header: "See sentences",
    accessorKey: "see sentences",
    // filterFn: 'contains',
    Cell: SeeContextCol,
    size: 200,
    enableSorting: false,
    enableGlobalFilterModes: false,
    enableColumnActions: false,
  },
];

export const Themes_Summary = (handleCellChange: any) => [
  {
    accessorKey: 'sl_no',
    header: 'Sl.No',
    size: 100,
    filterFn: 'contains',
  },
  { header: "Theme", accessorKey: "DISPLAY_NAME", size: 200, filterFn: 'contains', },
  { header: "Theme type", accessorKey: "TYPE", size: 200, filterFn: 'contains', },
  {
    header: "Summaries",
    accessorKey: "SUMMARY",
    filterFn: 'contains',
    size: 720,
    enableSorting: false,
    Cell: (props: any) => (
      <RenameThemeCol
        {...props}
        onCellChange={handleCellChange}
        tableName="Themes Summary"
      />
    ),
  },
  {
    header: "See sentences",
    accessorKey: "see sentences",
    // filterFn: 'contains',
    Cell: SeeContextCol,
    size: 200,
    enableSorting: false,
    enableGlobalFilterModes: false,
    enableColumnActions: false,
  },
];
export const Market_Summary = (handleCellChange: any) => [

  {
    accessorKey: 'sl_no',
    header: 'Sl.No',
    size: 100,
    filterFn: 'contains',
  },
  { header: "Topics", accessorKey: "DISPLAY_NAME", size: 300, filterFn: 'contains', },
  {
    header: "Summaries",
    accessorKey: "SUMMARY",
    filterFn: 'contains',
    size: 1140,
    enableSorting: false,
    Cell: (props: any) => (
      <RenameThemeCol
        {...props}
        onCellChange={handleCellChange}
        tableName="Market Summary"
      />
    ),
  },
];



// For Report Table Header 
export const ReportTableColumn: any = [
  {
    accessorKey: 'sl_no',
    header: 'Sl.No',
    size: 150,
    filterFn: 'contains',
  },
  {
    accessorKey: 'topic_type',
    header: 'Topic Type',
    size: 200,
    filterFn: 'contains',
  },
  {
    accessorKey: 'topic_value',
    header: 'Topic Value',
    size: 200,
    filterFn: 'contains',
  },
  {
    accessorKey: 'nesting_value',
    header: 'Nesting Value',
    size: 200,
    filterFn: 'contains',
  },
  {
    accessorKey: 'summaries',
    header: 'Summaries',
    size: 600,
    filterFn: 'contains',
  },
  {
    accessorKey: 'see_sentences',
    header: 'See sentences',
    Cell: SeeContextCol,
    size: 200,
    filterFn: 'contains',
  },
]

// For Email Table  Header
export const EmailTableColumn: any = [
  {
    accessorKey: 'sl_no',
    header: 'Sl.No',
    size: 150,
    filterFn: 'contains',
  },
  {
    accessorKey: 'topic_type',
    header: 'Topic Type',
    size: 150,
    filterFn: 'contains',
  },
  {
    accessorKey: 'topic_value',
    header: 'Topic Value',
    size: 150,
    filterFn: 'contains',
  },
  {
    accessorKey: 'summaries',
    header: 'Summaries',
    size: 750,
    filterFn: 'contains',
  },
  {
    accessorKey: 'see_sentences',
    header: 'See sentences',
    size: 200,
    Cell: SeeContextCol,
    filterFn: 'contains'
  },


]

export const Merge_col: any = [
  {
    accessorKey: 'sl_no',
    header: 'Sl.No',
    size: 100,
    filterFn: 'contains',
  },
  {
    header: "ACCOUNT_NAME",
    accessorKey: "ACCOUNT_NAME",
    filterFn: 'contains',
    enableSorting: false,
    enableGlobalFilterModes: false,
    enableColumnActions: false,
    enableColumnFilters: false,
  },
  { header: "PROJECT_NAME", accessorKey: "PROJECT_NAME", size: 380, filterFn: 'contains', },
  { header: "CATEGORY_NAME", accessorKey: "CATEGORY_NAME", filterFn: 'contains', },
  { header: "STATUS", accessorKey: "STATUS", size: 180, filterFn: 'contains', },
  {
    header: "SELECT_TO_MERGE",
    accessorKey: "SELECT_TO_MERGE",
    filterFn: 'contains',
    Cell: ChangeThemeType,
  },
];
