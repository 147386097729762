import { useEffect, useRef, useState } from "react"
import './downloadPptStyle.css'
import CircularProgress from '@mui/material/CircularProgress';
import { RootState, useAppDispatch } from "../../redux/store";
import { useParams } from "react-router-dom";
import { getExportPptReportsData } from '../../redux/Masters/action';
import { useSelector } from "react-redux";
import axios from 'axios';
import Spinner from "../../components/Spinner";
import { updateCurrentPage } from "../../redux/currentPage";
import { Tooltip } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { XCircle } from "react-feather";
import Popup from "reactjs-popup";
import { decryption, secrateKey } from "../LoginLayout/LoginLayout";
import settings from "../../config";
import moment from 'moment';



function DownloadPPT() {
  const heightRef: any = useRef();
  const dispatch = useAppDispatch()
  const projectId = useParams().id
  const selectedProj = useSelector((state: RootState) => state.config.selectedProject)
  const featuresData = useSelector((state: RootState) => state.master.featuresData);
  const exportPptReportsData = useSelector((state: RootState) => state.master.exportPptReportsData);
  const exportPptReportsDataIsEmpty = useSelector((state: RootState) => state.master.exportPptReportsDataIsEmpty);

  const [data, setData] = useState<any>([])
  const [displayData, setDisplayData] = useState<any>([])
  const [loadingData, setLoadingData] = useState(true)

  const [height, setHeight] = useState<any>(0);
  const [searchValue, setSearchValue] = useState<any>('')
  const [systemReportData, setSystemReportData] = useState<any>([]);
  const [isDefault, setIsDefault] = useState<boolean>(true);

  const systemGeneratedReports = data.filter(
    (report: any) => report.DESCRIPTION === "System-generated project report" || report.IS_SYSTEM === true
  );
  const customGeneratedReports = displayData?.filter(
    (report: any) => report.IS_SYSTEM === false || report.DESCRIPTION !== "System-generated project report"
  );


  useEffect(() => {
    const projectDate = new Date(selectedProj.CREATED_AT).getTime();
    const validDate = new Date("2024-05-31").getTime();
    setIsDefault(projectDate > validDate)
    let data: any[] = [];
    if (projectDate > validDate) {
      data = [
        {
          ID: 1,
          TRIGGER_TIME: selectedProj.CREATED_AT,
          DESCRIPTION: "System-generated summary report",
          REPORT_NAME: "Summary Report",
          STATUS: 2,
          IS_ACTIVE: true,
          IS_SYSTEM: true,
          IS_SHOW: featuresData?.includes("DOWNLOAD_TWO_PAGE_SUMMARY_REPORT") && projectDate > validDate,
          IS_DOWNLOAD_SUMMARY: true,
          FILE_EXTENSION: "pdf"
        },
        {
          ID: 2,
          TRIGGER_TIME: selectedProj.CREATED_AT,
          DESCRIPTION: "System-generated data export",
          REPORT_NAME: "Data Export",
          STATUS: 2,
          IS_ACTIVE: true,
          IS_SYSTEM: true,
          IS_SHOW: featuresData?.includes("DOWNLOAD_EXCEL_SUMMARY_REPORT") && projectDate > validDate,
          IS_DOWNLOAD_SUMMARY: true,
          FILE_EXTENSION: "xlsx"

        }
      ]
    } else {
      data = systemGeneratedReports?.map((report: any) => { return { ...report, IS_SHOW: true, IS_DOWNLOAD_SUMMARY: false } })
    }
    setSystemReportData(data);
  }, [selectedProj, data, featuresData])

  useEffect(() => {
    if (heightRef.current) {
      const TopPos = heightRef.current?.getBoundingClientRect().top;
      setHeight(window.innerHeight - TopPos - 100);
    }
  });

  useEffect(() => {
    dispatch(updateCurrentPage("Download"));
    setData([]);
    (async function () {
      await dispatch(getExportPptReportsData({ projectId }));
      setData(exportPptReportsData)
    })();
  }, [])

  const [isDataUpdated, setIsDataUpdated] = useState(false)


  useEffect(() => {
    if (!isDataUpdated) {
      const timeoutId = setTimeout(() => {
        (async function () {
          await dispatch(getExportPptReportsData({ projectId }));
          setData(exportPptReportsData)
          setIsDataUpdated(true)
          setLoadingData(false)
        })();
      }, 2000);
      // cleanup function
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [exportPptReportsData])

  useEffect(() => {
    const intervalId = setInterval(() => {
      (async function () {
        await dispatch(getExportPptReportsData({ projectId }));
        setData(exportPptReportsData)
        setIsDataUpdated(true)
      })();
    }, 5000);
    // cleanup function
    return () => {
      clearInterval(intervalId);
    };
  }, [exportPptReportsData])

  useEffect(() => {
    if (data.length !== 0 && isDataUpdated) {
      setLoadingData(false)
    }
  }, [data])

  useEffect(() => {
    if (exportPptReportsDataIsEmpty === true && isDataUpdated) {
      setLoadingData(false)
    }
  }, [exportPptReportsDataIsEmpty])

  useEffect(() => {
    setDisplayData([...data].filter((item: any) => item.IS_ACTIVE).reverse().filter((item: any) => item?.REPORT_NAME?.toLowerCase().includes(searchValue.toLocaleLowerCase())))
  }, [data, searchValue])

  return (
    <div ref={heightRef} className="w-full flex flex-col justify-start items-center">
      {(loadingData) &&
        <div className="bg-[#FFFFFF]  w-[80vw] rounded-lg shadow-lg flex justify-center items-center align-items-center border" style={{ height: height }}>
          <Spinner height={20} />
        </div>
      }

      <div className="flex justify-center items-center ">
        {!loadingData &&
          <div className={`bg-[#FFFFFF]  w-[80vw] rounded-lg shadow-lg`}>
            <h1 className="text-lg p-1 font-bold">System Generated Reports</h1>
            <div className=" grid grid-cols-12 h-[7vh] border-b">
              <div className="flex items-center w-full mx-5 font-semibold text-lg col-span-5"></div>
              <div className="mx-5 flex items-center font-semibold text-lg col-span-4">Report Description</div>
              <div className="mx-5 flex items-center font-semibold text-lg col-span-2">Created at</div>
              <div className="col-span-1"></div>
            </div>
            <div className=''>
              {systemReportData?.map((item: any, i: any) => {
                return item.IS_SHOW && (
                  <DownloadPPTListElement
                    key={item}
                    item={item}
                    i={i}
                    setData={setData}
                    exportPptReportsData={exportPptReportsData}
                    dispatch={dispatch}
                  />
                );
              })}
            </div>
            {systemReportData?.length === 0 &&
              <div className="h-[100px] w-full flex items-center justify-center">
                No results found
              </div>
            }
          </div>
        }
      </div>
      <div className="flex justify-center items-center">

        {!loadingData && (exportPptReportsDataIsEmpty === true || customGeneratedReports?.length === 0) && searchValue === '' &&
          <div className="bg-[#FFFFFF] w-[80vw] shadow-lg border-t-0 border-black" style={{ height: height * 0.6 }}>
            <h1 className="text-lg p-1 font-bold">Custom Generated Reports</h1>
            <div className="bg-[#FFFFFF] h-full w-[80vw] rounded-lg shadow-lg flex justify-center items-center" >
              <div className="">No results found</div>
            </div>
          </div>
        }
        {!loadingData && (exportPptReportsDataIsEmpty === false && (customGeneratedReports?.length !== 0 || searchValue !== '')) &&
          <div className="bg-[#FFFFFF] w-[80vw] shadow-lg">
            <h1 className="text-lg p-1 font-bold">Custom Generated Reports</h1>
            <div className=" grid grid-cols-12 h-[7vh] border-b">
              <div className="flex items-center w-full mx-5 font-semibold text-lg col-span-5">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
                <input type="text" placeholder="Search Report Name" className="p-2 h-[5vh] w-full outline-none font-normal placeholder:font-semibold" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
              </div>
              <div className="mx-5 flex items-center font-semibold text-lg col-span-4">Report Description</div>
              <div className="mx-5 flex items-center font-semibold text-lg col-span-2">Created at</div>
              <div className="col-span-1"></div>
            </div>
            <div className="overflow-y-auto scrollbar " style={{ height: isDefault ? height * 0.53 : height * 0.63 }}>
              {customGeneratedReports?.length !== 0 && <div className=" ">
                {customGeneratedReports?.map((item: any, i: any) => (
                  <DownloadPPTListElement key={item} item={item} i={i} setData={setData} exportPptReportsData={exportPptReportsData} dispatch={dispatch} />
                ))}
              </div>
              }
            </div>
            {customGeneratedReports?.length === 0 &&
              <div className=" w-full flex items-center justify-center" style={{ height: height * 0.6 }}>
                <h1 className="text-2xl p-1 font-bold">Custom Generated Reports</h1>
                <p>No results found</p>
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
}

function DownloadPPTListElement(props: any) {

  const [openArchivePopUp, setOpenArchivePopUp] = useState(false)
  const [archiveLoading, setArchiveLoading] = useState(false)
  const selectedProj = useSelector((state: RootState) => state.config.selectedProject)
  const encrypted = localStorage.getItem("_U");

  const convertDateTime = (input: any) => {
    const current_date = new Date()
    const myOffset = current_date.toString().split(' ')[5].slice(3,)
    if (myOffset.startsWith('+')) {
      const res = moment.utc(input).local().format('DD-MMM-YY HH:mm');
      return res
    } else {
      const res = moment.utc(input).local().format('DD-MMM-YY HH:mm');
      return res;
    }
  }

  const [downloading, setDownloading] = useState(false)
  const projectId = useParams().id

  const onClickArchiveReport = () => {
    setArchiveLoading(true)
    const encrypted = localStorage.getItem("_U");
    let token = decryption(encrypted, secrateKey)
    if (token) {
      token = JSON.parse(token).accessToken;
    }
    axios({
      url:
        settings.REACT_APP_API_URL +
        `project/${projectId}/reports/update?report_id=${props.item.ID}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response: any) => {
      (async function () {
        await props.dispatch(getExportPptReportsData({ projectId }));
        props.setData([...props.exportPptReportsData].filter((item: any) => item.ID !== props.item.ID))
        setArchiveLoading(false)
        setOpenArchivePopUp(false)
      })();
    }).catch((error: any) => {
      console.error(error);
      setOpenArchivePopUp(false)
    })
  }

  const handleDownload = (extension: any) => {
    const type = extension === "pdf" ? 'Summary_report' : 'Excel_export';
    let file_name = (props.item.IS_SYSTEM === true && props.item?.FILE_EXTENSION?.toLowerCase() === 'pdf') ? selectedProj?.PROJECT_NAME + `-Summary Report.${extension}` : selectedProj?.PROJECT_NAME + `-Data Export.${extension}`;
    let token = decryption(encrypted, secrateKey)
    if (token) {
      token = JSON.parse(token).accessToken;
    }

    axios({
      url:
        settings.REACT_APP_API_URL +
        `projects/${projectId}/download_summary_report?type=${type}`,
      method: "GET",
      responseType: "blob", // Set the response type to 'blob'
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response: any) => {
        // Create a temporary link element
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);

        // Append the link to the document and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up the temporary link and URL object
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error: any) => {
        // Handle error
        console.error("Error downloading .xlsm file:", error);
      });
  };

  const onClickDownloadFile = () => {
    setDownloading(true)
    const encrypted = localStorage.getItem("_U");
    let token = decryption(encrypted, secrateKey)
    if (token) {
      token = JSON.parse(token).accessToken;
    }
    axios({
      url:
        settings.REACT_APP_API_URL +
        `projects/${projectId}/output/download_data?export_id=${props.item.ID}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response: any) => {
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = props.item.REPORT_NAME + '.pptx';
      a.click();
      window.URL.revokeObjectURL(url);
      setDownloading(false)
    }).catch((error: any) => {
      console.error("Error downloading the file:", error);
      setDownloading(false)
    })
  }

  return (
    <div
      className={`grid grid-cols-12 h-[7vh] items-center ${props.i % 2 === 0 ? "bg-[#F5F5F5]" : "bg-[#FFFFFF]"
        } border-b`}
    >
      {props.status === 1 && (
        <div className="mx-5 flex items-center text-lg col-span-5 cursor-pointer text-black">
          <Tooltip title={props.item.REPORT_NAME}>
            <p className="truncate">{props.item.REPORT_NAME}</p>
          </Tooltip>{" "}
          <p className="text-sm italic truncate">
            &nbsp;{`(Generating... Please Wait)`}
          </p>{" "}
        </div>
      )}
      {props.item.STATUS !== 2 && (
        <div className="mx-5 flex items-center text-lg col-span-5 cursor-pointer text-black">
          <Tooltip title={props.item.REPORT_NAME}>
            <p className="truncate">{props.item.REPORT_NAME}</p>
          </Tooltip>{" "}
          <p className="text-sm italic truncate">
            &nbsp;{`(Generating... Please Wait)`}
          </p>{" "}
        </div>
      )}
      {props.item.STATUS === 2 && downloading && (
        <div className="mx-5 flex items-center text-lg col-span-5 cursor-pointer text-black">
          <Tooltip title={props.item.REPORT_NAME}>
            <p className="truncate">{props.item.REPORT_NAME}</p>
          </Tooltip>{" "}
          <p className="text-sm italic truncate">
            &nbsp;{`(Downloading...)`}
          </p>{" "}
        </div>
      )}
      {props.item.STATUS === 2 && !downloading && (
        <div
          className="mx-5 flex items-center text-lg col-span-5 cursor-pointer text-blue-500 underline"
          onClick={() => props?.item?.IS_SYSTEM === true && props?.item?.IS_DOWNLOAD_SUMMARY ? handleDownload(props?.item?.FILE_EXTENSION?.toLowerCase()) : onClickDownloadFile()}
        >
          {props.item.IS_SYSTEM === true && props.item?.FILE_EXTENSION?.toLowerCase() === 'pdf' ?
            <img
              src="/images/pdf.png"
              alt=""
              width="25"
              height="25"
            />
            :
            <>
              {props.item.IS_SYSTEM === true && props.item?.FILE_EXTENSION?.toLowerCase() === 'xlsx' ?
                <img
                  src="/images/excel.png"
                  alt=""
                  width="25"
                  height="25"
                />
                :
                (<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 110.031 107.5"><path d="M57.893 0h7.087v11.25c13.363.075 26.738-.138 40.088.062 2.875-.275 5.125 1.962 4.838 4.837.212 23.35-.05 46.712.125 70.075-.125 2.525.25 5.325-1.2 7.562-1.825 1.325-4.2 1.15-6.338 1.25-12.5-.062-25-.037-37.513-.037v12.5h-7.774c-19.05-3.475-38.138-6.65-57.2-10-.013-29.162 0-58.325 0-87.475C19.292 6.688 38.58 3.288 57.893 0z" fill="#d24625" /><path d="M64.98 15h41.25v76.25H64.98v-10h30v-5h-30V70h30v-5H64.993c-.013-2.45-.013-4.9-.024-7.35 4.95 1.537 10.587 1.5 15.012-1.476 4.788-2.837 7.288-8.25 7.7-13.65-5.487-.038-10.975-.025-16.45-.025-.012-5.438.062-10.875-.112-16.3-2.05.4-4.1.825-6.138 1.262V15z" fill="#fff" /><path d="M73.743 23.587c8.688.4 15.987 7.712 16.45 16.375-5.488.063-10.975.038-16.463.038 0-5.475-.012-10.95.013-16.413z" fill="#d24625" /><path d="M20.055 33.025c6.788.325 15.013-2.688 20.638 2.4 5.388 6.538 3.963 18.562-4.025 22.476-2.837 1.449-6.087 1.25-9.175 1.149-.013 4.888-.024 9.775-.013 14.663a1323.27 1323.27 0 0 0-7.438-.625c-.112-13.351-.136-26.713.013-40.063z" fill="#fff" /><path d="M27.48 39.788c2.463-.113 5.513-.562 7.176 1.75 1.425 2.45 1.35 5.675.162 8.2-1.425 2.575-4.65 2.325-7.138 2.625-.263-4.188-.237-8.376-.2-12.575z" fill="#d24625" /></svg>
                )}
            </>
          }
          <Tooltip title={props.item.IS_SYSTEM === true && props.item?.IS_DOWNLOAD_SUMMARY ? (props.item?.FILE_EXTENSION?.toLowerCase() === 'pdf' ? "Click to download Summary Report" : "Click to download Data Export") : "Click to download PPT"}>
            <p className="truncate ml-2">{props.item.REPORT_NAME}</p>
          </Tooltip>
        </div>
      )}
      <div className="mx-5 flex items-center text-lg col-span-4">
        <Tooltip title={props.item.DESCRIPTION}>
          <p className="truncate">{props.item.DESCRIPTION}</p>
        </Tooltip>
      </div>
      <div className="mx-5 flex items-center text-lg col-span-2">
        <p className="truncate">{convertDateTime(props.item.TRIGGER_TIME)}</p>
      </div>
      {(props.item.STATUS === 2 && !downloading) && (
        <div className="col-span-1 flex items-center justify-end mr-2">
          {(props.item.IS_SYSTEM === 0 || props.item.IS_SYSTEM === false) && (
            <Tooltip title="Archive PPT">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={28}
                height={28}
                viewBox="0 0 256 256"
                className="cursor-pointer fill-[#898989] hover:fill-[#000000] transition-all"
                onClick={() => setOpenArchivePopUp(true)}
              >
                <path
                  strokeMiterlimit={10}
                  d="M4 5v6h1v16h22V11h1V5zm2 2h20v2H6zm1 4h18v14H7zm5.813 2c-.551.05-.958.543-.907 1.094.051.55.543.957 1.094.906h6c.36.004.695-.184.879-.496a1.01 1.01 0 0 0 0-1.008c-.184-.312-.52-.5-.879-.496H12.812z"
                  fontFamily="none"
                  fontSize="none"
                  fontWeight="none"
                  style={{
                    mixBlendMode: "normal",
                  }}
                  textAnchor="none"
                  transform="scale(8)"
                />
              </svg>
            </Tooltip>)}
        </div>
      )}

      <Popup
        modal
        open={openArchivePopUp}
        contentStyle={{
          width: "34vw",
          boxShadow: "0px 0px 1px white",
          backgroundColor: "white",
          borderRadius: "6px",
        }}
      >
        <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
          <div className="flex justify-between items-center w-full mb-6">
            <p className="font-base text-lg  flex items-center pl-10 pr-10">
              {" "}
              <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
            </p>
            <XCircle
              className="mr-6"
              size={24}
              style={{ cursor: "pointer" }}
              fill="#BDBCBC"
              color="#FFFF"
              onClick={() => {
                setOpenArchivePopUp(false);
              }}
            />
          </div>

          <span
            className="font-base pb-6 -2 pl-11 pr-10"
            style={{ fontSize: "12px", color: "#545454" }}
          >
            Archiving the report will remove it from your Download Report
            page. Please reach out to our support team if you wish to retrieve
            it later.
          </span>
          <div
            className=" mt-5 flex justify-between items-center ml-10 mr-10"
            style={{ width: "90%" }}
          >
            <button
              style={{ color: " #8088A4", border: "none" }}
              onClick={() => {
                setOpenArchivePopUp(false);
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                onClickArchiveReport();
              }}
              className="border w-48 p-2 rounded"
              style={{ border: "1px solid red", color: "red" }}
            >
              {archiveLoading && (
                <div className="">
                  <CircularProgress color="inherit" size="1rem" />
                </div>
              )}
              {!archiveLoading && <div className="">Proceed</div>}
            </button>
          </div>
        </div>
      </Popup>
    </div>

  );
}

export default DownloadPPT






