const settings = {
  REACT_APP_SSO_LOGIN: window.env.REACT_APP_SSO_LOGIN,
  REACT_APP_SSO_LOGOUT: window.env.REACT_APP_SSO_LOGOUT,
  REACT_APP_ACCESS_DENIED: window.env.REACT_APP_ACCESS_DENIED,
  REACT_APP_ENV: window.env.REACT_APP_ENV,
  REACT_APP_API_URL: window.env.REACT_APP_API_URL,
  REACT_APP_IMG_URL: window.env.REACT_APP_IMG_URL,
  REACT_APP_IMG_DOMAIN: window.env.REACT_APP_IMG_DOMAIN,
  REACT_APP_CHECK_ROUTE: window.env.REACT_APP_CHECK_ROUTE,
  REACT_APP_IS_DEMO: window.env.REACT_APP_IS_DEMO,
  REACT_APP_IS_PROJECT_CREATION_ENABLED:window.env.REACT_APP_IS_PROJECT_CREATION_ENABLED,
  REACT_APP_LLM_API: window.env.REACT_APP_LLM_API,
  REACT_APP_ERROR_LOG_URL: window.env.REACT_APP_ERROR_LOG_URL,
  REACT_APP_CONNECTION_STRING: window.env.REACT_APP_CONNECTION_STRING,
  REACT_APP_BLOB_PATH: window.env.REACT_APP_BLOB_PATH,
  REACT_APP_PASSWORD_IV: window.env.REACT_APP_PASSWORD_IV,
  REACT_APP_PASSWORD_KEY: window.env.REACT_APP_PASSWORD_KEY
};

export default settings;
