import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import AppContainer from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';

export const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <AppContainer />
  </Provider>
);

reportWebVitals();
