import { createAsyncThunk } from "@reduxjs/toolkit";
import { profileAPI } from "../../service/api/profileAPI";
import CryptoJS from 'crypto-js';

import settings from "../../config";

// const key = CryptoJS.enc.Base64.parse('3bZsR1cGjUDbAeLxQ9fKzN/m5LYkXJIw2pn6HYrMd8E=');
// var iv = CryptoJS.enc.Utf8.parse("1234567890123456");
const key = CryptoJS.enc.Base64.parse(`${settings.REACT_APP_PASSWORD_KEY}`);
var iv = CryptoJS.enc.Utf8.parse(`${settings.REACT_APP_PASSWORD_IV}`);

export const login = createAsyncThunk("login", async ({ username, password }: any, { rejectWithValue }) => {
    try {
        var encryptionOptions = {
            iv: iv,
            mode: CryptoJS.mode.CBC
        };
        var encryptedPassword = CryptoJS.AES.encrypt(password, key, encryptionOptions).toString();

        const response = await profileAPI.login({ username, password: encryptedPassword })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const resetPassword = createAsyncThunk("resetPassword", async ({ email }: any, { rejectWithValue }) => {
    try {
        const response = await profileAPI.resetPassword({ email })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const setPassword = createAsyncThunk("setPassword", async ({ password, reset_token }: any, { rejectWithValue }) => {
    try {
        var encryptionOptions = {
            iv: iv,
            mode: CryptoJS.mode.CBC
        };
        var encryptedPassword = CryptoJS.AES.encrypt(password, key, encryptionOptions).toString();

        const response = await profileAPI.setPassword({ password: encryptedPassword, reset_token })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const verifyEmail = createAsyncThunk("verifyEmail", async ({ password, reset_token }: any, { rejectWithValue }) => {
    try {
        var encryptionOptions = {
            iv: iv,
            mode: CryptoJS.mode.CBC
        };
        var encryptedPassword = CryptoJS.AES.encrypt(password, key, encryptionOptions).toString();

        const response = await profileAPI.verifyEmail({ password: encryptedPassword, reset_token })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const signup = createAsyncThunk("signup", async ({ Email, CountryCode, FirstName, LastName, DisplayName, CountryId, CompanyId, JobRoleId, PhoneNumber, Mode }: any, { rejectWithValue }) => {
    try {
        const response = await profileAPI.signup({ Email, CountryCode, FirstName, LastName, DisplayName, CountryId, CompanyId, JobRoleId, PhoneNumber, Mode })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const getJobRoles = createAsyncThunk("jobRoles", async ({ }: any, { rejectWithValue }) => {
    try {
        const response = await profileAPI.getJobRoles()
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const getCountries = createAsyncThunk("countries", async ({ }: any, { rejectWithValue }) => {
    try {
        const response = await profileAPI.getCountries()
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const getUsers = createAsyncThunk("users", async ({ }: any, { rejectWithValue }) => {
    try {
        const response = await profileAPI.getUsers()
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const addJobRoles = createAsyncThunk("addJobRoles", async ({ jobRole }: any, { rejectWithValue }) => {
    try {
        const response = await profileAPI.addJobRoles({ jobRole })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

// export const getUser = createAsyncThunk( "userDetails", async({userName}: any, { rejectWithValue }) => {
//     // try{
//     //     const response = await profileAPI.getUser(userName)
//     //     return{
//     //         response: response.data
//     //     }
//     // }
//     // catch(error: any){
//     //     throw rejectWithValue(error.data)
//     // }

//     function decryptUserData(encryptedText:string, secrateKey:string) {
//         const bytes = CryptoJS.AES.decrypt(encryptedText, secrateKey);
//         const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
//         return JSON.parse(decryptedData);
//     }    

//     const encryptedUserData = localStorage.getItem('_U');
//     if (encryptedUserData) {
//         const userDataString = JSON.parse(encryptedUserData);
//         const userData = decryptUserData(userDataString, '#1234');
//         return {
//             USERNAME: userData?.email,
//             FIRSTNAME: userData?.firstName,
//             LASTNAME: userData?.lastName,
//             PHONENUMBER: userData?.phoneNumber,
//         }
//     }
//     return {}
// })
export const addCountries = createAsyncThunk("addCountries", async ({ country }: any, { rejectWithValue }) => {
    try {
        const response = await profileAPI.addCountries({ country })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const updateUserName = createAsyncThunk("updateUserName", async ({ userName, firstName, lastName }: any, { rejectWithValue }) => {
    try {
        const response = await profileAPI.updateName({ userName, firstName, lastName })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const updatePhoneNumber = createAsyncThunk("updatePhoneNumber", async ({ userName, phoneNumber }: any, { rejectWithValue }) => {
    try {
        const response = await profileAPI.updatePhoneNumber({ userName, phoneNumber })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error)
    }
})

export const decodeToken = createAsyncThunk("decodeToken", async ({ token }: any, { rejectWithValue }) => {
    try {
        const response = await profileAPI.decodeToken({ token })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const userLogout = createAsyncThunk("userLogout", async ({ token }: any, { rejectWithValue }) => {
    try {
        const response = await profileAPI.logout({ token })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const refreshAccessToken = createAsyncThunk("refreshAccessToken", async ({ token }: any, { rejectWithValue }) => {
    try {
        const response = await profileAPI.refreshAccessToken({ token })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
