import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';

const ProdSiteDownUI = () => {
    return (

        <div className=' grid grid-cols-2 max-lg:flex max-lg:flex-col  h-full w-full bg-white'>
            <div className="bg-gray-50 flex flex-col justify-center items-center gap-7 max-lg:mt-[30px] max-lg:h-[20vh] max-lg:bg-white">
                <img
                    // src={"images/EzythemesLogo.png"}
                    src={`${window.innerWidth >= 1024 ? "/images/EzyThemes logo _vertical_white bg.svg" : "https://ezythemes.com/wp-content/uploads/2024/04/logo.svg"}`}

                    style={{
                        // width: "180px",
                        width: window.innerWidth >= 1024 ? "180px" : "50vw",
                        height: "auto",
                    }}
                    alt="Logo"
                />
            </div>
            {/* <div className="flex flex-col justify-center items-center gap-7"> */}
            <div className="col-span-1 bg-white mt-[28px] max-lg:min-h-[75vh] max-lg:mt-0 flex justify-center items-center max-lg:items-start">

                {/* <div> */}
                <div className="flex flex-col justify-center items-center  w-[28vw] max-lg:w-[90vw]">

                    <h1 className="font-black-700 text-3xl font-extrabold my-5 flex flex-row">Upgrade Underway <span><ConstructionOutlinedIcon style={{ width: "40px", height: "30px" }} /></span></h1>

                    <h1 className="text-gray-400  text-3xl font-extrabold my-5">We're Elevating Your Experience!</h1>

                    <p className="text-lg text-black-400 font-semibold my-7">You caught us in the middle of a little platform touch-up. We're<br />
                        adding some extra sparkle and tidying things up behind the,<br />
                        scenes to upgrade your experience to the next level</p>

                    <p className="text-lg text-black-400 font-semibold my-7">For just a brief moment(Estimated:10 mins), our platform might
                        <br />look like it's gone fishing.But fear not! We'll be back in a jiffy,
                        <br />better than ever.</p>
                    <p className="text-black-400 font-semibold text-lg my-5">Thanks for your patience.The fun's about to resume</p>
                </div>
            </div>
        </div>

    )

}

export default ProdSiteDownUI