import { axiosconfig } from "../../service/apiconfig";

export const ProjectAPI = {
  getProjectsDataList: ({ project_id }: any) => {
    return axiosconfig.get("projects/" + project_id + "/project-datasets");
  },
  deleteProject: ({ project_id }: any) => {
    return axiosconfig.delete("projects/" + project_id );
  },
  getProjectDomine: () => {
    return axiosconfig.get("projects/domain-master");
  },
  getProjectState: ({ project_id, activeStep }: any) => {
    return axiosconfig.get(`projects/${project_id}/get_state`, { params: { project_id: project_id, step_id: activeStep } });
  },
  getProjectFileUpload: ({
    project_id,
    project_name,
    domain_id,
    domain_name,
    category_id,
    category_name,
    files,
    state,
    onUploadProgress
  }: any) => {
    let formData = new FormData();
    files.forEach((element: any, i: number) => {
      formData.append(`file${i}`, element);
    });
   
    return axiosconfig.post(`projects/${project_id}/upload-files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        project_name: project_name,
        domain_id: domain_id,
        domain_name: domain_name,
        category_id: category_id,
        category_name: category_name
      },
      onUploadProgress,
      state
    });
  },

  // getDiscussionGuideUpload:({project_id,files}:any)=>{
  //   let formData = new FormData();
  //   files.forEach((element: any, i: number) => {
  //     formData.append(`file${i}`, element);
  //   });
  //   return axiosconfig.post(`projects/${project_id}/upload-discussion-guide`, formData, {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   });
  // },
  

  getAllProjects: ( offset:number, limit:number, searchString:string, status:string, favoriteOnly:boolean, sortBy:string, accountFilter:any ) => {
    let user_account_id;
    if(accountFilter){
      user_account_id=accountFilter
    }
    return axiosconfig.get(
      "projects",
      {"params":{
        offset:offset,
        limit:limit,
        search_string: searchString,
        status: status,
        user_account_id,
        favorite_only: favoriteOnly,
        sort_by: sortBy
      }}
    );
  },
  deleteFile: ({ project_id, filename, dataset_id }: any) => {
    return axiosconfig.delete(`projects/${project_id}/files/${dataset_id}`,
      {params:{filename}}
    );
  },
  verifyDatasets: ({ projectId, projectName, domainId, domainName, categoryId, categoryName }: any) => {
    return axiosconfig.post(`projects/${projectId}/finalize-datasets`, null, {
      params: {
        project_name: projectName,
        domain_id: domainId,
        domain_name: domainName,
        category_id: categoryId,
        category_name: categoryName
      }
    });
  },
  saveFileUploadState: ({ projectId,subscriptionId, data }: any) => {
    return axiosconfig.post(`/projects/${projectId}/save-file-upload-state/${subscriptionId}`, data);
  },
  editProject: (projectId:string, projectPayload:any) => {
    return axiosconfig.put(`projects/${projectId}`,projectPayload)
  },
  getExportData:({projectId}: any) => {
    return axiosconfig.get(`/projects/${projectId}/output/_download_data`)
  },
  downloadManageThemes:({project_id}: any) => {
    return axiosconfig.getFile(`/projects/${project_id}/manage_themes_qc`)
  },
  uploadManageThemes:({project_id,file}: any) => {
    const formData=new FormData()
    formData.append("file",file)
    return axiosconfig.post(`/projects/${project_id}/manage_themes_qc`,formData,{ headers: {"content-type": "multipart/form-data"}})
  },
  confirmManageThemes:({project_id}: any) => {
    return axiosconfig.post(`/projects/${project_id}/manage_themes_qc/action=confirm`,
    null)
  },
  downLoadMRQc:({projectId}:any)=>{
    return axiosconfig.getFile(`/projects/${projectId}/mr_correction_qc`)
  },
  // uploadMRQc:({projectId,file,fileInfo}:any)=>{
  uploadMRQc:({projectId,file,fileInfo}:any)=>{
    const formData=new FormData()
    // formData.append("file",file)
    formData.append("file_info",`${JSON.stringify(fileInfo)}`)
    return axiosconfig.post(`/projects/${projectId}/mr_correction_qc`,formData,{ headers: {"content-type": "multipart/form-data"}})
  },
  confirmMRQCCorrection:({projectId, payload}:any)=>{
    return axiosconfig.post(`/projects/${projectId}/mr_correction_qc/action=confirm`, payload)
  },
  getMRQCFiles:({projectId}:any)=>{
    return axiosconfig.get(`/projects/${projectId}/project_dataset_state`)
  },
  saveMRQCFiles:({projectId,data}:any)=>{
    return axiosconfig.post(`/projects/${projectId}/mr_correction`,data)
  },
  getProjectDetails:({project_id}: any) =>{
    return axiosconfig.get(`projects/${project_id}`)
  },
  getDivergenceData:(project_id: any) => {
    return axiosconfig.get(`projects/${project_id}/get_divergence_data`)
  },
  getAccountFilterData:()=>{
    return axiosconfig.get(`/projects/accounts`)
  },
  getUpdateAccountId:(projectId:any)=>{
    const project_id = projectId.project_id
    return axiosconfig.post(`/projects/${project_id}/account_id`,{
      project_id:project_id
    })
  },
  getClonedProjectInfo:(ProjectId:any)=>{
    return axiosconfig.get(`/projects/${ProjectId}/cloned_group`)
  },
  downloadInputFiles:({project_id, dataset_id}: any) => {
    return axiosconfig.getFile(`/project/${project_id}/downloadinputfiles?dataset_id=${dataset_id}`)
  },
  getEntitiesQcData:(projectId:any, viewType:any, entityId:number)=>{
    if(entityId){
      return axiosconfig.get(`/project/${projectId}/entites?select=${viewType}&entity_id=${entityId}`)
    }else{
      return axiosconfig.get(`/project/${projectId}/entites?select=${viewType}`)
    }
  },
  getEntitiesPreQcData:(projectId:any, viewType:any, entityId:number)=>{
    if(entityId){
      return axiosconfig.get(`/project/${projectId}/entites?select=${viewType}&is_pre_qc=true&entity_id=${entityId}`)
    }else{
      return axiosconfig.get(`/project/${projectId}/entites?select=${viewType}&is_pre_qc=true`)
    }
  },
  getEntityTypesData:(projectId:any)=>{
      return axiosconfig.get(`/project/${projectId}/entity_types`)
  },
  getEntityTypesPreQcData:(projectId:any)=>{
    return axiosconfig.get(`/project/${projectId}/entity_types?is_pre_qc=true`)
  },
  getSentenceEntityAnchor:(projectId:any, anchorId:any)=>{
      return axiosconfig.get(`project/${projectId}/anchor/sentence?anchor_id=${anchorId}`)
  },  

  intelligentMR:({projectId}: any) => {
    return axiosconfig.get(`/projects/${projectId}/get_intelligent_mr_data`)
  },
  intelligentCorrection:({projectId}: any) => {
    return axiosconfig.get(`/projects/${projectId}/get_intelligent_correction_data`)
  },
  updateIntelligentCorrection:(payload: any) => {
    return axiosconfig.post(`/projects/${payload.project_id}/update_intelligent_correction`,payload)
  },
  addEntityEquivalent:(projectId:any, anchorId:any, equivalentName:any)=>{
    return axiosconfig.post(`/project/${projectId}/entity_equivalent?anchor_id=${anchorId}`,equivalentName)
  },
  addEntityPreQcEquivalent:(projectId:any, anchorId:any, equivalentName:any)=>{
    return axiosconfig.post(`/project/${projectId}/entity_equivalent?anchor_id=${anchorId}&is_pre_qc=true`, equivalentName)
  },
  addEntityAnchor:(projectId:any, anchorName:any, entityType:any)=>{
    return axiosconfig.post(`/project/${projectId}/entity_anchor?entity_type=${entityType}`, anchorName)
  },
  addEntityPreQcAnchor:(projectId:any, anchorName:any, entityType:any)=>{
    return axiosconfig.post(`/project/${projectId}/entity_anchor?entity_type=${entityType}&is_pre_qc=true`, anchorName)
  },
  addEntityType:(projectId:any, entityType:any)=>{
    return axiosconfig.post(`project/${projectId}/entity_type?entity_type=${entityType}`,{})
  },
  addEntityPreQCType:(projectId:any, entityType:any)=>{
    return axiosconfig.post(`project/${projectId}/entity_type?entity_type=${entityType}&is_pre_qc=true`,{})
  },
  updateIntelligentMR:(payload: any) => {
    return axiosconfig.post(`/projects/${payload.project_id}/update_intelligent_mr`,payload)
  },
  updateEntityAnchor:(projectId:any,anchorId:any, newEntityAnchorName:any, isPreQc:any, payload:any)=>{
    let params: any = {}
    if (newEntityAnchorName) {
      params['rename_entity_anchor_name'] = newEntityAnchorName
    }
    if (isPreQc) {
      params['is_pre_qc'] = isPreQc
    }

    return axiosconfig.post(`project/${projectId}/entity_anchor/action=modify?anchor_id=${anchorId}`,payload, {params:params})
  },

  updateEntityPreQCAnchor:(projectId:any,anchorId:any, newEntityAnchorName:any, payload: any)=>{
    if(newEntityAnchorName){
      return axiosconfig.post(`project/${projectId}/entity_anchor/action=modify?anchor_id=${anchorId}&is_pre_qc=true&rename_entity_anchor_name=${newEntityAnchorName}`,{})
    }
    else{
      return axiosconfig.post(`project/${projectId}/entity_anchor/action=modify?anchor_id=${anchorId}&is_pre_qc=true`, payload)
    }
  },

  updateEntityType:(projectId:any,entityId:any, isUserDefined:boolean,sentiment:any, contextUsed:any,renameEntityType:any, isPreQc:any, payload:any )=>{
    let params:any={"entity_id":entityId, 
      "is_user_defined":isUserDefined,
    }
    if(sentiment){
      params[`sentiment`]=sentiment
    }
    if(contextUsed!==null){
      params[`context_used`]=contextUsed
    }
    if(renameEntityType){
      params[`rename_entity_type_name`]=renameEntityType
    }
    if (isPreQc){
      params[`is_pre_qc`]=isPreQc
    }

    return axiosconfig.post(`project/${projectId}/entity_type/action=modify`,payload, {params:params})
  },

  updateEntityPreQcType:(projectId:any,entityId:any, isUserDefined:boolean,sentiment:any, contextUsed:any,renameEntityType:any,payload:any )=>{
    let params:any={"entity_id":entityId, 
      "is_user_defined":isUserDefined,
      "is_pre_qc":true
    }
    if(sentiment){
      params[`sentiment`]=sentiment
    }
    if(contextUsed!==null){
      params[`context_used`]=contextUsed
    }
    if(renameEntityType){
      params[`rename_entity_type_name`]=renameEntityType
    }

    return axiosconfig.post(`project/${projectId}/entity_type/action=modify`,payload, {params:params})
  },

  deleteEntity:(projectId:any,entityId:any,type:string,isDeleted:boolean )=>{
    return axiosconfig.post(`project/${projectId}/delete_entity?id=${entityId}&type=${type}&is_delete=${isDeleted}`,{})
  },
  deleteEntityPreQC:(projectId:any,entityId:any,type:string,isDeleted:boolean )=>{
    return axiosconfig.post(`project/${projectId}/delete_entity?id=${entityId}&type=${type}&is_delete=${isDeleted}&is_pre_qc=true`,{})
  }, 
  moveEntityAnchor:(projectId:any,entityType:any,payload:any  )=>{
    return axiosconfig.post(`project/${projectId}/move_entity_anchor?entity_type=${entityType}`,payload)
  },
  moveEntityPreQcAnchor:(projectId:any,entityType:any,payload:any  )=>{
    return axiosconfig.post(`project/${projectId}/move_entity_anchor?entity_type=${entityType}&is_pre_qc=true`,payload)
  }, 
  moveEntityEquivalent:(projectId:any,anchorId:any,payload:any  )=>{
    return axiosconfig.post(`project/${projectId}/move_entity_equivalent?anchor_id=${anchorId}`,payload)
  },
  moveEntityPreQcEquivalent:(projectId:any,anchorId:any,payload:any  )=>{
    return axiosconfig.post(`project/${projectId}/move_entity_equivalent?anchor_id=${anchorId}&is_pre_qc=true`,payload)
  }, 
  getParkAnchorsList:(projectId:any)=>{
    return axiosconfig.get(`/project/${projectId}/park_anchors`)
  },
  getParkAnchorsPreQcList:(projectId:any)=>{
    return axiosconfig.get(`/project/${projectId}/park_anchors?is_pre_qc=true`)
  },
  getParkEquivalentsList:(projectId:any, entityType:any)=>{
    if(entityType){
      return axiosconfig.get(`/project/${projectId}/park_equivalents?entity_type=${entityType}`)
    }else{
      return axiosconfig.get(`/project/${projectId}/park_equivalents`)
    }
  },
  getPreQcParkEquivalentsList:(projectId:any)=>{
    return axiosconfig.get(`/project/${projectId}/park_equivalents?is_pre_qc=true`)
  },
  moveToParkTabs:(projectId:any,parkGroup:number, type:any, payload:any)=>{
    return axiosconfig.post(`/project/${projectId}/move_park_group?park_group=${parkGroup}&type=${type}`, payload)
  },
  moveToPreQcParkTabs:(projectId:any,parkGroup:number, type:any, payload:any)=>{
    return axiosconfig.post(`/project/${projectId}/move_park_group?park_group=${parkGroup}&type=${type}&is_pre_qc=true`, payload)
  },
  getDomainMaster:()=>{
    return axiosconfig.get(`/projects/domain-master`)
  },
  updateProjectCategory: ( project_id:any, payload : any) => {
    return axiosconfig.post(`/projects/${project_id}/project_category`, payload);
  },

  //Process output 
  postProcessOutputState: (projectId: any,field:any ) => {
    return axiosconfig.post(`/projects/${projectId}/?action=${field}`, {})
  },
  //Save files configs
  saveProjectConfiguration: (projectId: any, research_type: string, subscription_id: string, payload: any) => {
    let state = "state"
    return axiosconfig.post(`/projects/${projectId}/save_config?field=${state}&research_type=${research_type}&subscription_id=${subscription_id}`, {
      state: payload.state,
      topics_of_interests : payload.topics_of_interests
    })
  },

  //Get files configs
  getProjectConfiguration: (projectId: any, activeStep: number) => {
    let state = "state"
    return axiosconfig.get(`/projects/${projectId}/?field=${state}&step_id=${activeStep}`)
  },
  getPreQcTabData: (projectId: any) => {
    return axiosconfig.get(`/project/${projectId}/pre_qc_data`)
  },
  updatePreQcTabData: (projectId: any, payload: any) => {
    return axiosconfig.post(`/project/${projectId}/update_pre_qc_data`, payload)
  },
  createPreQcTabData: (projectId: any, payload: any) => {
    return axiosconfig.post(`/project/${projectId}/create_pre_qc_data`, payload)
  },
  deletePreQcTabData: (projectID: any, id: any) => {
    return axiosconfig.delete(`/project/${projectID}/pre_qc_data?id=${id}`)
  },
  addEntityPreQc: (projectId: any, bucket: any, name: any, id: any, isUserDefined: any, parentId: any ) => {
    let encodedName = encodeURIComponent(name)
    if (parentId) {
      return axiosconfig.post(`/project/${projectId}/add_entitiy?bucket=${bucket}&name=${encodedName}&id=${id}&is_user_defined=${isUserDefined}&parent_id=${parentId}`, {})
    }
    return axiosconfig.post(`/project/${projectId}/add_entitiy?bucket=${bucket}&name=${encodedName}&id=${id}&is_user_defined=${isUserDefined}`, {})
  },

  qc0SubmissionValidation: (projectId: any) => {
    return axiosconfig.get(`/projects/${projectId}/validate_qc0_submission`)
  },
  qcDaizyLabelsSearch: (searchString: any, cancelToken: any) => {
    return axiosconfig.get(`/project/daizy_labels_search/${searchString}`, { cancelToken: cancelToken })
  },
  getIntelligentMrQcData: (projectId: any, fileID: any) => {
    return axiosconfig.get(`/project/${projectId}/intelligent_mr_preqc_data?file_id=${fileID}`)
  },
  getSpeakersList: (projectId: any, file_id: string) => {
    return axiosconfig.get(`project/${projectId}/distinct_speakers?file_id=${file_id}`)
  },
  getCorrectedMrPreQcData: (projectId: any, fileID: any) => {
    return axiosconfig.get(`/project/${projectId}/intelligent_mr_preqc_corrected_data?file_id=${fileID}`)
  },
  getTranscriptsData: (projectId: any) => {
    return axiosconfig.get(`/project/${projectId}/get_transcripts`)
  },
  updateIntelligentMrPreQc:(projectId:any, action:any, fileID: any, payload:any)=>{
    return axiosconfig.post(`/project/${projectId}/intelligent_mr_preqc/${action}?file_id=${fileID}`,payload)
  },
  updateMrPreQcTextBlocks:(projectId:any, payload:any)=>{
    return axiosconfig.post(`/project/${projectId}/split_mr_textblocks`, payload)
  },
  renameSavedViews:(projectID: any, name: any, id: any) => {
    return axiosconfig.post(`/projects/${projectID}/saved_view?name=${name}&action=rename&id=${id}`, {})
  },
  submitIntelligentCorrectionQc: (projectID: any) => {
    return axiosconfig.post(`/projects/${projectID}/submit_intelligent_correction_qc`, {})
  }, 
  submitEntityQc: (projectID: any) => {
    return axiosconfig.post(`/project/${projectID}/submit_entity_qc`, {})
  }, 
  
};


