import { createAsyncThunk } from "@reduxjs/toolkit";
import { WizardAPI } from "../../service/api/wizardAPI";
import { setResetEnable, setScopeUpdated } from "../EditProject/EditProjectReducer";

export const getWizardData = createAsyncThunk(
  "getWizardData",
  async ({ projectId }: any, { rejectWithValue }) => {
    try {
      const response = await WizardAPI.getWizardData({ projectId });
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);
export const getConfigureDataState = createAsyncThunk(
  "getConfigureDataState",
  async ({ project_id }: any, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setResetEnable(false))
      dispatch(setScopeUpdated(false))
      const response = await WizardAPI.getConfigureDataState({ project_id });
      return {
        response: response.data,
        project_id,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);

export const postConfigureDataState = createAsyncThunk(
  "postConfigureDataState",
  async (
    {
      project_id,
      isAutosave,
      attribute_data,
      name,
      is_active,
      values,
      dataset_id,
      value,
      reporting_buckets,
      themes,
      entities,
      entity_name,
      entity_values,
      config_code
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await WizardAPI.postConfigureDataState({ project_id, isAutosave, attribute_data, reporting_buckets, themes, entities, config_code });
      return {
        response: response.data,
        project_id, attribute_data, reporting_buckets, themes, entities, config_code
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);
export const getProjectDataSet = createAsyncThunk("getProjectDataSet", async ({ project_id }: any, { rejectWithValue }) => {
  try {
    const response = await WizardAPI.getProjectDataSet({ project_id });
    return {
      response: response.data,
      project_id
    }
  } catch (error: any) {
    throw rejectWithValue(error.data)
  }
})