import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IThemeData, ITranscriptDataBlock, IResponseBlock } from "./type";
import { getThemes, getThemeSentences, updateSentencePPTSelection, saveUpdatedSentence, deleteSentence, hideThemes, mergeThemes, getTranscriptDataList, getSentenceContext, getTranscriptIndexes, getTranscriptBlocks, getViewByCount, getOrderedTranscriptIds, getTranscriptData } from "./action";


const initialState: IThemeData = {
    selectedMode: "View",
    themeLoader: false,
    allThemes: [],
    themeQBMap: {},
    allThemesSentence: [],
    sentenceLoader: false,
    allTranscripts: [],
    contextLoader: false,
    sentenceContext: {},
    focusSelection: [],
    transcriptData: [],
    sentenceCount: 0,
    transcriptLoader: false,
    viewByCount: 0,
    viewByLoader: false,
    selectedView: "Attributes",
    isStarCount: 0,
    orderedTranscriptIds: [],
    transcriptTempCont: [],
    transcriptLookUp: {},
    transcriptOriginal: [],
    isScroll: false
}

const includesValue = (searchFor: string, array: Array<any>) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i] === searchFor) {
            return true
        }
    }
    return false
}

function reFormatTranscript(transData: any) {
    let tempTranscript: any = []
    transData.forEach((block: ITranscriptDataBlock) => {
        let responses: any = []
        tempTranscript.push({
            type: "M",
            dID: block.dID,
            q_t: block.q_t
        })
        block.r.forEach((resp: IResponseBlock) => {
            responses.push({
                type: "R",
                dID: block.dID,
                s: resp.s
            })
        })
        tempTranscript = tempTranscript.concat(responses)
    })
    return tempTranscript
}

export const themeDataSlice = createSlice({
    name: "themes",
    initialState: initialState,
    reducers: {
        updateSelectedMode: (state: IThemeData, action) => {
            state.selectedMode = action.payload
        },
        upadateFocusSelection: (state: IThemeData, action) => {
            // const update=[]
            // state.focusSelection=
        },
        updateSelectedView: (state, action) => {
            state.selectedView = action.payload
        },
        updateSelectionCount: (state, action) => {
            state.isStarCount = action.payload


        },
        updateLocalSentenceSentiment: (state, action) => {
            const temp = state.allThemesSentence.map((item: any) => {
                if (item.sentence_id === action.payload.sentence_id) {
                    return {
                        ...item,
                        sentiment: action.payload.senti
                    }
                }
                else {
                    return item
                }
            })
            state.allThemesSentence = temp
        },
        triggerScroll: (state, action) => {
            state.isScroll = action.payload;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getThemes.pending, (state: IThemeData) => {
                state.themeLoader = true
            })
            .addCase(getThemes.fulfilled, (state: IThemeData, action: PayloadAction<any>) => {
                state.allThemes = action.payload.response?.data
                state.themeLoader = false
            })
            .addCase(getThemes.rejected, (state: IThemeData) => {
                state.themeLoader = false
            });
        builder
            .addCase(getThemeSentences.pending, (state: IThemeData) => {
                state.sentenceLoader = true
                state.sentenceCount = 0
            })
            .addCase(getThemeSentences.fulfilled, (state: IThemeData, action: PayloadAction<any>) => {
                state.allThemesSentence = action.payload.response.data.sentences
                state.sentenceCount = action.payload.response.data.count
                state.sentenceLoader = false
            })
            .addCase(getThemeSentences.rejected, (state: IThemeData) => {
                //state.sentenceLoader = false
                state.sentenceCount = 0
                state.allThemesSentence = []
            });
        builder
            .addCase(hideThemes.pending, (state: IThemeData, action) => {
                let { themeIds, payload } = action.meta.arg
                let updatedList = state.allThemes.map((item: any) => {
                    {
                        for (let i = 0; i < item.id.length; i++) {
                            if (includesValue(item.id[i].id, themeIds)) {
                                return {
                                    ...item,
                                    is_active: !payload?.hide
                                }
                            }
                        }
                        return {
                            ...item
                        }
                    }
                })
                state.allThemes = updatedList
            })
            .addCase(hideThemes.fulfilled, (state, action: PayloadAction<any>) => {

            })
            .addCase(hideThemes.rejected, (state, action: PayloadAction<any>) => {

            })
            .addCase(updateSentencePPTSelection.pending, (state: IThemeData, { meta }) => {
                const update = state.allThemesSentence.map((each: any) => {
                    if (meta.arg.updatedPPTSelection.sentence_id[0] === each.sentence_id) {
                        return { ...each, show_in_ppt: meta.arg.updatedPPTSelection.show }
                    } else {
                        return { ...each }
                    }
                })
                state.allThemesSentence = update
            })
            .addCase(updateSentencePPTSelection.fulfilled, (state: IThemeData, action: any) => { })
            .addCase(updateSentencePPTSelection.rejected, (state: IThemeData, { meta }) => {
                const update = state.allThemesSentence.map((each: any) => {
                    if (meta.arg.updatedPPTSelection.sentence_id[0] === each.sentence_id) {
                        return { ...each, show_in_ppt: !meta.arg.updatedPPTSelection.show }
                    } else {
                        return { ...each }
                    }
                })
                state.allThemesSentence = update
            });

        builder
            .addCase(saveUpdatedSentence.pending, (state: IThemeData, { meta }) => {
            })
            .addCase(saveUpdatedSentence.fulfilled, (state: IThemeData, action: any) => {
                const update = state.allThemesSentence.map((each: any) => {
                    if (action.meta.arg.sentenceId[0] === each.sentence_id) {
                        return { ...each, sentence: action.meta.arg.updatedSentence, is_edited: true }
                    } else {
                        return { ...each }
                    }
                })
                state.allThemesSentence = update
            })
            .addCase(saveUpdatedSentence.rejected, (state: IThemeData) => {
            });
        builder
            .addCase(mergeThemes.pending, (state: IThemeData, { meta }) => {
            })
            .addCase(mergeThemes.fulfilled, (state: IThemeData, action: any) => {

            })
            .addCase(mergeThemes.rejected, (state: IThemeData) => {
            });
        builder
            .addCase(deleteSentence.pending, (state: IThemeData, { meta }) => { })
            .addCase(deleteSentence.fulfilled, (state: IThemeData, action: any) => {
                const update = state.allThemesSentence.filter((each: any) => each.sentence_id !== action.meta.arg.sentenceId)
                state.allThemesSentence = update
            })
            .addCase(deleteSentence.rejected, (state: IThemeData, { meta }) => { });
        builder
            .addCase(getTranscriptDataList.pending, (state: IThemeData) => {
            })
            .addCase(getTranscriptDataList.fulfilled, (state: IThemeData, action: any) => {
                state.allTranscripts = action.payload.response.data.data
            })
            .addCase(getTranscriptDataList.rejected, (state: IThemeData) => {
            });
        builder
            .addCase(getSentenceContext.pending, (state: IThemeData) => {
                state.contextLoader = true
            })
            .addCase(getSentenceContext.fulfilled, (state: IThemeData, action: PayloadAction<any>) => {
                state.sentenceContext = action.payload.response.data
                state.contextLoader = false
            })
            .addCase(getSentenceContext.rejected, (state: IThemeData) => {
                state.contextLoader = false
            })
        builder
            .addCase(getTranscriptIndexes.pending, (state: IThemeData) => {
            })
            .addCase(getTranscriptIndexes.fulfilled, (state: IThemeData, action: PayloadAction<any>) => {
                state.transcriptData = Array.from(Array(action.payload.response.data).keys()).map((index: any) => { return { index: index, data: {} } })
            })
            .addCase(getTranscriptIndexes.rejected, (state: IThemeData) => {
            })
        builder
            .addCase(getTranscriptBlocks.pending, (state: IThemeData) => {
                //state.transcriptLoader=true
            })
            .addCase(getTranscriptBlocks.fulfilled, (state: IThemeData, action: PayloadAction<any>) => {
                const offset = action.payload.offset
                const total = action.payload.response?.data.length
                let temp = JSON.parse(JSON.stringify(state.transcriptData))

                for (let i = 0; i < total; i++) {
                    if (offset + i < temp.length) {
                        temp[offset + i].data = action.payload.response?.data[i]
                    }
                }
                state.transcriptData = temp
                state.transcriptLoader = false
            })
            .addCase(getTranscriptBlocks.rejected, (state: IThemeData) => {
                state.transcriptLoader = false
            })
        builder
            .addCase(getViewByCount.pending, (state: IThemeData) => {
                state.viewByLoader = true
            })
            .addCase(getViewByCount.fulfilled, (state: IThemeData, action: PayloadAction<any>) => {
                state.viewByCount = action.payload.response.data.count
                state.isStarCount = action.payload.response.data.is_star_count
                state.viewByLoader = false
            })
            .addCase(getViewByCount.rejected, (state: IThemeData) => {
                //state.viewByLoader=false
            })
        builder
            .addCase(getOrderedTranscriptIds.pending, (state: IThemeData) => {
                state.transcriptData = []
                state.transcriptTempCont = []
            })
            .addCase(getOrderedTranscriptIds.fulfilled, (state: IThemeData, action: PayloadAction<any>) => {
                state.orderedTranscriptIds = action.payload.response.data
            })
            .addCase(getOrderedTranscriptIds.rejected, (state: IThemeData) => {
            })
        builder
            .addCase(getTranscriptData.pending, (state: IThemeData) => {
            })
            .addCase(getTranscriptData.fulfilled, (state: IThemeData, action: PayloadAction<any>) => {
                if (action.payload.index === 0) {
                    state.transcriptData = reFormatTranscript(action.payload.response?.data)
                    state.transcriptOriginal = action.payload.response?.data
                    //Create a look up for index to response id
                    let tempMapper: any = {}
                    let mapperCount = 0
                    action.payload.response?.data.forEach((item: any, index: number) => {
                        let mapp: any = {}
                        mapperCount = mapperCount + 1
                        item?.r.forEach((resp: { rid: string, s: any }) => {
                            mapp[resp.rid] = mapperCount
                            mapperCount = mapperCount + 1
                        })
                        tempMapper[index] = mapp
                    })
                    state.transcriptLookUp = tempMapper
                }
                else {
                    state.transcriptTempCont = state.transcriptTempCont.concat(action.payload.response?.data)
                }
                if (action.payload.final) {
                    const remReformat = reFormatTranscript([...state.transcriptTempCont])
                    state.transcriptData = state.transcriptData.concat(remReformat)

                    //Complete the look up mapper
                    let temp: any = {}
                    let count = 0
                    const tempData = state.transcriptOriginal.concat(state.transcriptTempCont)
                    tempData.forEach((item: any, index: number) => {
                        let mapp: any = {}
                        count = count + 1
                        item?.r.forEach((resp: { rid: string, s: any }) => {
                            mapp[resp.rid] = count
                            count = count + 1
                        })
                        temp[index] = mapp
                    })
                    state.transcriptLookUp = temp
                }
            })
            .addCase(getTranscriptData.rejected, (state: IThemeData) => {
            })

    }
});

export const { updateSelectedMode, upadateFocusSelection, triggerScroll, updateSelectedView, updateSelectionCount, updateLocalSentenceSentiment } = themeDataSlice.actions;

export default themeDataSlice.reducer;