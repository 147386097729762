import { useEffect, useMemo, useRef, useState } from "react";
import {
  MaterialReactTable,
  type MRT_SortingState,
  type MRT_RowVirtualizer,
} from "material-react-table";
import { Analysis_topics_Summary, Themes_Summary, Entities_Summary, Market_Summary, } from "./Tables"
import { getCEEnabledTabs, getSummaries, updateSummaries } from "../../redux/EditProject/action";
import { RootState, useAppDispatch } from "../../redux/store";
import { useLocation } from "react-router-dom";
import { setResetEnable, setTableChangeStatus, setActiveTab, setupdateSummaries, setScopeUpdated, setupdateSummary } from "../../redux/EditProject/EditProjectReducer";
import Popup from "reactjs-popup";
import { useSelector } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { XCircle } from "react-feather";
export type ActiveTabKey = "Analysis topics Summary" | "Entities Summary" | "Themes Summary" | "Market Summary";


const Summaries = () => {
  const dispatch = useAppDispatch();
  const activeTab = useSelector((state: RootState) => state.editProject.activeTab);
  const summaryData = useSelector((state: RootState) => state.editProject.summaryData)
  const payloadSummary = useSelector((state: RootState) => state.editProject.payloadSummary)
  const [isUpdating, setIsUpdating] = useState(false);
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);
  const { pathname } = useLocation();
  const projectId = pathname.split("/")[2];
  const [data, setData] = useState<any[]>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const changes = useSelector((state: RootState) => state.editProject.changes);
  const isTableChanged = changes[activeTab];
  const heightRef = useRef<any>(null);
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(1);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<string>("");
  const [selectedtab, setSelectedtab] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [typeName, setTypeName] = useState<string>('reporting_bucket');

  useEffect(() => {
    const TopPos = heightRef.current?.getBoundingClientRect().top;
    const LeftPos = heightRef.current?.getBoundingClientRect().left;
    setHeight(window.innerHeight - TopPos - 60);
    setWidth(window.innerWidth - LeftPos - 15);
  });

  useEffect(() => {
    const validTabs: ActiveTabKey[] = ["Analysis topics Summary", "Entities Summary", "Themes Summary", "Market Summary"];
    if (validTabs.includes("Analysis topics Summary" as ActiveTabKey)) {
      dispatch(setActiveTab("Analysis topics Summary" as ActiveTabKey));
    }
  }, []);

  useEffect(() => {
    setData([...summaryData]);
  }, [summaryData])

  const tabTypeMapping: any = {
    "Analysis topics Summary": "reporting_bucket",
    "Entities Summary": "entity",
    "Themes Summary": "theme",
    "Market Summary": "market",
  };

  const idMapping = {
    "Analysis topics Summary": "REPORTING_BUCKET_ID",
    "Entities Summary": "ENTITY_ID",
    "Themes Summary": "THEME_ID",
    "Market Summary": "DISPLAY_NAME",
  };

  const handleCellChange = (tableName: string, rowIndex: number, columnName: string, newValue: any) => {
    dispatch(setScopeUpdated(true))
    dispatch(setResetEnable(true))
    // Update the data state
    let id = ''
    let p_key = ''
    const newData = data.map((item, index) => {

      if (index === rowIndex) {
        id = item[idMapping[activeTab]]
        p_key = item.P_KEY
        return { ...item, p_key, SUMMARY: newValue };
      }
      return item;
    });

    let update = [...payloadSummary]
    const is_exist = update?.find((each: any) => each?.id == id)

    if (is_exist === undefined) {
      update.push({
        id,
        p_key: p_key,
        updated_summary: newValue
      })
    } else {
      update = update?.map((each: any) => {
        if (each.id === id) {
          return { ...each, updated_summary: newValue }
        }
        return { ...each }
      })
    }

    dispatch(setupdateSummaries(update))
    setData(newData);
    dispatch(setupdateSummary(newData));


    // Correctly update the changes state for the specific table
    dispatch(setTableChangeStatus({ tableName: activeTab, hasChanges: true }));
  };

  const entitiesColumns = useMemo(() => Entities_Summary(handleCellChange), [handleCellChange, data]);
  const themesColumns = useMemo(() => Themes_Summary(handleCellChange), [handleCellChange, data]);
  const marketColumns = useMemo(() => Market_Summary(handleCellChange), [handleCellChange, data]);
  const analysisTopicsColumns = useMemo(() => Analysis_topics_Summary(handleCellChange), [handleCellChange, data]);


  const columnsByTab = {
    "Analysis topics Summary": analysisTopicsColumns,
    "Entities Summary": entitiesColumns,
    "Themes Summary": themesColumns,
    "Market Summary": marketColumns,
  };

  const handleReset = () => {
    setIsClicked('Reset')
    setOpenPopup(true);
  };

  const handleApplyChanges = () => {
    setOpenPopup(true)
    setIsClicked('Apply changes');
  };

  const handleProceed = () => {
    setOpenPopup(false);
    if (isClicked === 'Reset') {
      // Fetch original data for the active tab again
      const type = tabTypeMapping[activeTab];
      setData([])
      setIsLoading(true)
      dispatch(getSummaries({ project_id: projectId, type }))
        .unwrap()
        .then((response: any) => {
          // setData(response?.response?.data);
          setIsLoading(false)
          dispatch(setScopeUpdated(false))
          dispatch(setResetEnable(false))

        })
      dispatch(setTableChangeStatus({ tableName: activeTab, hasChanges: false }));
    } else {
      setIsLoading(true)
      setIsUpdating(true);
      // Dispatch the action with the prepared payload
      dispatch(updateSummaries({
        project_id: projectId,
        type: tabTypeMapping[activeTab], // Use the tabTypeMapping to determine the type
        changes: payloadSummary, // Send the changes payload
      }))

        .then((res: any) => {
          if (res.meta.requestStatus === "fulfilled") {
            dispatch(setupdateSummaries([]))
            dispatch(getCEEnabledTabs({ project_id: projectId }))
            dispatch(getSummaries({ project_id: projectId, type: tabTypeMapping[activeTab] })).then(() => setIsLoading(false))
            setTimeout(() => {
              setIsUpdating(false);
              dispatch(setScopeUpdated(false))
              dispatch(setResetEnable(false))
              dispatch(setTableChangeStatus({ tableName: activeTab, hasChanges: false }));
            }, 2000);
          }
        })
    }
    if (isClicked === 'Edited') {
      const validTabs: ActiveTabKey[] = ["Analysis topics Summary", "Entities Summary", "Themes Summary", "Market Summary"];
      if (validTabs.includes(selectedtab as ActiveTabKey)) {
        dispatch(setActiveTab(selectedtab as ActiveTabKey));
      }
    }


  }

  useEffect(() => {
    const updatedData = summaryData.map((item, index) => ({
      ...item,
      sl_no: index + 1,
      SUMMARY: item.SUMMARY === null ? '' : item.SUMMARY
    }));

    setData(updatedData);
  }, [summaryData]);

  //based on the selected tab make api request
  useEffect(() => {
    if (projectId && activeTab) {
      setData([])
      setIsLoading(true)
      dispatch(getSummaries({ project_id: projectId, type: typeName }))
        .unwrap()
        .then(() => {
          setIsLoading(false)
        })
        .catch((error) => console.error(error));
    }

  }, [projectId, activeTab]);


  useEffect(() => {
    // Scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  const handleTabClick = (tabName: string) => {
    if (isTableChanged) {
      setOpenPopup(true);
      setIsClicked('Edited');
      setSelectedtab(tabName)
    } else {
      const validTabs: ActiveTabKey[] = ["Analysis topics Summary", "Entities Summary", "Themes Summary", "Market Summary"];
      if (validTabs.includes(tabName as ActiveTabKey)) {
        dispatch(setActiveTab(tabName as ActiveTabKey));
        setTypeName(tabTypeMapping[tabName])
      }
    }

  };

  const handleCancel = () => {
    setOpenPopup(false);
    if (isClicked === 'Edited') {
      const type = tabTypeMapping[activeTab];
      setData([])
      setIsLoading(true)
      dispatch(getSummaries({ project_id: projectId, type }))
        .unwrap()
        .then((response: any) => {
          // setData(response?.response?.data);
          setIsLoading(false)
          dispatch(setResetEnable(false))
          dispatch(setScopeUpdated(false))
        })
      dispatch(setTableChangeStatus({ tableName: activeTab, hasChanges: false }));
    }
    setIsClicked("");
    if (isClicked === 'Edited') {
      const validTabs: ActiveTabKey[] = ["Analysis topics Summary", "Entities Summary", "Themes Summary", "Market Summary"];
      if (validTabs.includes(selectedtab as ActiveTabKey)) {
        dispatch(setActiveTab(selectedtab as ActiveTabKey));
      }
    }
  }
  return (
    <div className="">
      <div className="flex justify-between w-full">
        <div className="flex items-center">
          {Object.keys(tabTypeMapping).map((tabName) => (
            <button
              key={tabName}
              className={`bg-[#9747FF] py-1 px-4 text-white font-normal rounded-lg mx-1 cursor-pointer ${activeTab === tabName ? "bg-opacity-100" : "bg-opacity-50"
                }`}
              onClick={() => handleTabClick(tabName)}
            >
              {tabName}
            </button>
          ))}
        </div>
        <div className="flex">
          <button
            className={`${isTableChanged ? "bg-[#9747FF]" : "bg-[#808080]"
              } transition-all py-1 px-5 text-white font-normal text-lg rounded-lg mx-1 cursor-pointer`}
            onClick={handleReset}
            disabled={!isTableChanged}
          >
            Reset
          </button>
          <button
            className={`${isTableChanged ? "bg-[#9747FF]" : "bg-[#808080]"
              } bg-[#808080] py-1 px-5 text-white font-normal text-lg rounded-lg mx-1 cursor-pointer`}
            onClick={handleApplyChanges}
            disabled={isUpdating || !isTableChanged}
          >
            Apply Changes
          </button>
        </div>
        {/* <Popup
        modal
        open={isUpdating}
        contentStyle={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "transparent",
          border: "none",
        }}
      >
        <div className="flex flex-col items-start justify-center mr-2  bg-transparent pb-6 pt-5">
          <Spinner />
        </div>
      </Popup> */}
      </div>
      <div className="mt-3" ref={heightRef} style={{ height: height, width: width }}>
        {Object.entries(columnsByTab).map(([tabName, columns], index) => (
          <div key={index} className={activeTab === tabName ? "block" : "hidden"}
          >
            {(data.length > 0 || isLoading) ?
              <MaterialReactTable
                columns={columns}
                data={data}
                globalFilterFn='contains'
                editDisplayMode="cell"
                defaultDisplayColumn={{ enableResizing: true }}
                enableBottomToolbar={false}
                enableColumnResizing={true}
                enableColumnVirtualization={true}
                enableGlobalFilterModes={true}
                enablePagination={false}
                enableColumnPinning={true}
                // enableRowNumbers={true}
                enableRowVirtualization={true}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: height - 50,
                    overflowY: 'auto',
                    overflowX: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#CECDCD',
                      borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: '#F5F7FB',
                    },
                  },
                }}
                onSortingChange={setSorting}
                state={{ isLoading, sorting }}
                rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
                rowVirtualizerOptions={{ overscan: 5 }}
                columnVirtualizerOptions={{ overscan: 2 }}
                muiTableProps={{
                  sx: {
                    borderCollapse: 'collapse',
                    '& td': {
                      border: '1px solid #e2e8f0',
                      padding: '5px',
                      height: '70px',
                      fontSize: '1rem',
                      fontFamily: "'Poppins'",
                      color: "black"
                    },
                    '& th': {
                      fontSize: "1rem",
                      fontFamily: "'Poppins'"
                    },
                  },
                }}
              />
              :
              <>
                <div className="flex justify-center items-center bg-white" style={{ height: height, width: width }} >No data available</div>
              </>
            }
          </div>
        ))}
      </div>
      <Popup
        open={openPopup}
        // onClose={() => setOpenPopup(false)}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        contentStyle={{
          width: "34vw",
          boxShadow: "0px 0px 1px white",
          backgroundColor: "white",
          borderRadius: "6px",
        }}
      >
        <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
          <div className="flex justify-between items-center w-full mb-6">
            <p className="font-base text-lg  flex items-center pl-10 pr-10">
              {" "}
              <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
            </p>
            <XCircle
              className="mr-6"
              size={24}
              style={{ cursor: "pointer" }}
              fill="#BDBCBC"
              color="#FFFF"
              onClick={() => {
                setOpenPopup(false);
              }}
            />
          </div>
          <span
            className="font-base pb-6 -2 pl-10 pr-10"
            style={{ fontSize: "12px", color: "#545454" }}
          >
            {isClicked === 'Edited' ? 'You are leaving the tab. Do you want to apply changes and continue? If not, the changes will not be saved.' : isClicked === 'Reset' ? ' Changes will be discarded. Do you wish to proceed? ' : " Applying changes. Do you wish to proceed?"}
          </span>
          <div
            className=" mt-5 flex justify-between items-center ml-10 mr-10"
            style={{ width: "90%" }}
          >
            <button
              style={{ color: " #8088A4", border: "none" }}
              onClick={handleCancel}
            >
              {isClicked === 'Edited' ? 'Continue anyway' : 'Cancel'}
            </button>
            <button
              onClick={handleProceed}
              className="border w-48 p-2 rounded"
              style={{ border: "1px solid red", color: "red" }}
            >
              {isClicked === 'Edited' ? 'Apply and continue' : 'Proceed'}
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Summaries;