import { axiosconfig } from "../../service/apiconfig";

export const FocusApi = {
    getThemes: ({project_id, sentiment, question_bucket_id} : any) => {
        let params:any = {}
        if (sentiment){
            params.type = sentiment
        }
        if (question_bucket_id){
            params.question_bucket_id = question_bucket_id
        }
        return axiosconfig.get(`projects/${project_id}/theme`, {params: params})
    },
    reportingQB:({project_id,question_bucket_id }: any) => {
        return axiosconfig.post(`projects/${project_id}/question_buckets_v3?offset=0&limit=100`,{question_bucket_id,attribute:[]} )
    },
    entities:({project_id}:any)=>{
        return axiosconfig.get(`projects/${project_id}/entities`)
    },
    sentenceHide: ({ payload}:any) => {
        return axiosconfig.post(`projects/${payload.project_id}/focus/action=hide`, payload)
    },
    mergeThemes: ({projectId, payload}:any) => {
        return axiosconfig.post(`projects/${projectId}/focus/action=merge`, payload)
    },
    getSentenceMatrix: ({projectId, questionBucketId, themesId, entityId}: any) => {
        return axiosconfig.post(`projects/${projectId}/sentence_count_matrix`, {project_id: projectId, question_bucket_id: questionBucketId, themes_id: themesId, entity_id: entityId})
    },
    updateSentimentThemes: ({ project_id, theme_id, sentiment }: any) => {
        return axiosconfig.put(`projects/${project_id}/theme_sentiment/${theme_id}/${sentiment}`)
    },
    updateSentenceSentiment:({project_id,sentiment,sentenceId}:any)=>{
        return axiosconfig.put(`/projects/${project_id}/sentence_sentiment/${sentiment}?sentence_id=${sentenceId}`)
    },
    getSentenceBubbleData:({project_id,bucket_id,bucket_type, filter_payload}:any)=>{
        return axiosconfig.post(`/projects/${project_id}/bubble_bucket?bucket_id=${bucket_id}&bucket_type=${bucket_type}`, filter_payload)
    },
    updateBubbleBookMark:({project_id,bucket_id,bubble_bucket_id ,is_bookmarked}:any)=>{
        return axiosconfig.get(`/projects/${project_id}/bookmark_bubble?bucket_id=${bucket_id}&bubble_bucket_id=${bubble_bucket_id}&is_bookmarked=${is_bookmarked}`)
    }
}
