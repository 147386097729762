import { useState } from "react"
import { RootState, useAppDispatch } from "../../redux/store"
import Popup from "reactjs-popup"
import { IconButton, MenuItem, } from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel';
import { useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import { getDomains } from "../../redux/DomAccept/action";

interface ISeeDomains {
    type: string
    handleClose: () => void
}

export default function SeeDomains(props: ISeeDomains) {

    const dispatch = useAppDispatch()
    const pendingLoadder = useSelector((state: RootState) => state.domAccept.getPendingLoader)
    const domainData = useSelector((state: RootState) => state.domAccept.domainsData)
    const [open, setOpen] = useState<boolean>(false)

    return (
        <Popup
            modal
            open={open}
            onOpen={() => {
                setOpen((prev) => !prev)
                let value = props.type === "approved"
                dispatch(getDomains({ is_approved: value }))
            }}
            onClose={() => {
                setOpen(false);
            }}
            contentStyle={{
                width: "34vw",
                backgroundColor: "white",
                borderRadius: "6px",
                zIndex: 10000
            }}
            trigger={
                <MenuItem onClick={() => props.handleClose()}>
                    <span className="mx-auto w-full text-center" style={{ fontFamily: '"Poppins", sans-serif' }}>
                        {props.type === "approved" ? "Approved Domains" : "Rejected Domains"}
                    </span>
                </MenuItem>
            }
        >
            <div>
                <div className="flex justify-between items-center px-2">
                    <div className="font-semibold text-lg">Full list of {props.type === "approved" ? "Approved" : "Rejected"} Domains :</div>
                    <IconButton sx={{ padding: "0px" }} onClick={() => setOpen(false)}><CancelIcon /></IconButton>
                </div>
                <div className="border rounded-md mt-2 overflow-y-auto" style={{ maxHeight: "60vh" }}>
                    <div className="grid grid-cols-2 border-b">
                        <div className="flex justify-center items-center border-r py-1"><span className="font-semibold">Company Name</span></div>
                        <div className="flex justify-center items-center py-1"><span className="font-semibold">Email Domain</span></div>
                    </div>
                    {pendingLoadder ?
                        <div className="h[400px] flex justify-center items-center">
                            <Spinner />
                        </div>
                        :
                        domainData.map((item: any, i: number) => {
                            return (
                                <div className="grid grid-cols-2 border-b" key={i}>
                                    <div className="flex justify-center items-center border-r py-1">
                                        <span className="text-center">{item.COMPANY_NAME}</span>
                                    </div>
                                    <div className="flex justify-center items-center py-1">
                                        <span className="text-center">{item.DOMAIN_NAME}</span>
                                    </div>
                                </div>
                            )
                        }
                        )
                    }
                </div>
            </div>
        </Popup>
    )
}