import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getJobRoles, getUsers, updatePhoneNumber, login, resetPassword, setPassword, signup, verifyEmail, userLogout, refreshAccessToken } from "./action";
import { IProfileData } from "./type";
import jwt_decode from "jwt-decode";
import { secrateKey } from "../../modules/LoginLayout/LoginLayout";
import * as CryptoJS from 'crypto-js';
import { getUserDetails } from "../../utils/localStorage";




const initialState: IProfileData = {
    loginSuccess: false,
    errorMessage: "",
    loginDetails: {},
    resetPasswordSent: false,
    loginLoader: false,
    signupLoader: false,
    mailSentMessage: "",
    resestPwdSucc: false,
    signupMailSent: false,
    jobRolesData: [],
    countriesData: [],
    usersData: [],
    userInfo: {},
    verifyEmailSucc: false,
    tokenExpiry: false,
    logoutStatus: false,
    updatedAccessToken: '',
    intialTimeOfLogin: '',
    intialTimeofLoginTimeStamp: '',
}

interface IUser {
    accountName: string;
    email: string;
    displayName: string;
    accessToken: string;
    refreshToken: string;
}

function decryptUserData(encryptedText: string) {
    const bytes = CryptoJS.AES.decrypt(encryptedText, '#1234');
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
}

export const profileDataSlice = createSlice({
    name: "profileData",
    initialState: initialState,
    reducers: {
        updateLoginDetails: (state, action) => {
            state.loginDetails = {};
        },
        updateStatus: (state) => {
            state.loginSuccess = false
        },
        updateDisplayName: (state, action) => {
            state.userInfo = { ...state.userInfo, FIRSTNAME: action.payload.first_name, LASTNAME: action.payload.last_name }
        },
        updateLoginDisplayName: (state, action) => {
            state.loginDetails = { ...state.loginDetails, displayName: action.payload.first_name }
        },
        updateSignUpstate: (state) => {
            state.signupMailSent = false;
        },
        updateTokenExpiry: (state, action) => {
            state.tokenExpiry = action.payload;
        },
        updateErrorNote: (state, action) => {
            state.errorMessage = ""
        },
        updateUserInfo: (state) => {
            const encryptedUserData = localStorage.getItem('_U');
            if (encryptedUserData) {
                const userDataString = JSON.parse(encryptedUserData);
                const userData = decryptUserData(userDataString);
                state.userInfo = {
                    USERNAME: userData?.email,
                    FIRSTNAME: userData?.firstName,
                    LASTNAME: userData?.lastName,
                    PHONENUMBER: userData?.phoneNumber,
                }
            } else {
                state.userInfo = {}
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state: IProfileData, action: PayloadAction<any>) => {
                state.loginLoader = true
            })
            .addCase(login.fulfilled, (state: IProfileData, action: PayloadAction<any>) => {
                state.loginLoader = false
                if (action.payload.response.status) {
                    let IST_offset = 5.5 * 60 * 60 * 1000;
                    state.loginSuccess = true;
                    state.loginDetails = action.payload?.response.data
                    state.intialTimeOfLogin = action.payload.response.timestamp
                    let intialLogin = state.intialTimeOfLogin
                    state.intialTimeofLoginTimeStamp = new Date(new Date(intialLogin).getTime() + IST_offset).getTime();
                    let intialTimeStamp = state.intialTimeofLoginTimeStamp

                    localStorage.setItem("timeStamp", JSON.stringify(intialTimeStamp))
                }
                else {
                    state.loginSuccess = false;
                    state.errorMessage = action.payload.response.error
                }
            })
            .addCase(login.rejected, (state: IProfileData, action: PayloadAction<any>) => {
                state.loginSuccess = false;
                state.errorMessage = action.payload?.detail
                state.loginLoader = false
            });
        builder
            .addCase(resetPassword.pending, (state: IProfileData, action: PayloadAction<any>) => {
            })
            .addCase(resetPassword.fulfilled, (state: IProfileData, action: PayloadAction<any>) => {
                state.resetPasswordSent = action.payload.response.status
            })
            .addCase(resetPassword.rejected, (state: IProfileData, action: PayloadAction<any>) => {
                state.resetPasswordSent = false
                state.mailSentMessage = action.payload.response
            });
        builder
            .addCase(setPassword.pending, (state: IProfileData, action: PayloadAction<any>) => {
            })
            .addCase(setPassword.fulfilled, (state: IProfileData, action: PayloadAction<any>) => {
                state.resestPwdSucc = true
            })
            .addCase(setPassword.rejected, (state: IProfileData, action: PayloadAction<any>) => {
                state.resestPwdSucc = false
            });
        builder
            .addCase(verifyEmail.pending, (state: IProfileData, action: PayloadAction<any>) => {
            })
            .addCase(verifyEmail.fulfilled, (state: IProfileData, action: PayloadAction<any>) => {
                state.verifyEmailSucc = true
            })
            .addCase(verifyEmail.rejected, (state: IProfileData, action: PayloadAction<any>) => {
                state.verifyEmailSucc = false
            });
        builder
            .addCase(signup.pending, (state: IProfileData, action: PayloadAction<any>) => {
                state.signupLoader = true
            })
            .addCase(signup.fulfilled, (state: IProfileData, action: PayloadAction<any>) => {
                state.signupMailSent = true
                state.signupLoader = false
            })
            .addCase(signup.rejected, (state: IProfileData, action: PayloadAction<any>) => {
                state.signupMailSent = false
                state.signupLoader = false
            });
        builder
            .addCase(getJobRoles.pending, (state: IProfileData, action: PayloadAction<any>) => {
            })
            .addCase(getJobRoles.fulfilled, (state: IProfileData, action: PayloadAction<any>) => {
                state.jobRolesData = action.payload.response
            })
            .addCase(getJobRoles.rejected, (state: IProfileData, action: PayloadAction<any>) => {
            });
        builder
            .addCase(getUsers.pending, (state: IProfileData, action: PayloadAction<any>) => {
            })
            .addCase(getUsers.fulfilled, (state: IProfileData, action: PayloadAction<any>) => {
                state.usersData = action.payload.response
            })
            .addCase(getUsers.rejected, (state: IProfileData, action: PayloadAction<any>) => {
            });
        builder
            .addCase(updatePhoneNumber.pending, (state: IProfileData, action: PayloadAction<any>) => { })
            .addCase(updatePhoneNumber.fulfilled, (state: IProfileData, action: any) => {
                state.userInfo = { ...state.userInfo, PHONENUMBER: action.meta.arg.phoneNumber }
            })
            .addCase(updatePhoneNumber.rejected, (state: IProfileData, action: PayloadAction<any>) => {
            });
        // builder
        //     .addCase(getUser.pending, (state: IProfileData, action: PayloadAction<any>) => {
        //     })
        //     .addCase(getUser.fulfilled, (state: IProfileData, action: PayloadAction<any>) => {
        //         state.userInfo = action.payload.response
        //     })
        //     .addCase(getUser.rejected, (state: IProfileData, action: PayloadAction<any>) => {
        //     })
        builder
            .addCase(userLogout.pending, (state: IProfileData, action: PayloadAction<any>) => {
            })
            .addCase(userLogout.fulfilled, (state: IProfileData, action: PayloadAction<any>) => {
                state.logoutStatus = action.payload.response.status
            })
            .addCase(userLogout.rejected, (state: IProfileData, action: PayloadAction<any>) => {
            })
        // builder
        //     .addCase(refreshAccessToken.pending, (state: IProfileData, action: PayloadAction<any>) => {
        //     })
        //     .addCase(refreshAccessToken.fulfilled, (state: IProfileData, action: PayloadAction<any>) => {
        //         if (action.payload.response.status === true) {
        //             const userDetails= getUserDetails()
        //             let loginDetails:any
        //             let updatedLoginTime:any
        //             state.loginDetails=JSON.parse(JSON.stringify(state.loginDetails))

        //             if(state.loginDetails){
        //                 loginDetails = state.loginDetails
        //             }else{
        //                 loginDetails={...userDetails,
        //                     isFirstTimeLogin:false}
        //             }

        //             state.updatedAccessToken = action.payload.response.data["access_token"]
        //             let updatedLoginDetails = { ...loginDetails, accessToken: state.updatedAccessToken}
        //             let IST_offset = 5.5 * 60 * 60 * 1000;

        //             state.loginDetails = updatedLoginDetails
        //             loginDetails=state.loginDetails

        //             state.intialTimeOfLogin = action.payload.response.timestamp
        //             updatedLoginTime=new Date(new Date(action.payload.response.timestamp).getTime() + IST_offset).getTime()

        //             state.intialTimeofLoginTimeStamp= updatedLoginTime

        //            //increasing the expiry time by 1 hour
        //             const decodedAT: any = jwt_decode(loginDetails?.accessToken);
        //             const expiry = {
        //                 exp: decodedAT.exp * 1000 + 60*60*1000,
        //                 time: new Date(decodedAT.exp * 1000 + 60*60*1000).toISOString(),
        //             };
        //             const cuser: IUser = {
        //                 accountName: loginDetails?.accountName,
        //                 email: loginDetails?.email,
        //                 displayName: loginDetails?.displayName,
        //                 accessToken: loginDetails?.accessToken,
        //                 refreshToken: loginDetails?.refreshToken,
        //             };
        //             const encryptedText = CryptoJS.AES.encrypt(JSON.stringify(cuser), secrateKey).toString();

        //             localStorage.setItem("_U", JSON.stringify(encryptedText));
        //             localStorage.setItem("expiry", JSON.stringify(expiry));
        //             localStorage.setItem('timeStamp',updatedLoginTime)
        //         }
        //   })
        //     .addCase(refreshAccessToken.rejected, (state: IProfileData, action: PayloadAction<any>) => {
        //     })
    }
})

export const { updateLoginDetails, updateStatus, updateDisplayName, updateLoginDisplayName, updateSignUpstate, updateErrorNote, updateUserInfo, updateTokenExpiry
} =
    profileDataSlice.actions;

export default profileDataSlice.reducer;