import { Button, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import CancelIcon from '@mui/icons-material/Cancel';
import { useAppDispatch } from "../../redux/store";
import { postDomainOutcomes } from "../../redux/DomAccept/action";

interface IConfirmSlotPopup {
    dataList: Array<any>
}

export default function ConfirmSlotPopup(props: IConfirmSlotPopup) {

    const dispatch = useAppDispatch()

    const [open, setOpen] = useState<boolean>(false)
    const [accepted, setAccepted] = useState<Array<any>>([])
    const [rejected, setRejected] = useState<Array<any>>([])

    const getLarger = () => {
        return accepted.length > rejected.length ? accepted.length : rejected.length
    }

    const handleOK = () => {
        let payload: any = {}
        payload["approved"] = props.dataList.filter((item: any) => item.outcome === "Accepted").map((item: any) => item.DOMAIN_ID)
        payload["rejected"] = props.dataList.filter((item: any) => item.outcome === "Rejected").map((item: any) => item.DOMAIN_ID)
        dispatch(postDomainOutcomes({ payload }))
        setOpen(false)
    }

    useEffect(() => {
        setAccepted(props.dataList.filter((item: any) => item.outcome === "Accepted"))
        setRejected(props.dataList.filter((item: any) => item.outcome === "Rejected"))
    }, [props.dataList])

    return (
        <Popup
            modal
            open={open}
            onOpen={() => {
                setOpen((prev) => !prev);
            }}
            onClose={() => {
                setOpen(false);
            }}
            contentStyle={{
                width: "34vw",
                backgroundColor: "white",
                borderRadius: "6px",
            }}
            trigger={
                <Button variant="contained"><span className="px-5">Confirm</span></Button>
            }
        >
            <div>
                <div className="flex justify-between items-center px-2">
                    <div className="font-semibold text-lg">Confirm that these are the changes that you are making :</div>
                    <IconButton sx={{ padding: "0px" }} onClick={() => setOpen(false)}><CancelIcon /></IconButton>
                </div>
                <div className="border rounded-md mt-2">
                    <div className="grid grid-cols-2">
                        <div className="bg-green-100 flex justify-center items-center border-r py-1"><span className="font-semibold">Approved</span></div>
                        <div className="bg-red-100 flex justify-center items-center py-1"><span className="font-semibold">Rejecetd</span></div>
                    </div>
                    {Array.from(Array(getLarger()).keys()).map((item: any, i: number) => {
                        return (
                            <div className="grid grid-cols-2 border-b" key={i}>
                                <div className="flex justify-center items-center border-r py-1">
                                    <span>{i < accepted.length ? accepted[i].DOMAIN_NAME : ""}</span>
                                </div>
                                <div className="flex justify-center items-center py-1">
                                    <span>{i < rejected.length ? rejected[i].DOMAIN_NAME : ""}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="flex justify-center items-center gap-10 py-2 mt-1">
                    <Button variant="outlined" onClick={() => setOpen(false)}><span className="px-7" >No</span></Button>
                    <Button onClick={handleOK} variant="contained"><span className="px-7" >Yes</span></Button>
                </div>
            </div>
        </Popup>
    )
}
