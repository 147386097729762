import { createSlice } from "@reduxjs/toolkit";

interface ICurrentPage {
    currentPage: String
}

const initialState: ICurrentPage = {
    currentPage: "Review"
}
export const currentPageSlice = createSlice({
    name: "site",
    initialState: initialState,
    reducers: {
        updateCurrentPage: (state, action) => {
            state.currentPage = action.payload;
            // localStorage.setItem("currentPage", action.payload); // Persist the selected page to local storage
        },
    }
})
export const {
    updateCurrentPage
} = currentPageSlice.actions

export default currentPageSlice.reducer