import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IEditProject } from "./type";
import { ActiveTabKey } from "../../modules/EditProject/Summaries"
import { getAllAccounts, getThemeMasterData, getEntitySentencesData, getEntityMaster, getEntityTypeData, updateEntityTypeData, getEntitySentencesSeeContextData, getThemeSentencesData, updateProjectScope, updateThemeMasterData, getSummaries, getCEEnabledTabs, updateEntityMaster, updateEntitySentenceData, triggerCEReprocessing, updateThemeSentenceData, moveProject, getSummaryReport } from "./action";


const initialState: IEditProject = {
  confirmProceedToSummaries: false,
  destinationAccountName: "",
  destinationAccountId: "",
  themeMasterData: [],
  summaryData: [],
  themeSentencesData: [],
  isResetEnabled: false,
  isScopeUpdated: false,
  entityTypeData: [],
  entityMasterData: [],
  AllAccountsData: [],
  updateProjectScopeSuccess: false,
  updateProjectScopeLoader: false,
  moveProjectLoader: false,
  updateEntityTypeDataSuccess: [],
  updateEntityTypeDataLoader: false,
  entityTypeDataLoader: false,
  getEntityMasterLoader: false,
  updateEntityMasterLoader: false,
  CETabLoader: 0,
  openPopup: false,
  submitEnable: false,
  tabSwitchPopUpEnable: false,
  reportSummaryData: [],
  isSummaryEdited: false,
  isSystemGeneratedReports: false,

  changes: {
    "Analysis topics Summary": false,
    "Entities Summary": false,
    "Themes Summary": false,
    "Market Summary": false,
  },
  activeTab: "Analysis topics Summary" as ActiveTabKey,
  payloadSummary: [],
  entitySentencesData: [],
  entitySentencesDataLoading: true,
  entitySentencesSeeContextData: [],
  themeMasterDataLoading: false,
  themeSentenceDataLoading: false,
  tabsEnabledData: [],
  updateThemeMasterStatus: false,
  updateThemeSentenceStatus: false

}

export const EditProjectSlice = createSlice({
  name: "EditProject",
  initialState: initialState,
  reducers: {
    setConfirmProceedToSummaries: (state, action: PayloadAction<boolean>) => {
      state.confirmProceedToSummaries = action.payload;
    },
    setDestinationAccountName: (state, action: PayloadAction<string>) => {
      state.destinationAccountName = action.payload;
    },
    setDestinationAccountId: (state, action: PayloadAction<string>) => {
      state.destinationAccountId = action.payload;
    },
    setResetEnable: (state, action: PayloadAction<boolean>) => {
      state.isResetEnabled = action.payload
    },
    setTableChangeStatus: (state, action: PayloadAction<{ tableName: ActiveTabKey, hasChanges: boolean }>) => {
      const { tableName, hasChanges } = action.payload;
      state.changes[tableName] = hasChanges;
    },
    setResetEntitySentencesSeeContextData: (state, action: PayloadAction<Array<any>>) => {
      state.entitySentencesSeeContextData = action.payload
    },
    setActiveTab: (state, action: PayloadAction<ActiveTabKey>) => {
      state.activeTab = action.payload;
    },
    setupdateSummary: (state, action: PayloadAction<any>) => {
      state.summaryData = action.payload;
    },
    setupdateSummaries: (state, action) => {
      state.payloadSummary = action.payload
    },
    setUpdateEntitySentencesDataLoading: (state, action) => {
      state.entitySentencesDataLoading = action.payload
    },
    setScopeUpdated: (state, action: PayloadAction<boolean>) => {
      state.isScopeUpdated = action.payload;
    },
    setOpenPopup: (state, action: PayloadAction<boolean>) => {
      state.openPopup = action.payload;
    },
    setSubmitEnable: (state, action: PayloadAction<boolean>) => {
      state.submitEnable = action.payload;
    },
    setTabSwitchPopUpEnable: (state, action: PayloadAction<boolean>) => {
      state.tabSwitchPopUpEnable = action.payload;
    },
    updateIsDisable: (state, action) => {
      state.CETabLoader = action.payload
    },
    updateReportSummaryData: (state, action) => {
      state.reportSummaryData = action.payload
    },
    updateIsEditedSummaryReport: (state, action) => {
      state.isSummaryEdited = action.payload;
    },
    setIsSystemGeneratedReports: (state, action: PayloadAction<boolean>) => {
      state.isSystemGeneratedReports = action.payload;
    },

  },


  extraReducers: (builder) => {
    builder
      .addCase(getThemeMasterData.pending, (state: IEditProject, action: PayloadAction<any>) => {
        state.themeMasterDataLoading = true
      })
      .addCase(getThemeMasterData.fulfilled, (state: IEditProject, action: PayloadAction<any>) => {
        if (action.payload.response.status === true) {
          state.themeMasterDataLoading = false
          state.themeMasterData = action.payload.response.data
        }
      })
      .addCase(getThemeMasterData.rejected, (state: IEditProject, action: PayloadAction<any>) => {
        state.themeMasterDataLoading = false
      });
    builder
      .addCase(getThemeSentencesData.pending, (state: IEditProject, action: PayloadAction<any>) => {
        state.themeSentenceDataLoading = true
      })
      .addCase(getThemeSentencesData.fulfilled, (state: IEditProject, action: PayloadAction<any>) => {
        if (action.payload.response.status === true) {
          state.themeSentencesData = action.payload.response.data
          state.themeSentenceDataLoading = false
        }
      })
      .addCase(getThemeSentencesData.rejected, (state: IEditProject, action: PayloadAction<any>) => {
        state.themeSentenceDataLoading = false
      });


    builder
      .addCase(getAllAccounts.pending, (state: IEditProject, action: PayloadAction<any>) => {
      })
      .addCase(getAllAccounts.fulfilled, (state: IEditProject, action: PayloadAction<any>) => {
        state.AllAccountsData = action.payload.response.data
      })
      .addCase(getAllAccounts.rejected, (state: IEditProject, action: PayloadAction<any>) => {
      });
    builder
      .addCase(
        getEntityTypeData.pending,
        (state: IEditProject, action: PayloadAction<any>) => {
          state.entityTypeDataLoader = true
          state.updateEntityTypeDataLoader = false
          state.entityTypeData = []
        }
      )
      .addCase(
        getEntityTypeData.fulfilled,
        (state: IEditProject, action: PayloadAction<any>) => {
          if (action.payload.response.status === true) {
            state.entityTypeData = action.payload.response.data.map((item: any, index: any) => {
              return {
                "ID": index + 1,
                "entity_type": item,
                "rename_to": "",
                "delete_type": false,
                "merge_to": "",
              }
            });
          }
          state.entityTypeDataLoader = false
        }
      )
      .addCase(
        getEntityTypeData.rejected,
        (state: IEditProject, action: PayloadAction<any>) => { }
      );
    builder
      .addCase(getEntityMaster.pending, (state: IEditProject, action: PayloadAction<any>) => {
        state.getEntityMasterLoader = true
        state.updateEntityMasterLoader = false
        state.entityMasterData = []
      })
      .addCase(getEntityMaster.fulfilled, (state: IEditProject, action: PayloadAction<any>) => {
        state.entityMasterData = action.payload.response.data.map((item: any, index: number) => {
          return {
            ...item,
            "ID": index + 1,
            "rename_to": "",
            "merge_to": "",
            "move_to": "",
            "delete_anchor": false,
          }
        })
        state.getEntityMasterLoader = false
      })
      .addCase(getEntityMaster.rejected, (state: IEditProject, action: PayloadAction<any>) => {
      })
    builder
      .addCase(updateProjectScope.pending, (state: IEditProject) => {
        state.updateProjectScopeLoader = true
      })
      .addCase(
        updateProjectScope.fulfilled,
        (state: IEditProject, action: PayloadAction<any>) => {
          state.updateProjectScopeSuccess = action.payload.response.status;
          state.updateProjectScopeLoader = false
        }
      )
      .addCase(updateProjectScope.rejected, (state: IEditProject) => {
        state.updateProjectScopeLoader = false
      });
    builder
      .addCase(moveProject.pending, (state: IEditProject) => {
        state.moveProjectLoader = true
      })
      .addCase(
        moveProject.fulfilled,
        (state: IEditProject, action: PayloadAction<any>) => {

          state.moveProjectLoader = false
        }
      )
      .addCase(moveProject.rejected, (state: IEditProject) => {
        state.moveProjectLoader = false
      });
    builder
      .addCase(updateEntityTypeData.pending, (state: IEditProject) => {
        state.updateEntityTypeDataLoader = true
      }).
      addCase(updateEntityTypeData.fulfilled, (state: IEditProject, action: any) => {
        state.updateEntityTypeDataLoader = false
      })
      .addCase(updateEntityTypeData.rejected, (state: IEditProject) => {

      })

    builder
      .addCase(getSummaries.pending, (state: IEditProject, action: PayloadAction<any>) => {
      })
      .addCase(getSummaries.fulfilled, (state: IEditProject, action: PayloadAction<any>) => {

        if (action.payload.response.status === true) {
          state.summaryData = action.payload.response.data
        }
      })
      .addCase(getSummaries.rejected, (state: IEditProject, action: PayloadAction<any>) => {
      });
    builder
      .addCase(updateThemeMasterData.pending, (state: IEditProject, action: PayloadAction<any>) => {
      })
      .addCase(updateThemeMasterData.fulfilled, (state: IEditProject, action: PayloadAction<any>) => {
        if (action.payload.response.status == true) {
          state.updateThemeMasterStatus = true
        }
      })
      .addCase(updateThemeMasterData.rejected, (state: IEditProject, action: PayloadAction<any>) => {
      });

    builder
      .addCase(updateEntitySentenceData.pending, (state: IEditProject, action: PayloadAction<any>) => {
      })
      .addCase(updateEntitySentenceData.fulfilled, (state: IEditProject, action: PayloadAction<any>) => {
        state.entitySentencesDataLoading = true
      })
      .addCase(updateEntitySentenceData.rejected, (state: IEditProject, action: PayloadAction<any>) => {
      });

    builder
      .addCase(getEntitySentencesData.pending, (state: IEditProject, action: PayloadAction<any>) => {
        state.entitySentencesDataLoading = true
      })
      .addCase(getEntitySentencesData.fulfilled, (state: IEditProject, action: PayloadAction<any>) => {
        if (action.payload.response.status === true) {
          state.entitySentencesData = action.payload.response.data
          state.entitySentencesDataLoading = false
        }
        else {
          state.entitySentencesDataLoading = false
        }
      })
      .addCase(getEntitySentencesData.rejected, (state: IEditProject, action: PayloadAction<any>) => {
        state.entitySentencesDataLoading = false
      });
    builder
      .addCase(getEntitySentencesSeeContextData.pending, (state: IEditProject, action: PayloadAction<any>) => {
      })
      .addCase(getEntitySentencesSeeContextData.fulfilled, (state: IEditProject, action: PayloadAction<any>) => {
        if (action.payload.response.status === true) {
          state.entitySentencesSeeContextData = action.payload.response.data
        }
      })
      .addCase(getEntitySentencesSeeContextData.rejected, (state: IEditProject, action: PayloadAction<any>) => {
      });
    builder
      .addCase(getCEEnabledTabs.pending, (state: IEditProject, action: PayloadAction<any>) => {
      })
      .addCase(getCEEnabledTabs.fulfilled, (state: IEditProject, action: PayloadAction<any>) => {
        if (action.payload.response.status === true) {
          state.tabsEnabledData = action.payload.response.data

        }
      })
      .addCase(getCEEnabledTabs.rejected, (state: IEditProject, action: PayloadAction<any>) => {
      });
    builder
      .addCase(updateThemeSentenceData.pending, (state: IEditProject, action: PayloadAction<any>) => {
      })
      .addCase(updateThemeSentenceData.fulfilled, (state: IEditProject, action: PayloadAction<any>) => {
        if (action.payload.response.status == true) {
          state.updateThemeSentenceStatus = true
        }
      })
      .addCase(updateThemeSentenceData.rejected, (state: IEditProject, action: PayloadAction<any>) => {
      });
    builder
      .addCase(updateEntityMaster.pending, (state: IEditProject) => {
        state.updateEntityMasterLoader = true
      }).
      addCase(updateEntityMaster.fulfilled, (state: IEditProject, action: any) => {
        state.updateEntityMasterLoader = false
      })
      .addCase(updateEntityMaster.rejected, (state: IEditProject) => {

      })
    builder
      .addCase(triggerCEReprocessing.pending, (state: IEditProject, action: PayloadAction<any>) => {
      })
      .addCase(triggerCEReprocessing.fulfilled, (state: IEditProject, action: PayloadAction<any>) => {
      })
      .addCase(triggerCEReprocessing.rejected, (state: IEditProject) => {
      })
    builder
      .addCase(getSummaryReport.pending, (state: IEditProject, action: PayloadAction<any>) => {
      })
      .addCase(getSummaryReport.fulfilled, (state: IEditProject, action: PayloadAction<any>) => {
        const update = action.payload.response.data?.map((item: any, index: number) => {
          return {
            ...item, isEdited: false,
            TOPIC_TYPE: item.TOPIC_TYPE === 'Reporting_Bucket' ? 'Analysis topic' : item.TOPIC_TYPE,
            NESTING_TYPE: item.NESTING_TYPE === null ? '' : item.NESTING_TYPE,
            sl_no: index + 1,
            SUMMARY: item.SUMMARY === null ? '' : item.SUMMARY
          }
        })
        state.reportSummaryData = update
      })
      .addCase(getSummaryReport.rejected, (state: IEditProject) => {
      })

  },

});

export const { setConfirmProceedToSummaries, setupdateSummary, setActiveTab, setTableChangeStatus, setDestinationAccountName, setDestinationAccountId, setResetEnable, setupdateSummaries, setResetEntitySentencesSeeContextData, updateIsDisable, setUpdateEntitySentencesDataLoading, updateIsEditedSummaryReport, setScopeUpdated, updateReportSummaryData, setOpenPopup, setSubmitEnable, setTabSwitchPopUpEnable, setIsSystemGeneratedReports } = EditProjectSlice.actions;
export default EditProjectSlice.reducer;