import { useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { getEntityMaster, getEntitySentencesData, getEntitySentencesSeeContextData } from "../../redux/EditProject/action";
import { setResetEntitySentencesSeeContextData, setScopeUpdated } from "../../redux/EditProject/EditProjectReducer";
import { MRT_ColumnDef, MRT_RowVirtualizer, MRT_SortingState, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Autocomplete, Button, IconButton, Popover, TextField } from "@mui/material";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Spinner from "../../components/Spinner";


export default function EntitySentences({ setEntitySentenceWarning, setResetSortFilter, resetSortFilter, handleResetEnable }: any) {

    const heightRef = useRef<any>(null);
    const [height, setHeight] = useState<number>(0);
    const [width, setWidth] = useState<number>(1);

    useEffect(() => {
        const TopPos = heightRef.current?.getBoundingClientRect().top;
        const LeftPos = heightRef.current?.getBoundingClientRect().left;
        setHeight(window.innerHeight - TopPos - 60);
        setWidth(window.innerWidth - LeftPos - 15);
    });

    const dispatch = useAppDispatch();
    const projectId = useParams().id;
    const entitySentencesData = useSelector((state: RootState) => state.editProject.entitySentencesData)
    const entitySentencesDataLoading = useSelector((state: RootState) => state.editProject.entitySentencesDataLoading)
    const [data, setData] = useState<any>([])
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);
    const [deleteAllValue, setDeleteAllValue] = useState<any>(false)


    const [currentDataState, setCurrentDataState] = useState<any>([])

    useEffect(() => {
        setEntitySentenceWarning(currentDataState)
        var enable = false
        currentDataState.map((item: any) => {
            if (item?.is_deleted) {
                enable = true
            }
            else if (item?.change_sentence_anchor) {
                enable = true
            }
        })
        handleResetEnable(enable)
    }, [currentDataState])

    useEffect(() => {
        table.resetSorting(true)
        table.resetColumnFilters(true)
    }, [resetSortFilter])

    useEffect(() => {
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);

    useEffect(() => {
        dispatch(getEntitySentencesData({ project_id: projectId }))
        dispatch(getEntityMaster({ project_id: projectId }))
        handleResetEnable(false)
        dispatch(setScopeUpdated(false))
    }, [])

    useEffect(() => {
        if (Array.isArray(entitySentencesData)) {
            setData([])
            const tempEntitySentencesData = entitySentencesData.map((item, index) => (
                { ...item, sl_no: index + 1, is_deleted: false }
            ))
            setData([...tempEntitySentencesData])
            setCurrentDataState([...entitySentencesData])
            handleResetEnable(false)
            dispatch(setScopeUpdated(false))
            // this refreshes the UI to show checkbox properly
            table.getCoreRowModel().rows?.map((item: any) => {
                item._valuesCache['is_deleted'] = null
            })
        }
    }, [entitySentencesData])

    function SentenceCol({ cell, column, row, table }: any) {
        return (
            <div className="w-full h-full overflow-y-auto">
                {cell.getValue()}
            </div>
        )
    }

    function SeeContext({ cell, column, row, table }: any) {

        const entitySentencesSeeContextData = useSelector((state: RootState) => state.editProject.entitySentencesSeeContextData)
        const onClick = (e: any) => {
            dispatch(getEntitySentencesSeeContextData({ project_id: projectId, question_id: cell.getValue() }))
            setAnchorEl(e.currentTarget);
        }

        const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

        const handleClose = () => {
            setAnchorEl(null);
            dispatch(setResetEntitySentencesSeeContextData([]))
        };

        const open = Boolean(anchorEl);

        return (
            <div className="w-full h-full flex justify-center items-center">
                <Button variant="text" className="text-blue-400 text-xs" sx={{ textTransform: "lowercase", padding: "2px" }} onClick={onClick}>see context</Button>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {entitySentencesSeeContextData.length == 0
                        ?
                        <div className="h-full w-[500px] flex justify-center items-center">
                            <Spinner height={20} />
                        </div>
                        :
                        <div className="p-3 w-[500px] max-h-[400px] overflow-y-auto">
                            <div className="head w-full flex justify-between items-start">
                                <div className="text-base font-semibold">{entitySentencesSeeContextData[0]?.question_type_identifier} : {entitySentencesSeeContextData[0]?.question_text}</div>
                                <IconButton className="scale-90" style={{ padding: "2px" }} onClick={handleClose}><CancelRoundedIcon /></IconButton>
                            </div>
                            <div className="flex flex-col justify-start items-start mx-4">
                                {entitySentencesSeeContextData[0]?.responses.map((resp: any, i: number) => {
                                    return (
                                        <div>
                                            <span className="font-semibold">{resp?.response_type_identifier} : </span>
                                            <span>{resp?.response_text}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                </Popover>
            </div>
        )
    }

    function DeleteRow({ cell, column, row, table, currentDataState, setCurrentDataState }: any) {
        const [value, setValue] = useState(cell.getValue())

        const onChange = (e: any) => {
            const currVal = value ? false : true
            setValue(currVal)
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "is_deleted": currVal }
                return temp
            })
            row._valuesCache[column.id] = currVal
            handleResetEnable(true)
            dispatch(setScopeUpdated(true))
        }

        return (
            <div className="w-full flex justify-center items-center">
                <input type="checkbox" className="h-5 w-5 accent-[#9747FF] transition-all cursor-pointer" checked={value === true} onClick={onChange} />
            </div>
        )
    }

    function ChangeSentenceAnchor({ cell, column, row, table, currentDataState, setCurrentDataState }: any) {

        const entityMasterData = useSelector((state: RootState) => state.editProject.entityMasterData)

        // const dropDownData = Array.from(new Set([...table.options.data?.map((item: any) => item?.['anchor'])])).filter((item: any) => item !== row.original['anchor'])
        const [dropDownData, setDropdownData] = useState<any>([])
        useEffect(() => {
            let data: any = entityMasterData.map((item: any) => item.anchor).filter((arch: any) => arch !== row.original?.anchor)
            setDropdownData([...data])
        }, [entityMasterData])
        const [value, setValue] = useState<any>(cell.getValue())

        const onChange = (newValue: any) => {
            const currVal = newValue
            setValue(currVal)
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "change_sentence_anchor": currVal }
                return temp
            })
            row._valuesCache[column.id] = currVal
            handleResetEnable(true)
            dispatch(setScopeUpdated(true))
        }

        function OptionElement({ params }: any) {
            var ref = useRef<any>()
            const [visible, setVisible] = useState(false)
            const isVisible = useOnScreen(ref)

            useEffect(() => {
                if (isVisible) {
                    setVisible(true)
                }
            })

            return (
                <div {...params} ref={ref} className="p-2 border-b h-[40px] flex items-center cursor-pointer hover:bg-slate-100 transition-all">
                    {visible && <div className="truncate">{params.key}</div>}
                </div>
            )
        }

        return (
            <div className="w-full hover:bg-white transition-all">
                <Autocomplete
                    value={value}
                    onChange={(event: any, newValue: string | null) => {
                        onChange(newValue);
                    }}
                    className="w-full"
                    options={dropDownData}
                    autoHighlight
                    getOptionLabel={(option: any) => option}
                    renderOption={(params) => <OptionElement params={params} />}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Select Entity"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'black',
                                },
                            }}
                        />
                    )}
                />
            </div>
        )
    }

    function DeleteAll({ table, deleteAllValue, setDeleteAllValue }: any) {

        const onChangeValue = (row: any, value: any) => {
            const currVal = value ? false : true
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[row?.index] = { ...temp[row?.index], "is_deleted": currVal }
                return temp
            })
            row._valuesCache['is_deleted'] = currVal
            handleResetEnable(true)
            dispatch(setScopeUpdated(true))
        }

        const onClickDeleteAll = () => {
            setDeleteAllValue(!deleteAllValue)
            table.getRowModel().rows?.map((row: any) => {
                onChangeValue(row, deleteAllValue)
            })
        }

        useEffect(() => {
            var temp = table.getRowModel().rows?.every((row: any) => row._valuesCache['is_deleted'] === true)
            setDeleteAllValue(temp)
        }, [table])

        return (
            <div className="w-full flex justify-end">
                <div className="h-[35px] flex items-center gap-5 border rounded p-2 cursor-pointer" onClick={onClickDeleteAll}>
                    <div className="">Delete All </div>
                    <input type="checkbox" checked={deleteAllValue} className="h-5 w-5 accent-[#9747FF] transition-all cursor-pointer" />
                </div>
            </div>
        )
    }

    function MergeToColFilter({ column, columnName, table }: any) {

        const [dropDownData, setDropDownData] = useState<any>([])
        const [fullDropDownData, setFullDropDownData] = useState<any>([]);
        const [value, setValue] = useState<any>(column?.getFilterValue() || '')
        const [open, setOpen] = useState(false)

        const onChange = (newValue: any) => {
            const currVal = newValue
            setValue(currVal)
            column.setFilterValue(currVal)

            if (newValue === null || newValue === '' || newValue === undefined) {
                // Reset to full list when filter is cleared
                setDropDownData(fullDropDownData);
            }
        }

        useEffect(() => {
        if (deleteAllValue) {
            setDropDownData([true]);
        } else {
            const allValues = Array.from(new Set(table?.options.data?.map((item: any) => item?.[`${columnName}`])?.filter((item: any) => item !== undefined && item !== null && item !== '')));
            setFullDropDownData(allValues);  
            setDropDownData(allValues); 
        }
    }, [table]);

        const onFocus = () => {
            if (!deleteAllValue) {
                setDropDownData(fullDropDownData);
            }
            setOpen(true)
        }

        function OptionElement({ params }: any) {
            var ref = useRef<any>()
            const [visible, setVisible] = useState(false)
            const isVisible = useOnScreen(ref)

            useEffect(() => {
                if (isVisible) {
                    setVisible(true)
                }
            })

            return (
                <div {...params} ref={ref} className="p-2 border-b h-[40px] flex items-center cursor-pointer hover:bg-slate-100 transition-all">
                    {visible && <div className="truncate">{params.key}</div>}
                </div>
            )
        }

        return (
            <div className="w-full mt-1 hover:bg-white transition-all">
                <Autocomplete
                    open={open}
                    disabled={columnName === 'question_id'}
                    value={value}
                    onChange={(event: any, newValue: string | null) => {
                        onChange(newValue);
                    }}
                    onFocus={onFocus}
                    onBlur={() => setOpen(false)}
                    className="w-full"
                    options={dropDownData}
                    autoHighlight
                    getOptionLabel={(option: any) => option}
                    renderOption={(params) => <OptionElement params={params} />}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onChange={(event: any) => {
                                onChange(event.target.value);
                            }}
                            placeholder={`Select ${column.columnDef.header}`}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                                onKeyDown: (e) => {
                                    if (e.key === 'Enter') {
                                        setOpen(false)
                                        e.stopPropagation();
                                    }
                                },
                            }}
                            variant="standard"
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'black',
                                },
                            }}
                        />
                    )}
                />
            </div>
        )
    }

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "sl_no",
                header: "Sl.No",
                size: 120,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'sl_no'} table={table} />
                ),
            },
            {
                accessorKey: 'type',
                header: 'Entity Type',
                size: 150,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'type'} table={table} />
                ),
            },
            {
                accessorKey: 'anchor',
                header: 'Entity Anchor',
                size: 150,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'anchor'} table={table} />
                ),
            },
            {
                accessorKey: 'entity_equivalent',
                header: 'Entity Equivalent',
                size: 150,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'entity_equivalent'} table={table} />
                ),
            },
            {
                accessorKey: 'sentence',
                header: 'Sentence',
                size: 750,
                filterFn: 'contains',
                Cell: SentenceCol,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'sentence'} table={table} />
                ),
            },
            {
                accessorKey: 'question_id',
                header: 'See Context',
                size: 150,
                filterFn: 'contains',
                Cell: SeeContext,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'question_id'} table={table} />
                ),
            },
            {
                accessorKey: 'sentence_sentiment',
                header: 'Sentence Sentiment',
                size: 150,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'sentence_sentiment'} table={table} />
                ),
            },
            {
                accessorKey: 'is_deleted',
                header: 'Delete',
                size: 110,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <DeleteRow cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'is_deleted'} table={table} />
                ),
            },
            {
                accessorKey: 'change_sentence_anchor',
                header: 'Change sentence anchor',
                size: 300,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <ChangeSentenceAnchor cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'change_sentence_anchor'} table={table} />
                ),
            },
        ], [deleteAllValue]
    )

    const table = useMaterialReactTable({
        columns,
        data,
        defaultDisplayColumn: { enableResizing: true },
        enableBottomToolbar: false,
        enableColumnResizing: true,
        enableColumnVirtualization: true,
        enableGlobalFilterModes: true,
        enablePagination: false,
        enableColumnPinning: true,
        // enableRowNumbers: true,
        enableRowVirtualization: true,
        initialState: { sorting },
        rowVirtualizerInstanceRef,
        renderTopToolbarCustomActions: ({ table }) => <DeleteAll table={table} deleteAllValue={deleteAllValue} setDeleteAllValue={setDeleteAllValue} />,
        rowVirtualizerOptions: { overscan: 1 },
        columnVirtualizerOptions: { overscan: 2 },
        muiTableContainerProps: {
            sx: {
                maxHeight: height - 50,
                overflowY: 'auto',
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#CECDCD',
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#F5F7FB',
                },
            },
        },
        muiTableProps: {
            sx: {
                borderCollapse: 'collapse',
                '& td': {
                    border: '1px solid #e2e8f0',
                    padding: '5px',
                    height: '75px',
                    fontSize: '1rem',
                    fontFamily: "'Poppins'"
                },
                '& th': {
                    fontSize: "1rem",
                    fontFamily: "'Poppins'"
                },
                // '& th': {
                //     backgroundColor: '#e2e8f0',
                // },
            },
        },
        muiTableHeadCellProps: {
            sx: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        },
        globalFilterFn: 'contains',
    })

    useEffect(() => {
        var count = 0
        table.getRowModel().rows?.map((row: any) => {
            if (row._valuesCache['is_deleted']) {
                count = count + 1;
            }
        })

        if (count === table.getRowModel().rows.length) {
            setDeleteAllValue(true)
        } else {
            setDeleteAllValue(false)
        }
    }, [table.getRowModel().rows])

    return (
        <div
            className=""
            ref={heightRef}
            style={{ height: height, width: width }}
        >
            {entitySentencesDataLoading ? (
                <div className="flex justify-center items-center overflow-hidden w-full h-full rounded bg-white">
                    <Spinner height={40} />
                </div>
            ) : data.length > 0 ? (
                <MaterialReactTable table={table} />
            ) : (
                <div className="flex justify-center items-center overflow-hidden w-full h-full rounded bg-white text-lg">
                    No data available
                </div>
            )}
        </div>
    );
}

function useOnScreen(ref: any) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                setIntersecting(entry.isIntersecting);
            }),
        []
    );

    useEffect(() => {
        const currentRef = ref.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [observer, ref]);

    return isIntersecting;
}