import React from 'react';
import 'tailwindcss/tailwind.css';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../redux/store';
import { resetPassword } from '../../redux/Profile/action';
import { useSelector } from 'react-redux';

interface IMailSentProps {
  email: string;
  // handleGoback:any
}

function MailSent({ email, }: IMailSentProps) {
  const dispatch = useAppDispatch()
  const userDetails: any = useSelector((state: RootState) => state.profile.userInfo)

  const navigate = useNavigate()

  const handleCancel = () => {
    navigate('/profile')
  };
  const handleResendMail = () => {
    if (userDetails?.USERNAME) {
      dispatch(resetPassword({
        email: userDetails?.USERNAME,
      }))
    }

  }

  return (
    <div className='h-full'>
      <div className="m-8 pl-16 bg-white mr-10 h-full rounded-xl pr-16 shadow-lg" style={{ height: '90%' }}>
        <h1 className="mt-14 pt-16 text-4xl font-semibold pb-10">Link sent!</h1>
        <h3 className="text-sm pb-4 italic text-gray-600 font-semibold">
          Check email : {userDetails?.USERNAME}
          <b>{email}</b>
        </h3>
        <h3 className="text-sm pb-4 font-semibold">You should have received a note from us based on the email address you have entered earlier. Open the link in the mail to verify and activate your EZY account.​</h3>
        <h3 className="text-sm inline font-semibold">Didn’t receive the link?</h3>
        <button className="text-sm inline ml-2 text-custom-blue font-medium text-lg cursor-pointer" onClick={handleResendMail}>Resend link</button>
        <div className="flex justify-between w-1/3 h-12 mt-7 mb-10">
          <button className="bg-white lg:w-48 md:w-16 text-gray-500 text-sm rounded cursor-pointer border border-custom-blue" onClick={handleCancel}>
            Go Back
          </button>
        </div>

      </div>
    </div>
  );
}

export default MailSent;