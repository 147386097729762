import { createSlice } from "@reduxjs/toolkit";

interface ICheckout {
  checkout: { selectedUseCase: string; selectedPackage: string }[];
}

const initialState: ICheckout = {
  checkout: [],
};
export const checkoutSlice = createSlice({
  name: "checkout",
  initialState: initialState,
  reducers: {
    addCheckout: (state, action) => {
      state.checkout = state.checkout.filter(
        (item) => item.selectedUseCase !== action.payload.selectedUseCase && item.selectedPackage === action.payload.selectedPackage
      );
      state.checkout.push(action.payload)
    },
    removeCheckout: (state, action) => {
      state.checkout = state.checkout.filter(
        (item) => item.selectedPackage !== action.payload.selectedPackage
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { addCheckout, removeCheckout } = checkoutSlice.actions;
export default checkoutSlice.reducer;
