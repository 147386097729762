import { useEffect, useRef, useState } from "react"
import { updateCurrentPage } from "../../../redux/currentPage"
import { useAppDispatch } from "../../../redux/store"
import { Link, useLocation } from "react-router-dom"
import { ChevronDown, Search, Triangle, Trash2, PlusCircle } from "react-feather"
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { ReceiptLongOutlined } from "@mui/icons-material";
import Modal from '@mui/material/Modal';
import BasicSwitch from "../../../components/BasicSwitch"

export default function GuideTour() {
    // NOTE: this is a static page

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(updateCurrentPage("Transcripts"))
    }, [])

    const leftRef = useRef<any>(null);
    const [leftHeight, setLeftHeight] = useState<number>(0);
    const rightRef = useRef<any>(null);
    const [rightHeight, setRightHeight] = useState<number>(0);

    useEffect(() => {
        const LTopPos = leftRef.current?.getBoundingClientRect().top;
        const RTopPos = rightRef.current?.getBoundingClientRect().top;
        setLeftHeight(window.innerHeight - LTopPos - 70);
        setRightHeight(window.innerHeight - RTopPos - 70);
    });

    const { pathname } = useLocation();
    const id = pathname.split("/")[2]
    const part = pathname.split("/")[3]

    return (
        <div className="relative z-0">
            <div className="flex justify-between items-center">
                <div className="flex justify-start items-center text-lg font-semibold p-1">
                    Overview on how to manage transcripts bucketing
                </div>
                <div className="flex justify-between items-center">
                    <div className="rounded-lg shadow-md bg-gray-400 text-white p-3 flex justify-center items-center w-[10vw] cursor-pointer mr-3">Quick Tour</div>
                    <Link to={`/qbqc/${id}`} >
                        <div className="rounded-lg shadow-md bg-[#9747FF] text-white p-3 flex justify-center items-center w-[10vw] cursor-pointer">Got it!</div>
                    </Link>
                </div>
            </div>
            <div className="w-full grid grid-cols-8 gap-4 h-full">
                <div className="col-span-2 pt-1">
                    <div className="flex justify-start items-center text-lg font-semibold p-1">
                        Analysis Topics
                    </div>
                    {/* this is analysis topics content */}
                    <div className="main w-full bg-white rounded-lg flex flex-col justify-start gap-2 shadow-md" ref={leftRef} style={{ height: leftHeight }}>
                        <div className="flex justify-between items-center gap-2 p-3 border-b-2 border-b-gray-300">
                            <span className="flex justify-between items-center w-full px-2 py-2 rounded-md bg-gray-200">Ungrouped</span>
                        </div>
                        <div className="flex justify-start items-center gap-2 p-3 border-b-2 border-b-gray-300" style={{ height: "50px" }}>
                            <Search size={18} />
                            <div className="text-[#A3A3A3]">Search Analysis Topics</div>
                        </div>
                        <div className="overflow-y-auto" style={{ height: "88%" }}>
                            <div className="flex justify-between items-center w-full rounded-md gap-2 bg-blue-100 pl-4">
                                <div className="w-3/4 py-2">Attributes in choosing snacks</div>
                                <div className="flex justify-evenly w-1/4">
                                    <ModeEditOutlineOutlinedIcon className="text-gray-600" />
                                    <Trash2 size={19} className="text-gray-600" />
                                </div>
                            </div>
                            <div className="flex justify-between items-center w-full rounded-md gap-2 pl-4">
                                <div className="w-3/4 py-2">Concerns about snacking</div>
                                <div className="flex justify-evenly w-1/4">
                                    <ModeEditOutlineOutlinedIcon className="text-gray-600" />
                                    <Trash2 size={19} className="text-gray-600" />
                                </div>
                            </div>
                            <div className="flex justify-between items-center w-full rounded-md gap-2 pl-4">
                                <div className="w-3/4 py-2">Preferred brands</div>
                                <div className="flex justify-evenly w-1/4">
                                    <ModeEditOutlineOutlinedIcon className="text-gray-600" />
                                    <Trash2 size={19} className="text-gray-600" />
                                </div>
                            </div>
                            <div className="flex justify-between items-center w-full rounded-md gap-2 pl-4">
                                <div className="w-3/4 py-2">Snack purchasing channels</div>
                                <div className="flex justify-evenly w-1/4">
                                    <ModeEditOutlineOutlinedIcon className="text-gray-600" />
                                    <Trash2 size={19} className="text-gray-600" />
                                </div>
                            </div>
                            <div className="flex justify-between items-center w-full rounded-md gap-2 pl-4">
                                <div className="w-3/4 py-2">Snacking occasions</div>
                                <div className="flex justify-evenly w-1/4">
                                    <ModeEditOutlineOutlinedIcon className="text-gray-600" />
                                    <Trash2 size={19} className="text-gray-600" />
                                </div>
                            </div>
                            <div className="">
                                <div className="border-b-2 border-[#9747FF] ml-4 w-72 text-[#A3A3A3] py-2">Add more...</div>
                                <span className="float-right text-xs mr-3 mt-1 font-semibold text-gray-500">
                                    Press Enter to create analysis topics
                                </span>
                            </div>
                        </div>
                        <div className="flex justify-between items-center gap-2 p-3 border-t-2">
                            <span className="flex justify-between items-center w-full px-2 py-2 rounded-md bg-red-200">Excluded</span>
                        </div>
                    </div>

                </div>
                <div className="col-span-6 pt-3">
                    <div className="flex justify-start items-center px-3 pt-1 pb-2">
                        <div className="flex justify-between items-center w-full">
                            <div className="flex justify-start items-center  text-lg font-semibold">
                                <span>Transcripts Preview</span>
                                <div className="ml-4 flex items-center justify-between p-2 border border-gray-300 rounded bg-white text-black" style={{ fontSize: "14px" }}>
                                    <span className="w-48 font-normal">All Selected</span>
                                    <ChevronDown className="ml-2 text-gray-700" style={{ width: '16px', height: '16px' }} />
                                </div>
                            </div>
                            <div className="flex justify-end items-center mr-2 gap-1">

                                <div className={`${part === 'tour5' ? "text-transparent" : ""}`} style={{ width: '150px', display: 'flex' }}>
                                    {part !== 'tour5' && <BasicSwitch />}
                                    <span className="font-medium pt-2">Expand All</span>
                                </div>
                                <div className="ml-4 flex items-center justify-between p-2 border border-gray-300 rounded bg-white text-black" style={{ fontSize: "14px" }}>
                                    <div className="flex justify-between items-center text-[#A3A3A3]">
                                        <>
                                            <Search className="w-5 h-5 mx-1" />
                                            <span className="w-48 font-normal mx-1" style={{ fontSize: "14px" }}>Search Transcript</span>
                                        </>
                                        <>
                                            <ExpandLessIcon />
                                            <KeyboardArrowDownIcon />
                                        </>
                                    </div>
                                </div>
                                <span className="ml-2">prev</span>
                                <Triangle
                                    fill="black"
                                    size={18}
                                    color="black"
                                />
                                <Triangle
                                    fill="black"
                                    size={18}
                                    className="rotate-180"
                                />
                                <span>next</span>
                                {/* <div className="p-2 w-[5vw] mx-2"></div> */}
                                {/* <div className="rounded-lg bg-[#9747FF] text-white p-2 flex justify-center items-center w-[5vw] text-sm mx-2">RESET</div> */}
                                <div className="rounded-lg bg-[#9747FF] text-white p-2 flex justify-center items-center w-[5vw] text-sm">PROCESS</div>
                            </div>
                        </div>
                    </div>
                    {/* this is transcripts content */}
                    <div className="bg-white w-full rounded-lg overflow-y-auto text-left shadow-md px-1 pt-2" ref={rightRef} style={{ height: rightHeight }}>
                        <div className="flex justify-start items-center">
                            <ReceiptLongOutlined />
                            <span className="w-[10px] h-[10px] rounded-full inline-block ml-[10px] bg-[#BDD7EE]"></span>
                            <span className="text-left ml-2 font-bold w-full pr-2">Snacking_Focus group 1.mp3</span>
                        </div>
                        <hr className="border-b-2 border-[#9747FF] w-full mt-2" />
                        <div className="mr-1">
                            {/* <div className="font-bold px-3"></div>
                            <div className="px-3 ml-8"></div> */}
                            <div className="font-bold px-3 pt-1"> <span className="bg-[#BDD7EE]"> M : Before we go on to the next question, is there a reason why you prefer chips some days, or is it just what you're craving on that day?</span></div>
                            <div className="px-3 ml-8"> <span className="bg-[#BDD7EE]"> R : I think it's just like what I'm craving, and if I'm like, oh, I ate really unhealthy the past couple of days, I'm like, okay, now I actually have to be healthy and eat a lot of fruit. Yeah, that's kind of what it depends on. </span></div>

                            <div className="font-bold px-3"> <span className="bg-[#BDD7EE]"> M : No, that makes sense. So, are there some must-have things you have to see in a snack before deciding whether to buy them?</span></div>
                            <div className="px-3 ml-8"> <span className="bg-[#BDD7EE]"> R : Again, if I'm trying to be healthy, I'll just look at the ingredients and be like, okay, this sounds like a pretty good snack to eat. And then I'll get that.</span></div>

                            <div className="font-bold px-3"> <span className="bg-[#BDD7EE]"> M : Awesome. And the final question is, from this category, is there anything else that comes to your mind that you feel is important when you buy a snack?</span></div>
                            <div className="px-3 ml-8"> <span className="bg-[#BDD7EE]"> R : Not really. Nothing that I can think of. Nothing like anything in particular.</span></div>

                            {part === 'tour4' && <div className="h-[30px]"></div>}

                            <div className="font-bold px-3">M : Okay, so we'll move on to our third category. We have concerns about snacking habits. We'll start off with you. Do you have friends or family who are anti-snackers, say, people who dislike snacks or are opposed to them? And what might be some of their reasons for being anti-snackers?</div>
                            <div className="px-3 ml-8">R : Well, my dad loves snacking. I'm pretty sure he was the one who got me into these spicy snacks because he would share with me. But my mom is not a big fan of it because she thinks that if I eat snacks before dinner, I won't eat my actual dinner and it won't be good for me. So that's one of the reasons why my mom doesn't let me snack.</div>

                            <div className="font-bold px-3">M : That's actually kind of funny. Like, my parents are the exact same. My dad loves eating snacks, and my mom's kind of like the stricter person. So moving on to the second question. Do you relate to some of the reasons that, I guess, your mom gives you for being this anti-snacker?</div>
                            <div className="px-3 ml-8">R : Do I relate? No.</div>

                            <div className="font-bold px-3">M : You don't agree with her about it will ruin your appetite?</div>
                            <div className="px-3 ml-8">R : No, because I feel like I can eat dinner and the snack at the same time. Yeah, not really.</div>

                            <div className="font-bold px-3">M : Interesting. And final question, do you end up snacking less than you want to because something is playing on your mind? If so, what makes you stop from binging on snacks?</div>

                        </div>
                    </div>

                </div>
            </div>

            {/* popup */}
            <div className="absolute inset-0">
                <Modal open={true} className="transition-none" >
                    <div className="w-full h-full">
                        {part === 'tour1' &&
                            <div className="pl-[90px] pr-[50px] pt-[465px]">
                                <div className="main w-1/4 bg-white rounded-lg flex flex-col justify-start gap-2 shadow-md" >
                                    <div className="rounded-lg border-2 border-[#9747FF] m-1 relative ">
                                        <div className="flex flex-col">
                                            <div className="border-b-2 border-[#9747FF] ml-3 w-72 text-[#A3A3A3] py-2">Add more...</div>
                                            <div className="self-end text-xs mr-2 mt-1 font-semibold text-gray-500">
                                                Press Enter to create analysis topics
                                            </div>
                                        </div>
                                        <div className="absolute inset-y-0 right-0 flex items-center">
                                            <div className="fixed flex items-center">
                                                <div className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="-rotate-90 h-[25px] w-[25px] -mr-[1px]" viewBox="0 0 100 100">
                                                        <polygon points="50,50 100,100 0,100" fill="white" />
                                                    </svg>
                                                </div>
                                                <div className="bg-[#FFFFFF] p-5 rounded-lg relative">
                                                    <div className="absolute top-1 right-1">
                                                        <Link to={`/qbqc-guide/${id}/home`}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 fill-gray-300 cursor-pointer">
                                                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                    <div className="mt-3">You may add a new Analysis Topic.</div>
                                                    <div className="mt-3 flex justify-center items-center text-sm">
                                                        <Link to={`/qbqc-guide/${id}/home`} className="rounded-lg shadow-md bg-gray-200 text-[#9747FF] w-1/3 p-2 flex justify-center items-center cursor-pointer mr-12">
                                                            <div className="">Prev</div>
                                                        </Link>
                                                        <Link to={`/qbqc-guide/${id}/tour2`} className="rounded-lg shadow-md bg-[#9747FF] w-1/3 text-white p-2 flex justify-center items-center cursor-pointer">
                                                            <div className="">Next</div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {part === 'tour2' &&
                            <div className="pl-[90px] pr-[50px] pt-[430px]">
                                <div className="w-1/4 bg-white rounded-lg flex flex-col justify-start gap-2 shadow-md" >
                                    <div className="rounded-lg border-2 border-[#9747FF] m-1 relative">
                                        <div className="flex justify-between items-center w-full rounded-md gap-2 pl-3">
                                            <div className="w-3/4 py-2">Snacking occasions</div>
                                            <div className="flex justify-evenly w-1/4 -mr-1">
                                                <ModeEditOutlineOutlinedIcon className="text-gray-600" />
                                                <Trash2 size={19} className="text-gray-600" />
                                            </div>
                                        </div>
                                        <div className="absolute inset-y-0 right-0 flex items-center">
                                            <div className="fixed flex items-center">
                                                <div className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="-rotate-90 h-[25px] w-[25px] -mr-[1px]" viewBox="0 0 100 100">
                                                        <polygon points="50,50 100,100 0,100" fill="white" />
                                                    </svg>
                                                </div>
                                                <div className="bg-[#FFFFFF] p-5 rounded-lg relative">
                                                    <div className="absolute top-1 right-1">
                                                        <Link to={`/qbqc-guide/${id}/home`}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 fill-gray-300 cursor-pointer">
                                                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                    <div className="mt-3">Edit/Delete an existing one.</div>
                                                    <div className="mt-3 flex justify-center items-center text-sm">
                                                        <Link to={`/qbqc-guide/${id}/tour1`} className="rounded-lg shadow-md bg-gray-200 text-[#9747FF] w-1/3 p-2 flex justify-center items-center cursor-pointer mr-12">
                                                            <div className="">Prev</div>
                                                        </Link>
                                                        <Link to={`/qbqc-guide/${id}/tour3`} className="rounded-lg shadow-md bg-[#9747FF] w-1/3 text-white p-2 flex justify-center items-center cursor-pointer">
                                                            <div className="">Next</div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {part === 'tour3' &&
                            <div className="pl-[90px] pr-[10px] pt-[240px] flex">
                                <div className="w-1/4"></div>
                                <div className="bg-white rounded-lg w-3/4 ml-2 relative">
                                    <div className="rounded-lg border-2 border-[#9747FF] m-1">
                                        <div className="w-[95%]">
                                            <div className="font-bold px-2 pt-1"> <span className="bg-[#BDD7EE]"> M : Before we go on to the next question, is there a reason why you prefer chips some days, or is it just what you're craving on that day?</span></div>
                                            <div className="px-2 ml-7"> <span className="bg-[#BDD7EE]"> R : I think it's just like what I'm craving, and if I'm like, oh, I ate really unhealthy the past couple of days, I'm like, okay, now I actually have to be healthy and eat a lot of fruit. Yeah, that's kind of what it depends on. </span></div>

                                            <div className="font-bold px-2"> <span className="bg-[#BDD7EE]"> M : No, that makes sense. So, are there some must-have things you have to see in a snack before deciding whether to buy them?</span></div>
                                            <div className="px-2 ml-7"> <span className="bg-[#BDD7EE]"> R : Again, if I'm trying to be healthy, I'll just look at the ingredients and be like, okay, this sounds like a pretty good snack to eat. And then I'll get that.</span></div>

                                            <div className="font-bold px-2"> <span className="bg-[#BDD7EE]"> M : Awesome. And the final question is, from this category, is there anything else that comes to your mind that you feel is important when you buy a snack?</span></div>
                                            <div className="px-2 ml-7"> <span className="bg-[#BDD7EE]"> R : Not really. Nothing that I can think of. Nothing like anything in particular.</span></div>
                                        </div>
                                    </div>
                                    <div className="absolute right-[30%] top-[95%] w-[40%] flex items-center">
                                        <div className=" flex flex-col items-center">
                                            <div className="">
                                                <svg xmlns="http://www.w3.org/2000/svg" className=" h-[25px] w-[25px] -mt-[1px]" viewBox="0 0 100 100">
                                                    <polygon points="50,50 100,100 0,100" fill="white" />
                                                </svg>
                                            </div>
                                            <div className="bg-[#FFFFFF] p-5 rounded-lg relative">
                                                <div className="absolute top-1 right-1">
                                                    <Link to={`/qbqc-guide/${id}/home`}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 fill-gray-300 cursor-pointer">
                                                            <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                                                        </svg>
                                                    </Link>
                                                </div>
                                                <div className="mt-3">Highlighted sections indicate that part of the transcript belongs to the selected bucket from the left pane</div>
                                                <div className="mt-3 flex justify-center items-center text-sm">
                                                    <Link to={`/qbqc-guide/${id}/tour2`} className="rounded-lg shadow-md bg-gray-200 text-[#9747FF] w-1/3 p-2 flex justify-center items-center cursor-pointer mr-12">
                                                        <div className="">Prev</div>
                                                    </Link>
                                                    <Link to={`/qbqc-guide/${id}/tour4`} className="rounded-lg shadow-md bg-[#9747FF] w-1/3 text-white p-2 flex justify-center items-center cursor-pointer">
                                                        <div className="">Next</div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {part === 'tour4' &&
                            <div className="pl-[90px] pr-[10px] pt-[245px] flex">
                                <div className="w-1/4"></div>
                                <div className="rounded-lg w-3/4 ml-3 mr-2">
                                    <div className="font-bold px-3 pt-1 mr-1 cursor-default opacity-0"> <span className="bg-[#BDD7EE]"> M : Before we go on to the next question, is there a reason why you prefer chips some days, or is it just what you're craving on that day?</span></div>
                                    <div className="px-3 ml-8 cursor-default opacity-0"> <span className="bg-[#BDD7EE]"> R : I think it's just like what I'm craving, and if I'm like, oh, I ate really unhealthy the past couple of days, I'm like, okay, now I actually have to be healthy and eat a lot of fruit. Yeah, that's kind of what it depends on. </span></div>

                                    <div className="font-bold px-3 cursor-default opacity-0"> <span className="bg-[#BDD7EE]"> M : No, that makes sense. So, are there some must-have things you have to see in a snack before deciding whether to buy them?</span></div>
                                    <div className="px-3 ml-8 cursor-default opacity-0"> <span className="bg-[#BDD7EE]"> R : Again, if I'm trying to be healthy, I'll just look at the ingredients and be like, okay, this sounds like a pretty good snack to eat. And then I'll get that.</span></div>

                                    <div className="font-bold px-3 cursor-default opacity-0"> <span className="bg-[#BDD7EE]"> M : Awesome. And the final question is, from this category, is there anything else that comes to your mind that you feel is important when you buy a snack?</span></div>
                                    <div className="px-3 ml-8 flex opacity-0"> <span className="bg-[#BDD7EE] cursor-default"> R : Not really. Nothing that I can think of. Nothing like anything in particular.</span></div>
                                    <div className="w-fit mt-2 ml-8">
                                        <div className="-mt-2 bg-white p-1 rounded-lg relative">
                                            <div className="flex items-center justify-between border-2 border-[#9747FF] rounded-lg p-0.5 w-[60px] ">
                                                <PlusCircle
                                                    fill="lightgreen"
                                                    color="white"
                                                    className=""
                                                />
                                                <div
                                                    className="rounded-full bg-red-400 px-2 text-white flex justify-center items-center"
                                                    style={{
                                                        fontSize: "12px",
                                                        width: "19px",
                                                        height: "19px",
                                                    }}
                                                >
                                                    E
                                                </div>
                                            </div>

                                            <div className="absolute -left-[200%] top-[95%] flex items-center">
                                                <div className="flex flex-col items-center">
                                                    <div className="">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className=" h-[25px] w-[25px] -mt-[1px]" viewBox="0 0 100 100">
                                                            <polygon points="50,50 100,100 0,100" fill="white" />
                                                        </svg>
                                                    </div>
                                                    <div className="bg-[#FFFFFF] p-5 rounded-lg relative">
                                                        <div className="absolute top-1 right-1">
                                                            <Link to={`/qbqc-guide/${id}/home`}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 fill-gray-300 cursor-pointer">
                                                                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                                                                </svg>
                                                            </Link>
                                                        </div>
                                                        <div className="mt-3 w-[300px]">Multiselect the sections you wish to include/exclude from the bucket</div>
                                                        <div className="mt-3 flex justify-center items-center text-sm">
                                                            <Link to={`/qbqc-guide/${id}/tour3`} className="rounded-lg shadow-md bg-gray-200 text-[#9747FF] w-1/3 p-2 flex justify-center items-center cursor-pointer mr-12">
                                                                <div className="">Prev</div>
                                                            </Link>
                                                            <Link to={`/qbqc-guide/${id}/tour5`} className="rounded-lg shadow-md bg-[#9747FF] w-1/3 text-white p-2 flex justify-center items-center cursor-pointer">
                                                                <div className="">Next</div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {part === 'tour5' &&
                            <div className="w-full h-full flex justify-center items-start">
                                <div className="mt-[153px] ml-[14.5vw] pr-[25px] flex items-center">
                                    <div className="rounded-lg bg-white p-1 relative">
                                        <div className="border-2 border-[#9747FF] rounded-lg py-1">
                                            <div className="w-[150px] flex" >
                                                <BasicSwitch />
                                                <span className="font-medium pt-2">Expand All</span>
                                            </div>
                                        </div>
                                        <div className="absolute right-0 top-[95%] flex items-center">
                                            <div className=" flex flex-col items-end">
                                                <div className="mr-[45px]">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className=" h-[25px] w-[25px] -mt-[1px]" viewBox="0 0 100 100">
                                                        <polygon points="50,50 100,100 0,100" fill="white" />
                                                    </svg>
                                                </div>
                                                <div className="bg-[#FFFFFF] p-5 rounded-lg relative">
                                                    <div className="absolute top-1 right-1">
                                                        <Link to={`/qbqc-guide/${id}/home`}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 fill-gray-300 cursor-pointer">
                                                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                    <div className="mt-3 w-[300px]">Toggle to see only mapped texts for selected Analysis Topic.</div>
                                                    <div className="mt-3 flex justify-center items-center text-sm">
                                                        <Link to={`/qbqc-guide/${id}/tour4`} className="rounded-lg shadow-md bg-gray-200 text-[#9747FF] w-1/3 p-2 flex justify-center items-center cursor-pointer mr-12">
                                                            <div className="">Prev</div>
                                                        </Link>
                                                        <Link to={`/qbqc-guide/${id}/tour7`} className="rounded-lg shadow-md bg-[#9747FF] w-1/3 text-white p-2 flex justify-center items-center cursor-pointer">
                                                            <div className="">Next</div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                        {/* {part === 'tour6' &&
                            <div className="w-full h-full flex justify-end items-start">
                                <div className="mt-[153px] mr-[5vw] pr-[25px] flex items-center">
                                    <div className="rounded-lg bg-white p-1 relative">
                                        <div className="border-2 border-[#9747FF] rounded-lg py-1">
                                            <div className="rounded-lg bg-[#9747FF] text-white p-2 flex justify-center items-center w-[5vw] text-sm mx-2">RESET</div>
                                        </div>

                                        <div className="absolute right-0 top-[95%] flex items-center">
                                            <div className=" flex flex-col items-end">
                                                <div className="mr-[45px]">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className=" h-[25px] w-[25px] -mt-[1px]" viewBox="0 0 100 100">
                                                        <polygon points="50,50 100,100 0,100" fill="white" />
                                                    </svg>
                                                </div>
                                                <div className="bg-[#FFFFFF] p-5 rounded-lg relative">
                                                    <div className="absolute top-1 right-1">
                                                        <Link to={`/qbqc-guide/${id}/home`}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 fill-gray-300 cursor-pointer">
                                                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                    <div className="mt-3 w-[300px]">Reset if you wish to discard the changes and revert to original state before any edits were made.</div>
                                                    <div className="mt-3 flex justify-center items-center text-sm">
                                                        <Link to={`/qbqc-guide/${id}/tour5`} className="rounded-lg shadow-md bg-gray-200 text-[#9747FF] w-1/3 p-2 flex justify-center items-center cursor-pointer mr-12">
                                                            <div className="">Prev</div>
                                                        </Link>
                                                        <Link to={`/qbqc-guide/${id}/tour7`} className="rounded-lg shadow-md bg-[#9747FF] w-1/3 text-white p-2 flex justify-center items-center cursor-pointer">
                                                            <div className="">Next</div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        } */}
                        {part === 'tour7' &&
                            <div className="w-full h-full flex justify-end items-start">
                                <div className="mt-[153px] pr-[15px] flex items-center">
                                    <div className="rounded-lg bg-white p-1 relative">
                                        <div className="border-2 border-[#9747FF] rounded-lg py-1">
                                            <div className="rounded-lg bg-[#9747FF] text-white p-2 flex justify-center items-center w-[5vw] text-sm mx-2">PROCESS</div>
                                        </div>

                                        <div className="absolute right-0 top-[95%] flex items-center">
                                            <div className=" flex flex-col items-end">
                                                <div className="mr-[45px]">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className=" h-[25px] w-[25px] -mt-[1px]" viewBox="0 0 100 100">
                                                        <polygon points="50,50 100,100 0,100" fill="white" />
                                                    </svg>
                                                </div>
                                                <div className="bg-[#FFFFFF] p-5 rounded-lg relative">
                                                    <div className="absolute top-1 right-1">
                                                        <Link to={`/qbqc-guide/${id}/home`}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 fill-gray-300 cursor-pointer">
                                                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                    <div className="mt-3 w-[500px]">On Process Submission the transcripts are re-calculated and updated for your Review. The processing may take around  40 mins to 1 hour for 8 transcripts approximately. And user will be notified once project is available for review by mail notification.</div>
                                                    <div className="mt-3 flex justify-center items-center text-sm">
                                                        <Link to={`/qbqc-guide/${id}/tour5`} className="rounded-lg shadow-md bg-gray-200 text-[#9747FF] w-1/3 p-2 flex justify-center items-center cursor-pointer mr-12">
                                                            <div className="">Prev</div>
                                                        </Link>
                                                        <Link to={`/qbqc-guide/${id}/home`} className="rounded-lg shadow-md bg-[#9747FF] w-1/3 text-white p-2 flex justify-center items-center cursor-pointer">
                                                            <div className="">End Tour</div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal>
            </div>

        </div>
    )
}
