import React from 'react'
import LayoutForCompareWordCloud from '../../components/LayoutForCompareWordCloud/LayoutForCompareWordCloud'

const WordCloud = () => {
  return (
    <div>
      <LayoutForCompareWordCloud />
    </div>
  )
}

export default WordCloud