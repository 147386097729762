import { createAsyncThunk } from "@reduxjs/toolkit";
import { MasterAPI } from "../../service/api/masterData";
import { openSnackbar } from "../Notification/notificationReducer";

export const getQuestionBucket = createAsyncThunk("getQuestionBucket", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.getQuestionBucket({ project_id })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getAssociations = createAsyncThunk("getAssociations", async ({ project_id, selectedQbs, sentiment, attributes }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.getAssociations({ project_id, selectedQbs, sentiment, attributes })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getConfigurePPt = createAsyncThunk("getConfigurePPt", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.configurePPt({ project_id })
        return { response: response.data }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getConfigureProfiles = createAsyncThunk("getConfigureProfiles", async ({ project_id, limit, offset }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.configureProfiles({ project_id, limit, offset })
        return { response: response.data }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const savePptConfiguration = createAsyncThunk("savePptConfiguration", async ({ projectId, qbConfig }: any, { dispatch, rejectWithValue }) => {
    try {
        dispatch(openSnackbar({ message: "Saving configuration ..." }))
        const response = await MasterAPI.savePptConfiguration({ projectId, qbConfig })
        if (response.data.status) {
            dispatch(openSnackbar({ message: "Configuration saved", type: "success" }))
            return { response: response.data }
        }
        else {
            dispatch(openSnackbar({ message: "Something went wrong when saving the configuration.", type: "error" }))
            throw rejectWithValue(response.data)
        }

    }
    catch (error: any) {
        dispatch(openSnackbar({ message: "Something went wrong when saving the configuration.", type: "error" }))
        throw rejectWithValue(error.data)
    }
})


export const getDeepDiveConfiguration = createAsyncThunk("getDeepDiveConfiguration", async ({ projectId, profileIds }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.getDeepDiveConfiguration({ projectId, profileIds })
        if (response.data.status) {
            return { response: response.data }
        }
        else {
            throw rejectWithValue(response.data)
        }

    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const saveDeepDiveConfiguration = createAsyncThunk("saveDeepDiveConfiguration", async ({ projectId, profileIds, deepDiveConfig }: any, { dispatch, rejectWithValue }) => {
    try {
        dispatch(openSnackbar({ message: "Saving configuration ..." }))
        const response = await MasterAPI.saveDeepDiveConfiguration({ projectId, profileIds, deepDiveConfig })
        if (response.data.status) {
            dispatch(openSnackbar({ message: "Configuration saved", type: "success" }))
            return { response: response.data }
        }
        else {
            dispatch(openSnackbar({ message: "Something went wrong when saving the configuration.", type: "error" }))
            throw rejectWithValue(response.data)
        }

    }
    catch (error: any) {
        dispatch(openSnackbar({ message: "Something went wrong when saving the configuration.", type: "error" }))
        throw rejectWithValue(error.data)
    }
})


export const addConfigureProfile = createAsyncThunk("addConfigureProfile", async ({ project_id, profileData }: any, { rejectWithValue }) => {
    // try{
    const response = await MasterAPI.createConfigureProfille({ project_id, profileData })
    if (response.data.status) {
        return { response: response.data }
    } else {
        throw rejectWithValue(response.data)
    }
    // }
    // catch(error:any){
    //     throw rejectWithValue(error.data)
    // }
})

export const deleteProfile = createAsyncThunk("deleteProfile", async ({ project_id, profile_id }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.deleteConfigureProfile({ project_id, profile_id })
        return { response: response.data }
    } catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const updateConfigureProfile = createAsyncThunk("updateConfigureProfile", async ({ project_id, profile_id, updatedData }: any, { rejectWithValue }) => {

    const response = await MasterAPI.editConfigureProfile({ project_id, profile_id, updatedData })
    const jsonString = new TextDecoder().decode(response.data);
    const jsonObject = JSON.parse(jsonString);
    if (jsonObject.status) {
        return { response: jsonObject }
    } else {
        throw rejectWithValue(jsonObject.data);
    }
    // }
    // catch(error:any){
    //     throw rejectWithValue(error.data)
    // }
})

export const updatePPTSelectionValue = createAsyncThunk("updatePPTSelectionValue", async ({ project_id, selectionData }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.updatePPtSelectionValue({ project_id, selectionData })
        return { response: response.data }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getMentions = createAsyncThunk("getMentions", async ({ project_id, selectedQbs, sentiment, attributes, associations }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.getMentions({ project_id, selectedQbs, sentiment, attributes, associations })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})



export const getAttributes = createAsyncThunk("getAttributes", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.getAllAttributes({ project_id })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const combineQuestionBuc = createAsyncThunk("combineQuestionBuc", async ({ project_id, question_bucket_ids, display_name }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.combineQuestionBuc({ project_id, question_bucket_ids, display_name })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const hideUnhideQB = createAsyncThunk("hideUnhideQB", async ({ project_id, question_bucket_ids, hide }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.hideUnhideQB({ project_id, question_bucket_ids, hide })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const hideUnhidePhrase = createAsyncThunk("hideUnhidePhrase", async ({ project_id, phrase_ids, hide }: any, { dispatch, rejectWithValue }) => {
    try {
        const response = await MasterAPI.hideUnhidePhrase({ project_id, phrase_ids, hide })
        if (response.data.status) {
            return { response: response.data }
        }
        else {
            dispatch(openSnackbar({ message: 'Something went wrong.' }))
            throw rejectWithValue(response.data)
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const editQuestionBuc = createAsyncThunk("editQuestionBuc", async ({ project_id, exclude_question_bucket_ids, include_question_bucket_ids, new_display_name }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.editQuestionBuc({ project_id, exclude_question_bucket_ids, include_question_bucket_ids, new_display_name })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const renameAssociation = createAsyncThunk("renameAssociation", async ({ project_id, display_name, new_display_name }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.renameAssociation({ project_id, display_name, new_display_name })
        return {
            response: response.data,
            display_name: display_name,
            new_display_name: new_display_name
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getQBPhrases = createAsyncThunk("getQBPhrases", async ({ project_id, selectedQbs, sentiment, attributes, limit, offset, associations, mentions, search_phrase, search_sentence }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.getQBPhrases({ project_id, selectedQbs, sentiment, attributes, limit, offset, associations, mentions, search_phrase, search_sentence })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const addQuestionBucket = createAsyncThunk("addQuestionBucket", async ({ project_id, question_bucket_name }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.addQuestionBucket({ project_id, question_bucket_name })
        return {
            response: response.data,
            project_id: project_id,
            question_bucket_name: question_bucket_name

        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getExportState = createAsyncThunk("getExportState", async ({ projectId }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.getExportState({ projectId })
        return {
            response: response.data,
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const triggerExportData = createAsyncThunk("triggerExportData", async ({ projectId, reportingBucketsIds, themeIds, entityIds, reportName, reportDescription, templateId, templateType }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.triggerExportData({ projectId, reportingBucketsIds, themeIds, entityIds, reportName, reportDescription, templateId, templateType });
        return {
            response: response.data,
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getExportPptReportsData = createAsyncThunk("getexportPptReportsData", async ({ projectId }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.getexportPptReportsData({ projectId });
        return {
            response: response.data,
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const resetData = createAsyncThunk("resetData", async ({ projectId }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.resetData({ projectId });
        return {
            response: response.data,
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const backupData = createAsyncThunk("backupData", async ({ projectId }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.backupData({ projectId });
        return {
            response: response.data,
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getMasterWordCloudData = createAsyncThunk("wordCloudData", async ({ project_id, selectedQbs, sentiment, attributes }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.masterWordCloudData({ project_id, selectedQbs, sentiment, attributes });
        return {
            response: response.data,
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getFeatures = createAsyncThunk("features", async ({ }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.getFeatures();
        return {
            response: response.data,
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getNotes = createAsyncThunk("getNotes", async ({ projectId, page }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.getNotes({ projectId, page });
        return {
            response: response.data,
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const postNotes = createAsyncThunk("postNotes", async ({ projectId, payload, page }: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.postNotes({ projectId, payload, page });
        return {
            response: response.data,
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const userBookingSlot = createAsyncThunk("userBookingSlot", async (bookingData: any, { rejectWithValue }) => {
    try {
        const response = await MasterAPI.bookingSlot(bookingData);
        return {
            response: response.data,
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getPptTemplates = createAsyncThunk("getPptTemplates", async (projectId: any, { rejectWithValue }) => {
    try {
        const pptTemplatesResponse = await MasterAPI.getPptTemplates(projectId);
        const defaultTemplateResponse = { data: { data: pptTemplatesResponse.data.data[0] } }
        return {
            pptTemplatesResponse: pptTemplatesResponse.data,
            defaultTemplateResponse: defaultTemplateResponse.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})