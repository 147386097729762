import { sortBy } from "lodash";
import { toast } from "react-toastify";

export function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export function getFooterOffset() {
    return document.getElementById('footer')?.offsetHeight;
}

export enum ToastEnum {
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
}

export const showToast = (text: string, type: string) => {
    toast.dismiss();

    switch (type) {
        case 'error':
            return toast.error(text, {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                className: 'react-toast-overwrite'
            });

        case 'info':
            return toast.info(text, {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                className: 'react-toast-overwrite'
            });

        case 'success':
            return toast.success(text, {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                className: 'react-toast-overwrite'
            });
    }

}

export const getPeriodTitle = (periodkey: string) => {
    switch (periodkey) {
        case "P1M":
            return "1 Month";

        case "P3M":
            return "3 Months";

        case "P6M":
            return "6 Months";

        case "P9M":
            return "9 Months";

        case "P12M":
            return "12 Months";
    }
}

export const roundTwoDec = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

export const getFirstLetterCaps = (item: string) => {
    return item?.toUpperCase().split('')[0];
}

export const createAndDownloadBlob = (response: any, contentDisposition: any) => {
    let fname = '';
    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = fileNameRegex.exec(contentDisposition);

    if (matches != null && matches[1]) {
        fname = matches[1].replace(/['"]/g, '');
    }

    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    document.body.appendChild(link);
    link.href = url;
    link.download = fname;
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export const sortByKey = (arr: any[], key: string, namekey: string, type: string) => {
    let sorted = [];
    if (type === "asc") {
        sorted = arr.sort((a: any, b: any) => {
            if (Math.abs(a[key]) === Math.abs(b[key])) {
                return a[namekey] > b[namekey] ? 1 : -1
            }

            else {
                return Math.abs(a[key]) > Math.abs(b[key]) ? 1 : -1
            }

        }
        )
    }

    else if (type === "desc") {
        sorted = arr.sort((a: any, b: any) => {
            if (Math.abs(a[key]) === Math.abs(b[key])) {
                return a[namekey] > b[namekey] ? -1 : 1
            }

            else {
                return Math.abs(a[key]) > Math.abs(b[key]) ? -1 : 1
            }
        }
        )
    }

    return sorted;
}

export const range = (start: number, stop: number, step: number) => Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
);

export const checkAlpha = (word: string) => {
    return !/[^a-zA-Z ]/.test(word);
}

export const validateEmail = (email: string) => {
    return String(email)?.toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const getNameFromId = (arr: any[], keyToFind: string, matchWithId: number, returnNameKey: string) => {
    const foundItem = arr?.find((item: any) => item[keyToFind] === matchWithId);
    return foundItem ? foundItem[returnNameKey] : null;
}

export const getItemFromId = (arr: any[], keyToFind: string, matchWithId: number) => {
    return arr?.find((item: any) => item[keyToFind] === matchWithId);
}


export const brandSort = (data: any, column: string) => {
    return sortBy(data, column);
}


export const findFeature = (data: Array<string>, feature: string) => {
    return feature === data?.find((data: string) => { return data === feature })
}


export function parseString(input: string): string[] {
    const result: string[] = [];
    let currentString = '';
    let insideQuotes = false;

    for (let i = 0; i < input.length; i++) {
        const char = input[i];

        if (char === '"') {
            insideQuotes = !insideQuotes;
        } else if (char === ',' && !insideQuotes) {
            let currentValue = currentString.replaceAll("\"", "").trim()
            if (currentValue !== '') {
                result.push(currentValue);
            }
            currentString = '';
        } else {
            currentString += char;
        }
    }

    let currentValue = currentString.replaceAll("\"", "").trim()

    if (currentValue !== '') {
        result.push(currentValue);
    }

    let uniqueList: any = []
    result.map((item: any) => {
        if (!uniqueList.some((uniqueItem: any) => uniqueItem?.toLowerCase() === item?.toLowerCase())) {
            uniqueList.push(item)
        }
    })

    return uniqueList;
}

export function getQCNavigationLinks(qc_key: string) {
    switch (qc_key) {
        case "INTELLIGENT_CORRECTION_QC":
            return "/createProject/intelligent_correction_qc"
        case "ANALYSIS_TOPIC_QC":
            return "/createProject/reporting_buckets_qc"
        case "ENTITIES_QC":
            return "/createProject/entities_qc"
        case "THEMES_QC":
            return "/createProject/themes_qc"
        default:
            return ""
    }
}