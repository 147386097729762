import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILLMData } from "./types";
import { ChatPopulation, ChatQuery, ChatStart, ChatStatus } from "./action";

const initialState: ILLMData = {
  StatusData: [],
  sessionId: "",
  QueryResponse: [],
  populated: false,
  QueryError: false,
  QueryErrorMessage: "",
};

export const LLMDataSlice = createSlice({
  name: "LLMData",
  initialState: initialState,
  reducers: {
    updateQueryResponse: (state, action) => {
      state.QueryResponse = [...state.QueryResponse, action.payload];
    },
    updateResponse: (state, action) => {
      const { payload } = action;

      // Check if state.QueryResponse exists and has at least one element
      if (!state.QueryResponse || state.QueryResponse.length === 0) {
        return state;
      }

      // Get the index of the last item in the QueryResponse array
      const lastSessionIndex = state.QueryResponse.length - 1;

      // Update the response field of the last item with the new payload
      const updatedQueryResponse = [...state.QueryResponse];
      const currentResponse = updatedQueryResponse[lastSessionIndex].response || '';
      const newIndex = currentResponse.length;
      const newResponse = currentResponse + payload[newIndex];
      updatedQueryResponse[lastSessionIndex] = {
        ...updatedQueryResponse[lastSessionIndex],
        response: newResponse
      };

      return { ...state, QueryResponse: updatedQueryResponse };
    },


    updateError: (state, action) => {
      const { payload } = action;
      if (!state.QueryResponse || state.QueryResponse.length === 0) {
        return state;
      }
      const lastSessionIndex = state.QueryResponse.length - 1;
      if (lastSessionIndex >= 0) {
        state.QueryResponse[lastSessionIndex].response = payload;
        state.QueryResponse[lastSessionIndex].error = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ChatStatus.pending, (state: ILLMData) => {
        state.QueryResponse = [];
      })
      .addCase(
        ChatStatus.fulfilled,
        (state: ILLMData, action: PayloadAction<any>) => {
          state.StatusData = action.payload.response;
        }
      )
      .addCase(ChatStatus.rejected, (state: ILLMData) => { });
    builder
      .addCase(ChatStart.pending, (state: ILLMData) => { })
      .addCase(
        ChatStart.fulfilled,
        (state: ILLMData, action: PayloadAction<any>) => {
          state.sessionId = action.payload.response.session_id;
        }
      )
      .addCase(ChatStart.rejected, (state: ILLMData) => { });
    builder
      .addCase(ChatQuery.pending, (state: ILLMData) => {
        state.QueryError = false;
      })
      .addCase(
        ChatQuery.fulfilled,
        (state: ILLMData, action: PayloadAction<any>) => {
          state.QueryError = false;
          const response = action.payload.response;

          // Updating the last session in state.QueryResponse
          const lastSessionIndex = state.QueryResponse.length - 1;
          if (lastSessionIndex >= 0) {
            state.QueryResponse[lastSessionIndex].response = response
              .split("\n")
              .map((line: any) => line.replace("data: ", ""))
              .join(" ");
          }
        }
      )

      .addCase(ChatQuery.rejected, (state: ILLMData, { error }) => {
        const lastSessionIndex = state.QueryResponse.length - 1;
        if (lastSessionIndex >= 0) {
          state.QueryResponse[lastSessionIndex].session_id = state.sessionId;
          state.QueryResponse[lastSessionIndex].response = error.message;
          state.QueryResponse[lastSessionIndex].error = true;
        }
      });

    builder
      .addCase(ChatPopulation.pending, (state: ILLMData) => { })
      .addCase(
        ChatPopulation.fulfilled,
        (state: ILLMData, action: PayloadAction<any>) => {
          state.populated = true;
        }
      )
      .addCase(ChatPopulation.rejected, (state: ILLMData, { meta }) => { });
  },
});
export const { updateQueryResponse, updateResponse, updateError } =
  LLMDataSlice.actions;
export default LLMDataSlice.reducer;
