import { createAsyncThunk } from "@reduxjs/toolkit";
import { configAPI } from "../../service/api/configAPI";
import { VireGroupAPI } from "../../service/api/viewGroup";

export const getALLGroups = createAsyncThunk("getALLGroups", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await VireGroupAPI.getALLGroups({ project_id })
        return { response: response.data }
    } catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getGroupQuestionAndAnswers = createAsyncThunk("getGroupQuestionAndAnswers", async ({ project_id, group_ids, offset, limit, keyword }: any, { rejectWithValue }) => {
    try {
        const response = await VireGroupAPI.getGroupQuestionAndAnswers({ project_id, group_ids, offset, limit, keyword })
        return { response: response.data }
    } catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const combineQuestionBuckets = createAsyncThunk("combineQuestionBuckets", async ({ project_id, question_bucket_name, question_bucket_id }: any, { rejectWithValue }) => {
    try {
        const response = await VireGroupAPI.combineQuestionBuckets({ project_id, question_bucket_name, question_bucket_id })
        return { response: response.data }
    } catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const deleteQuestionBuckets = createAsyncThunk("deleteQuestionBuckets", async ({ project_id, question_bucket_id, display_names }: any, { rejectWithValue }) => {
    try {
        const response = await VireGroupAPI.deleteQuestionBuckets({ project_id, question_bucket_id })
        return {
            response: response.data,
            question_bucket_id: question_bucket_id,
            display_names: display_names
        }
    } catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const excludeQuestionAns = createAsyncThunk("excludeQuestionAns", async ({ project_id, question_ids, exclude }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.makeQuestionsExclude({ project_id, question_ids, exclude })
        return {
            response: response.data,
            project_id: project_id,
            question_ids: question_ids
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const excludeAllUngrouped = createAsyncThunk("excludeAllUngrouped", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await VireGroupAPI.excludeAllUngrouped({ project_id })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const makeUngroupedBuckets = createAsyncThunk("makeUngroupedBuckets", async ({ project_id }: any, { rejectWithValue }) => {
    try {
        const response = await VireGroupAPI.makeUngroupedBuckets({ project_id })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})

export const getViewGrpQbs = createAsyncThunk("getViewGrpQbs", async ({ project_id, offset, limit }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.getConfigQbs({ project_id, offset, limit })
        return {
            response: response.data
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})
export const vgAddQBucket = createAsyncThunk("vgAddQBucket", async ({ project_id, question_bucket_name }: any, { rejectWithValue }) => {
    try {
        const response = await configAPI.addQuestionBucket({ project_id, question_bucket_name })
        return {
            response: response.data,
            question_bucket_name: question_bucket_name
        }
    }
    catch (error: any) {
        throw rejectWithValue(error.data)
    }
})