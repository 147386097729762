import ResetPassword from "../../components/ResetPassword/ResetPassword"
import { axiosconfig } from "../apiconfig"

export const profileAPI = {
    login: ({ username, password }: any) => {
        return axiosconfig.post("/login",
            {
                username: username,
                password: password
            })
    },
    resetPassword: ({ email }: any) => {
        return axiosconfig.post("/resetPassword",
            {
                email: email
            })
    },
    setPassword: ({ password, reset_token }: any) => {
        return axiosconfig.post("/setPassword",
            {
                password: password,
                reset_token: reset_token

            })
    },
    verifyEmail: ({ password, reset_token }: any) => {
        return axiosconfig.post("/verifyEmail",
            {
                password: password,
                reset_token: reset_token

            })
    },
    signup: ({ Email,CountryCode, FirstName, LastName, DisplayName, CountryId, CompanyId, JobRoleId,PhoneNumber,Mode }: any) => {
        return axiosconfig.post("/signup",
            {
                Email,
                CountryCode,
                FirstName,
                LastName,
                DisplayName,
                CountryId,
                CompanyId,
                JobRoleId,
                PhoneNumber,
                Mode
            })
    },
    getJobRoles:() => {
        return axiosconfig.get(`/jobRoles`)
    },
    getCountries:() => {
        return axiosconfig.get(`/countries`)
    },
    getUsers:() => {
        return axiosconfig.get(`/users`)
    },
    addJobRoles: ({ jobRole }: any) => {
        return axiosconfig.post("/addJobRoles",
            {
                jobRole:jobRole
            })
    },
    addCountries: ({ country }: any) => {
        return axiosconfig.post("/addCountries",
            {
                country:country
            })
    },

    // getUser:(userName: string) => {
    //     return axiosconfig.get(`/user/profile`);
    // },
    updateName: ({userName, firstName, lastName} : any) => {
        return axiosconfig.put(`/user/${userName}`,
            {
                first_name: firstName,
                last_name: lastName
            })
    },  
    updatePhoneNumber:({userName, phoneNumber} : any) => {
        return axiosconfig.put(`user/phnnum/${userName}`,
            {
                phone_number : phoneNumber
            })
    },
    decodeToken: ({token}: any) => {
        return axiosconfig.post(`/decode_token?reset_token=${token}`, {})
    },
    logout: ({token}: any) => {
        return axiosconfig.post("/logout", {token})
    },
    refreshAccessToken: ({ token }: any) => {
        const encodedToken = encodeURIComponent(token);
        return axiosconfig.post(`/access_token?refresh_token=${encodedToken}`, {}, {
            responseType: 'json',
        });
    },
    
}