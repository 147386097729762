import { useEffect, useRef, useState, useMemo } from "react";
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
    type MRT_SortingState,
    type MRT_RowVirtualizer,
} from 'material-react-table';
import { Autocomplete, Button, IconButton, Popover, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { useLocation } from "react-router-dom";
import { getCEEnabledTabs, getEntitySentencesSeeContextData, getThemeMasterData, getThemeSentencesData, updateThemeSentenceData } from "../../redux/EditProject/action";
import Spinner from "../../components/Spinner";
import { setResetEntitySentencesSeeContextData, setScopeUpdated, updateIsDisable, setSubmitEnable } from "../../redux/EditProject/EditProjectReducer";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';


interface IThemeSentences {
    themeTab: any
    setWarningSentences: any
    setApplyChangesActive: any
    applyChangesClick: boolean
    setResetChanges: any
    resetClick: boolean
    setResetClick: any
    setApplyChangesClick: any
    setResetSortFilter: any
    resetSortFilter: any
}


function ThemeSentences(props: IThemeSentences) {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const projectId = pathname.includes('edit-project') ? pathname.split('/')[2] : pathname.split('/')[3]


    // const projectId = useParams().id;


    const themeSentencesData = useSelector((state: RootState) => state.editProject.themeSentencesData)
    const themeSentenceDataLoading = useSelector((state: RootState) => state.editProject.themeSentenceDataLoading)


    const [themesToDelete, setThemesToDelete] = useState<any>([])
    const [changeTheme, setChangeTheme] = useState<any>([])
    const [deleteAllValue, setDeleteAllValue] = useState<any>(false)



    useEffect(() => {
        table.resetSorting(true)
        table.resetColumnFilters(true)
    }, [props.resetSortFilter])



    const heightRef = useRef<any>(null);
    const [height, setHeight] = useState<number>(0);
    const [width, setWidth] = useState<number>(1);


    const [data, setData] = useState<any>([])
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);

    const [currentDataState, setCurrentDataState] = useState<any>([])


    useEffect(() => {
        dispatch(getThemeSentencesData({ project_id: projectId }))
        dispatch(getThemeMasterData({ project_id: projectId }))
    }, [])

    useEffect(() => {
        const TopPos = heightRef.current?.getBoundingClientRect().top;
        const LeftPos = heightRef.current?.getBoundingClientRect().left;
        setHeight(window.innerHeight - TopPos - 60);
        setWidth(window.innerWidth - LeftPos - 20);
    });

    useEffect(() => {
        props.setWarningSentences(currentDataState)
    }, [currentDataState])


    useEffect(() => {
        if (themesToDelete?.length === 0 && changeTheme?.length === 0) {
            props.setApplyChangesActive(false)
            props.setResetChanges(false)
            dispatch(setSubmitEnable(false))
        }
        else {
            dispatch(setScopeUpdated(true))
            props.setApplyChangesActive(true)
            props.setResetChanges(true)
            dispatch(setSubmitEnable(true))
        }

    }, [themesToDelete, changeTheme])

    useEffect(() => {
        let payload =
        {
            "list_of_themes_sentences_to_delete": themesToDelete,
            "theme_to_change_for_sentence": changeTheme
        }

        if (props.themeTab === 'Themes Sentences' && props.applyChangesClick === true) {
            dispatch(updateIsDisable(6));
            dispatch(updateThemeSentenceData({
                project_id: projectId,
                qc_step: pathname.includes('themes_qc'),
                payload: [payload]
            }))
                .then((res: any) => {
                    if (res.meta.requestStatus === 'fulfilled') {
                        setData([])
                        setCurrentDataState([])
                        setThemesToDelete([])
                        setChangeTheme([])
                        dispatch(getThemeSentencesData({ project_id: projectId }))
                        dispatch(getCEEnabledTabs({ project_id: projectId }))
                        props.setApplyChangesClick(false)
                    }
                })

        }
    }, [props.applyChangesClick])

    useEffect(() => {
        if (props.themeTab === "Themes Sentences" && props.resetClick) {
            setData([])
            setCurrentDataState([])
            setThemesToDelete([])
            setChangeTheme([])

            dispatch(getThemeSentencesData({ project_id: projectId }))
                .then((res: any) => {
                    if (res.meta.requestStatus === "fulfilled") {
                        props.setResetClick(false)

                    }
                })
        }
    }, [props.resetClick])






    useEffect(() => {
        if (Array.isArray(themeSentencesData)) {
            const sortedThemeSentencesData = themeSentencesData
                .slice()
                .sort((a, b) => a.theme_name.localeCompare(b.theme_name));

            const tempThemeSentencesData = sortedThemeSentencesData.map((item, index) => (
                { ...item, sl_no: index + 1, is_deleted: false }
            ))
            setData([...tempThemeSentencesData])
            setCurrentDataState([...sortedThemeSentencesData])
        }
    }, [themeSentencesData])





    useEffect(() => {
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);

    function SentenceCol({ cell, column, row, table }: any) {
        return (
            <div className="w-full h-full overflow-y-auto">
                {cell.getValue()}
            </div>
        )
    }

    function SeeContext({ cell, column, row, table }: any) {

        const entitySentencesSeeContextData = useSelector((state: RootState) => state.editProject.entitySentencesSeeContextData)
        const onClick = (e: any) => {
            dispatch(getEntitySentencesSeeContextData({ project_id: projectId, question_id: cell.getValue() }))
            setAnchorEl(e.currentTarget);
        }

        const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

        const handleClose = () => {
            setAnchorEl(null);
            dispatch(setResetEntitySentencesSeeContextData([]))
        };

        const open = Boolean(anchorEl);

        return (
            <div className="w-full h-full flex justify-center items-center">
                <Button variant="text" className="text-blue-400 text-xs" sx={{ textTransform: "lowercase", padding: "2px" }} onClick={onClick}>see context</Button>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {entitySentencesSeeContextData.length == 0
                        ?
                        <div className="h-full w-[500px] flex justify-center items-center">
                            <Spinner height={20} />
                        </div>
                        :
                        <div className="p-3 w-[500px] max-h-[400px] overflow-y-auto">
                            <div className="head w-full flex justify-between items-start">
                                <div className="text-base font-semibold">{entitySentencesSeeContextData[0]?.question_type_identifier} : {entitySentencesSeeContextData[0]?.question_text}</div>
                                <IconButton className="scale-90" style={{ padding: "2px" }} onClick={handleClose}><CancelRoundedIcon /></IconButton>
                            </div>
                            <div className="flex flex-col justify-start items-start mx-4">
                                {entitySentencesSeeContextData[0]?.responses.map((resp: any, i: number) => {
                                    return (
                                        <div>
                                            <span className="font-semibold">{resp?.response_type_identifier} : </span>
                                            <span>{resp?.response_text}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                </Popover>
            </div>
        )
    }

    function ChangeSentenceThemeType({ cell, column, row, table, currentDataState, setCurrentDataState }: any) {

        //const dropDownData = Array.from(new Set([...table.options.data?.map((item: any) => item['theme_name'])]?.filter((item: any) => item !== row.original['theme_name'])))
        const themeMasterData = useSelector((state: RootState) => state.editProject.themeMasterData)
        const [dropDownData, setDropDownData] = useState<Array<string>>([])

        const [value, setValue] = useState<any>(cell.getValue())

        useEffect(() => {
            let data: Array<string> = themeMasterData.map((item: any) => item.display_name).filter((thm: string) => thm !== row.original?.theme_name);
            setDropDownData(data)
        }, [themeMasterData])

        // useEffect(() => {
        //     if(themeSentenceDataLoading){
        //         row._valuesCache[column.id] = null
        //         setValue(null)
        //     }    
        // }, [themeSentencesData])

        const onChange = (newValue: any) => {
            var targetThemeId = ''
            const currVal = newValue
            if (newValue === null) {
                setValue(null)
                targetThemeId = ''
            } else {
                setValue(currVal)
                themeMasterData.map((item: any) => {
                    if (item['theme_name'] === currVal) {
                        targetThemeId = item['theme_id']
                    }
                })
            }

            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "change_theme_type": currVal }
                return temp
            })
            row._valuesCache[column.id] = currVal

            setChangeTheme((prevThemesToMergeThemes: any) => {
                let updatedMergeThemeList
                let themeId = row.original.theme_id;
                let sentenceId = row.original.sentence_id;

                const existingIndex = prevThemesToMergeThemes.findIndex(
                    (theme: any) => (theme.source_theme_id === themeId && theme.sentence_id === sentenceId)
                );
                if (existingIndex !== -1) {
                    if (currVal === null) {
                        updatedMergeThemeList = prevThemesToMergeThemes?.filter((item: any) => !(item.source_theme_id === themeId && item.sentence_id === sentenceId));
                        return updatedMergeThemeList;
                    } else {
                        updatedMergeThemeList = [...prevThemesToMergeThemes];
                        updatedMergeThemeList[existingIndex] = {
                            ...updatedMergeThemeList[existingIndex],
                            target_theme_id: targetThemeId
                        };
                        return updatedMergeThemeList;
                    }
                }

                else {
                    // If the themeId doesn't exist, add a new entry
                    return [
                        ...prevThemesToMergeThemes,
                        {
                            source_theme_id: themeId,
                            target_theme_id: targetThemeId,
                            sentence_id: sentenceId
                        }
                    ];
                }

            })

        }

        function OptionElement({ params }: any) {
            var ref = useRef<any>()
            const [visible, setVisible] = useState(false)
            const isVisible = useOnScreen(ref)

            useEffect(() => {
                if (isVisible) {
                    setVisible(true)
                }
            })


            return (
                <div {...params} ref={ref} className="p-2 border-b h-[40px] flex items-center cursor-pointer hover:bg-slate-100 transition-all">
                    {visible && <div className="truncate">{params.key}</div>}
                </div>
            )
        }

        return (
            <div className="w-full hover:bg-white transition-all">
                <Autocomplete
                    value={value}
                    onChange={(event: any, newValue: string | null) => {
                        onChange(newValue);
                    }}
                    className="w-full"
                    options={dropDownData}
                    autoHighlight
                    getOptionLabel={(option: any) => option}
                    renderOption={(params) => <OptionElement params={params} />}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Select Theme"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'black',
                                },
                            }}
                        />
                    )}
                />
            </div>
        )

        // const dropDownData = ['Positive', 'Negative', 'Overall']
        // // const [value, setValue] = useState(cell.getValue())
        // const [value, setValue] = useState('')


        // useEffect(() => {
        //     row._valuesCache[column.id] = null
        //     setValue('')
        // }, [themeSentencesData])


        // useEffect(() => {
        //     currentDataState.map((item: any) => {
        //         if (item.row === row.index && item.col === 'Change Sentence Theme') {
        //             setValue(item.val)
        //         }
        //     })
        // }, [])

        // const onChange = (e: any) => {
        //     const currVal = e.target.value
        //     setValue(currVal)
        //     const temp = { row: row.index, col: 'Change Sentence Theme', val: currVal }
        //     setCurrentDataState((prevState: any) => {
        //         let temp = [...prevState]
        //         temp[cell.row.index] = { ...temp[cell.row.index], "change_theme_type": (currVal === '' ? null : currVal) }
        //         return temp

        //         // const existingItemIndex = prevState.findIndex(
        //         //     (item: any) => item.row === temp.row && item.col === temp.col
        //         // );
        //         // if (existingItemIndex !== -1) {
        //         //     return [
        //         //         ...prevState.slice(0, existingItemIndex),
        //         //         temp,
        //         //         ...prevState.slice(existingItemIndex + 1),
        //         //     ];
        //         // }
        //         // return [...prevState, temp];
        //     })
        //     row._valuesCache[column.id] = currVal
        //     setThemesToUpdateSentiment((prevThemesToUpdateSenti:any)=>{
        //         let updatedSentimentThemeList
        //         let themeId=row.original.theme_id;

        //         const existingIndex = prevThemesToUpdateSenti.findIndex(
        //             (theme: any) => theme.theme_id === themeId
        //         );

        //         if (existingIndex !== -1){

        //         if (currVal !== "") {
        //             updatedSentimentThemeList = [...prevThemesToUpdateSenti];
        //             updatedSentimentThemeList[existingIndex] = {
        //                 ...updatedSentimentThemeList[existingIndex],
        //                 type: currVal
        //             };
        //         } else {
        //             updatedSentimentThemeList = prevThemesToUpdateSenti.filter((item: any) => item.theme_id !== themeId);
        //         } 
        //         return updatedSentimentThemeList;

        //     }

        //         else {
        //             // If the themeId doesn't exist, add a new entry
        //             return [
        //                 ...prevThemesToUpdateSenti,
        //                 {
        //                     theme_id: themeId,
        //                     type: currVal
        //                 }
        //             ];
        //         }

        //     })
        // }

        // return (
        //     <div className="w-full hover:bg-white transition-all ">
        //         <Select
        //             value={value as string}
        //             onChange={onChange}
        //             displayEmpty
        //             inputProps={{ 'aria-label': 'Without label' }}
        //             // className={`w-full h-10 text-white ${value === 'Positive' ? 'bg-[#27AE68]' : (value === 'Negative' ? 'bg-[#F03A5B]' : 'bg-[#338ACD]')}`}
        //             className={`w-full h-10 ${value === 'Positive' ? 'bg-green-300' : (value === 'Negative' ? 'bg-red-300' : (value === 'Overall') ? 'bg-blue-300' : 'bg-slate-50')}`}
        //             MenuProps={{
        //                 style: {
        //                     maxHeight: 400,
        //                 },
        //             }}
        //             sx={{
        //                 '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        //                     borderColor: 'black',
        //                 },
        //                 '.MuiSvgIcon-root ': {
        //                     fill: "black !important",
        //                 }
        //             }}
        //             renderValue={(selected) => {
        //                 if (selected === '') {
        //                     return 'Select Sentence Theme';
        //                 }
        //                 return selected
        //             }}
        //         >
        //              <MenuItem value={''} >None</MenuItem>
        //             {dropDownData?.map((item: any) => (
        //                 <MenuItem value={item} >{item}</MenuItem>
        //             ))}
        //         </Select>
        //     </div>
        // )
    }

    function DeleteRow({ cell, column, row, table, currentDataState, setCurrentDataState }: any) {
        const [value, setValue] = useState(cell.getValue())

        const onChange = (e: any) => {
            const currVal = value ? false : true
            setValue(currVal)
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[cell.row.index] = { ...temp[cell.row.index], "is_deleted": currVal }
                return temp
            })
            row._valuesCache[column.id] = currVal

            setThemesToDelete((prevThemesToDelete: any) => {
                let updatedList;
                const themeId = row.original.theme_id;
                const sentenceId = row.original.sentence_id

                const existingIndex = prevThemesToDelete?.findIndex(
                    (theme: any) => (theme.theme_id === themeId && theme.sentence_id === sentenceId)
                );

                if (existingIndex !== -1) {
                    if (currVal === false) {
                        updatedList = prevThemesToDelete?.filter((item: any) => !(item.theme_id === themeId && item.sentence_id === sentenceId));
                        return updatedList;
                    }

                }

                else {
                    // If the themeId doesn't exist, add a new entry
                    return [
                        ...prevThemesToDelete,
                        {
                            theme_id: themeId,
                            sentence_id: sentenceId
                        }
                    ];

                }

                return updatedList;
            });
        }

        return (
            <div className="w-full flex justify-center items-center">
                <input type="checkbox" className="h-5 w-5 accent-[#9747FF] transition-all cursor-pointer" checked={value === true} onClick={onChange} />
            </div>
        )
    }

    function DeleteAll({ table, deleteAllValue, setDeleteAllValue }: any) {

        const onChangeValue = (row: any, value: any) => {
            const currVal = value ? false : true
            setCurrentDataState((prevState: any) => {
                let temp = [...prevState]
                temp[row?.index] = { ...temp[row?.index], "is_deleted": currVal }
                return temp
            })
            row._valuesCache['is_deleted'] = currVal

            setThemesToDelete((prevThemesToDelete: any) => {
                let updatedList;
                const themeId = row?.original.theme_id;
                const sentenceId = row?.original.sentence_id

                const existingIndex = prevThemesToDelete?.findIndex(
                    (theme: any) => (theme.theme_id === themeId && theme.sentence_id === sentenceId)
                );

                if (existingIndex !== -1) {
                    if (currVal === false) {
                        updatedList = prevThemesToDelete?.filter((item: any) => !(item.theme_id === themeId && item.sentence_id === sentenceId));
                        return updatedList;
                    }

                }

                else {
                    // If the themeId doesn't exist, add a new entry
                    return [
                        ...prevThemesToDelete,
                        {
                            theme_id: themeId,
                            sentence_id: sentenceId
                        }
                    ];

                }

                return updatedList;
            });
        }

        const onClickDeleteAll = () => {
            setDeleteAllValue(!deleteAllValue)
            table.getRowModel().rows?.map((row: any) => {
                onChangeValue(row, deleteAllValue)
            })
        }

        useEffect(() => {
            var temp = table.getRowModel().rows?.every((row: any) => row._valuesCache['is_deleted'] === true)
            setDeleteAllValue(temp)
        }, [table])

        return (
            <div className="w-full flex justify-end">
                <div className="h-[35px] flex items-center gap-5 border rounded p-2 cursor-pointer" onClick={onClickDeleteAll}>
                    <div className="">Delete All </div>
                    <input type="checkbox" checked={deleteAllValue} className="h-5 w-5 accent-[#9747FF] transition-all cursor-pointer" />
                </div>
            </div>
        )
    }


    function MergeToColFilter({ column, columnName, table, deleteAllValue }: any) {

        const [dropDownData, setDropDownData] = useState<any>([])
        const [value, setValue] = useState<any>(column.getFilterValue())
        const [open, setOpen] = useState(false)

        useEffect(() => {
            if (deleteAllValue) {
                setDropDownData([true])
            }
        }, [deleteAllValue])

        const onChange = (newValue: any) => {
            const currVal = newValue
            setValue(currVal)
            column.setFilterValue(currVal)
        }

        useEffect(() => {
            if (deleteAllValue) {
                setDropDownData([true])
            } else {
                setDropDownData(Array.from(new Set(table?.getRowModel().rows?.map((item: any) => item?._valuesCache?.sl_no ? item?._valuesCache : item?.original)?.map((item: any) => item?.[`${columnName}`])?.filter((item: any) => item !== undefined && item !== null && item !== ''))))
            }
        }, [table])

        const onFocus = () => {
            setOpen(true)
        }

        function OptionElement({ params }: any) {
            var ref = useRef<any>()
            const [visible, setVisible] = useState(false)
            const isVisible = useOnScreen(ref)

            useEffect(() => {
                if (isVisible) {
                    setVisible(true)
                }
            })

            return (
                <div {...params} ref={ref} className="p-2 border-b h-[40px] flex items-center cursor-pointer hover:bg-slate-100 transition-all">
                    {visible && <div className="truncate">{params.key}</div>}
                </div>
            )
        }

        return (
            <div className="w-full mt-1 hover:bg-white transition-all">
                <Autocomplete
                    open={open}
                    disabled={columnName === 'question_id'}
                    value={value}
                    onChange={(event: any, newValue: string | null) => {
                        onChange(newValue);
                    }}
                    freeSolo
                    onFocus={onFocus}
                    onBlur={() => setOpen(false)}
                    className="w-full"
                    options={dropDownData}
                    autoHighlight
                    getOptionLabel={(option: any) => option}
                    renderOption={(params) => <OptionElement params={params} />}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onChange={(event: any) => {
                                onChange(event.target.value);
                            }}
                            placeholder={`Select ${column.columnDef.header}`}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                                onKeyDown: (e) => {
                                    if (e.key === 'Enter') {
                                        setOpen(false)
                                        e.stopPropagation();
                                    }
                                },
                            }}
                            variant="standard"
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'black',
                                },
                            }}
                        />
                    )}
                />
            </div>
        )
    }

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'sl_no',
                header: 'Sl.No',
                filterFn: 'contains',
                size: 100,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'sl_no'} table={table} />
                ),
            },
            {
                accessorKey: 'theme_name',
                header: 'Theme',
                filterFn: 'contains',
                size: 150,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'theme_name'} table={table} />
                ),
            },
            {
                accessorKey: 'theme_type',
                header: 'Theme Type',
                filterFn: 'contains',
                size: 150,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'theme_type'} table={table} />
                ),
            },
            {
                accessorKey: 'sentence',
                header: 'Sentence',
                size: 750,
                filterFn: 'contains',
                Cell: SentenceCol,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'sentence'} table={table} />
                ),
            },
            {
                accessorKey: 'sentence_sentiment',
                header: 'Sentence Sentiment',
                size: 150,
                filterFn: 'contains',
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'sentence_sentiment'} table={table} />
                ),
            },
            {
                accessorKey: 'is_deleted',
                header: 'Delete',
                size: 110,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <DeleteRow cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />,
                Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'is_deleted'} table={table} deleteAllValue={deleteAllValue} />
                ),
            },
            {
                accessorKey: 'question_id',
                header: 'See Context',
                size: 150,
                filterFn: 'contains',
                Cell: SeeContext, Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'question_id'} table={table} />
                ),
            },
            {
                accessorKey: 'Change Sentence Theme',
                header: 'Change Sentence Theme',
                size: 220,
                filterFn: 'contains',
                Cell: ({ cell, column, row, table }) => <ChangeSentenceThemeType cell={cell} column={column} row={row} table={table} currentDataState={currentDataState} setCurrentDataState={setCurrentDataState} />, Filter: ({ column }) => (
                    <MergeToColFilter column={column} columnName={'Change Sentence Theme'} table={table} />
                ),
            },
        ], [deleteAllValue]
    )

    const table = useMaterialReactTable({
        columns,
        data,
        defaultDisplayColumn: { enableResizing: true },
        enableBottomToolbar: false,
        enableColumnResizing: true,
        enableColumnVirtualization: true,
        enableGlobalFilterModes: true,
        enablePagination: false,
        enableColumnPinning: true,
        // enableRowNumbers: true,
        enableRowVirtualization: true,
        initialState: { sorting, },
        rowVirtualizerInstanceRef,
        renderTopToolbarCustomActions: ({ table }) => <DeleteAll table={table} deleteAllValue={deleteAllValue} setDeleteAllValue={setDeleteAllValue} />,
        rowVirtualizerOptions: { overscan: 1 },
        columnVirtualizerOptions: { overscan: 2 },
        muiTableContainerProps: {
            sx: {
                maxHeight: height - 50,
                overflowY: 'auto',
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#CECDCD',
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#F5F7FB',
                },
            },
        },
        muiTableProps: {
            sx: {
                borderCollapse: 'collapse',
                '& td': {
                    border: '1px solid #e2e8f0',
                    padding: '5px',
                    height: '75px',
                    fontSize: '1rem',
                    fontFamily: "'Poppins'"
                },
                '& th': {
                    fontSize: "1rem",
                    fontFamily: "'Poppins'"
                },
                // '& th': {
                //     backgroundColor: '#e2e8f0',
                // },
            },
        },
        muiTableHeadCellProps: {
            sx: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        },
        globalFilterFn: 'contains',
    });

    useEffect(() => {
        var count = 0
        table.getRowModel().rows?.map((row: any) => {
            if (row._valuesCache['is_deleted']) {
                count = count + 1;
            }
        })

        if (count === table.getRowModel().rows?.length) {
            setDeleteAllValue(true)
        } else {
            setDeleteAllValue(false)
        }
    }, [table.getRowModel().rows])

    return (
        <div className="mt-5 shadow-md mx-1" ref={heightRef} style={{ height: height, width: width }}>
            {themeSentenceDataLoading ?
                <div
                    className="flex justify-center items-center overflow-hidden"
                    style={{
                        backgroundColor: "white",
                        position: "absolute",
                        height: height,
                        width: width,
                        // height: "74%",
                        // width: "93.8%",
                        zIndex: 1000,
                    }}
                >
                    <Spinner height={40} />
                </div>
                :
                <>
                    {(data.length > 0) ? <MaterialReactTable table={table} /> : <div className="h-full w-full flex justify-center items-center bg-white">No data available</div>}
                </>
            }
        </div>
    )
}

function useOnScreen(ref: any) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                setIntersecting(entry.isIntersecting);
            }),
        []
    );

    useEffect(() => {
        const currentRef = ref.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [observer, ref]);

    return isIntersecting;
}

export default ThemeSentences