import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, ThemeProvider, createTheme } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { updateSelectedAttr } from "../../redux/Masters/masterDataReducer";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const theme = createTheme({
    palette: {
        primary: {
            main: "#9747FF"
        }
    },
});

export default function AttrMultiSelect() {

    const dispatch = useAppDispatch()
    const masterAttr = useSelector((state: RootState) => state.master.masterAttributes)
    const selectedAttrRedux = useSelector((state: RootState) => state.master.selectedAttr)

    const [selectedAttr, setSelectedAttr] = useState<Array<any>>([]);
    const [dropData, setDropData] = useState<Array<any>>([])
    const inputRef = useRef<any>(null)

    // useEffect(() => {
    //     // Initialize selectedAttr with all options if not already set
    //     let allOptions: any[] = [];
    //     Object.values(masterAttr).forEach((attr: any) => {
    //         attr.value.forEach((value: string) => {
    //             allOptions.push({ name: value });
    //         });
    //     });
    //     setSelectedAttr(allOptions);
    //     dispatch(updateSelectedAttr(allOptions));
    // }, [masterAttr]);

    function toggle(item: any) {
        if (!item[item.length - 1].isGroup) {
            let found = selectedAttr.filter((elem: any) => elem.name === item[item.length - 1]?.name).length > 0
            let data = [...selectedAttr]
            if (found) {
                data = data.filter((elem: any) => elem.name !== item[item.length - 1].name)
            }
            else {
                data.push(item[item.length - 1])
            }
            setSelectedAttr(data)
            dispatch(updateSelectedAttr(data))
        }
    }

    function returnData(dropData: any) {
        let newData: Array<any> = []
        dropData.map((group: any) => {
            newData.push({ isGroup: true, name: group.attribute_name, showAttr: group.showAttr })
            if (group.showAttr) {
                group.attribute_value.map((attr: any, i: number) => {
                    newData.push({
                        isGroup: false,
                        name: attr,
                        groupName: group.attribute_name,
                        showDivider: i === (group.attribute_value.length - 1),
                        attr_id: group.attribute_id
                    })
                })
            }
        })
        return newData
    }

    function updateShowAttr(name: string) {
        let updatedData = dropData.map((group: any) => {
            if (group.attribute_name === name) {
                return {
                    ...group,
                    showAttr: !group.showAttr
                }
            }
            else {
                return {
                    ...group
                }
            }
        })
        setDropData(updatedData)
    }

    // useEffect(() => {
    //     //clean up redux state after unloading the component
    //     return () => {
    //         dispatch(updateSelectedAttr(null))
    //     }
    // }, [])

    useEffect(() => {
        let data: any = []
        masterAttr !== undefined && (
            Object?.keys(masterAttr)?.map((item: any) => {
                data?.push({ attribute_name: masterAttr[item].name, attribute_value: masterAttr[item].value, showAttr: true, attribute_id: item })
            }))
        setDropData(data)
        if (!selectedAttrRedux) {
            if (returnData(data).length === 0) {
                setSelectedAttr(["Not defined"])
            } else {
                setSelectedAttr(returnData(data).filter((item: any) => !item.isGroup))
                dispatch(updateSelectedAttr(returnData(data).filter((item: any) => !item.isGroup)))
            }
        }
        else {
            setSelectedAttr(selectedAttrRedux)
        }
    }, [masterAttr])

    return (
        <div className="flex items-center mr-8">
            {/* a dummy input to take away focus from the select dropdown when closed */}
            <input style={{ height: "0px", width: "0px", display: "hidden" }} readOnly ref={inputRef} />
            <ThemeProvider theme={theme} >
                <FormControl
                    size="small"
                    sx={{
                        width: "120px",
                        position: "right",
                    }}
                >
                    <InputLabel
                        shrink={true}
                        id="demo-job-checkbox-label"
                        sx={{
                            height: '30px',
                            fontSize: { sm: 20, md: 30, lg: 15 },
                            fontFamily: '"Poppins", sans-serif',
                            '&.Mui-focused': {
                                color: 'inherit', // To prevent the label color from changing on focus
                            },
                        }}
                    >
                        Attributes
                    </InputLabel>

                    <Select
                        // variant="standard"
                        labelId="demo-multiple-checkbox-label"
                        label="Attributes"
                        id="demo-multiple-checkbox"
                        sx={{ height: '30px', fontSize: "13px", fontWeight: "700", textAlign: "left", fontFamily: '"Poppins", sans-serif', }}
                        multiple
                        value={selectedAttr}
                        // make this 0
                        renderValue={(selected) =>
                            //  returnData(dropData).length > 2? selected.length + " Selected": "Not defined"}
                            returnData(dropData).length !== 0 ? selected.length + " Selected" : "Not defined"}

                        onChange={(e) => toggle(e.target.value)}
                        // disabled={returnData(dropData).length==2}
                        disabled={returnData(dropData).length === 0}
                        onClose={() => setTimeout(() => inputRef.current.focus(), 100)}
                        IconComponent={ExpandMoreIcon}
                        displayEmpty={true}
                    >
                        {returnData(dropData).map((item: any, i: number) => {
                            if (item.isGroup) {
                                return (
                                    <MenuItem key={i} value={item} onClick={(e) => updateShowAttr(item.name)} sx={{ fontFamily: '"Poppins", sans-serif' }} >
                                        <ListItemText sx={{ fontFamily: '"Poppins", sans-serif' }} primary={item.name} primaryTypographyProps={{
                                            sx: {
                                                fontFamily: '"Poppins", sans-serif'
                                            },
                                        }} />
                                        {item.showAttr ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </MenuItem>
                                )
                            }
                            else {
                                return (
                                    <MenuItem key={i} value={item} divider={item.showDivider} sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                        <Checkbox sx={{ margin: 0, padding: "2px 7px" }}
                                            checked={selectedAttr.filter((elem: any) => elem.name === item.name).length !== 0} />
                                        <ListItemText sx={{ fontFamily: '"Poppins", sans-serif' }} primary={item.name} primaryTypographyProps={{
                                            sx: {
                                                fontFamily: '"Poppins", sans-serif'
                                            },
                                        }} />
                                    </MenuItem>
                                )
                            }
                        })}
                    </Select>
                </FormControl>
            </ThemeProvider>
        </div>
    )
}