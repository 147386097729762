import { useNavigate } from "react-router-dom";

const ErrorHandling = () => {
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate("/dashboard/projects");
  };
  return (
    <div className="flex justify-center flex-col items-center py-3 mt-44">
      <h1 className="text-4xl font-black tracking-wider	" style={{ fontFamily: "Poppins", fontWeight: "900", color: "#4D4D4D" }}>
        Getting Things Ready
      </h1>
      <h2 className="text-xl mt-4" style={{ color: "#4D4D4D" }}>Please come back after some time</h2>
      <button
        onClick={handleNavigateHome}
        className="bg-[#9747ff] px-6 rounded-md py-2 text-xl text-white mt-16"
      >
        Go to Homepage
      </button>
    </div>
  );
};

export default ErrorHandling;
