import { useParams } from "react-router-dom"
import MobileSignup from "./MobileSignUp"
import DesktopSignup from "./DesktopSignUp"


export default function MainSignUp(props: any) {

    let mode = useParams().mode

    return (
        <>
            {mode === "qrSignup" ?
                <MobileSignup handleLoginSu={props.handleLoginSu} />
                :
                <DesktopSignup handleLoginSu={props.handleLoginSu} />
            }
        </>
    )
}
