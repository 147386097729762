import { axiosconfig } from "../../service/apiconfig";

export const configAPI = {
    getAllModerators: ({ project_id }: any) => {
        return axiosconfig.get("/projects/" + project_id + "/moderators/type=pdf")
    },
    fetchProject: ({project_id}:any) => {
        return axiosconfig.get("/projects/" + project_id)
    },
    getFileNames: ({ project_id }: any) => {
        return axiosconfig.get("projects/" + project_id + "/uploaded_files")
    },
    sendMQnAData: ({ project_id, attribute_json }: any) => {
        return axiosconfig.post("/projects/" + project_id + "/configured_data", {
            project_id: project_id,
            attribute_json: attribute_json
        })
    },
    getExcludeQnA: ({ project_id, filter, limit, offset, keyword }: any) => {
        let params:any = {
            filter:filter,
            offset:offset,
            limit:limit,   
        }
        if (keyword){
            params.keyword=keyword
        }
        
        return axiosconfig.post(
            "/projects/" + project_id + "/questions", 
            null,
            {params:params})
        
    },
    getQuestionBucket: ({ project_id, limit, offset }: any) => {  //need to add the offset and limit.
        return axiosconfig.get("projects/" + project_id + "/question_buckets")
    },
    addQuestionBucket: ({ project_id, question_bucket_name }: any) => {
        return axiosconfig.post("projects/" + project_id + "/question_buckets/" + question_bucket_name, {
            project_id: project_id,
            question_bucket_name: question_bucket_name,
        })
    },
    makeQstnsGrouped: ({ project_id, question_ids, question_bucket_ids }: any) => {
        return axiosconfig.post("/projects/" + { project_id } + "/questions/action=group", {
            project_id: project_id,
            question_ids: question_ids,
            question_bucket_ids: question_bucket_ids
        })
    },
    makeQuestionsExclude: ({ project_id, question_ids, exclude }: any) => {
        return axiosconfig.post("/projects/" + { project_id } + "/questions/action=exclude", {
            project_id: project_id,
            question_ids: question_ids,
            exclude: exclude
        })
    },

    updateProjState: ({ project_id, new_state }: any) => {
        return axiosconfig.post(
            "/projects/" + project_id + "/state", 
            {},
            {params:{new_state:new_state}})
    },
    getMapQnAColNames: ({ project_id }: any) => {
        return axiosconfig.get("/projects/" + project_id + "/dataset/columns_names")
    },
    getMQnAColValues: ({ project_id, column_name, dataset_id }: any) => {
        return axiosconfig.get(
            `/projects/${project_id}/dataset/column`,
            {params:{
                dataset_id:dataset_id,
                column_name:column_name
            }}
        )
    },
    sendMQnAExcelData: ({ project_id, attribute_json }: any) => {
        return axiosconfig.post("/projects/" + project_id + "/configured_data_excel", {
            project_id: project_id,
            attribute_json: attribute_json
        })
    },
    getConfigQbs: ({ project_id, limit, offset}: any) => {
        return axiosconfig.get(`/projects/${project_id}/config_question_buckets`, {params: {offset: offset, limit: limit}})
    },
    getGroupQuestionAndAnswers: ({ project_id, group_ids, offset, limit, keyword }: any) => {
        let params:any= {
            offset:offset,
            limit:limit
        }
        if (keyword){
            params.keyword = keyword
        }
        return axiosconfig.post(
            `projects/${project_id}/groups/questionresponses`, 
            group_ids,
            {params:params});
    },
    getInternalPublish: ({project_id}:any)=>{
        return axiosconfig.post(`/projects/${project_id}/internal_review_publish`,{
            project_id: project_id
        })
    }
}
