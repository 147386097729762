import { Button, IconButton, ThemeProvider, createTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { ChevronLeft } from 'react-feather'
import { useAppDispatch, RootState } from "../../redux/store";
import { resetPassword } from "../../redux/Profile/action";
import { useSelector } from "react-redux";
import { CheckCircle, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import { updateTokenExpiry } from "../../redux/Profile/profileDataReducer";

interface IForgotPwd {
    handleClickBack?: false
}


export default function ForgotPwd(props: any) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const tokenExpiry = useSelector((state: RootState) => state.profile.tokenExpiry)


    const customTheme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 640,
                md: 768,
                lg: 1024,
                xl: 1280,
            },
        },
    });

    const [sendLink, setSendLink] = useState(false)
    const [buttonClick, setButtonClick] = useState(false)
    const [enteredLoginMail, setEnteredLoginMail] = useState("")
    const [error, setError] = useState(false)
    const [resendMail, setResendMail] = useState(false)
    const [emailCheck, setEmailCheck] = useState(true)
    const [userNotFound, setUserNotFound] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false);

    // useEffect(() => {
    //     dispatch((getUsers({})))
    // }, [])

    const handleKeyPress = (event: any) => {

        if (event.key === 'Enter') {
            handleSendLink()
        }
    };

    function emailExists(email: any, list: any) {
        const lowercasedEmail = email?.toLowerCase()
        return list?.some((item: any) => item?.USERNAME?.toLowerCase() === lowercasedEmail);
    };


    function handleSendLink() {
        setButtonClick(true)
        if (enteredLoginMail == "" || (!isValidEmail(enteredLoginMail))) {
            setError(true)
        } else {


            handleSendMailLink()
            setError(false)

        }

    }

    function handleSendMailLink() {
        dispatch(resetPassword({
            email: enteredLoginMail,
        })).then((result: any) => {
            if (result.meta.requestStatus === "fulfilled") {
                setSendLink(result.payload.response.status)
                setErrorMsg(!result.payload.response.status)
                setButtonClick(false)
                result.payload.response.status && dispatch(updateTokenExpiry(true))
            } else {
                setUserNotFound(true)
                // setEmailCheck(true)
                setError(true)
                setEmailCheck(false)
            }
        })

    }

    function handleResendMail() {
        setResendMail(true)
        setTimeout(() => setResendMail(false), 4000)
        handleSendMailLink()
    }


    function isValidEmail(email: any) {
        return /\S+@\S+\.\S+/.test(email);
    }

    function handleClickBack() {
        dispatch(updateTokenExpiry(false))
        navigate('/login')
    }

    const handleSignUpFP = () => {
        navigate('/signUp')
    }

    return (
        (!sendLink) ?
            <div className="grid grid-cols-2 max-lg:flex max-lg:flex-col h-full w-full">
                <div className="flex justify-center items-center max-lg:p-14 max-lg:h-[10vh]">
                    <img
                        src={`${window.innerWidth >= 1024 ? "images/EzyThemes logo _vertical_white bg.svg" : "https://ezythemes.com/wp-content/uploads/2024/04/logo.svg"}`}
                        style={{
                            width: window.innerWidth >= 1024 ? "280px" : "50vw",
                            height: "auto"
                        }}
                        alt="Logo"
                    />
                </div>
                <div className="col-span-1 bg-white flex flex-col justify-start items-center gap-[23vh] max-lg:gap-[12vh] max-lg:h-[90vh]">
                    <div className="flex justify-end items-center w-full p-5">
                        <div className="flex justify-end items-center w-fit cursor-pointer" onClick={handleClickBack}>
                            <ChevronLeft
                                color="gray"
                                size={window.innerWidth >= 1024 ? 16 : 36}
                            />
                            <span className="text-gray-500 max-lg:text-3xl">Back to log in</span>
                        </div>
                    </div>
                    <div className="flex flex-col items-center w-[30vw] max-lg:w-[80vw] gap-12 max-lg:gap-16">
                        <div className="w-full">
                            <h1 className="font-semibold text-2xl max-lg:text-5xl mb-3">
                                Password Reset
                            </h1>
                            <p className=" text-lg max-lg:text-3xl text-gray-400">
                                Provide us your email address and we will send you a password reset link
                            </p>
                            {userNotFound &&
                                <div className="text-sm max-lg:text-2xl mt-3 max-lg:mt-8 flex justify-start items-center gap-2">
                                    <CancelIcon className="text-red-500" />
                                    <span className="text-red-600 font-semibold">
                                        Account not activated. Please
                                        <a href="mailto:support@ezythemes.com" className="text-blue-500 no-underline"> contact </a>
                                        our support team for help.
                                    </span>
                                </div>
                            }
                        </div>
                        <div className="w-full">
                            <ThemeProvider theme={customTheme}>
                                <TextField
                                    id="outlined-basic"
                                    sx={{
                                        //maxWidth: '350px', 
                                        placeSelf: 'center',
                                        flexGrow: '1',
                                        width: "100%",
                                        "& .MuiInputBase-root": {
                                            height: { sm: 60, md: 80, lg: 52 },
                                        },
                                        "& .MuiInputLabel-root, & .MuiInputBase-root": {
                                            fontSize: { sm: 20, md: 30, lg: 15 },
                                            fontFamily: '"Poppins", sans-serif'
                                        },
                                        "& .MuiFormHelperText-root": {
                                            fontSize: { sm: 20, md: 20, lg: 10 },
                                            fontFamily: '"Poppins", sans-serif'
                                        },
                                    }}
                                    label="Email Address"
                                    variant="outlined"
                                    onChange={(e) => {
                                        setEnteredLoginMail(e.target.value);
                                        setErrorMsg(false);
                                    }}
                                    value={enteredLoginMail}
                                    onKeyDown={handleKeyPress}
                                    error={error && (enteredLoginMail.length === 0 || (enteredLoginMail.length > 0 && !isValidEmail(enteredLoginMail)) ||
                                        (enteredLoginMail.length > 0 && !emailCheck)
                                    )}
                                    helperText={(error && enteredLoginMail.length === 0 ? "Required" : (error && !isValidEmail(enteredLoginMail) ? <span style={{ color: "red" }}>Email is not valid</span> : <></>
                                        // (!emailCheck && buttonClick ?
                                        //     <div>
                                        //         <p className="text-gray-400">Not an ezythemes user?<span className="text-blue-400 cursor-pointer" onClick={handleSignUpFP} >Sign up</span></p>
                                        //     </div> : "")
                                    ))}
                                />
                            </ThemeProvider>
                            {errorMsg && <span className="text-red-600">Invalid Username</span>}
                        </div>
                        <div className="w-full">
                            <ThemeProvider theme={customTheme}>
                                <Button
                                    variant="outlined"
                                    onClick={handleSendLink}
                                    style={{
                                        width: "100%",
                                        backgroundColor: "#9747FF",
                                        color: "#FFFF",
                                        padding: "8px",
                                    }}
                                    sx={{
                                        height: { sm: "70px", md: "70px", lg: "35px" },
                                        fontSize: { sm: 25, md: 25, lg: 15 },
                                        fontFamily: '"Poppins", sans-serif'
                                    }}>
                                    <span className="capitalize">Send Link</span>
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </div>
            </div>
            :
            <>
                {tokenExpiry &&
                    <svg className="absolute z-10  top-0 mt-[48px] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 16" fill="none">
                        <path d="M1440 0H-188.109L1440 16V0Z" fill="#9747FF" />
                        <path d="M1440 0H-198L1440 9.6V0Z" fill="#85E8E5" />
                    </svg>
                }
                <div className="grid grid-cols-2 max-lg:flex max-lg:flex-col h-full w-full relative">
                    <div className="flex justify-center items-center max-lg:p-14 max-lg:h-[10vh]">
                        <img
                            src={`${window.innerWidth >= 1024 ? "images/EzyThemes logo _vertical_white bg.svg" : "https://ezythemes.com/wp-content/uploads/2024/04/logo.svg"}`}
                            style={{
                                width: window.innerWidth >= 1024 ? "250px" : "50vw",
                                height: "auto"
                            }}
                            alt="Logo"
                        />
                    </div>
                    <div className="col-span-1 bg-white flex flex-col justify-start items-center gap-[27vh] max-lg:gap-[12vh] max-lg:h-[90vh]">
                        <div className="flex justify-end items-center w-full p-5 mt-12">
                            <div className="flex justify-end items-center w-fit cursor-pointer" onClick={handleClickBack}>
                                <ChevronLeft
                                    color="gray"
                                    size={window.innerWidth >= 1024 ? 20 : 36}
                                />
                                <span className="text-gray-500 max-lg:text-3xl">Back to log in</span>
                            </div>
                        </div>
                        <div className="flex flex-col w-[35vw] max-lg:w-[80vw] gap-7 max-lg:gap-10">
                            <div className="w-full">
                                <h1 className="font-semibold text-2xl max-lg:text-5xl mb-3">Link has been sent</h1>
                                <p className="text-gray-500 max-lg:text-3xl">Check your email :{" "}<span className="text-gray-500 underline italic">{enteredLoginMail}</span></p>
                            </div>
                            <div className="w-full">
                                <p className="max-lg:text-3xl">
                                    You should have received an email from us based on the email address you have entered earlier. Open the
                                    link in the email to reset your password within the next 30 minutes.
                                </p>
                            </div>
                            <div className="w-full max-lg:text-3xl">
                                <p>Did not receive the link? <a href="mailto:support@ezythemes.com" className="text-blue-400 no-underline">
                                    Contact Team
                                </a></p>
                            </div>
                            {
                                resendMail &&
                                <div className="flex justify-between bg-lime-100 items-center p-3 max-lg:py-8 rounded-lg max-lg:rounded-2xl">
                                    <div className="flex justify-start items-center gap-2 max-lg:gap-4">
                                        <CheckCircle className="text-green-600 max-lg:scale-[2] max-lg:ml-[14px]" />
                                        <p className="text-lg max-lg:text-3xl"> A new mail has been sent</p>
                                    </div>
                                    <IconButton
                                        className="max-lg:scale-[2]"
                                        sx={{ padding: "2px", marginRight: window.innerWidth >= 1024 ? "" : "14px" }}
                                        onClick={() => setResendMail(false)}>
                                        <Close />
                                    </IconButton>
                                </div>
                            }
                        </div>
                    </div>
                    {tokenExpiry &&
                        <svg className=" fixed bottom-0 left-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 16" fill="none">
                            <path d="M0.000732422 16H1628.11L0.000732422 0V16Z" fill="#9747FF" />
                            <path d="M0 16H1638L0 7V16Z" fill="#85E8E5" />
                        </svg>
                    }
                </div>
            </>
    )
}