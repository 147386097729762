import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {

  const navigate = useNavigate();
  const pathName = useLocation().pathname.split("/")[1];
  return (
    <div className="bg-white mb-0 pb-3 ml-1 pr-2 w-full">
      <hr />
      <div className="flex justify-between w-full items-center mb-0 mt-1 mr-80 select-none">
        <div className="flex items-center w-1/4	">
          <img className="pt-1"
            src="/images/EzyThemes logo _horizontal_white bg.svg"
            style={{ width: "auto", height: "24px", marginLeft: "20px" }}
            alt="logo"
          />
          {(pathName.includes('reset-password') || (pathName.includes('forgotPassword'))) && <span className="text-black-600 text-sm pl-4">
            © 2023 Ezythemes
          </span>}
          {/*<span className='text-white'>Data shown reflects latest updated data(MM/YY) unless stated otherwise</span>*/}
        </div>
        {pathName !== "profile" && (
          <div className={`flex justify-center gap-2 ${(pathName.includes('reset-password') || (pathName.includes('forgotPassword'))) ? 'pr-72' : ""}`}>
            <span>Need help?</span>
            <span
              className="cursor-pointer"
              style={{ color: "#9747FF" }}
              onClick={() => navigate("/help", { replace: true })}
            >
              Contact team
            </span>
          </div>
        )}

        {(!pathName.includes('reset-password') && !(pathName.includes('forgotPassword'))) && <div className=" mr-2 flex justify-end w-1/4">
          <span className="text-black-600 text-sm">
            © 2023 Ezythemes. All Rights Reserved
          </span>
        </div>}
      </div>
    </div>
  );
};

export default Footer;
