import { ChevronRight } from "react-feather"

interface ISingleSelectComp {
    dataList: Array<any>
    selectedItem: any
    handleSelect: any
}

export default function SingleSelectComp(props: ISingleSelectComp) {

    return (
        <div className="h-full flex flex-col justify-start gap-2">
            {props.dataList.map((item: any, i: number) => {
                return (
                    <div key={i} className={`w-full flex justify-between items-center rounded-md py-2 ${item.is_active ? "cursor-pointer" : "cursor-default"} ${props.selectedItem.name === item.name ? "bg-blue-100" : ""}`}
                        onClick={() => {
                            if (item.is_active) {
                                props.handleSelect(item, "Single")
                            }
                        }}>
                        <div className="pl-2 flex justify-between items-center w-full">
                            <span className={`${item.is_active ? "" : "text-gray-400"}`}>{item.name} <span className="text-gray-400">({item.sentence_count})</span></span>
                            {/* {!item.is_active && <VisibilityOffIcon className="text-gray-500 mr-3"/>} */}
                        </div>
                        {props.selectedItem.name === item.name && <ChevronRight />}
                    </div>
                )
            })
            }
        </div>
    )
}