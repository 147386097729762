import ThemeItems from "../ThemeItems"

interface IMultiSelectComp {
    dataList: Array<any>
    selectedItem: Array<any>
    handleSelect: any
    handleHide: any
    editTheme: any
}

export default function MultiSelectComp(props: IMultiSelectComp) {

    return (
        <div className="h-full flex flex-col justify-start gap-2">
            {props.dataList.map((item: any, i: number) => {
                return (
                    <div key={i} className={`w-full `} onClick={() => props.handleSelect(item)}>
                        <ThemeItems
                            handleThemeSelection={props.handleSelect}
                            item={item}
                            selectedTheme={props.selectedItem}
                            handelHideTheme={props.handleHide}
                            editTheme={props.editTheme}
                        />
                    </div>
                )
            })
            }
        </div>
    )
}