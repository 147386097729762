import { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './redux/store';
import { UserDataProvider } from './context/userDataContext';
import { FeaturesDataProvider } from './context/featuresDataContext';
import { MasterDataProvider } from './context/masterDataContext';
import { SiteDataProvider } from './context/siteDataContext';
import Layout from './components/Layout';
import Notification from './components/Notification';
import ScrollToTop from './components/ScrollToTop';
import { getAccessToken, getExpiry, removeTokens } from './utils/localStorage';
import { refreshAccessToken } from './redux/Profile/action';
import { secrateKey } from './modules/LoginLayout/LoginLayout';
import * as CryptoJS from 'crypto-js';
import jwt_decode from "jwt-decode";




interface IUser {
	accountName: string;
	email: string;
	displayName: string;
	accessToken: string;
	refreshToken: string;
}
function AppContainer() {
	const siteData = useSelector((state: RootState) => state.site.data);
	const masterData = useSelector((state: RootState) => state.master.data);
	const featuresData = useSelector((state: RootState) => state.master.data);

	const loginDetails = useSelector((state: RootState) => state.profile.loginDetails);
	const [userData, setUserData] = useState(loginDetails);
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		setUserData(loginDetails);
	}, [loginDetails]);

	//function to decrypt userdata from local storage
	function decryptUserData(encryptedText: string, secrateKey: string) {
		const bytes = CryptoJS.AES.decrypt(encryptedText, secrateKey);
		const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
		return JSON.parse(decryptedData);
	}

	useEffect(() => {
		const checkExpirationAndRedirect = () => {
			const expiry = getExpiry();
			const currentDate = Date.now();
			if ((!expiry) && !window.location.href.includes('set-password') && !window.location.href.includes('signUp') && !window.location.href.includes('qrSignup')) {
				if (!window.location.href.includes('login')) {
					//If a user arrives via a mail link and is not logged in, we store the URL in local storage. Upon returning to the login page, we clear the local storage.
					localStorage.setItem("redirectUrl", JSON.stringify(window.location.href))
					window.location.href = `${window.location.origin}/login`;
				}
			}
			if (expiry !== "" && expiry && currentDate > expiry.exp) {
				localStorage.clear();
				window.location.href = `${window.location.origin}/login`;
			}
		};

		// const activityTimeout = setTimeout(() => {
		// 	removeTokens();
		// 	window.location.href = `${window.location.origin}/login`;
		// }, 45 * 60 * 1000);

		const refreshAccessTokenIfNeeded = async () => {
			const expiry = getExpiry();
			const currentDate = Date.now();

			// Check if within 5 minutes of token expiry
			if (expiry && currentDate > expiry.exp - 5 * 60 * 1000) {
				const encryptedUserData = localStorage.getItem('_U');
				if (encryptedUserData) {
					const userDataString = JSON.parse(encryptedUserData);
					const userData = decryptUserData(userDataString, secrateKey);
					const refreshToken = userData.refreshToken;

					// Perform the refresh access token action
					if (refreshToken) {
						try {
							const res: any = await dispatch(refreshAccessToken({ token: refreshToken }));
							const newAccessToken = res.payload.response.data.access_token;

							// Updating local storage with new access token
							if (newAccessToken) {
								userData.accessToken = newAccessToken;  // Update accessToken in userData and encrypt
								const encodedUserData = CryptoJS.AES.encrypt(JSON.stringify(userData), secrateKey).toString();
								localStorage.setItem('_U', JSON.stringify(encodedUserData));

								// Updating expiry time
								const decodedAT: any = jwt_decode(newAccessToken);
								const newExpiry = {
									exp: decodedAT.exp * 1000,
									time: new Date(decodedAT.exp * 1000).toISOString(),
								};
								localStorage.setItem('expiry', JSON.stringify(newExpiry));
							}
						}
						catch (error) {
							console.error('Error refreshing access token:', error);
						}
					} else {
						console.error('No refresh token available');
					}
				} else {
					console.error('User data is not found in local storage');
				}
			}
		};


		checkExpirationAndRedirect();
		const refreshInterval = setInterval(refreshAccessTokenIfNeeded, 60 * 1000);

		return () => {
			// clearTimeout(activityTimeout);
			clearInterval(refreshInterval);
		};
	}, [dispatch, userData]);

	return (
		<UserDataProvider value={{ userData, setUserData }}>
			<BrowserRouter>
				<ScrollToTop>
					<FeaturesDataProvider value={featuresData}>
						<SiteDataProvider value={siteData}>
							<MasterDataProvider value={masterData}>
								<Layout />
								<Notification />
							</MasterDataProvider>
						</SiteDataProvider>
					</FeaturesDataProvider>
				</ScrollToTop>
			</BrowserRouter>
		</UserDataProvider>
	);
}

export default AppContainer;
