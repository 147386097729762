import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getConfigureDataState,
  getProjectDataSet,
  getWizardData,
  postConfigureDataState,
} from "./action";
import { IWizardData } from "./type";
import { actions } from "react-table";
import { sortBy } from 'lodash';

const initialState: IWizardData = {
  loaded: false,
  data: {},
  error: "",
  wizardData: [],
  ConfigureDataState: [],
  projectDataSet: [],
  questionBuckets: [],
  areaOfInterest: [],
  attribute1: '',
  attribute2: '',
  toggleValue1: true,
  toggleValue2: true,
  attributeData: [],
  attributeData1: [],
  postConfigureDataStateSuccess: false,
  configureLoader: false,
  createProjectLoader: false,
  ConfigureDataSuccess: false,
  configureDataLoader: false
};

export const wizardDataSlice = createSlice({
  name: "wizarddata",
  initialState: initialState,
  reducers: {
    updateWizardData: (state, action) => {
      state.wizardData = action.payload;
    },
    updateQuestionBucket: (state, action) => {
      state.questionBuckets = action.payload
    },
    updateAreaOfInterest: (state, action) => {
      state.areaOfInterest = action.payload
    },
    UpdateAttribute1: (state, actions) => {
      state.attribute1 = actions.payload
    },
    UpdateAttribute2: (state, actions) => {
      state.attribute2 = actions.payload
    },
    UpdateToggle1: (state, actions) => {
      state.toggleValue1 = actions.payload
    },
    UpdateToggle2: (state, actions) => {
      state.toggleValue2 = actions.payload
    },
    UpdateAttributeData: (state, actions) => {
      state.attributeData = actions.payload
    },
    UpdateAttributeData1: (state, actions) => {
      state.attributeData1 = actions.payload
    },
    updateCreateProjectLoader: (state, actions) => {
      state.createProjectLoader = actions.payload
    },
    updateConfigureDataSuccess: (state, action) => {
      state.ConfigureDataSuccess = false
    },
    updateProjectDataSet: (state, action) => {
      state.projectDataSet = []
    },
    updateConfigureData: (state, action) => {
      state.ConfigureDataState = action.payload
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getWizardData.pending, (state: IWizardData) => { })
      .addCase(
        getWizardData.fulfilled,
        (state: IWizardData, action: PayloadAction<any>) => {
          const stepOrder = sortBy(action.payload?.response?.data, ['steps_order'])
          state.wizardData = stepOrder;
        }
      )
      .addCase(getWizardData.rejected, (state: IWizardData) => { });
    builder
      .addCase(getConfigureDataState.pending, (state: IWizardData) => {
        state.configureDataLoader = true
      })
      .addCase(
        getConfigureDataState.fulfilled,
        (state: IWizardData, action: PayloadAction<any>) => {
          state.ConfigureDataState = action.payload.response.data;
          state.ConfigureDataSuccess = action.payload.response.status
          state.configureDataLoader = false
        }
      )
      .addCase(getConfigureDataState.rejected, (state: IWizardData) => {
        state.configureDataLoader = false
      });
    builder
      .addCase(postConfigureDataState.pending, (state: IWizardData) => { })
      .addCase(
        postConfigureDataState.fulfilled,
        (state: IWizardData, action: PayloadAction<any>) => {
          // state.ConfigureDataState = action.payload.response.data;
        }
      )
      .addCase(postConfigureDataState.rejected, (state: IWizardData) => { });
    builder
      .addCase(getProjectDataSet.pending, (state: IWizardData) => { })
      .addCase(
        getProjectDataSet.fulfilled,
        (state: IWizardData, action: PayloadAction<any>) => {
          state.projectDataSet = action.payload.response.data;
        }
      )
      .addCase(getProjectDataSet.rejected, (state: IWizardData) => { });
  },
});

export const { updateWizardData, updateConfigureDataSuccess, updateProjectDataSet, updateQuestionBucket, updateAreaOfInterest, UpdateAttribute1, UpdateToggle2, UpdateAttribute2, UpdateToggle1, UpdateAttributeData, UpdateAttributeData1, updateCreateProjectLoader, updateConfigureData } = wizardDataSlice.actions;

export default wizardDataSlice.reducer;
