import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProjectAPI } from "../../service/api/createProj";
import { insertFile, projectDataSlice } from "./projectDataReducer";
import { RootState } from "../../redux/store";
import { openSnackbar } from "../Notification/notificationReducer";
import { configAPI } from "../../service/api/configAPI";

export const getProjectsDataList = createAsyncThunk(
  "getProjectsDataList",
  async ({ project_id }: any, { rejectWithValue }) => {
    try {
      const response = await ProjectAPI.getProjectsDataList({ project_id });
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);
export const getProjectDomine = createAsyncThunk(
  "getProjectDomine",
  async (any, { rejectWithValue }) => {
    try {
      const response = await ProjectAPI.getProjectDomine();
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);

export const getProjectState = createAsyncThunk(
  "getProjectState",
  async ({ project_id, activeStep }: any, { rejectWithValue }) => {
    try {
      const response = await ProjectAPI.getProjectState({ project_id, activeStep });
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);
export const getProjectFileUpload = createAsyncThunk(
  "getProjectFileUpload",
  async (
    {
      project_id,
      project_name,
      domain_id,
      domain_name,
      category_id,
      category_name,
      files,
      state,
    }: any,
    { dispatch, rejectWithValue }
  ) => {
    try {
      files.forEach((element: any) => {
        dispatch(insertFile(element.filename));
      });
      const fileProgressEvent = (progressEvent: ProgressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        dispatch(
          projectDataSlice.actions.setFileUploadProgress({
            filenames: files.map((e: any) => e.name),
            progress: progress,
          })
        );
      };
      const response = await ProjectAPI.getProjectFileUpload({
        project_id,
        project_name,
        domain_id,
        domain_name,
        category_id,
        category_name,
        files,
        state,
        fileProgressEvent,
      });
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);
// export const getDiscussionGuideUpload = createAsyncThunk(
//   "getDiscussionGuideUpload",
//   async ({ project_id, files }: any, { dispatch, rejectWithValue }) => {
//     try {
//       files.forEach((element: any) => {
//         dispatch(insertFile(element.filename));
//       });
//       const fileProgressEvent = (progressEvent: ProgressEvent) => {
//         const progress = Math.round(
//           (progressEvent.loaded / progressEvent.total) * 100
//         );
//         dispatch(
//           projectDataSlice.actions.setFileUploadProgress({
//             filenames: files.map((e: any) => e.name),
//             progress: progress,
//           })
//         );
//       };
//       const response = await ProjectAPI.getProjectFileUpload({
//         project_id,
//         files,
//         fileProgressEvent,
//       });
//       return {
//         response: response.data,
//       };
//     } catch (error: any) {
//       throw rejectWithValue(error.data);
//     }
//   }
// );



export const getAllProjects = createAsyncThunk(
  "getAllProjects",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const {
        paginationLimit,
        currentPage,
        projectsSearch,
        projectsFilter,
        projectsOrder,
        favoritesProjectsOnly,
        AccountFilterData,
        accountFilter,
      } = state.project;
      const offset = paginationLimit * (currentPage - 1);
      const accountFilterId = AccountFilterData?.find((each: any) => each?.account_id === accountFilter)?.account_id
      const response = await ProjectAPI.getAllProjects(
        offset,
        paginationLimit,
        projectsSearch,
        projectsFilter,
        favoritesProjectsOnly,
        projectsOrder,
        accountFilterId
      );
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);

export const getDeleteProjectFile = createAsyncThunk(
  "getDeleteProjectFile",
  async ({ project_id, filename, dataset_id }: any, { rejectWithValue }) => {
    try {
      const response = await ProjectAPI.deleteFile({ project_id, filename, dataset_id });
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);

export const editProjectName = createAsyncThunk(
  "editProjectName",
  async ({ projectId, projectName }: any, { dispatch, rejectWithValue }) => {
    const response = await ProjectAPI.editProject(projectId, {
      project_name: projectName,
    });
    const jsonString = new TextDecoder().decode(response.data);
    const jsonObject = JSON.parse(jsonString);
    if (jsonObject.status) {
      dispatch(openSnackbar({ message: 'Project name edited Successfully', type: 'success' }))
      return jsonObject.data
    } else {
      dispatch(openSnackbar({ message: 'Project with same name already exists', type: 'error' }))
      throw rejectWithValue(jsonObject.data);
    }
  }
);

export const addFavorite = createAsyncThunk(
  "addFavorite",
  async ({ projectId, isFavorite }: any, { rejectWithValue }) => {
    const response = await ProjectAPI.editProject(projectId, {
      is_favorite: isFavorite,
    });
    const jsonString = new TextDecoder().decode(response.data);
    const jsonObject = JSON.parse(jsonString);
    if (jsonObject.status) {
      return response.data;
    } else {
      throw rejectWithValue(jsonObject.data);
    }
  }
);
export const getDeleteProject = createAsyncThunk(
  "getDeleteProject",
  async ({ project_id }: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await ProjectAPI.deleteProject({ project_id });
      return {
        response: response.data,
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);

export const updateProject = createAsyncThunk("updateProject", async ({ project_id }: any, { rejectWithValue }) => {
  try {
    const response = await configAPI.fetchProject({ project_id })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
});

export const downloadManageThemes = createAsyncThunk("downloadManageThemes", async ({ project_id }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.downloadManageThemes({ project_id })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
});

export const uploadManageThemes = createAsyncThunk("uploadManageThemes", async ({ project_id, file }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.uploadManageThemes({ project_id, file })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
});

export const confirmManageThemes = createAsyncThunk("confirmManageThemes", async ({ project_id }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.confirmManageThemes({ project_id })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const downLoadMRQc = createAsyncThunk("downLoadMRQc", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.downLoadMRQc({ projectId })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const uploadMRQc = createAsyncThunk("uploadMRQc", async ({ projectId, fileInfo }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.uploadMRQc({ projectId, fileInfo })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})


export const confirmMRQCCorrection = createAsyncThunk("confirmMRQCCorrection", async ({ projectId, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.confirmMRQCCorrection({ projectId, payload })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const saveMRQCFiles = createAsyncThunk("saveMRQCFiles", async ({ projectId, data }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.saveMRQCFiles({ projectId, data })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})


export const getMRQCFiles = createAsyncThunk("getMRQCFiles", async ({ projectId }: any, { dispatch, rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getMRQCFiles({ projectId });
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getProjectDetails = createAsyncThunk("getProjectDetails", async ({ project_id }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getProjectDetails({ project_id })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getDivergenceData = createAsyncThunk("getDivergenceData", async ({ project_id }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getDivergenceData(project_id)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getAccountFilterData = createAsyncThunk("getAccountFilterData", async ({ project_id }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getAccountFilterData()
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})


export const getUpdateAccountId = createAsyncThunk("getUpdateAccountId", async ({ project_id }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getUpdateAccountId({ project_id })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getClonedProjectInfo = createAsyncThunk("getClonedProjectInfo", async ({ ProjectId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getClonedProjectInfo(ProjectId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const downloadInputFiles = createAsyncThunk("downloadInputFiles", async ({ projectId, dataset_id }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.downloadInputFiles({ projectId, dataset_id })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})
export const getEntitiesQcData = createAsyncThunk("getEntitiesQcData", async ({ projectId, viewType, entityId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getEntitiesQcData(projectId, viewType, entityId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getEntitiesPreQcData = createAsyncThunk("getEntitiesPreQcData", async ({ projectId, viewType, entityId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getEntitiesPreQcData(projectId, viewType, entityId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const intelligentMR = createAsyncThunk("intelligentMR", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.intelligentMR({ projectId })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})
export const getEntityTypesData = createAsyncThunk("getEntityTypesData", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getEntityTypesData(projectId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getEntityTypesPreQcData = createAsyncThunk("getEntityTypesPreQcData", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getEntityTypesPreQcData(projectId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const intelligentCorrection = createAsyncThunk("intelligentCorrection", async ({ projectId }: any, { rejectWithValue }) => {
  try {

    const response = await ProjectAPI.intelligentCorrection({ projectId })
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})
export const getSentenceEntityAnchor = createAsyncThunk("getSentenceEntityAnchor", async ({ projectId, anchorId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getSentenceEntityAnchor(projectId, anchorId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const updateIntelligentCorrection = createAsyncThunk("updateIntelligentCorrection", async (payload: any, { rejectWithValue }) => {
  try {

    const response = await ProjectAPI.updateIntelligentCorrection(payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const addEntityEquivalent = createAsyncThunk("addEntityEquivalent", async ({ projectId, anchorId, equivalentName }: any, { rejectWithValue }) => {
  try {

    const response = await ProjectAPI.addEntityEquivalent(projectId, anchorId, equivalentName)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const addEntityPreQcEquivalent = createAsyncThunk("addEntityPreQcEquivalent", async ({ projectId, anchorId, equivalentName }: any, { rejectWithValue }) => {
  try {

    const response = await ProjectAPI.addEntityPreQcEquivalent(projectId, anchorId, equivalentName)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const addEntityAnchor = createAsyncThunk("addEntityAnchor", async ({ projectId, anchorName, entityType }: any, { rejectWithValue }) => {
  try {

    const response = await ProjectAPI.addEntityAnchor(projectId, anchorName, entityType)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const addEntityPreQcAnchor = createAsyncThunk("addEntityPreQcAnchor", async ({ projectId, anchorName, entityType }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.addEntityPreQcAnchor(projectId, anchorName, entityType)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const addEntityType = createAsyncThunk("addEntityType", async ({ projectId, entityType }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.addEntityType(projectId, entityType)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const addEntityPreQCType = createAsyncThunk("addEntityPreQCType", async ({ projectId, entityType }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.addEntityPreQCType(projectId, entityType)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const updateIntelligentMR = createAsyncThunk("updateIntelligentMR", async (payload: any, { rejectWithValue }) => {
  try {

    const response = await ProjectAPI.updateIntelligentMR(payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})
export const updateEntityAnchor = createAsyncThunk("updateEntityAnchor", async ({ projectId, anchorId, newEntityAnchorName, isPreQc, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.updateEntityAnchor(projectId, anchorId, newEntityAnchorName, isPreQc, payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const updateEntityPreQCAnchor = createAsyncThunk("updateEntityPreQCAnchor", async ({ projectId, anchorId, newEntityAnchorName, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.updateEntityPreQCAnchor(projectId, anchorId, newEntityAnchorName, payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const updateEntityType = createAsyncThunk("updateEntityType", async ({ projectId, entityId, isUserDefined, sentiment, contextUsed, renameEntityType, isPreQc, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.updateEntityType(projectId, entityId, isUserDefined, sentiment, contextUsed, renameEntityType, isPreQc, payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const updateEntityPreQcType = createAsyncThunk("updateEntityPreQcType", async ({ projectId, entityId, isUserDefined, sentiment, contextUsed, renameEntityType, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.updateEntityPreQcType(projectId, entityId, isUserDefined, sentiment, contextUsed, renameEntityType, payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const deleteEntity = createAsyncThunk("deleteEntity", async ({ projectId, entityId, type, isDeleted }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.deleteEntity(projectId, entityId, type, isDeleted)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const deleteEntityPreQC = createAsyncThunk("deleteEntityPreQC", async ({ projectId, entityId, type, isDeleted }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.deleteEntityPreQC(projectId, entityId, type, isDeleted)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const moveEntityAnchor = createAsyncThunk("moveEntityAnchor", async ({ projectId, entityType, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.moveEntityAnchor(projectId, entityType, payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const moveEntityPreQcAnchor = createAsyncThunk("moveEntityPreQcAnchor", async ({ projectId, entityType, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.moveEntityPreQcAnchor(projectId, entityType, payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const moveEntityEquivalent = createAsyncThunk("moveEntityEquivalent", async ({ projectId, anchorId, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.moveEntityEquivalent(projectId, anchorId, payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const moveEntityPreQcEquivalent = createAsyncThunk("moveEntityPreQcEquivalent", async ({ projectId, anchorId, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.moveEntityPreQcEquivalent(projectId, anchorId, payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getParkAnchorsList = createAsyncThunk("getParkAnchorsList", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getParkAnchorsList(projectId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getParkAnchorsPreQcList = createAsyncThunk("getParkAnchorsPreQcList", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getParkAnchorsPreQcList(projectId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getParkEquivalentsList = createAsyncThunk("getParkEquivalentsList", async ({ projectId, entityType }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getParkEquivalentsList(projectId, entityType)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getPreQcParkEquivalentsList = createAsyncThunk("getPreQcParkEquivalentsList", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getPreQcParkEquivalentsList(projectId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const moveToParkTabs = createAsyncThunk("moveToParkTabs", async ({ projectId, parkGroup, type, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.moveToParkTabs(projectId, parkGroup, type, payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const moveToPreQcParkTabs = createAsyncThunk("moveToPreQcParkTabs", async ({ projectId, parkGroup, type, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.moveToPreQcParkTabs(projectId, parkGroup, type, payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getDomainMaster = createAsyncThunk("getDomainMaster", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getDomainMaster()
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const updateProjectCategory = createAsyncThunk("updateProjectCategory", async ({ projectId, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.updateProjectCategory(projectId, payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})
//Process output 
export const postProcessOutputState = createAsyncThunk("postProcessOutputState", async ({ project_id, field }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.postProcessOutputState(project_id, field);
    return {
      response: response.data,
      project_id, field
    };
  } catch (error: any) {
    throw rejectWithValue(error.data);
  }
}
);

//Save file configuration 
export const saveProjectConfiguration = createAsyncThunk(
  "saveProjectConfiguration",
  async (
    { project_id, research_type, subscription_id, payload }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await ProjectAPI.saveProjectConfiguration(project_id, research_type, subscription_id, payload);
      return {
        response: response.data,
        project_id, research_type, subscription_id, payload
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);

export const getProjectConfiguration = createAsyncThunk(
  "getProjectConfiguration",
  async (
    { project_id, activeStep }: any,
    { rejectWithValue }
  ) => {
    try {
      const response = await ProjectAPI.getProjectConfiguration(project_id, activeStep);
      return {
        response: response.data,
        project_id, activeStep
      };
    } catch (error: any) {
      throw rejectWithValue(error.data);
    }
  }
);


export const getPreQcTabData = createAsyncThunk("getPreQcTabData", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getPreQcTabData(projectId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const updatePreQcTabData = createAsyncThunk("updatePreQcTabData", async ({ projectId, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.updatePreQcTabData(projectId, payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const createPreQcTabData = createAsyncThunk("createPreQcTabData", async ({ projectId, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.createPreQcTabData(projectId, payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const deletePreQcTabData = createAsyncThunk("deletePreQcTabData", async ({ projectId, id }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.deletePreQcTabData(projectId, id)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const addEntityPreQc = createAsyncThunk("addEntityPreQc", async ({ projectId, bucket, name, id, isUserDefined, parentId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.addEntityPreQc(projectId, bucket, name, id, isUserDefined, parentId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const qc0SubmissionValidation = createAsyncThunk("qc0SubmissionValidation", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.qc0SubmissionValidation(projectId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const qcDaizyLabelsSearch = createAsyncThunk("qcDaizyLabelsSearch", async ({ searchString, cancelToken }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.qcDaizyLabelsSearch(searchString, cancelToken)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getIntelligentMrQcData = createAsyncThunk("getIntelligentMrQcData", async ({ projectId, fileID }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getIntelligentMrQcData(projectId, fileID)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})
export const getSpeakersList = createAsyncThunk("getSpeakersList", async ({ projectId, file_id }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getSpeakersList(projectId, file_id)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const getCorrectedMrPreQcData = createAsyncThunk("getCorrectedMrPreQcData", async ({ projectId, fileID }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getCorrectedMrPreQcData(projectId, fileID)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})
export const getTranscriptsData = createAsyncThunk("getTranscriptsData", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.getTranscriptsData(projectId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const updateIntelligentMrPreQc = createAsyncThunk("updateIntelligentMrPreQc", async ({ projectId, action, fileID, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.updateIntelligentMrPreQc(projectId, action, fileID, payload)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const updateMrPreQcTextBlocks = createAsyncThunk("updateMrPreQcTextBlocks", async ({ projectId, payload }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.updateMrPreQcTextBlocks(projectId, payload)
    return {
      index: payload?.index,
      oldText: payload?.updated_old_text,
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const renameSavedViews = createAsyncThunk("renameSavedViews", async ({ projectId, name, id }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.renameSavedViews(projectId, name, id)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const submitIntelligentCorrectionQc = createAsyncThunk("submitIntelligentCorrectionQc", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.submitIntelligentCorrectionQc(projectId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})

export const submitEntityQc = createAsyncThunk("submitEntityQc", async ({ projectId }: any, { rejectWithValue }) => {
  try {
    const response = await ProjectAPI.submitEntityQc(projectId)
    return {
      response: response.data
    }
  }
  catch (error: any) {
    throw rejectWithValue(error.data)
  }
})