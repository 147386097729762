
import { useEffect, useState } from "react";
import ThemeSentences from "./ThemesSentences";
import ThemeMaster from "./ThemesMaster";
import { useAppDispatch } from "../../redux/store";
import { XCircle } from "react-feather";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Popup from "reactjs-popup";
import { setScopeUpdated, setSubmitEnable } from "../../redux/EditProject/EditProjectReducer";


function ThemesEditProject() {
    const dispatch = useAppDispatch();

    const [themeTab, setThemeTab] = useState('Theme Master')
    const [applyChangesActive, setApplyChangesActive] = useState(false)
    const [applyChangesClick, setApplyChangesClick] = useState(false)
    const [resetChanges, setResetChanges] = useState(false)
    const [resetClick, setResetClick] = useState(false)
    const [openPopup, setOpenPopup] = useState(false)
    const [openPopupReset, setOpenPopupReset] = useState(false)
    const [openPopupSwitchTab, setOpenPopupSwitchTab] = useState(false)
    const [warningMaster, setWarningMaster] = useState([])
    const [warningSentences, setWarningSentences] = useState([])
    const [warningsShow, setWarningsShow] = useState<any>([])
    const [resetSortFilter, setResetSortFilter] = useState(false)


    useEffect(() => {
        setWarningsShow([])
        validation()
    }, [warningMaster])

    useEffect(() => {
        setWarningsShow([])
        validation()
    }, [warningSentences])

    const onClickTabSwitch = (tab: any) => {
        if (applyChangesActive && resetChanges) {
            setOpenPopupSwitchTab(true)
        } else {
            setThemeTab(tab)
        }
    }

    useEffect(() => {
        if (openPopupSwitchTab) {
            setOpenPopupSwitchTab(false)
            if (themeTab === 'Theme Master') {
                setThemeTab('Themes Sentences')
            } else {
                setThemeTab('Theme Master')
            }
        }
    }, [warningMaster, warningSentences])



    const validation = () => {
        if (themeTab === "Theme Master") {
            let msg = "";
            warningMaster.map((row: any, index: any) => {
                if (row.rename_theme && row.merge_to && row.is_deleted) {
                    msg = `The theme "${row.display_name}" is deleted therefore will not be merged with "${row.merge_to}" or renamed. Do you wish to Apply changes?`
                    setWarningsShow(((prevState: any) => {
                        let temp = [...prevState]
                        temp[index] = { ...temp[index], "message": msg }
                        return temp
                    }))
                }

                // rename but deleted 
                else if (row.is_deleted === true && row.rename_theme) {
                    msg = `The theme "${row.display_name}" is deleted and renaming will not have any effect. Do you wish to Apply changes?`
                    setWarningsShow(((prevState: any) => {
                        let temp = [...prevState]
                        temp[index] = { ...temp[index], "message": msg }
                        return temp
                    }))
                }
                // renamed but merged with another
                else if (row.rename_theme && row.merge_to) {
                    msg = `The theme "${row.display_name}" is merged with "${row.merge_to}" and renaming will not have any effect. Do you wish to Apply changes?`
                    setWarningsShow(((prevState: any) => {
                        let temp = [...prevState]
                        temp[index] = { ...temp[index], "message": msg }
                        return temp
                    }))

                }

                else if (row.merge_to && row.is_deleted) {
                    msg = `The theme "${row.display_name}" is deleted therefore will not be merged with "${row.merge_to}". Do you wish to Apply changes?`
                    setWarningsShow(((prevState: any) => {
                        let temp = [...prevState]
                        temp[index] = { ...temp[index], "message": msg }
                        return temp
                    }))
                }

                // merged but deleted 
                else if (row.merge_to) {
                    warningMaster.map((item: any) => {
                        if (item.display_name === row.merge_to) {
                            if (item.is_deleted) {
                                msg = `The theme "${row.display_name}" will not be merged to "${row.merge_to}" which will be deleted. Do you wish to Apply changes?`
                                setWarningsShow(((prevState: any) => {
                                    let temp = [...prevState]
                                    temp[index] = { ...temp[index], "message": msg }
                                    return temp
                                }))
                            }
                        }
                    })
                } else if (row.is_user_defined && row.is_deleted) {
                    msg = `You are trying to delete "${row.display_name}" which is an user defined theme. Do you wish to Apply changes?`
                    setWarningsShow(((prevState: any) => {
                        let temp = [...prevState]
                        temp[index] = { ...temp[index], "message": msg }
                        return temp
                    }))
                }
                else if (row.is_deleted === true && row.change_theme_type) {
                    msg = `The theme "${row.display_name}" will be deleted and changing theme type will not have any effect. Do you wish to Apply changes?`
                    setWarningsShow(((prevState: any) => {
                        let temp = [...prevState]
                        temp[index] = { ...temp[index], "message": msg }
                        return temp
                    }))
                }
            })
            return msg
        }
        else {
            let msg = "";
            warningSentences.map((row: any, index: any) => {
                // changing sentence theme but deleting 
                if (row.is_deleted === true && row.change_theme_type) {
                    //msg = `The theme "${row.theme_name}" will be deleted and changing sentence theme will not have any effect. Do you wish to Apply changes?`
                    msg = `The sentence is deleted and will not be moved to theme "${row.theme_name}". Do you wish to Apply changes?`
                    setWarningsShow(((prevState: any) => {
                        let temp = [...prevState]
                        temp[index] = { ...temp[index], "message": msg }
                        return temp
                    }))
                }
            })
            return msg
        }
    }

    const onClickReset = () => {
        setOpenPopupReset(true)
    }

    useEffect(() => {
        setApplyChangesActive(false)
        dispatch(setSubmitEnable(false))
        setResetChanges(false)
        setWarningMaster([])
    }, [themeTab])

    const onApplyChangesTM = () => {
        if (validation() === '') {
            setOpenPopup(true)
        } else {
            setOpenPopup(true)
            setResetSortFilter(!resetSortFilter)
        }
    }

    const handleButtonClick = () => {
        setOpenPopup(false);
        setApplyChangesActive(false);
        setApplyChangesClick(true);
        setResetChanges(false);
        setResetClick(false);
        dispatch(setScopeUpdated(false));
        dispatch(setSubmitEnable(false));
    };


    return (
        <div className="">
            <div className="flex w-full justify-between">
                <div className="flex items-center">
                    <div className={`bg-[#9747FF] transform transition duration-300 w-fit py-1 px-5 text-white font-normal text-lg rounded-lg mx-2 cursor-pointer ${themeTab === 'Theme Master' ? 'scale-[1.05] shadow-lg' : 'bg-opacity-50'}`} onClick={() => onClickTabSwitch('Theme Master')}>Theme Master</div>
                    <div className={`bg-[#9747FF] transform transition duration-300 w-fit py-1 px-5 text-white font-normal text-lg rounded-lg mx-2 cursor-pointer ${themeTab === 'Themes Sentences' ? 'scale-[1.05] shadow-lg' : 'bg-opacity-50'}`} onClick={() => onClickTabSwitch('Themes Sentences')}>Themes Sentences</div>
                </div>
                <div className="flex">
                    <button disabled={!resetChanges} className={`${resetChanges ? 'bg-[#9747FF] cursor-pointer' : 'bg-[#808080] cursor-not-allowed'} transition-all py-1 px-5 text-white font-normal text-lg rounded-lg mx-1`} onClick={onClickReset} >Reset</button>
                    <button disabled={!applyChangesActive} className={`${applyChangesActive ? 'bg-[#9747FF] cursor-pointer' : 'bg-[#808080] cursor-not-allowed'} transition-all text-white py-1 px-5  font-normal text-lg rounded-lg mx-1 `} onClick={onApplyChangesTM} >Apply Changes</button>
                </div>
            </div>
            {themeTab === 'Theme Master' && <ThemeMaster themeTab={themeTab} setApplyChangesActive={setApplyChangesActive} setResetChanges={setResetChanges} setWarningMaster={setWarningMaster} applyChangesClick={applyChangesClick} setApplyChangesClick={setApplyChangesClick} resetClick={resetClick} setResetClick={setResetClick} setResetSortFilter={setResetSortFilter} resetSortFilter={resetSortFilter} />}
            {themeTab === 'Themes Sentences' && <ThemeSentences themeTab={themeTab} setApplyChangesActive={setApplyChangesActive} setResetChanges={setResetChanges} setWarningSentences={setWarningSentences} applyChangesClick={applyChangesClick} setApplyChangesClick={setApplyChangesClick} resetClick={resetClick} setResetClick={setResetClick} setResetSortFilter={setResetSortFilter} resetSortFilter={resetSortFilter} />}

            {/* pop up */}
            <Popup
                modal
                open={openPopup}
                // onClose={() => setOpenPopup(false)}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                contentStyle={{
                    width: "34vw",
                    boxShadow: "0px 0px 1px white",
                    backgroundColor: "white",
                    borderRadius: "6px",
                }}
            >
                <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
                    <div className="flex justify-between items-center w-full mb-6">
                        <p className="font-base text-lg  flex items-center pl-10 pr-10">
                            {" "}
                            <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
                        </p>
                        <XCircle
                            className="mr-6"
                            size={24}
                            style={{ cursor: "pointer" }}
                            fill="#BDBCBC"
                            color="#FFFF"
                            onClick={() => {
                                setOpenPopup(false);
                            }}
                        />
                    </div>
                    <div className="flex justify-center mx-8 max-h-[300px] overflow-y-auto">
                        <div className="grid grid-cols-8 gap-2 justify-center" style={{ fontSize: "12px", color: "#545454" }}>
                            {warningsShow.map((item: any, index: any) => {
                                if (item) {
                                    return (
                                        <>
                                            <div className="">row: {index + 1}</div>
                                            <div className="col-span-7">{item.message}</div>
                                        </>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    {warningsShow?.length === 0 &&
                        <span
                            className="font-base pb-6 -2 pl-10 pr-10"
                            style={{ fontSize: "12px", color: "#545454" }}
                        >
                            Applying changes. Do you wish to proceed?
                        </span>
                    }
                    <div
                        className=" mt-5 flex justify-between items-center ml-10 mr-10"
                        style={{ width: "90%" }}
                    >
                        <button
                            style={{ color: " #8088A4", border: "none" }}
                            onClick={() => {
                                setOpenPopup(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            // onClick={() => {
                            //     setOpenPopup(false);
                            //     // setApplyChangesActive(!applyChangesActive)
                            //     setApplyChangesActive(false)
                            //     setApplyChangesClick(true)
                            //     setResetChanges(false)
                            //     setResetClick(false)
                            //     dispatch(setScopeUpdated(false))
                            //     dispatch(setSubmitEnable(false))
                            // }}
                            onClick={handleButtonClick}
                            className="border w-48 p-2 rounded"
                            style={{ border: "1px solid red", color: "red" }}
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            </Popup>
            <Popup
                modal
                open={openPopupReset}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                contentStyle={{
                    width: "34vw",
                    boxShadow: "0px 0px 1px white",
                    backgroundColor: "white",
                    borderRadius: "6px",
                }}
            >
                <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
                    <div className="flex justify-between items-center w-full mb-6">
                        <p className="font-base text-lg  flex items-center pl-10 pr-10">
                            {" "}
                            <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
                        </p>
                        <XCircle
                            className="mr-6"
                            size={24}
                            style={{ cursor: "pointer" }}
                            fill="#BDBCBC"
                            color="#FFFF"
                            onClick={() => setOpenPopupReset(false)}
                        />
                    </div>

                    <span
                        className="font-base pb-6 -2 pl-10 pr-10"
                        style={{ fontSize: "12px", color: "#545454" }}
                    >
                        Changes will be discarded. Do you wish to proceed?{" "}
                    </span>
                    <div
                        className=" mt-5 flex justify-between items-center ml-10 mr-10"
                        style={{ width: "90%" }}
                    >
                        <button
                            style={{ color: " #8088A4", border: "none" }}
                            onClick={() => setOpenPopupReset(false)}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => {
                                setResetClick(true)
                                setResetChanges(false)
                                setApplyChangesActive(false)
                                setApplyChangesClick(false)
                                setOpenPopupReset(false)
                            }}
                            className="border w-48 p-2 rounded"
                            style={{ border: "1px solid red", color: "red" }}
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            </Popup>
            <Popup
                modal
                open={openPopupSwitchTab}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                contentStyle={{
                    width: "34vw",
                    boxShadow: "0px 0px 1px white",
                    backgroundColor: "white",
                    borderRadius: "6px",
                }}
            >
                <div className="flex flex-col items-start mr-2  bg-transparent pb-6 pt-5">
                    <div className="flex justify-between items-center w-full mb-6">
                        <p className="font-base text-lg  flex items-center pl-10 pr-10">
                            {" "}
                            <ErrorOutlineIcon className="text-red-600 mr-2" /> Please Note
                        </p>
                        <XCircle
                            className="mr-6"
                            size={24}
                            style={{ cursor: "pointer" }}
                            fill="#BDBCBC"
                            color="#FFFF"
                            onClick={() => setOpenPopupSwitchTab(false)}
                        />
                    </div>

                    <span
                        className="font-base pb-6 -2 pl-10 pr-7"
                        style={{ fontSize: "12px", color: "#545454" }}
                    >
                        You are leaving the tab. Do you want to apply changes and continue? If not, the changes will not be saved.
                    </span>
                    <div
                        className=" mt-5 flex justify-between items-center ml-10 mr-10"
                        style={{ width: "90%" }}
                    >
                        <button
                            style={{ color: " #8088A4", border: "none" }}
                            onClick={() => {
                                dispatch(setScopeUpdated(false))
                                setOpenPopupSwitchTab(false)
                                if (themeTab === 'Theme Master') {
                                    setThemeTab('Themes Sentences')
                                } else {
                                    setThemeTab('Theme Master')
                                }
                            }}
                        >
                            Continue anyway
                        </button>
                        <button
                            onClick={() => {
                                setApplyChangesActive(false)
                                setApplyChangesClick(true)
                                setResetChanges(false)
                                setResetClick(false)
                                dispatch(setScopeUpdated(false))
                            }}
                            className="border w-48 p-2 rounded"
                            style={{ border: "1px solid red", color: "red" }}
                        >
                            Apply and continue
                        </button>
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default ThemesEditProject
