import React, { useState } from 'react';
import PDFDOCX from './PdfDocx';
import XLSXCSV from './XlsxCsv';
import SwitchButtons from './SwitchButtons';

const ExampleData = () => {

  const data = [
    { text: "Would everybody please take turns to introduce themselves by giving your name, and briefly share with the group how often you put on make-up.", speaker: "Moderator", city_group: "bangalore", gender: "M" },
    { text: "Hi, I am A here. So normally I put on light make-up in school, when coming to school and heavy make-up for wedding dinner.", speaker: "A", city_group: "mumbai", gender: "F" },
    { text: "Hi, I am B. How to said cause I put on make-up most of the time but light.", speaker: "B", city_group: "mumbai", gender: "F" },
    { text: "Hi, I am C. I put on make-up when I am going to wedding dinner or clubbing.", speaker: "C", city_group: "bangalore", gender: "M" },
    { text: "Hi, I am D and I put on light make-up when I go out.", speaker: "D", city_group: "mumbai", gender: "M" },
    { text: "As long as you going out?", speaker: "Moderator", city_group: "kolkata", gender: "F" },
    { text: "Sometimes I do not when I feel lazy but most of the time when I going out as in like going town.", speaker: "D", city_group: "chennai", gender: "F" },
    { text: "Hi, I am E. I wear make-up almost every day when I go out but like is usually light make-up, only for occasional events will wear heavy make-up", speaker: "E", city_group: "bangalore", gender: "M" },
  ];
  const data1 = [
    {
      text: " This column should contain all questions and answers from the Moderator or Respondent", type: "This column should state if the response is coming from a Moderator or Respondent. If you have details regarding which Respondent it is for that study,it can be indicated here as well",
      location: "This column should contain the location of where the study was conducted (e.g. Country or City)", optional: "(Optional) Additional columns from here on can be considered and used as Attributes of the study (e.g. Age, Gender etc)."
    }
  ]
  const data2 = [
    {
      dialogue: "I mean for some people they just one like WhatsApp, is there any reason or reasons why you use a variety of those different tools?",
      speaker: "Speaker 1",
      timeStamp: "{0:02:10 – 0:02:15}",
      gender: "",
    },
    {
      dialogue: "Different information, stuff like that. Because I think sometimes information you can read say for newspapers, stuff like that, they’re not always correct. So I like research a story because I’ll see something on social network and think well that’s been over-exaggerated, and then I’ll Google the story to get a better perspective on it, stuff like that.",
      speaker: "Speaker 2",
      timeStamp: "{0:02:15 – 0:02:40}",
      gender: "",
    },
    {
      dialogue: "And you say about information and stuff, what kind of information? Is there any particular information that you like to look at daily or?",
      speaker: "Speaker 1",
      timeStamp: "{0:02:40 – 0:02:45}",
      gender: "",
    },
    {
      dialogue: "Bus times, I’ve got a bus app. Train app for my public transport needs. I check the cinema times online – everything really. I shop online for my shopping, everything I do in my day to day life is online.",
      speaker: "Speaker 2",
      timeStamp: "{0:02:45 – 0:03:00}",
      gender: "",
    },
    {
      dialogue: "Interesting. Participant two, can you please describe your activities of using technology, why, what type you’ve used, any mobile apps?",
      speaker: "Speaker 1",
      timeStamp: "{0:03:00 – 0:03:05}",
      gender: "",
    },
    {
      dialogue: "I don’t use it quite as much as participant one, but I do. I’ve got a mobile, a Samsung phone and a Samsung tablet. And I’ve got mobile banking from Lloyds TSB and Santander. And I use, I’m on Facebook for my social media to keep up to date really with my cousins who live in Australia and Poland and Canada and places like that. It’s nice to have got back in touch with them again, and some friends from, who I knew in high school and stuff. I enjoy downloading games to play on.",
      speaker: "Speaker 3",
      timeStamp: "{0:03:05 - 0:03:35}",
      gender: "",
    },
  ];

  const data3 = [
    { text: "I know where that is.", speaker: "X", gender: "M", location: "Delhi" },
    { text: "We found that.", speaker: "X", gender: "M", location: "Mumbai" },
    { text: "Which school’s that, may I ask?", speaker: "Jane", gender: "F", location: "Chennai" },
    { text: "It’s at [Catholic independent boys’ school]", speaker: "Mary", gender: "F", location: "Kolkata" },
    { text: "Okay, so like is it a combined primary and high school?", speaker: "Ben", gender: "M", location: "Bengaluru" },
    { text: "It is yes.", speaker: "Mary", gender: "F", location: "Hyderabad" },
    { text: "5-12, yes. So that you can teach in both primary and secondary.", speaker: "Mary", gender: "F", location: "Ahmedabad" },
    { text: "Which is good.", speaker: "Y", gender: "M", location: "Pune" },
  ];

  const data4 = [
    { text: "Please switch on your video. Keep your mike mute,when you want to talk you can unmute.", type: "Q", city: "Bangalore" },
    { text: "We cannot hear you.", type: "R1", city: "Bangalore" },
    { text: "Others can hear me?", type: "Q", city: "Bangalore" },
    { text: "You are audible but voice is not clear", type: "R1", city: "Bangalore" },
    { text: "Just a second Can you hear me now?", type: "Q", city: "Bangalore" },
    { text: "Yes.", type: "R1", city: "Bangalore" },
  ]




  const [activeButton, setActiveButton] = useState('pdfDocx');

  const handleButtonClick = (buttonType: string) => {
    setActiveButton(buttonType);
  };

  return (
    <div className="container mx-auto mt-20 p-4" style={{ height: '700px' }}>
      <SwitchButtons activeButton={activeButton} handleButtonClick={handleButtonClick} />

      {
        activeButton === 'pdfDocx' ? (
          <PDFDOCX data={data} data2={data2} data3={data3} />
        ) : (
          <XLSXCSV data={data1} data2={data4} />
        )
      }
    </div>
  );
};

export default ExampleData