import React from "react";
import LayoutForCompareWordCloud from '../../components/LayoutForCompareWordCloud/LayoutForCompareWordCloud';

const Compare = () => {
  return (
    <div>
      <LayoutForCompareWordCloud />
    </div>
  )
};

export default Compare