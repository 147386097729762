import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { useEffect, useState } from "react"
import { updateConfigureDataSuccess } from "../../redux/Wizard/wizardReducer";
import CircleIcon from '@mui/icons-material/Circle';
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import Spinner from "../Spinner";
import { Download, XCircle } from "react-feather";
import { CircularProgress, Tooltip } from "@mui/material";
import { decryption, secrateKey } from "../../modules/LoginLayout/LoginLayout";
import { getProjectsDataList } from "../../redux/Project/action";
import settings from "../../config";

interface IProjectInfoPopup {
  handleClosePopup?: any,
  project_id?: string,
  state?: any,
  isConfigureDataSuccess?: any,
  setIsConfigureDataSuccess?: any
}


export default function ProjectInfoPopup(props: IProjectInfoPopup) {
  const { handleClosePopup } = props
  const projectId = useParams().projectId
  const id = useLocation().pathname
  const project_Id = id.split('/')[3]
  const [dataSetId, setDataSetId] = useState<string>("")
  const [disabledDownloadButton, setDisabledDownloadButton] = useState(true);
  const [configData, setConfigData] = useState<any>([]);
  const ConfigureDataSuccess = useSelector((state: RootState) => state.wizard.ConfigureDataSuccess);
  const ConfigureDataState = useSelector((state: RootState) => state.wizard.ConfigureDataState);
  const [showConfig, setShowConfig] = useState(false);
  const dispatch = useAppDispatch();
  const ProjectId = ConfigureDataState.project_id
  const configureData = useSelector((state: RootState) => state.wizard.ConfigureDataState);
  const configureDataLoader = useSelector((state: RootState) => state.wizard.configureDataLoader)
  const featuresData = useSelector((state: RootState) => state.master.featuresData);
  const projectDataSetList: any = useSelector((state: RootState) => state.project.projectDataSetList);
  const projectDataSetListDownloadAvailable: any = useSelector((state: RootState) => state.project.projectDataSetListDownloadAvailable);
  const projectDataSetListLoader: any = useSelector((state: RootState) => state.project.projectDataSetListLoader);

  let files: any[] = []
  let headers: string[] = []
  let topics_of_interest_data: any[] = []


  if (configureData?.step_id === 1) {
    files = configureData?.state?.files;
    // const headers = files?.length > 0 && files[0]?.attribute_data?.length > 0
    //   ? ['File name', ...files[0].attribute_data?.map((attr: any) => attr.name)]
    //   : ['File name'];
    headers = ['File name'];
    topics_of_interest_data = configureData?.topics_of_interests

    if (files?.length > 0) {
      // Collect all unique, non-empty attribute names
      const attributeNamesSet: Set<string> = new Set(
        files.flatMap((file: any) =>
          file.attribute_data?.filter((attr: any) => attr.name.trim() !== "").map((attr: any) => attr.name) || []
        )
      );
      const attributeNames: string[] = Array.from(attributeNamesSet).filter(name => name.trim() !== ""); // Convert Set to Array and filter out empty names

      headers.push(...attributeNames);
    }
  }

  useEffect(() => {
    if (props.state !== true) {
      dispatch(getProjectsDataList({ project_id: props.project_id }))
    }
  }, [])

  const [fileData, setFileData] = useState<Array<any>>([])
  const transformFileData = (data: any) => {
    if (data) {
      const datasetIdMap: Record<string, number> = {};
      data[0]?.values?.forEach((item: any, index: any) => {
        datasetIdMap[item.dataset_id] = index;
      });

      const sortedData = data[1]?.values?.length > 0 ? [...data[1]?.values] : [];
      sortedData?.length > 0 && sortedData?.sort((a, b) => {
        return datasetIdMap[a.dataset_id] - datasetIdMap[b.dataset_id];
      });
      let out = JSON.parse(JSON.stringify(data[0]?.values))
      out = out.map((item: any, index: number) => {
        return { ...item, value2: sortedData.length > 0 ? sortedData[index]?.value : "" }
      })
      return out
    }
  }

  useEffect(() => {
    let data: any = [];
    if (props.isConfigureDataSuccess) {

      if (configureData?.step_id === 1 && topics_of_interest_data?.length > 0) {
        topics_of_interest_data?.map((item: any) => {
          data.push({ name: item?.toi_name })
        })
      }
      else if (configureData?.step_id === 2) {
        if (configureData?.reporting_buckets?.length > 0) {
          let rb: any = [];
          configureData?.reporting_buckets?.map((item: any) => {
            rb.push(item.bucket_name);
          });
          data.push({ "name": "Reporting Buckets", "value": rb });
        }

        let theme: any = []
        configureData?.themes?.map((item: any) => {
          theme.push(item.theme_name)
        })
        if (theme.length > 0) {
          data.push({ name: "Themes", value: theme })
        }
        configureData?.entities?.forEach((item: any) => {
          let temp: any = []
          item?.values?.forEach((elem: any) => {
            temp.push(elem.entity_value)
          })
          data.push({ name: item.entity_type, value: temp })
        })
      }
      setConfigData(data);
      setShowConfig(true);
      // setOnOpenPopup(true)
      // dispatch(updateConfigureDataSuccess(false))
      setFileData(transformFileData(configureData?.attribute_data))
      props.setIsConfigureDataSuccess(false)
    }
  }, [props.isConfigureDataSuccess]);

  const downloadTranscriptFile = (fileName: string, dataset_id: string) => {
    setDataSetId(dataset_id)
    if (disabledDownloadButton) {
      setDisabledDownloadButton(false);
      const encrypted = localStorage.getItem("_U");
      let token = decryption(encrypted, secrateKey)
      if (token) {
        token = JSON.parse(token).accessToken;
      }
      let final_file_name = fileName.split(".")[0] + ".pdf";
      axios({
        url:
          settings.REACT_APP_API_URL +
          `projects/${projectId ?? project_Id ?? ProjectId}/downloadtranscript/${dataset_id}`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response: any) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", final_file_name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          setDisabledDownloadButton(true);
          setDataSetId("")
        })
        .catch((error: any) => {
          setDisabledDownloadButton(true);
          console.error("Error downloading .xlsm file:", error);
          setDataSetId("")
        });
    }
  }

  const handleTranscriptClick = (data: any) => {
    let dataset_id: string = ""

    if (props?.state) {
      if (configureData?.step_id === 2) {
        dataset_id = configureData.attribute_data[0]?.values?.filter((each: any) => each?.file_name === data?.file_name)[0]?.dataset_id
      }
      else if (configureData?.step_id === 1) {
        dataset_id = files?.filter((each: any) => each?.filename === data?.filename)[0]?.dataset_id
      }
    } else {
      if (configureData?.step_id === 2) {
        dataset_id = projectDataSetList?.filter((each: any) => each?.dataset_name === data?.file_name)[0]?.dataset_id
      }
      else if (configureData?.step_id === 1) {
        dataset_id = projectDataSetList?.filter((each: any) => each?.dataset_name === data?.filename)[0]?.dataset_id
      }
    }
    window.open(`/transcript_view/${props.project_id}/${dataset_id}`, '_blank');
  }

  const hasOnlyEmptyAttributes = (attributeData: any) => {
    return attributeData?.every((attrObj: any) => attrObj?.name?.trim() === "" && attrObj?.value?.trim() === "");
  };

  const hasFileName = configureData?.step_id===2 && configureData?.attribute_data?.some((attribute:any) => 
    attribute?.values?.some((value:any) => value?.file_name && value?.file_name?.trim() !== "")
  );

  return (
    <>
      {(configureDataLoader || projectDataSetListLoader) ? (
        <div className="h-52">
          <div className="font-semibold text-xl ">
            <span>Project Scope</span>
          </div>
          <div className="flex justify-center items-center">
            <Spinner height={22} />
          </div>
        </div>
      ) : (Object.keys(configureData).length !== 0 ?
        <div>
          <div className="mb-2 border-b border-gray-300 rounded-t-md bg-gray-100 p-1 flex justify-between" style={{ minHeight: "14vh" }}>
            <div className="h-full flex flex-col justify-center gap-2 px-2">
              <span className="text-gray-400 text-lg">Project Scope</span>
              <span className="font-semibold text-2xl w-[45vw]">{configureData?.project_name}</span>
              {configureData?.step_id === 1 && configureData?.research_type && <span className="text-gray-400 text-lg ">Type of Research: <span className="uppercase text-black">{configureData?.research_type} </span></span>}

            </div>
            <XCircle
              className="mr-2 mt-1"
              size={24}
              style={{ cursor: "pointer" }}
              fill="#BDBCBC"
              color="#FFFF"
              onClick={handleClosePopup}
            />
          </div>
          <div
            className="flex flex-col justify-start gap-3 overflow-y-auto p-[2%]"
            style={{
              maxHeight: "75vh",
            }}
          >
            <div className="w-full">
              {configureData?.category_name.length > 0 && <><span className="font-semibold">Category : </span> <span>{configureData?.category_name}</span></>}
            </div>

            {configureData?.step_id === 2 && hasFileName &&
              <div className="w-full border border-gray-300 rounded-md">
                <div className="header grid grid-cols-7 border-b border-gray-300 text-gray-400 font-medium">
                  <div className="col-span-3 px-6 py-2">
                    File name
                  </div>
                  <div className="col-span-2 h-full px-6 py-2 border-r border-l border-gray-300">
                    Location
                  </div>
                  <div className="col-span-2 h-full px-6 py-2">
                    {configureData?.attribute_data[1]?.name}
                  </div>
                </div>
                {
                  fileData?.map((item: any, index: number) => {
                    const dotIndex = item?.file_name?.lastIndexOf('.')
                    const fileName = item?.file_name?.slice(0, dotIndex)
                    const extension = item?.file_name?.slice(dotIndex)?.toUpperCase()
                    return (
                      <div className={`header grid grid-cols-7 ${index < fileData.length - 1 ? "border-b" : ""} border-gray-300`} key={index}>
                        <div className="col-span-3 px-6 py-2 flex justify-start items-center gap-2 overflow-hidden">
                          {dataSetId === item.dataset_id ?
                            <CircularProgress size={"18px"} sx={{ color: '#9747FF' }} />
                            :
                            // userInfo.ROLE_ID === 2 && (
                            projectDataSetListDownloadAvailable && (
                              <Tooltip title={featuresData.some((item: string) => item === "MR_CORRECTION_QC_STEP") ? "" : configureData.downloadable_transcript ? "" : "Transcript not available for download"}>
                                <div className={`flex flex-col justify-start items-center h-fit ${featuresData.some((item: string) => item === "MR_CORRECTION_QC_STEP") ? "cursor-pointer" : configureData.downloadable_transcript ? "cursor-pointer" : ""}`}
                                  onClick={() => featuresData.some((item: string) => item === "MR_CORRECTION_QC_STEP") ? downloadTranscriptFile(item.file_name, item.dataset_id) : configureData.downloadable_transcript ? downloadTranscriptFile(item.file_name, item.dataset_id) : ""}
                                >
                                  <Download color={featuresData.some((item: string) => item === "MR_CORRECTION_QC_STEP") ? "#9747FF" : configureData.downloadable_transcript ? "#9747FF" : "#3333"} size={18} />
                                  <span className={`text-[8px] ${featuresData.some((item: string) => item === "MR_CORRECTION_QC_STEP") ? "text-[#9747FF]" : configureData.downloadable_transcript ? "text-[#9747FF]" : "text-gray-300"}`}>PDF</span>
                                </div>
                              </Tooltip>
                            )
                          }
                          {/* <span style={{wordBreak: "break-word"}} >{fileName}<span className="bg-gray-400 text-white px-2 rounded-md ml-3">{extension}</span></span> */}
                          <div className="relative flex flex-col items-center group">
                            <Tooltip title={"Opens in a new tab"} placement="top" >
                              <span style={{ wordBreak: "break-word" }} onClick={() => handleTranscriptClick(item)}>
                                <span className="text-black cursor-pointer border-b border-b-1 border-gray-600">
                                  {fileName}
                                </span>
                                <span className="bg-gray-400 text-white px-2 rounded-md ml-2">
                                  {extension}
                                </span>
                              </span>
                            </Tooltip>

                          </div>
                        </div>
                        <div className="col-span-2 h-full px-6 py-2 border-r border-l border-gray-300 overflow-hidden">
                          {item.value}
                        </div>
                        <div className="col-span-2 h-full px-6 py-2">
                          {configureData?.attribute_data[1]?.is_active ? item.value2 : ''}
                        </div>
                      </div>
                    )
                  })}

              </div>
            }

            {configureData?.step_id === 1 && files?.length > 0 &&
              // <table className={`${files[0]?.attribute_data?.length > 0 ? "min-w-full" : "w-fit"} divide-y divide-gray-200 border-collapse border border-gray-300`}>
              <table className={`${files[0]?.attribute_data?.length !== 0 || "attribute_data" in files[0] && hasOnlyEmptyAttributes(files[0]?.attribute_data) ? "w-fit" : "min-w-full"} divide-y divide-gray-200 border-collapse border border-gray-300`}>
                <thead className="bg-gray-50">
                  <tr>
                    {headers?.filter(header => header?.trim() !== "").map((header, index) => (
                      <th
                        key={index}
                        className="px-6 py-3 text-left text-md font-medium text-gray-500  tracking-wider border border-gray-300"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {files?.map((item: any, index: any) => {
                    const dotIndex = item?.filename.lastIndexOf('.');
                    const fileName = item?.filename.slice(0, dotIndex);
                    const extension = item?.filename.slice(dotIndex).toUpperCase();
                    const emptyCells = headers?.filter(header => header?.trim() !== "").length - item?.attribute_data?.filter((item: any) => item?.name).length - 1
                    return (
                      <tr className={`${index < files.length - 1 ? 'border-b' : ''} border-gray-300`} key={index}>
                        <td className="col-span-3 px-6 py-2 flex justify-start items-center gap-2 overflow-hidden">
                          {dataSetId === item?.dataset_id ?
                            <CircularProgress size={"18px"} sx={{ color: '#9747FF' }} />
                            :
                            projectDataSetListDownloadAvailable && (
                              <Tooltip title={featuresData.some((featureItem: any) => featureItem === "QC_0_STEP") ? "" : configureData.downloadable_transcript ? "" : "Transcript not available for download"}>
                                <div className={`flex flex-col justify-start items-center h-fit ${featuresData.some((featureItem: any) => featureItem === "QC_0_STEP") ? "cursor-pointer" : configureData.downloadable_transcript ? "cursor-pointer" : ""}`}
                                  onClick={() => featuresData.some((featureItem: any) => featureItem === "QC_0_STEP") ? downloadTranscriptFile(item.filename, item.dataset_id) : configureData.downloadable_transcript ? downloadTranscriptFile(item.filename, item.dataset_id) : ""}
                                >
                                  <Download color={featuresData.some((featureItem: any) => featureItem === "QC_0_STEP") ? "#9747FF" : configureData.downloadable_transcript ? "#9747FF" : "#3333"} size={18} />
                                  <span className={`text-[8px] ${featuresData.some((featureItem: any) => featureItem === "QC_0_STEP") ? "text-[#9747FF]" : configureData.downloadable_transcript ? "text-[#9747FF]" : "text-gray-300"}`}>PDF</span>
                                </div>
                              </Tooltip>
                            )
                          }
                          <div className="relative flex flex-col items-center group">
                            <Tooltip title={"Opens in a new tab"} placement="top">
                              <span style={{ wordBreak: "break-word" }} onClick={() => handleTranscriptClick(item)}>
                                <span className="text-black cursor-pointer border-b border-b-1 border-gray-600">
                                  {fileName}
                                </span>
                                {
                                  item.simultaneous_transcript &&
                                  <span className="text-black text-bold"> *</span>
                                }
                                <span className="bg-gray-400 text-white px-2 rounded-md ml-2">
                                  {extension}
                                </span>
                              </span>
                            </Tooltip>
                          </div>
                        </td>
                        {/* <td className="col-span-2 px-6 py-2 border-r border-l border-gray-300 overflow-hidden">
                              {item.value}
                            </td> */}
                        {("attribute_data" in item) &&
                          item?.attribute_data?.every((attrObj: any) => attrObj?.name?.trim() !== "" || attrObj?.value?.trim() !== "") &&
                          item?.attribute_data?.map((attrObj: any) =>
                            <td className="col-span-2 px-6 py-2 border border-gray-300">
                              {attrObj?.value}
                            </td>
                          )}
                        {Array.from({ length: emptyCells }, (_, index) => (
                          <td className="col-span-2 px-6 py-2 border border-gray-300"></td>
                        ))}
                      </tr>
                    )
                  })}

                </tbody>
              </table>
            }
            {configureData?.step_id === 1 &&
              files.some((file: any) => file?.simultaneous_transcript) ? (
              <span className="text-sm italic  text-gray-400">* Simultaneous transcription</span>
            ) : (
              null
            )
            }
            {configureData?.step_id === 1 &&
              <div className="w-full flex flex-col gap-2 gap-y-3">
                {configureData?.topics_of_interests?.length > 0 && <div className="w-full"><span className="text-gray-600">Topics of Interests</span></div>}
                <div className="flex flex-col">
                  {
                    configureData?.topics_of_interests.length > 0 && configureData?.topics_of_interests.map((item: any, index: number) => {
                      return (
                        <>
                          <div className="" key={index}>
                            <ul className="">
                              <li className="flex justify-start items-start" key={index}>
                                <CircleIcon
                                  className="scale-[0.3]"
                                  sx={{ padding: "0" }}
                                />
                                <span className="break-words w-[95%]"> {item?.toi_name}</span>
                              </li>
                            </ul>
                          </div>

                        </>
                      )
                    })
                  }
                </div>
              </div>
            }
            {
              (configureData?.step_id === 2 &&
                (
                  <div className="w-full grid grid-cols-2 gap-2 gap-y-3">
                    {Object.keys((configureData).length !== 0) &&
                      configData.map((item: any, index: any) => {
                        return (
                          <>
                            <div className="" >
                              <div className="w-full"><span className="text-gray-400">{item.name === 'Reporting Buckets' ? "Analysis Topics" : item.name}</span></div>
                              <ul>
                                {item.value.map((value: string, i: number) => {
                                  return (
                                    <li className="flex justify-start items-start" key={i}>
                                      <CircleIcon
                                        className="scale-[0.3]"
                                        sx={{ padding: "0" }}
                                      />
                                      <span className="break-words w-[95%]">{value}</span>
                                    </li>
                                  )
                                }
                                )}
                              </ul>
                            </div>
                            {
                              (index % 2 !== 0 && index < configData.length - 1) &&
                              <div className="col-span-2 h-[1px] bg-gray-200"></div>
                            }
                          </>
                        );
                      })}
                  </div>
                )
              )
            }
          </div>
        </div>
        :
        <div className="w-full p-3">
          <div className="font-semibold mb-1 text-lg">Project Scope Information Required!</div>
          <div>
          {configureData?.step_id === 1? 
          "The project's attributes, Topics of interest have not been configured. Kindly complete these fields to define the project scope." 
          : 
          "The project's attributes, reporting objectives, and areas of interest have not been configured. Kindly complete these fields to define the project scope."
          }         
          </div>
        </div>
      )}
    </>
  )
}